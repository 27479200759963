import React from 'react';
import PropTypes from 'prop-types';

import './CountDisplay.scss'

const countDisplay = (props) => {
    return (
        <div className="countContainer text-center pull-right">
            <span>{props.start}</span>
            <span > - </span>
            <span>{props.end}</span>
            <span > of </span>
            <span>{props.total}</span>
        </div>
    )
}

countDisplay.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    total: PropTypes.number
}

export default countDisplay;