export const BASE_ROUTE = '/';
export const JOB_LIST_ROUTE = '/';
export const POST_JOB_ROUTE = '/postjob';
export const EDIT_JOB_ROUTE = '/editjob/:id';
export const DISPLAY_TAGS_ROUTE = '/displayTags';
export const NOT_ADMIN_ROUTE = '/notAdmin';
export const PLACEMENT_ROUTE = '/placement';
export const EDIT_PLACEMENT_ROUTE = '/editcandidate/:id';
export const POST_PLACEMENT_ROUTE = '/postcandidate';
export const INTEGRATION_ROUTE = '/integration';
export const ADDINTEGRATION_ROUTE ='/addintegration';
export const EDITINTEGRATION_ROUTE = '/editintegration/:id';
export const RESUMELIST_ROUTE = '/resumelist';
export const CHAT_MESSAGES_ROUTE = '/messages';
export const PROJECT_LIST_ROUTE = '/projects';
export const EDIT_RECRUITER_PASSWORD_ROUTE = '/changepasswordByAdmin';
export const RESET_RECRUITER_PASSWORD_LINK_ROUTE = '/resetPasswordLink';
export const CREATE_TAG_MAILER = '/newTagMailer';
export const LIST_TAG_MAILER = '/tagMailers';
export const EDIT_TAG_MAILER = '/tagMailers/:id';
export const ORDERS_LIST = '/orders';
export const SHOWCASE_PLAN_LIST = '/showcasePlanList'
export const SHOWCASE_PLAN_ADD = '/showcasePlanAdd'
export const LIST_SHOWCASE_COMPANIES = '/showcase'
export const CREATE_SHOWCASE_COMPANY = '/newShowcaseCompany'
export const EDIT_SHOWCASE_COMPANY = '/showcase/:id'
export const REPORT_SHOWCASE_COMPANY = '/analyticsReport'
export const Company_Management_List = '/companyManagement'
export const Company_Management_NewCompany = '/NewCompany'
export const Company_Management_EditCompany = '/managementCompany/:id'

export const Transfer_Job_Management = '/transfer-job';

export const RECRUITER_PROFILE_MGT_EDIT = '/recruiter/recruiter_profile_mgtedit'
export const RECRUITER_INCOMPLETE_EDIT = '/recruiter/recruiter_incomplete_edit';
export const RECRUITER_MGT = '/recruiter/recruiter_mgt'
export const LIST_LEADS = '/leads'
export const CREATE_LEAD = '/newLead'
export const RECRUITER_INCOMPLETE = '/recruiter/recruiter_incomplete';
export const RECRUITER_PROFILE_NOTIFICATION = '/recruiter/recprofile_notification';
export const RECRUITER_DOMAIN_MANAGEMENT = '/recruiter/domain_management';
export const RECRUITER_MGR_MGT = '/recruiter/mgr_mgt';
export const OTHER_FUNCTIONAL_AREA = '/otherFunctionalArea';
export const DOMAIN_MAPPING = '/domain_mapping';
export const SEEKER_SUBSCRIPTIONS = '/manageSubscriptions'
export const SEEKER_SUBSCRIPTIONS_FORM = '/manageSubscriptionsForm'
export const RESUME_BUILDER = '/resumeBuilder';
export const RESUME_BUILDER_EDIT = '/resumeBuilder/edit/:id';
export const RESUME_BUILDER_ADD = '/resumeBuilder/add';
export const AVG_PUBLISH_TIME = '/avg_publish_time'
export const INVOICE_UPLOAD = '/invoice-upload';
export const TRANSACTION_UPLOAD = '/transaction-upload';
export const JOBSEEKER_MGT = '/recruiter/seeker_mgt';
export const APPLICATIONS = '/applications'
export const PREMIUM_JOB_LIST = '/premiumJobList';
export const PREMIUM_RECRUITER_LIST = '/premiumRecruiterList';
export const PREMIUM_POSTING_ADOPTION = '/premiumPostingAdoption';
export const UNICORN_DASHBOARD = '/unicorn-premium-list';
export const ANY_RECRUITER_LOGIN = '/anyRecruiterLogin';
export const RECRUITER_CREDIT_MANAGEMENT = '/recruiter/credit_management';
export const RECRUITER_CREDIT_MANAGEMENT_ADD = '/recruiter/credit_management/add';
export const RECRUITER_CREDIT_MANAGEMENT_EDIT = '/recruiter/credit_management/edit';
export const RECRUITER_CREDIT_MANAGEMENT_DISTRIBUTE = '/recruiter/credit_management/distribute';
export const RECRUITER_PROFILE_MGT = '/recruiter/recruiter_profile_mgt';
export const NOTIFIER_LOGS = '/notifierLogs'

export const CLIENT_ENG_REPORTSHEET = '/clientEngagementReportSheet';
export const ADD_CLIENT_ENGAGEMENT = '/addClient';
export const EDIT_CLIENT_ENGAGEMENT = '/editClient/:id';
export const SEEKER_NOTIFICATION_EMAIL_SETTINGS = '/seekerNotificationSettings'
export const RECRUITER_COMPANY_LIST = '/recruiterCompanyList'
export const RECRUITER_NEW_COMPANY = '/addRecruiterCompany'
export const RECRUITER_EDIT_COMPANY = '/editRecruiterCompany/:id'
export const JOB_HISTORY = '/jobHistory'
export const DIVERSITY_PLAN_LIST = '/diversityPlanList'
export const DIVERSITY_PLAN_ADD = '/diversityPlanAdd'
export const FOLLOW_UP_VIEW = '/companyListInFollowUp'
export const USERS_ANALYSIS = '/analysis/users';
export const JOBS_ANALYSIS = '/analysis/jobs';
export const FUNNEL_ANALYSIS = '/analysis/funnel';
export const FOLLOWER_REPORT = '/followerReport';
export const SEARCH_MAIL_REPORT = '/search-mail-report';
export const SEARCH_QUERY_REPORT = '/search/query-report';
export const SEARCH_FLAGGED_USERS = '/search-flagged-users';

export const PAYMENT_SYNC_DASHBOARD = '/payment-sync-dashboard';
export const RECRUITER_VERIFICATION_DASHBOARD = '/recruiter_verification';
export const PREMIUM_JOB_LISTING = '/recruiter/premium_job_listing';
export const PREMIUM_JOB_DETAIL = '/premiumjobdetail';
export const COUNT_SEARCHED_KEYWORDS = '/recruiter/count_searched_keywords';

export const LEARNING_SUBSCRIPTION = '/learningSubscription';
export const PRO_LEAD_GEN_REPORT = '/analysis/proLeadGenReport';
export const USER_APPLY_JOBS = '/analysis/userApplyJobs';
export const TAGS_ANALYSIS = '/tagsAnalysis';
export const CONFIDENTIAL_JOBS = '/confidentialJobs'

export const APPLY_REFERENCE_ANALYSIS = '/analysis/refApplyAnalysis';
export const REC_FOLLOW_UP_REPORT = '/recruiter/followup_report';

export const REPORTS = '/managerJobReports';
export const ACTIVITY_DETAILS = '/activityDetails';
export const ADMIN_USERS = '/recruiter/adminusers';
export const SEEKER_BLACKLIST = '/recruiter/blacklist';
export const XML_FEED_EXCLUDED_USERS = '/recruiter/xml-feed-excluded-users';

export const SEEKER_VERIFICATION_LIST = '/unverifiedUser';
export const CHANGE_SEEKER_PASSWORD = '/recruiter/changepasswordSeekerByAdmin'
export const SEEKER_FOLLOW_UP_MGT = '/recruiter/jobseeker_followup_mgt';
export const ALL_TAG_COUNT = '/recruiter/all_tag_count';
export const ANY_JOBSEEKER_LOGIN = '/anyJobseekerLogin';
export const COURSE_LIST = '/courseList';
export const ADD_EDIT_COURSE = '/addCourse';
export const COURSE_MAPPING_LIST = '/courseMappingList';
export const COURSE_ENQUIRY = '/courseEnquiry';
export const COURSE_VIEW_LEADS = '/courseViewLeads';
export const CHANGE_COURSE_RECRUITER = '/changeCourseRecruiter';
export const MAPPING_ADD_EDIT = '/courseMapping';
export const ATS_PANEL = '/ats-panel-integrations';
export const ATS_PANEL_RECS = '/ats-panel-integrations/:id/recs';
export const ATS_PANEL_ALL_RECS = '/ats-panel-integrations/recs';

export const JOBSEEKER_PLAN_ACTIVE = '/recruiter/jobseeker_plan_active';

export const COMPETITION_DASHBOARD = '/competition-dashboard';
export const RECRUITER_LOG_REPORT = '/recruiter/rec_log_report';

export const MANAGER_TEAM_MGT = '/recruiter/mgr_team';
export const POTENTIAL_PAID_JOBS = '/potential-paid-jobs';

// export const PRO_MEMBERSHIP_INVOICE = '/pro-membership-invoice';
export const PRO_MEMBERSHIP_INVOICE_CN = '/pro-membership-invoice-cn';

export const LG_BILL = '/addLGBill';
export const LG_BILL_EDIT = '/lGBill/:id';
export const LG_BILLS = '/lGBills';

export const LG_COLLECTION = '/addLGCollection';
export const LG_COLLECTION_EDIT = '/lGCollection/:id';
export const LG_COLLECTIONS = '/lGCollections';

export const LG_BILLING_TARGET = '/addLGBillTarget';
export const LG_BILLING_TARGET_EDIT = '/lGBillTarget/:id';
export const LG_BILLING_TARGETS = '/lGBillTargets';


export const LG_COLLECTION_TARGET = '/addLGCollectionTarget';
export const LG_COLLECTION_TARGET_EDIT = '/lGCollectionTarget/:id';
export const LG_COLLECTION_TARGETS = '/lGCollectionTargets';
export const JOB_VISIBILITY = '/job/:jobId/visibility';

export const VALUE_DELIVERY_JOBS = '/valueDeliveryJobs'

export const COMPANY_ACQUISITION = '/company-acquisition';
export const JOB_ACQUISITION = '/job-acquisition';



