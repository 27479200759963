import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Aux from '../Aux';
import './SuggestedTags.scss';
import CreatableSelect from 'react-select/lib/Creatable';
import { Checkbox, Popup } from 'semantic-ui-react';

import { components } from 'react-select';

const Input = (props) => <components.Input {...props} isHidden={false} />;

const SuggestedTags = (props) => {
    const inputArray = props.selectedTags.map(item => item.label || '');
    const [ inputValues, setInputValues ] = useState(inputArray);
    const [searchedTags,setSearchedTags] = useState(props.allTags);
    // console.log(props.selectedTags);
    // console.log(inputValues);
    
    // setInputValues(props.selectedTags.map(i => i.label || ''));
    useEffect(() => {
        // console.log("change");
        const newVals = props.selectedTags.map(item => item.label || '')
        setInputValues(newVals);
    }, [props.selectedTags]);
    
    const onChange = (i, data) => {
        props.handleResultSelect(i, data)
        // let newValues = [...inputValues];
        // newValues[i] = data ? data.label : '';
        // if (!data) {
        //     newValues.splice(i, 1);
        //     // empty last item
        //     newValues[props.selectedTags.length - 1] = ''
        // }
        // setInputValues(newValues);
    };

    const filterTagsOnSearch = (inputValueOrg) => {
        if(inputValueOrg.length==0)
           return props.allTags
        const inputValue=inputValueOrg.toLowerCase();
        const filterTags=props.allTags.filter((eachTag)=>{return eachTag.label.toLowerCase().includes(inputValue)}).sort((a,b)=>{
            if(a.label.toLowerCase().indexOf(inputValue)-b.label.toLowerCase().indexOf(inputValue)==0)
            return a.label.length-b.label.length;
            return a.label.toLowerCase().indexOf(inputValue)-b.label.toLowerCase().indexOf(inputValue)});

        return filterTags
    }

    const onInputChange = (i, inputValue, { action }) => {
        const filteredTags= filterTagsOnSearch(inputValue)
        setSearchedTags(filteredTags);
        const newValues = [...inputValues];
        const value = props.selectedTags[i];
        // onBlur => setInputValue to last selected value
        if (action === 'input-blur') {
            newValues[i] = (value ? value.label : '');
            setInputValues(newValues);
        }
    
        // onInputChange => update inputValue
        else if (action === 'input-change') {
          newValues[i] = (inputValue);
          setInputValues(newValues);
        }
    };

    return (
        <Aux>
            {
                props.postJob ? null : (
                    process.env.REACT_APP_PLATFORM == 'iimjobs' ? (
                        <div className="tags-container">
                            <h5>Suggested Tags- </h5>
                            {
                                props.suggestedTags.map((aSelTag, i) => {
                                    return (
                                        <button
                                            className="label-button"
                                            onClick={(event) => props.addSuggestedTag(event, aSelTag, i)}
                                        >
                                            {aSelTag.label}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    ) : null
                )
            }

            {
                props.selectedTags && props.selectedTags.length ? (
                    <div className="tags-container">
                        <h5>Selected Tags -</h5>
                        {props.selectedTags.map((aTag, i) => {
                            return (
                                <div key={i} className='selected-dropdown-container'>
                                    <Popup
                                        trigger={<span className='CopyIcon' onClick={(event) => props.copyToClipBoard(event, aTag)}>
                                            <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjU2MXB4IiBoZWlnaHQ9IjU2MXB4IiB2aWV3Qm94PSIwIDAgNTYxIDU2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTYxIDU2MTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImNvbnRlbnQtY29weSI+DQoJCTxwYXRoIGQ9Ik0zOTUuMjUsMGgtMzA2Yy0yOC4wNSwwLTUxLDIyLjk1LTUxLDUxdjM1N2g1MVY1MWgzMDZWMHogTTQ3MS43NSwxMDJoLTI4MC41Yy0yOC4wNSwwLTUxLDIyLjk1LTUxLDUxdjM1Nw0KCQkJYzAsMjguMDUsMjIuOTUsNTEsNTEsNTFoMjgwLjVjMjguMDUsMCw1MS0yMi45NSw1MS01MVYxNTNDNTIyLjc1LDEyNC45NSw0OTkuOCwxMDIsNDcxLjc1LDEwMnogTTQ3MS43NSw1MTBoLTI4MC41VjE1M2gyODAuNVY1MTANCgkJCXoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg=="></img>
                                        </span>}
                                        content={props.tooltipMessage ? 'Copied' : 'Copied'}
                                        position={props.positionTooltip ? props.positionTooltip : 'bottom center'}
                                        inverted disabled={false}
                                        on={'click'}
                                    />
                                    <CreatableSelect
                                        value={aTag}
                                        inputValue={inputValues[i]}
                                        onChange={onChange.bind(this, i)}
                                        onInputChange={onInputChange.bind(this, i)}
                                        options={searchedTags}
                                        controlShouldRenderValue={false}
                                        isClearable
                                        components={{
                                            Input
                                        }}
                                    />
                                    {process.env.REACT_APP_MANDATORY_TAGS == 1 && !props.isCourse ?
                                        <div className='mandatory-tag-checkbox' title='Mark this tag as must have for this job. It means this skill is necessary required for the job. Any candidate who doesn’t have all the mandatory skills, may not be suitable for the job.'>
                                            <Checkbox onChange={() => props.onMandatory(aTag)} checked={aTag['label'] ? props.mandatoryTags[aTag['label']] : false} label="Mandatory Tag" />
                                        </div> : null}
                                </div>
                            )

                        })}

                    </div>
                ) : null
            }

        </Aux>
    )
}

SuggestedTags.propTypes = {
    suggestedTags: PropTypes.array,
    selectedTags: PropTypes.array,
    totalTags: PropTypes.number,
    addSuggestedTag: PropTypes.func,
    handleResultSelect: PropTypes.func,
    allTags: PropTypes.array,
}

export default SuggestedTags;