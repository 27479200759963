import axios from '../utils/axios';

export default function postLead(adminId, data) {
    return (
        axios.post('/admin/' + adminId + '/postLeadsData',  data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
