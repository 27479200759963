import axios from '../utils/axios';

export default function fetchJobHistory(adminId, params) {
    return (
        axios.get(`/admin/${adminId}/job-history`, { params })
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err;
            })
    )
}
