export const seekerPlanActivationReasons = [
    {
        id: 0,
        name: 'Select reason'
    },
    {
        id: 1,
        name: 'Pending Payment - Apple',
    },
    {
        id: 2,
        name: 'Pending Payment - PayU',
    },
    {
        id: 3,
        name: 'L&G Activation',
    },
    {
        id: 4,
        name: 'Testing purpose',
    }
];