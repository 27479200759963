import axios from '../utils/axios';

export default function fetchIntegration(params) {
    return (
        axios.get('/placement/integration', {
            params
        })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
