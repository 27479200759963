import React, { useEffect, useState } from "react";
import "./proMembershipInvoice.scss";
import user from "../../../store/user";
import { getQueryParams, isTechDomain } from "../../../utils/utilFunctions";
import getProMembershipInvoiceList from "../../../services/getProMembershipInvoiceList";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { Header, Modal, Table } from "semantic-ui-react";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FromToDatePicker from "../../../components/FromToDatepicker";
import ButtonContainer from "../../../components/ButtonContainer";
import InputBox from "../../../components/InputBox";
import moment from "moment";
import Document from "react-pdf/dist/esm/Document";
import Page from "react-pdf/dist/esm/Page";
import createCreditNote from "../../../services/createCreditNote";

const PAGE_CONTENT = 50;

const subscriptionTypeList = [
  { id: "", name: "Select" },
  { id: 1, name: "Pro Membership Plan" },
  { id: 2, name: "Early Applicant Plan" },
  { id: 3, name: "Resume Builder Plan" },
  { id: 4, name: "Early Applicant & Resume Builder Plan" },
];

const invoiceTypeList = [
  { id: "", name: "Select" },
  { id: 'invoice', name: "Invoice" },
  { id: 'credit note', name: "Credit Note" },
];

const paymentGatewayList = [
  { id: "", name: "Select" },
  { id: `${process.env.REACT_APP_PLATFORM}_apple`, name: `${process.env.REACT_APP_PLATFORM}_APPLE` },
  { id: `${process.env.REACT_APP_PLATFORM}_payu`, name: `${process.env.REACT_APP_PLATFORM}_PAYU` },
];

const ProMembershipInvoice = (props) => {
  const [content, setContent] = useState({
    list: [],
    count: 0,
    pageNo: 1,
  });

  const [admin, setAdmin] = useState({
    adminId: null,
  });

  const [fetchApiCall, setFetchApiCall] = useState(true);

  const [pagination, setPagination] = useState({
    nextPage: null,
    prevPage: null,
    showPagination: true,
    showPrev: true,
    showNext: true,
  });

  const [inputData, setInputData] = useState({
    paymentGatewayId: "",
    documentNo: "",
  });

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    paymentGatewayId: null,
    subscriptionType: "",
    invoiceType: "",
    documentNo: "",
    paymentGateway: "",
  });

  const [modalItem, setModalItem] = useState(null);
  const [creditNoteModal, setCreditNoteModal] = useState(null);

  const [viewInvoiceModal, setViewInvoiceModal] = useState(false);

  const [invoiceNumPages, setInvoiceNumPages] = useState(1);

  const [downloadingExcel, setDownloadingExcel] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      return await user.getProfile();
    };
    fetchProfile().then((userData) => {
      if (
        userData.adminType == 2 ||
        userData.adminType == 3 ||
        userData.adminType == 4
      ) {
        setAdmin({
          adminId: userData.id,
        });
        const queryObj = getQueryParams(props.location.search);
        setContent((prev) => ({
          ...prev,
          pageNo: queryObj.pageNo,
        }));

        const filterObj = {
          ...queryObj
        };


        if(queryObj['startDate'] && moment(queryObj['startDate']).isValid()) {
          filterObj['startDate'] = moment(queryObj['startDate']).toDate();
        }

        if(queryObj['endDate'] && moment(queryObj['endDate']).isValid()) {
          filterObj['endDate'] = moment(queryObj['endDate']).toDate();
        }

        setFilters({...filterObj});

        const {documentNo, paymentGatewayId} = queryObj;

        setInputData({
          documentNo,
          paymentGatewayId
        })


        const pgNo = queryObj.pageNo ? queryObj.pageNo : 1;
        getInvoiceList(userData.id, queryObj, pgNo);
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });
  }, []);

  const getInvoiceList = async (adminId, filters = {}, pageNo) => {
    setFetchApiCall(true);

    try {
      const appliedFilters = {};
      for (const key in filters) {
        if (filters[key]) {
          appliedFilters[key] = filters[key];
          if (key == "startDate" || key == "endDate") {
            appliedFilters[key] = moment(filters[key]).format("YYYY-MM-DD");
          }
        }
      }
      routeRequest({ pageContent: PAGE_CONTENT, pageNo, ...appliedFilters });
      const res = await getProMembershipInvoiceList(adminId, {
        pageNo: pageNo || 1,
        pageSize: PAGE_CONTENT,
        ...appliedFilters,
      });
      setContent({
        pageNo: pageNo || 1,
        list: res.data.data.data,
      });
      const newPagination = {
        ...pagination,
        prevPage: pageNo - 1,
        nextPage: pageNo + 1,
      };
      if (pageNo == 1) {
        newPagination["showPrev"] = false;
      } else {
        newPagination["showPrev"] = true;
      }
      if (res.data.data.hasMore) {
        newPagination["showNext"] = true;
      } else {
        newPagination["showNext"] = false;
      }
      setPagination(newPagination);
      setFetchApiCall(false);
    } catch (err) {
      console.log(err);
      setFetchApiCall(false);
    }
  };

  const routeRequest = (filters) => {
    let array = [];

    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        array.push(`${key}=${decodeURIComponent(value)}`);
      }
    }

    array = array.join("&");
    props.history.push("?" + array);
  };

  const loadNextPage = async (e) => {
    e.preventDefault();
    const filterObj = { ...filters };

    await getInvoiceList(admin.adminId, filterObj, pagination.nextPage);
  };

  const loadPrevPage = async (e) => {
    e.preventDefault();
    const filterObj = { ...filters };

    await getInvoiceList(admin.adminId, filterObj, pagination.prevPage);
  };

  const resetDate = async () => {
    const newFilters = {
      ...filters,
      startDate: "",
      endDate: "",
    };
    setFilters(newFilters);

    await getInvoiceList(admin.adminId, newFilters, 1);
  };

  const search = async (ev) => {
    const { value } = ev.target;
    const appliedFilters = { ...filters, [value]: inputData[value] };
    setFilters(appliedFilters);
    await getInvoiceList(admin.adminId, appliedFilters, 1);
  };

  const clear = async (ev) => {
    const { value } = ev.target;
    const appliedFilters = { ...filters, [value]: "" };
    setInputData({ ...inputData, [value]: "" });
    setFilters(appliedFilters);

    await getInvoiceList(admin.adminId, appliedFilters, 1);
  };

  const handleInputChange = (ev) => {
    const { name, value } = ev.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleOnKeyPress = (event) => {
    if (event && event.key == "Enter") {
      search({ target: { value: event.target.name } });
    }
  };

  const handleFilterChange = (ev) => {
    const { name, value } = ev.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetFilters = async () => {
    const newFilters = {
      ...filters,
      invoiceType: "",
      paymentGateway: "",
      subscriptionType: "",
    };
    setFilters(newFilters);

    await getInvoiceList(admin.adminId, newFilters, 1);
  };

  const onSearch = async () => {
    await getInvoiceList(admin.adminId, filters, 1);
  };

  const handleInvoiceModalClose = () => {
    setViewInvoiceModal(false);
    setModalItem(null);
  };

  const handleCreditModalClose = () => {
    setCreditNoteModal(null);
  };

  const onViewInvoice = async (details) => {
    setModalItem({ ...details });

    setViewInvoiceModal(true);
  };

  const onInvoiceLoadSuccess = ({ numPages }) => {
    setInvoiceNumPages(numPages);
  };

  const downloadInvoice = () => {
    window.open(
      `${isTechDomain() ? process.env.REACT_APP_BASE_URL_TECH : process.env.REACT_APP_BASE_URL}/admin/${admin.adminId}/jobseeker-admin/v2/admin/invoice/${modalItem.userId}/viewInvoice?subscriptionType=${modalItem.subscriptionTypeId}&viewType=download&documentNo=${modalItem.documentNo}`,
      "_blank"
    );
  };

  const generateCreditNote = async () => {
    try {
      await createCreditNote(admin.adminId, creditNoteModal.userId, {
        createdBy: admin.adminId,
        invoiceId: creditNoteModal.documentNo,
      });

      toast.success("Credit note generated successfully");
      setCreditNoteModal(null);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const onClickDownload = async () => {
    const appliedFilters = {};
    for (const key in filters) {
      if (filters[key]) {
        appliedFilters[key] = filters[key];
        if (key == "startDate" || key == "endDate") {
          appliedFilters[key] = moment(filters[key]).format("YYYY-MM-DD");
        }
      }
    }


    try {

      setDownloadingExcel(true);

      const res = await getProMembershipInvoiceList(admin.adminId, {
        pageNo: content.pageNo,
        pageSize: PAGE_CONTENT,
        ...appliedFilters,
        exportFile: true,
        download: true
      }, true);
  
      const blobType = res["headers"]["content-type"];
  
      var blob = new Blob([res.data]);
      var url = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `invoice.xlsx`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadingExcel(false);


    }
    catch(err) {
      console.log(err);
      toast.error("Something went wrong");
      setDownloadingExcel(false);
    }
   
  };

  return (
    <div className="pro-membership-invoice-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="pro-membership-invoice-list-heading">
        <Button
          skin="dark"
          className="btn_pro_membership_invoice"
          text="View Invoice and Credit Notes"
          clickButton={() => {
            props.history.push("/pro-membership-invoice-cn");
          }}
        />

        <Button
          skin="light"
          className="btn_pro_membership_dwnld"
          text="Download Excel"
          clickButton={onClickDownload}
          loading={downloadingExcel}
          disabled={downloadingExcel}
        />
      </div>

      <div className="filters-container">
        <ButtonContainer heading={"Filter By:"}>
          <li key="serviceName">
            <label>Service Name</label>{" "}
            <Dropdown
              name="subscriptionType"
              data={subscriptionTypeList}
              dropdownChange={handleFilterChange}
              defaultValue={filters.subscriptionType}
            />{" "}
          </li>

          {/* <li key="invoiceType">
            <label>Invoice Type</label>{" "}
            <Dropdown
              name="invoiceType"
              data={invoiceTypeList}
              dropdownChange={handleFilterChange}
              defaultValue={filters.invoiceType}
            />{" "}
          </li> */}

          <li key="paymentGateway">
            <label>Payment Gateway</label>{" "}
            <Dropdown
              name="paymentGateway"
              data={paymentGatewayList}
              dropdownChange={handleFilterChange}
              defaultValue={filters.paymentGateway}
            />{" "}
          </li>
          <li key="searchDate">
            <label></label>
            <br />
            <Button skin="dark" text="Search" clickButton={onSearch} />
          </li>
          <li key="resetDate">
            <label></label>
            <br />
            <Button skin="light" text="Reset" clickButton={resetFilters} />
          </li>
        </ButtonContainer>
      </div>

      <div className="filters-container">
        <ButtonContainer heading={"Filter By:"}>
          <li key="jobDate">
            <label>Start & End Date</label>
            <FromToDatePicker
              name="date"
              startDate={filters.startDate}
              endDate={filters.endDate}
              handleChangeStart={(e) =>
                setFilters({ ...filters, startDate: e })
              }
              handleChangeEnd={(e) =>
                setFilters({
                  ...filters,
                  endDate: new Date(new Date(e).setHours(23, 59, 59)),
                })
              }
              disabled={!filters.startDate}
              maxDate={moment(filters.startDate)
                .add(1, "year")
                .toDate()}
            />
          </li>
          <li key="searchDate">
            <label></label>
            <br />
            <Button skin="dark" text="Search" clickButton={onSearch} />
          </li>
          <li key="resetDate">
            <label></label>
            <br />
            <Button skin="light" text="Reset" clickButton={resetDate} />
          </li>
        </ButtonContainer>
      </div>

      <div className="filters-container">
        <div className="single-search-filter-set">
          <InputBox
            placeholder="Payment Gateway Id"
            name="paymentGatewayId"
            value={inputData.paymentGatewayId}
            onChange={handleInputChange}
            onKeyPress={handleOnKeyPress}
          />
          <Button
            skin="dark"
            text="Search"
            value="paymentGatewayId"
            clickButton={search}
          />
          <Button
            skin="light"
            text="Clear"
            value="paymentGatewayId"
            clickButton={clear}
          />
        </div>
      </div>

      <div className="filters-container">
        <div className="single-search-filter-set">
          <InputBox
            placeholder="Enter Document Number"
            name="documentNo"
            value={inputData.documentNo}
            onChange={handleInputChange}
            onKeyPress={handleOnKeyPress}
          />
          <Button
            skin="dark"
            text="Search"
            value="documentNo"
            clickButton={search}
          />
          <Button
            skin="light"
            text="Clear"
            value="documentNo"
            clickButton={clear}
          />
        </div>
      </div>

      <div className="row text-center">
        {pagination.showPagination ? (
          <div className="pro-membership-invoice-list-pagination">
            {pagination.showPrev ? (
              <li>
                <a href="#" onClick={loadPrevPage}>
                  {"<<Prev"}
                </a>
              </li>
            ) : null}

            {pagination.showNext ? (
              <li>
                <a href="#" onClick={loadNextPage}>
                  {"Next>>"}
                </a>
              </li>
            ) : null}
          </div>
        ) : null}

        {fetchApiCall ? (
          <Loader />
        ) : content.list.length ? (
          <div className="content">
            <Table fixed selectable className="mailers-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Document Date</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Invoice No.</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Customer Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Invoice Type</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Service Name</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Bill To State</Table.HeaderCell>
                  <Table.HeaderCell width={5}>
                    Transaction/Ref Code
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>Payment Gateway</Table.HeaderCell>
                  <Table.HeaderCell width={5}>PayU Id</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Paid Amount</Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    Applied Invoice Date for CN Case
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {content.list.map((item, i) => {
                  return (
                    <Table.Row key={item.documentNo}>
                      <Table.Cell>{item.documentDate}</Table.Cell>
                      <Table.Cell>{item.documentNo} </Table.Cell>
                      <Table.Cell>{item.customerName}</Table.Cell>
                      <Table.Cell>{item.invoiceType}</Table.Cell>
                      <Table.Cell>{item.subscriptionType}</Table.Cell>
                      <Table.Cell>{item.billToState}</Table.Cell>
                      <Table.Cell>{item.refCode}</Table.Cell>
                      <Table.Cell>{item.paymentGateway}</Table.Cell>
                      <Table.Cell>{item.paymentGatewayId}</Table.Cell>
                      <Table.Cell>{item.netInvoiceAmount}</Table.Cell>
                      <Table.Cell>{item.appliedInvoiceDate}</Table.Cell>
                      <Table.Cell>
                        {item.invoiceType == "Invoice"
                          ? "Purchased"
                          : "Refunded"}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          text="View Invoice"
                          skin="dark"
                          clickButton={() => onViewInvoice(item)}
                        />
                       {/* {
                          item.invoiceType == 'Invoice' ? (
                            <Button
                              text="Generate Credit Note"
                              skin={item.creditNoteNo ? 'light' : "dark"}
                              clickButton={() => setCreditNoteModal(item)}
                              disabled={item.creditNoteNo}
                            />

                          ): null
                        } */}
                        
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <div>No Records Found</div>
        )}
      </div>

      <Modal
        className="applications-resume-modal"
        open={viewInvoiceModal}
        onClose={handleInvoiceModalClose}
        size="large"
      >
        {modalItem && (
          <>
            <Header content={modalItem.documentNo} />
            <Modal.Content scrolling>
              {modalItem ? (
                <div className="resume-container">
                  <Document
                    file={{
                      url: `${isTechDomain() ? process.env.REACT_APP_BASE_URL_TECH : process.env.REACT_APP_BASE_URL}/admin/${admin.adminId}/jobseeker-admin/v2/admin/invoice/${modalItem.userId}/viewInvoice?subscriptionType=${modalItem.subscriptionTypeId}&viewType=download&documentNo=${modalItem.documentNo}`,
                      withCredentials: true,
                    }}
                    onLoadSuccess={onInvoiceLoadSuccess}
                    onSourceError={() => {}}
                    error="No Invoice Found!"
                  >
                    {Array.from(Array(invoiceNumPages).keys()).map((key) => (
                      <Page key={key} scale={0.7} pageNumber={key + 1} />
                    ))}
                  </Document>
                </div>
              ) : (
                <div>No Invoice Found!</div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                clickButton={downloadInvoice}
                skin="dark"
                text="Download Invoice"
              />
              <Button
                clickButton={handleInvoiceModalClose}
                skin="light"
                text="Close"
              />
            </Modal.Actions>
          </>
        )}
      </Modal>

      <Modal
        className="applications-resume-modal"
        open={creditNoteModal}
        onClose={handleCreditModalClose}
        size="small"
      >
        {creditNoteModal && (
          <>
            <Header content="Please confirm" />
            <Modal.Content>
              <div>
                Are you sure you want to generate a credit note? Please generate
                this if the transaction is refunded 
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                clickButton={generateCreditNote}
                skin="dark"
                text="Yes, Generate credit note"
              />
              <Button
                clickButton={handleCreditModalClose}
                skin="light"
                text="Close"
              />
            </Modal.Actions>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ProMembershipInvoice;
