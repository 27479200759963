import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditJob from './Pages/EditJobPage'

export default () => (
	<Switch>
		<Route path="/postjob" component={EditJob} />
		<Route path="/editJob/:id" component={EditJob} />
	</Switch>
);
