import React from "react";
import Button from "../../components/Button";
import uploadInvoices from "../../services/uploadTransactions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user from '../../store/user';
import "./styles.scss"

export default class UploadInvoice extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            files: [],
            error: null,
            loader: false
        };
    }

    fileChangeHandler(ev) {
        console.log(ev.target.files);

        if (Array.from(ev.target.files).length > 1) {
            return this.setState({
                error: "Maximum Limit is 1!"
            });
        }

        this.setState({
            files: ev.target.files,
            error: null
        });
    }

    async submit() {
        const { files } = this.state;

        try {
            if (!Array.from(files).length) return;

            console.log(files);
    
            const formData = new FormData();
    
            for (let file of files) {
                formData.append('transactions', file)
            }
    
            const profile = await user.getProfile();
            const adminId = profile.id;
    
            if (!adminId) return;

            this.setState({ loader: true })
            const resp = await uploadInvoices(adminId, formData);
            this.setState({ loader: false, files: [] })
            toast.success("Invoices Uploaded Successfully");
        } catch (err) {
            console.log(err.response);
            this.setState({ loader: false });
            let msg = err.response.data.message || "Some Error Occurred";
            toast.error(msg);
        }
    }

    render() {
        const { files } = this.state;
        return (
            <div className="upload-invoice">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <p>Upload Transactions</p>
                <div className="file-selector">
                    <input 
                        type="file" 
                        multiple={true} 
                        accept=".xlsx" 
                        onChange={this.fileChangeHandler.bind(this)}
                    />
                    <span>Choose Files</span>
                </div>

                <div className="file-listing">
                    {
                        Array.from(files).map(item => {
                            return (
                                <div key={item.name} className="file">{item.name}</div>
                            )
                        })
                    }
                </div>

                <Button 
                    clickButton={this.submit.bind(this)}
                    loading={this.state.loader} 
                    disabled={this.state.loader} 
                    skin="dark" 
                    text="Submit" />
                {
                    this.state.error ?
                    <span className="error">{this.state.error}</span> : null
                }
            </div>
        )
    }

}