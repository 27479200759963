/* eslint-disable no-undef */
import React, { useState } from 'react'
import Button from '../../components/Button'
import InputBox from '../../components/InputBox'
import anyJobseekerLogin from '../../services/anyJobseekerLogin'
import user from '../../store/user'
import { validateEmail } from '../../utils/utilFunctions'
import { toast, ToastContainer } from 'react-toastify'

import './AnyJobseekerLogin.scss'

const AnyJobseekerLogin = () => {

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    })
    const [loginError, setLoginError] = useState({
        email: '',
        password: '',
    })

    const [apiCalling, setApiCalling] = useState(false);

    const onChange = (ev) => {
        const { name, value } = ev.target;
        setLoginData((prevData) => ({ ...prevData, [name]: value }));
    }

    const onSubmit = async () => {
        if (validateLogin()) {
            try {

                const profile = await user.getProfile();

                setApiCalling(true);
                const res = await anyJobseekerLogin(profile.id, { ...loginData, web: 1, recAdminEmail: profile.email });
                // set token and stop loader

                const token = res.data.data.data.authToken;
                setLoginToken(token)

                setApiCalling(false);
                window.location.href = process.env.REACT_APP_JOBSEEKER_BASE_URL;

            } catch (error) {
                // show toast
                console.log(error.response);
                toast.error(error.response.data.message || 'Something Went Wrong')
                setApiCalling(false);
            }
        }
    }

    const setLoginToken = (token) => {

        const cookie = process.env.REACT_APP_OLD_COOKIE_NAME;
        const jobseekerCookie = process.env.REACT_APP_JOBSEEKER_COOKIE_NAME;
        const today = new Date();
        today.setTime(today.getTime());
        const expires = 1 * 1000 * 60 * 60 * 24;
        const expiresDate = new Date(today.getTime() + expires);
        const domain = process.env.REACT_APP_DOMAIN;

        document.cookie =
            cookie +
            '=' +
            token +
            (expires ? ';expires=' + expiresDate.toGMTString() : '') +
            ';path=/' +
            ';domain=' +
            domain;

        document.cookie =
            jobseekerCookie +
            '=' +
            token +
            (expires ? ';expires=' + expiresDate.toGMTString() : '') +
            ';path=/' +
            ';domain=' +
            domain;
    }

    const validateLogin = () => {

        if (!loginData.email.trim().length) {
            setLoginError((prevErr) => ({ ...prevErr, email: 'Please enter an email address.' }));
            return false;
        } else if (!validateEmail(loginData.email)) {
            setLoginError((prevErr) => ({ ...prevErr, email: 'Please enter valid email address.' }));
            return false;
        } else {
            setLoginError((prevErr) => ({ ...prevErr, email: '' }));
        }

        if (!loginData.password.trim().length) {
            setLoginError((prevErr) => ({ ...prevErr, password: 'Please enter a password.' }));
            return false;
        } else {
            setLoginError((prevErr) => ({ ...prevErr, password: '' }));
        }

        return true;
    }

    return (
        <div className='jobseeker-login-container'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
            <div className='jobseeker-login-body'>
                <div className='form-container login-container'>
                    <div className='box-heading'>
                        <h1>Jobseeker's Login</h1>
                    </div>
                    <div className='form-fields'>
                        <div className='form-field'>
                            <span className='field-label'>Email *</span>
                            <div className='input-field'>
                                <InputBox
                                    name='email'
                                    type='email'
                                    value={loginData.email}
                                    onChange={onChange}
                                />
                                {loginError.email ?
                                    <span className='validation-error'>{loginError.email}</span>
                                    : null
                                }
                            </div>
                        </div>

                        <div className='form-field'>
                            <span className='field-label'>Password *</span>
                            <div className='input-field'>
                                <InputBox
                                    name='password'
                                    type='password'
                                    value={loginData.password}
                                    onChange={onChange}
                                />
                                {loginError.password ?
                                    <span className='validation-error'>{loginError.password}</span>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className='form-submisstion-field'>
                        <Button
                            text='Submit'
                            skin='dark'
                            clickButton={onSubmit}
                            loading={apiCalling}
                            disabled={apiCalling}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnyJobseekerLogin