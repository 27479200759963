import React, { Component } from 'react';
import user from '../../store/user';

class NotAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: ''
        }
    }

    componentDidMount = async() => {
        const profile = await user.getProfile();
        this.setState({email: profile.email})
    }

    render() {
        return (
            <div> You are logged in through {this.state.email}. This account does not have admin priviledges. Login through an admin account.</div>
        )
    }
}

export default NotAdmin;