import React from "react";
import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import user from '../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from 'react-datepicker';

import fetchRecruiterId from '../../../services/fetchRecruiterId';
import getCreditListing from '../../../services/getCreditListing';
import updateCredits from '../../../services/updateCredits';

import { RECRUITER_CREDIT_MANAGEMENT } from '../../../models/routes';
import { getQueryParams, getQueryString } from '../../../utils/utilFunctions';
import "./styles.scss";

import moment from "moment";

const validationMsgs = {
    totalCreditsReq: "Credits Required!",
    // totalCreditsIncorrect: "Select Correct Value for Credits",
    usedCreditsReq: "Used Credits Required!",
    startDateReq: "Start Date Required!",
    endDateReq: "End Date Required!",
    // creditsReq: "Credits Required!",
}

class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recInfo: {},
            loader: false,
            credInfo: {},
            profile: {},
            idNotFound: false,
            validations: {}
        };
    }

    async componentDidMount() {
        const params = getQueryParams(window.location.search);
        const profile = await user.getProfile();
        const resp = await getCreditListing(profile.id, {
            id: params.id
        });
        console.log(resp);
        if (resp.data.data.credits && resp.data.data.credits.length) {
            let credInfo = resp.data.data.credits[0];
            credInfo.totalCredits = credInfo.credits_no;
            credInfo.usedCredits = credInfo.used_credits;

            this.setState({
                profile,
                credInfo
            });
        } else {
            window.alert("No Record Found!");
        }
    }


    onChange(key, ev) {
        console.log(key, ev);
        let { credInfo } = this.state;

        if (["startDate", "endDate"].includes(key)) {
            credInfo[key] = ev;
        } else {
            const { value } = ev.target;
            credInfo[key] = value;
        }
        // console.log(state);
        this.setState({
            credInfo
        });
    }

    validate() {
        const { credInfo } = this.state;
        const validations = {};

        if (!credInfo.id) {
            return window.alert("Select correct id");
        }

        console.log(!credInfo.totalCredits && +credInfo.totalCredits != 0);
        if (!credInfo.totalCredits && credInfo.totalCredits != 0) {
            validations.totalCredits = validationMsgs.totalCreditsReq;
        }

        if (!credInfo.usedCredits && credInfo.usedCredits != 0) {
            validations.usedCredits = validationMsgs.usedCreditsReq;
        }

        if (!credInfo.startDate) {
            validations.startDate = validationMsgs.startDateReq;
        }

        if (!credInfo.endDate) {
            validations.endDate = validationMsgs.endDateReq;
        }

        console.log(validations);
        return validations;
    }

    async onSubmit() {
        const validations = this.validate();
        if (Object.keys(validations).length) {
            this.setState({
                validations
            });
            return;
        }

        try {
            await updateCredits(this.state.profile.id, this.state.credInfo);
            toast.info('Credits Updated successfully');
            setTimeout(() => {
                this.goToListing();    
            }, 1000);
        } catch (err) {
            if (err.response && err.response.data)
                toast.error(err.response.data.message);
            else
                toast.error("Some Error Occured!");
        }
    }

    goToListing() {
        this.props.history.push(RECRUITER_CREDIT_MANAGEMENT);                    
    }

    render() {
        const { validations, credInfo } = this.state;
        console.log(credInfo);

        return (
            <div className="credit-management-edit">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <Heading text={'Recruiter Credit Management > Edit > ' + credInfo.id || ''} />
                <div className="row">
                    <Button skin='dark' className="right-btn" text="Go To Credits Listing" clickButton={this.goToListing.bind(this)} />
                </div>
                <br />
                <br />
                <br />
                <div className="row form-container">
                    <div className="form-group cutoff">
                        <label className="col-sm-6 sub-head"> Recruiter Information </label>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> ID </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.rec_id}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Name </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.rec_name}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Email </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.email}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Assigned Credits </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.credits_no}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Used Credits </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.used_credits}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Distributed Credits </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.distributed_credits}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Quoteline No. </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <InputBox
                                    type='number'
                                    name='quoteline_no'
                                    value={credInfo.quoteline_no}
                                    placeholder='Quoteline No.'
                                    onChange={this.onChange.bind(this, "quoteline_no")} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Reason </label>
                        <div className="col-sm-6 left valueBox">
                            {credInfo.reason}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Credit No. </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <InputBox
                                    type='number'
                                    name='totalCredits'
                                    value={credInfo.totalCredits}
                                    placeholder='Total Credits'
                                    onChange={this.onChange.bind(this, "totalCredits")} />
                            </div>
                            {validations.totalCredits ? <p className='validatorError'> {validations.totalCredits} </p> : null}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Used Credit No. </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <InputBox
                                    type='number'
                                    name='usedCredits'
                                    value={credInfo.usedCredits}
                                    placeholder='Used Credits'
                                    onChange={this.onChange.bind(this, "usedCredits")} />
                            </div>
                            {validations.usedCredits ? <p className='validatorError'> {validations.usedCredits} </p> : null}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Start Date </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <DatePicker
                                    className='datePicker'
                                    selected={moment(credInfo.startDate).toDate()}
                                    placeholderText='Start'
                                    selectsStart
                                    onChange={this.onChange.bind(this, "startDate")}
                                    dateFormat='dd/MM/yyyy'
                                // minDate={ }
                                />
                            </div>
                            {validations.startDate ? <p className='validatorError'> {validations.startDate} </p> : null}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> End Date </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <DatePicker
                                    className='datePicker'
                                    selected={moment(credInfo.endDate).toDate()}
                                    placeholderText='End'
                                    selectsStart
                                    onChange={this.onChange.bind(this, "endDate")}
                                    dateFormat='dd/MM/yyyy'
                                // minDate={ }
                                />
                            </div>
                            {validations.endDate ? <p className='validatorError'> {validations.endDate} </p> : null}
                        </div>
                    </div>

                    <div className="row actions">
                        <Button skin='dark' text="Submit" clickButton={this.onSubmit.bind(this)} />
                    </div>

                </div>
            </div>
        )
    }

}

export default Edit;