import React from 'react';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const IntegrationList = Loadable({
  loader: () => import('../IntegrationList'),
  loading: Loading,
});

const IntegrationListing = () => (
    <IntegrationList />
)

IntegrationListing.propTypes = {
};

export default IntegrationListing;
