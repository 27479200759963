import React from "react";
import Heading from "../../../components/Heading";
import Pagination from "../../../components/Pagination";
import CountDisplay from "../../../components/CountDisplay";
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import { Table } from "semantic-ui-react";
import Loader from '../../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import fetchPublishedJobs from '../../../services/fetchPublishedJobs';
import fetchPublishedJobsCount from '../../../services/fetchPublishedJobsCount';
import transferJob from '../../../services/transferJob';
import user from '../../../store/user';
import { getJobUrl } from '../../../utils/utilFunctions'; 
import "./list.scss";

class TransferJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            jobId: '',
            bulkTransferString: '',
            transferString: '',
            jobs: [],
            isLoading: false,
            totalCount: null,
            pageNumber: 1,
            pageContent: 50,
            transferStatus: {},
            loading: {},
            bulkLoading: false
        };
    }

    onChange = (ev) => {
        const { value, name } = ev.target;
        this.setState({
            [name]: value
        });
    }

    handleKeyPress = (ev) => {
        if (ev.which == 13) {

        }
    }

    onClickClear = () => {
        this.setState({
            searchString: '',
            jobId: '',
            totalCount: null,
            jobs: [],
            pageContent: 50,
            pageNumber: 1
        })
    }

    onClickSearch = async () => {
        try {
            const { pageNumber, pageContent, searchString, jobId } = this.state;
            const profile = await user.getProfile();
            if (this.validate()) {
                this.setState({
                    isLoading: true
                });

                let count = 0;
                if (pageNumber == 1 && !jobId) {
                    const countRes = await fetchPublishedJobsCount(profile.id, {
                        pageContent,
                        pageNumber,
                        searchString,
                        jobId
                    });
                    count = countRes.data.data.count;
                }

                const res = await fetchPublishedJobs(profile.id, {
                    pageContent,
                    pageNumber,
                    searchString,
                    jobId
                });

                if (jobId) {
                    count =  (res.data.data.jobs || []).length;
                }

                if (!res.data.data.jobs.length) {
                    toast.error('No Data Found');
                }

                console.log(res.data);
                this.setState({
                    jobs: res.data.data.jobs,
                    totalCount: count,
                    isLoading: false
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            pageNumber
        }, () => {
            this.onClickSearch()
        });
    }

    onSubmitTransfer = async (data) => {
        try {
            if (!this.state[`transferString_${data.jobId}`]) return;

            let loading = { 
                ...this.state.loading,
                [data.jobId]: true
            };
            this.setState({ loading });
            const profile = await user.getProfile();
            const result = await transferJob(profile.id, {
                transferTo: this.state[`transferString_${data.jobId}`],
                transferFrom: data.recruiterId,
                jobIds: [data.jobId]
            });
            
            toast.info('Transfer Started');

            let jobs = [...this.state.jobs];
            const index = jobs.findIndex(i => i.jobId == data.jobId);
            jobs[index] = {
                ...jobs[index],
                transferStatus: 1
            };
            loading = { 
                ...this.state.loading,
                [data.jobId]: false
            };
            this.setState({
                jobs,
                loading
            });

        } catch (err) {
            if (err.response && err.response.data.message) {
                toast.error(err.response.data.message);
            }
            let loading = { 
                ...this.state.loading,
                [data.jobId]: false
            };
            this.setState({ loading });
        }
    }

    validate = () => {
        return true;
    }

    handleCheckboxChange = (ev) => {
        const { name, checked } = ev.target;
        this.setState({
            transferStatus: {
                ...this.state.transferStatus,
                [name]: checked
            }
        })
    }

    handleBulkCheck = (ev) => {
        const { checked } = ev.target;
        const transferStatus = this.state.jobs.reduce((p, c) => {
            if (c.transferStatus != 1) {
                p[c.jobId] = checked;
            }
            return p;
        }, {});
        this.setState({
            transferStatus
        });
    }

    onBulkTransfer = async () => {
        console.log("Bulk Called");
        const { transferStatus, bulkTransferString, jobs } = this.state;
        try {
            let jobIds = [];
            for (let key in transferStatus) {
                if (transferStatus[key]) {
                    jobIds.push(key);
                }
            }
            if (!jobIds.length) {
                toast.error('Please select atleast on job');
                return;
            }
            this.setState({
                bulkLoading: true
            });
            const transferFrom = jobs.find(item => item.jobId == jobIds[0]).recruiterId;

            const profile = await user.getProfile();
            const result = await transferJob(profile.id, {
                transferTo: bulkTransferString,
                transferFrom,
                jobIds
            });
            toast.info('Transfer Started');

            let newJobs = [...this.state.jobs];

            for (let i=0; i< newJobs.length; i++) {
                if (transferStatus[newJobs[i].jobId]) {
                    newJobs[i] = { ...newJobs[i], transferStatus: 1 }
                }
            }

            this.setState({
                bulkLoading: false,
                jobs: newJobs,
                transferStatus: {}
            });

        } catch (err) {
            if (err.response && err.response.data.message) {
                toast.error(err.response.data.message);
            }
            console.log(err);
            this.setState({
                bulkLoading: false
            });
        }
    }

    goToOld = () => {
        window.location = process.env.REACT_APP_CLIENT_URL + '/recruiter/transfer_of_job.php';
    }

    render() {
        return (
            <div className="wrapper">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <div className="pageHeading">
                    <Heading text="Transfer Job Management" />
                    <Button
                            className="go-to-old"
                            skin="dark"
                            disabled={false}
                            text="Go To Old Version"
                            clickButton={this.goToOld}
                        />
                </div>
                <div className="row">
                    <div className="form left">
                        <InputBox
                            placeholder="Enter Recruiter ID/Email"
                            name='searchString'
                            value={this.state.searchString}
                            onChange={this.onChange}
                            onKeyPress={this.handleKeyPress}
                        />
                        <span className="form-or"> OR </span>
                        <InputBox
                            placeholder="Enter Job Id"
                            name="jobId"
                            value={this.state.jobId}
                            onChange={this.onChange}
                            onKeyPress={this.handleKeyPress}
                        />
                        <Button
                            className="form-btn"
                            skin="dark"
                            disabled={false}
                            text="Search"
                            clickButton={this.onClickSearch}
                        />
                        <Button
                            className="form-btn"
                            skin="light"
                            disabled={false}
                            text="Clear"
                            clickButton={this.onClickClear}
                        ></Button>
                    </div>
                    <div className="form right">
                        <InputBox
                            placeholder="Enter Recruiter ID/Email"
                            name='bulkTransferString'
                            value={this.state.bulkTransferString}
                            onChange={this.onChange}
                            onKeyPress={this.handleKeyPress}
                        />
                        <Button
                            className="form-btn"
                            skin="dark"
                            disabled={this.state.bulkLoading || false}
                            text="Bulk Transfer"
                            loading={this.state.bulkLoading}
                            clickButton={this.onBulkTransfer}
                        />
                    </div>
                </div>
                <div>

                    {
                        this.state.jobs.length && !this.state.isLoading ? <div>
                            <div className='row text-center'>
                                <Pagination totalItemsCount={this.state.totalCount} content={this.state.pageContent} pageNumber={this.state.pageNumber} handlePageChange={this.handlePageChange} />
                                <CountDisplay start={(this.state.pageNumber - 1) * this.state.pageContent + 1} end={this.state.pageNumber * this.state.pageContent} total={this.state.totalCount} />
                            </div>

                            <div>
                                <Table fixed selectable className="mailers-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <InputBox
                                                    type='checkbox'
                                                    onChange={this.handleBulkCheck} />
                                                Job Id
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Job Title</Table.HeaderCell>
                                            <Table.HeaderCell>Recruiter Info</Table.HeaderCell>
                                            <Table.HeaderCell>Transfer Job</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.jobs.map((item, i) => {
                                                return (
                                                    <Table.Row key={item.jobId}>
                                                        <Table.Cell>
                                                            {
                                                                item.transferStatus != 1 ? <InputBox
                                                                    type='checkbox'
                                                                    name={item.jobId}
                                                                    value={this.state.transferStatus[item.jobId]}
                                                                    checked={this.state.transferStatus[item.jobId] ? true : false}
                                                                    onChange={this.handleCheckboxChange} /> : null
                                                            }
                                                            <b> {item.jobId} {!item.state ? ' [Unpublished] ' : ''} </b>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <a href={getJobUrl(item.cleanTitle, item.jobId)} target="_blank">{item.title}</a>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {item.recruiterId}<br />
                                                            {item.recruiterName}<br />
                                                            {item.recruiterEmail}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {
                                                                item.transferStatus == 1 ?
                                                                    'Request Submitted' : <div className="form">
                                                                        <InputBox
                                                                            placeholder="Enter Recruiter ID/Email"
                                                                            name={`transferString_${item.jobId}`}
                                                                            value={this.state[`transferString_${item.jobId}`]}
                                                                            onChange={this.onChange}
                                                                            onKeyPress={this.handleKeyPress}
                                                                        />
                                                                        <Button
                                                                            className="form-btn"
                                                                            skin="dark"
                                                                            disabled={this.state.loading[item.jobId] || false}
                                                                            text="Submit"
                                                                            loading={this.state.loading[item.jobId]}
                                                                            clickButton={() => this.onSubmitTransfer(item)}
                                                                        />
                                                                    </div>
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>

                            </div>
                        </div> : null
                    }
                    {
                        this.state.isLoading ? <Loader /> : null
                    }
                </div>
            </div>
        );
    }
}

export default TransferJob;
