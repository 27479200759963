import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'semantic-ui-react';

import './TableItem.scss';

const SOCIALS_MAP = {
    facebook: 'facebook',
    linkedin: 'linkedin',
    twitter: 'twitter'
};

const TableItem = props => {
    const recruiter = props.recruiter;

    const recnameSmall = recruiter.recname.split(' ').map(part => part.toLowerCase()).join('-')

    const actions = (
        <div className="item__actions">
            <a href={`/recruiter/recruiter_profile_mgtedit?recid=${recruiter.id}`} target="_blank">Edit Profile</a>
            <a href={`/?recruiterId=${recruiter.id}`} target="_blank">View Post Jobs</a>
            <a href={`${process.env.REACT_APP_CLIENT_URL}/r/${recruiter.id}-${recnameSmall}`} target="_blank">View Profile</a>
        </div>
    );

    const handleRemoveLink = (type) => {
        const userChoice = confirm(`Do you want to delete ${type[0].toUpperCase() + type.substring(1)} details? `);

        if (userChoice) {
            props.handleDisconnectSocials({
                recruiterId: recruiter.id,
                disconnectFacebook: type === SOCIALS_MAP.facebook ? true : null,
                disconnectLinkedin: type === SOCIALS_MAP.linkedin ? true : null,
                disconnectTwitter: type === SOCIALS_MAP.twitter ? true : null,
            })
        }
    }

    const socialActions = [];

    // update actions will be rejected at the backend anyway
    // so hide the option from profile
    if (recruiter.status == 1) {
        if (recruiter.lurl != '' || recruiter.linkedinid != '' || recruiter.linkedinoauth_token != '' || recruiter.linkedinoauth_token_secret != '') {
            socialActions.push(<span onClick={() => handleRemoveLink(SOCIALS_MAP.linkedin)}>Remove LinkedIn</span>);
        }
        if (recruiter.facebookid != 0 || recruiter.furl != '') {
            socialActions.push(<span onClick={() => handleRemoveLink(SOCIALS_MAP.facebook)}>Remove Facebook</span>);
        }
        if (recruiter.twitter_token != '' || recruiter.twitter_secret != '' || recruiter.turl != '') {
            socialActions.push(<span onClick={() => handleRemoveLink(SOCIALS_MAP.twitter)}>Remove Twitter</span>);
        }
    }

    return (
        <Table.Row className="item__container">
            <Table.Cell width='one' verticalAlign='top'>{recruiter.id}</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>{recruiter.recname}</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>---</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>{recruiter.designation}</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>{recruiter.organisation}</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>{actions}</Table.Cell>
            <Table.Cell width='one' verticalAlign='top'>{socialActions}</Table.Cell>
        </Table.Row>
    )
}

TableItem.propTypes = {
    recruiter: PropTypes,
    handleDisconnectSocials: PropTypes.func,
}

export default TableItem;