import axios from '../utils/axios';

export default function getSeekerProfile(adminId, params) {
    return axios.get(`/admin/${adminId}/jobseeker-admin/v2/admin-recruiter/profile`, { params })
        .then(response => {
            return response.data.data.details;
        })
        .catch(err => {
            console.log(err);
            return null;
        });
}