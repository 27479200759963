const industryIIMJobs = [
  {
      'text': 'Accounting / Finance / Audit',
      'key': 24,
      'value': '24'
  },
  {
      'text': 'Advertising / PR / MR / Events',
      'key': 25,
      'value': '25'
  },
  {
      'text': 'Agriculture / Dairy',
      'key': 34,
      'value': '34'
  },
  {
      'text': 'Animation',
      'key': 36,
      'value': '36'
  },
  {
      'text': 'Architecture / Interior Design',
      'key': 37,
      'value': '37'
  },
  {
      'text': 'Auto / Auto Ancillary',
      'key': 1,
      'value': '1'
  },
  {
      'text': 'Aviation / Aerospace Firm',
      'key': 38,
      'value': '38'
  },
  {
      'text': 'Banking / Financial Services / Broking',
      'key': 2,
      'value': '2'
  },
  {
      'text': 'BPO / ITES',
      'key': 3,
      'value': '3'
  },
  {
      'text': 'Brewery / Distillery',
      'key': 39,
      'value': '39'
  },
  {
      'text': 'Ceramics / Sanitary ware',
      'key': 42,
      'value': '42'
  },
  {
      'text': 'Chemicals / Petrochemical / Plastic / Rubber',
      'key': 26,
      'value': '26'
  },
  {
      'text': 'Construction / Engineering / Cement / Metals',
      'key': 5,
      'value': '5'
  },
  {
      'text': 'Consulting',
      'key': 4,
      'value': '4'
  },
  {
      'text': 'Consumer Durables',
      'key': 40,
      'value': '40'
  },
  {
      'text': 'Courier / Transportation / Freight',
      'key': 41,
      'value': '41'
  },
  {
      'text': 'Defence / Government',
      'key': 43,
      'value': '43'
  },
  {
      'text': 'Education / Teaching / Training',
      'key': 6,
      'value': '6'
  },
  {
      'text': 'Electricals / Switchgears',
      'key': 44,
      'value': '44'
  },
  {
      'text': 'Export / Import',
      'key': 45,
      'value': '45'
  },
  {
      'text': 'Facility Management',
      'key': 27,
      'value': '27'
  },
  {
      'text': 'Fertilizers / Pesticides',
      'key': 46,
      'value': '46'
  },
  {
      'text': 'FMCG / Foods / Beverage',
      'key': 7,
      'value': '7'
  },
  {
      'text': 'Food Processing',
      'key': 47,
      'value': '47'
  },
  {
      'text': 'Gems and Jewellery',
      'key': 48,
      'value': '48'
  },
  {
      'text': 'Glass',
      'key': 49,
      'value': '49'
  },
  {
      'text': 'Healthcare / Medical / Hospital',
      'key': 8,
      'value': '8'
  },
  {
      'text': 'Heating Ventilation Air Conditioning',
      'key': 50,
      'value': '50'
  },
  {
      'text': 'Hotels / Restaurants / Airlines',
      'key': 51,
      'value': '51'
  },
  {
      'text': 'Industrial Products / Heavy Machinery',
      'key': 10,
      'value': '10'
  },
  {
      'text': 'Infrastructure / Oil and Gas / Power / Energy',
      'key': 11,
      'value': '11'
  },
  {
      'text': 'Insurance',
      'key': 12,
      'value': '12'
  },
  {
      'text': 'Internet / Online / eCommerce',
      'key': '13',
      'value': '13'
  },
  {
      'text': 'IT',
      'key': 14,
      'value': '14'
  },
  {
      'text': 'IT-Software / Software Services',
      'key': 52,
      'value': '52'
  },
  {
      'text': 'IT-Hardware and Networking',
      'key': 53,
      'value': '53'
  },
  {
      'text': 'KPO / Research / Analytics',
      'key': 15,
      'value': '15'
  },
  {
      'text': 'Legal',
      'key': 16,
      'value': '16'
  },
  {
      'text': 'Logistics / SCM / Freight / Shipping',
      'key': 35,
      'value': '35'
  },
  {
      'text': 'Manufacturing',
      'key': 17,
      'value': '17'
  },
  {
      'text': 'Media / Dotcom / Entertainment',
      'key': 18,
      'value': '18'
  },
  {
      'text': 'Medical / Healthcare / Hospital',
      'key': 55,
      'value': '55'
  },
  {
      'text': 'Mining / Steel / Shipping',
      'key': 19,
      'value': '19'
  },
  {
      'text': 'NGO / Social Service',
      'key': 20,
      'value': '20'
  },
  {
      'text': 'Oil and Gas / Power / Infrastructure / Energy',
      'key': 56,
      'value': '56'
  },
  {
      'text': 'Office Equipment / Automation',
      'key': 57,
      'value': '57'
  },
  {
      'text': 'Paper',
      'key': 58,
      'value': '58'
  },
  {
      'text': 'Pharma / Biotech / Clinical Research',
      'key': 28,
      'value': '28'
  },
  {
      'text': 'Printing / Packaging',
      'key': 59,
      'value': '59'
  },
  {
      'text': 'Publishing',
      'key': 60,
      'value': '60'
  },
  {
      'text': 'Real Estate / Property',
      'key': 29,
      'value': '29'
  },
  {
      'text': 'Recruitment / Staffing',
      'key': 30,
      'value': '30'
  },
  {
      'text': 'Retail',
      'key': 22,
      'value': '22'
  },
  {
      'text': 'Security / Law Enforcement',
      'key': 61,
      'value': '61'
  },
  {
      'text': 'Semiconductors / Electronics',
      'key': 31,
      'value': '31'
  },
  {
      'text': 'Shipping / Marine',
      'key': 62,
      'value': '62'
  },
  {
      'text': 'Steel',
      'key': 63,
      'value': '63'
  },
  {
      'text': 'Strategy / Management Consulting Firms',
      'key': 64,
      'value': '64'
  },
  {
      'text': 'Telecom / ISP',
      'key': 23,
      'value': '23'
  },
  {
      'text': 'Textile / Garments / Accessories / Fashion',
      'key': 32,
      'value': '32'
  },
  {
      'text': 'Travel / Hospitality',
      'key': 9,
      'value': '9'
  },
  {
      'text': 'Tyres',
      'key': 65,
      'value': '65'
  },
  {
      'text': 'Water Treatment / Waste Management',
      'key': 66,
      'value': '66'
  },
  {
      'text': 'Wellness / Fitness / Sports',
      'key': 67,
      'value': '67'
  },
  {
      'text': 'Other',
      'key': 33,
      'value': '33'
  }
].sort(function (a, b) {
  return a[ 'text' ].localeCompare(b.text)
});

const industryUpdazz = [{
key: 1,
text: "FMCG/Food & Beverages",
value: "1"
}, {
key: 2,
text: "Banking/Financial Services/Broking",
value: "2"
}, {
key: 3,
text: "Pharma/Biotech/Clinical Research",
value: "3"
}, {
key: 4,
text: "Internet/E-commerce",
value: "4"
}, {
key: 5,
text: "BPO/IteS",
value: "5"
}, {
key: 6,
text: "Retail",
value: "6"
}, {
key: 7,
text: "Industrial Product /Heavy Machinery",
value: "7"
}, {
key: 8,
text: "Auto/Auto Ancillary",
value: "8"
}, {
key: 9,
text: "Accounting/Finance",
value: "9"
}, {
key: 10,
text: "Others",
value: "10"
}, {
key: 11,
text: "Agriculture/Dairy",
value: "11"
}, {
key: 12,
text: "Animation",
value: "12"
}, {
key: 13,
text: "Architecture/Interior Designing",
value: "13"
}, {
key: 14,
text: "Aviation/Aerospace firm",
value: "14"
}, {
key: 15,
text: "Brewery/Distillery",
value: "15"
}, {
key: 16,
text: "Broadcasting",
value: "16"
}, {
key: 17,
text: "Ceramics/Sanitary ware",
value: "17"
}, {
key: 18,
text: "Chemicals/Petro-chemicals/Plastics/Rubber",
value: "18"
}, {
key: 19,
text: "Construction/Engineering/Cement/ Metals",
value: "19"
}, {
key: 20,
text: "Consumer Durables",
value: "20"
}, {
key: 21,
text: "Courier/Transportation/Freight",
value: "21"
}, {
key: 22,
text: "Defence",
value: "22"
}, {
key: 23,
text: "Education",
value: "23"
}, {
key: 24,
text: "Electrical/Switchgear",
value: "24"
}, {
key: 25,
text: "Export/Import",
value: "25"
}, {
key: 26,
text: "Facility Managemnt",
value: "26"
}, {
key: 27,
text: "Fertilizers/ Pesticides",
value: "27"
}, {
key: 28,
text: "Food Processing",
value: "28"
}, {
key: 29,
text: "Gems and Jewellery",
value: "29"
}, {
key: 30,
text: "Glass",
value: "30"
}, {
key: 31,
text: "Heat Ventillation/Air Conditioning",
value: "31"
}, {
key: 32,
text: "Hotels/Restaurants/Airlines/Travel",
value: "32"
}, {
key: 33,
text: "Insurance",
value: "33"
}, {
key: 34,
text: "IT - Harware and Networking",
value: "34"
}, {
key: 35,
text: "IT - Software/Software Services",
value: "35"
}, {
key: 36,
text: "KPO/Research Analytics",
value: "36"
}, {
key: 37,
text: "Leather",
value: "37"
}, {
key: 38,
text: "Legal",
value: "38"
}, {
key: 39,
text: "Media/DotCom/Entertainment",
value: "39"
}, {
key: 40,
text: "Medical/Healthcare/Hospital",
value: "40"
}, {
key: 41,
text: "Medical Devices/Equipments",
value: "41"
}, {
key: 42,
text: "Mining/Metals",
value: "42"
}, {
key: 43,
text: "NGO/Social Services",
value: "43"
}, {
key: 44,
text: "Office Equipments/Automation",
value: "44"
}, {
key: 45,
text: "Oil &amp; Gas/Power/Infrastructure/Energy",
value: "45"
}, {
key: 46,
text: "Paper",
value: "46"
}, {
key: 47,
text: "Printing/Packaging",
value: "47"
}, {
key: 48,
text: "Publishing",
value: "48"
}, {
key: 49,
text: "Real Estate/Property",
value: "49"
}, {
key: 50,
text: "Recruitment",
value: "50"
}, {
key: 51,
text: "Security/Law Enforcement",
value: "51"
}, {
key: 52,
text: "Semiconductors/Electronics",
value: "52"
}, {
key: 53,
text: "Shipping/Marine",
value: "53"
}, {
key: 54,
text: "Strategy/Management Consulting Firms",
value: "54"
}, {
key: 55,
text: "Sugar",
value: "55"
}, {
key: 56,
text: "Telecom/ISP",
value: "56"
}, {
key: 57,
text: "Textiles/Garments/Accessories",
value: "57"
}, {
key: 58,
text: "Tyres",
value: "58"
}, {
key: 59,
text: "Wellness/Fitness/Sports/Beauty",
value: "59"
}, {
key: 60,
text: "Manufacturing",
value: "60"
}, {
key: 61,
text: "Advertising/PR/MR/Events",
value: "61"
}].sort(function (a, b) {
  return a[ 'text' ].localeCompare(b.text)
});


const getIndustry = () => {
  let industry;
  switch(process.env.REACT_APP_PLATFORM){
      case 'iimjobs':
          industry = industryIIMJobs;
          break;
      case 'updazz' :
          industry =  industryUpdazz;
          break;
      default:
          industry =  []
      
  }

  return industry;
}

export const industry = getIndustry();

export const industryLabel = industry.map(item => {
  item.label = item.text;
  return item;
});
