import React, { useState, useEffect } from "react";
import user from "../../../store/user";
import fetchRecruiterCompanies from "../../../services/fetchRecruiterCompanies";
import addRecruiterCompany from "../../../services/addRecruiterCompany";
import updateRecruiterCompany from "../../../services/updateRecruiterCompany";


import "./editRecruiterCompany.scss";
import Button from "../../../components/Button";
import Heading from "../../../components/Heading";
import { toast, ToastContainer } from "react-toastify";
import InputBox from "../../../components/InputBox";

const EditRecruiterCompany = (props) => {

    let timer;
    const id = props.match.params.id;
    const [companyName, setCompanyName] = useState('')
    const [validation, setValidation] = useState({})
    const [submitApiCall, setSubmitApiCall] = useState(false)
    const [admin, setAdmin] = useState({
      adminId: null,
  })

    useEffect(() => {

      const fetchProfile = async () => {
        return await user.getProfile()
      }
      fetchProfile().then(userData => {
          if (userData.adminType == 2 || userData.adminType == 3) {
              setAdmin({
                  adminId: userData.id,
              })

              if(id) {
                getRecruiterCompanies(userData.id, 1, {id})
              }
      
                    
          } else {
              window.location.href = process.env.REACT_APP_LOGIN_URL;
          }
      });
        
        return () => clearTimeout(timer)

    }, [])

    const getRecruiterCompanies = async (adminId, page, filters = {}) => {
        try {
            const res = await fetchRecruiterCompanies(adminId, { pageNumber: page, pageContent: 1, ...filters })
            setCompanyName(res.data.data[0].name)
        }
        catch (err) {
            alert(err.message)
            console.log(err);
        }
    }

  
    const onCompanyListBtnClick = () => {
      props.history.push('/recruiterCompanyList')
    }


    const addUpdateRecruiterCompany = async () => {

      const adminId = admin.adminId;
      let res;

      if(id) {
        res = await updateRecruiterCompany(adminId, id, {
          name: companyName
        })

        toast.success("Company successfully updated.")

      }
      else {
        res = await addRecruiterCompany(adminId, {
          name: companyName
        })

        toast.success("Company successfully added.")
      }

      return res;
    }
   

    const onSubmit = async (e) => {
        e.preventDefault();

        const companyNameLen = companyName.trim().length

        if(companyNameLen) {
          setValidation({})
          try {
            setSubmitApiCall(true)

            const res = await addUpdateRecruiterCompany()
            setCompanyName('')

            if(id) {
              timer = setTimeout(() => props.history.push('/recruiterCompanyList'),2000)
            }


            setSubmitApiCall(false)

          }
          catch(e) {
            console.log(e);
            setSubmitApiCall(false)
            if(e.response.status == 409) {
              return toast.error("Company Already Exist")
            }
            toast.error("Something went wrong")
          }
        }
        else {
          setValidation({
            nameReq: true
          })
        }

    }

    return (
        <div className="edit-recruiter-company-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="edit-recruiter-pageHeading">
                <Heading text={`${id ? 'Edit Company' : 'Add Company'}`} />

                <Button skin="dark" text="Company List" clickButton={onCompanyListBtnClick} />

            </div>
            <hr />
            <div className="edit-recruiter-form-container">
              <form>
                <div className="row section details-section">
                  <div className="form-group companyName">
                    <label > Company Name *</label>
                    <div className="valueBox">
                      <InputBox
                        type="text"
                        value={companyName}
                        placeholder="Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      {validation.nameReq ? <p className='validatorError'> Please enter company name </p> : null}
                    </div>
                  </div>
                  <div className="actions">
                  <Button
                    skin="dark"
                    disabled={submitApiCall}
                    loading={submitApiCall}
                    text={id ? "Update" : "Add"}
                    clickButton={onSubmit}
                  />
                </div>
                </div>

                
              </form>
            </div>
        </div>
    );
};

export default EditRecruiterCompany;
