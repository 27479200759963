import React, { useState, useEffect } from 'react';
import user from '../../../store/user';
import fetchClientList from '../../../services/fetchClientList';
import updateClientEngagementStatus from '../../../services/updateClientEngagementStatus';
import './clientEngagementReportsheet.scss';
import { Header, Label, Modal, Table } from 'semantic-ui-react';
import Loader from '../../../components/Loader';
import CountDisplay from '../../../components/CountDisplay';
import ButtonContainer from '../../../components/ButtonContainer';
import LinkButtons from '../../../components/LinkButtons';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { clientEngagementFilters, statusMap, clientEngagementStatus } from '../../../models/clientEngagementFilters';
import fetchClientTestimonials from '../../../services/fetchClientTestimonials';
import fetchClientNotes from '../../../services/fetchClientNotes';
import addCETestimonial from '../../../services/addCETestimonial';
import addCENote from '../../../services/addCENote';
import updateCENote from '../../../services/updateCENote';
import updateCETestimonial from '../../../services/updateCETestimonial';
import deleteCENote from '../../../services/deleteCENote';
import deleteCETestimonial from '../../../services/deleteCETestimonial';
import InputBox from '../../../components/InputBox';
import validator from 'validator';
import { getQueryParams, isEmptyObject } from "../../../utils/utilFunctions";


const PAGE_CONTENT = 10;

const validationMsg = {
  testimonialNameReq: 'Please enter name',
  testimonialOrgReq: 'Please enter company name',
  testimonialEmailReq: 'Please enter valid email id',
  testimonialPositionReq: 'This field is required.',
  testimonialReq: 'Please Enter Testimonial',
  noteReq: 'Please Enter Note',
  testimonialPositionNotInt: 'Decimal not allowed'
};

const ClientEngagementReportsheet = (props) => {

  const [content, setContent] = useState({
    list: [],
    count: 0,
    pageNumber: 1
  })
  const [testimonials, setTestimonials] = useState([]);
  const [notes, setNotes] = useState([]);

  const [testimonialModalStatus, setTestimonialModalStatus] = useState(false);
  const [notesModalStatus, setNotesModalStatus] = useState(false);

  const [testimonialAddEditStatus, setTestimonialAddEditStatus] = useState(false);
  const [notesAddEditStatus, setNotesAddEditStatus] = useState(false);

  const [newTestimonial, setNewTestimonial] = useState({
      old: false,
      name: '',
      organisation: '',
      email: '',
      positionClose: '',
      testimonial: ''
  })

  const [newNote, setNewNote] = useState({
    old: false,
    note: ''
  })

  const [testimonialValidations, setTestimonialValidations] = useState({});
  const [notesValidations, setNotesValidations] = useState({});

  const [admin, setAdmin] = useState({
    adminId: null,
  })
  const [fetchApiCall, setFetchApiCall] = useState(true)

  const [filter, setFilter] = useState('')

  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id,
        })
        const queryObj = getQueryParams(props.location.search);
        setContent(prev => ({
          ...prev,
          pageNumber: queryObj.pageNumber
        }))
        setFilter(queryObj.status ? queryObj.status : '');
        const filterObj = {};
        if(queryObj.status){
          filterObj['status'] = queryObj.status;
        }
        const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
        getClientList(userData.id, pgNo, filterObj);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])

  const getClientList = async (adminId, pageNumber, filters = {}) => {
    setFetchApiCall(true);
    try {
      routeRequest(pageNumber, filters)
      const res = await fetchClientList(adminId, { pageNumber, pageContent: PAGE_CONTENT, ...filters })
      setContent({
        pageNumber: pageNumber,
        list: res.data.data,
        count: res.data.totalCount
      })
      setFetchApiCall(false)
    }
    catch (err) {
      console.log(err);
      setFetchApiCall(false)
    }
  }


  const handlePageChange = async(n) => {
      const filterObj = {};
      if(filter){
        filterObj['status'] = filter ;
      }
      await getClientList(admin.adminId, n, filterObj)
  }

  const onFilterChange = async (filterId) => {
    setFilter(filterId)
      const filterObj = {}
      if(filterId) {
        filterObj['status'] = filterId;
      }
      await getClientList(admin.adminId, 1, filterObj)
  }

  const onStatusChange = async (e, recId, status, id) => {
    e.preventDefault();

    if(status == 5 && id) {
        props.history.push(`/editClient/${id}`)
        return;
    }
    try{
      await updateClientEngagementStatus(admin.adminId, {
        id,
        status
      })
      toast.success('Status updated successfully')
      const filterObj = {};
      if(filter){
        filterObj['status'] = filter ;
      }
      await getClientList(admin.adminId, content.pageNumber, filterObj)
    }
    catch(e) {
      console.log(e);
      toast.error('Something went wrong')
    }
  }

  const onViewTestimonials = async (ceId) => {
    const res = await fetchClientTestimonials(admin.adminId, ceId);
    setTestimonials(res.data);
    setTestimonialModalStatus(true);
  }

  const onViewNotes = async (ceId) => {
    const res = await fetchClientNotes(admin.adminId,ceId);
    setNotes(res.data);
    setNotesModalStatus(true);
  }

  const onTestimonialChange= (e) => {
      const name = e.target.name;
      const value= e.target.value;

      setNewTestimonial(prev => ({
        ...prev,
        [name]: value
      }))
  }

  const onNoteChange= (e) => {
    const name = e.target.name;
    const value= e.target.value;

    setNewNote(prev => ({
      ...prev,
      [name]: value
    }))
}

  const onAddTestimonial = async () => {
      if(validateTestimonial()) {
        try {
          const data = {
            name: newTestimonial.name,
            organisation: newTestimonial.organisation,
            email: newTestimonial.email,
            positionClose: newTestimonial.positionClose,
            testimonial: newTestimonial.testimonial,
            ceId: newTestimonial.ceId
          }
          if(newTestimonial.id) {
            await updateCETestimonial(admin.adminId, newTestimonial.id, data);
            toast.success('Testimonial successfully updated');
          }
          else {
            data['status'] = 1;
            await addCETestimonial(admin.adminId, data);
            toast.success('Testimonial successfully added');

          }


          const res = await fetchClientTestimonials(admin.adminId,newTestimonial.ceId);
          setTestimonials(res.data);
          setTestimonialAddEditStatus(false);
          

        }
        catch(e) {
          console.log(e);
          if(e.message.includes('404')) {
            return toast.error('Please enter valid email id');
          }

          toast.error('Something went wrong')
        }
      }
  }
  

  const onAddNote = async () => {
    if(validateNote()) {
      try {
        const data = {
          notes: newNote.note,
          ceId: newNote.ceId
        }
        if(newNote.id) {
          await updateCENote(admin.adminId, newNote.id, data);
          toast.success('Note successfully updated');
        }
        else {
          data['status'] = 1;
          await addCENote(admin.adminId, data);
          toast.success('Note successfully added');

        }

        const res = await fetchClientNotes(admin.adminId,newNote.ceId);
        setNotes(res.data);
        setNotesAddEditStatus(false);

      }
      catch(e) {
        console.log(e);
        if(e.message.includes('404')) {
          return toast.error('Please enter valid email id');
        }

        toast.error('Something went wrong')
      }
    }
}

  const checkEmpty = (val) => {
    if (!val) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  const validateTestimonial = () => {
    const validations = {
      testimonialNameReq: checkEmpty(newTestimonial.name),
      testimonialOrgReq: checkEmpty(newTestimonial.organisation),
      testimonialEmailReq: checkEmpty(newTestimonial.email),
      testimonialPositionReq: checkEmpty(newTestimonial.positionClose),
      testimonialReq: checkEmpty(newTestimonial.testimonial),
      testimonialPositionNotInt: !Number.isInteger(+newTestimonial.positionClose),

     }
     setTestimonialValidations({...validations})

     for (let key in validations) {
      if (validations[key]) {
        // scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;
  }

  const validateNote = () => {
    const validations = {
      noteReq: checkEmpty(newNote.note)
     }
     setNotesValidations({...validations})

     for (let key in validations) {
      if (validations[key]) {
        // scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;
  }

  const handleTestimonialModalClose = () => {
    setTestimonialValidations({});
    setTestimonialAddEditStatus(false);
    setNewTestimonial({});
  }

  const handleNoteModalClose = () => {
    setNotesValidations({});
    setNotesAddEditStatus(false);
    setNewNote({});
  }

  const onEditTestimonial = (testimonial) => {
      setNewTestimonial({
        old: true,
        id: testimonial.id,
        ceId: testimonial.ce_id,
        name: testimonial.rec_name,
        email: testimonial.rec_email,
        organisation: testimonial.company_name,
        positionClose: testimonial.position_close,
        testimonial: testimonial.testimonail
      })
      setTestimonialAddEditStatus(true)
  }

  const onEditNote = (note) => {
      setNewNote({
        old: true,
        id: note.id,
        ceId: note.ce_id,
        note: note.notes
      })
      setNotesAddEditStatus(true)
  }

  const onDeleteTestimonial = async (id, ceId) => {

    try {

      await deleteCETestimonial(admin.adminId, id);
      const res = await fetchClientTestimonials(admin.adminId, ceId);
      setTestimonials(res.data)

      toast.success('Testimonial successfully deleted')

    }catch(e) {
      console.log(e);
      toast.error('Something went wrong');
    }
  }

  const onDeleteNote = async (id, ceId) => {

    try {

      await deleteCENote(admin.adminId, id);
      const res = await fetchClientNotes(admin.adminId, ceId);
      setNotes(res.data);

      
      toast.success('Note successfully deleted')

    }catch(e) {
      console.log(e);
      toast.error('Something went wrong');
    }
  }

  const routeRequest = (pageNumber, filters) => {
    let array = [];

    array.push(`pageNumber=${pageNumber}`);
    if(filters.status){
      array.push(`status=${filters.status}`)
    }
    array = array.join("&");
    props.history.push("?" + array);
};

const openAddNote = (ceId) => {
    setNewNote({ceId});
    setNotesAddEditStatus(true);
}

const openAddTestimonial= (ceId) => {
  setNewTestimonial({ceId});
  setTestimonialAddEditStatus(true);
}

  return (
    <div className="client-engagement-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="client-engagement-list-heading">
        <Heading text="Client Engagement Reportsheet" />
        <Button  skin='dark' className="btn_add_client" text="Add Client" clickButton={() => { props.history.push('/addClient') }} />
        <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/display_client_engagement_reportsheet.php`} target='_blank'>
            <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
        </a>
        <ButtonContainer heading='Filter By: '>
              {
                  clientEngagementFilters.map((filter, i) => (
                      <li key={filter.id}> <LinkButtons name={filter.name} id={filter.id} sortClick={() => onFilterChange(filter.id)} /> </li>
                  ))
              }
          </ButtonContainer>
      </div>


      <div className="row text-center">

      <Pagination
          totalItemsCount={content.count}
          content={PAGE_CONTENT}
          pageNumber={content.pageNumber}
          handlePageChange={(n) => handlePageChange(n)} />
      <CountDisplay start={(content.pageNumber - 1) * PAGE_CONTENT + 1} end={content.pageNumber * PAGE_CONTENT} total={content.count} />

        {fetchApiCall ? (
          <Loader />
        ) : (
          content.list.length ? (
            <div className="content">
              <Modal className='ce-modal' open={testimonialModalStatus} onClose={() => setTestimonialModalStatus(false)} size='large'>
                  <Header content="Testimonials" />
                  <Modal.Content scrolling>
                      { testimonials.length > 0 ? (
                          <Table
                          fixed
                          selectable
                          className="mailers-table"
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={4}>
                                Name
                              </Table.HeaderCell>
                              <Table.HeaderCell width={4}>
                                Email id
                              </Table.HeaderCell>
                              <Table.HeaderCell width={3}>
                                Organisation
                              </Table.HeaderCell>
                              <Table.HeaderCell width={3}>
                                No. of position
                              </Table.HeaderCell>
                              <Table.HeaderCell width={8}>
                                Testimonial Note
                              </Table.HeaderCell>
                              <Table.HeaderCell width={6}>
                                Action
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
          
                          <Table.Body>
                            {testimonials.map((item, i) => {
                              return (
                                <Table.Row>
                                  <Table.Cell>
                                    {item.rec_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.rec_email}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.company_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.position_close}</Table.Cell>
                                  <Table.Cell>
                                    {item.testimonail}</Table.Cell>
                                  <Table.Cell>
                                      <div>
                                        <Button  skin='light' className="btn_view_testimonial" text="Edit" clickButton={() => onEditTestimonial(item)} />
                                        <Button  skin='light' className="btn_add_notes" text="Delete" clickButton={() => onDeleteTestimonial(item.id, item.ce_id)} />
                                      </div>
                                  
                                  </Table.Cell>
          
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      ) : <div className='not-found'>No testimonials found</div>
                      }
                  </Modal.Content>
                  <Modal.Actions>
                      <Button clickButton={() => setTestimonialModalStatus(false)} skin="light" text="Close"/>
                  </Modal.Actions>
              </Modal>

              <Modal className='ce-modal' open={notesModalStatus} onClose={() => setNotesModalStatus(false)} size='large'>
                  <Header content="Notes" />
                  <Modal.Content scrolling>
                      {
                          notes.length > 0 ? (
                            <Table
                            fixed
                            selectable
                            className="mailers-table"
                          >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width={4}>
                                  Created Date
                                </Table.HeaderCell>
                                <Table.HeaderCell width={12}>
                                  Note
                                </Table.HeaderCell>
                                <Table.HeaderCell width={6}>
                                  Action
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
            
                            <Table.Body>
                              {notes.map((item, i) => {
                                return (
                                  <Table.Row>
                                    <Table.Cell>
                                      {moment(item.created_on).format('YYYY-MM-DD')}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {item.notes}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div>
                                          <Button  skin='light' className="btn_view_notes" text="Edit" clickButton={() => onEditNote(item)} />
                                          <Button  skin='light' className="btn_delete_notes" text="Delete" clickButton={() => onDeleteNote(item.id, item.ce_id)} />
                                        </div>
                                    </Table.Cell>
            
                                  </Table.Row>
                                );
                              })}
                            </Table.Body>
                          </Table>
                          ) : <div className='not-found'>No notes found</div>
                          
                      }
                  </Modal.Content>
                  <Modal.Actions>
                      <Button clickButton={() => setNotesModalStatus(false)} skin="light" text="Close"/>
                  </Modal.Actions>
              </Modal>

              <Modal className='ce-modal' open={testimonialAddEditStatus} onClose={handleTestimonialModalClose} size='large'>
                  <Header content="Add Testimonial"/>
                  <Modal.Content>
                      <form>
                        <div className='row section details-section'>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> Name *</label>
                            <div className="col-sm-6 left valueBox">
                              <InputBox
                                type="text"
                                name="name"
                                value={newTestimonial.name}
                                placeholder="Name"
                                onChange={onTestimonialChange}
                              />
                              {testimonialValidations.testimonialNameReq ? <p className='validatorError'> {validationMsg.testimonialNameReq} </p> : null}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> Organisation *</label>
                            <div className="col-sm-6 left valueBox">
                              <InputBox
                                type="text"
                                name="organisation"
                                value={newTestimonial.organisation}
                                placeholder="Organisation"
                                onChange={onTestimonialChange}
                              />
                              {testimonialValidations.testimonialOrgReq ? <p className='validatorError'> {validationMsg.testimonialOrgReq} </p> : null}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> Email *</label>
                            <div className="col-sm-6 left valueBox">
                              <InputBox
                                type="text"
                                name="email"
                                value={newTestimonial.email}
                                placeholder="Email"
                                onChange={onTestimonialChange}
                              />
                              {testimonialValidations.testimonialEmailReq ? <p className='validatorError'> {validationMsg.testimonialEmailReq} </p> : null}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> No. of position closed *</label>
                            <div className="col-sm-6 left valueBox">
                              <InputBox
                                type="number"
                                name="positionClose"
                                value={newTestimonial.positionClose}
                                placeholder="No. of position closed"
                                onChange={onTestimonialChange}
                              />
                              {testimonialValidations.testimonialPositionReq ? <p className='validatorError'> {validationMsg.testimonialPositionReq} </p> : null}
                              {testimonialValidations.testimonialPositionNotInt ? <p className='validatorError'> {validationMsg.testimonialPositionNotInt} </p> : null}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> Testimonial *</label>
                            <div className="col-sm-6 left valueBox">
                              <textarea placeholder='Testimonial' className='inputBox new-ce-testimonial' name='testimonial' value={newTestimonial.testimonial} rows='5' maxLength='500' onChange={onTestimonialChange}>
                              </textarea>
                              {testimonialValidations.testimonialReq ? <p className='validatorError'> {validationMsg.testimonialReq} </p> : null}
                            </div>
                          </div>
                        </div>
                        
                      </form>
                  </Modal.Content>

                  <Modal.Actions>
                      <Button clickButton={onAddTestimonial} skin="dark" text={newTestimonial.old ? 'Update' : 'Add'}/>
                      <Button clickButton={handleTestimonialModalClose} skin="light" text="Close"/>
                  </Modal.Actions>
              </Modal>

              <Modal className='ce-modal' open={notesAddEditStatus} onClose={handleNoteModalClose} size='large'>
                  <Header content="Add Note"/>
                  <Modal.Content>
                      <form>
                        <div className='row section details-section'>
                          <div className='form-group'>
                            <label className="col-sm-2 left"> Note *</label>
                            <div className="col-sm-6 left valueBox">
                              <textarea placeholder='Note' className='inputBox new-ce-note' name='note' value={newNote.note} rows='5' maxLength='500' onChange={onNoteChange}>
                              </textarea>
                              {notesValidations.noteReq ? <p className='validatorError'> {validationMsg.noteReq} </p> : null}
                            </div>
                          </div>
                        </div>
                        
                      </form>
                  </Modal.Content>

                  <Modal.Actions>
                      <Button clickButton={onAddNote} skin="dark" text={newNote.old ? 'Update' : 'Add'}/>
                      <Button clickButton={handleNoteModalClose} skin="light" text="Close"/>
                  </Modal.Actions>
              </Modal>

              <Table
                fixed
                selectable
                className="mailers-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6}>
                      Manager Email id
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Domain
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Plan Start Date
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Plan End Date
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Premium Posting
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      No of Loggedin
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      More Info
                    </Table.HeaderCell>
                    <Table.HeaderCell width={8}>
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {content.list.map((item, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/mgr_team.php?recid=${item.mgr_rec_id}`}>{item.mgr_email}</a>
                        </Table.Cell>
                        <Table.Cell>
                          {item.domain}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(item.plan_start_date).format('YYYY-MM-DD')}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(item.plan_end_date).format('YYYY-MM-DD')}
                        </Table.Cell>
                        <Table.Cell>
                          {item.premium_posting}</Table.Cell>
                        <Table.Cell>
                          {item.no_of_login}</Table.Cell>
                        <Table.Cell>
                          {statusMap[item.status]}</Table.Cell>
                          <Table.Cell>
                            <ul>
                              <li><b>Sales Manager: </b> {item.sales_manager}</li>
                              <li><b>Location: </b> {item.location}</li>
                              <li><b>Ticket Size: </b> {item.ticket_size}</li>
                              <li><b>Branding: </b> {item.branding}</li>
                              <li><b>POC: </b> {item.poc}</li>
                              <li><b>Head TA: </b> {item.head_ta}</li>
                            </ul>
                          </Table.Cell>
                        <Table.Cell>
                          <div>
                            <div className="status-actions">
                              {
                                clientEngagementStatus.map(status => {
                                    return <a key={status.id} href="#" onClick={(e) => onStatusChange(e, item.mgr_rec_id, status.id,item.id)} >{status.name}</a>
                                })
                              }
                              
                            </div>
                            <div>
                              <Button  skin='light' className="btn_view_testimonial" text="View Testimonial" clickButton={() => onViewTestimonials(item.id)} />
                              <Button  skin='light' className="btn_add_notes" text="View Notes" clickButton={() => onViewNotes(item.id)} />
                              <Button  skin='light' className="btn_add_testimonial" text="Add Testimonial" clickButton={() => openAddTestimonial(item.id)} />
                              <Button  skin='light' className="btn_add_notes" text="Add Notes" clickButton={() => openAddNote(item.id)} />

                            </div>
                          </div>
                         
                        </Table.Cell>

                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <div>
              No Records Found
            </div>
          )
          
        )
        }
      </div>
    </div>
  );
};

export default ClientEngagementReportsheet;