import { PAYMENT_SYNC_DASHBOARD } from "./routes"
import { getDomain } from "../utils/utilFunctions";

const domain = getDomain();

/* eslint-disable no-undef */
export const navbar = {
    'category': process.env.REACT_APP_CLIENT_URL + '/category.php',
    'my-jobs': '/',
    'messages': '/messages',
    // 'post-job': process.env.REACT_APP_CLIENT_URL + '/recruiter/jobpost.php',
    // 'old-report': process.env.REACT_APP_CLIENT_URL + '/recruiter/mgr_job_reports_admin.php',
    // 'new-report': process.env.REACT_APP_CLIENT_URL + '/recruiter/mgr_job_reports_admin2.php',
    // 'open-house': process.env.REACT_APP_CLIENT_URL + 'recruiter/openhouse.php',
    // 'chat': process.env.REACT_APP_CLIENT_URL + '/mychat',
    // 'manage-calenders': process.env.REACT_APP_CLIENT_URL + '/cal/events',
    // 'calender-view-slots': process.env.REACT_APP_CLIENT_URL + '/scheduled/interviews',
    // 'calender-monthly-report': process.env.REACT_APP_CLIENT_URL + '/cal/report',
    // 'calender-detailed-report': process.env.REACT_APP_CLIENT_URL + '/cal/detailed_report',
    // 'linkedin-post': process.env.REACT_APP_CLIENT_URL + '/recruiter/linkedinpost.php',
    // 'daily-post': process.env.REACT_APP_CLIENT_URL + '/recruiter/dailyfeedpost.php',
    'cache': process.env.REACT_APP_CLIENT_URL + '/recruiter/clearcache.php',
    // 'proxy-post': process.env.REACT_APP_CLIENT_URL + '/meta/tag.php',
    // 'proxy-edit': process.env.REACT_APP_CLIENT_URL + '/meta/edittag.php',
    'my-tags': process.env.REACT_APP_CLIENT_URL + '/recruiter/tags/',
    // 'saved-candidates': process.env.REACT_APP_CLIENT_URL + '/recruiter/savedshortlisted',
    'tags-list': '/recruiter/all_tag_count',
    'orders': '/orders',
    'leads': '/leads',
    'manageSubscriptions':'/manageSubscriptions',
    'resumeBuilder':'/resumeBuilder',
    'otherFA': '/otherFunctionalArea',
    'avg-publish-time': '/avg_publish_time',
    'invoice-upload': '/invoice-upload',
    'premiumJobList': '/premiumJobList' ,
    'potentialPaidJobs': '/potential-paid-jobs',
    'premiumRecruiterList': '/premiumRecruiterList' ,
    'seekerNotificationSettings': '/seekerNotificationSettings',
    'premiumPostingAdoption': '/premiumPostingAdoption' ,
    'notifierLogs': '/notifierLogs',
    'clientEngagementReportsheet': '/clientEngagementReportsheet',
    'addClient': '/addClient',
    'valueDeliveryJobs': '/valueDeliveryJobs' ,

} 
 
export const profile = [
    { 'My profile': process.env.REACT_APP_CLIENT_URL + '/recruiter/myprofile.php' },
    { 'My chat': process.env.REACT_APP_LOGIN_URL + '/chats' },
    { 'Settings / Edit Profile': process.env.REACT_APP_LOGIN_URL + '/account-settings' },
    { 'Change Password': process.env.REACT_APP_LOGIN_URL + '/account-settings' },
    { 'Logout': process.env.REACT_APP_CLIENT_URL + '/recruiter/login.php' },

]

export const getNavbarSubItems = (itemType, adminType) => {
    
    let analysisNavbar = [
        { 'Showcase Report': '/analyticsReport' },
        { 'Job Analysis': '/analysis/jobs' },
        { 'User Analysis': '/analysis/users' },
        { 'Funnel Data Analysis': '/analysis/funnel' },
        // { 'Recruiter Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiteranalysis.php' },
        // { 'Mobile App Users Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/mobileappusers.php' },
        { 'Tags Analysis': 'tagsAnalysis' /* process.env.REACT_APP_CLIENT_URL + '/recruiter/tagsanalysis.php' */ },
        
        // URL not working
        { 'Job Application': '/applications' },
        
        // { 'Job Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/job_report.php' },
        // { 'Payment Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/paymentreport.php' },
        // { 'Top Recruiter Actions': process.env.REACT_APP_CLIENT_URL + '/recruiter/topappactionlist.php' },
        // { 'Pro Lead Gen Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/proleadgenreport.php' },
        { 'Pro Lead Gen Report': '/analysis/proLeadGenReport' },
        // { 'User Applied Jobs Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/userapplyjobs.php' },
        { 'User Applied Jobs Analysis': '/analysis/userApplyJobs' },
        // { 'Ref URL Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/refapplyanalysis.php' },
        { 'Ref URL Analysis': '/analysis/refApplyAnalysis' },
        // { 'Recruiter Score Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiterscoringtop10.php' },
        // { 'VSRC Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/vsrc.php' },
        // { 'Follow-up Report Old': process.env.REACT_APP_CLIENT_URL + '/recruiter/followup_report.php' },
        { 'Follow-up Report': '/recruiter/followup_report' },
        // { 'Followed-up Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/followupanalysis.php' },
    
        { 'Premium Jobs List': '/recruiter/premium_job_listing' },
        // { 'Recommendation Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/recommendation_report.php' },
        // { 'Recommendation Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/recommendation_analysis.php' },
        // { 'Test Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/test_report.php' },
        // { 'Test Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/test_analysis.php' },
        // { 'Live Chat Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/live_chat_analysis.php' },
        // { 'Chat Message Count Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/chat_msg_count_report.php' },
        // { 'Additional Information Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/additionalinfo_analysis.php' },
        // { 'Additional Information Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/additionalinfo_report.php' },
        // { 'Skill Analysis': process.env.REACT_APP_CLIENT_URL + '/recruiter/skills_analysis.php' },
        // { 'Calender Slot Detail Report': process.env.REACT_APP_CLIENT_URL + '/calendar_slots/calendar_report_detail.php' },
        // { 'Calender Slot Entries Report': process.env.REACT_APP_CLIENT_URL + '/calendar_slots/calendar_report.php' },
        // { 'List of Banner': process.env.REACT_APP_CLIENT_URL + '/recruiter/featured_banner_admin.php' },
        // { 'Verified User Reportsheet': process.env.REACT_APP_CLIENT_URL + '/recruiter/report_verified_user_email_phoneno.php' },
        { 'Searched Keywords Reportsheet New': '/recruiter/count_searched_keywords' },
        // { 'Searched Keywords Detail Reportsheet': process.env.REACT_APP_CLIENT_URL + '/recruiter/top_searched_key.php' },
        // { 'Recruiter Domain Subscription': process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_domain_subscribe.php' },
        // { 'Question Bank Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/ques_count.php' },
        // { 'Recommended Jobs User Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/recommended_jobs_users_report.php' },
        // { 'Referral Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/refral_report.php' },
        // { 'Recruiter Registeration Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/rec_reg_mgmt.php' },
    
        { 'Unicorn & Premium Dashboard': '/unicorn-premium-list' }
    ]


    let managementNavbar = [
        { 'Recruiter Management': '/recruiter/recruiter_mgt' },
        // { 'Recruiter Profile Management Old': process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_profile_mgt.php' },
        { 'Recruiter Profile Management': '/recruiter/recruiter_profile_mgt' },
        // { 'Jobseeker Management Old': process.env.REACT_APP_CLIENT_URL + '/recruiter/seeker_mgt.php' },
        { 'Jobseeker Management': '/recruiter/seeker_mgt' },
        { 'Manager Management': '/recruiter/mgr_mgt' },
        { 'Login By Any Recruiter': '/anyRecruiterLogin' },
        { 'Login By Any Jobseeker': process.env.REACT_APP_PLATFORM != 'updazz' ? '/anyJobseekerLogin' : process.env.REACT_APP_CLIENT_URL + '/registration/login_xwpm.php' },
        { 'Tag Based Mailer': '/tagMailers' },
        { 'Company Management New': '/companyManagement' },
        // { 'Job Transfer Management New': '/transfer-job' },
        { 'Recruiter Verification': '/recruiter_verification' },
        // { 'New Tag Mailer': '/tagMailers' },
        // { 'Users By Batch': process.env.REACT_APP_CLIENT_URL + '/recruiter/userwithbatch.php' },
        // { 'Users By Tags And Status': process.env.REACT_APP_CLIENT_URL + '/recruiter/userapplyontag.php' },
        // { 'Recruiter Plan Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_plan_mgt.php' },
        // { 'Jobseeker Premium Apply Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/jobseeker_plan_mgt.php' },
        { 'Resume Search Plan Management': process.env.REACT_APP_PLATFORM == 'hirist' ? `https://search.${domain}/dbsearchlog` : "https://search.iimjobs.com/dbsearchlog" },
    
        { 'Jobseeker ProPlan Activation': process.env.REACT_APP_PLATFORM !== 'updazz' ? '/recruiter/jobseeker_plan_active' : process.env.REACT_APP_CLIENT_URL + '/recruiter/jobseeker_plan_active.php' },
        { 'Jobseeker Follow-up Management': process.env.REACT_APP_PLATFORM !== 'updazz' ? '/recruiter/jobseeker_followup_mgt' : process.env.REACT_APP_CLIENT_URL + '/recruiter/jobseeker_followup_mgt.php' },
        // { 'Jobseeker Search Plan Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/dbsearchlog.php' },
        
    
        // New Linkage
        // { 'IQ Search Plan Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/iqsearchlog.php' },
        // { 'IQ Monthly Search Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/iqsearchmonthwiselog.php' },
        // { 'Monthly Search Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/dbsearchmonthwiselog.php' },
        // { 'Datewise Search Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/dbsearchdatewiselog.php' },
        
        
        // { 'Event Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/event_admin.php' },
        { 'Premium Credits Management': '/recruiter/credit_management'/*process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_credit_plan.php'*/ },
        // { 'Premium Credits Management New': '/recruiter/credit_management' },
        // { 'Company Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/company_list.php' },
        { 'Company List': '/recruiterCompanyList' },
        // { 'Mail Service': process.env.REACT_APP_CLIENT_URL + '/recruiter/mailservice.php' },
        // { 'Social Info Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/remove_social_info.php' },
        // { 'Banner Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/featured_banner_admin.php' },
        // { 'Video Profile': process.env.REACT_APP_CLIENT_URL + '/recruiter/seekervideoprofile.php' },
        // { 'User Verify Skill Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/userverifyskillpanel.php' },
        // { 'Showcase Management': process.env.REACT_APP_CLIENT_URL + '/showcase/cp_listing.php' },
        { 'Add Client Engagement': '/addClient'},
        { 'View Client Engagement Report': '/clientEngagementReportsheet'},
        // { 'Unverified User List': process.env.REACT_APP_CLIENT_URL + '/recruiter/unverifieduser.php' },
        { 'Unverified User List': process.env.REACT_APP_PLATFORM !== 'updazz' ? '/unverifieduser' : process.env.REACT_APP_CLIENT_URL + '/recruiter/unverifieduser.php' },
        // { 'Confidential Job Panel': process.env.REACT_APP_CLIENT_URL + '/recruiter/confidential_job_panel.php' },
        { 'Confidential Job Panel': '/confidentialJobs' },
        { 'Follow Up Report': '/followerReport' },
        { 'Follow Up View': '/companyListInFollowUp' },
        { 'Learn & Grow Subscribed User': '/learningSubscription' },
        { 'Learn & Grow Bills': '/lgBills' },
        { 'Learn & Grow Collections': '/lgCollections' },
        { 'Learn & Grow Bill Targets': '/lgBillTargets' },
        { 'Learn & Grow Collection Targets': '/lgCollectionTargets' },
        { 'Referral Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/refral_report.php' },
        // { 'Referral Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/refral_report.php' },
        // { 'Keywords Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/keyword_report.php' },
        { 'Recruiter Activation/Deactivation Log': '/recruiter/rec_log_report' },
        { 'Reported Search Query': '/search/query-report' /*process.env.REACT_APP_CLIENT_URL + '/recruiter/searchqueryreport.php'*/ },
        // { 'PayU Reconcilliation Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/payu-reconciliation' },
        // { 'Unpublish Reason Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/job_unpublish_reason.php' },
        // { 'Search Similar Tag Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/searchsimilartag.php' },
        // { 'Search Similar Tag Report New': process.env.REACT_APP_CLIENT_URL + '/recruiter/searchsimilartagnew.php' },
        // { 'Keywords Synonyms Management': process.env.REACT_APP_CLIENT_URL + '/recruiter/keyword_synonym_mgmt.php' },
        // { 'Keywords Synonyms Report': process.env.REACT_APP_CLIENT_URL + '/recruiter/keyword_synonym.php' },
        { 'Recruiter Notificataion Setting': '/recruiter/recprofile_notification' },
        { 'Search Flagged User': '/search-flagged-users'/**process.env.REACT_APP_CLIENT_URL + '/recruiter/searchFlaggedUserReport.php'*/ },
        { 'Job Transfer Management': '/transfer-job' /*process.env.REACT_APP_CLIENT_URL + '/recruiter/transfer_of_job.php'*/ },
        { 'Search Mail Report': '/search-mail-report'/**process.env.REACT_APP_CLIENT_URL + '/recruiter/search_mail_report.php'*/ },
        { 'Domain Management': '/recruiter/domain_management' },
    
        { 'Showcase v2 Plan Management': '/showcasePlanList' },
        { 'Diversity Plan Management': '/diversityPlanList' },
        { 'ATS Panel': '/ats-panel-integrations' },
        { 'XML Feeds': '/recruiter/xml-feed-excluded-users' },
        { 'Naukri CRM Sync Dashboard': PAYMENT_SYNC_DASHBOARD },
    ]

    let universeNavBar = [
        {'Company Acquisition Report': '/company-acquisition'},
        {'Job Acquisition Report': '/job-acquisition'}
    ]

    let moreNavbar = [
        { 'My Tags': navbar['my-tags'] },
        { 'Tag List': navbar['tags-list'] },
        { 'Chat Messages': navbar['messages'] },
        { 'Orders': navbar['orders'] },
        { 'Leads Dashboard': navbar['leads'] },
        { 'Resume Builder': navbar['resumeBuilder'] },
        { 'Other Functional Areas': navbar['otherFA'] },
        { 'Seeker Subscription Panel': navbar['manageSubscriptions'] },
        { 'Average Publish Time': navbar['avg-publish-time'] },
        { 'Upload Invoice': navbar['invoice-upload'] },
        { 'Premium Job List': navbar['premiumJobList'] },
        { 'Potential Paid Jobs': navbar['potentialPaidJobs'] },
        { 'Premium Recruiter List': navbar['premiumRecruiterList'] },
        { 'Seeker Notification Settings': navbar['seekerNotificationSettings'] },
        { 'Premium Posting Adoption': navbar['premiumPostingAdoption'] },
        { 'Value Delivery Jobs': navbar['valueDeliveryJobs'] },


    ]

    let reportNavbar = [
        {'New Report': '/managerJobReports'},
        {'Calculus Report': `${process.env.REACT_APP_CALCULUS_BASE_URL}/dbsearchlog`},
        {'Calculus Monthly Report': `${process.env.REACT_APP_CALCULUS_BASE_URL}/iqsearchmonthlog`},
    ]
    if(adminType == 4) {
        
        managementNavbar = [
            { 'Recruiter Management': '/recruiter/recruiter_mgt' },
            { 'Manager Management': '/recruiter/mgr_mgt' },
            { 'Login By Any Recruiter': '/anyRecruiterLogin' },
            { 'Resume Search Plan Management': process.env.REACT_APP_PLATFORM == 'hirist' ? `https://search.${domain}/dbsearchlog` : "https://search.iimjobs.com/dbsearchlog" },
            { 'Monthly Search Report': process.env.REACT_APP_PLATFORM == 'hirist' ? `https://search.${domain}/dbsearchmonthwiselog` : "https://search.iimjobs.com/dbsearchmonthwiselog" },
            { 'Premium Credits Management': '/recruiter/credit_management'/*process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_credit_plan.php'*/ }
          
        ]

        moreNavbar = [
            { 'My Tags': navbar['my-tags'] },
        ]

        analysisNavbar = []
    }


    if(itemType == 'analysis') {
        return analysisNavbar
    }
    if(itemType == 'management') {
        return managementNavbar;
    }
    if(itemType == 'reports') {
        return reportNavbar;
    }
    if(itemType == 'universe') {
        return universeNavBar;
    }
    if(itemType == 'more') {
        return moreNavbar;
    }
}

export const getNavbarItems = (adminType) => {

    let items = [
        {list: false, data: {'My Jobs': navbar['my-jobs']}},
        {list: false, data: {'Post Job': '/postjob'}},
        {list: true, data: {'Reports': '/'}, itemType: 'reports'},
        {list: true, data: {'Management': '/'}, itemType:'management'},
        {list: true, data: {'More': '/'}, itemType: 'more'},

    ]

    if(adminType && adminType != 4) {
        items = [
            {list: false, data: {'My Jobs': navbar['my-jobs']}},
            {list: false, data: {'Post Job': '/postjob'}},
            {list: true, data: {'Reports': '/'}, itemType: 'reports'},
            {list: true, data: {'Analysis': '/'}, itemType: 'analysis'},
            {list: true, data: {'Management': '/'}, itemType:'management'},
            {list: true, data: {'Job Universe': '/'}, itemType:'universe'},
            {list: false, data: {'Cache': navbar['cache']}},
            {list: true, data: {'More': '/'}, itemType: 'more'},
    
        ]
    }

    return items;
}