import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './InputBox.scss';

class InputBox extends Component {

    // shouldComponentUpdate = (nextProps, nextState) => {
    //     return nextProps.value !== this.props.value || nextProps.checked !== this.props.checked
    // }

    render() {
        return(
            <input className="inputBox" type={this.props.type} checked={this.props.checked} disabled={this.props.disabled} value={this.props.value} name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} onKeyPress={this.props.onKeyPress} onBlur={this.props.onBlur} min={this.props.min} accept={this.props.accept} ></input>
        )
    }
}

InputBox.propTypes = {
    type: PropTypes.oneOf([ 'text', 'checkbox', 'number', 'radio', 'email' ]),
    name: PropTypes.string,
    checked: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyPress:PropTypes.func,
    disabled: PropTypes.bool,
    onBlur:PropTypes.func,
    min: PropTypes.number
}

export default InputBox;