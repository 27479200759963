import axios from '../utils/axios';

const resendActivationLink = (adminId, recruiterId) => {
    return (
        axios.get('/admin/' + adminId + '/recruiter/' + recruiterId + '/resend')
            .then((response) => {
                return response
            }).catch((error) => {
                return error
            })
    )
}

export default resendActivationLink;