import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Table, Icon, Checkbox } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';

import user from '../../store/user';
import getAllTagsList from '../../services/getAllTagsList';
import getAllTagsCount from '../../services/getAllTagsCount';
import updateTagDetails from '../../services/updateTagDetails';
import updateTagName from '../../services/updateTagName';

import { adminFeatureMapping } from '../../models/adminFeatureMapping';

import { getQueryParams } from '../../utils/utilFunctions';
import { ALL_TAG_COUNT } from '../../models/routes';

import './styles.scss';

const AllTagCount = props => {

    const [adminId, setAdminId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const searchRef = useRef(null);
    const [hasEditAccess, setHasEditAccess] = useState(false);
    const [editTag, setEditTag] = useState({
        tagId: null,
        tagName: null,
        isLoading: false,
        error: null
    });

    const SORTBY_KEYS = {
        tagCount: 'tag_count',
        id: 'id',
        tagName: 'tag_name'
    };

    const CONTROLLER_KEYS = {
        list: 'list',
        isPageLoading: 'isPageLoading',
        totalCount: 'totalCount',
        pageNumber: 'pageNumber',
        pageContent: 'pageContent',
        filter: 'filter',
        searchtext: 'searchtext',
        isSearchUsed: 'isSearchUsed',
        sort: 'sort',
        sortby: 'sortby',
        startAPI: 'startAPI',
        isDownloading: 'isDownloading',
        addedBy: 'addedBy'
    };

    const [dataController, setDataController] = useState({
        list: [],
        isPageLoading: false,
        totalCount: 0,
        pageNumber: 1,
        pageContent: 100,
        filter: null,
        searchtext: null,
        isSearchUsed: false,
        sort: 2, // 1->asc, 2->desc
        sortby: SORTBY_KEYS.tagCount,
        reset: null,
        startAPI: null,
        isDownloading: false,
        addedBy: null
    });

    useEffect(() => {
        // get admin user
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        }

        // check query params
        const params = getQueryParams(props.location.search);
           
        let sort = null, sortby = null;
        
        setController(CONTROLLER_KEYS.pageNumber, params.pg || 1)
        setController(CONTROLLER_KEYS.filter, params.filter || null);
        setController(CONTROLLER_KEYS.addedBy, params.addedby || null);

        if (params.tag) {
            setController(CONTROLLER_KEYS.searchtext, decodeURIComponent(params.tag));
            setController(CONTROLLER_KEYS.isSearchUsed, true);
        } else {
            setController(CONTROLLER_KEYS.searchtext, null);
        }

        if (params.sort && params.sortby) {
            sort = params.sort;
            sortby = params.sortby;
            setController(CONTROLLER_KEYS.sort, sort);
            setController(CONTROLLER_KEYS.sortby, sortby);
        }

        getProfile().then(response => {
            // check if user is admin
            if (response.type != 1 && response.adminType != 2 && response.adminType != 3) {
                window.location.href = '/notAdmin';
            }

            setHasEditAccess(user.checkFeatureAccess(adminFeatureMapping.EDIT_TAGS));
            setAdminId(response.id);
        }).catch(error => {
            // console.log(error);
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    // will fire once when admin id is available
    useEffect(() => {
        if (adminId) {
            fetchTagsList();
            fetchTagsCount();
        }
    }, [adminId]);

    // will fire whenever startAPI is set to true
    useEffect(() => {
        if (dataController.startAPI) {
            fetchTagsList();
            fetchTagsCount();
            updateQueryParams();
            setController(CONTROLLER_KEYS.startAPI, false);
        }
    }, [dataController.startAPI]);

    // helper function to set values to dataController state
    const setController = (key, value) => {
        setDataController(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        });
    };

    // helper function to get parameters for the API call
    const getParameters = (page = null) => {

        const params = {
            pageNumber: page || dataController.pageNumber,
            pageContent: dataController.pageContent
        };

        if (dataController.sortby && dataController.sort) {
            params.sort = dataController.sort;
            params.sortby = dataController.sortby;
        } 
        
        if (dataController.filter) {
            params.filter = dataController.filter;
        }
        
        if (dataController.searchtext) {
            params.tag = dataController.searchtext;
        } 

        if (dataController.addedBy) {
            params.addedBy = dataController.addedBy;
        } 

        return params;
    };

    // get tags list
    const fetchTagsList = async (page = null) => {
        try {
            setController(CONTROLLER_KEYS.isPageLoading, true);

            const params = getParameters(page);

            const response = await getAllTagsList(adminId, params);

            setController(CONTROLLER_KEYS.list, response.data.data);

            updateQueryParams(params);
        } catch (err) {
            // console.log(err);
            toast.error('Something went wrong. Please try again later!');
        }

        setController(CONTROLLER_KEYS.isPageLoading, false);
        setIsLoading(false);
    };

    // get tags count
    const fetchTagsCount = async () => {
        try {
            const params = getParameters();

            if (params.filter) {
                params['status'] = params.filter === 'active' ? '1' : '0';
            }

            const response = await getAllTagsCount(adminId, params);

            setController(CONTROLLER_KEYS.totalCount, response.data.data[0].cnt);
        } catch (err) {
            // console.log(err);
        }
    };

    /**
     * to update tag details
     * @param {*} type description  or status
     * @param {*} tagId tag id for the tag in the list
     * @returns 
     */
    const updateTag = async (type, tagId) => {
        
        const selectedTag = dataController.list.find(item => item.id === tagId);

        if (!selectedTag) {
            return;
        } 

        const params = { tagId };

        if (type === 'description') {
            params['description'] = selectedTag.description;
        } else if (type === 'status') {
            params['status'] = selectedTag.status == 1 ? '0' : '1'; // status must be a string
        } else {
            return;
        }

        try {

            const response = await updateTagDetails(adminId, params);

            // update list if status is changed
            if (type === 'status') {

                if (params.status == 0) {
                    toast.success('Tag deactivated successfully');
                } else {
                    toast.success('Tag activated successfully');
                }

                setDataController(prevObj => {
                    const newList = prevObj.list.map(item => {
                        if (item.id == tagId) {
                            item.status = +params.status;
                        }
                        return { ...item };
                    });
   
                    return {
                        ...prevObj,
                        newList
                    };
                });

            } else {
                toast.success('Tag updated successfully');
            }

        } catch (error) {
            // console.log(error);
            if (params.status && params.status == 0) {
                toast.error('Failed to deactivate tag. Please try again later!');
            } else if (params.status && params.status == 1) {
                toast.error('Failed to activate tag. Please try again later!');
            } else {
                toast.error('Could not update tag. Please try again later!');
            }
        }
    };

    const downloadData = async () => {
        setController(CONTROLLER_KEYS.isDownloading, true);

        try {
            const params = getParameters();

            const response = await getAllTagsList(adminId, {
                download: true,
                ...params
            });
            
            const filename = 'all_tags_count.csv';

            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // console.log(error);
            toast.error('Error downloading data. Please try again later!');
        }

        setController(CONTROLLER_KEYS.isDownloading, false);
    }

    // helper function for clear button
    const handleClearSearch = () => {
        if (searchRef && searchRef.current) {
            searchRef.current.value = '';
        }

        setController(CONTROLLER_KEYS.addedBy, null);
        setController(CONTROLLER_KEYS.searchtext, null);
        setController(CONTROLLER_KEYS.isSearchUsed, false);
        setController(CONTROLLER_KEYS.pageNumber, 1);
        setController(CONTROLLER_KEYS.sort, 2);
        setController(CONTROLLER_KEYS.sortby, SORTBY_KEYS.tagCount);
        setController(CONTROLLER_KEYS.filter, null);
        setController(CONTROLLER_KEYS.startAPI, true);
    };

    // helper function for search button
    const handleSearchSubmit = () => {
        if (!dataController.searchtext) {
            return;
        }

        setIsLoading(true);
        setController(CONTROLLER_KEYS.pageNumber, 1);
        setController(CONTROLLER_KEYS.isSearchUsed, true);
        setController(CONTROLLER_KEYS.startAPI, true);
    };

    // helper function to update tag name in edit
    const handleEditTagNameChange = (event) => {
        const value = event.target.value || '';
        setEditTag(prevObj => {
            return {
                ...prevObj,
                tagName: value
            }
        });
    };

    // helper function to save edit tag name changes
    const handleEditTagSave = async (originalTagName) => {
        try {

            if (editTag.tagName.trim() === originalTagName) {
                return;
            }

            if (!editTag.tagName || (editTag.tagName && editTag.tagName.trim().length === 0)) {
                // tag name cannot be empty
                setEditTag(prevObj => {
                    return {
                        ...prevObj,
                        tagName: '',
                        error: 'Tag Name cannot be empty'
                    }
                })

                return;
            }

            const params = {
                tagId: editTag.tagId,
                tagName: editTag.tagName.trim()
            };

            const response = await updateTagName(adminId, params);
            toast.success('Tag name updated successfully');

            setTimeout(() => window.location.reload(), 500)
        } catch (error) {
            if (error.response && error.response.status == 409) {
                toast.error('Could not update tag name, Tag name already exists.');
                return;
            }
            toast.error('Could not update tag name. Please try again later!');
        }
    };

    // helper function to cancel edit tag
    const handleEditTagCancel = () => {
        setEditTag({});
    };

    // helper function to update search text
    const handleOnSearchChange = event => {
        const value = event.target.value;

        setController(CONTROLLER_KEYS.searchtext, value);
    };

    // helper function to detect Enter key
    const handleOnKeyPress = (event) => {
        if (event && event.key == 'Enter') {
            handleSearchSubmit();
        }
    };

    // helper function for page change
    const handlePageChange = (page) => {
        setController(CONTROLLER_KEYS.pageNumber, page);
        fetchTagsList(page);
    };

    // helper function to update tag description when textarea input changes
    const handleOnDescChange = (event, tagId) => {
        const value = event.target.value;

        setDataController(prevObj => {
            
            const newList = prevObj.list.map(item => {
                if (item.id == tagId) {
                    item.description = value;
                }
                return { ...item };
            });
            
            return {
                ...prevObj,
                list: newList,
            };
        });
    };

    // helper function to change sorting params
    // just add whatever filter you need here
    const changeSortParams = (key) => {

        let sort = 2;

        if (['active', 'inactive'].includes(key)) {
            setController(CONTROLLER_KEYS.filter, key);
        } else if (['admin', 'seeker'].includes(key)) {
            setController(CONTROLLER_KEYS.addedBy, key);
        }else {
            if (key === dataController.sortby) {
                sort = dataController.sort == 1 ? 2 : 1;
            }
    
            setController(CONTROLLER_KEYS.sort, sort);
            setController(CONTROLLER_KEYS.sortby, key);
        }
        
        setController(CONTROLLER_KEYS.startAPI, true);
    };

    const updateQueryParams = () => {
        const params = [];

        params.push(`pg=${dataController.pageNumber}`);

        if (dataController.searchtext) {
            params.push(`tag=${encodeURIComponent(dataController.searchtext)}`);
        }

        if (dataController.sort && dataController.sortby) {
            params.push(`sort=${dataController.sort}`);
            params.push(`sortby=${dataController.sortby}`);
        }

        if (dataController.filter) {
            params.push(`filter=${dataController.filter}`);
        }

        if (dataController.addedBy) {
            params.push(`addedby=${dataController.addedBy}`);
        }

        props.history.push(`${ALL_TAG_COUNT}?${params.join('&')}`);
    };

    let headerId = 'arrow down';
    let headerTagCount = 'arrow down';
    let headerTagName = 'arrow down';

    if (dataController.isSearchUsed && dataController.sortby === SORTBY_KEYS.tagName){
        if (dataController.sort == 1) {
            headerTagName = 'arrow up';
        } else {
            headerTagName = 'arrow down';
        }
    }

    if (dataController.sortby === SORTBY_KEYS.id) {
        if (dataController.sort == 1) {
            headerId = 'arrow up';
        } else {
            headerId = 'arrow down';
        }
    }

    if (dataController.sortby === SORTBY_KEYS.tagCount) {
        if (dataController.sort == 1) {
            headerTagCount = 'arrow up';
        } else {
            headerTagCount = 'arrow down';
        }
    }

    const tableHeading = (
        <Table.Header>
            <Table.HeaderCell width="one">
                <div onClick={() => changeSortParams(SORTBY_KEYS.id)}>
                    <span className='span-link'>ID</span> <Icon name={headerId} />
                </div>
            </Table.HeaderCell>
            <Table.HeaderCell width="two">
                {dataController.isSearchUsed ? (
                    <div>
                        <span 
                            className='span-link' 
                            onClick={() => changeSortParams(SORTBY_KEYS.tagName)}
                        >
                            Tag Name
                        </span>
                        <Icon name={headerTagName} />
                    </div>
                ) : <span>Tag Name</span>}
            </Table.HeaderCell>
            <Table.HeaderCell width="two">Tag Lower Name</Table.HeaderCell>
            <Table.HeaderCell width="one">
                <div onClick={() => changeSortParams(SORTBY_KEYS.tagCount)}>
                    <span className='span-link'>Tag Count</span> <Icon name={headerTagCount} />
                </div>
            </Table.HeaderCell>
            <Table.HeaderCell width="three">Description</Table.HeaderCell>
            <Table.HeaderCell width="one">
                <div>
                    <span className={`span-link${dataController.filter == 'active' ? '-active' : ''}`} onClick={() => {
                        if (dataController.filter != 'active') {
                            changeSortParams('active')
                        }
                    }}>Active</span> | <span className={`span-link${dataController.filter == 'inactive' ? '-active' : ''}`} onClick={() => {
                        if (dataController.filter != 'inactive') {
                            changeSortParams('inactive')
                        }
                    }}>Inactive</span>
                </div>
            </Table.HeaderCell>
            <Table.HeaderCell width="one" textAlign="center">
                <div>
                    <div>Added By</div>
                    <div>
                        <span className={`span-link${dataController.addedBy == 'admin' ? '-active' : ''}`} onClick={() => {
                            if (dataController.addedBy != 'admin') {
                                changeSortParams('admin');
                            }
                        }}>Admin</span> | <span className={`span-link${dataController.addedBy == 'seeker' ? '-active' : ''}`} onClick={() => {
                            if (dataController.addedBy != 'seeker') {
                                changeSortParams('seeker')
                            }
                        }}>Seeker</span>
                    </div>
                </div>
            </Table.HeaderCell>
        </Table.Header>
    );

    let mainContent = null;

    if (isLoading) {
        mainContent = <Loader />
    } else if (!isLoading && dataController.isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        );
    } else if (!isLoading && !dataController.isPageLoading && dataController.list.length === 0) {
        mainContent = (
            <>
                <div>No data found !</div>
            </>
        )
    } else {
        mainContent = (
            <Table className="tags-table">
                {tableHeading}
                <Table.Body>
                    {dataController.list.map(item => {
                        return (
                            <Table.Row className="tags-table-item row-item">
                                <Table.Cell verticalAlign="top" width="one">{item.id}</Table.Cell>
                                <Table.Cell verticalAlign="top" width="two" className="tag-name">
                                    <div className='tag__name'>
                                        {editTag.tagId === item.id ? (
                                            <div className='tag__name--control'>
                                                <InputBox
                                                    placeholder="Search by tag name" 
                                                    value={editTag.tagName}
                                                    onChange={handleEditTagNameChange}
                                                />
                                                {editTag.error ? <div className='error-text'>{editTag.error}</div> : null}
                                                <Button text="Save" skin="dark" clickButton={() => handleEditTagSave(item.tag_name)} />
                                                <Button text="Cancel" skin="light" clickButton={handleEditTagCancel} />
                                            </div>
                                        ) : (
                                            <a href={`${process.env.REACT_APP_CLIENT_URL}/k/${item.tag_name_lower}-jobs-${item.id}.html`} target="_blank">
                                                <span>{item.tag_name}</span>
                                            </a>
                                        )}

                                        {(hasEditAccess && editTag.tagId !== item.id) ? (
                                            <span title="Edit Tag" className='tag__name--edit' onClick={() => setEditTag({ tagId: item.id, tagName: item.tag_name })}>                                                
                                                <Icon name="pencil" />
                                            </span>
                                        ) : null}
                                    </div>
                                </Table.Cell>
                                <Table.Cell verticalAlign="top" width="two" className="tag-name-lower">{item.tag_name_lower}</Table.Cell>
                                <Table.Cell verticalAlign="top" width="one">{item.tag_count}</Table.Cell>
                                <Table.Cell verticalAlign="top" width="three">
                                    <div className='textarea-desc'>
                                        <textarea value={item.description} rows={5} onChange={(event) => handleOnDescChange(event, item.id)}/>
                                        <Button skin="dark" text="Update" clickButton={() => updateTag('description', item.id)} />
                                    </div>
                                </Table.Cell>
                                <Table.Cell verticalAlign="top" width="one">
                                    <span className='span-link' onClick={() => updateTag('status', item.id)}>
                                        {item.status == 1 ? 'Deactivate' : 'Activate'}
                                    </span>
                                </Table.Cell>
                                <Table.Cell verticalAlign="top" width="one">
                                    { item.seekerId > 0 ? `Jobseeker: ${item.seekerId}` : 'Admin' }
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        );
    }

    let topPageIndicators = null, bottomPageIndicators = null;

    if (!isLoading && dataController.list.length > 0) {
        topPageIndicators = (
            <div className="row text-center top-page-controller">
                <Pagination totalItemsCount={dataController.totalCount} content={dataController.pageContent} pageNumber={dataController.pageNumber} handlePageChange={handlePageChange} />
                <CountDisplay start={(dataController.pageNumber - 1) * dataController.pageContent + 1} end={dataController.pageNumber * dataController.pageContent} total={dataController.totalCount} />
            </div>
        )
        if (!dataController.isPageLoading) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination totalItemsCount={dataController.totalCount} content={dataController.pageContent} pageNumber={dataController.pageNumber} handlePageChange={handlePageChange} />
                </div>
            )
        }
    } else {
        topPageIndicators = <div></div>
        bottomPageIndicators = <div></div>
    }

    return (
        <div className='page-content all-tags-list'>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className='page-heading'>
                <Heading text="All Tags Count" />
            </div>

            {adminId ? (
                <div className='controller'>
                    <div className='controller__search'>
                        <div className='controller__search--input'>
                            <InputBox 
                                _ref={searchRef}
                                placeholder="Search by tag name" 
                                value={dataController.searchtext}
                                onChange={handleOnSearchChange}
                                onKeyPress={handleOnKeyPress}
                            />
                        </div>
                        <div className='controller__search--actions'>
                            <Button skin="dark" text="Search" clickButton={handleSearchSubmit} />
                            <Button skin="light" text="Clear" clickButton={handleClearSearch}/>
                        </div>
                    </div>
                    <div className='controller__actions'>
                        <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/all_tag_count.php`}>
                            <Button skin="dark" text="Use Old Version" />
                        </a>
                        <Button 
                            skin="light" 
                            text="Download" 
                            loading={dataController.isDownloading}
                            disabled={dataController.isDownloading ? dataController.isDownloading : null} 
                            clickButton={downloadData}
                        />
                    </div>
                </div>
            ) : null}

            <div className='page-body'>
                {topPageIndicators}
                {mainContent}
                {bottomPageIndicators}
            </div>
        </div>
    );
};

export default AllTagCount;