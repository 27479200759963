import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';

import './styles.scss';

const PillSelect = props => {
    const { options, selectedOptions = [], onSelectOption } = props;

    const handlePillClick = (optsId) => {
        onSelectOption(optsId);
    };
    return (
        <div className='pillselect__container'>
            <div className='pillselect__container--pills'>
                {options.map((item, index) => {
                    return (
                        <div 
                            key={item.id}
                            onClick={() => handlePillClick(item.id)}
                            className={`pill-item ${selectedOptions.indexOf(item.id) > -1 ? 'selected' : ''}`}
                            >
                            {item.value} <Icon className="pill-icon" name={`${selectedOptions.indexOf(item.id) > -1 ? 'checkmark' : 'plus'}`} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

PillSelect.propTypes = {
    options: PropTypes.shape({
        id: PropTypes.any,
        value: PropTypes.string,
    }),
    selectedOptions: PropTypes.arrayOf(PropTypes.any),
    onSelectOption: PropTypes.func,
};

export default PillSelect;