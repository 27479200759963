const benefits = [
    { id: 1, value: 'Medical Insurance' },
    { id: 2, value: 'ESOPs' },
    { id: 3, value: 'Child Care' },
    { id: 4, value: 'Creche' },
    { id: 5, value: 'Maternity Leaves' },
    { id: 6, value: 'Paternity Leaves' },
    { id: 7, value: 'Car Lease' },
    { id: 8, value: 'Retirement Benefits' },
    { id: 9, value: 'Free Meal' },
    { id: 10, value: 'Office Cab/Shuttle' },
    { id: 11, value: 'Office Gym' },
    { id: 12, value: 'Soft Skill Training' },
    { id: 13, value: 'Recreational Activities' },
    { id: 14, value: 'Annual Health Checkups' },
];

export const getCompanyBenefits = () => {
    return benefits;
};