import axios from '../utils/axios';

export default function fetchClientList(adminId, params) {
    return (
        axios.get('/admin/' + adminId + '/client-engagement-reportsheet', { params })
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err;
            })
    )
}
