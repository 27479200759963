export const premiumStatus = [
    {name: 'Created Premium (Pending)', id: '1'},
    {name: 'Remove Premium (Pending)', id: '2'},
    {name: 'Premium', id: '3'},
    {name: 'Removed Premium', id: '4'}
]
    

export function getPremiumStatusMap() {
    return premiumStatus.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}