import axios from '../utils/axios';

export default function fetchAllJobs(adminId,params) {
    return (
        axios.get('/admin/' + adminId + '/all-jobs',{
            params: params
        })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
