import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';
import InputBox from '../../components/InputBox';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import TableItem from './TableItem';

import user from '../../store/user';
import getDomainList from '../../services/getDomainList';
import getDomainListCount from '../../services/getDomainListCount';
import updateDomainInfo from '../../services/updateDomainInfo';

import './Container.scss';

class Container extends React.Component {
    state = {
        adminId: null,
        pageNumber: 1,
        pageContent: 50,
        isLoading: false,
        isPageLoading: false,
        searchError: null,
        domainList: [],
        total: 0,
        searchDomainType: 'all',
        searchDomain: null,
    }

    constructor(props) {
        super(props);
        this.searchInputRef = React.createRef(null);
        this.tableRef = React.createRef(null);
    }

    /**
     * This function gets called when user tried to navigate away from the current page
     * and only catches the back press. It will then load the page based on the 
     * previous parameters
     * @param {object} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.history && this.props.history.action === 'POP') {
                this.onRouteChange(this.props);
            }
        }
    }

    /**
     * This function gets called whenever the page is loaded or mounted
     * @param {object} props laters props
     */
    onRouteChange = (props) => {
        let queryString = props.location.search;
        const obj = {};

        queryString = queryString.replace('?', '');
        queryString = queryString.split('&');
        for (let i=0; i < queryString.length; i++) {
            const temp = queryString[i].split('=');
            obj[temp[0]] = temp[1];
        }
        
        const state = this.state;
        state.pageNumber = obj['pg'] || 1;

        state.isLoading = true;
        this.setState(state);
        this.getDomainList();
        this.getDomainListCount();
        
    }

    componentDidMount = async () =>{
        const profile = await user.getProfile();
        this.setState({ adminId: profile.id });

        const adminType = profile.adminType;
        if (!(adminType == '2' || adminType =='3')) {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        }

        this.onRouteChange(this.props);
    }

    /**
     * Http call to get the list of domains based on current filters
     */
    getDomainList = async () => {
        const params = {
            pageNumber: this.state.pageNumber,
            pageContent: this.state.pageContent,
            domainType: this.state.searchDomainType,
            domain: this.state.searchDomain
        };

        const response = await getDomainList(this.state.adminId, params);

        const state = this.state;
        state.isLoading = false;
        state.isPageLoading = false;
        state.searchError = null;
        state.domainList = response.data;
        this.setState(state);

        // lose focus from input box
        if (this.searchInputRef && this.searchInputRef.current) {
            this.searchInputRef.current.blur();
        }
    }

    /**
     * Http call to get the total number of results for the current filters
     */
    getDomainListCount = async () => {
        const params = {
            pageNumber: this.state.pageNumber,
            pageContent: this.state.pageContent,
            domainType: this.state.searchDomainType,
            domain: this.state.searchDomain
        };
        
        const response = await getDomainListCount(this.state.adminId, params);

        if (response.data.cnt) {
            this.setState({ total: response.data.cnt });
        }
    }

    /**
     * This function will change the url and add the applicable parameters 
     * @param {object} parameters current parameters for the http endpoint 
     */
    changeRoute = (parameters) => {
        let array = [];
        for (let key in parameters) {
            if (key === 'pageNumber') {
                array.push('pg=' + parameters[key]);
            } else if (key === 'domain') {
                array.push('domain=' + parameters[key]);
            } else if(key === 'domainType') {
                array.push('domainType=' + parameters[key]);
            }
        }

        array = array.join('&');
        this.props.history.push('/recruiter/domain_management?' + array);
    }

    /**
     * Handler function for key press inside {@link InputBox} component
     * Used to catch enter key on the input box
     * @param {object} event 
     */
    onKeyPressHandler = (event) => {
        if (event.key === 'Enter') {
            this.onSearchHandler();
        }
    }

    /**
     * Handler for searching domain info. Triggers on clicking search button
     * Will call dimain info and count endpoint
     * @param {object} event 
     */
    onSearchHandler = (event) => {
        const searchtext = this.searchInputRef.current.value;

        const state = this.state;
        state.isLoading = true;
        state.pageNumber = 1;

        if (searchtext) {
            state.searchDomain = searchtext;
            state.searchDomainType = null;
        } else if (this.state.searchDomainType) {
            state.searchDomain = null;
        } else {
            state.searchDomain = null;
            state.searchDomainType = 'all';
        }

        this.setState(state);
        this.getDomainList();
        this.getDomainListCount();
    }

    /**
     * This function will clear all the filters on search and dropdown
     * @param {object} event default event object
     */
    onClearHandler = (event) => {
        this.searchInputRef.current.value = '';

        const state = this.state;
        state.searchDomainType = 'all';
        state.searchDomain = null;
        state.pageNumber = 1;
        state.isLoading = true;

        this.setState(state);
        this.getDomainList();
        this.getDomainListCount();
    }

    /**
     * Handler object for dropdown option change inside {@link Dropdown} component
     * @param {object} event default event object
     */
    onDropdownChangeHandler = (event) => {
        let domainType = event.target.value;
        if (domainType == '0') {
            domainType = 'all';
        }
        this.setState({ searchDomainType: domainType });
    }

    /**
     * Handler function for {@link Pagination} component
     * @param {number} page the page number which is selected by user
     */
    handlePageChange = (page) => {
        const state = this.state;
        state.pageNumber = page;
        state.isPageLoading = true;
        this.setState(state);

        this.getDomainList();
        this.getDomainListCount();
    }

    /**
     * This method will select all the checkboxes in the table. It operates on ReactDOM
     * @param {object} event default event object 
     */
    selectAllHandler = (event) => {
        let selection = false;
        if (event.target.checked) {
            selection = true;
        } 
        const checkboxes = document.getElementsByClassName('domain_checkbox');
        for (let i=0; i<checkboxes.length; i++) {
            checkboxes[i].checked = selection;
        }
    }

    getButton = (text, className, onClick) => {
        return <Button className={className} text={text} skin='light' clickButton={onClick}/>
    }

    /**
     * This method sets the field `isLoading` in the domain object in state list
     * this will decide whether to show a loading spinner on when a button is clicked
     * @param {object} params contains domain info 
     * @param {boolean} isLoading can be one of [1,2,3,4] depending on which action taken
     */
    setButtonLoading = (params, isLoading) => {
        const state = this.state;
        const domains = params.domain;
        const domainArr = state.domainList;

        let _state = 0;
        if (params.blacklist) _state = 1
        else if(params.paid_client) _state = 2
        else if(params.premium) _state = 3
        else if(params.comments) _state = 4

        for(let i=0; i<domainArr.length; i++) {
            if (domains.indexOf(domainArr[i].domain) != -1) {
                domainArr[i] = {
                    ...domainArr[i],
                    isLoading: isLoading ? _state : null,
                };
            }
        }
        state.domainList = domainArr;
        this.setState(state);
    }

    /**
     * This function updates the state after an http call
     * @param {object} params 
     */
    updateDomainState = (params) => {
        const state = this.state;
        const domainArr = state.domainList;

        for(let i=0; i<domainArr.length; i++) {
            if (params.domain.indexOf(domainArr[i].domain) != -1) {
                if (params.comments == null || params.comments == undefined) {
                    domainArr[i] = {
                        ...domainArr[i],
                        premium: params.premium,
                        paid_client: params.paid_client,
                        blacklist: params.blacklist,
                    }
                } else {
                    domainArr[i] = {
                        ...domainArr[i],
                        comments: params.comments
                    }
                }
            } 
        }
        state.domainList = domainArr;
        this.setState(state);
    }

    /**
     * This method will update a single domain info. This happens when actions 
     * are clicked on table row 
     * @param {object} params contains parameters for the endpoint 
     */
    updateSingleHandler = async (params) => {
        // set loading
        this.setButtonLoading(params, true);
        
        const result = await updateDomainInfo(this.state.adminId, params);

        if (result.data) {
            // If the api call was successful, then update the current state
            this.updateDomainState(params)
            toast.success('Domain info updated');
        } else {
            toast.error('Something went wrong');
        }
        
        // unset loading
        this.setButtonLoading(params, false);
    }

    /**
     * This method will udpate multiple domain infos. 
     * @param {number} domainType domain type. Valid values: 1(blacklist), 2(paid_client)
     * 3(premium)
     * @param {string} action the action to take. Valid values: 'make' and 'remove' 
     */
    updateMultipleHandler = async (domainType, action) => {
        const domainArr = [];
        const _state = action === 'make' ? 1 : 0;
        const params = {
            premium: 0,
            blacklist: 0,
            paid_client: 0
        };

        const checkboxes = document.getElementsByClassName('domain_checkbox');
        for(let i = 0; i<checkboxes.length; i++) {
            if(checkboxes[i].checked) {
                domainArr.push(checkboxes[i].getAttribute('data-domain'));
            }
        }

        if (domainArr.length == 0) {
            return;
        }
        
        if (domainType == 1) params.blacklist = _state
        else if(domainType  == 2) params.paid_client = _state
        else params.premium = _state

        const result = await updateDomainInfo(this.state.adminId, {
            domain: domainArr,
            ...params
        });
        
        if (result.data) {
            this.updateDomainState({ domain: domainArr, ...params });
            toast.success('Multiple domain info update successful')
        } else {
            toast.error('Something went wrong')
        }
    }

    render() {
        // Drop down options for domain type dropdown
        const dropdownOptions = [
            {
                id: 0,
                name: 'Select Domain Type'
            },
            {
                id: 1,
                name: 'Blacklisted Domain'
            },
            {
                id: 2,
                name: 'Paid Client Domain'
            },
            {
                id: 3,
                name: 'Premium Domain'
            }
        ];

        const { isLoading, isPageLoading, domainList, total, pageContent, pageNumber } = this.state;

        const headerBtnsArr = [];
        headerBtnsArr.push(this.getButton('Make Multiple Premium', 'btn', () => this.updateMultipleHandler(3, 'make')))
        headerBtnsArr.push(this.getButton('Make Multiple Blacklist', 'btn', () => this.updateMultipleHandler(1, 'make')))
        headerBtnsArr.push(this.getButton('Make Multiple Paid Client', 'btn', () => this.updateMultipleHandler(2, 'make')))
        headerBtnsArr.push(this.getButton('Remove Multiple Premium', 'btn', () => this.updateMultipleHandler(3, 'remove')))
        headerBtnsArr.push(this.getButton('Remove Multiple Blacklist', 'btn', () => this.updateMultipleHandler(1, 'remove')))
        headerBtnsArr.push(this.getButton('Remove Multiple Paid Client', 'btn', () => this.updateMultipleHandler(2, 'remove')))

        const domainHeader = (
            <div className='header-domain'>
                <InputBox type="checkbox" onChange={this.selectAllHandler}/>
                <span>Domain</span>
            </div>
        );

        const tableHeading = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="1">{domainHeader}</Table.HeaderCell>
                    <Table.HeaderCell width="1">Domain Type</Table.HeaderCell>
                    <Table.HeaderCell width="3">Action</Table.HeaderCell>
                    <Table.HeaderCell width="2">Comments</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )

        const searchContent = (
            <div className='search__container'>
                <div className='search__container--control'>
                    <InputBox className="search__container--input" type="text" _ref={this.searchInputRef} placeholder="Enter Email/Domain" onKeyPress={this.onKeyPressHandler} />
                    <span>OR</span>
                    <Dropdown className="search__container--dropdown" defaultValue={this.state.searchDomainType} data={dropdownOptions} dropdownChange={this.onDropdownChangeHandler} />
                    <div className='search__container--buttons'>
                        <Button className='btn_search' skin='dark' text='Search' clickButton={this.onSearchHandler}/>
                        <Button className='btn_clear' skin='light' text='Clear' clickButton={this.onClearHandler}/>
                    </div>
                </div>
                <div>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/domain_management.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
                </div>
            </div>
        );

        let mainContent;
        if (isPageLoading) {
            mainContent = (
                <>
                    <Table>{tableHeading}</Table>
                    <Loader />
                </>
            )
        } else {
            mainContent = (
                <div ref={this.tableRef}>
                    <Table celled striped>
                        {tableHeading}
                        <tbody className='table_body'>
                            {domainList.map(item => {
                                return <TableItem data={item} onUpdate={this.updateSingleHandler} />
                            })}
                            {domainList.length==0 ? <div>No Record Found</div> : null}
                        </tbody>
                    </Table>
                </div>
            )
        }

        let topPageIndicators, bottomPageIndicators;

        if (!isLoading && domainList.length > 0) {
            topPageIndicators = (
                <div className='row text-center'>
                    <Pagination
                        totalItemsCount={total}
                        content={pageContent}
                        pageNumber={pageNumber}
                        handlePageChange={this.handlePageChange}
                    />
                    <CountDisplay
                        start={(pageNumber - 1) * pageContent}
                        end={pageNumber * pageContent}
                        total={total}
                    />
                </div>
            );

            if (!isPageLoading) {
                bottomPageIndicators = (
                    <div className='row text-center'>
                        <Pagination
                            totalItemsCount={total}
                            content={pageContent}
                            pageNumber={pageNumber}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                );
            }
        } else {
            topPageIndicators = null;
            bottomPageIndicators = null;
        }

        return (
            <div className="page-content domain-management">
                <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover
                />
                <div className="page-heading">
                    <Heading text="Domain Management" />
                </div>

                {searchContent}

                {isLoading ? <Loader /> : (
                    <>
                        {topPageIndicators}
                        <div className='row'>
                            <div className='btns__container'>
                                {headerBtnsArr}
                            </div>
                            {mainContent}
                        </div>
                        {bottomPageIndicators}
                    </>
                )}
            </div>
        );
    };
}

export default Container;