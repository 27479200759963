import axios from '../utils/axios';

export default function logout(adminId) {
    return (
        axios.post(`/admin/${adminId}/logout`,  {} )
        .then(res => {
            return res
        })
        .catch(err => {
            return err;
        })
    )
}
