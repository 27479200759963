import axios from '../utils/axios';

export default function rejectCompanyManagement(recId,companyId,data) {
    return (
        axios.post(`/admin/${recId}/companyManagement/v1/unverified-companies/${companyId}/reject`, data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}