const year = [
    { id: 2020, name: '2020' },
    { id: 2019, name: '2019' },
    { id: 2018, name: '2018' },
    { id: 2017, name: '2017' },
    { id: 2016, name: '2016' },
    { id: 2015, name: '2015' },
    { id: 2014, name: '2014' },
    { id: 2013, name: '2013' },
    { id: 2012, name: '2012' },
    { id: 2011, name: '2011' },
    { id: 2010, name: '2010' },
    { id: 2009, name: '2009' },
    { id: 2008, name: '2008' },
    { id: 2007, name: '2007' },
    { id: 2006, name: '2006' },
    { id: 2005, name: '2005' },
    { id: 2004, name: '2004' },
    { id: 2003, name: '2003' },
    { id: 2002, name: '2002' },
    { id: 2001, name: '2001' },
    { id: 2000, name: '2000' },
    { id: 1999, name: '1999' },
    { id: 1998, name: '1998' },
    { id: 1997, name: '1997' },
    { id: 1996, name: '1996' },
    { id: 1995, name: '1995' },
    { id: 1994, name: '1994' },
    { id: 1993, name: '1993' },
    { id: 1992, name: '1992' },
    { id: 1991, name: '1991' },
    { id: 1990, name: '1990' },
    { id: 1989, name: '1989' },
    { id: 1988, name: '1988' },
    { id: 1987, name: '1987' },
    { id: 1986, name: '1986' },
    { id: 1985, name: '1985' },
    { id: 1984, name: '1984' },
    { id: 1983, name: '1983' },
    { id: 1982, name: '1982' },
    { id: 1981, name: '1981' },
    { id: 1980, name: '1980' },
    { id: 1979, name: '1979' },
    { id: 1978, name: '1978' },
    { id: 1977, name: '1977' },
    { id: 1976, name: '1976' },
    { id: 1975, name: '1975' },
    { id: 1974, name: '1974' },
    { id: 1973, name: '1973' },
    { id: 1972, name: '1972' },
    { id: 1971, name: '1971' },
    { id: 1970, name: '1970' }
]

function getYears(){
    var minYear = 1970;
    var currYear = new Date().getFullYear();
    var years = [];
    for(let i=currYear; i>=minYear;i--){
        years.push({ id: i, name: `${i}` });
    } 
    return years;
}

const copyMinYear = getYears();
copyMinYear.unshift({ id: '', name: 'Min Year' })
export const minYear = copyMinYear;

const copyMaxYear = getYears();
copyMaxYear.unshift({ id: '', name: 'Max Year' });
export const maxYear = copyMaxYear;