import React, { useState, useEffect } from 'react';
import user from '../../store/user';
import './confidentialJobsList.scss';
import { Table } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import CountDisplay from '../../components/CountDisplay';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Heading from '../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import fetchConfidentialJobs from '../../services/fetchConfidentialJobs';
import updateConfidentialStatus from '../../services/updateConfidentialStatus';
import InputBox from '../../components/InputBox';
import { getQueryParams } from "../../utils/utilFunctions";


const PAGE_CONTENT = 50;


const ConfidentialJobsList = (props) => {

  const [content, setContent] = useState({
    list: [],
    count: 0,
    pageNumber: 1
  })

  const [admin, setAdmin] = useState({
    adminId: null,
  })
  const [fetchApiCall, setFetchApiCall] = useState(true)

  const [searchJobId, setSearchJobId] = useState('')

  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id,
        })
        getResult(userData.id);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])

  const getJobsList = async (adminId, pageNumber, filters = {}) => {
    setFetchApiCall(true);
    try {
      routeRequest(pageNumber, filters)
      const res = await fetchConfidentialJobs(adminId, { pageNumber, pageContent: PAGE_CONTENT, ...filters })
      setContent({
        pageNumber: pageNumber,
        list: res.data.data.jobs,
        count: res.data.data.totalCount
      })
      setFetchApiCall(false)
    }
    catch (err) {
      toast.error('Something went wrong');
      console.log(err);
      setFetchApiCall(false)
    }
  }

  const handlePageChange = async(n) => {
      const filterObj = {};
      if(searchJobId){
        filterObj['jobId'] = searchJobId ;
      }
      await getJobsList(admin.adminId, n, filterObj)
  }

  const onSearch = async () => {
      const filterObj = {}
      if(searchJobId.trim()) {
        filterObj['jobId'] = searchJobId;
        await getJobsList(admin.adminId, 1, filterObj)
        routeRequest(1, filterObj)

      }
  }

  const onClickClearSearch = async () => {
    setSearchJobId('');
    await getJobsList(admin.adminId, 1, {})
  }

  const onClickMakeConfBtn = async (jobId, confStatus) => {
    try {

      const newStatus = 1 - confStatus;
      await updateConfidentialStatus(admin.adminId, jobId, {
        cnfi: newStatus
      });
      await getResult(admin.adminId);

      toast.success('Confidentiality updated successfully');

    }catch(e) {
      console.log(e);
      if(e.response.status == 404) {
        toast.error("Job not found")
        return;
      }

      toast.error('Something went wrong')
    }
  }

  const routeRequest = (pageNumber, filters) => {
    let array = [];

    array.push(`pageNumber=${pageNumber}`);
    if(filters.jobId){
      array.push(`jobId=${filters.jobId}`)
    }
    array = array.join("&");
    props.history.push("?" + array);
};

  const changeHandler = (ev) => {
    setSearchJobId(ev.target.value)
  };

  const getResult = async (adminId) => {
    const queryObj = getQueryParams(props.location.search);
    setContent(prev => ({
      ...prev,
      pageNumber: queryObj.pageNumber
    }))
    setSearchJobId(queryObj.jobId ? queryObj.jobId : '');
    const filterObj = {};
    if(queryObj.jobId){
      filterObj['jobId'] = queryObj.jobId;
    }
    const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
    await getJobsList(adminId, pgNo, filterObj);
  }

  const onEnterPress = async (e) => {
    if(e.key == 'Enter') {
       await onSearch();
    }
  }

  return (
    <div className="conf-job-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="conf-job-list-heading">
        <Heading text="Confidential Job Management" />
        <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/confidential_job_panel.php`} target='_blank'>
            <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
        </a>
      </div>

        <hr />
      <div className='search-container'>
            <InputBox
                name="jobId"
                type="number"
                placeholder="Search Job Id"
                value={searchJobId}
                onChange={changeHandler}
                onKeyPress={onEnterPress}
            />
            <Button
                text='Search'
                skin='dark'
                clickButton={onSearch}
            />
            <Button
                skin="light"
                text="Clear"
                clickButton={onClickClearSearch}
            ></Button>
        </div>


      <div className="row text-center">

      <Pagination
          totalItemsCount={content.count}
          content={PAGE_CONTENT}
          pageNumber={content.pageNumber}
          handlePageChange={(n) => handlePageChange(n)} />
      <CountDisplay start={(content.pageNumber - 1) * PAGE_CONTENT + 1} end={content.pageNumber * PAGE_CONTENT} total={content.count} />

        {fetchApiCall ? (
          <Loader />
        ) : (
          content.list.length ? (
            <div className="content">
              <Table
                fixed
                selectable
                className="mailers-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      Job Id
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      Job Title
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Recruiter Info
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Make Job Confidential
                    </Table.HeaderCell>
                    
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {content.list.map((item, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          {item['publishedId']}
                        </Table.Cell>
                        <Table.Cell>
                          {item['title']}
                        </Table.Cell>
                        <Table.Cell>
                          <ul>
                            <li>{item['recId']}</li>
                            <li>{item['recName']}</li>
                          </ul>
                        </Table.Cell>
                    
                        <Table.Cell>
                            <Button skin='light' className="make_conf_btn" text={`${item['cnfi'] ? 'Remove Confidential' : 'Make Confidential'}`} clickButton={() => onClickMakeConfBtn(item['publishedId'], item['cnfi'])}/>  
                        </Table.Cell>

                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <div>
              No Records Found
            </div>
          )
          
        )
        }
      </div>
    </div>
  );
};

export default ConfidentialJobsList;