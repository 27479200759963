import React, { useState, useEffect } from 'react';
import user from '../../../store/user';
import './jobAcquisition.scss';
import { Header, Modal, Table } from 'semantic-ui-react';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import { getQueryParams } from "../../../utils/utilFunctions";
import moment from 'moment';
import { Link } from 'react-router-dom';
import FromToDatePicker from '../../../components/FromToDatepicker';
import ButtonContainer from '../../../components/ButtonContainer';
import fetchAcquiredJobs from '../../../services/fetchAcquiredJobs';


const filtersInitialState = {
  from: moment().subtract(1, 'd').toDate(),
  to: moment().subtract(1, 'd').toDate()
}

const JobAcquisition = (props) => {

  const [content, setContent] = useState({
    list: [],
    total: 0
  })

  const [modalData,setModalData] = useState([])

  const [admin, setAdmin] = useState({
    adminId: null,
    adminType: null
  })
  const [fetchApiCall, setFetchApiCall] = useState(true)

  const [showModal, setShowModal]= useState(false);

  const [filters, setFilters] = useState(filtersInitialState);
  
  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id,
          adminType: userData.adminType
        })
        getResult(userData.id);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])


  const getAcquisitionList = async (adminId, filters = {}) => {
    setFetchApiCall(true);
    const params = {...filters };
    try {
      routeRequest(params)
      const res = await fetchAcquiredJobs(adminId, params)
      setContent({
        list: res.data.data.data,
        total: res.data.data.totalCount
      })
      setFetchApiCall(false)
    }
    catch (err) {
      toast.error('Something went wrong');
      console.log(err);
      setFetchApiCall(false)
    }
  }


  const handleModalClose = () => {
    setShowModal(false);
    setModalData(null);
};

  const onSearch = async () => {
      const filterObj = {}
 
      if(filters.from && filters.to){
        filterObj['from'] = filters.from;
        filterObj['to'] = filters.to;
      }

      await getAcquisitionList(admin.adminId, filterObj)
      routeRequest(filterObj)
  }

  const routeRequest = (filters) => {
    let array = [];

    if(filters.recId){
      array.push(`recId=${filters.recId}`)
    }
    if(filters.from) {
      array.push(`from=${moment(filters.from).format()}`)
    }
    if(filters.to) {
      array.push(`to=${moment(filters.to).format()}`)
    }
    array = array.join("&");
    props.history.push("?" + array);
};


  const getResult = async (adminId) => {
    const queryObj = getQueryParams(props.location.search);
    setContent(prev => ({
      ...prev
    }))

    const from = queryObj.from && moment(queryObj.from).isValid() ? moment(queryObj.from).toDate() : moment().subtract(1, 'd').toDate();
    const to = queryObj.to && moment(queryObj.to).isValid() ? moment(queryObj.to).toDate() : moment().subtract(1, 'd').toDate();

    setFilters({
      from ,
      to
    })
    const filterObj = {
        from,
        to
    };
 
    await getAcquisitionList(adminId, filterObj);
  }


  const handleItemClick = (data) => {
    setModalData(data);
    setShowModal(true);
  }

  return (
    <div className="job-acquisition-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="job-acquisition-list-heading">
        <div>
          <Heading text={`Job Acquisition - (Unique Company Count: ${content.total})`} />
        </div>
      </div>
        <hr />


        <div className='filter-container'>
                <ButtonContainer heading='Filter By Date: '>
                    <li key="postDate">
                        <FromToDatePicker
                            name="date"
                            startDate={filters.from}
                            endDate={filters.to}
                            handleChangeStart={(e) => setFilters({ ...filters, from: e })}
                            handleChangeEnd={(e) => setFilters({ ...filters, to: new Date(new Date(e).setHours(23, 59 ,59)) })}
                        />
                    </li>
                    <li>
                        <Button
                        text='Filter'
                        skin='dark'
                        clickButton={onSearch}
                        disabled={!filters.from || !filters.to} 
                        />
                    </li>
                </ButtonContainer>
                
        </div>

      <div className="row text-center">

        {fetchApiCall ? (
          <Loader />
        ) : (
          content.list.length ? (
            <div className="content">
              <Table
                fixed
                className="mailers-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>
                      S.No
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      No. Companies
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {content.list.map((item, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          { i + 1}
                        </Table.Cell>
                        <Table.Cell>
                          { moment(item['date']).format('DD-MM-YY')}
                        </Table.Cell>
                        <Table.Cell>
                            <a target="blank" onClick={() => handleItemClick(item['data'])}>{item['total']}</a>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <Modal
                  className="premium-history-modal"
                  open={showModal}
                  onClose={handleModalClose}
                  size="fullscreen"
              >
                  {modalData && (
                      <>
                          {/* <Header content={modalItem['title']} /> */}
                          {
                            modalData.length == 0 ? (
                              <div className='no-premium-records'>Sorry, no companies found</div>
                            ) : (
                              <>
                                <Modal.Content scrolling>
                              {
                                  <>
                                     <div>
                                        <Table
                                          fixed
                                          selectable
                                          className="mailers-table"
                                        >
                                          <Table.Header>
                                            <Table.Row>
                                              <Table.HeaderCell width={3}>
                                                S.No
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={6}>
                                                Domain
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={6}>
                                                Type
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                No. of Recruiters
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                               Last Job Posted
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                Last 3 months job All
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                Last 6 months job All
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                Last 3 months job Crawled
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                Last 6 months job Crawled
                                              </Table.HeaderCell>
                                            </Table.Row>
                                          </Table.Header>

                                          <Table.Body>
                                            {modalData.map((item, i) => {
                                              return (
                                                <Table.Row>
                                                  <Table.Cell>
                                                      {i+1}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      {`${[item['domain']]}`}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      { `${item['type'] == 1 ? 'Direct' : 'Consultant'}`}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      {item['recCount']}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      {moment(item['lastPostedDate']).format('DD-MM-YYYY')}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    <Link to={`/?order=desc&pageNumber=1&pageContent=50&domain=${item.domain}`}>{item['last3MonthsJob']}</Link>
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    <Link to={`/?order=desc&pageNumber=1&pageContent=50&domain=${item.domain}`}>{item['last6MonthsJob']}</Link>
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    <Link to={`/?order=desc&type=jobUniverse&pageNumber=1&pageContent=50&domain=${item.domain}`}>{item['last3MonthsJobCrawled']}</Link>
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    <Link to={`/?order=desc&type=jobUniverse&pageNumber=1&pageContent=50&domain=${item.domain}`}>{item['last6MonthsJobCrawled']}</Link>
                                                  </Table.Cell>
                                                </Table.Row>
                                              );
                                            })}
                                          </Table.Body>
                                        </Table>
                                      </div>
                                  </>
                              }
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        clickButton={handleModalClose}
                                        skin="light"
                                        text="Close"
                                    />
                                </Modal.Actions>
                              </>
                            )
                          }
                          
                      </>
                  )}
              </Modal>
            </div>
          ) : (
            <div>
              No Records Found
            </div>
          )
          
        )
        }
      </div>
    </div>
  );
};

export default JobAcquisition;