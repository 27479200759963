import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user from '../../../store/user';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import Heading from '../../../components/Heading';
import Loader from '../../../components/Loader';
import Button from "../../../components/Button";

import { Header, Modal } from 'semantic-ui-react';

import { Table } from "semantic-ui-react";


import "./showcaseList.scss";
import fetchShowcaseCompanies from "../../../services/fetchShowcaseCompanies";
import analyticsCompanyStatus from "../../../models/analyticsCompanyStatus";

import updateShowcaseCompany from "../../../services/updateShowcaseCompany";
import { adminFeatureMapping } from "../../../models/adminFeatureMapping";

const PAGE_CONTENT = 50;
class ShowcaseList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            count: 0,
            pageNumber: 0,
            fetchApiCall: false,
            adminId: null,
            viewAccess: true,
            filter: false,
            deleteModal: false,
            modalItem: null
        }
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    async componentDidMount() {
        try {

            const profile = await user.getProfile();

            // const access = process.env.REACT_APP_SHOWCASE_COMPANIES_LIST_ACCESS_EMAILS.includes(profile.email.toLowerCase());

            this.setState({
                viewAccess: user.checkFeatureAccess(adminFeatureMapping.SHOWCASE_VIEW),
                adminId: profile.id
            })

            await this.getCompanies(1);

        } catch (err) {
            console.log(err);
        }
    }

    async getCompanies(page, filters = {}) {
        const profile = await user.getProfile();

        this.setState({
            fetchApiCall: true
        })
        try {

            const res = await fetchShowcaseCompanies(profile.id, { pageNumber: page, pageContent: PAGE_CONTENT, ...filters })

            const list = this.formatCompanies(res.data.data.data);

            this.setState({
                pageNumber: page,
                list,
                count: res.data.data.totalCount,
                fetchApiCall: false
            })

        } catch (err) {
            console.log(err);
            this.setState({ fetchApiCall: false });
        }
    }

    formatCompanies(companies) {
        return companies.map(item => {

            item.statusName = analyticsCompanyStatus[item.status];
            return item;
        })
    }

    handlePageChange(n) {
        
        this.getCompanies(n);
    }

    edit(company) {
        this.props.history.push({
            pathname: `/showcase/${company.companyName}`,
            state: { company: company }
        });
    }



    newCompany() {
        this.props.history.push(`/newShowcaseCompany`);
    }

    reportGeneration() {
        this.props.history.push(`/analyticsReport`);
    }

    async removeCompany() {

        try {

            const {modalItem} = this.state;

            const data = {
                'companyName': modalItem.companyName,
                'firebaseKeywords': modalItem.firebaseKeywords,
                'gtagKeywords': modalItem.gtagKeywords,
                'jobKeywords': modalItem.jobKeywords,
                'diversityFirebaseKeywords': modalItem.diversityFirebaseKeywords,
                'diversityGtagKeywords': modalItem.diversityGtagKeywords,
                'diversityJobKeywords': modalItem.diversityJobKeywords,
                'showcasePrice': modalItem.showcasePrice,
                'diversityPrice': modalItem.diversityPrice,
                'status': !modalItem.status ? 1:0
            }
            await updateShowcaseCompany(this.state.adminId, modalItem.id, data)
            .then(res => {
                 this.getCompanies(this.state.pageNumber);
                 this.setState({ deleteModal: false, modalItem: null });
            })
            .catch( e => {
                console.log(e);
                this.setState({ deleteModal: false, modalItem: null });
                });

        } catch (error) {
            console.log(error);
        }
    }

    async filterCompany() {
        await this.getCompanies(1, { status: !this.state.filter ? 1 : undefined });
        this.setState({
            filter : !this.state.filter
        });
    }

    handleModalOpen(data) {
        this.setState({deleteModal: true, modalItem: data})
    }
    handleModalClose() {
        this.setState({ deleteModal: false, modalItem: null });
    }


    render() {
        const { list } = this.state;
        const  modalItem = this.state.modalItem || {};
        return (
            <div>
                {
                    this.state.viewAccess ?
                        <div className="wrapper">
                            <ToastContainer position='bottom-left'
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover />

                            <div className="pageHeading">
                                <Heading text='Showcase Companies' />
                                <Button  skin= 'dark' className="report_btn" text="Report Generation" clickButton={() => { this.reportGeneration(); }} />                               
                                <Button  skin='dark' className="btn_company" text="New Company" clickButton={() => { this.newCompany(); }} />
                                <Button  skin= 'dark' className="btn_filter" text={`Show ${ this.state.filter ? 'All' : 'Active'}`} clickButton={() => { this.filterCompany(); }} />
                            </div>
                            <hr />
                            <div className='row text-center'>
                                <Pagination
                                    totalItemsCount={this.state.count}
                                    content={PAGE_CONTENT}
                                    pageNumber={this.state.pageNumber}
                                    handlePageChange={(n) => this.handlePageChange(n)} />
                                <CountDisplay start={(this.state.pageNumber - 1) * PAGE_CONTENT + 1} end={this.state.pageNumber * PAGE_CONTENT} total={this.state.count} />

                                {
                                    this.state.fetchApiCall ?
                                        <Loader /> :
                                        <div className="content">
                                            <Table fixed selectable className="mailers-table">
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={4} >ID</Table.HeaderCell>
                                                        <Table.HeaderCell width={8} >Name</Table.HeaderCell>
                                                        <Table.HeaderCell width={8} >Firebase Keywords</Table.HeaderCell>
                                                        <Table.HeaderCell width={8} >GTAG Keywords</Table.HeaderCell>
                                                        <Table.HeaderCell width={8}>Job Detail Keywords</Table.HeaderCell>
                                                        <Table.HeaderCell width={8} >Diversity Firebase Keywords</Table.HeaderCell>
                                                        <Table.HeaderCell width={8} >Diversity GTAG Keywords</Table.HeaderCell>
                                                        <Table.HeaderCell width={8}>Company Code</Table.HeaderCell>
                                                        <Table.HeaderCell width={8}>Showcase Price</Table.HeaderCell>
                                                        <Table.HeaderCell width={8}>Diversity Price</Table.HeaderCell>
                                                        <Table.HeaderCell width={4}>Status</Table.HeaderCell>
                                                        <Table.HeaderCell width={12}>Actions</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {
                                                        list.map((item, i) => {
                                                            return (
                                                               
                                                                    <Table.Row>
                                                                    <Table.Cell>{item.id}</Table.Cell>
                                                                    <Table.Cell>{item.companyName}</Table.Cell>
                                                                    <Table.Cell>{item.firebaseKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.gtagKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.jobKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.diversityFirebaseKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.diversityGtagKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.diversityJobKeywords}</Table.Cell>
                                                                    <Table.Cell>{item.showcasePrice}</Table.Cell>
                                                                    <Table.Cell>{item.diversityPrice}</Table.Cell>
                                                                    <Table.Cell>{item.statusName}</Table.Cell>

                                                                    <Table.Cell>
                                                                        {
                                                                            
                                                                                <Button skin='dark' text="Edit" clickButton={this.edit.bind(this, item)} /> 
                                                                        }
                                                                        {
                                                                            
                                                                                <Button skin={item.status ? 'dark' : 'light'} text={item.status ? "Inactive" : "Active"} clickButton={this.handleModalOpen.bind(this, item)} />
                                                                        }
                                                                        
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                
                                                            )
                                                        })
                                                    }
                                                </Table.Body>
                                            </Table>
                                            <Modal open={this.state.deleteModal} onClose={this.handleModalClose}>
                                                <Header content={modalItem.status ? "Inactivate" : "Active"} />
                                                <Modal.Content>
                                                    {
                                                        <p>Do you want to make this company {modalItem.status ? "inactive" : "Active"}? </p>
                                                    }
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button clickButton={this.removeCompany.bind(this)} skin='dark' text={modalItem.status ? "Inactivate" : "Active"}/>
                                                    <Button clickButton={this.handleModalClose} skin="light" text="Close"/>
                                                   
                                                </Modal.Actions>
                                            </Modal>
                                        </div>

                                }


                            </div>

                        </div>
                        : <div>
                            You don't have showcase company access
                        </div>
                }
            </div>
        )
    }

}

export default ShowcaseList;