const estimatedRevenue = [
    { label: "Less than $1M", value: "1" },
    { label: "$1M to $10M", value: "2" },
    { label: "$10M to $50M", value: "3" },
    { label: "$50M to $100M", value: "4" },
    { label: "$100M to $500M", value: "5" },
    { label: "$500M to $1B", value: "6" },
    { label: "$1B to $10B", value: "7" },
    { label: "$10B+", value: "8" },
];

export function getEstimatedRevenueArr() {
    return estimatedRevenue;
};