import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import Heading from '../../components/Heading';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import TableItem from './TableItem';

import user from '../../store/user';
import getUnicornData from '../../services/getUnicornData';
import getUnicornDataCount from '../../services/getUnicornDataCount';

import './CompanyList.scss';

const CompanyList = () => {
    const PAGE_CONTENT = 10;

    const [adminId, setAdminId] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [filterError, setFilterError] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);

    const [cummulativeData, setCummulativeData] = useState({
        isLoading: true,
        data: []
    });

    const [dailyDataController, setDailyDataController] = useState({
        unicorn: {
            isLoading: true,
            data: [],
            total: 0,
            pageNumber: 1,
            allLoaded: false
        },
        premium: {
            isLoading: true,
            data: [],
            total: 0,
            pageNumber: 1,
            allLoaded: false
        }
    });

    const handlePageChange = async (page, type) => {
        if(dailyDataController[type].isLoading) {
            return;
        }

        setDailyDataController(prevValue => {
            const newValue = { ...prevValue };
            newValue[type] = {
                ...prevValue[type],
                isLoading: true,
                pageNumber: page
            };
            return newValue;
        });

        try {
            await getCompanyData(type, page);
        } catch (error) {
            console.log(error);
            setDailyDataController(prevValue => {
                const newValue = { ...prevValue };
                newValue[type] = {
                    ...newValue[type],
                    isLoading: false
                };
                return newValue;
            });
        }
    }

    const getCompanyDataCount = async (type, applyFilter = false) => {
        if (!adminId) {
            return;
        }

        try {
            const result = await getUnicornDataCount(adminId, {
                type,
                startDate: applyFilter ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: applyFilter ? moment(endDate).format('YYYY-MM-DD') : null
            });
            
            if (type === 'unicorn') {
                setDailyDataController(prevValue => {
                    return {
                        ...prevValue,
                        unicorn: {
                            ...prevValue.unicorn,
                            total: result.data.data.count
                        }
                    }
                });
            } else if (type === 'premium') {
                setDailyDataController(prevValue => {
                    return {
                        ...prevValue,
                        premium: {
                            ...prevValue.premium,
                            total: result.data.data.count
                        }
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getCompanyData = async (type, page, doFilter = false) =>{

        if (!adminId) {
            return;
        }

        let filterData = doFilter || applyFilter;

        try {
            const response = await getUnicornData(adminId, {
                type: type,
                pageNumber: page,
                pageContent: PAGE_CONTENT,
                startDate: filterData ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: filterData ? moment(endDate).format('YYYY-MM-DD') : null
            });

            const responseData = response.data.data;
            if (type === 'unicorn') {
                setDailyDataController(prevValue => {
                    return {
                        ...prevValue,
                        unicorn: {
                            ...prevValue.unicorn,
                            data: responseData,
                            isLoading: false,
                            allLoaded: responseData.length < PAGE_CONTENT ? true : false
                        }
                    }
                });
            } else if (type === 'premium') {
                setDailyDataController(prevValue => {
                    return {
                        ...prevValue,
                        premium: {
                            ...prevValue.premium,
                            data: responseData,
                            isLoading: false,
                            allLoaded: responseData.length < PAGE_CONTENT ? true : false
                        }
                    }
                });
            } else if (type === 'unicorn_cummulative') {
                setCummulativeData(prevValue => {
                    return {
                        ...prevValue,
                        data: [...prevValue.data, ...responseData],
                        isLoading: false
                    }
                });
            } else if (type === 'premium_cummulative') {
                setCummulativeData(prevValue => {
                    return {
                        ...prevValue,
                        data: [...prevValue.data, ...responseData],
                        isLoading: false
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        async function getUserProfile () {
            const profile = await user.getProfile();
            return profile;
        };

        getUserProfile().then(profile => {
            setAdminId(profile.id);
        })
    }, []);

    useEffect(() => {
        // daily data
        getCompanyDataCount('unicorn', false);
        getCompanyDataCount('premium', false);
        getCompanyData('unicorn', 1, false);
        getCompanyData('premium', 1, false);

        // cummulative data
        getCompanyData('unicorn_cummulative', 1);
        getCompanyData('premium_cummulative', 1);
    }, [adminId]);

    const getFilteredData = filter => {
        getCompanyDataCount('unicorn', filter);
        getCompanyDataCount('premium', filter);
        getCompanyData('unicorn', 1, filter);
        getCompanyData('premium', 1, filter);
    }

    const handleApplyFilter = () => {
        if (startDate > endDate) {
            setFilterError(true);
            return;
        }

        setFilterError(false);
        getFilteredData(true);
    }

    // on click remove filter
    const handleRemoveFilter = () => {
        // reset dates
        setStartDate(new Date());
        setEndDate(new Date());

        // remove filters
        setApplyFilter(false);
        setFilterError(false);

        // get original data
        getFilteredData(false);
    }

    const tableHeaderCummulative = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Company Type</Table.HeaderCell>
                <Table.HeaderCell>Number of Companies Registered</Table.HeaderCell>
                <Table.HeaderCell>Number of Recruiters Registered</Table.HeaderCell>
                <Table.HeaderCell>6-months Active Companies</Table.HeaderCell>
                <Table.HeaderCell>3-months Active Companies</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    const tableHeaderDaily = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Number of Jobs Posted</Table.HeaderCell>
                <Table.HeaderCell>Number of Published Jobs</Table.HeaderCell>
                <Table.HeaderCell>Number of Applications</Table.HeaderCell>
                <Table.HeaderCell>Number of Active Companies</Table.HeaderCell>
                <Table.HeaderCell>New Recruiter Registrations</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    const unicornDailyData = (
        <div className="data__container--unicorn">
            <Heading text="Daily Data - Unicorns" />

            <div className="row top-page-controller">
                <Pagination totalItemsCount={dailyDataController.unicorn.total} content={PAGE_CONTENT} pageNumber={dailyDataController.unicorn.pageNumber} handlePageChange={(page) => handlePageChange(page, 'unicorn')} />
                <CountDisplay start={(dailyDataController.unicorn.pageNumber - 1) * PAGE_CONTENT + 1} end={dailyDataController.unicorn.pageNumber * PAGE_CONTENT} total={dailyDataController.unicorn.total} />
            </div>

            <Table>
                {tableHeaderDaily}
                {dailyDataController.unicorn.isLoading ? (
                    <Loader />
                ) : (
                    <Table.Body>
                        {dailyDataController.unicorn.data.map(item => {
                        if (item) {
                            return <TableItem data={item} type={item.type} />
                        }
                    })}
                    </Table.Body>
                )}
            </Table>
        </div>
    );

    const premiumDailyData = (
        <div className="data__container--premium">
            <Heading text="Daily Data - Premium Companies" />

            <div className="row top-page-controller">
                <Pagination totalItemsCount={dailyDataController.premium.total} content={PAGE_CONTENT} pageNumber={dailyDataController.premium.pageNumber} handlePageChange={(page) => handlePageChange(page, 'premium')} />
                <CountDisplay start={(dailyDataController.premium.pageNumber - 1) * PAGE_CONTENT + 1} end={dailyDataController.premium.pageNumber * PAGE_CONTENT} total={dailyDataController.premium.total} />
            </div>

            <Table>
                {tableHeaderDaily}
                <Table.Body>
                    {
                        dailyDataController.premium.isLoading ? (
                            <Loader />
                        ) : (
                            dailyDataController.premium.data.map(item => {
                                if (item) {
                                    return <TableItem data={item} type={item.type} />
                                }
                            })
                        )
                    }
                </Table.Body>
            </Table>
        </div>
    );

    const cummulativeDataContent = (
        <div className="data__container--cummulative">
            <Heading text="Cummulative Data" />

            <Table>
                {tableHeaderCummulative}
                {cummulativeData.isLoading ? (
                    <Loader />
                ) : (
                    <Table.Body>
                        {cummulativeData.data.map(item => {
                        if (item) {
                            return <TableItem data={item} type={item.type}/>
                        }
                    })}
                    </Table.Body>
                )}
            </Table>
        </div>
    );

    return (
        <div className="page-content">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />
            
            <div className="page-heading">
                <Heading text={'Unicorn/Premium Company Dashboard'} />
            </div>

            <div className="date__controller">
                <div className="date__controller--main">
                    <div>From: </div>
                    <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
                    <div>To: </div>
                    <ReactDatePicker selected={endDate} onChange={(date) => setEndDate(date)}/>
                    <Button text="Apply Filter" skin="dark" clickButton={handleApplyFilter}/>
                    <Button text="Remove Filter" skin="dark" clickButton={handleRemoveFilter}/>
                </div>
                <div className="date__controller--error">
                    {filterError ? (
                        <p>Start date cannot be greater than end date</p>
                    ) : null}
                </div>
            </div>

            {cummulativeDataContent}
            {unicornDailyData}
            {premiumDailyData}
        </div>
    );
};

export default CompanyList;