import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Aux from '../Aux';
import './Navbar.scss';
import user from '../../store/user';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import { navbar, getNavbarSubItems, profile, getNavbarItems } from '../../models/navbar';
import { Link } from 'react-router-dom';
import logout from '../../services/logout';

export default class MenuExampleInvertedSegment extends Component {
    state = {
        activeItem: 'home',
        navbarDisplay: false,
        email: null,
        adminType: null
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    toggleNavbar = () => {
        this.setState({
            navbarDisplay: !this.state.navbarDisplay
        })
    }

    logout = async () => {
        Cookies.remove(process.env.REACT_APP_COOKIE_NAME, { domain: process.env.REACT_APP_DOMAIN });
        Cookies.remove(process.env.REACT_APP_OLD_COOKIE_NAME, { domain: process.env.REACT_APP_DOMAIN });
        const profile = await user.getProfile();
        await logout(profile.id);
    }

    componentDidMount = async () => {
        const profile = await user.getProfile();
       
        const email = profile.email;
        const image = profile.image;
        const adminType = profile.adminType;
        this.setState({ email: email, image: image, adminType: adminType })
        
    }

    render() {
        const { activeItem } = this.state;
        const isMobile = window.innerWidth <= 500;

        return (
            <div className='header-nav'>
                <div className='hdr whitebg'>
                    <div className='container-fluid myconfield'>
                        <div className='logo'>
                            <div className='navbar-header'>
                                <Icon name='bars' size='large' onClick={this.toggleNavbar} />
                            </div>
                            <a href={navbar.category} className='sitename pull-left'>
                                <h3>{process.env.REACT_APP_DOMAIN}</h3>
                                {/* <span>.com</span> */}
                            </a>
                        </div>
                        <div className='righthdr'>
                            <ul className='newrtoption'>
                                <li className='newposrel '>
                                    <span className='hidden-xs'>
                                        <a href={profile[0]["My profile"]}>
                                            <span className='usim'><img src={this.state.image}/></span>
                                            <span className='pdleft5'>
                                                {this.state.email}
                                            </span>
                                        </a>
                                        <span className='caret'></span>
                                    </span>
                                    <div className='newpositionb mobmenu-pop' id=''>
                                        <span className='arrb visible-xs'></span>
                                        <ul className='newrtopul'>
                                            {profile.map((val, i) => {
                                                for (const key in val) {
                                                    return <li><a href={val[key]} onClick={key=='Logout' ? this.logout : null } >{key}</a></li>
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </li>
                                <li className='posrel visible-xs' style={{ visibility: 'hidden' }}><span className='filterhor'><span className='glyphicon glyphicon-filter fltr'></span></span></li>
                                <li className='posrel visible-xs'><span className='pophor'><span className='glyphicon glyphicon-user usr'></span></span> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='navbar mynavbr' style={{ display: isMobile ? (this.state.navbarDisplay ? 'inline-block' : 'none') : 'inline-block' }}>
                    <div className='container-fluid myconfield'>
                        <div id='navbar' className='navbar-collapse collapse mynavcollapse'>
                            <ul className='nav navbar-nav newmynavbar-nav newmynavbar-navres'>
                                {
                                    getNavbarItems(this.state.adminType).map((val, i) => {
                                        if(val['list']) {
                                            for (const key in val['data']) {
                                                return <li><a className='nav-link' href='javascript:void(0);'>{key}</a>
                                                <div className='navbarnewhovm'>
                                                    <ul className='navbarnewhovmul'>
                                                        {getNavbarSubItems(val['itemType'], this.state.adminType).map((val, i) => {
                                                            for (const key in val) {
                                                                return <li><a href={val[key]}>{key}</a></li>
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                                </li>
                                            }
                                        }
                                        else {
                                            for(const key in val['data']) {
                                                return <li><Link className='nav-link' to={val['data'][key]}>{key}</Link></li>

                                            }
                                        }
                                        
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}