const Criteria = [
    {
        name: "Select Criteria",
        id: '0'
    },
    {
        name: "Users based on job feed tags (existing). Active in last 1 year.",
        id: '1'
    },
    {
        name: "Users based on tags for jobs applied in last 12 months",
        id: '2'
    },
    {
        name: "Users based on matching current designation",
        id: '3'
    },
    {
        name: "Users based on matching tags on shortlisted jobs in last 12 months",
        id: '4'
    },
    {
        name: "Users based on resume tags(top 10 / 20)",
        id: '5'
    },
    {
        name: "Users based on search tag history(in last 6 months)",
        id: '6'
    },
    {
        name: "Combination of 1, and 3(May result in lower result set but it will be more targeted)",
        id: '7'
    },
    {
        name: "Combination of 2, and 3(May result in lower result set but it will be more targeted)",
        id: '8'
    },
    {
        name: "Combination of 4, and 3(May result in lower result set but it will be more targeted)",
        id: '9'
    },
    {
        name: "Combination of 5, and 3(May result in lower result set but it will be more targeted)",
        id: '10'
    },
    {
        name: "Combination of 6, and 3(May result in lower result set but it will be more targeted)",
        id: '11'
    }
]

export const criteriaDesc = {
    1: "In this approach, users will be selected based on the basis of jobfeed tags. Users who are active in last 1 year, will be considered.",
    2: "In this approach, users will be selected based on the job tags applied by them in last 12 months. Users who are active in last 1 year, will be considered.",
    3: "In this approach, users will be selected based on their current designation. Users who are active in last 1 year, will be considered.",
    4: "In this approach, users will be selected based on their matching for which they were shortlisted by any recruiter in last 1 year. Users who are active in last 1 year, will be considered.",
    5: "In this approach, users will be selected based on the matching tags in their resume. Users who are active in last 1 year, will be considered.",
    6: "In this approach, users will be selected based on their job search history. Users who are active in last 1 year, will be considered.",
    7: "In this approach, users will be selected based on the basis of jobfeed tags and matching current designation as specified. Users who are active in last 1 year, will be considered.",
    8: "In this approach, users will be selected based on the job tags applied by them in last 12 months and matching current designation as specified. Users who are active in last 1 year, will be considered.",
    9: "In this approach, users will be selected based on their matching for which they were shortlisted by any recruiter in last 1 year and matching current designation as specified. Users who are active in last 1 year, will be considered.",
    10: "In this approach, users will be selected based on the matching tags in their resume and matching current designation as specified. Users who are active in last 1 year, will be considered.",
    11: "In this approach, users will be selected based on their job search history and matching current designation as specified. Users who are active in last 1 year, will be considered."
}

export const criteriaMap = Criteria.reduce((p,c) => { p[c.id] = c.name; return p; }, {})
export default Criteria;