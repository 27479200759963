import React, { useEffect } from "react";
import { useState } from "react";
import { Header, Modal } from "semantic-ui-react";
import { getCourseTypes } from "../../models/courseType";
import { getDegrees } from "../../models/degree";
import { getFA } from "../../models/functionalAreas";
import { getInstitutes } from "../../models/institutes";
import { getLanguages } from "../../models/language";
import { locationListLabel } from "../../models/locationSeeker";
import { maxSalary, minSalary } from "../../models/salary";
import Button from "../Button";
import InputBox from "../InputBox";
import Dropdown from "../Dropdown";


import "./multiFilterModal.scss";
import { maxExperience, minExperience } from "../../models/experience";
import { maxYear, minYear } from "../../models/year";
import { maxAge, minAge } from "../../models/age";
import { noticePeriodList } from "../../models/noticePeriod";
import { applicationDateList } from "../../models/applicationDate";
import { lastSeenList } from "../../models/lastSeen";
import { yesNo } from "../../models/YesNo";
import { workPermit } from "../../models/workPermit";

const MultiFilterModal = (props) => {

    const [searchKeywords, setSearchKeywords] = useState({
        curLoc: '',
        prefLoc: '',
        institute: '',
        degree: '',
        fa: '',
        language: '',
        courseType: '',
        tags: '',
        org: '',
        curOrg: ''
    })
    const [filterData, setFilterData] = useState({
        curLoc: locationListLabel,
        prefLoc: locationListLabel,
        institute: getInstitutes(),
        degree: getDegrees(),
        fa: getFA(),
        language: getLanguages(),
        courseType: getCourseTypes(),
        tags: props.tags,
        org: props.org,
        curOrg: props.currOrg
    })
    
    useEffect(() => {
        setFilterData(prev => ({
            ...prev,
            tags: props.tags,
            org: props.org,
            curOrg: props.currOrg
        }));
    }, [props.tags, props.org, props.curOrg]);


    const applyFilter = () => {
        props.onApply();
    };

    const resetFilter = () => {
        props.onReset()
    };

    const onChangeSearch = (e) => {
        const val = e.target.value;
        const name = e.target.name;
        let fullList = null;
        if (name == "org") {
            fullList = props.org;
        }
        if (name == "curOrg") {
            fullList = props.currOrg;
        }
        if (name == "curLoc") {
            fullList = locationListLabel;
        }
        if (name == "prefLoc") {
            fullList = locationListLabel;
        }
        if (name == "institute") {
            fullList = getInstitutes();
        }
        if (name == "degree") {
            fullList = getDegrees();
        }
        if (name == "fa") {
            fullList = getFA();
        }
        if (name == "language") {
            fullList = getLanguages();
        }
        if (name == "courseType") {
            fullList = getCourseTypes();
        }
        if (name == "tags") {
            fullList = props.tags;
        }

        setSearchKeywords(prev => ({
            ...prev,
            [name]: val
        }))

        if (!val) {
            setFilterData(prev => ({
                ...prev,
                [name]: fullList
            }))
            return;
        }
        const newList = fullList.filter((item) => {
            return item.label.toLowerCase().indexOf(val) > -1;
        });

        setFilterData(prev => ({
            ...prev,
            [name]: newList
        }))
    };

    const onChangeCheckBox = (e) => {
        const val = e.target.value;
        const name = e.target.name;

        props.onCheckboxChange(val, name);
        
    };

    const handleSalaryDropDownChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        props.setSalaryFilter(prev => ({
            ...prev,
            [name]: val
        }))
    }

    const handleExpDropDownChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        props.setExperienceFilter(prev => ({
            ...prev,
            [name]: val
        }))
    }

    const handleBatchDropDownChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        props.setBatchFilter(prev => ({
            ...prev,
            [name]: val
        }))
    }
    
    const handleAgeDropDownChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        prop.setAgeFilter(prev => ({
            ...prev,
            [name]: val
        }))
    }

    const handleDropDownChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        props.onDropdownChange(val, name);

    }

    return (
        <Modal
            className="applications-filter-modal"
            open={props.open}
            onClose={props.onClose}
            size="large"
        >
            <Header content="Filter By" />
            <Modal.Content scrolling>
                {
                    <>
                        <div className="filter-wrapper">
                            <h3 className="filter-header">Current Location</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['curLoc']}
                                        onChange={onChangeSearch}
                                        name="curLoc"
                                    />
                                </div>
                                {filterData['curLoc'].map((location) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={location.key}
                                                checked={
                                                    props.selectedFilters['curLoc'][location.key]
                                                }
                                                name="curLoc"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {location.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">
                                Preferred Location
                            </h3>
                            <div className="filter-search-wrapper">
                                <InputBox
                                    placeholder="Search"
                                    value={searchKeywords['prefLoc']}
                                    onChange={onChangeSearch}
                                    name="prefLoc"
                                />
                            </div>
                            {filterData['prefLoc'].map((location) => (
                                <div className="filter-checkbox">
                                    <div className="filter-checkbox-wrapper">
                                        <InputBox
                                            type="checkbox"
                                            value={location.key}
                                            checked={
                                                props.selectedFilters['prefLoc'][location.key]
                                            }
                                            name="prefLoc"
                                            onChange={onChangeCheckBox}
                                        />
                                    </div>

                                    {location.label}
                                </div>
                            ))}
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">
                                Current Organization
                            </h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['curOrg']}
                                        onChange={onChangeSearch}
                                        name="curOrg"
                                    />
                                </div>
                                {filterData['curOrg'].map((curOrg) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={curOrg.key}
                                                checked={
                                                    props.selectedFilters['curOrg'][curOrg.key]
                                                }
                                                name="curOrg"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {curOrg.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">All Organization</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['org']}
                                        onChange={onChangeSearch}
                                        name="org"
                                    />
                                </div>
                                {filterData['org'].map((org) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={org.key}
                                                checked={props.selectedFilters['org'][org.key]}
                                                name="org"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {org.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Institute</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['institute']}
                                        onChange={onChangeSearch}
                                        name="institute"
                                    />
                                </div>
                                {filterData['institute'].map((institute) => (
                                    <div className="filter-checkbox filter-checkbox-small">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={institute.key}
                                                checked={
                                                    props.selectedFilters['institute'][institute.key]
                                                }
                                                name="institute"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {institute.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Degree</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['degree']}
                                        onChange={onChangeSearch}
                                        name="degree"
                                    />
                                </div>
                                {filterData['degree'].map((degree) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={degree.key}
                                                checked={
                                                    props.selectedFilters['degree'][degree.key]
                                                }
                                                name="degree"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {degree.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Functional Area</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['fa']}
                                        onChange={onChangeSearch}
                                        name="fa"
                                    />
                                </div>
                                {filterData['fa'].map((fa) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={fa.key}
                                                checked={props.selectedFilters['fa'][fa.key]}
                                                name="fa"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {fa.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Language</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['language']}
                                        onChange={onChangeSearch}
                                        name="language"
                                    />
                                </div>
                                {filterData['language'].map((language) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={language.key}
                                                checked={
                                                    props.selectedFilters['language'][language.key]
                                                }
                                                name="language"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {language.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">
                                MBA/PGDM Course Type
                            </h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['courseType']}
                                        onChange={onChangeSearch}
                                        name="courseType"
                                    />
                                </div>
                                {filterData['courseType'].map((courseType) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={courseType.key}
                                                checked={
                                                    props.selectedFilters['courseType'][courseType.key]
                                                }
                                                name="courseType"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {courseType.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Tags</h3>
                            <div className="filter-content">
                                <div className="filter-search-wrapper">
                                    <InputBox
                                        placeholder="Search"
                                        value={searchKeywords['tags']}
                                        onChange={onChangeSearch}
                                        name="tags"
                                    />
                                </div>
                                {filterData['tags'].map((tag) => (
                                    <div className="filter-checkbox">
                                        <div className="filter-checkbox-wrapper">
                                            <InputBox
                                                type="checkbox"
                                                value={tag.key}
                                                checked={props.selectedFilters['tags'][tag.key]}
                                                name="tags"
                                                onChange={onChangeCheckBox}
                                            />
                                        </div>

                                        {tag.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Salary</h3>
                            <div className="filter-content">
                            
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={handleSalaryDropDownChange} name='min' defaultValue={props.salaryFilter.min} data={minSalary} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={handleSalaryDropDownChange} name='max' defaultValue={props.salaryFilter.max} data={maxSalary} />
                            </div>

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Experience</h3>
                            <div className="filter-content">
                            
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={handleExpDropDownChange} name='min' defaultValue={props.experienceFilter.min} data={minExperience} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={handleExpDropDownChange} name='max' defaultValue={props.experienceFilter.max} data={maxExperience} />
                            </div>

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Batch</h3>
                            <div className="filter-content">
                            
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={handleBatchDropDownChange} name='min' defaultValue={props.batchFilter.min} data={minYear} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={handleBatchDropDownChange} name='max' defaultValue={props.batchFilter.max} data={maxYear} />
                            </div>

                            </div>
                        </div>

                        {/* <div className="filter-wrapper">
                            <h3 className="filter-header">Batch</h3>
                            <div className="filter-content">
                            
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={handleBatchDropDownChange} name='min' defaultValue={props.batchFilter.min} data={minAge} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={handleBatchDropDownChange} name='max' defaultValue={props.batchFilter.max} data={maxAge} />
                            </div>

                            </div>
                        </div> */}

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Age</h3>
                            <div className="filter-content">
                            
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={handleAgeDropDownChange} name='min' defaultValue={props.ageFilter.min} data={minAge} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={handleAgeDropDownChange} name='max' defaultValue={props.ageFilter.max} data={maxAge} />
                            </div>

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Notice Period</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='noticePeriod' defaultValue={props.selectedFilters['noticePeriod']} data={noticePeriodList} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Application Date</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='applicationDate' defaultValue={props.selectedFilters['applicationDate']} data={applicationDateList} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Last Seen</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='lastSeen' defaultValue={props.selectedFilters['lastSeen']} data={lastSeenList} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Willing to relocate?</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='relocate' defaultValue={props.selectedFilters['relocate']} data={yesNo} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Work Permit for USA</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='workPermit' defaultValue={props.selectedFilters['workPermit']} data={workPermit} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Handled team?</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='handledTeam' defaultValue={props.selectedFilters['handledTeam']} data={yesNo} />

                            </div>
                        </div>

                        <div className="filter-wrapper">
                            <h3 className="filter-header">Differently Abled?</h3>
                            <div className="filter-content">
                            
                            <Dropdown dropdownChange={handleDropDownChange} name='differentlyAbled' defaultValue={props.selectedFilters['differentlyAbled']} data={yesNo} />

                            </div>
                        </div>

                        {/* <div className="filter-wrapper">
                            <h3 className="filter-header">Assessment</h3>
                            <div className="filter-content">
                            
                            <div>Score</div>
                            <div className="filter-dropdown">
                                <InputBox type="number" value={score.min} onChange={onChangeScore} name='min' placeholder='Min'></InputBox>
                            </div>
                            <div className="filter-dropdown filter-right">
                                <InputBox type='number' value={score.max} onChange={onChangeScore} name='max' placeholder='Max'></InputBox>
                            </div>

                            <div>Time Taken</div>
                            <div className="filter-dropdown">
                                <Dropdown dropdownChange={onChangeTimeTaken} name='min' defaultValue={timeTaken.min} data={minTimeTaken} />
                            </div>
                            <div className="filter-dropdown filter-right">
                                <Dropdown dropdownChange={onChangeTimeTaken} name='max' defaultValue={timeTaken.max} data={maxTimeTaken} />
                            </div>

                            <div>Attempted On</div>
                            <FromToDatePicker
                                        name="assessmentDate"
                                        startDate={assessmentDateRange.startDate}
                                        endDate={assessmentDateRange.endDate}
                                        maxDate={moment()
                                            .subtract(1, "day")
                                            .startOf("day")
                                            .toDate()}
                                        handleChangeStart={(e) => {
                                            setAssessmentDateRange({
                                                ...assessmentDateRange,
                                                startDate: e,
                                            });
                                        }}
                                        handleChangeEnd={(e) => {
                                            setAssessmentDateRange({
                                                ...assessmentDateRange,
                                                endDate: e,
                                            });
                                        }}
                                    />

                            </div>
                        </div> */}

                    </>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button clickButton={applyFilter} skin="dark" text="Apply" />
                <Button clickButton={resetFilter} skin="light" text="Reset" />
            </Modal.Actions>
        </Modal>
    );
};

export default MultiFilterModal;
