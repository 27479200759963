import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox, Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Loader from '../../components/Loader';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import TableItem from './components/TableItem/TableItem';

import user from '../../store/user';
import getManagerTeam from '../../services/getManagerTeam';
import getManagerTeamCount from '../../services/getManagerTeamCount';
import fetchRecruiterProfile from '../../services/fetchRecruiterProfile';
import updateManagedRecruiterStatus from '../../services/updateManagedRecruiterStatus';

import { getQueryParams } from '../../utils/utilFunctions';

import './styles.scss';

const checkIfNumber = (value) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
        return true;
    }
    return false;
}

const ManagerTeamMgt = props => {
    const [adminId, setAdminId] = useState(null);

    // controller for main list
    const [pageController, setPageController] = useState({
        pageNumber: 0,
        pageContent: 50,
        recruiterList: [],
        total: 0,
        isLoading: true,
        isPageLoading: false,
        callFetchApi: false,
        searchText: '',
        managerId: null,
        managerProfile: {},
        isSelectAll: false,
        selectedRecruiters: [],
    });

    // controller for search list
    const [searchController, setSearchController] = useState({
        recruiterList: [],
        pageNumber: 0,
        pageContent: 50,
        total: 0,
        isSelectAll: false,
        isLoading: false,
        isPageLoading: false,
        searchValue: '',
        callSearchApi: false,
        selectedRecruiters: [],
    })

    const setSearchData = obj => {
        setSearchController(prevObj => {
            return {
                ...prevObj,
                ...obj,
            };
        });
    };

    const setPageData = obj => {
        setPageController(prevObj => {
            return {
                ...prevObj,
                ...obj,
            };
        });
    };

    const getTableHeading = (isActiveRecruiter = true) => {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="one">ID</Table.HeaderCell>
                    <Table.HeaderCell width="two">Name</Table.HeaderCell>
                    <Table.HeaderCell width="two">Email</Table.HeaderCell>
                    <Table.HeaderCell width="two">Phone</Table.HeaderCell>
                    <Table.HeaderCell width="two">Organisation</Table.HeaderCell>
                    <Table.HeaderCell width="one">Actions</Table.HeaderCell>
                    {isActiveRecruiter ? <Table.HeaderCell width="one">More</Table.HeaderCell> : null}
                    <Table.HeaderCell width="one">
                        <Checkbox
                            checked={isActiveRecruiter ? pageController.isSelectAll : searchController.isSelectAll}
                            onChange={() => handleSelectAll(!isActiveRecruiter ? 'search' : '')}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    // handle Select all checkbox click
    const handleSelectAll = (source) => {
        if (source === 'search') {
            const selectAll = !searchController.isSelectAll;
            setSearchData({
                isSelectAll: selectAll,
                selectedRecruiters: selectAll ? searchController.recruiterList.map(item => item.id) : [],
            });
        } else {
            const selectAll = !pageController.isSelectAll;
            setPageData({
                isSelectAll: selectAll,
                selectedRecruiters: selectAll ? pageController.recruiterList.map(item => item.id) : [],
            });
        }
        
    };

    const handleSingleCheckboxChange = (recruiterId, source) => {
        const controllerFunc = (prevObj) => {
            let selectedArr = prevObj.selectedRecruiters;
            if(selectedArr.includes(recruiterId)) {
                selectedArr = selectedArr.filter(item => item != recruiterId);
            } else {
                selectedArr.push(recruiterId);
            }

            return {
                ...prevObj,
                selectedRecruiters: selectedArr,
            }
        };

        if (source === 'search') {
            setSearchController(controllerFunc);
        } else {
            setPageController(controllerFunc);
        }
    }

    const getManagerDetails = async () => {
        try {
            const response = await fetchRecruiterProfile(adminId, { id: pageController.managerId });

            if (response && response.data) {
                setPageData({
                    managerProfile: {
                        name: response.data.name,
                        email: response.data.email,
                        phone: response.data.phone,
                        organisation: response.data.organisation
                    }
                });
            }
        } catch (error) {
            // console.log(error);
        }
    }

    const getRecruiterList = async (params, isSearch = false) => {
        try {
            if (!params.mgrId) {
                const obj = {
                    recruiterList: [],
                    isLoading: false,
                    isPageLoading: false,
                };

                setPageData({
                    ...obj,
                    callFetchApi: false,
                });
                setSearchData({
                    ...obj,
                    callSearchApi: false,
                });
                return;
            }

            const response = await getManagerTeam(adminId, params);

            let arr = [];

            if (response && response.data && response.data.data) {
                arr = response.data.data;
            }

            if (isSearch) {
                setSearchData({
                    recruiterList: arr,
                    isLoading: false,
                    isPageLoading: false,
                    callSearchApi: false,
                })
            } else {
                setPageData({
                    recruiterList: arr,
                    isLoading: false,
                    isPageLoading: false,
                    callFetchApi: false,
                });
            }
        } catch (error) {
            // console.log(error);
        }
    };

    const getRecruiterListCount = async (params, isSearch = false) => {
        try {
            if (!params.mgrId) {
                setPageData({ callFetchApi: false });
                setSearchData({ callSearchApi: false });
                return;
            }

            const response = await getManagerTeamCount(adminId, params);

            if (response && response.data && response.data.data && response.data.data.count) {
                if (isSearch) {
                    setSearchData({
                        total: response.data.data.count,
                        callSearchApi: false,
                    });
                } else {
                    setPageData({
                        total: response.data.data.count,
                        callFetchApi: false,
                    });
                }
            }
        } catch (error) {
            // console.log(error);
        }
    }

    const getParams = (searchParams = false) => {
        if (searchParams) {
            return {
                pageNumber: searchController.pageNumber,
                pageContent: searchController.pageContent,
                search: searchController.searchValue,
                mgrId: pageController.managerId,
                status: 0,
            }
        }
        return {
            pageNumber: pageController.pageNumber,
            pageContent: pageController.pageContent,
            mgrId: pageController.managerId,
            status: 1
        }
    };

    const handlePageChange = (page, source) => {
        if (source === 'search') {
            setSearchData({
                pageNumber: page,
            });
            return;
        }
        setPageData({
            pageNumber: page,
        });
    };
    
    const handleKeyPress = event => {
        const key = event.key;

        if (key == 'Enter') {
            handleSearchSubmit();
        }
    }

    const handleSearchChange = event => {
        const value = event.target.value;

        setPageData({
            searchText: value,
        });
    };

    // clear all search results and reset controller
    const handleSearchClear = () => {
        setSearchData({
            isLoading: false,
            isPageLoading: false,
            recruiterList: [],
            pageNumber: 0,
            isSelectAll: false,
            selectedRecruiters: [],
            callSearchApi: false,
            total: 0,
            searchValue: ''
        });
        setPageData({ searchText: '' });
    }

    // handles when search is submitted
    const handleSearchSubmit = () => {
        // return if no query present
        if (!pageController.searchText) {
            return;
        }
        setSearchData({
            searchValue: pageController.searchText,
            pageNumber: 1,
            callSearchApi: true,
        });
    };

    const handleUpdateRecruiterStatus = async (recruiterIdArr, status) => {
        try {
            await updateManagedRecruiterStatus(adminId, {
                managerId: pageController.managerId,
                recruiterId: recruiterIdArr,
                status,
            });

            // update status in main list, remove from selected recruiters list
            // if was selected earlier
            if (status == 0) {
                // remove recruiter

                setPageController(prevObj => {
                    let recruiterList = [ ...prevObj.recruiterList ];
                    const selectedRecruiters = [ ...prevObj.selectedRecruiters ];

                    recruiterIdArr.forEach(recId => {
                        const index = recruiterList.findIndex(item => item.id == recId);
                        const selectedIndex = selectedRecruiters.indexOf(recId);
                        
                        if (index != -1) {
                            recruiterList[index] = {
                                ...recruiterList[index],
                                status,
                            }
                        }

                        if (selectedIndex != -1) {
                            selectedRecruiters.splice(selectedIndex, 1);
                        }
                    });

                    // filter out items with status = 0
                    recruiterList = recruiterList.filter(item => item.status == 1);

                    return {
                        ...prevObj,
                        recruiterList,
                        selectedRecruiters,
                        isSelectAll: false,
                        selectedRecruiters: [],
                        total: recruiterList.length // also update the total number of results
                    };
                });
            } else {
                // remove from inactive list
                // reload active list
                let recruiterList = [...searchController.recruiterList];
                recruiterList = recruiterList.filter(item => !recruiterIdArr.includes(item.id));

                setSearchData({
                    recruiterList,
                    isSelectAll: false,
                    selectedRecruiters: [],
                });

                if (recruiterList.length == 0) {
                    handleSearchClear();
                }

                getRecruiterListCount(getParams());
            }

            setPageData({ callFetchApi: true });
            toast.success('Recruiter Status updated Successfully');
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong, Please try again later!');
        }
    };

    // bulk remove all selected Recruiters
    const handleBulkRemove = () => {
        if (!pageController.selectedRecruiters || (pageController.selectedRecruiters && pageController.selectedRecruiters.length == 0)) {
            toast.error('Please select at least one recruiter to take this action');
            return;
        }
        handleUpdateRecruiterStatus(pageController.selectedRecruiters, 0);
    };

    // bulk add all selected Recuiters
    const handleBulkAdd = () => {
        if (!searchController.selectedRecruiters || (searchController.selectedRecruiters && searchController.selectedRecruiters.length == 0)) {
            toast.error('Please select at least one recruiter to take this action');
            return;
        }
        handleUpdateRecruiterStatus(searchController.selectedRecruiters, 1);
    };

    // runs whenever list of selected recruiters changes
    useEffect(() => {
        if (pageController.selectedRecruiters && 
            pageController.recruiterList && 
            pageController.selectedRecruiters.length > 0 && 
            pageController.recruiterList.length > 0 ) {
            if (pageController.selectedRecruiters.length < pageController.recruiterList.length) {
                setPageData({ isSelectAll: false });
            } else {
                setPageData({ isSelectAll: true });
            }
        }
    }, [pageController.selectedRecruiters])

    // runs whenever manager id changes, fetches manager profile details
    useEffect(() => {
        if (pageController.managerId) {
            getManagerDetails();

            if (pageController.searchText && pageController.searchText.length > 0) {
                handleSearchSubmit();
            }
        }
    }, [pageController.managerId]);

    // runs whenever page number changes, sets callFetchApi to true
    useEffect(() => {
        if (pageController.pageNumber) {
            setPageData({
                isPageLoading: true,
                callFetchApi: true,
            })
        }
    }, [pageController.pageNumber]);

     // runs whenever page number changes, sets callFetchApi to true
     useEffect(() => {
        if (searchController.pageNumber) {
            setSearchData({
                isPageLoading: true,
                callSearchApi: true,
            })
        }
    }, [searchController.pageNumber]);

    // runs whenever search is submitted
    useEffect(() => {
        if (searchController.callSearchApi && !searchController.isLoading) {
            const params = getParams(true);
            getRecruiterList(params, true);

            if (params.pageNumber == 1) {
                getRecruiterListCount(params, true);
            }

            setSearchData({ isLoading: true });
        }
    }, [searchController.callSearchApi]);

    // runs whenever callFetchApi value changes
    useEffect(() => {
        if (!pageController.callFetchApi) {
            return;
        }
        
        setPageData({
            callFetchApi: false,
        });

        const params = getParams();
        getRecruiterList(params);

        if (!pageController.recruiterList || (pageController.recruiterList && pageController.recruiterList.length == 0)) {
            getRecruiterListCount(params);
        }

    }, [pageController.callFetchApi]);

    // runs once whenever admin id becomes available
    // checks query params and sets controller
    useEffect(() => {
        if (adminId) {
            const queryParams = getQueryParams(props.location.search);

            let pageNumber = 1, search = null, mgrId = null;

            if (queryParams.recid) {
                mgrId = queryParams.recid;
            }

            if (queryParams.pg && checkIfNumber(queryParams.pg)) {
                pageNumber = queryParams.pg;
            }

            if (queryParams.search) {
                search = queryParams.search;
            }

            setPageData({
                pageNumber,
                searchText: search,
                managerId: mgrId
            });

        }
    }, [adminId]);

    // runs once and before everything else,
    // fetches recruiter profile, sets admin id, and checks access
    useEffect(() => {
        const getProfile = async () => {
            const userProfile = await user.getProfile();
            return userProfile;
        };

        getProfile().then(data => {
            if (data.adminType == 2 || data.adminType == 3 || data.adminType == 4) {
                setAdminId(data.id);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        }).catch(error => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    const tableHeading = getTableHeading(true);

    let topPageIndicators = null;

    if (!pageController.isLoading && pageController.recruiterList.length > 0) {
        topPageIndicators = (
            <div className="page-controller pagination-top">
                <div className='row text-center'>
                    <Pagination totalItemsCount={pageController.total} content={pageController.pageContent} pageNumber={pageController.pageNumber} handlePageChange={handlePageChange}/>
                    <CountDisplay start={(pageController.pageNumber - 1) * pageController.pageContent + 1} end={pageController.pageNumber * pageController.pageContent} total={pageController.total} />
                </div>
            </div>
        );
    } else {
        topPageIndicators = <div></div>;
    }

    let mainContent = null, searchContent = null;

    if (pageController.isLoading) {
        mainContent = (
            <Loader />
        );
    } else if (!pageController.isLoading && pageController.isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <div>
                    <Loader />
                </div>
            </>
        );
    }else if (!pageController.managerId){
        mainContent = (
            <div>
                Invalid Manager Id
            </div>
        )
    }  else {
        let numberOfActive = 0;
        pageController.recruiterList.map(item => {
            if (item.status == 1) {
                numberOfActive += 1;
            }
        });

        mainContent = (
            <>
                {pageController.managerProfile.email ? (
                    <Table>
                        <Table.Body>
                            <TableItem 
                                type="manager"
                                data={{
                                    id: '',
                                    name: pageController.managerProfile.name,
                                    email: '---',
                                    phone: '---',
                                    organisation: pageController.managerProfile.organisation,
                                    managerEmail: pageController.managerProfile.email,
                                    managerId: pageController.managerId,
                                }}
                            />
                        </Table.Body>
                    </Table>
                ) : null}
                <Table striped className="main-table">
                    {tableHeading}
                    <Table.Body>
                        {
                            pageController.recruiterList && pageController.recruiterList.length > 0 ? pageController.recruiterList.map(item => {
                                if (!item.status) {
                                    return null;
                                }

                                return (
                                    <TableItem
                                        type="active"
                                        onUpdateRecruiterStatus={handleUpdateRecruiterStatus}
                                        onSelectRecruiter={handleSingleCheckboxChange}
                                        data={{
                                            id: item.id,
                                            name: item.name,
                                            email: item.email,
                                            phone: item.phone,
                                            organisation: item.organisation,
                                            isChecked: pageController.selectedRecruiters && pageController.selectedRecruiters.includes(item.id)
                                        }}
                                    />
                                )
                            }) : null
                        }
                    </Table.Body>
                </Table>
                {pageController.recruiterList && pageController.recruiterList.length == 0 ? (
                    <div className='error-message'>
                        Sorry No recruiter is this team. Please search and add recruiter
                    </div>
                ) : null}
            </>
        )
    }

    const searchTableHeading = getTableHeading(false);

    if (searchController.isLoading) {
        searchContent = <Loader />;
    } else if (!searchController.isLoading && searchController.isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {searchTableHeading}
                </Table>
                <div>
                    <Loader />
                </div>
            </>
        );
    } 
    else if (!searchController.isLoading && !searchController.isPageLoading && searchController.searchValue && searchController.searchValue.length > 0 && searchController.recruiterList.length === 0) {
        searchContent = <div>No Recruiters Found</div>
    }
    else if (searchController.recruiterList && searchController.recruiterList.length > 0) {
        searchContent = (
            <Table>
                {searchTableHeading}
                <Table.Body>
                    {searchController.recruiterList.map(item => {
                        return (
                            <TableItem
                                type="inactive"
                                onUpdateRecruiterStatus={handleUpdateRecruiterStatus}
                                onSelectRecruiter={(recruiterId) => handleSingleCheckboxChange(recruiterId, 'search')}
                                data={{
                                    id: item.id,
                                    name: item.name,
                                    email: item.email,
                                    phone: item.phone,
                                    organisation: item.organisation,
                                    isChecked: searchController.selectedRecruiters && searchController.selectedRecruiters.includes(item.id),
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }

    let searchTopPageIndicators = null, searchBottomPageIndicators = null;

    if (!searchController.isLoading && searchController.recruiterList.length > 0) {
        searchTopPageIndicators = (
            <div className="page-controller pagination-top">
                <div className='row text-center'>
                    <Pagination totalItemsCount={searchController.total} content={searchController.pageContent} pageNumber={searchController.pageNumber} handlePageChange={(page) => handlePageChange(page, 'search')}/>
                    <CountDisplay start={(searchController.pageNumber - 1) * searchController.pageContent + 1} end={searchController.pageNumber * searchController.pageContent} total={searchController.total} />
                </div>
            </div>
        );
        searchBottomPageIndicators = (
            <div className="page-controller row text-center pagination-bottom">
                <Pagination totalItemsCount={searchController.total} pageNumber={searchController.pageNumber} content={searchController.pageContent} handlePageChange={(page) => handlePageChange(page, 'search')} />
            </div>
        )
    } else {
        searchTopPageIndicators = <div></div>;
        searchBottomPageIndicators = <div></div>;
    }

    return (
        <div className='page-content manager-teammgt'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className='page-heading'>
                <Heading text="Manager Team Management" />
            </div>

            <div>
                <Button text="Bulk Remove Recruiter" skin="dark" clickButton={handleBulkRemove}/> 
            </div>

            {topPageIndicators}
            
            <div className="main-row">
                {mainContent}
            </div>

            <div className='search__controller'>
                <InputBox 
                    type="text"
                    name=""
                    placeholder="Search using Email/ID"
                    value={pageController.searchText}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                />

                <Button text="Search" skin="dark" clickButton={handleSearchSubmit}></Button>
                <Button text="Clear" skin="light" clickButton={handleSearchClear}></Button>

                {searchController.selectedRecruiters && searchController.selectedRecruiters.length > 0 ? (
                    <Button text="Bulk Add Recruiter" skin="dark" clickButton={handleBulkAdd}></Button>
                ) : null}
            </div>

            {searchTopPageIndicators}

            <div className='search-table'>
                {searchContent}
            </div>

            {searchBottomPageIndicators}
        </div>
    )
};

export default ManagerTeamMgt; 