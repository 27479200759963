import React from "react";
import fetchIntegrations from "../../services/fetchIntegrations";
import addIntegrations from "../../services/addIntegrations";
import updateIntegration from "../../services/updateIntegrations";
import { Header, Modal } from 'semantic-ui-react';
import { Table } from "semantic-ui-react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import user from '../../store/user';
import "./styles.scss";

import Heading from '../../components/Heading';
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import Dropdown from '../../components/Dropdown';

const ATS_TYPES = [
    {
        id: 0,
        name: "Select Type"
    },
    {
        id: 1,
        name: "XML Feed"
    },
    {
        id: 2,
        name: "API Integration"
    }
];

const ATS_TYPES_MAP = ATS_TYPES.reduce((p, c) => {
    p[c.id] = c.name;
    return p;
}, {});

class ATSPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            profile: {},
            recs: [],
            selectedIntegration: null
        }
    }

    async componentDidMount() {
        const profile = await user.getProfile();

        const resp = await fetchIntegrations(profile.id);
        this.setState({
            list: resp.data.data,
            profile
        })
    }

    async getRecs(id) {
        this.props.history.push(`/ats-panel-integrations/${id}/recs`);
    }

    modalClose = () => {
        this.setState({ selectedIntegration: null });
    }

    toggleActivation = async (item) => {
        try {
            const { profile, list } = this.state;
            let newList = [];
            const data = {
                ...item,
                status: item.status == 1 ? 0 : 1
            };
            await updateIntegration(profile.id, item.id, data);
            newList = list.map(i => {
                if (i.id == item.id) {
                    return {
                        ...data
                    };
                }
                return i;
            });
            toast.success("Integration Updated Successfully");
            this.setState({
                list: newList
            });
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    submit = async () => {
        try {
            const { selectedIntegration, profile, list } = this.state;
            let newList = [];
            if (selectedIntegration.id) {
                const resp = await updateIntegration(profile.id, selectedIntegration.id, selectedIntegration);
                newList = list.map(i => {
                    if (i.id == selectedIntegration.id) {
                        return {
                            ...selectedIntegration
                        };
                    }
                    return i;
                });
                toast.success("Integration Updated Successfully");
            } else {
                const resp = await addIntegrations(profile.id, selectedIntegration);
                newList = [resp.data.data, ...list];
                toast.success("Integration Added Successfully");
            }
            this.setState({
                list: newList,
                selectedIntegration: null
            });
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    onChange = (ev) => {
        const { name, value } = ev.target;
        const { selectedIntegration } = this.state;

        this.setState({
            selectedIntegration: {
                ...selectedIntegration,
                [name]: value
            }
        })
    }

    onEdit = (item) => {
        console.log(item);
        this.setState({ selectedIntegration: { ...item } });
    }

    render() {
        const { list, selectedIntegration } = this.state;

        return (

            <div className="ats-panel">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <div className="pageHeading">
                    <Heading text='ATS Integration Management' />
                    <Button skin='dark' clickButton={() => this.setState({ selectedIntegration: {} })} text="Add Integration" />
                    <Button skin='dark' className="rec-list" clickButton={
                        () => this.props.history.push(`/ats-panel-integrations/recs`)
                    } text="Recruiters" />
                </div>
                <Table fixed selectable className="ats-panel-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            list.map((item, i) => {
                                return (
                                    <>
                                        <Table.Row>
                                            <Table.Cell>{item.id} </Table.Cell>
                                            <Table.Cell>{item.name} </Table.Cell>
                                            <Table.Cell>{ATS_TYPES_MAP[item.atsType]} </Table.Cell>
                                            <Table.Cell>{item.status ? "Active" : "In Active"} </Table.Cell>
                                            <Table.Cell>
                                                <Button skin='dark' text={"Edit"} clickButton={() => this.onEdit(item)} />
                                                <Button skin='dark' text={"Recruiters"} clickButton={this.getRecs.bind(this, item.id)} />
                                                <Button skin='dark' text={!!item.status ? "DeActivate" : "Activate"} clickButton={() => this.toggleActivation(item)} />
                                            </Table.Cell>
                                        </Table.Row>
                                    </>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
                {
                    selectedIntegration ?
                        <Modal className="add-ats-panel" open={selectedIntegration} onClose={this.modalClose}>
                            <Header content={selectedIntegration.id ? "Edit Integration" : "Add Integration"} />
                            <Modal.Content>
                                <div className="paid-jobs-section">
                                    <label>Name:</label>
                                    <InputBox
                                        name="name"
                                        value={selectedIntegration.name}
                                        defaultValue={selectedIntegration.name}
                                        onChange={this.onChange}
                                    />
                                    <br />
                                    <br />
                                    <label>ATS Type:</label>
                                    <Dropdown
                                        name="atsType"
                                        data={ATS_TYPES}
                                        dropdownChange={this.onChange}
                                        defaultValue={selectedIntegration.atsType} />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button clickButton={this.submit} skin="light" text="Submit">
                                </Button>
                            </Modal.Actions>
                        </Modal> : null
                }
            </div>
        )
    }

}

export default ATSPanel;