import React, { useEffect, useState } from 'react';
import { withRouter  } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import InputBox from '../../../components/InputBoxWithoutCheck';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import fetchProductTypes from '../../../services/fetchProductTypeSeekerSubscriptions';
import fetchSubscriptions from '../../../services/fetchSeekerSubscriptions';
import deactivateSeekerSubscriptions from '../../../services/deactivateSeekerSubscriptions';
import activateSeekerSubscriptions from '../../../services/activateSeekerSubscriptions';
import user from '../../../store/user';
import './paymentInfo.scss';
import {SEEKER_SUBSCRIPTIONS_FORM} from '../../../models/routes';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import SeekerActionModal from '../../../components/SeekerActionModal';

import { seekerPlanDeactivationReasons } from '../../../models/seekerPlanDeactivationReasons';

const paymentInfo =(props)=>{
    const [ordersData,setOrdersData]=useState([]);
    const [pageNumber,setPageNumber] = useState(1);
    const [pageContent,setPageContent] = useState(10);
    const [totalCount,setTotalCount] = useState(0);
    const [email,setEmail] = useState('');
    const [productId,setProductId] = useState('');
    const [products,setProducts] = useState([{ id:'',name:'All' }]);
    const [loader,setLoader]=useState(false);
    const [actionButtonsLoader,setActionLoader]=useState(Array.apply(null, Array(5)).map(function () { return 0; }))
    const [adminId,setAdmin]=useState();

    const [actionModalData, setActionModalData] = useState({
        actionType: null,
        data: {},
        isLoading: false,
    });

    const statusMap = {
        1:'Plan Active',
        0:'Plan Expired/Inactive'
    }
    const paymentStatusMap = {
        0:'Pending',
        1:'Success',
        2:'Failed'        
    }
    const hiristProductIdMap = {
        'hirist-EA':'hirist-EA,hirist-EA_RB',
        'hirist-RB':'hirist-RB,hirist-EA_RB',
        'hirist-EA_RB':'hirist-EA_RB'
    }

    const iimjobsProductIdMap = {
        'iimjobs-EA':'iimjobs-EA,iimjobs-EA_RB',
        'iimjobs-RB':'iimjobs-RB,iimjobs-EA_RB',
        'iimjobs-EA_RB':'iimjobs-EA_RB'
    }

    const updazzProductIdMap = {
        'updazz-EA':'updazz-EA,updazz-EA_RB',
        'updazz-RB':'updazz-RB,updazz-EA_RB',
        'updazz-EA_RB':'updazz-EA_RB'
    }

    const productIdMap = process.env.REACT_APP_PLATFORM == 'iimjobs' ? iimjobsProductIdMap 
                        : process.env.REACT_APP_PLATFORM == 'hirist' ? hiristProductIdMap 
                        : process.env.REACT_APP_PLATFORM == 'updazz' ? updazzProductIdMap : {};

    useEffect(()=>{
        (async () => {
        const profile = await user.getProfile();
        setAdmin(profile.id);

        const hasPageAccess = user.checkFeatureAccess(adminFeatureMapping.MANAGE_SUBSCRIPTIONS_FORM);

        if (!hasPageAccess) {
            window.location.href = '/notAdmin';
        }
        
        try{
            var prodTypes=await fetchProductTypes(profile.id);
            prodTypes=prodTypes.data ? prodTypes.data.plans : [];
            prodTypes = prodTypes.map((eachType)=>{
                return {
                    id:eachType.planCode,
                    name:eachType.planName
                }
            })
            setProducts([...products,...prodTypes]);
        }
        catch(err)
        {
            toast.error(err.response && err.response.data && err.response.data.reason?err.response.data.reason:err.message);
        }
    })()
    
    },[]);

    async function manageOrder(order,index, reason){
        var actionButtons =[...actionButtonsLoader];
       
        try{
            actionButtons[index]=1;
            setActionLoader(actionButtons);
            var res,action;
            if(order.active)
             {res=await deactivateSeekerSubscriptions(adminId,{ purchaseOrderId:order.id, adminId, reason, });action='Deactivated'}
            else
             {res=await activateSeekerSubscriptions(adminId,{ purchaseOrderId:order.id, adminId, reason, });action='Activated'}
             toast.success(action+" Successfully");
             actionButtons[index]=0;
             setActionLoader(actionButtons);
            setActionModalData({ actionType: null, isLoading: false });
            await searchOrders()

        }
        catch(err)
        {
            toast.error(err.response && err.response.data && err.response.data.reason?err.response.data.reason:err.message);
            actionButtons[index]=0;
            setActionLoader(actionButtons);
            setActionModalData({ actionType: null, isLoading: false });
        }
    }

    async function searchOrders(pageNum=-1){
        try{
        var choosedPlan = productId.length?productIdMap[productId]:null;
        setLoader(true);
        var paramsApi = { pageNumber:pageNum>-1?pageNum:pageNumber,page:pageContent,productTypes:choosedPlan };
        if(Number(email))
        {
            paramsApi.userIds=email;
        }
        else{
            paramsApi.emails=email;
        }
        var ordersRes=await fetchSubscriptions(adminId,paramsApi);
        var totalCountRes = ordersRes.data ? ordersRes.data.total : 0;
        ordersRes = ordersRes.data ?ordersRes.data.result:[];
        if(totalCountRes==0){
            toast.error("No records found");
        }
        setOrdersData([...ordersRes]);
        setTotalCount(totalCountRes);
        setLoader(false);
        setPageNumber(pageNum>-1?pageNum:pageNumber);
    }
    catch(err){
        toast.error(err.response && err.response.data && err.response.data.reason?err.response.data.reason:err.message);
       setLoader(false);
    }
    }

    async function onChangePageNum(pageNum){
        await searchOrders(pageNum)
    }
    async function handleEnterKey(event){
        if(event.key=='Enter'){
            searchOrders();
        }
    }

    const openModal = (type, eachOrder, index, initialReason) => {
        setActionModalData({
            actionType: type,
            data: {
                reason: 0,
                eachOrder,
                index,
            },
            reasonsList: initialReason == 4 ? seekerPlanDeactivationReasons.filter(item => item.id == 4 || item.id == 0) : seekerPlanDeactivationReasons,
        });
    }

    const onActionReasonChange = (reason) => {
        setActionModalData(prevObj => {
            const newObj = { ...prevObj };
            newObj.data.reason = reason;
            return newObj;
        });
    };

    const onActionModalClose = () => {
        setActionModalData({ actionType: null });
    };

    const onActionSubmit = () => {
        setActionModalData(prevObj => {
            const newObj = { ...prevObj };
            newObj.isLoading = true;
            return newObj;
        });

        manageOrder(actionModalData.data.eachOrder, actionModalData.data.index, actionModalData.data.reason);
    };

    return(<div className='seekerSubscriptions'>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        <div className='topMenu'>
            <div className='filterButtons'>
                <div className='emailField'>
                    <label className='emailLabel'>Email:</label><InputBox
             type="text"
            value={email}
            placeholder="Enter Email"
            onChange={(e) => {setEmail(e.target.value)}}
            onKeyPress={handleEnterKey}
                    />
                </div>
                <div className='productTypeField'>
                    <label className='productTypeLabel'>
                    Product Type:
                    </label><Dropdown data={products} dropdownChange={(e) => setProductId(e.target.value)}  />
                </div>
                <Button
            skin="dark"
            disabled={loader}
            loading={loader}
            text="Search"
            clickButton={()=>searchOrders()}
        />
             
            </div>
            <div className='newCreateButton'>
                <Button
            skin="dark"
            text="Create Subscription"
            clickButton={()=>{props.history.push(SEEKER_SUBSCRIPTIONS_FORM)}}
        />
            </div>
        </div>
        <div className='paginationSubscription'>
        <Pagination totalItemsCount={totalCount} content={pageContent} pageNumber={pageNumber} handlePageChange={onChangePageNum} />
        </div>
        <CountDisplay start={(pageNumber - 1) * pageContent + 1} end={Math.min(pageNumber * pageContent,totalCount)} total={totalCount} />       

        <Table fixed selectable structured className="payments-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={4} >Seeker ID</Table.HeaderCell>
                    <Table.HeaderCell width={8} >Email</Table.HeaderCell>
                    <Table.HeaderCell width={4} >Order ID</Table.HeaderCell>
                    <Table.HeaderCell width={4} >Plan ID</Table.HeaderCell>
                    <Table.HeaderCell width={8} >Plan Name</Table.HeaderCell>
                    <Table.HeaderCell width={8} >Log Date from IP Address</Table.HeaderCell>
                    <Table.HeaderCell width={12}>Response Code Description</Table.HeaderCell>
                    <Table.HeaderCell width={6} >Payment Status</Table.HeaderCell>
                    <Table.HeaderCell width={8}>Dates From to</Table.HeaderCell>
                    <Table.HeaderCell width={8}>Subscription Status</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Amount</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Action</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {ordersData.map((eachOrder,index)=>{
                   return <Table.Row>
                       <Table.Cell>
                           {eachOrder.seekerId || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.email || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.orderId || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.productCode || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.product_name || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {(eachOrder.logDate?moment(eachOrder.logDate).format("DD-MM-YYYY , HH:mm:ss"):'')+' ip: '+eachOrder.userIpAddress}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.responseDescription || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {paymentStatusMap[eachOrder.paymentStatus] || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.startDate && eachOrder.endDate?(moment(eachOrder.startDate).format("DD-MM-YYYY") + ' to ' + moment(eachOrder.endDate).format("DD-MM-YYYY")):'NA'}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.active >0? 'Plan Active': "Plan Expired/Inactive"}
                       </Table.Cell>
                       <Table.Cell>
                           {eachOrder.transactionAmount || "NA"}
                       </Table.Cell>
                       <Table.Cell>
                           <div className="actionButtons" >{eachOrder.active?<Button
            skin="dark"
            disabled={actionButtonsLoader[index]==1?true:false}
            loading={actionButtonsLoader[index]==1?true:false}
            text="Deactivate"
            clickButton={()=>openModal('deactivate', eachOrder,index, eachOrder.reason)}
        />: null}
        </div>
                       </Table.Cell>
                   </Table.Row>
                })}
            </Table.Body>
        </Table>
    
        {actionModalData && actionModalData.actionType === 'deactivate' ? (
            <SeekerActionModal 
                type="deactivate"
                reason={actionModalData.data.reason}
                setReason={onActionReasonChange}
                onClose={onActionModalClose}
                errorMsg="Please select reason for Deactivation"
                onActionSubmit={onActionSubmit}
                isLoading={actionModalData.isLoading}
                reasonsDropdownList={actionModalData.reasonsList}
            />
        ) : null}
    </div>);
}

export default withRouter(paymentInfo);