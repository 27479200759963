export const locationList = [
    { 'text': '--Anywhere--', 'disabled': true },
    { 'key': '87', 'value': '87', 'text': 'Metros', },
    { 'key': '88', 'value': '88', 'text': 'Anywhere in India/Multiple Locations' },
    { 'key': '89', 'value': '89', 'text': 'Overseas/International' },
    { 'key': '0', 'value': '0', 'text': 'Any Location', },
    { 'text': '--National Locations--', 'disabled': true },
    { 'key': '1', 'value': '1', 'text': 'Delhi NCR' },
    { 'key': '2', 'value': '2', 'text': 'Mumbai' },
    { 'key': '3', 'value': '3', 'text': 'Bangalore' },
    { 'key': '4', 'value': '4', 'text': 'Hyderabad' },
    { 'key': '5', 'value': '5', 'text': 'Kolkata' },
    { 'key': '6', 'value': '6', 'text': 'Chennai' },
    { 'key': '7', 'value': '7', 'text': 'Pune' },
    { 'key': '8', 'value': '8', 'text': 'Gujarat' },
    { 'key': '9', 'value': '9', 'text': 'Maharashtra' },
    { 'key': '10', 'value': '10', 'text': 'MP' },
    { 'key': '11', 'value': '11', 'text': 'Jaipur' },
    { 'key': '12', 'value': '12', 'text': 'Guwahati' },
    { 'key': '13', 'value': '13', 'text': 'Goa' },
    { 'key': '14', 'value': '14', 'text': 'Chandigarh' },
    { 'key': '15', 'value': '15', 'text': 'Punjab' },
    { 'key': '16', 'value': '16', 'text': 'Haryana' },
    { 'key': '17', 'value': '17', 'text': 'Kerala' },
    { 'key': '18', 'value': '18', 'text': 'Orisa' },
    { 'key': '19', 'value': '19', 'text': 'Bihar' },
    { 'key': '20', 'value': '20', 'text': 'Jharkhand' },
    { 'key': '21', 'value': '21', 'text': 'UP' },
    { 'key': '31', 'value': '31', 'text': 'Karnataka' },
    { 'key': '32', 'value': '32', 'text': 'Tamil Nadu' },
    { 'key': '33', 'value': '33', 'text': 'Rajasthan' },
    { 'key': '34', 'value': '34', 'text': 'Andhra Pradesh' },
    { 'key': '35', 'value': '35', 'text': 'Telangana' },
    { 'key': '36', 'value': '36', 'text': 'Delhi' },
    { 'key': '37', 'value': '37', 'text': 'Gurgaon/Gurugram' },
    { 'key': '38', 'value': '38', 'text': 'Noida' },
    { 'key': '39', 'value': '39', 'text': 'Greater Noida' },
    { 'key': '40', 'value': '40', 'text': 'Faridabad' },
    { 'key': '41', 'value': '41', 'text': 'Ghaziabad', },
    { 'key': '42', 'value': '42', 'text': 'Jammu & Kashmir' },
    { 'key': '43', 'value': '43', 'text': 'Jammu' },
    { 'key': '44', 'value': '44', 'text': 'Srinagar' },
    { 'key': '45', 'value': '45', 'text': 'Amritsar' },
    { 'key': '46', 'value': '46', 'text': 'Jalandhar' },
    { 'key': '47', 'value': '47', 'text': 'Patiala' },
    { 'key': '48', 'value': '48', 'text': 'Ludhiana' },
    { 'key': '49', 'value': '49', 'text': 'Sonipat' },
    { 'key': '50', 'value': '50', 'text': 'Panipat' },
    { 'key': '51', 'value': '51', 'text': 'Udaipur' },
    { 'key': '52', 'value': '52', 'text': 'Jodhpur' },
    { 'key': '53', 'value': '53', 'text': 'Ahmedabad' },
    { 'key': '54', 'value': '54', 'text': 'Surat' },
    { 'key': '55', 'value': '55', 'text': 'Gandhinagar' },
    { 'key': '56', 'value': '56', 'text': 'Vadodara/Baroda' },
    { 'key': '57', 'value': '57', 'text': 'Haridwar' },
    { 'key': '58', 'value': '58', 'text': 'Dehradun' },
    { 'key': '59', 'value': '59', 'text': 'Uttarakhand' },
    { 'key': '60', 'value': '60', 'text': 'Lucknow' },
    { 'key': '61', 'value': '61', 'text': 'Patna' },
    { 'key': '62', 'value': '62', 'text': 'Ranchi' },
    { 'key': '63', 'value': '63', 'text': 'Jamshedpur' },
    { 'key': '64', 'value': '64', 'text': 'Chhattisgarh' },
    { 'key': '65', 'value': '65', 'text': 'Bhubaneshwar' },
    { 'key': '66', 'value': '66', 'text': 'Nagpur' },
    { 'key': '67', 'value': '67', 'text': 'Nasik' },
    { 'key': '68', 'value': '68', 'text': 'Navi Mumbai' },
    { 'key': '69', 'value': '69', 'text': 'Thane' },
    { 'key': '70', 'value': '70', 'text': 'Cochin/Kochi' },
    { 'key': '71', 'value': '71', 'text': 'Hosur' },
    { 'key': '72', 'value': '72', 'text': 'Hubli' },
    { 'key': '73', 'value': '73', 'text': 'Mysore' },
    { 'key': '74', 'value': '74', 'text': 'Raipur' },
    { 'key': '75', 'value': '75', 'text': 'Trivandrum/Thiruvananthapuram' },
    { 'key': '76', 'value': '76', 'text': 'Vijayawada' },
    { 'key': '77', 'value': '77', 'text': 'Guntur' },
    { 'key': '78', 'value': '78', 'text': 'Vishakhapatnam/Vizag' },
    { 'key': '79', 'value': '79', 'text': 'Aurangabad' },
    { 'key': '80', 'value': '80', 'text': 'Rajkot' },
    { 'key': '81', 'value': '81', 'text': 'Varanasi/Banaras' },
    { 'key': '82', 'value': '82', 'text': 'Warangal' },
    { 'key': '83', 'value': '83', 'text': 'Madurai' },
    { 'key': '84', 'value': '84', 'text': 'Coimbatore' },
    { 'key': '85', 'value': '85', 'text': 'Pondicherry' },
    { 'key': '86', 'value': '86', 'text': 'Cuttack' },
    { 'key': '121', 'value': '121', 'text': 'Bhopal' },
    { 'key': '123', 'value': '123', 'text': 'Assam' },
    { 'key': '127', 'value': '127', 'text': 'Arunachal Pradesh' },
    { 'key': '129', 'value': '129', 'text': 'West Bengal' },
    { 'key': '130', 'value': '130', 'text': 'Indore' },
    { 'key': '131', 'value': '131', 'text': 'Mohali' },
    { 'key': '132', 'value': '132', 'text': 'Remote' },

    { 'text': '--International Locations--', 'disabled': true },
    { 'key': '22', 'value': '22', 'text': 'US' },
    { 'key': '23', 'value': '23', 'text': 'UK' },
    { 'key': '24', 'value': '24', 'text': 'Singapore' },
    { 'key': '25', 'value': '25', 'text': 'Middle East' },
    { 'key': '26', 'value': '26', 'text': 'Africa' },
    { 'key': '27', 'value': '27', 'text': 'Malaysia' },
    { 'key': '28', 'value': '28', 'text': 'EU' },
    { 'key': '30', 'value': '30', 'text': 'Hong Kong' },
    { 'key': '90', 'value': '90', 'text': 'Bahrain' },
    { 'key': '91', 'value': '91', 'text': 'Dubai', },
    { 'key': '92', 'value': '92', 'text': 'Kabul' },
    { 'key': '93', 'value': '93', 'text': 'Kuwait' },
    { 'key': '94', 'value': '94', 'text': 'Nigeria' },
    { 'key': '95', 'value': '95', 'text': 'London' },
    { 'key': '96', 'value': '96', 'text': 'Oman' },
    { 'key': '97', 'value': '97', 'text': 'Muscat' },
    { 'key': '98', 'value': '98', 'text': 'Doha' },
    { 'key': '99', 'value': '99', 'text': 'Qatar' },
    { 'key': '100', 'value': '100', 'text': 'Abu Dhabi' },
    { 'key': '101', 'value': '101', 'text': 'Saudi Arabia' },
    { 'key': '102', 'value': '102', 'text': 'Riyadh' },
    { 'key': '103', 'value': '103', 'text': 'Indonesia' },
    { 'key': '104', 'value': '104', 'text': 'Nepal' },
    { 'key': '105', 'value': '105', 'text': 'Bhutan' },
    { 'key': '106', 'value': '106', 'text': 'Dhaka' },
    { 'key': '107', 'value': '107', 'text': 'Bangladesh' },
    { 'key': '108', 'value': '108', 'text': 'China' },
    { 'key': '109', 'value': '109', 'text': 'Afghanistan' },
    { 'key': '110', 'value': '110', 'text': 'Pakistan' },
    { 'key': '111', 'value': '111', 'text': 'Sri Lanka' },
    { 'key': '112', 'value': '112', 'text': 'Ethiopia' },
    { 'key': '113', 'value': '113', 'text': 'Egypt' },
    { 'key': '114', 'value': '114', 'text': 'Kenya' },
    { 'key': '115', 'value': '115', 'text': 'Nairobi' },
    { 'key': '116', 'value': '116', 'text': 'Tanzania' },
    { 'key': '117', 'value': '117', 'text': 'South Africa' },
    { 'key': '118', 'value': '118', 'text': 'Zimbabwe' },
    { 'key': '119', 'value': '119', 'text': 'Zambia' },
    { 'key': '120', 'value': '120', 'text': 'Philippines' },
    { 'text': '--Others--', 'disabled': true },
    { 'key': '100000', 'value': '100000', 'text': 'Others' }
]

export const locationListLabel = locationList.map(item => {
    item.label = item.text;
    return item;
})

export function getLocationMap() {
    let arr = locationList;
    
    return arr.reduce((p,c) => {
        p[c.value] = c.text;
        return p;
    }, {})
}