import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import './DragAndDrop.scss';

const DragAndDrop = props => {

    const drop = React.useRef(null);
    const inputRef = React.useRef(null);
    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    // when file is dropped 
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.selectedFile) {
            return;
        }

        setDragging(false);

        const { files } = event.dataTransfer;
        if (files.length > 0) {
            const file = files[0];
            
            // check if file format not correct
            if (props.formats) {
                const ext = file.name.toLowerCase().split('.').pop();

                if (!props.formats.includes(ext)) {
                    props.onError(`Invalid file uploaded. Only following formats are accepted: ${props.formats.join(', ')}`);
                    return;
                }
            }
            props.setSelectedFile(file)
            props.onUpload(file);
        }
    }

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!props.selectedFile)
            setDragging(true);
        else
            setDragging(false);
    }

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    }

    // when file is selected using input
    const onFileChangeHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (props.formats) {
                const ext = file.name.toLowerCase().split('.').pop();

                if (!props.formats.includes(ext)) {
                    props.onError(`Invalid file uploaded. Only following formats are accepted: ${props.formats.join(', ')}`);
                    return;
                }
            }
            props.setSelectedFile(file);
            props.onUpload(file);
        }

    }

    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        drop.current.addEventListener('dragenter', handleDragEnter);
        drop.current.addEventListener('dragleave', handleDragLeave);

        return () => {
            drop.current.removeEventListener('dragover', handleDragOver);
            drop.current.removeEventListener('drop', handleDrop);
            drop.current.removeEventListener('dragenter', handleDragEnter);
            drop.current.removeEventListener('dragleave', handleDragLeave);
        }
    }, [])

    let dragDropSelected = null;
    if (props.selectedFile) {
        dragDropSelected = (
            <div className='dragdrop__body--noselect'>
                <p className='selected__file'>{props.selectedFile.name }</p>
                <Button skin='dark' text='Clear' clickButton={() => props.setSelectedFile(null)} />
            </div>
        )
    }

    const dragDropNotSelected = (
        <>
            <div className={`dragdrop__body--select ${dragging ? 'hidden': ''}`}>
                <div>Drag &amp; Drop your .csv file here</div>
                <span>OR</span>
                <input type='file' onChange={onFileChangeHandler} ref={inputRef}/>
                <Button skin='light' text='Select File' clickButton={() => {
                    if (inputRef.current) {
                        inputRef.current.click();
                    }
                }}/>
            </div>
        </>
    );

    let mainContent;
    if (dragging) {
        mainContent = (
            <div>Drop Here</div>
        );
    } else {
        if (props.selectedFile) {
            mainContent = dragDropSelected;
        }else {
            mainContent = dragDropNotSelected;
        }
    }

    return (
        <div ref={drop} className='dragdrop__container'>
            <div className={`dragdrop__container--area ${dragging ? 'dragging' : ''}`}>
                {mainContent}
            </div>
        </div>
    );
};

DragAndDrop.propTypes = {
    onUpload: PropTypes.func.isRequired,
    formats: PropTypes.array,
    onError: PropTypes.func.isRequired,
    selectedFile: PropTypes.object.isRequired,
    setSelectedFile: PropTypes.func.isRequired
}
export default DragAndDrop;