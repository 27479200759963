import React, { useState } from 'react';

import './competitionModal.scss';
import { Header, Modal, Table } from 'semantic-ui-react';
import moment from 'moment';
import Button from '../../../../components/Button';
import InputBox from '../../../../components/InputBox';
import { EMAIL_TYPES } from '../../constants';
import Dropdown from '../../../../components/Dropdown/Dropdown';


const STATUS_MAP = {
    0: 'Pending',
    1: 'Submitted'
}

const ACTION_MAP = {
    0: 'Pending',
    1: 'Selected, Moved to next round',
    2: 'Rejected'
}


const CompetitionModal = (props) => {

    const [scoreAction, setScoreAction] = useState({});

    const [isInvalidMsg, setIsInvalidMsg] = useState('')

    const [isScoreSubmitting, setIsScoreSubmitting] = useState(false);

    const [isEmailSending, setIsEmailSending] = useState(null);

    const onScoreChange = (e, item) => {
        const value = e.target.value;

        const data = {
            seekerId: item['seekerId'],
            competitionId: item['competitionId'],
            round: item['currentRound'],
            score: value,
            scoredBy: props.adminId

        }

        if (value < 0 || value > 20) {
            setIsInvalidMsg('0 to 20 allowed');
            return;
        }
        else {
            setIsInvalidMsg('')
        }

        setScoreAction(data);


    }

    const onSubmitScore = async () => {

        try {

            if (!scoreAction['score']) {
                setIsInvalidMsg('0 to 20 allowed')
                return;
            }
            setIsScoreSubmitting(true);

            await props.submitScore(scoreAction)

            setIsScoreSubmitting(true);


        }
        catch (e) {

            console.log(e);
            setIsScoreSubmitting(false);

        }
    }

    const onSendEmail = async (e, emailType) => {

        try {

            setIsEmailSending(emailType);

            await props.sendSingleEmail(e, props.modalItem['seekerId']);

            setIsEmailSending(null);

        }

        catch (e) {
            setIsEmailSending(null);

        }

    }

    return (
        <Modal
            className="competition-detail-modal"
            open={props.open}
            onClose={props.onClose}
            size="large"
        >
            {props.modalItem && (
                <>
                    <Header content='Candidate Details' />
                    <Modal.Content scrolling>
                        {
                            <div>
                                <div className='competition-modal-details-container'>
                                    <div>
                                        <span className='competition-modal-detail-name'><b>Name: </b></span>
                                        <span className='competition-modal-detail-value'>{props.modalItem['name']}</span>
                                    </div>

                                    <div>
                                        <span className='competition-modal-detail-name'><b>Institute: </b></span>
                                        <span className='competition-modal-detail-value'>{props.modalItem['institute']['instituteName']}</span>
                                    </div>

                                    <div>
                                        <span className='competition-modal-detail-name'><b>Domain: </b></span>
                                        <span className='competition-modal-detail-value'>{props.modalItem['domain']}</span>

                                    </div>

                                    <div>
                                        <span className='competition-modal-detail-name'><b>Specialisation: </b></span>
                                        <span className='competition-modal-detail-value'>{props.modalItem['institute']['specialisation']}</span>

                                    </div>

                                    <div>
                                        <span className='competition-modal-detail-name'><b>Batch: </b></span>
                                        <span className='competition-modal-detail-value'>{props.modalItem['institute']['batch']}</span>

                                    </div>

                                    <div>
                                        <span className='competition-modal-detail-name'><b>Registered On: </b></span>
                                        <span className='competition-modal-detail-value'>{moment(props.modalItem['createdAt']).format('Do MMM YYYY')}</span>
                                    </div>

                                </div>

                                <h2><b>Competition Progress</b></h2>

                                <Table>
                                    <Table.Header>
                                        <Table.HeaderCell width={3}>
                                            Round
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Status
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Score/Submission
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Result
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Action
                                        </Table.HeaderCell>
                                    </Table.Header>

                                    <Table.Body>

                                        <Table.Row>
                                            <Table.Cell>{`Round 1`}</Table.Cell>
                                            <Table.Cell>{STATUS_MAP[props.modalItem['rounds']['1']['status']]}</Table.Cell>
                                            <Table.Cell>



                                                {/* {
                                                    props.modalItem['rounds']['1']['problemStatement'] ? (
                                                        <div>
                                                            <p><b>Problem Statement: </b></p>
                                                            <p>{props.modalItem['rounds']['1']['problemStatement']}</p>
                                                            <br />
                                                        </div>
                                                    ) : null
                                                } */}

                                                {
                                                    props.modalItem['rounds']['1']['status'] == 1 ? (
                                                        <>
                                                            <p>
                                                                <span><b>Score: </b></span>
                                                                <span>{props.modalItem['rounds']['1'] && (props.modalItem['rounds']['1']['score'] || props.modalItem['rounds']['1']['score'] == 0) ? `${props.modalItem['rounds']['1']['score']}/ ${JSON.parse(props.modalItem['rounds']['1']['metaResult'] || "{}")['totalMarks']}` : ''}</span>
                                                            </p>
                                                            <p onClick={e => e.preventDefault()}>
                                                                <a onClick={(e) => props.onClickDetailScore(e, props.modalItem['seekerId'])} href='' >Details</a>
                                                            </p>
                                                        </>
                                                    ) : null
                                                }

                                            </Table.Cell>
                                            <Table.Cell>{ACTION_MAP[props.modalItem['rounds']['1']['action']]}</Table.Cell>
                                            <Table.Cell>
                                                <Table.Cell>

                                                    {
                                                        props.modalItem['rounds'][props.modalItem['currentRound']] && (!props.modalItem['currentRound'] && !props.modalItem.rounds[props.modalItem['currentRound']]['score']) && props.modalItem.rounds[props.modalItem['currentRound']]['status'] == 1 && props.modalItem['currentRound'] == 1 ? (
                                                            <>
                                                                <label>{`Round ${props.modalItem['currentRound']} Score`}</label>

                                                                <p onClick={(e) => e.stopPropagation()}>
                                                                    <InputBox error={isInvalidMsg} className='comp-score-input' name='score' type='number' max='20' value={scoreAction['seekerId'] == props.modalItem['seekerId'] ? scoreAction['score'] : ''} onChange={(e) => onScoreChange(e, props.modalItem)} />
                                                                    <p className='validatorError'> {isInvalidMsg ? '0 to 20 allowed' : ''} </p>

                                                                    <Button
                                                                        text='Submit'
                                                                        skin='dark'
                                                                        clickButton={onSubmitScore}
                                                                        loading={scoreAction['seekerId'] == props.modalItem['seekerId'] && isScoreSubmitting}

                                                                    />
                                                                </p>

                                                            </>
                                                        ) : (

                                                            <>
                                                                <Button
                                                                    clickButton={(e) => props.handleCompetitionAction(e, props.modalItem, 1)}
                                                                    skin="dark"
                                                                    text="Shortlist"
                                                                    loading={props.actionDetails[0]['seekerId'] == props.modalItem['seekerId'] && props.actionDetails[0]['action'] == 1 && props.modalItem['currentRound'] == 1}
                                                                    disabled={props.modalItem['currentRound'] != 1 || props.modalItem.rounds[1]['status'] == 0 || props.modalItem.rounds[1]['action'] > 0}

                                                                />

                                                                <Button
                                                                    clickButton={(e) => props.handleCompetitionAction(e, props.modalItem, 2)}
                                                                    skin="light"
                                                                    text="Reject"
                                                                    loading={props.actionDetails[0]['seekerId'] == props.modalItem['seekerId'] && props.actionDetails[0]['action'] == 2 && props.modalItem['currentRound'] == 1}
                                                                    disabled={props.modalItem['currentRound'] != 1 || props.modalItem.rounds[1]['status'] == 0 || props.modalItem.rounds[1]['action'] > 0}

                                                                />
                                                            </>
                                                        )
                                                    }



                                                </Table.Cell>
                                            </Table.Cell>
                                        </Table.Row>
                                        {
                                            [2].map(round => {

                                                return (
                                                    props.modalItem['rounds'][round] ? (
                                                        <Table.Row >
                                                            <Table.Cell>{`Round ${round}`}</Table.Cell>
                                                            <Table.Cell>{STATUS_MAP[props.modalItem['rounds'][round]['status']]}</Table.Cell>
                                                            <Table.Cell>

                                                                <p>
                                                                    <span><b>Score: </b></span>
                                                                    <span>{props.modalItem['rounds'][round] && props.modalItem['rounds'][round]['score'] ? `${props.modalItem['rounds'][round]['score']} / 20` : ''}</span>
                                                                </p>


                                                                {
                                                                    props.modalItem['rounds'][round]['problemStatement'] ? (
                                                                        <div>
                                                                            <p><b>Problem Statement: </b></p>
                                                                            <p>{props.modalItem['rounds'][round]['problemStatement']}</p>
                                                                            <br />
                                                                        </div>
                                                                    ) : null
                                                                }

                                                                <span><b>Submissions: </b></span>

                                                                {

                                                                    props.modalItem['rounds'][round]['solutionLinks'].map((solution, ind) => (

                                                                        <p onClick={e => { e.stopPropagation() }}>
                                                                            {
                                                                                solution['mimeType'] && solution['mimeType'].includes('video') ? (
                                                                                    <p onClick={e => { e.preventDefault() }}>
                                                                                        <a onClick={(e) => props.setVideoSource(solution['fileUrl'])} href='' >{solution['fileName']}</a>
                                                                                    </p>

                                                                                ) : (
                                                                                    <a key={ind} onClick={e => e.stopPropagation()} href={`${solution['fileUrl']}`} target="__blank">{solution['fileName']}</a>

                                                                                )
                                                                            }
                                                                        </p>
                                                                    ))
                                                                }
                                                                {/* <a onClick={e => e.stopPropagation()} href={`${props.modalItem['rounds'][round]['solutionLink']}`} target="__blank">PDF</a> */}
                                                            </Table.Cell>
                                                            <Table.Cell>{ACTION_MAP[props.modalItem['rounds'][round]['action']]}</Table.Cell>
                                                            <Table.Cell>
                                                                <Table.Cell>

                                                                    {
                                                                        props.modalItem['rounds'][props.modalItem['currentRound']] && props.modalItem.rounds[props.modalItem['currentRound']]['status'] == 1 && (props.modalItem['currentRound'] != 1 && !props.modalItem.rounds[props.modalItem['currentRound']]['score']) && props.modalItem['currentRound'] == round ? (
                                                                            <>
                                                                                <label>{`Round ${props.modalItem['currentRound']} Score`}</label>

                                                                                <p onClick={(e) => e.stopPropagation()}>
                                                                                    <InputBox error={isInvalidMsg} className='comp-score-input' name='score' type='number' max='20' value={scoreAction['seekerId'] == props.modalItem['seekerId'] ? scoreAction['score'] : ''} onChange={(e) => onScoreChange(e, props.modalItem)} />
                                                                                    <p className='validatorError'> {isInvalidMsg ? '0 to 20 allowed' : ''} </p>

                                                                                    <Button
                                                                                        text='Submit'
                                                                                        skin='dark'
                                                                                        clickButton={onSubmitScore}
                                                                                        loading={scoreAction['seekerId'] == props.modalItem['seekerId'] && isScoreSubmitting}
                                                                                        disabled={scoreAction['seekerId'] != props.modalItem['seekerId'] || isScoreSubmitting}


                                                                                    />
                                                                                </p>

                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button
                                                                                    clickButton={(e) => props.handleCompetitionAction(e, props.modalItem, 1)}
                                                                                    skin="dark"
                                                                                    text="Shortlist"
                                                                                    loading={props.actionDetails[0]['seekerId'] == props.modalItem['seekerId'] && props.actionDetails[0]['action'] == 1 && props.modalItem['currentRound'] == round}
                                                                                    disabled={props.modalItem['currentRound'] != round || props.modalItem.rounds[round]['status'] == 0 || props.modalItem.rounds[round]['action'] > 0}
                                                                                />

                                                                                <Button
                                                                                    clickButton={(e) => props.handleCompetitionAction(e, props.modalItem, 2)}
                                                                                    skin="light"
                                                                                    text="Reject"
                                                                                    loading={props.actionDetails[0]['seekerId'] == props.modalItem['seekerId'] && props.actionDetails[0]['action'] == 2 && props.modalItem['currentRound'] == round}
                                                                                    disabled={props.modalItem['currentRound'] != round || props.modalItem.rounds[round]['status'] == 0 || props.modalItem.rounds[round]['action'] > 0}
                                                                                />
                                                                            </>

                                                                        )

                                                                    }



                                                                </Table.Cell>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ) : null
                                                )
                                            })

                                        }
                                    </Table.Body>
                                </Table>


                                <h2><b>Mailers</b></h2>
                                <Table>
                                    <Table.Header>
                                        <Table.HeaderCell width={3}>
                                            Reminders Before Start
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Round Start Emailers
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Submission Reminders
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Result
                                        </Table.HeaderCell>

                                    </Table.Header>

                                    <Table.Body>

                                        {
                                            [1, 2, 3, 4].map((item, ind) => (
                                                <Table.Cell key={ind}>
                                                    <p >

                                                        <Dropdown
                                                            name='email'
                                                            data={EMAIL_TYPES[item]}
                                                            dropdownChange={(e) => props.onChangeSingleEmail(e, props.modalItem['seekerId'], item)}
                                                            defaultValue={props.singleMail[item] ? props.singleMail[item]['mail'] : ''}
                                                        />
                                                    </p>
                                                    <Button
                                                        clickButton={(e) => onSendEmail(e, item)}
                                                        skin="light"
                                                        text="Send Mail"
                                                        loading={isEmailSending == item}
                                                        disabled={!props.singleMail[item] || !props.singleMail[item]['mail']}
                                                    />
                                                </Table.Cell>
                                            ))
                                        }

                                    </Table.Body>
                                </Table>


                            </div>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            clickButton={props.onClose}
                            skin="light"
                            text="Close"
                        />
                    </Modal.Actions>
                </>
            )
            }
        </Modal >
    )
}

export default CompetitionModal