export const convertUnixToDate = (unixTs) => {
  let date = new Date(unixTs);
  let dd = date.getDate().toString();
  dd = dd.length < 2 ? `0${dd}` : dd;
  let mm = (date.getMonth() + 1).toString(); //January is 0!
  mm = mm.length < 2 ? `0${mm}` : mm;
  const yyyy = date.getFullYear();

  date = yyyy + '-' + mm + '-' + dd;
  return date;
}


export const convertUnixToTime = (unixTs) => {
  const date = new Date(unixTs);
  let hour = date.getHours().toString();
  hour = hour.length <= 1 ? `0${hour}` : hour;
  let mins = date.getMinutes().toString();
  mins = mins.length <= 1 ? `0${mins}` : mins;
  return `${hour}:${mins}`;
}

export const createMarkup = (title) => {
  return { __html: title };
}

export const getJAndR = (channelId) => {
  let cId = channelId.split('-');
  let a = cId[cId.length - 2];
  let b = cId[cId.length - 1];
  return {
    r: a.includes('r') ? a.substring(1, a.length) : b.substring(1, b.length),
    j: b.includes('j') ? b.substring(1, b.length) : a.substring(1, a.length),
  }
}

export function getJobUrl(title, jobId) {
  return `${process.env.REACT_APP_CLIENT_URL}/j/${title}-${jobId}.html`;
}

export function getQueryParams(queryString) {
    const obj = {};
    queryString = queryString.replace('?', '');
    queryString = queryString.split('&');
    for (let i = 0; i < queryString.length; i++) {
        const temp = queryString[i].split('=');
        obj[temp[0]] = temp[1]
    }
    return obj;
}

export function getQueryString(params) {
  let str = "?";
  for (let k in params) {
    str += params[k] ? `${k}=${params[k]}&` : '';
  }
  return str.substr(0, str.length - 1);
}

export function hideScroller() {
  document.body.style.overflow = "hidden";
}

export function showScroller() {
  document.body.style.overflow = "auto";
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function validateEmail(email) {

  if(!email.trim().length) return false;

  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  
  return emailRegex.test(email)

} 

export const getDomain = () => {
  try {
      if (process.env.REACT_APP_PLATFORM !== 'hirist') {
          return process.env.REACT_APP_DOMAIN;
      }

      if (isTechDomain()) {
          return process.env.REACT_APP_DOMAIN_TECH;
      } else {
          return process.env.REACT_APP_DOMAIN;
      }
  } catch (error) {
      console.log(".tech error")
      console.log(error);
      return process.env.REACT_APP_DOMAIN;
  }
}

export const isTechDomain = () => {
  if (!window) {
      return false;
  }

  const origin = window.location.origin;
  const originParts = origin.split('.');

  if (originParts[originParts.length - 1] === 'tech') {
      return true;
  } else {
      return false;
  }
}

export const cleanText = (text) =>  {
  text = text.trim();
    text = text.toLowerCase();
    var codeEntitiesMatch = [' ', '--', '&quot;', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '{', '}', '|', ':', '"', '<', '>', '?', '[', ']', '\\', ';', "'", ',', '.', '/', '*', '+', '~', '`', '='];
    var codeEntitiesReplace = ['-', '-', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '-', '', '', '', '', ''];
    for (var i = 0; i < codeEntitiesMatch.length; i++) {
        var regex = new RegExp('\\' + codeEntitiesMatch[i], 'g');
        text = text.replace(regex, codeEntitiesReplace[i]);
    }
    return text;
}