import React, { useState, useEffect } from "react";
import user from "../../store/user";
import updateSeekerEmailNotifSettings from "../../services/updateSeekerEmailNotifSettings";
import fetchSeekerEmailNotifSettings from "../../services/fetchSeekerEmailNotifSettings";


import "./seekerEmailNotificationSettings.scss";
import { Header, Label, Modal, Table } from "semantic-ui-react";
import Loader from "../../components/Loader";
import CountDisplay from "../../components/CountDisplay";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import { toast, ToastContainer } from "react-toastify";
import InputBox from "../../components/InputBox";
import PillButton from "../../components/PillButton";
import ActionIcons from "../../components/ActionIcons";
import { getQueryParams } from "../../utils/utilFunctions";

const PAGE_CONTENT = 10;
const modalInitObj = {
    'jobfeed': 1,
    'follw_upused': 1,
    'chat': 1,
    'search': 1,
    'jobapply': 1,
    'pending_test': 1,
    'education': 1,
    'boost': 1,
    'follw_up': 1,
    'promotions': 1,
    'jobaction': 1,
    'unpublish': 1
}
const deselectedObj = {
    'jobfeed': 0,
    'follw_upused': 0,
    'chat': 0,
    'search': 0,
    'jobapply': 0,
    'pending_test': 0,
    'education': 0,
    'boost': 0,
    'follw_up': 0,
    'promotions': 0,
    'jobaction': 0,
    'unpublish': 0
}

const optionsArr = ['jobfeed', 'follw_upused', 'chat', 'search', 'jobapply', 'pending_test', 'education', 'boost', 'follw_up', 'promotions', 'jobaction', 'unpublish'];

const tooltipMessage = {
    'jobfeed': 'Daily jobfeeds',
    'follw_upused': 'Notification for follow-up used',
    'chat': 'Chat notifications in your inbox',
    'search': 'Recruiter Activity on your profile in database',
    'jobapply': ' Application confirmation emails right after applying to any job.',
    'pending_test': 'Pending test notification email.',
    'education': 'Courses notifications based on your profile.',
    'boost': 'Interesting relevant jobs for your profile.',
    'follw_up': 'Notification for follow-up credited in your account.',
    'promotions': 'Good job offers or other promotional content from recruiters.',
    'jobaction': 'Recruiter action email in your inbox.' ,
    'unpublish': 'Updates about the job unpublished status.'
}
const SettingsEmailNotificationSettings = (props) => {

    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 0
    })
    const [admin, setAdmin] = useState({
        adminId: null,
    })
    const [fetchApiCall, setFetchApiCall] = useState(true)

    const [allEmails, setAllEmails] = useState([])
    const [selectedEmails, setSelectedEmails] = useState([])

    const [allModalEmails, setAllModalEmails] = useState([])
    const [selectedModalEmails, setSelectedModalEmails] = useState([])

    const [openModalStatus, setOpenModalStatus] = useState(false)

    const [modalItem, setModalItem] = useState({...modalInitObj})

    const [nextCursor, setNextCursor] = useState(null)
    const [prevCursor, setPrevCursor] = useState(null)

    const [showPagination, setShowPagination] = useState(true)

    useEffect(() => {
        const queryObj = getQueryParams(props.location.search);
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
                setAdmin({
                    adminId: userData.id,
                })
        
                let email = queryObj.email;

                if (email) {
                    getSeekerNotificationSettings(userData.id, null, null, {
                        seekerEmail: email
                    });
                } else {
                    getSeekerNotificationSettings(userData.id, nextCursor, prevCursor);
                }
                
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });

        

    }, [])

    const getSeekerNotificationSettings = async (adminId, nextCursor, prevCursor, filters = {}) => {
        setFetchApiCall(true);
        try {
            

            const res = await fetchSeekerEmailNotifSettings(adminId, { nextCursor, prevCursor, pageContent: PAGE_CONTENT, ...filters })
            const data = res.data.data.data;

            const dataWithSelected = data.map(item => {
                return {
                    ...item,
                    selectAll: checkAllSelected(item)
                }
            })
            setContent({
                list: dataWithSelected,
            })
            setNextCursor(res.data.data.nxtCursor)
            setPrevCursor(res.data.data.prevCursor)
            setFetchApiCall(false)
        }
        catch (err) {
            console.log(err);
            setFetchApiCall(false)
        }
    }


    const searchSeeker = async () => {
        
        if(selectedEmails.length) {
            setShowPagination(false)
        }
        else{
            setShowPagination(true)
        }
        const emails = selectedEmails.map(email => email.email).join(',')
        await getSeekerNotificationSettings(admin.adminId, null, null, {seekerEmail: emails})
        
        
      };

    // const clearSearch = async (e) =>  {
    //     setSelectedEmails([]);
    //     setAllEmails([]);
    //     setNextCursor(null)
    //     setPrevCursor(null)
    //     setIsCleared(true)
    //     getSeekerNotificationSettings(admin.adminId)
    // };


    const onSettingChange = async (e, seekerId,index) => {

        let notifSetting = [];
        let optionsMap = {};

        const newList = [...content.list]


        if(e.target.name == 'selectAll'){

            const isAllSelected = newList[index]['selectAll'];

            if(isAllSelected){
                optionsMap = {...deselectedObj};
                newList[index] = {...newList[index], ...optionsMap}
            }
            else{
                optionsMap = {...modalInitObj};
                newList[index] = {...newList[index], ...optionsMap}

            }

            for(const [key, value] of Object.entries(optionsMap)){
                notifSetting.push({
                    name: key,
                    value: value
                })
            }

        }

        else {
            newList[index][e.target.name] = newList[index][e.target.name] ? 0 : 1;
            notifSetting = [{
                name: e.target.name,
                value: newList[index][e.target.name]
            }]
        } 

        try {
            await updateSeekerEmailNotifSettings(admin.adminId, {
                seekerId,
                notifSetting
            })

            await searchSeeker();
            toast.success('Settings updated successfully')
            
        }catch(e) {
            console.log(e);
            toast.error('Something went wrong')
        }
    }

    const checkAllSelected = (item) => {

        for(let option of optionsArr) {
            if(item[option] == 0){
                return false;
            }
        }

        return true;
    }

    const handlePillChange = (ev, data) => {
        const allEmail = [];
        const selectedEmail= [];

        data.value.forEach((email, i) => {
            allEmail.push({key: i, value: email, text: email})
            selectedEmail.push({email, id: i})
        })

        setAllEmails(allEmail)
        setSelectedEmails(selectedEmail)
    }

    const handleModalPillChange = (ev, data) => {
        const allEmail = [];
        const selectedEmail= [];
        
        data.value.forEach((email, i) => {
            allEmail.push({key: i, value: email, text: email})
            selectedEmail.push({email, id: i})
        })

        setAllModalEmails(allEmail)
        setSelectedModalEmails(selectedEmail)
    }

    const handleModalClose = () => {
        setOpenModalStatus(false)
        setModalItem({...modalInitObj})
        setSelectedModalEmails([])
    }

    const onClickModalActionBtn = async() => {
        
        const emails = selectedModalEmails.map(email => email.email)
        const settings = [];
        for(let key in modalItem){
            settings.push({'name': key, 'value': modalItem[key]})
        }

        try{
            const res = await updateSeekerEmailNotifSettings(admin.adminId, {
                seekerEmail: emails,
                notifSetting: settings
            })

            if(res.data.data.length) {
                const invalidEmails = res.data.data.join(', ');
                 return toast.error(`${invalidEmails} ${res.data.data.length > 1 ? 'are' : 'is'} invalid`)
            }
            
            toast.success("Settings successfully updated")
            handleModalClose();

        }catch(e){
            console.log(e);
            toast.error('Something went wrong')
        }
    }

    const onModalSettingChange = (e) => {
        
        const name = e.target.name;
        const value = e.target.value;
        setModalItem(prev => ({
            ...prev,
            [name]: value ? 0: 1
        }))
    }

    const loadNextPage = async (e) => {
        e.preventDefault();
        await getSeekerNotificationSettings(admin.adminId, nextCursor)
    }

    const loadPrevPage = async (e) => {
        e.preventDefault();
        await getSeekerNotificationSettings(admin.adminId, null, prevCursor)

    }

    return (
        <div className="recruiter-company-list-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="company-list-heading">
                <Heading text="Seeker Email Notification Settings" />
            </div>


            <div className='seeker-notification-setting-search-field'>
                <div className="search-email-wrapper">
                    <label>Search Email: </label>
                    <PillButton data={allEmails} placeholder='Enter seeker email' name='selectedEmails' onChange={handlePillChange} allowAdditions onAddOtherLocations={handlePillChange} defaultValue={[]} maximumValues={10} />

                    <Button skin="dark" text="Search" clickButton={searchSeeker}/>
                    {/* <Button skin="light" text="Clear" clickButton={clearSearch} /> */}

                </div>
                <div className='right-aligned'>
                    <Button skin="dark" text="Edit For Multiple Seekers" clickButton={() => {setOpenModalStatus(true)}} />
                </div>

            </div>
            <hr />
            
            <div className="row text-center">

                {
                    showPagination ? (
                        <div className="seeker-setting-pagination" >
                            <li>
                                <a href="#" onClick={loadPrevPage}>{'<<Prev'}</a>
                            </li>
                            <li>
                                <a href="#" onClick={loadNextPage}>{'Next>>'}</a>
                            </li>
                        </div>
                    ): null
                }
 <Modal className="seeker-notif-setting-modal" size="large" open={openModalStatus} onClose={handleModalClose}>
                            <Header content='Edit Notification Settings' />
                            <Modal.Content>
                                {
                                    <>
                                        <PillButton data={allModalEmails} placeholder='Enter seeker email' name='selectedModalEmails' onChange={handleModalPillChange} allowAdditions onAddOtherLocations={handlePillChange} defaultValue={[]} maximumValues={10} />
                                        <div className="seeker-notifictions-settings-grid">
                                            
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="jobfeed" value={modalItem.jobfeed} onChange={onModalSettingChange} checked={modalItem['jobfeed'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Daily New Jobs</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobfeed}/>

                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="follw_upused" value={modalItem.follw_upused} onChange={onModalSettingChange} checked={modalItem['follw_upused'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Follow-up Used</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.follw_upused}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="chat" value={modalItem.chat} onChange={onModalSettingChange} checked={modalItem['chat'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Chat Notifications</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.chat}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="search" value={modalItem.search} onChange={onModalSettingChange} checked={modalItem['search'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Recruiter viewing my profile</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.search}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="jobapply" value={modalItem.jobapply} onChange={onModalSettingChange} checked={modalItem['jobapply'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Applied Jobs</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobapply}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="pending_test" value={modalItem.pending_test} onChange={onModalSettingChange} checked={modalItem['pending_test'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Pending Test</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.pending_test}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="education" value={modalItem.education} onChange={onModalSettingChange} checked={modalItem['education'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Educational-Learn and Grow</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.education}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="boost" value={modalItem.boost} onChange={onModalSettingChange} checked={modalItem['boost'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Job Relevant to me</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.boost}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="follw_up" value={modalItem.follw_up} onChange={onModalSettingChange} checked={modalItem['follw_up'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Follow-up Credited</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.follw_up}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="promotions" value={modalItem.promotions} onChange={onModalSettingChange} checked={modalItem['promotions'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Promotional</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.promotions}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="jobaction" value={modalItem.jobaction} onChange={onModalSettingChange} checked={modalItem['jobaction'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Recruiter's Actions</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobaction}/>
                                                    </div>
                                                    <div className="option-container">
                                                        <InputBox type='checkbox' name="unpublish" value={modalItem.unpublish} onChange={onModalSettingChange} checked={modalItem['unpublish'] ? true : false} />
                                                        <label className="seeker-email-setting-label">Unpublished Job Notification</label>
                                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.unpublish}/>
                                                    </div>
                                                </div>
                                            </>
                                }
                            </Modal.Content>
                            <Modal.Actions>
                                <Button disabled={selectedModalEmails.length == 0} clickButton={onClickModalActionBtn} skin='dark' text='Confirm'/>
                                <Button clickButton={handleModalClose} skin="light" text="Close"/>
                                
                            </Modal.Actions>
                        </Modal>
                

                {fetchApiCall ? (
                    <Loader />
                ) : (
                    content.list.length ? (
                        <div className="content">
                        <Table
                            fixed
                            selectable
                            className="seeker-notification-settings-table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Email
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Select All
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Daily New Jobs
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobfeed}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Follow-up used
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.follw_upused}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Chat Notifications
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.chat}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Recruiters Viewing My Profile
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.search}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Applied Job
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobapply}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Pending Test
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.pending_test}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Educational-Learn and Grow
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.education}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Job Relevant for me
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.boost}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Follow-up credited
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.follw_up}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Promotional
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.promotions}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Recruiter's Actions
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.jobaction}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Unpublished Job Notification
                                        <ActionIcons type="onHoldJob" tooltipMessage={tooltipMessage.unpublish}/>
                                    </Table.HeaderCell>
                                    
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.list.map((item, i) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                {item.id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.email}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="selectAll" value={item.selectAll}  onChange={(e) => onSettingChange(e, item.id, i)} checked={item['selectAll'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="jobfeed" value={item.jobfeed} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['jobfeed'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="follw_upused" value={item.follw_upused} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['follw_upused'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="chat" value={item.chat} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['chat'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="search" value={item.search} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['search'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="jobapply" value={item.jobapply} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['jobapply'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="pending_test" value={item.pending_test} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['pending_test'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="education" value={item.education} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['education'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="boost" value={item.boost} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['boost'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="follw_up" value={item.follw_up} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['follw_up'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="promotions" value={item.promotions} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['promotions'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="jobaction" value={item.jobaction} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['jobaction'] ? true : false} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <InputBox type='checkbox' name="unpublish" value={item.unpublish} onChange={(e) => onSettingChange(e, item.id, i)} checked={item['unpublish'] ? true : false} />
                                            </Table.Cell>
                                            
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>


                    </div>
                    ) : (
                        <div>
                            No Records Found
                        </div>
                    )
                )
                }
            </div>
        </div>
    );
};

export default SettingsEmailNotificationSettings;