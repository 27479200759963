import axios from '../utils/axios';

export default function fetchActiveShowcaseCompanies(adminId) {
    return (
        axios.get('/admin/' + adminId + '/showcase-companies', {
            params: {
                status: 1
            }
        })
    )
}
