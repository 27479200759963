import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, Table, Header } from 'semantic-ui-react';

import InputBox from '../../components/InputBox';
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { JobSeekerItem, JOBSEEKER_MGT_ACTIONS } from './JobSeekerItem';

import user from '../../store/user';
import getSeekerList from '../../services/getSeekerList';
import updateSeekerProfile from '../../services/updateSeekerProfile';
import updateSeekerSubscription from '../../services/updateSeekerSubscription';
import changeSeekerActivationStatus from '../../services/changeSeekerActivationStatus';
import sendOtpApi from '../../services/sendOtp';
import { getQueryParams, isTechDomain } from '../../utils/utilFunctions';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

import { JOBSEEKER_MGT } from '../../models/routes';

import './JobSeekerList.scss';
import SecurityModal from '../../components/SecurityModal/SecurityModal';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import captchaVerification from '../../services/captchaVerification';


const JobSeekerManagement = ({ location, history }) => {
    const PAGE_CONTENT = 50;
    const [adminId, setAdminId] = useState(null);
    const [adminEmail, setAdminEmail] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchError, setSearchError] = useState(null);
    const searchRef = useRef(null);

    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [seekerList, setSeekerList] = useState(null);
    const [showCaptcha, setShowCaptch] = useState(false);

    const [viewResumeModal, setViewResumeModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const [resumeNumPages, setResumeNumPages] = useState(1)


    useEffect(() => {
        const getProfile = async () => {
            const userData = await user.getProfile();
            return userData;
        }

        getProfile().then(data => {
            setAdminId(data.id);
            setAdminEmail(data.email);

            if (!data || data.status != 1 || (data.adminType != 2 && data.adminType != 3)) {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }

            if (!user.checkFeatureAccess(adminFeatureMapping.JOBSEEKER_MGT)) {
                window.location.href = '/notAdmin';
            }

            const isDeleteAccess = user.checkFeatureAccess(adminFeatureMapping.SEEKER_PROFILE_DELETE);
            if (isDeleteAccess) {
                setShowDelete(true);
            } else {
                setShowDelete(false);
            }

        }).catch(error => {
            // console.log(error);
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        });
    }, []);

    useEffect(() => {
        if (adminId) {
            // check for query params
            const params = getQueryParams(location.search);

            if (params.searchtext) {
                setSearchText(params.searchtext);
                handleGetSeekerList(null, params.searchtext);
            }
        }
    }, [adminId]);

    const onClickViewResume = (item) => {
        setShowCaptch(true);
        setModalItem(item);
    }

    const handleSecuriySubmit = async (token) => {

        setShowCaptch(false);
        try {
            await captchaVerification(adminId, { token, })
            if (modalItem) {
                setViewResumeModal(true);
            }

        } catch (error) {
            console.log(error);
            toast.error('captcha verification failed')

        }



    }

    const getParameters = (text = null) => {
        const params = {
            pageContent: PAGE_CONTENT,
            pageNumber: pageNumber,
        };

        params.searchtext = text || searchText.trim();
        return params;
    }

    const handleGetSeekerList = async (event, search = null) => {
        const text = search || searchText;

        if (!text.trim()) {
            setSearchError('Please enter search text!');
            return;
        }

        setIsLoading(true);
        const params = getParameters(text);

        try {
            const results = await getSeekerList(adminId, params);

            const resultsArr = results.data.data;
            if (resultsArr.length > 0) {
                setSeekerList(resultsArr);
            } else {
                setSeekerList([]);
            }

            updateQueryParams(text);

        } catch (error) {
            setSeekerList([]);
            // console.log(error);
        }

        setIsLoading(false);
    }

    const updateSeekerProSubscription = async (data) => {
        setActionLoading(true);

        try {
            const response = await updateSeekerSubscription(adminId, data);

            toast.success('Jobseeker updated successfully!');

            setSeekerList(null);
            handleGetSeekerList();
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again!')
        }

        setActionLoading(false);
    }

    const activateDeactivateSeeker = async (action, userId) => {
        setActionLoading(true);

        try {
            const response = await changeSeekerActivationStatus(adminId, {
                userId,
                action,
                recEmail: adminEmail
            });

            if (action === JOBSEEKER_MGT_ACTIONS.ACTIVATE) {
                toast.success('Jobseeker activated successfully');
            } else if (action === JOBSEEKER_MGT_ACTIONS.DEACTIVATE) {
                toast.success('Jobseeker deactivated successfully');
            }

            // console.log(response);
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later!');
        }
    };

    const updateSeeker = async (action, params) => {
        setActionLoading(true);

        try {
            const response = await updateSeekerProfile(adminId, action, params);

            // reload list
            setSeekerList(null);
            handleGetSeekerList();
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again!')
        }

        setActionLoading(false);
    };

    const sendOtp = async () => {
        console.log("Send OTP ...", adminId);
        await sendOtpApi(adminId, 1);
    }

    const updateQueryParams = searchtext => {
        history.push(`${JOBSEEKER_MGT}?searchtext=${searchtext}`);
    };

    const handleViewResumeModalClose = () => {
        setViewResumeModal(false);
        setModalItem(null);
    };

    const downloadResume = () => {
        // window.open(
        //     `${process.env.REACT_APP_BASE_URL}${modalItem.resume}`,
        //     "_blank"
        // );
    };

    const onResumeLoadSuccess = ({ numPages }) => {
        setResumeNumPages(numPages);
    }

    const handleViewResumeClose = () => {
        setViewResumeModal(false);
        setModalItem(null);
    };

    const controllerContent = (
        <div className='search_container'>
            <div className='search_container-input'>
                <InputBox
                    type='text'
                    className={`input-search ${searchError ? 'error' : ''}`}
                    name='input-search'
                    placeholder="Enter Email / ID"
                    value={searchText}
                    _ref={searchRef}
                    onChange={event => {
                        setSearchText(event.target.value);
                        setSearchError(null);
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            handleGetSeekerList();
                        }
                    }}
                />
                {searchError ? (
                    <div className='search-error'>{searchError}</div>
                ) : null}
            </div>
            <Button className="btn-search" skin="dark" text="Search" clickButton={handleGetSeekerList}></Button>
        </div>
    );

    let mainContent = null;

    if (isLoading) {
        mainContent = (
            <Loader />
        );
    } else if (!isLoading && seekerList && seekerList.length == 0) {
        mainContent = (
            <div>No Record Found!</div>
        )
    } else if (!isLoading && seekerList && seekerList.length > 0) {
        const tableHeaders = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="one">ID</Table.HeaderCell>
                    <Table.HeaderCell width="one">Email</Table.HeaderCell>
                    <Table.HeaderCell width="one">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
        mainContent = (
            <div className='seekerlist__container'>
                <Table>
                    {tableHeaders}

                    <Table.Body>
                        {seekerList.map(item => {
                            return (
                                <JobSeekerItem 
                                    data={item} 
                                    updateSeeker={updateSeeker}
                                    changeSeekerStatus={activateDeactivateSeeker}
                                    updateSeekerSubscription={updateSeekerProSubscription} 
                                    onClickViewResume={onClickViewResume} 
                                    showDelete={showDelete} 
                                    sendOtp={sendOtp}
                                />
                            ); 
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    return (
        <div className='page-content seeker-mgt'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className='page-heading'>
                <Heading text="JobSeeker Management" />
                <div className='page-heading-actions'>
                    <a className='btn-blacklist' href={process.env.REACT_APP_PLATFORM !== 'updazz' ? '/recruiter/blacklist' : process.env.REACT_APP_CLIENT_URL + '/recruiter/blacklist.php'} target='_blank' rel='noopener noreferrer'>Blacklist</a>
                    <a className='btn-blacklist' href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/seeker_mgt.php`} target='_blank' rel='noopener noreferrer'>Use Old Version</a>
                </div>
            </div>
            {showCaptcha ?
                <SecurityModal submit={handleSecuriySubmit} />
                : null
            }

            {controllerContent}

            {actionLoading ? <Loader /> : null}

            <div className="main_container">
                {mainContent}
            </div>

            <Modal
                className="jobseeker-resume-modal"
                open={viewResumeModal}
                onClose={handleViewResumeClose}
                size="large"
            >
                {
                    modalItem && (
                        <>
                            <Header content={modalItem.name} />
                            <Modal.Content scrolling>
                                {
                                    modalItem.resume ? (
                                        <div className="resume-container">
                                            <Document file={`${isTechDomain() ? process.env.REACT_APP_BASE_URL_TECH :  process.env.REACT_APP_BASE_URL}${modalItem.resume}`} onLoadSuccess={onResumeLoadSuccess} error="No Resume Found!">
                                                {
                                                    Array.from(Array(resumeNumPages).keys()).map(key =>
                                                        <Page pageNumber={key + 1} />
                                                    )
                                                }
                                            </Document>
                                        </div>


                                    ) : <div>No Resume Found!</div>
                                }
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    clickButton={downloadResume}
                                    skin="dark"
                                    text="Download Resume"
                                />
                                <Button
                                    clickButton={handleViewResumeModalClose}
                                    skin="light"
                                    text="Close"
                                />
                            </Modal.Actions>
                        </>
                    )
                }
            </Modal>

        </div>
    );
};

export default JobSeekerManagement;