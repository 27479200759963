export const EMAIL_TYPES = {
    "1": [
        { id: '', name: 'Select email' },

        {
            name: "Welcome Email",
            id: "1"
        },

        {
            name: 'Round 1 Reminder 1',
            id: '2'
        },
        {
            name: 'Round 1 Reminder 2',
            id: '3'
        },
        {
            name: 'Round 1 Reminder 3',
            id: '4'
        },


        {
            name: 'Round 2 Reminder 1',
            id: '8'
        },
        {
            name: 'Round 2 Reminder 2',
            id: '9'
        },
        {
            name: 'Round 2 Reminder 3',
            id: '10'
        },


        // {
        //     name: 'Round 3 Reminder 1',
        //     id: '14'
        // },
        // {
        //     name: 'Round 3 Reminder 2',
        //     id: '15'
        // },
        // {
        //     name: 'Round 3 Reminder 3',
        //     id: '16'
        // },


        // {
        //     name: 'Round 4 Reminder 1',
        //     id: '20'
        // },
        // {
        //     name: 'Round 4 Reminder 2',
        //     id: '21'
        // },
        // {
        //     name: 'Round 4 Reminder 3',
        //     id: '22'
        // },


    ],
    "2": [
        { id: '', name: 'Select email' },

        {
            name: "Round 1 start",
            id: "5"
        },

        {
            name: "Round 2 start",
            id: "11"
        },

        // {
        //     name: "Round 3 start",
        //     id: "17"
        // },

        // {
        //     name: "Round 4 start",
        //     id: "23"
        // },

    ],
    "3": [
        { id: '', name: 'Select email' },

        {
            name: "Round 1 Submission Reminder 1",
            id: "26"
        },

        {
            name: "Round 1 Submission Reminder 2",
            id: "27"
        },

        {
            name: "Round 1 Submission Reminder 3",
            id: "34"
        },

        {
            name: "Round 2 Submission Reminder 1",
            id: "28"
        },

        {
            name: "Round 2 Submission Reminder 2",
            id: "29"
        },

        {
            name: "Round 2 Submission Reminder 3",
            id: "35"
        },

        // {
        //     name: "Round 3 Submission Reminder 1",
        //     id: "30"
        // },

        // {
        //     name: "Round 3 Submission Reminder 2",
        //     id: "31"
        // },

        // {
        //     name: "Round 4 Submission Reminder 1",
        //     id: "32"
        // },

        // {
        //     name: "Round 4 Submission Reminder 2",
        //     id: "33"
        // },
    ],
    "4": [

        { id: '', name: 'Select email' },

        {
            name: "Round 1 Result Declare",
            id: "6"
        },

        {
            name: "Result to Round 1 qualifiers",
            id: "7"
        },


        {
            name: "Round 2 Result Declare",
            id: "12"
        },

        {
            name: "Result to Round 2 qualifiers",
            id: "13"
        },


        // {
        //     name: "Round 3 Result Declare",
        //     id: "18"
        // },

        // {
        //     name: "Result to Round 3 qualifiers",
        //     id: "19"
        // },


        // {
        //     name: "Round 4 Result Declare",
        //     id: "24"
        // },

        // {
        //     name: "Result to Round 4 qualifiers",
        //     id: "25"
        // },

    ]
}


export const SCORE_FILTER_TYPES = [
    { id: '1', name: 'Greater than Equal' },
    { id: '2', name: 'Less Than Equal' },


]