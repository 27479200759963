import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import getAllCompanies from '../../../services/getCompanyManagement';
import getUnverifiedCompanies from '../../../services/getUnverifiedCompanies';
import Button from '../../../components/Button';
import CompanySingleList from './components/CompanySingleList';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import Loader from '../../../components/Loader';
import user from '../../../store/user';
import updateCompanyManagement from '../../../services/updateCompanyManagement';
import verifyCompanyManagement from '../../../services/verifyCompanyManagement';
import rejectCompanyManagement from '../../../services/rejectCompanyManagement';
import './CompanyStyles.scss';
import { ToastContainer, toast } from 'react-toastify';
import { Tab, Table, TableBody, TableHeader, TableRow } from 'semantic-ui-react';
import InputBox from '../../../components/InputBox';
import fetchIndustries from '../../../services/fetchIndustries';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import { getQueryParams } from '../../../utils/utilFunctions';
import { Company_Management_List } from '../../../models/routes';

const CompanyList = (props) => {
  const [status, setStatus] = useState('1');
  const [CompanyListData, setCompanyListData] = useState([]);
  const pageContent = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [count, setTotalCount] = useState('');
  const [adminId, setAdminId] = useState('');
  const [industryData, setIndustryData] = useState({});
  const [executeAccess, setExecuteAcess] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [companyType, setCompanyType] = useState('0');

  useEffect(() => {
    async function fetchUserData() {
      const userDetails = await user.getProfile();
      const adminIdData = userDetails.id;
      const adminEmail = userDetails.email;

      const queryParams = getQueryParams(props.location.search || '');
      
      const params = {
        pageNumber: pageNumber,
        pageContent: pageContent,
        status,
      };

      // check if query params has a status value
      if (queryParams) {
        if (queryParams.search) {
            setSearchText(queryParams.search);
            params.nameLike = queryParams.search;
            params.infoedgeIdLike = queryParams.search;
            params.domainLike = queryParams.search;
            params.or = 1;
        }

        if (queryParams.status) {
            setStatus(queryParams.status);
            params.status = queryParams.status;
        }

        if (queryParams.companyType) {
          setCompanyType(queryParams.companyType);
        }

        if (queryParams.companyType == 1) {
          params.unicornStatus = 1;
        } else if (queryParams.companyType == 2) {
          params.premiumRankIimjobsGTE = 1;
        } else if (queryParams.companyType == 3) {
          params.premiumRankHiristGTE = 1;
        }
      }

      if (
        user.checkFeatureAccess(adminFeatureMapping.COMPANY_MGT)
      ) {
        setExecuteAcess(true);
      }
      await setAdminId(adminIdData);

      setCompanyListDataFromApi(adminIdData, params);
      var industryApiData = await fetchIndustries();
      var indObj = {};
      for (
        var indIterator = 0;
        indIterator < industryApiData.industries.length;
        indIterator++
      ) {
        indObj[industryApiData.industries[indIterator].id] =
          industryApiData.industries[indIterator].name;
      }
      setIndustryData(indObj);
    }
    fetchUserData();
  }, []);

  const updateQueryParams = (filterStatus = null, handleClear = false, companyType = null) => {
    const arr = [];
    const selectedStatus = filterStatus ? filterStatus : status;
    const search = handleClear ? null : searchText ? searchText : null;  

    arr.push(`status=${selectedStatus}`);

    if (search) {
        arr.push(`search=${search}`);
    }

    if (companyType) {
      arr.push(`companyType=${companyType}`);
    }

    props.history.push(`${Company_Management_List}?${arr.join('&')}`);
  }

  const handleFilterChange = event => {
    selectCompanyStatus(event.target.value);
    updateQueryParams(event.target.value);
  }

  const handleCompanyFilterChange = event => {
    console.log("Change Type -- ", event.target.value);
    selectCompanyType(event.target.value);
    updateQueryParams(null, null, event.target.value);
  }

  async function selectCompanyType(value) {
    setCompanyType(value);
    setPageNumber(1);
    setIsLoading(true);
    var params = getParams();
    params.pageNumber=1;

    delete params.unicornStatus;
    delete params.premiumRankIimjobsGTE;
    delete params.premiumRankHiristGTE;

    if (value == 1) {
      params.unicornStatus = 1;
    } else if (value == 2) {
      params.premiumRankIimjobsGTE = 1;
    } else if (value == 3) {
      params.premiumRankHiristGTE = 1;
    }

    setCompanyListDataFromApi(adminId, params);
  }

  async function selectCompanyStatus(value) {
    setStatus(value);
    setPageNumber(1);
    setIsLoading(true);
    var params = getParams();
    params.status = Number(value);
    params.pageNumber=1;
    setCompanyListDataFromApi(adminId, params);
  }

  async function navEditPage(id, status) {
    props.history.push(`/managementCompany/${id}?status=${status}`);
  }

  async function newCompanyLink() {
    props.history.push('/NewCompany/');
  }

  function getParams() {
    var params = {
      status: status,
      pageNumber: pageNumber,
      pageContent: pageContent,
      companyType: companyType
    };

    if (companyType == 1) {
      params.unicornStatus = 1;
    } else if (companyType == 2) {
      params.premiumRankIimjobsGTE = 1;
    } else if (companyType == 3) {
      params.premiumRankHiristGTE = 1;
    }

    if (searchText) {
      params.nameLike = searchText;
      params.infoedgeIdLike = searchText;
      params.domainLike = searchText;
      params.or = 1;
    }
    return params;
  }

  async function setCompanyListDataFromApi(adminIdData = '', params) {
    if (adminId != '') adminIdData = adminId;
    let companyData = null;
    
    if (params.status == 1) {
        companyData = await getAllCompanies(adminIdData, params);
    } else {
        companyData = await getUnverifiedCompanies(adminIdData, params);
    }

    setCompanyListData(companyData.data.data.companies || []);
    setTotalCount(parseInt(companyData.data.data.totalCount));
    setIsLoading(false);
    setIsPageLoading(false);
  }

  async function handlePageChange(receivedPageNumber) {
    setPageNumber(receivedPageNumber);
    setIsPageLoading(true);
    var params = getParams();
    params.pageNumber = receivedPageNumber;
    setCompanyListDataFromApi(adminId, params);
  }

  async function rejectCompany(companyIndex) {
    var wholeData = CompanyListData[companyIndex];
    wholeData.status = 0;
    
    await rejectCompanyManagement(adminId, wholeData.id, wholeData)
        .then(res => {
          if (res.status != 'success') {
            toast.error('Reject failed');
            return;
          }
          toast.info('Rejected Successfully');
          var newDataObj = JSON.parse(JSON.stringify(CompanyListData));
          newDataObj[companyIndex].status = wholeData.status;
          setCompanyListData(newDataObj);
        }).catch (error => {
          // eslint-disable-next-line no-undef
        //   console.log(err);
        })
  }

  async function changeStatus(companyIndex) {
    var wholeData = CompanyListData[companyIndex];
    let companyId = wholeData.status == 0 ? wholeData.companyId : wholeData.id;

    const doVerify = wholeData.status == 2 ? true : false;
    wholeData.status = wholeData.status == 1 ? 0 : 1;
    if (doVerify) {
      await verifyCompanyManagement(adminId, wholeData.id, wholeData)
        .then(res => {
          if (res.status != 'success') {
            toast.error('Verification failed');
            return;
          }
          toast.info('Verified Successfully');
          var newDataObj = JSON.parse(JSON.stringify(CompanyListData));
          newDataObj[companyIndex].status = wholeData.status;
          setCompanyListData(newDataObj);
        }).catch (error => {
          // eslint-disable-next-line no-undef
        //   console.log(err);
        })
    } else {
        await updateCompanyManagement(adminId, companyId, wholeData)
        .then((res) => {
          if (res.status != 'success') {
            toast.error('Update failed');
            return;
          }
          toast.info('Updated Successfully');
          var newDataObj = JSON.parse(JSON.stringify(CompanyListData));
          newDataObj[companyIndex].status = wholeData.status;
          setCompanyListData(newDataObj);
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
        //   console.log(err);
        });
    }
  }

  function onChangeSearch(ev) {
    if (ev.key === 'Enter') {
      setPageNumber(1);
      var params = getParams();
      params.pageNumber = 1;
      setIsLoading(true);
      setCompanyListDataFromApi(adminId, params);
      return;
    }
    setSearchText(ev.target.value);
  }
  function detectEnter(ev) {
    if (ev.key === 'Enter') {
      setPageNumber(1);
      var params = getParams();
      params.pageNumber = 1;
      setIsLoading(true);
      setCompanyListDataFromApi(adminId, params);
      updateQueryParams();
      return;
    }
  }

  async function onClickClear() {
    setSearchText('');
    setPageNumber(1);
    setIsLoading(true);
    setStatus(1)
    var params = {
      status: 1,
      pageNumber: 1,
      pageContent: pageContent,
    };
    updateQueryParams(1, true);
    setCompanyListDataFromApi(adminId, params);
  }

  return (
      <div>
          <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />
          <div className="row">
              <div className="heading inlineElement">Select Status :</div>
              <div className="inlineElement">
                  <select value={status} onChange={handleFilterChange}>
                      <option name="recType" value="1">Active</option>
                      <option name="recType" value="2">Unapproved</option>
                      <option name="recType" value="0">Rejected/Inactive</option>
                      <option name="recType" value="3">Duplicate</option>
                  </select>
              </div>
              {executeAccess ? (
                  <Button
            skin="dark"
            text="Add New Company"
            clickButton={() => newCompanyLink()}
          />
        ) : null}

              <div
          className="right"
          style={{ display: 'flex', alignItems: 'baseline' }}
        >
                  <InputBox
            placeholder="Enter Company Name"
            value={searchText}
            onChange={onChangeSearch}
            onKeyPress={detectEnter}
          />
                  <Button
            className="search-btn"
            skin="dark"
            disabled={false}
            text="Search"
            clickButton={() => {
              var params = getParams();
              setCompanyListDataFromApi(adminId, params);
              updateQueryParams();
            }}
          />
                  <Button
            skin="light"
            disabled={false}
            text="Clear"
            clickButton={onClickClear}
          ></Button>
              </div>
          </div>
          <br />

          <div className="row">
              <div className="inlineElement">
                  <select value={companyType} onChange={handleCompanyFilterChange}>
                      <option name="compType" value="0">All Companies</option>
                      <option name="compType" value="1">Unicorns</option>
                      <option name="compType" value="2">IIMJOBs Premium</option>
                      <option name="compType" value="3">Hirist Premium</option>
                  </select>
              </div>
          </div>

          <br />
          <div className="centerAlign">
              <div className="paginationManagement">
                  <Pagination
            totalItemsCount={count}
            content={pageContent}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
          />
              </div>
              <div className="countDisplayManagement">
                  <CountDisplay
            start={(pageNumber - 1) * pageContent + 1}
            end={pageNumber * pageContent}
            total={count}
          />
              </div>
          </div>

          {
            isLoading ? <Loader /> : (
                <>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <Table.HeaderCell width={1} className="centerAlignManagemnetTable">
                                    {status == 1 ? 'Company Id' : 'Unverified Company ID'}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              Domains
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              Display Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              Company Type
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              Industry
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2} className="centerAlignManagemnetTable">
              InfoedgeId
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1} className="centerAlignManagemnetTable">
              Is Moderated
                                </Table.HeaderCell>
                                <Table.HeaderCell width={12} className="centerAlignManagemnetTable">
              Actions
                                </Table.HeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                isPageLoading ? (
                                    <Table.Row>
                                        <Table.Cell colSpan={8}>
                                            <Loader />
                                        </Table.Cell>
                                    </Table.Row>
                                ) : CompanyListData.map((Company, index) => {
                                    return (Company.status == status)? (
                                        <CompanySingleList
                                        data={Company}
                                        executeAccess={executeAccess}
                                        onClick={() => navEditPage(Company.id, status)}
                                        industryData={industryData}
                                        changeStatus={() => changeStatus(index)}
                                        rejectCompany = {() => rejectCompany(index)}
                                        isRejectedStatus={status}
                                      />
                                    ) : null;
                                  })
                            }
                        </TableBody>
                    </Table>
                    <div className="centerAlign">
                        <div className="paginationManagement">
                            <Pagination
                              totalItemsCount={count}
                              content={pageContent}
                              pageNumber={pageNumber}
                              handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </>
            )
          }
      </div>
  );
};

export default withRouter(CompanyList);
