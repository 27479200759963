import getUserProfile from '../services/getUserProfile';

class User {
	constructor() {
		this.id = null;
		this.email = null;
		this.image = null;
		this.type=null;
		this.rurl=null;
		this.adminType=null;
		this.adminPermissions=null;
		this.invokedBefore=0;
		this.promiseFun=null;
		this.adminType=null;
		this.status = null;
		this.featureAccess = null;
		this.adminFeatureMap = null;
	}

	getProfile = async () => {
		if(this.id)
			return this
		if(this.invokedBefore==1)
		{
			var data=await this.promiseFun;
			return data
		}
		this.invokedBefore=1;
		// eslint-disable-next-line no-undef
		this.promiseFun = getUserProfile()
		.then(res => {
			this.id = res.data.id;
			this.email = res.data.email;
			this.image = res.data.img_link;
			this.type=res.data.type;
			this.rurl=res.data.rurl;
			this.adminType = res.data.adminType;
			this.adminPermissions = res.data.accessMap;
			
			this.invokedBefore=0;
			this.adminType = res.data.adminType;
			this.status = res.data.status;
			this.adminFeatureMap = res.data.adminFeatureMap;
			this.featureAccess = res.data.featureAccess ? res.data.featureAccess.split(",") : null;
			
			// console.log("In Then");
			// if (window.notificationHandler) {
			// 	console.log("Calling Handler");
			// 	notificationHandler.send({
			// 		type: window.notificationHandler.types.setUserDetails,
			// 		payload: {
			// 			id: this.id, 
			// 			email: this.email
			// 		}
			// 	});
			// }

			return this;
		})
		.catch(err=> console.log(err))

		return this.promiseFun;
	}

	checkFeatureAccess = (pageCode) => {
		if (!this.featureAccess) {
			return false;
		}

		const pageAccessData = this.adminFeatureMap.filter(item => item.pageCode === pageCode);

		if (pageAccessData.length === 0) {
			return false;
		}
		
		if (this.featureAccess.includes(pageAccessData[0].id.toString())) {
			return true;
		}
	
		return false;
	}
};

const user = new User();

export default user;