import axios from '../utils/axios';

export default function anyRecruiterLogin(adminId, data) {
    return (
        axios.post('/admin/' + adminId + '/recruiterLogin', data)
            .then(res => {
                return res
            })
            .catch(err => {
                return err;
            })
    )
}
