import axios from '../utils/axios';

export default function getRecruiterProfileNotiification(adminId, params) {
    return (
        axios.get('/admin/' + adminId + '/recruiter-notification', { params })
            .then(res => {
                return res.data;
            }).catch(err => {
                return err;
            })
    )
}