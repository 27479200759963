import React, { useEffect, useState } from 'react'
import { Checkbox, Table } from 'semantic-ui-react';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';

import user from '../../store/user';

import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import ButtonContainer from '../../components/ButtonContainer';
import LinkButtons from '../../components/LinkButtons';
import Dropdown from '../../components/Dropdown/Dropdown';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import FromToDatePicker from '../../components/FromToDatepicker';

import fetchPotentialPaidJobs from '../../services/fetchPotentialPaidJobs';
import fetchPotentialPaidJobsCount from '../../services/fetchPotentialPaidJobsCount';
import fetchPotentialPaidJobAssginees from '../../services/fetchPotentialPaidJobAssigness';
import updatePotentialPaidJob from '../../services/updatePotentialPaidJob';
import updatMultiplePotentialJobs from '../../services/updateMultiplePotentialJobs';

import { adminFeatureMapping } from '../../models/adminFeatureMapping';
import './PotentialPaidJobs.scss';

const sortCategories = [
    { name: 'All', id: 0, arrow: false, asc: true },
    { name: 'Job Date', id: 1, arrow: false, asc: true },
    { name: 'Recruiter Credits', id: 2, arrow: false, asc: true },
    { name: 'Global Credits', id: 3, arrow: false, asc: true },
    { name: 'Company Credits', id: 4, arrow: false, asc: true },
];

const inititalFilterState = {
    startDate: '',
    endDate: '',
    recruiterId: '',
    companyId: '',
    custId: '',
    region: '',
    commentId: -1,
    assignedTo: '',
    companyName: '',
    recType: '',
    refreshCount: ''
};

const inititalInputDataState = {
    recruiterId: '',
    companyId: '',
    custId: '',
    region: '',
    companyName: '',
};

const commentDropdown = [
    { id: -1, name: 'Select Comment' },
    { id: 1, name: 'Client doesn’t want to make premium' },
    { id: 2, name: 'Client unaware about inventory being sold' },
    { id: 3, name: 'Converted to premium' },
    { id: 4, name: 'Ask to connect later' },
    { id: 5, name: 'Credit Exhausted' },
    { id: 6, name: 'Internal Job' },
    { id: 7, name: 'Bangalore Account' },
    { id: 8, name: 'Kolkata Account' },
    { id: 9, name: 'Hyderabad Account' },
    { id: 10, name: 'Chennai >= 1lac' },
    { id: 11, name: 'Not an important role' },
    { id: 12, name: 'Time left before expiry' },
    { id: 13, name: 'Incorrect entry' },
    { id: 14, name: 'Other' },
];

const refreshCountDropdown = [
    { id: '', name: 'Select Refresh Count' },
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2+' }
]

const recTypeDropdown = [
    { id: '', name: 'Select Recruiter Type' },
    { id: 1, name: 'Direct Recruiter' },
    { id: 2, name: 'Consultant' }
    
]

export const PotentialPaidJobs = () => {
    const [adminId, setAdminId] = useState(null);
    const [pageAccessType, setPageAccessType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [jobTotalCount, setJobTotalCount] = useState(0);
    const [orderData, setOrderData] = useState({ sortBy: 0, order: 'desc' });
    const [pageNumber, setPageNumber] = useState(1);
    const [filters, setFilters] = useState(inititalFilterState);
    const [inputData, setInputData] = useState(inititalInputDataState);
    const [jobAssignees, setJobAssignees] = useState([]);
    const [assignTo, setAssignTo] = useState('');
    const [checkedIds, setCheckedIds] = useState([]);
    const [otherCommentInputs, setOtherCommentInputs] = useState({});
    const [editOtherComment, setEditOtherComment] = useState({});
    const [multipleComments, setMultipleComments] = useState('-1');
    const PAGE_CONTENT = 20;
    // sort filters: Date, companyCredit, recCredit, globalCredit
    // filter: Date, RecruiterId, CompanyId, CustId, Region, Comment

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();
            const pageAccess = user.checkFeatureAccess(adminFeatureMapping.POTENTIAL_PAID_JOBS_LEAD) ? 1 : user.checkFeatureAccess(adminFeatureMapping.POTENTIAL_PAID_JOBS) ? 2 : null;
            if (!pageAccess) {
                // eslint-disable-next-line no-undef
                window.location.href='/notAdmin'
            }
            setPageAccessType(pageAccess);
            setAdminId(profile.id);
            fetchJobsAndCount(profile.id, pageAccess);
            if (pageAccess === 1) {
                fetchJobAssignees(profile.id );
            }
        })()
    }, []);

    const fetchJobAssignees = async (id) => {
        const adId = adminId || id;
        const res = await fetchPotentialPaidJobAssginees(adId);
        setJobAssignees([{ name: 'Select', id: '' }, ...res.data.data ])
    }

    useEffect(() => {
        if (adminId) {
            fetchJobsAndCount();
        }
    }, [filters])

    useEffect(() => {
        if (adminId) {
            fetchData();
        }
    }, [orderData, pageNumber])

    const fetchData = async (id, access) => {
        try {
            const adId = adminId || id;
            const accessType = pageAccessType || access;
            const params = getFilters(1, adId, accessType);
            setIsLoading(true);
            const res = await fetchPotentialPaidJobs(adId, params);
            setData(res.data.data);
            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong');
            setIsLoading(false);
        }
    };

    const fetchJobsCount = async (id, access) => {
        try {
            const adId = adminId || id;
            const accessType = pageAccessType || access;
            const params = getFilters(1, adId, accessType);
            const res = await fetchPotentialPaidJobsCount(adId, params);
            setJobTotalCount(res.data.data.count);
        } catch (error) {
            toast.error(error.response.message || 'Something went wrong');
        }
    };

    const fetchJobsAndCount = async (id, pageAccess) => {
        setPageNumber(1);
        fetchData(id, pageAccess);
        fetchJobsCount(id, pageAccess);
    };

    const getFilters = (type, id, accessType) => {
        const currentFilters = { status: 1, pageNumber: pageNumber, pageContent: PAGE_CONTENT, };
        if (accessType === 1) {
            currentFilters['isMainAdmin'] = 1;
        } else {
            currentFilters['assignedTo'] = id
        }
        if (type === 1 && orderData.sortBy) {
            currentFilters['sortBy'] = orderData.sortBy;
            currentFilters['order'] = orderData.order;
        }
        for (const key in filters) {
            if (key != 'commentId' && filters[key]) {
                currentFilters[key] = filters[key];
            }
            if (key == 'commentId' && filters[key] >= 0) {
                currentFilters[key] = filters[key]
            }
        }
        return currentFilters;
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handleFilterChange = (ev) => {
        const { name, value } = ev.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        setInputData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleCheckboxChange = (ev, evData) => {
        if (evData.name === 'selectAll') {
            if(checkedIds.length) {
                setCheckedIds([]);
            } else {
                const jobIdArray = data.map(item => item.job_id);
                setCheckedIds(jobIdArray);
            }
            return;
        }
        if (checkedIds.includes(evData.id)) {
            const newIds = checkedIds.filter(id => id !== evData.id);
            setCheckedIds(newIds);
        } else  {
            setCheckedIds([...checkedIds, evData.id]);
        }
    };

    const handleAssign = async () => {
        try {
            await updatMultiplePotentialJobs(adminId, { assignedTo: assignTo }, { jobIds: checkedIds.join(',') });
            toast.info('Job Assigned Successfully');
            fetchData()
            setAssignTo('');
            setCheckedIds([]);
        } catch (error) {
            // eslint-disable-next-line no-undef
            console.log(error);
            toast.error('Something went wrong')
        }
    };

    const handleOtherCommentChange = (ev, jobId) => {
        const { value } = ev.target;
        setOtherCommentInputs({ ...otherCommentInputs, [jobId]: value });
    };

    const handleMultiComments = async () => {
        try {
            await updatMultiplePotentialJobs(adminId, { comment: multipleComments }, { jobIds: checkedIds.join(',') });
            toast.info('Commented Successfully');
            fetchData()
            setAssignTo('');
            setCheckedIds([]);
        } catch (error) {
            // eslint-disable-next-line no-undef
            console.log(error);
            toast.error('Something went wrong')
        }
    };

    const commentOnJob = async (ev, jobId, index) => {
        const { value } = ev.target;
        // console.log(value, jobId);
        try {
            await updatePotentialPaidJob(adminId, jobId, { comment: value });
            setData((prevData) => {
                const newData = [...prevData];
                newData[index] = { ...prevData[index], comment: value };
                return newData
            });
            toast.info('Comment Updated Successfully');
        } catch (error) {
            toast.error('Somethings Went Wrong');
        }
    };

    const addComment = async (jobId) => {
        if (!(jobId && otherCommentInputs[jobId])) {
            return;
        }
        try {   
            const otherComment = otherCommentInputs[jobId];
            await updatePotentialPaidJob(adminId, jobId, { otherComment });
            setOtherCommentInputs({ ...otherCommentInputs, [jobId]: null });
            setEditOtherComment({ ...editOtherComment, [jobId]: false });
            toast.info('Comment Added Successfully');
            fetchData();
        } catch (error) {
            toast.error(error.res.message || 'Something Went Wrong');
        }
    };

    const editComment = (jobId, comment) => {
        setEditOtherComment({ ...editOtherComment, [jobId]: true });
        setOtherCommentInputs({ ...otherCommentInputs, [jobId]: comment });
    };

    const onClickSortButton = (sortId, ind) => {
        setOrderData({
            sortBy: sortId,
            order: 'desc'
        });
    };

    const search = ev => {
        const { value } = ev.target;
        setFilters({ ...filters, [value]: inputData[value] })
    };

    const clear = (ev) => {
        const { value } = ev.target;
        setInputData({ ...inputData, [value]: inititalInputDataState[value] });
        setFilters({ ...filters, [value]: inititalFilterState[value] })
    };

    const resetDate = () => {
        setFilters({ ...filters, startDate: '', endDate: '' });
    };

    const handleOnKeyPress = (event) => {
        if (event && event.key == 'Enter') {
            search({ target: { value: event.target.name } });
        }
    };

    const getAssigneeName = (id) => {
        for (const assignee of jobAssignees) {
            if (assignee.id === id) {
                return assignee.name;
            }
        }
        return '';
    };

    return (
        <div className='potential-paid-jobs-container'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
            <h2 className='potential-paid-jobs-heading'>
                {'Potential Paid Jobs'}
            </h2>
            <div className='filters-container mutiple-action-container'>
                <ButtonContainer heading={'Sort By:'}>
                    {
                        sortCategories.map((category, i) => (
                            <li key={category.id}> <LinkButtons name={category.name} arrow={category.arrow} arrowType={category.asc} id={category.id} sortClick={() => onClickSortButton(category.id, i)} /> </li>
                        ))
                    }
                </ButtonContainer>
                { pageAccessType == 1 && checkedIds.length ? 
                    <div className='single-search-filter-set mutiple-action-item'>
                        <label>Select to Assign: </label>
                        <Dropdown data={jobAssignees} defaultValue={assignTo} dropdownChange={(ev) => setAssignTo(ev.target.value)} />
                        <Button skin="dark" text="Assign" disabled={assignTo == ''} clickButton={handleAssign}/>
                    </div>
                : null }
                { checkedIds.length ?  
                    <div className='single-search-filter-set mutiple-action-item'>
                        <label>Select to Comment: </label>
                        <Dropdown data={commentDropdown} defaultValue={multipleComments} dropdownChange={(ev) => setMultipleComments(ev.target.value)} />
                        <Button skin="dark" text="Comment" disabled={multipleComments == -1} clickButton={handleMultiComments}/>
                    </div>
                : null }
            </div>
            <div className='filters-container'>
                <ButtonContainer heading={'Filter By:'}>
                    <li key='comment'><label>Comment</label> <Dropdown name="commentId" data={[...commentDropdown, { id: 0, name: 'No Comments Added' }]} dropdownChange={handleFilterChange} defaultValue={filters.commentId} /> </li>
                    <li key='refreshCount'><label>Refresh Count</label> <Dropdown name="refreshCount" data={[...refreshCountDropdown]} dropdownChange={handleFilterChange} defaultValue={filters.refreshCount} /> </li>
                    <li key='recType'><label>Recruiter Type</label> <Dropdown name="recType" data={[...recTypeDropdown]} dropdownChange={handleFilterChange} defaultValue={filters.recType} /> </li>
                    <li key="jobDate">
                        <label>Job Start & End Date</label>
                        <FromToDatePicker
                            name="date"
                            startDate={filters.startDate}
                            endDate={filters.endDate}
                            handleChangeStart={(e) => setFilters({ ...filters, startDate: e })}
                            handleChangeEnd={(e) => setFilters({ ...filters, endDate: new Date(new Date(e).setHours(23, 59 ,59)) })}
                        />
                    </li>
                    <li key="resetDate">
                        <label>Reset Date</label><br/>
                        <Button skin="dark" text="Reset Date" clickButton={resetDate} />
                    </li>
                    {pageAccessType === 1? <li key='comment'><label>Assigned To</label> <Dropdown name="assignedTo" data={jobAssignees} dropdownChange={handleFilterChange} defaultValue={filters.assignedTo} /> </li> : null }
                </ButtonContainer>
            </div>
            <div className='filters-container'>
                <div className='single-search-filter-set'>
                    <InputBox placeholder="Enter Recruiter Id" type="number" name="recruiterId" value={inputData.recruiterId} onChange={handleInputChange} onKeyPress={handleOnKeyPress} />
                    <Button skin="dark" text="Search" value="recruiterId" clickButton={search} />
                    <Button skin="light" text="Clear" value="recruiterId" clickButton={clear} />
                </div>
                <div className='single-search-filter-set right-aligned' >
                    <InputBox placeholder="Enter Company Id" type="number" name="companyId" value={inputData.companyId} onChange={handleInputChange} onKeyPress={handleOnKeyPress} />
                    <Button skin="dark" text="Search" value="companyId" clickButton={search} />
                    <Button skin="light" text="Clear" value="companyId" clickButton={clear} />
                </div>
            </div>

            <div className='filters-container'>
                <div className='single-search-filter-set'>
                    <InputBox placeholder="Enter Cust Id" type="text" name="custId" value={inputData.custId} onChange={handleInputChange} onKeyPress={handleOnKeyPress} />
                    <Button skin="dark" text="Search" value="custId" clickButton={search} />
                    <Button skin="light" text="Clear" value="custId" clickButton={clear} />
                </div>
                <div className='single-search-filter-set right-aligned' >
                    <InputBox placeholder="Enter Region" type="text" name="region" value={inputData.region} onChange={handleInputChange} onKeyPress={handleOnKeyPress} />
                    <Button skin="dark" text="Search" value="region" clickButton={search} />
                    <Button skin="light" text="Clear" value="region" clickButton={clear} />
                </div>
            </div>
            <div className='filters-container'>
                <div className='single-search-filter-set right-aligned'>
                    <InputBox placeholder="Enter Company Name" type="text" name="companyName" value={inputData.companyName} onChange={handleInputChange} onKeyPress={handleOnKeyPress} />
                    <Button skin="dark" text="Search" value="companyName" clickButton={search} />
                    <Button skin="light" text="Clear" value="companyName" clickButton={clear} />
                </div>
            </div>

            <div className='filters-container'></div>

            <div className='text-center'>
                <div className='left'>
                    <Checkbox name="selectAll" onChange={handleCheckboxChange} checked={checkedIds.length===(jobTotalCount < (pageNumber * PAGE_CONTENT) ? jobTotalCount : (pageNumber * PAGE_CONTENT)) - ((pageNumber - 1) * PAGE_CONTENT)} label="Select All"/>
                </div>
                <Pagination
                    totalItemsCount={jobTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
                <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={jobTotalCount < (pageNumber * PAGE_CONTENT) ? jobTotalCount : (pageNumber * PAGE_CONTENT)} total={jobTotalCount} />
            </div>
            <div className='potential-paid-jobs-body'>
                {isLoading ? <Loader /> :
                <Table>
                    <Table.Header>
                        <Table.HeaderCell width={2}>Job Id</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Job Title</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                        <Table.HeaderCell >Recruiter Id</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Company Info</Table.HeaderCell>
                        <Table.HeaderCell >Cust Info</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Credits With Recruiter</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Global Pool Credit</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Credits With Company</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Region</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Comment</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {data.map((aData, i) => {
                            return (
                                <Table.Row key={aData['id']}>
                                    <Table.Cell>
                                        <Checkbox id={aData['job_id']} onChange={handleCheckboxChange} checked={checkedIds.includes(aData['job_id'])} />
                                        <br/>
                                        {aData['job_id']} {`(${aData['refresh_count'] != null ? aData['refresh_count'] : 'N/A'})`}
                                        {pageAccessType === 1 ? 
                                            <>
                                                <br/>
                                                Assigned To: {getAssigneeName(aData['assigned_to']) || 'None'} 
                                            </> 
                                        : null }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {aData['publish_id'] != 0 ? 
                                            // eslint-disable-next-line no-undef
                                            <a href={process.env.REACT_APP_CLIENT_URL+'/recruiter/job_visibility.php?id='+aData['publish_id']} target='_blank'>{aData['job_title']}</a>
                                            : aData['job_title'] }
                                    </Table.Cell>
                                    <Table.Cell>{moment(aData['job_date']).format('YYYY-MM-DD')}</Table.Cell>
                                    <Table.Cell>{aData['recruiter_id']}<br/>{`(${aData['recType'] == 1 ? 'Direct' : 'Consultant'})`}</Table.Cell>
                                    <Table.Cell><>Id: {aData['company_id']}<br/>Domain: {aData['domain']}<br/>Name: {aData['company_name']}</></Table.Cell>
                                    <Table.Cell>{aData['cust_id']}</Table.Cell>
                                    <Table.Cell>
                                        {aData['recruiter_credits']}
                                        <br />
                                        Expires On: {aData['credit_expire_on'] ? moment(aData['credit_expire_on']).format('YYYY-MM-DD'): null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {aData['company_pool_credits']}
                                        <br />
                                        Expires On: {aData['company_credit_expire_on'] ? moment(aData['company_credit_expire_on']).format('YYYY-MM-DD') : null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {aData['peer_credits']}
                                        <br />
                                        Expires On: {aData['peer_credit_expire_on'] ? moment(aData['peer_credit_expire_on']).format('YYYY-MM-DD') : null}
                                    </Table.Cell>
                                    <Table.Cell>{aData['region']}</Table.Cell>
                                    <Table.Cell>
                                        <Dropdown name="commentId" data={commentDropdown} dropdownChange={(ev) => commentOnJob(ev, aData['job_id'], i)} defaultValue={aData['comment']}  />
                                        <br />
                                        {aData['comment'] == 14 ?
                                            aData['other_comment'] && !editOtherComment[aData['job_id']] ? 
                                                <>
                                                    Comment: {aData['other_comment']}
                                                    <a onClick={() => editComment(aData['job_id'], aData['other_comment'])}> Edit Comment</a>
                                                </>
                                            :
                                                <>
                                                    <InputBox placeholder="Enter Your Comment" type="text" name="otherComment" value={otherCommentInputs[aData['job_id']]} onChange={(ev) => handleOtherCommentChange(ev, aData['job_id'])} />
                                                    <Button className="other-comment-btn" skin="dark" text="Comment" disabled={!otherCommentInputs[aData['job_id']]} value="otherComment" clickButton={() => addComment(aData['job_id'])} />
                                                </> 
                                        : null}
                                    </Table.Cell>
                                    {/* <Table.Cell>{aData['comment']}</Table.Cell> */}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>}
            </div>
            <div className='text-center'>
                <Pagination
                    totalItemsCount={jobTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
            </div>
        </div>
    )
}

/*
New Changes
    1. Page will have two types of admin, one who can see job assigned to them and other who can see all job and assign
    2. Filter to see assigned job to particular admin - Done
    3. checkbox to select multiple or all the jobs - Done
    4. A dropdown and button to assign selected job - Done
    5. Company Name Filter - Done
    6. Company name column - Done
    7. Job title on click redirect to JD page - Done
    8. Expire credit column -Done
    9. Other as new comment option (if not exist) - Done
    10. On select other an input field with button will appear to update comment - Done
    11. Other comment column - Done
    12. Assigned to column visible to only pageAdmin -Done
    13. Give required width to all the columns -Done
    14. Handle Enter Key Press - Done
*/