import React, { useEffect, useRef, useState } from "react";

import { toast, ToastContainer } from 'react-toastify';

import Heading from '../../components/Heading';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import user from '../../store/user';
import getRecruiterProfileNotification from '../../services/getRecruiterProfileNotification';
import saveRecruiterNotification from '../../services/saveRecruiterNotification';
import fetchRecruiterId from '../../services/fetchRecruiterId';

import './ProfileNotification.scss';

const ProfileNotification = props => {
    const inputRef = useRef(null);
    const [searchError, setSearchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [checkedData, setCheckedData] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [profile, setProfile] = useState(null);

    const checkForAdminUser = (type) => {
        if (type == 2 || type == 3) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        const fetchProfile = async () => {
            return await user.getProfile();
        }
        fetchProfile().then(userProfile => {
            if (userProfile.type!==1 && !checkForAdminUser(userProfile.adminType)) {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }

            setProfile(userProfile);
            setAdminId(userProfile.id);
        });
    }, []);

    useEffect(() => {
        if (adminId) {
            if (!checkForAdminUser(profile.adminType)) {
                searchRecruiterProfile(adminId, false);
                return;
            }

            let queryString = window.location.search;
            const obj = {}

            queryString = queryString.replace('?', '');
            queryString = queryString.split('&');
            for (let i = 0; i < queryString.length; i++) {
                const temp = queryString[i].split('=');
                obj[temp[0]] = temp[1];
            }

            if (obj['user']) {
                const searchValue = obj['user'];
                onSearchHandler(null, searchValue);
            }
        }
    }, [adminId]);

    const searchRecruiterProfile = async (searchValue, fetchProfile) => {
        let recruiterId = null;

        if(fetchProfile) {
            const profileResponse = await fetchRecruiterId(adminId, { email: searchValue });
            if (profileResponse.data) {
                recruiterId = profileResponse.data.id;
            } else {
                setSearchError(true);
                setIsLoading(false);
                setResponseData(null);
                if (inputRef && inputRef.current) {
                    inputRef.current.value = searchValue;
                }
                return;
            }
        } else {
            recruiterId = searchValue;
        }

        const response = await getRecruiterProfileNotification(adminId, { recruiterId });
        let data = response.data;

        if (!data) {
            data = {
                recruiter_id: recruiterId,
                invalid_email: 0,
                mail: 1,
            };
        } 

        setResponseData(data)

        if (data.invalid_email == 0 && data.mail == 0) {
            setCheckedData(null);
        } else if (data.invalid_email == 1) {
            setCheckedData(2)
        } else {
            setCheckedData(1)
        }
        setSearchError(false);
        setIsLoading(false);

        if (inputRef && inputRef.current) {
            inputRef.current.value = searchValue;
            inputRef.current.blur();
        }
    }

    const checkNumeric = (text) => {
        return !isNaN(text) && !isNaN(parseInt(text));
    }

    const onKeyPressHandler = (event) => {
        if (event.key === 'Enter') {
            onSearchHandler();   
        }
    }

    const onClearHandler = () => {
        inputRef.current.value = '';
        setResponseData(null);
        setSearchError(false);
    }

    const onSearchHandler = (event, text) => {
        let searchValue = '';
        if (text) {
            searchValue = text;
        } else {
            searchValue = inputRef.current.value;
        }

        if (searchValue) {
            if (checkNumeric(searchValue)) {
                searchRecruiterProfile(searchValue, false);
            } else {
                searchRecruiterProfile(searchValue, true);
            }
        }
    }

    const onSaveSettingsHandler = async () => {
        setIsSaving(true);

        const result = await saveRecruiterNotification(adminId, {
            ...responseData,
            mail: checkedData === 1 ? 1 : 0,
            invalid_email: checkedData === 2 ? 1 : 0,
        });

        if (result.status && result.status === 'success') {
            toast.success('Settings has been saved successfully.')
        } else {
            toast.error('Something went wrong!');
        }
        
        setIsSaving(false);
    }

    const onChangeHandler = (event) => {
        if (event.target.name === 'recnotification') {
            setCheckedData(prevValue => {
                return prevValue === 1 ? null : 1;
            });
        } else if (event.target.name === 'invalidemail') {
            setCheckedData(prevValue => {
                return prevValue === 2 ? null : 2;
            });
        }
    }

    let resultsContainer;
    if (searchError) {
        resultsContainer = (
            <div className="error__container">
                No Record Found!
            </div>
        )
    } else if(!responseData) {
        resultsContainer = null;
    } else {
        resultsContainer = (
            <div className="container__results">
                <Heading text="Recruiter Notification Settings" />
    
                <div className="container__results--checkbox">
                    <div>
                        <InputBox type="checkbox" name="recnotification" value="1" checked={checkedData === 1} onChange={onChangeHandler}/>
                        <label>Mail Notification</label>
                    </div>
                    {
                        checkForAdminUser(profile.adminType) ? (
                            <div>
                                <InputBox type="checkbox" name="invalidemail" value="1" checked={checkedData === 2} onChange={onChangeHandler}/>
                                <label>Invalid Email</label>
                            </div>
                        ) : null
                    }
                </div>
    
                {
                    <Button text="Save Settings" skin="light" clickButton={onSaveSettingsHandler} loading={isSaving} disabled={isSaving} />
                }
            </div>
        );
    }

    return (
        <div className="page-content recprofile-notification">
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="page-heading">
                <Heading text="Recruiter Profile Notification" />
                <div>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/recprofile_notification.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
                </div>
            </div>

            <div className="container">
                {adminId ? (
                    <>
                        {
                            checkForAdminUser(profile.adminType) ? (
                                <div className="container__search">
                                    <InputBox type="text" onKeyPress={onKeyPressHandler} placeholder="Search with id/email" _ref={inputRef} />
                                    <Button skin='dark' text="Search" clickButton={onSearchHandler}/>
                                    <Button skin='light' text="Clear" clickButton={onClearHandler} />
                                </div>
                            ) : null
                        }

                        {
                            isLoading ? <Loader /> : (
                                resultsContainer
                            )
                        }
                    </>
                ) : <Loader />}
            </div>

        </div>
    )
}

export default ProfileNotification;