import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AppButton from '../Button'; 
import 'semantic-ui-css/semantic.min.css';
import './Modal.scss';

class ModalWrapper extends Component {
    state = { modalOpen: true }

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.setState({ modalOpen: false })
    }

    handleChange = (event) => {
        event.preventDefault()
        this.handleOpen()
    }

    render() {
        return (
            <Modal open={this.props.modalOpen} trigger={<Button onClick={this.handleChange}>{this.props.buttonText}</Button>} onClose={ () => this.props.onCloseModal()} closeIcon>
                <Header content={this.props.content} />
                <Modal.Content>
                    {this.props.children}
                </Modal.Content>
                {/* <Modal.Actions>
                    <AppButton clickButton={this.handleClose} skin="light" text="Close">
                    </AppButton>
                </Modal.Actions> */}
            </Modal>
        )
    }
}

ModalWrapper.propTypes = {
    children: PropTypes.any,
    content: PropTypes.string,
    buttonText: PropTypes.any,
    modalOpen:PropTypes.bool
}

export default ModalWrapper;