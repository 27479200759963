import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import './TableItem.scss';

const TableItem = props => {
    const data = props.data;
    const manageTeamLink = `/recruiter/mgr_team?recid=${data.id}`;
    
    const onClickRemove = (event) => {
        event.preventDefault();
        const userResponse = window.confirm('Are you sure to remove this user from manager ?');
        if (userResponse) {
            props.onRemoveManager(data.id);
        }
    }

    const actionCell = (
        <div className='table-row__action'>
            <a href={manageTeamLink} target="_blank">Manage Team</a>
            <a href='' title='Click to remove from manager' onClick={onClickRemove}>Remove Manager</a>
        </div>
    )

    return (
        <Table.Row className='table-row' verticalAlign='top'>
            <Table.Cell width='one'>{data.id}</Table.Cell>
            <Table.Cell width='two'>{data.name}</Table.Cell>
            <Table.Cell width='one'>{data.email}</Table.Cell>
            <Table.Cell width='one'>{data.phone}</Table.Cell>
            <Table.Cell width='two'>{data.organisation}</Table.Cell>
            <Table.Cell width='two'>{actionCell}</Table.Cell>
        </Table.Row>
    )
}

TableItem.propTypes = {
    data: PropTypes.object,
    onRemoveManager: PropTypes.func
}

export default TableItem;