const hiristDesignation = [
    "Profile Designation",
    "Software Engineer",
    "Software Developer",
    "Senior software engineer",
    "System Engineer",
    "Project Manager",
    "Business Analyst",
    "Associate Consultant",
    "Consultant",
    "Data Analyst",
    "Android Developer",
    "Associate software engineer",
    "Data Scientist",
    "Senior Consultant",
    "Technical Lead",
    "Test Engineer",
    "Associate",
    "Web Developer",
    "Devops Engineer",
    "Software Test Engineer",
    "Manager",
    "Analyst",
    "SYSTEMS ENGINEER",
    "Product Manager",
    "Team Lead",
    "QA Engineer",
    "Senior Analyst",
    "Assistant Manager",
    "Programmer analyst",
    "Full stack Developer",
    "Senior Software Developer",
    "Graphic Designer",
    "Intern",
    "Project Engineer",
    "ui Developer",
    "Senior Manager",
    "IT Analyst",
    "iOS Developer",
    "Tech lead",
    "Quality Analyst",
    "Senior Engineer",
    "Senior Test Engineer",
    "Fresher",
    "Assistant system engineer",
    "python developer",
    "Senior Associate",
    "Java Developer",
    "Software Tester",
    "Technology Analyst",
    "engineer",
    "Senior Business Analyst",
    "Senior System Engineer",
    "Developer",
    "Application Developer",
    "System Administrator",
    "Network Engineer",
    "Front End Developer",
    "Sr. Software Engineer",
    "PHP Developer",
    "Lead Engineer",
    "Program Manager",
    "Test Analyst",
    "Senior Systems engineer",
    "technical architect",
    "Test Lead",
    "Application Development Analyst",
    "web designer",
    "Senior Project Manager",
    "Project Lead",
    "Frontend Developer",
    "Software Development Engineer",
    "Lead Consultant",
    "Deputy Manager",
    "Student",
    "Technical Consultant",
    "UI/UX Designer",
    "Team Leader",
    "Sr software engineer",
    "Machine Learning Engineer",
    "Associate Software Developer",
    "Director",
    "Vice President",
    "Module lead",
    "QA Lead",
    "Associate Manager",
    "Solution Architect",
    "Delivery Manager",
    "Senior Android Developer",
    "data engineer",
    "UI Designer",
    "IT MANAGER",
    "Associate Director",
    "Technology lead",
    "System Analyst",
    "scrum master",
    "RPA Developer",
    "QA",
    "Technical Specialist",
    "Technical Manager",
    "Associate Product Manager",
    "Technical Support Engineer",
    "Programmer",
    "product engineer",
    "Sr. Software developer",
    "Engineering Manager",
    "AVP",
    "ASSISTANT PROFESSOR",
    "Senior QA Engineer",
    "Senior Developer",
    "Salesforce Developer",
    "Associate Analyst",
    "Architect",
    "QA MANAGER",
    "EXECUTIVE",
    "Senior Software Test Engineer",
    "Quality Assurance Engineer",
    "Backend Developer",
    "Trainee",
    "Senior Graphic Designer",
    "Project Coordinator",
    "Principal Consultant",
    "Assistant Vice President",
    "Senior Quality Analyst",
    "Cloud Engineer",
    "Assistant Consultant",
    "Technical Project Manager",
    "QA Analyst",
    "Manager IT",
    "Business Technology Analyst",
    "Associate Engineer",
    "Sr. Graphic Designer",
    "Product Owner",
    "SSE",
    "Software Testing Engineer",
    "Principal Software Engineer",
    "NA",
    "Specialist",
    "Technical Writer",
    "Technical Associate",
    "Senior Test Analyst",
    "Senior product manager",
    "Research Associate",
    "Member of Technical Staff",
    "Freelancer",
    "android application developer",
    "Technical Analyst",
    "junior software engineer",
    "Data Science Intern",
    "ui ux designer",
    "Big Data Engineer",
    "Video Editor",
    "software engineer ",
    "Software Consultant",
    "Senior Technical Lead",
    "Member Technical Staff",
    "Management Trainee",
    "CTO",
    "Application Development Associate",
    "Testing Engineer",
    "Sr Software Developer",
    "Software Engineer 2",
    "Lead Analyst",
    "Sr manager",
    "Mean Stack Developer",
    "GRADUATE ENGINEER TRAINEE",
    "UX Designer",
    "Test Manager",
    "Senior Project Engineer",
    "senior associate consultant",
    "Lead Software Engineer",
    "Desktop support engineer",
    "Senior Executive",
    "Junior Software developer",
    "Front-end developer",
    "BIG DATA DEVELOPER",
    "Art Director",
    "Application development senior analyst",
    "Senior Technical Writer",
    "Senior Engineering Manager",
    "Senior Data scientist",
    "Senior Data Analyst",
    "Program Analyst",
    "Lead Business Analyst",
    "LEAD",
    "Design Engineer",
    "Associate System Engineer",
    "Associate Project Manager",
    "application Engineer",
    "Sr. Manager",
    "SDET",
    "Project leader",
    "Automation Test Engineer",
    "Automation Engineer",
    "Visual Designer",
    "Technical Support",
    "Software Developer ",
    "Service Delivery manager",
    "Quality engineer",
    "Product Designer",
    "NOC Engineer",
    "Junior Data Scientist",
    "It Executive",
    "IT Consultant",
    "Internship",
    "Embedded Software Engineer",
    "Associate business analyst",
    "Application Support Engineer",
    "Test Automation Engineer",
    "Sr.Software Engineer",
    "Sr. QA Engineer",
    "Sr. Project Manager",
    "Sr. Consultant",
    "Senior Quality Engineer",
    "manual tester",
    "Linux Administrator",
    "general manager",
    "Delivery Head",
    "Business Development Executive",
    "support engineer",
    "Sr. Business Analyst",
    "Software developer intern",
    "Senior software Tester",
    "Senior Program Manager",
    "Senior Process Associate",
    "Senior DevOps Engineer",
    "SECURITY ANALYST",
    "QA Tester",
    "Principal Engineer",
    "IT Head",
    "database developer",
    "Associate Test Engineer",
    "Sr. Android Developer",
    "Software trainee",
    "Software testing",
    "Software Specialist",
    "Senior System Analyst",
    "Programmer analyst trainee",
    "None",
    "Creative Director",
    "System Admin",
    "Software Analyst",
    "Senior Application Developer",
    "SAP ABAP Consultant",
    "Process Executive",
    "MOBILE APPLICATION DEVELOPER",
    "IT Engineer",
    "Data Scientist Intern",
    "Data Analyst Intern",
    "BI Developer",
    "Associate IT Consultant",
    "Associate Developer",
    "Test Specialist",
    "Technical Leader",
    "Staff Engineer",
    "Sr. Test Engineer",
    "Sr QA Engineer",
    "Senior Web Developer",
    "Senior technical consultant",
    "Product Analyst",
    "Lead Developer",
    "Functional Consultant",
    "Founder",
    "Embedded Engineer",
    "3D Artist",
    "Tableau Developer",
    "Sr. IOS Developer",
    "Senior System Administrator",
    "Senior Specialist",
    "Project Associate",
    "IT Support Engineer",
    "Information Security Analyst",
    "Business Consultant",
    "Assistant Software Engineer",
    "Trainee Engineer",
    "Technology Consultant",
    "Technical Test Lead",
    "Sr Test Engineer",
    "sr consultant",
    "software quality analyst",
    "Software QA Engineer",
    "Software Programmer",
    "senior software engineer ",
    "Senior QA",
    "RESEARCH ANALYST",
    "R&D Engineer",
    "process associate",
    "Network Administrator",
    "Lead QA",
    "ETL developer",
    "DevOps",
    "Designer",
    "Deputy General Manager",
    "Decision Scientist",
    "Creative Head",
    "Chief Technology Officer",
    "Associate Data Scientist",
    "Associate architect",
    "ASE",
    "Test Engineering Analyst",
    "Staff Software Engineer",
    "Sr. PHP Developer",
    "Software Engineer Trainee",
    "Senior UI Developer",
    "Senior Software analyst",
    "Senior Architect",
    "SE",
    "Project Trainee",
    "Product Developer",
    "linux system administrator",
    "Junior Engineer",
    "Hadoop developer",
    "Development Manager",
    "Database Administrator",
    "Blockchain Developer",
    "Assistant Project Manager",
    ".NET DEVELOPER",
    "VP",
    "Technology Specialist",
    "Summer Intern",
    "Subject matter expert",
    "Sr Project Manager",
    "Sr Graphic designer",
    "Sr Associate",
    "Software Enginner",
    "Software Engineer II",
    "Software Engineer 1",
    "Software Development Manager",
    "software development analyst",
    "Senior Technical Architect",
    "Senior Tech Lead",
    "Senior iOS Developer",
    "Research Intern",
    "Research Engineer",
    "Research Assistant",
    "Quality Assurance",
    "Operations Manager",
    "N/A",
    "Machine Learning intern",
    "Junior Developer",
    "IT Project Manager",
    "Hadoop Administrator",
    "Development Engineer",
    "Co-Founder",
    "AUTOMATION TESTER",
    "user experience designer",
    "ui/ux developer",
    "Trainee Software Engineer",
    "Technical support executive",
    "Sr. System Administrator",
    "Sr. Associate",
    "Software Architect",
    "Senior Software Testing Engineer",
    "Senior Quality Assurance Engineer",
    "Senior Network Engineer",
    "Senior Director",
    "Research Scholar",
    "React Native Developer",
    "QA Test Engineer",
    "member technical",
    "Lecturer",
    "Lead Data Scientist",
    "Jr. Software Engineer",
    "Graphics Designer",
    "Full Stack Web Developer",
    "Full Stack Engineer",
    "Director of Engineering",
    "Content Writer",
    "Chief Manager",
    "Analyst Programmer",
    "3d animator",
    "Web Developer intern",
    "Test Consultant",
    "Team Manager",
    "Sr. Quality Analyst",
    "Sr Analyst",
    "SOFTWARE QUALITY ASSURANCE ENGINEER",
    "software devloper",
    "software development",
    "Service Engineer",
    "Senior Research Fellow",
    "Senior Designer",
    "SAP FICO consultant",
    "SAP Consultant",
    "Ruby on rails developer",
    "ORACLE DBA",
    "Manual Test Engineer",
    "junior programmer",
    "Junior Associate",
    "Information Technology Analyst",
    "Head IT",
    "Hardware design engineer",
    "Game Developer",
    "Fullstack Developer",
    "embedded developer",
    "Delivery lead",
    "Creative Designer",
    "Business development manager",
    "Associate Vice President",
    "Associate Technology",
    "Associate Data Analyst",
    "Assistant General manager",
    "ASSISTANT ENGINEER",
    "Sr. Engineer",
    "Sr Android Developer",
    "Sql dba",
    "Specialist Programmer",
    "Solutions Architect",
    "Software Intern",
    "Software Developer Trainee",
    "Software Developer Engineer",
    "Software Associate",
    "Senior QA Analyst",
    "Senior Member of Technical Staff",
    "Senior Data Engineer",
    "Product specialist",
    "Physical Design Engineer",
    "No",
    "Nil",
    "Motion Graphic Designer",
    "Junior Java developer",
    "Jr. Software Developer",
    "Information security manager",
    "Gis engineer",
    "freelance",
    "Design Lead",
    "DBA",
    "Data Science Engineer",
    "Business Intelligence Analyst",
    "Associate Technical Consultant",
    "Assistant Product Manager",
    "UX/UI Designer",
    "Trainee decision scientist",
    "Test Engineer Analyst",
    "Technology Manager",
    "Technology Architect",
    "Technical Director",
    "Technical Delivery Manager",
    "Sr.Software Test Engineer",
    "Sr.Android Developer",
    "Sr. Manager IT",
    "Sr Php developer",
    "Sr Business Analyst",
    "Software Test Analyst",
    "Software Quality Engineer",
    "SOFTWARE ENGINEERING",
    "Software Engineer Analyst",
    "Software Development Engineer 1",
    "Software Design engineer",
    "Senior Web Designer",
    "Senior UI/UX Designer",
    "Senior UI Designer",
    "Senior Testing Engineer",
    "Senior Technical Leader",
    "Senior Programmer",
    "Senior Product Engineer",
    "Senior Process Executive",
    "Senior lead engineer",
    "Senior Java Developer",
    "sap mm consultant",
    "SAP BASIS consultant",
    "QA Consultant",
    "QA Automation Engineer",
    "Project Intern",
    "Practice Manager",
    "PMO",
    "Others",
    "Network security engineer",
    "MTS",
    "Manager - IT",
    "Lead Technical Writer",
    "Junior Research Fellow",
    "Jr Software Developer",
    "Game Tester",
    "FINANCIAL ANALYST",
    "Enterprise Architect",
    "Embedded Software Developer",
    "Director Engineering",
    "Data Specialist",
    "Business Development",
    "Build and release engineer",
    "Associate QA Engineer",
    "Associate Professional Product Developer",
    "Assistant Systems Engineer",
    "Angular Developer",
    "UX Lead",
    "Unity Developer",
    "TL",
    "Tester",
    "Test Engineering Senior Analyst",
    "Test Automation Lead",
    "Technical Program Manager",
    "Technical Intern",
    "System Engineer ",
    "Sr.Graphic Designer",
    "Sr. Web Developer",
    "Sr. Web designer",
    "Sr. UI/UX designer",
    "Sr. Technical Lead",
    "Sr. Tech Lead",
    "Sr UX designer",
    "Sr Technical Lead",
    "Sr iOS Developer",
    "Software Engineering Analyst",
    "software Engineer intern",
    "SOC Analyst",
    "SEO Analyst",
    "Senior UI UX Designer",
    "Senior Technical Associate",
    "Senior Solution Integrator",
    "Senior solution Architect",
    "senior software engg",
    "Senior PHP Developer",
    "Senior Member Technical",
    "Senior Design Engineer",
    "Senior Database Administrator",
    "Senior creative designer",
    "Senior Analyst Programmer",
    "Self Employed",
    "Risk Analyst",
    "Research Scientist",
    "Quality Service Associate",
    "QA developer",
    "Principal Product Manager",
    "Principal Architect",
    "Performance Test Engineer",
    "PCB Design engineer",
    "Operation Manager",
    "Network Support Engineer",
    "Network Analyst",
    "Manager Projects",
    "Manager Operations",
    "Junior analyst",
    "IT Officer",
    "iOS Application Developer",
    "implementation engineer",
    "HTML Developer",
    "Head of Engineering",
    "hadoop admin",
    "Gis analyst",
    "Full-Stack Developer",
    "Dot Net Developer",
    "Director Technology",
    "Design head",
    "Database architect",
    "Data Science Consultant",
    "Data Architect",
    "Cyber Security Engineer",
    "Cyber security analyst",
    "COO",
    "Automation Lead",
    "Asst Manager",
    "Associate Technical Lead",
    "ASSOCIATE TEAM LEAD",
    "Associate Projects",
    "Associate Process Manager",
    "Associate DevOps Engineer",
    "Assistant System Engineer Trainee",
    "ASSISTANT MANAGER IT",
    "Android developer ",
    "Analog layout engineer",
    "Analog Design Engineer",
    "Advisor",
    "Administrator",
    "wordpress developer",
    "Validation Engineer",
    "UX UI Designer",
    "Technical Team Lead",
    "Technical Product Manager",
    "Technical Head",
    "Sr. System Engineer",
    "SR. QA",
    "Sr. Frontend Developer",
    "Sr. Executive",
    "Sr. Analyst",
    "Sr UI/UX Designer",
    "Sr QA",
    "Sr Executive",
    "SQA",
    "Software Test Lead",
    "Software Engineer I",
    "Software Engg",
    "SME",
    "Senior Visual Designer",
    "Senior Vice President",
    "senior UX Designer",
    "Senior Test Lead",
    "Senior Software Quality Analyst",
    "Senior Software QA Engineer",
    "Senior Software Engineer II",
    "Senior Process Manager",
    "Senior Member Technical Staff",
    "Senior Manager IT",
    "Senior FrontEnd Developer",
    "Senior Front End Developer",
    "Senior Automation Engineer",
    "Senior Applications Engineer",
    "Security Consultant",
    "SDE",
    "SALES MANAGER",
    "Researcher",
    "Research Fellow",
    "Project Assistant",
    "Product lead",
    "Product HEad",
    "Product Development Engineer",
    "Product Architect",
    "PHP WEB DEVELOPER",
    "Officer",
    "Motion graphics designer",
    "Linux Admin",
    "Lead Software Developer",
    "Lead QA Engineer",
    "Lead architect",
    "Lead Android developer",
    "Junior Business Analyst",
    "JAVASCRIPT DEVELOPER",
    "ITA",
    "IT support",
    "IT Administrator",
    "Informatica Developer",
    "Independent Consultant",
    "Incident Manager",
    "Head of Technology",
    "Head - IT",
    "Group Project Manager",
    "Full stack developer intern",
    "Engineer Trainee",
    "Django Developer",
    "Digital Marketing Executive",
    "DevOps Manager",
    "Desktop support",
    "Delivery Project Executive",
    "Customer Success Manager",
    "Creative Lead",
    "Consultant Specialist",
    "Computer vision Engineer",
    "Business Development Associate",
    "Automation Test Lead",
    "Associate Technology L2",
    "Associate Tech Lead",
    "Associate QA",
    "Associate Lead",
    "Associate IT",
    "Associate application developer",
    "Application Developer 2",
    "Application Analyst",
    "Android Developer Intern",
    "Android app developer",
    "Analytics consultant",
    "AI developer",
    "workday consultant",
    "Web Application Developer",
    "Vice President IT",
    "UI Lead",
    "Test professional",
    "Test Engineering Team Lead",
    "Technology Head",
    "Technologist",
    "Technical Trainer",
    "Technical Support Associate",
    "Technical Engineer",
    "Sr. UX Designer",
    "Sr. UI Developer",
    "Sr. UI Designer",
    "Sr. Technical Architect",
    "Sr. Software Test Engineer",
    "Sr. Engineering Manager",
    "Sr. Data Analyst",
    "sr web developer",
    "Sr Program manager",
    "Sr Product Manager",
    "Sr Manager IT",
    "Sr Developer",
    "SQL DEVELOPER",
    "SQL database Administrator",
    "Solution Manager",
    "Solution Integrator",
    "Software Engineering Intern",
    "software engineer~~Software Engineer",
    "Software Development Intern",
    "Site Reliability Engineer",
    "Sharepoint Developer",
    "Service Delivery Specialist",
    "Senior Technical manager",
    "Senior Technical Engineer",
    "Senior Software Engineer Testing",
    "SENIOR PROJECT LEAD",
    "Senior Principal Engineer",
    "Senior delivery manager",
    "Senior Application Engineer",
    "SAS PROGRAMMER",
    "SAP SD Consultant",
    "Research and Development Engineer",
    "reporting analyst",
    "Quality Lead",
    "Project Delivery Manager",
    "Project Analyst",
    "Program Analyst Trainee",
    "Product Executive",
    "Process Specialist",
    "Process Consultant",
    "Principle Engineer",
    "Presales Consultant",
    "Operations Executive",
    "Network Admin",
    "Motion graphics artist",
    "Linux System Admin",
    "Linux Engineer",
    "Lead UI/UX Designer",
    "Lead Graphic Designer",
    "junior web developer",
    "IT Service Manager",
    "IT operations manager",
    "IT Director",
    "Information security consultant",
    "I.T. Analyst",
    "Head Of Operations",
    "GET",
    "Functional Test Engineer",
    "Frontend Engineer",
    "Front End Web Developer",
    "Engineering Lead",
    "Engineer 1",
    "director IT",
    "Developer Associate",
    "Design Consultant",
    "Delivery Module Lead",
    "Deep Learning Engineer",
    "Decision Analytics Associate",
    "data Scientist ",
    "Data researcher",
    "Customer Service Executive",
    "Content Analyst",
    "Cloud DevOps Engineer",
    "CIO",
    "Business System Analyst",
    "Business Process Lead",
    "Business Head",
    "Business analyst intern",
    "Big Data Analyst",
    "Backend Engineer",
    "AWS devops engineer",
    "Associate tech specialist",
    "Associate Creative Director",
    "associate consultant ",
    "Associate Business Consultant",
    "Applications Engineer",
    "Animator",
    "Android Engineer",
    "Analytics Manager",
    "AI Engineer",
    "Account Manager",
    "web development intern",
    "web & Graphic Designer",
    "VP Technology",
    "VP Engineering",
    "Visualizer",
    "Unity3d Game Developer",
    "UI Devloper",
    "UI / UX Designer",
    "Transaction Risk Investigator",
    "Transaction processing associate",
    "Trainer",
    "test engineer ",
    "Teaching Assistant",
    "Teacher",
    "Systems Analyst",
    "System developer",
    "Support Analyst",
    "Sr.Manager",
    "Sr.Consultant",
    "Sr. Technical Writer",
    "Sr. Team Lead",
    "Sr. System Analyst",
    "Sr. Software Tester",
    "Sr. Full Stack Developer",
    "Sr. DevOps Engineer",
    "Sr. Developer",
    "SR UI developer",
    "Sr technical consultant",
    "Sr system Engineer",
    "sr solution architect",
    "Sr Software Test Engineer",
    "Sr QA Analyst",
    "Sr engineer",
    "SQL Server DBA",
    "Solutions Consultant",
    "Software Technologist",
    "Software Engineering Specialist",
    "Software Engineering associate",
    "Software Engineer QA",
    "Software Engineer (Android)",
    "Software Development Engineer in Test",
    "Software Development Engineer II",
    "Software Developer Analyst",
    "Software Developer 2",
    "Software Designer",
    "SOFTWARE  ENGINEER",
    "SMTS",
    "Server Administrator",
    "SEO Executive",
    "Seniour Software Engineer",
    "Senior Technical Support Engineer",
    "Senior Technical specialist",
    "Senior Technical Analyst",
    "Senior Team Lead",
    "Senior staff engineer",
    "Senior software Programmer",
    "Senior software enginner",
    "Senior Software consultant",
    "Senior Security Analyst",
    "Senior SDET",
    "Senior research associate",
    "Senior Quality Assurance Analyst",
    "Senior Qa Lead",
    "Senior Programmer Analyst",
    "Senior officer",
    "Senior Manager - IT",
    "Senior IT Consultant",
    "Senior IT Analyst",
    "Senior Functional Consultant",
    "Senior Full stack Developer",
    "Senior Development Engineer",
    "Senior chief Engineer",
    "Senior Backend Developer",
    "Senior associate engineer",
    "Senior Associate Analyst",
    "Senior Art Director",
    "Senior Application Development Analyst",
    "Security engineer",
    "SAP Technical Consultant",
    "Salesforce Consultant",
    "Salesforce Administrator",
    "Sales coordinator",
    "Research & Development Engineer",
    "QUALITY SPECIALIST",
    "Quality Assurance Analyst",
    "QC engineer",
    "QA Testing Engineer",
    "QA Testing",
    "QA Team Lead",
    "Python/Django Developer",
    "Project Manager/Scrum Master",
    "Project Fellow",
    "PROCESS DEVELOPER",
    "Principal QA engineer",
    "Principal member Technical staff",
    "Practice Head",
    "PMTS",
    "PMO Analyst",
    "Performance test analyst",
    "Operation Executive",
    "Node Js Developer",
    "Network Engineer L1",
    "Mobile Developer",
    "MERN Stack Developer",
    "Marketing Manager",
    "Market Research Analyst",
    "Managing Consultant",
    "Manager Technology",
    "Manager Analytics",
    "Major Incident Manager",
    "Lead UI Developer",
    "Lead iOS Developer",
    "Lead Data Engineer",
    "junior python developer",
    "Junior Data Analyst",
    "Jr Software Engineer",
    "Jr Data Scientist",
    "Jira administrator",
    "jaipur",
    "IT Support Executive",
    "IT Infrastructure Manager",
    "IT Developer",
    "IoT Developer",
    "Internship Trainee",
    "Implementation Consultant",
    "Image editor",
    "Hybrid app developer",
    "HR executive",
    "HEAD OPERATIONS",
    "Hardware Engineer",
    "Group Leader",
    "General Manager IT",
    "Game Programmer",
    "Game Designer",
    "Functional tester",
    "Front End Engineer",
    "Freshers",
    "Founder & CEO",
    "Firmware Engineer",
    "Finance Executive",
    "Executive Director",
    "erp consultant",
    "Engineering Intern",
    "Embedded Systems Engineer",
    "Electronics Engineer",
    "Director - Projects",
    "Digital Marketing Analyst",
    "Development Lead",
    "Design Manager",
    "DELHI",
    "Customer Support Executive",
    "Creative producer",
    "Co Founder",
    "Cloud Consultant",
    "Cloud Architect",
    "Chief architect",
    "Chennai",
    "CEO",
    "Business systems consultant",
    "Business Intelligence Consultant",
    "Business Data Analyst",
    "BigData Developer",
    "BI ANALYST",
    "Bda",
    "Back End Developer",
    "AVP IT",
    "AVP - IT",
    "Automation Testing Engineer",
    "Associate Technical Manager",
    "Associate Software Engineer ",
    "Associate senior software engineer",
    "Associate Programmer",
    "Associate Product Engineer",
    "Assistant Application Developer",
    "Area Sales Manager",
    "Application development team lead",
    "Application Architect",
    "Android Developer~~Android Developer",
    "AGM IT",
    "AGM",
    "Advisory Consultant",
    "Advanced Analyst",
    "3D Visualizer",
    "3d modeler",
    "Windows System Administrator",
    "windows system admin",
    "windows Administrator",
    "Website Developer",
    "Web Devloper",
    "Web Designer and Developer",
    "Web and Graphic Designer",
    "volunteer",
    "Vmware administrator",
    "Vice President Technology",
    "Vice President - IT",
    "Vice President ",
    "Verification Engineer",
    "UX Architect",
    "Unemployed",
    "Ui Designer and Developer",
    "Trainee test engineer",
    "Testing executive",
    "Testing associate",
    "Testing",
    "Test Specialist Automation",
    "Test Engineering Associate Manager",
    "Test Engineer Senior Analyst",
    "Test Architect",
    "Technical Account Manager",
    "TechLead",
    "Tech support",
    "Tech Manager",
    "Talend Developer",
    "Systems Engineer trainee",
    "System Consultant",
    "System Architect",
    "System administration",
    "Supervisor",
    "Sr.Software Developer",
    "Sr.QA",
    "Sr. Visual Designer",
    "Sr. UI UX Designer",
    "Sr. Systems Engineer",
    "Sr. Software QA Engineer",
    "Sr. Software analyst",
    "Sr. QA Lead",
    "Sr. Product Manager",
    "Sr. Product Designer",
    "Sr. Network engineer",
    "Sr. Linux Administrator",
    "Sr. Infra developer",
    "Sr. Implementation Consultant",
    "Sr. Designer",
    "Sr. Creative Designer",
    "Sr. Art Director",
    "Sr Test Analyst",
    "SR Software tester",
    "Sr QA Manager",
    "Sr Oracle DBA",
    "Sr Engineering manager",
    "Sr DevOps Engineer",
    "SQA Engineer",
    "Solution specialist",
    "Solution Integration Consultant",
    "Solution engineer",
    "Solution Consultant",
    "Sofware Engineer",
    "Software trainer",
    "Software Testing Intern",
    "Software Test Specialist",
    "Software test Engg",
    "Software Support Engineer",
    "Software Research Engineer",
    "Software QA",
    "software product developer",
    "Software engineers",
    "SOFTWARE ENGINEER~~SENIOR SOFTWARE ENGINEER",
    "Software Engineer L2",
    "Software Engineer in test",
    "Software Engineer Associate",
    "Software Engineer 3",
    "Software Engineer - II",
    "Software Engineer - Data Science",
    "Software Engg.",
    "Software Development Trainee",
    "Software Development Engineer I",
    "Software Development Engineer 2",
    "Software Development Engineer - 1",
    "Software Developer II",
    "Software automation engineer",
    "Software",
    "Service Desk Engineer",
    "Service Desk Analyst",
    "Senior Visualizer",
    "Senior Testing Executive",
    "Senior Tester",
    "Senior Test Manager",
    "Senior Technology Specialist",
    "Senior Technology Manager",
    "Senior technical Support",
    "Senior Systems Analyst",
    "Senior support engineer",
    "senior support analyst",
    "Senior Solutions Architect",
    "Senior Software QA",
    "Senior Software Engineer~~Senior Software Engineer",
    "Senior Software Engineer I",
    "Senior software associate",
    "Senior RPA Developer",
    "Senior Research Engineer",
    "Senior Research Analyst",
    "Senior Project Leader",
    "Senior Project Fellow",
    "Senior Project Coordinator",
    "Senior Product development Engineer",
    "Senior Product Analyst",
    "Senior Principal Software Engineer",
    "senior principal consultant",
    "Senior Motion Graphics artist",
    "Senior mobile application developer",
    "Senior Manager Software Engineering",
    "Senior Manager QA",
    "Senior Manager Operations",
    "SENIOR IT MANAGER",
    "Senior Infra Developer",
    "Senior Embedded Engineer",
    "Senior Development Manager",
    "Senior Business System Analyst",
    "Senior Business Manager",
    "Senior Business Intelligence Analyst",
    "Senior business consultant",
    "Senior BI Consultant",
    "Senior Batch Specialist",
    "Senior Automation Tester",
    "Senior Associate Technology",
    "Senior Advisory Software Engineer",
    "Senior advisory consultant",
    "Security specialist",
    "Security architect",
    "sde2",
    "SDE-1",
    "SDE 2",
    "SAS analyst",
    "Sales Engineer",
    "RPA Consultant",
    "Robotics engineer",
    "Research and Development Intern",
    "Release manager",
    "Relationship manager",
    "Regional Service Delivery Manager",
    "Regional Manager",
    "R&D Manager",
    "R & D engineer",
    "Quality Manager",
    "Quality Associate",
    "QlikView Developer",
    "QA software engineer",
    "QA Intern",
    "QA Executive",
    "QA Engineer~~QA Engineer",
    "QA Engineer II",
    "QA Director",
    "Python Django developer",
    "PROPRIETOR",
    "Project executive",
    "project engineer ",
    "Project consultant",
    "programmer Analyst ",
    "Programme analyst trainee",
    "Programmar Analyst",
    "Program director",
    "Professional 1 Product Developer",
    "Professional 1 Application Delivery",
    "Product test engineer",
    "Product support Engineer",
    "Product Management",
    "Process Lead",
    "Principle Software Engineer",
    "Principal Quality Assurance Engineer",
    "Practice Lead",
    "Power BI Developer",
    "PlSQL Developer",
    "Platform Engineer",
    "Personal Banker",
    "Performance Tester",
    "Performance Engineer",
    "Penetration Tester",
    "PCB designer",
    "patent analyst",
    "Owner",
    "Operations Head",
    "Operations Analyst",
    "NodeJS Developer",
    "NLP Engineer",
    "Network specialist",
    "Network Engineer ",
    "Network Consultant",
    "Network Associate",
    "MSBI Developer",
    "Mobile App Developer",
    "MIS Executive",
    "Member Of Technical Staff 2",
    "Marketing Intern",
    "Market Analyst",
    "Manual Software Tester",
    "Managing Director",
    "Manager-IT",
    "MANAGER SPECIALIZED SERVICES",
    "Manager -IT",
    "Machine Learning Trainee",
    "Machine learning engineer intern",
    "Machine Learning",
    "Linux System Engineer",
    "Linux Support Engineer",
    "Lead UX designer",
    "Lead UI Engineer",
    "Lead Security Engineer",
    "Lead Quality Analyst",
    "Lead Programmer",
    "lead product engineer",
    "Lead Network Engineer",
    "lead devops engineer",
    "Lead Designer",
    "Lead business consultant",
    "Lead associate",
    "Lead Administrator",
    "Layout engineer",
    "Layout design engineer",
    "Junior Unity Developer",
    "Junior Tester",
    "Junior test engineer",
    "Junior Software Tester",
    "Junior PHP developer",
    "Junior executive",
    "Junior Dot Net Developer",
    "Junior Android Developer",
    "Jr. iOS Developer",
    "Jr. Data Scientist",
    "joint director",
    "Java Trainee",
    "JAVA SOFTWARE DEVELOPER",
    "IT SUPERVISOR",
    "IT Specialist",
    "IT Operations Analyst",
    "IT LEAD",
    "IT Admin",
    "it",
    "ios Developer ",
    "iOS app developer",
    "Integration Architect",
    "Instructional Designer",
    "Innovation Engineer",
    "Implementation Manager",
    "Implementation Lead",
    "Illustrator",
    "Head of IT",
    "Head of Delivery",
    "Head IT Operations",
    "Group Product Manager",
    "Group Manager",
    "Graduate Trainee Engineer",
    "Graduate Technical Intern",
    "Global Delivery Head",
    "General Manager - IT",
    "Full Stack Developer ",
    "Frontend Developer ",
    "Front end UI developer",
    "Freelance consultant",
    "Founder & Director",
    "Faculty",
    "Executive Project Manager",
    "Executive IT",
    "Executive Graphic Designer",
    "entrepreneur",
    "Engagement Manager",
    "Employee",
    "Embedded Hardware Engineer",
    "Digital Marketing manager",
    "DGM",
    "Deputy Manager IT",
    "Delivery Software Engineer",
    "Datascientist",
    "Datapower Developer",
    "Data Science Trainee",
    "Data Science Analyst",
    "Data science",
    "Data Entry Operator",
    "Data Analytics",
    "Data analyst~~Data Analyst",
    "Customer service associate",
    "Consulting Technical Manager",
    "consultant ",
    "computer teacher",
    "Computer Scientist",
    "Cognizant Technology Solutions",
    "Cofounder",
    "Cloud Support Engineer",
    "Client Service Analyst",
    "Citrix Administrator",
    "Chief Technical Officer",
    "Chief Manager IT",
    "Chief Information Security Officer",
    "Chief Information officer",
    "Chatbot developer",
    "Cad Engineer",
    "Bussiness analyst",
    "Business Systems Analyst",
    "Business Operations Associate",
    "Business Intelligence Developer",
    "Business Analyst ",
    "Big Data Architect",
    "BI Consultant",
    "Bangalore",
    "Backup Administrator",
    "Backend Software Engineer",
    "Backend Developer Intern",
    "Back-end Developer",
    "AWS and DevOps Engineer",
    "AVP - Technology",
    "Automation QA engineer",
    "Automation Developer",
    "AST",
    "Asst.Manager",
    "Asst. Project Manager",
    "Asst. Professor",
    "Asst. Manager",
    "Asst Manager IT",
    "Associate Ui Developer",
    "Associate Technical Support",
    "Associate Technical Specialist",
    "Associate Systems engineer",
    "Associate System Analyst",
    "Associate Specialist",
    "Associate software test engineer",
    "Associate Software Analyst",
    "Associate SAP Consultant",
    "Associate Research Engineer",
    "Associate QA Lead",
    "Associate QA Analyst",
    "Associate Project",
    "Associate Professional Test Engineer",
    "Associate Professional",
    "Associate Product Developer",
    "Associate Java Developer",
    "Associate ios developer",
    "Associate General Manager",
    "Associate Director - Product Management",
    "Associate Consultant QA",
    "Associate analyst development",
    "Associate ",
    "Assitant manager",
    "Assistant Technical Manager",
    "Assistant team lead",
    "Assistant Programmer",
    "Assistant Analyst",
    "Application Tester",
    "Application test Analyst",
    "Application Support",
    "Application Software Developer",
    "Application Development Manager",
    "Application Development Analyst ",
    "Application Delivery Manager",
    "app developer",
    "Annotation Engineer",
    "Android  developer",
    "Android",
    "Analyst Intern",
    "Agile Coach",
    "Advisory System Analyst",
    "admin",
    "2d game Artist",
    "-",
    "Wipro Technologies",
    "Web UI Developer",
    "Web Engineer",
    "web development",
    "Web Designing",
    "Web Designer & Developer",
    "Web Analyst",
    "Web Administrator",
    "Warehouse Incharge",
    "VoIP Network engineer",
    "visualiser",
    "Vice President - Technology",
    "VBA developer",
    "Validation manager",
    "UX Researcher",
    "UX Manager",
    "UX Design Lead",
    "UX Consultant",
    "UX / UI Designer",
    "UIUX Designer",
    "UI/Web Designer",
    "UI/UX Manager",
    "UI/UX Lead",
    "UI/UX & Graphic Designer",
    "UI/Graphic Designer",
    "UI-UX Designer",
    "UI UX Lead",
    "UI Team Lead",
    "UI Lead Developer",
    "UI Front End Developer",
    "UI Engineer",
    "UI developer ",
    "Ui designer ux designer",
    "UI Architect",
    "UI & UX Designer",
    "UI /UX Designer",
    "Tron associate",
    "Transmission Engineer",
    "Training",
    "Trainee software developer",
    "Trainee Data Scientist",
    "TPM",
    "Test Specialist : Automation",
    "Test Lead and Scrum Master",
    "Test Automation Specialist",
    "TERRITORY SALES MANAGER",
    "Territory Manager",
    "Technology Trainee",
    "Technology integration engineer",
    "Technology Associate",
    "Technical Trainee",
    "Technical System Analyst",
    "Technical support specialist",
    "Technical support representative",
    "Technical Support Lead",
    "Technical Solution Architect",
    "Technical service manager",
    "Technical Service Engineer",
    "Technical Recruiter",
    "Technical Project Lead",
    "Technical Operations Manager",
    "Technical Operations Associate",
    "Technical lead ",
    "Technical Expert",
    "Technical Executive",
    "Technical Content Writer",
    "Technical Artist",
    "Technical Architect ",
    "technical",
    "Techincal Architect",
    "Tech Team Lead",
    "Tech support engineer",
    "Tech Specialist",
    "Tech Project manager",
    "Tech intern",
    "Tech Consultant",
    "Team member",
    "Team Manager HRMS",
    "Team Lead UI/UX",
    "Team Lead Android",
    "Team Lead - Software Development",
    "Team Lead - Design",
    "Teal Lead",
    "TCs",
    "Systems Integrator",
    "System Validation Engineer",
    "System Support Engineer",
    "System software engineer",
    "System Programmer",
    "System Engineer Trainee",
    "SYSTEM DEVELOPMENT ASSOCIATE",
    "System and Network Administrator",
    "System and Network Admin",
    "System Administrator ",
    "Sysadmin",
    "Sys Admin",
    "SW engineer",
    "Support Executive",
    "Support",
    "Strategy Analyst",
    "Storage and Backup administrator",
    "Staff Engineer II",
    "STA",
    "Sr.Web designer",
    "Sr.Visualizer",
    "Sr.ux designer",
    "Sr.Test Lead",
    "Sr.System administrator",
    "sr.system admin",
    "Sr.QA Engineer",
    "Sr.IOS Developer",
    "Sr.Implementation Executive",
    "Sr.Executive",
    "Sr.Business Analyst",
    "Sr. Wordpress Developer",
    "Sr. visualizer",
    "Sr. UX/UI Designer",
    "sr. testing engineer",
    "Sr. Test Analyst",
    "Sr. Technical Manager",
    "Sr. Software Testing Engineer",
    "Sr. Software Engineer Testing",
    "Sr. Software Engg",
    "Sr. Software Architect",
    "Sr. Quality assurance analyst",
    "Sr. Programmer",
    "Sr. Product Owner",
    "Sr. Motion Graphics Designer",
    "Sr. Manager - Engineering",
    "Sr. Lead IT",
    "Sr. IT Engineer",
    "Sr. Html Developer",
    "Sr. Graphic Desinger",
    "sr. game developer",
    "Sr. Front-End Developer",
    "sr. desktop support engineer",
    "Sr. Data Scientist",
    "Sr. BI Consultant",
    "Sr. Android Application Developer",
    "Sr Visual Designer",
    "Sr Vice President",
    "Sr UI Designer",
    "Sr Tester",
    "Sr Technical Writer",
    "Sr Technical Specialist",
    "Sr Tech Lead",
    "Sr tech associate",
    "Sr systems engineer",
    "Sr System Administrator",
    "Sr SQA",
    "Sr Software Engineer - QA",
    "Sr SEO Executive",
    "Sr Quality Manager",
    "Sr quality assurance engineer",
    "Sr Quality Analyst",
    "Sr Product Designer",
    "Sr Process Associate",
    "Sr Network Engineer",
    "Sr JIRA Administrator",
    "Sr IT Executive",
    "Sr Graphics Designer",
    "Sr Director",
    "Sr Development Manager",
    "Sr chief engineer",
    "Sr Applications Developer",
    "Sr Application Developer",
    "Specialist Senior",
    "Specalist",
    "Spatial Data Specialist 1",
    "Spatial data specialist",
    "Sound designer",
    "Solutions Analyst",
    "Solution Delivery Manager",
    "sofware developer",
    "softwareengineer",
    "software testing engg",
    "Software Tester Engineer",
    "Software Test Engineer II",
    "software Test Engineer ",
    "Software Test Automation Engineer",
    "Software test analyst senior",
    "Software Quality Senior Engineer",
    "Software Quality Lead",
    "Software Quality Engineer 2",
    "Software quality assurance",
    "Software QA Developer",
    "Software professional",
    "Software Enginneer",
    "Software Engineering Manager",
    "Software Engineer/Data Analyst",
    "Software Engineer Test",
    "Software Engineer Level II",
    "Software Engineer - Machine Learning",
    "Software Engineer - iOS",
    "Software Development Team Lead",
    "software development senior associate",
    "Software Development Engineer - I",
    "Software Developer~~software Engineer",
    "Software developer~~Software Developer",
    "Software Developer L2",
    "Software Developer L1",
    "Software Developer in Test",
    "Software Developer Engineer Intern",
    "Software Developer and Analyst",
    "Software Developer (Intern)",
    "Software Developer - Android",
    "Software Developement Engineer",
    "Shift Lead",
    "Shift engineer",
    "ServiceNow Developer",
    "Service manager",
    "Service Integration Leader",
    "Service Delivery Leader",
    "SERVICE DELIVERY HEAD",
    "seo",
    "Seniour software developer",
    "Senior Verification Engineer",
    "Senior Unix Administrator",
    "Senior Travel consultant",
    "Senior Test Engineering Analyst",
    "Senior Test Designer",
    "Senior Test Automation Engineer",
    "Senior Technology Associate",
    "Senior technical services specialist",
    "Senior Technical Product Manager",
    "Senior team leader",
    "Senior Tax consultant",
    "Senior system admin",
    "Senior SQL Developer",
    "Senior SQL DBA",
    "Senior Software Test Analyst",
    "Senior Software Support Engineer",
    "Senior Software Quality Assurance Engineer",
    "Senior Software engineering analyst",
    "Senior Software Engineering",
    "Senior Software Engineer QA",
    "Senior Software Engineer in Test",
    "Senior Software Engineer 1",
    "Senior Software Engineer (Team Lead)",
    "Senior Software Development Engineer",
    "Senior Software development analyst",
    "Senior Software Developer ",
    "Senior Software Designer",
    "Senior Software Design Engineer",
    "Senior Software Delevoper",
    "senior software",
    "Senior Sharepoint Developer",
    "Senior Security Engineer",
    "Senior Scrum Master",
    "Senior SAP MM Consultant",
    "Senior Salesforce Developer",
    "Senior QC Engineer",
    "Senior QA Manager",
    "Senior QA consultant",
    "Senior QA Automation Engineer",
    "Senior QA Associate",
    "Senior Product Support Engineer",
    "Senior Product Management Specialist",
    "Senior Product Executive",
    "Senior Product Associate",
    "Senior PHP Programmer",
    "Senior Oracle Apps dba",
    "Senior Operations Professional",
    "Senior operations analyst",
    "Senior Manger",
    "Senior Manager Projects",
    "Senior Machine Learning Engineer",
    "Senior IT Infrastructure Engineer",
    "Senior iOS Engineer",
    "Senior IOS Developer ",
    "Senior Integration Engineer",
    "Senior Information Security Analyst",
    "Senior Hadoop Developer",
    "senior graphics designer",
    "Senior General Manager",
    "Senior Front end engineer",
    "Senior Faculty",
    "Senior ETL Developer",
    "Senior Editor",
    "Senior Developer Mobile Solutions",
    "senior Dba",
    "Senior database developer",
    "Senior Database Admin",
    "Senior Data Associate",
    "Senior Coordinator",
    "Senior Consultant - Testing",
    "Senior Consultant - IT",
    "Senior Cloud DevOps Engineer",
    "Senior Business Systems Analyst",
    "senior business analyst (BI)",
    "Senior BI Analyst",
    "Senior Automation test engineer",
    "Senior Associate Software Engineer",
    "Senior Associate QA L1",
    "Senior Associate QA",
    "Senior Associate Project Manager",
    "Senior Associate Designer",
    "Senior Associate ",
    "Senior Artist",
    "Senior Advisor",
    "Senior account manager",
    "Senior 3d artist",
    "self",
    "Security Analyst Trainee",
    "SDET Lead",
    "SDET III",
    "SDE I",
    "Scrum master/Project manager",
    "Scrum Lead",
    "SCIENTIFIC OFFICER",
    "SAP Technical Manager",
    "SAP Project Manager",
    "sap manager",
    "sap hr consultant",
    "Sap hcm consultant",
    "SAP HANA CONSULTANT",
    "SAP Functional Consultant",
    "SAP FICO Lead",
    "Sap fico functional consultant",
    "SAP FI consultant",
    "SAP Executive",
    "Sap BW Hana consultant",
    "SAP Associate Consultant",
    "SAP ABAP DEVELOPER",
    "Salesforce Solution Architect",
    "Sales Supervisor",
    "SALES OPS ANALYST",
    "RTL design engineer",
    "RPA Lead",
    "RPA DEVELOPER ",
    "ROR developer",
    "RF Optimization Engineer",
    "RF Engineer",
    "Research Staff",
    "Release Engineer",
    "Regional Sales Manager",
    "Reactjs Developer",
    "React.js Developer",
    "React JS Developer",
    "R&D developer",
    "Quantitative Analyst",
    "Quality control",
    "Quality Assurance Technician",
    "Quality Assurance Specialist",
    "Quality Assurance Engineering Professional",
    "Quality assurance automation engineer",
    "Quality",
    "QA/QC Engineer",
    "QA TEST ANALYST",
    "QA Specialist",
    "QA Project Manager",
    "QA Manual Tester",
    "QA Head",
    "Qa engineering",
    "Qa Engineer (Manual)",
    "QA Engg",
    "QA CONTRACTOR",
    "QA Automation Lead",
    "QA Associate",
    "QA Architect",
    "Q.A",
    "Python Programmer",
    "Python Automation Engineer",
    "Purchase Engineer",
    "Pune",
    "Project Officer",
    "Project Manger",
    "Project Manager IT",
    "Project Manager cum Scrum Master",
    "Project Manager / Scrum Master",
    "Project management officer",
    "Project Management",
    "Project lead ",
    "Project Director",
    "Programmar",
    "Program Manager 2",
    "Program Lead",
    "Professional2",
    "Professional",
    "Production support engineer",
    "Production Support Analyst",
    "Production Manager",
    "Production Head",
    "Production Associate",
    "Product Technical Lead",
    "Product support manager",
    "Product Solution Engineer",
    "Product Manger",
    "Product Manager Intern",
    "product management analyst",
    "product director",
    "Product Design Intern",
    "Product consultant",
    "Procurement Analyst",
    "Process Manager",
    "Process Engineer",
    "Principle Consultant",
    "Principal Visual Designer",
    "Principal UX",
    "Principal Specialist",
    "Principal Member of Technical Staff",
    "Principal Applications Engineer",
    "Principal Application Engineer",
    "Principal Analyst",
    "Pricing Analyst",
    "Pre Sales Consultant",
    "PMO Consultant",
    "Planning Engineer",
    "PL/SQL Developer",
    "Physical design trainee engineer",
    "Physical design engineer-1",
    "physical design engineer ",
    "PHP Programmer",
    "Photoshop Artist",
    "Personal tutor",
    "Performance Test Manager",
    "Pega developer",
    "PA",
    "ORACLE PLSQL Developer",
    "Oracle PL SQL Developer",
    "Oracle Apps DBA",
    "operations associate",
    "Operations",
    "Operation Engineer",
    "offshore delivery manager",
    "Odoo Developer",
    "NULL",
    "Not Applicable",
    "Node Developer",
    "NOC Team Leader",
    "Networking Engineer",
    "Network trainer",
    "Network trainee",
    "Network support",
    "Network Security Consultant",
    "Network enginner",
    "Network Engineer 2",
    "National Sales Manager",
    "MTS2",
    "MTS-1",
    "MRI TECHNOLOGIST",
    "MRI Technician",
    "Motion graphics",
    "Motion Graphic Artist",
    "Module lead QA",
    "Mobility Architect",
    "Mobile Software Developer",
    "ML Engineer",
    "Mechatronics engineer",
    "Mechanical Engineer",
    "Marketing executive",
    "marketing Analyst",
    "Marketing",
    "Manual testing",
    "Manual and Automation Test Engineer",
    "Manager, Development",
    "Manager Technical Lead",
    "Manager Software",
    "MANAGER SALES",
    "manager project",
    "Manager Business analysis",
    "Manager BI",
    "Manager - Software Engineering",
    "Management Trainee - Product Management",
    "Management Consultant",
    "Machine learning developer",
    "logistics manager",
    "Lighting artist",
    "Lead Video Editor",
    "Lead UX/UI Designer",
    "Lead User Experience Designer",
    "Lead Technical Consultant",
    "Lead systems engineer",
    "Lead Software Test Engineer",
    "Lead Product Owner",
    "Lead Product Manager",
    "Lead Functional Consultant",
    "Lead Experience Design",
    "Lead Enginner",
    "Lead DevOps",
    "Lead Data Analyst",
    "lead business analyst ",
    "L2 network engineer",
    "Key Account Manager",
    "Junior Technical writer",
    "Junior Technical Officer",
    "Junior Software Associate",
    "Junior Quality Analyst",
    "junior network engineer",
    "junior Devops Engineer",
    "Junior Cloud Engineer",
    "Jr.Software Tester",
    "Jr.Software Developer",
    "Jr.DevOps engineer",
    "jr.Data scientist",
    "Jr. Test Engineer",
    "Jr. Business Development Executive",
    "Jr. Android Developer",
    "Jr web developer",
    "Jr Embedded Engineer",
    "Java Programmer",
    "Java Full Stack Developer",
    "IT-Manager",
    "IT Support Analyst",
    "IT Senior Consultant",
    "IT SECURITY ANALYST",
    "IT Recruiter",
    "IT Product Manager",
    "IT operations Lead",
    "IT Operation Manager",
    "IT operation analyst",
    "IT intern",
    "IT Coordinator",
    "IT Associate",
    "IT application specialist",
    "iOS developers",
    "iOS Developer~~iOS Developer",
    "Intern Software developer",
    "Intern Analyst",
    "Interior Designer",
    "Interactive Developer",
    "Interaction Designer",
    "Integration Engineer",
    "Integration Consultant",
    "Infrastructure Specialist",
    "Infrastructure Manager",
    "Infrastructure Lead",
    "infrastructure engineer",
    "Infrastructure Analyst",
    "Infra Technology Specialist",
    "information security specialist",
    "Information Security Engineer",
    "Information Architect",
    "Industrial Engineer",
    "Individual Contributor",
    "Implementation Specialist",
    "Implementation Developer",
    "Implementation Analyst",
    "Implementation",
    "Im fresher",
    "I am a fresher",
    "HR Analyst",
    "hr",
    "Healthcare Consultant",
    "Head Technology",
    "Head of Testing",
    "Head of QA",
    "Head of Product Engineering",
    "Head of Product Development",
    "Head of product",
    "Head of IT department",
    "Head IT Infrastructure",
    "head designer",
    "Head - IT Infrastructure",
    "Hardware and networking",
    "Group lead",
    "Group CTO",
    "Graphic Designing",
    "Graphic Designer Intern",
    "Graphic designer and video editor",
    "Graphic Designer and Illustrator",
    "Graphic Designer & Video Editor",
    "graphic designer ",
    "Graphic Design Intern",
    "graphic & web designer",
    "Graduate Trainee",
    "Graduate student",
    "Graduate Intern Technical",
    "Graduate engineer",
    "Graduate Assistant",
    "GM IT Infrastructure",
    "Global Service Desk Manager",
    "Global Delivery Manager",
    "GIS Specialist",
    "Game Artist",
    "Functional Analyst",
    "Full Stack Javascript Developer",
    "Full Stack Java Developer",
    "Full Stack Developer Trainee",
    "Frontend Web Developer",
    "Front-end Web Developer",
    "Front-End Engineer",
    "Front end devloper",
    "front end developer ",
    "Freshee",
    "Freelance Software Developer",
    "Flutter developer",
    "Finance Analyst",
    "Fellowship Engineer",
    "Executive video editor",
    "Executive Producer",
    "Executive officer",
    "Executive Engineer",
    "Executive Analyst",
    "EUC administrator",
    "ETL analyst",
    "Escalation Engineer",
    "ERP MANAGER",
    "ERP Administrator",
    "Enterprise Solution Architect",
    "ENO",
    "Engineering Program Manager",
    "Engineering Director",
    "Engineer-QA",
    "Engineer operations",
    "Engineer II",
    "EMBEDDED SYSTEM ENGINEER",
    "embedded IOT engineer",
    "Embedded Design Engineer",
    "Electronic engineer",
    "Electrical Engineer",
    "EDP",
    "EDI Analyst",
    "DVP",
    "DSE",
    "Drupal Developer",
    "Document Controller",
    "Director, Product Development",
    "Director R&D",
    "Director Product Management",
    "Director Product Development",
    "Director - Technology",
    "Director - Technical",
    "Digital marketing head",
    "digital marketing",
    "DGM-IT",
    "DGM IT",
    "DevOps Support Engineer",
    "DevOps Lead",
    "DevOps Consultant",
    "Device Associate",
    "Developer Analyst",
    "Desktop Support Engineer L2",
    "desktop engineer",
    "Design Team Lead",
    "Design Intern",
    "Design Associate",
    "Design and Verification Engineer",
    "Derivative analyst",
    "Deputy Vice President",
    "Deputy Manager Design",
    "Deputy Manager - IT",
    "Deputy General Manager - IT",
    "Delivery Trainee",
    "Delivery Project Lead",
    "Delivery Director",
    "Deep Learning Intern",
    "Decision Analytics Associate Consultant",
    "DBA Manager",
    "DB Architect",
    "database specialist",
    "Database Engineer",
    "Database Consultant",
    "Data Warehouse Specialist",
    "Data Science Specialist",
    "Data Engineering Analyst",
    "Data Associate",
    "Data Analyst Trainee",
    "Data analyst associate",
    "Data Analyst ",
    "Cyber Security Specialist",
    "Cyber Security Manager",
    "Cyber Security associate",
    "Customer support Engineer",
    "Currently not working",
    "CTO & Director",
    "CSA",
    "Creative Manager",
    "Creative Graphic Designer",
    "Creative associate",
    "Contract engineer",
    "Contract",
    "CONSULTING PARTNER",
    "consulting engineer",
    "Consultant~~Consultant",
    "Consultant Development",
    "Consultant - Analytics",
    "Computer Vision Intern",
    "Co-Founder, Director",
    "Cloud Solutions Engineer",
    "cloud ops engineering sr analyst",
    "Cloud Operations Engineer",
    "Cloud Developer",
    "Cloud administrator",
    "cloud admin",
    "client success manager",
    "CISO",
    "Chief operating officer",
    "Change Manager",
    "Change control specialist",
    "Certified Scrum Master",
    "CEO and Co-founder",
    "CEO & Founder",
    "Category Manager",
    "Campus Ambassador",
    "Campaign Specialist",
    "Business unit head",
    "business technology associate",
    "Business presentation specialist",
    "Business partner",
    "Business Operations Specialist",
    "Business operations Analyst",
    "Business Operation Associate",
    "Business Manager",
    "Business Intelligence Manager",
    "Business Intelligence Engineer",
    "Business Associate",
    "Business Anlayst",
    "Business Analyst~~Business Analyst",
    "Business Analyst / Product Owner",
    "Btech",
    "Branch Manager",
    "Big Data Hadoop Administrator",
    "BDE",
    "Basis consultant",
    "banglore",
    "Backhaul engineer",
    "BA",
    "AWS System Admin",
    "AWS Developer",
    "AWS Cloud Engineer",
    "AWS Admin",
    "AVP Technology",
    "Automation Test Specialist",
    "Automation Test Analyst",
    "Automation software tester",
    "Automation QA Lead",
    "Automation QA",
    "Automation Architect",
    "Asst. General Manager",
    "Asst Consultant",
    "Assosiate",
    "Associate, Technology",
    "Associate Web Developer",
    "Associate UX Designer",
    "Associate UI/UX Designer",
    "Associate Test Lead",
    "Associate Test Consultant",
    "Associate Technology L1",
    "Associate Technical Operations",
    "Associate Technical Architect",
    "Associate Technical Analyst",
    "Associate Systems Analyst",
    "Associate solution engineer",
    "Associate Solution Advisor",
    "Associate software consultant",
    "Associate Quality Assurance Engineer",
    "Associate QA Manager",
    "Associate project Engineer",
    "Associate program manager",
    "associate professor",
    "Associate Operations",
    "Associate Network Engineer",
    "Associate Machine learning engineer",
    "Associate Lead Technology",
    "Associate Lead Quality",
    "Associate Lead Engineer",
    "Associate Lead Business Analyst",
    "Associate IT Developer",
    "Associate IT Analyst",
    "Associate implementation engineer",
    "Associate Graphic Designer",
    "Associate Game Developer",
    "Associate Functional Consultant",
    "Associate Director Technology",
    "Associate Director IT",
    "Associate Director - Technology",
    "Associate Consultent",
    "Associate Consultant~~Associate Consultant",
    "Associate Consultanat",
    "Associate cloud engineer",
    "Associate Automation Engineer",
    "Associate Applications Developer",
    "Associate Android Developer",
    "Associate 2",
    "Associate - Technology",
    "Assoc",
    "Assitant System Engineer",
    "Assistant Vice President - IT",
    "Assistant System Engineer-Trainee",
    "Assistant System Engineer - Trainee",
    "Assistant System Analyst",
    "Assistant Project Engineer",
    "Assistant Program Manager",
    "Assistant Operation Manager",
    "Assistant Manager QA",
    "Assistant Manager MIS",
    "Assistant Manager Design",
    "Assistant Manager - Design",
    "Assistant Manager ",
    "Assistant designer",
    "Assistance System Engineer",
    "Assistance Manager",
    "Asp.net developer",
    "Artificial Intelligence Engineer",
    "Artificial Intelligence Developer",
    "Apprenticeship",
    "Applied Research Scientist",
    "Applications Developer",
    "Applications Administrator",
    "Application Support Analyst",
    "Application specialist",
    "Application Software Engineer",
    "Application Development Technical Lead",
    "Application Development specialist",
    "Application Development Associate Manager",
    "Application Developer Analyst",
    "Application Developer ",
    "Application Consultant",
    "Android Team Lead",
    "Android Mobile Application Developer ",
    "Android Intern",
    "Android Development Trainee",
    "Android developee",
    "Analytics Specialist",
    "analytics engineer",
    "AM",
    "AI/ML Engineer",
    "AGM Technology",
    "Advisory Software Engineer",
    "Advisory Analyst",
    "Advanced engineer",
    "Administration",
    "Accountant",
    "ACCOUNT EXECUTIVE",
    "Account delivery Manager",
    "3d modeller",
    "2D artist",
    "2D Animator",
    " Software Engineer"
]

export function getDesignations(){
 
    return hiristDesignation.map((item, index) => {
        return {
            label: item,
            value: item,
            // key: index,
        }
    })
    
}