import React, { useEffect, useState } from 'react';
import fetchLGBills from '../../../../services/lGBillingCrud/fetchLGBills';
import user from '../../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import Heading from '../../../../components/Heading';
import ReactDatePicker from 'react-datepicker';
import InputBox from '../../../../components/InputBox';
import { currencyUnitsGlobal } from '../../../../models/currencyUnits';
import fetchBusinessLines from '../../../../services/lGBillingCrud/getBusinessLines';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Aux from '../../../../components/Aux'
import Button from '../../../../components/Button'
import moment from 'moment'
import "./Container.scss"
import scrollToElement from 'scroll-to-element';
import addLGBill from '../../../../services/lGBillingCrud/addLGBill';
import updateLGBill from '../../../../services/lGBillingCrud/updateLGBill'
import { adminFeatureMapping } from '../../../../models/adminFeatureMapping';

const LGBillContainer = (props) => {

    const addLGBillPath = "/addLGBill" 
    const lGBillsListPath = "/lGBills" 

    const [loader, setLoader] = useState(false)
    const [adminType, setAdminType] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [addBill, setAddBill] = useState(true);
    const [currencyUnits, setCurrencyUnits] = useState(null)
 
    const [formValues, setFormValues] = useState({
        billData : null,
        billingDate : null,
        businessLine : 0,
        availableBusinessLines: [],
        customerName: null,
        billingAmount: null,
        invoiceNumber: null,
    })

    const [formValidation, setFormValidation] = useState({
        billingDate : null,
        businessLine : null,
        customerName: null,
        billingAmount: null,
        invoiceNumber: null,
    })

    const getCurrencyUnits = (amt) => {
        let formattedValue, formattedUnit;
        if(!amt){
            formattedValue = amt
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1)
        }
        else
        if (amt >= 10000000) {
            formattedValue = (amt / 10000000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 10000000);
          } else if (amt >= 100000) {
            formattedValue = (amt / 100000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 100000);
          } else if (amt >= 1000) {
            formattedValue = (amt / 1000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1000);
          } else {
            formattedValue = amt;
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1);
        }
        
        return `${formattedValue} ${formattedUnit.unit}`
    }

    const getLGBill = async (adminId, billId) => {
        fetchLGBills(adminId, { billId })
        .then(info => {
            console.log(info.data.data)
            if(info.data && info.data.data){
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["billingDate"]: moment(info.data.data[0]["billingDate"]).toDate(),
                    ["businessLine"] : info.data.data[0]["businessLine"],
                    ["customerName"]: info.data.data[0]["customerName"],
                    ["billingAmount"]: info.data.data[0]["amount"],
                    ["invoiceNumber"]: info.data.data[0]["invoiceNumber"],
                    ["billData"]: info.data.data[0]
                }))
                const convertedAmount = getCurrencyUnits(info.data.data[0]["amount"])
                setCurrencyUnits(convertedAmount)
            }
        })
        .catch(err => {
            console.log(err)
            toast.error(err.response.data.message);
        });
    }

    const getBusinessLines = async(adminId) => {
        fetchBusinessLines(adminId, { })
        .then(res => {
            if(res.data){
                let resBuisenessLines = [{"id": 0, "name": "Select Business Line"}]
                Object.keys(res.data["BusinessLine"]).forEach(key => {
                    resBuisenessLines.push({"id": key, "name": res.data["BusinessLine"][key]})
                });
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["availableBusinessLines"]: resBuisenessLines
                }))
            }
        })
        .catch(err => {
            toast.error(err.response.data.message);
        });
    }

    const handleBillingDate = (date) =>{
        setFormValues((prevState) => ({
            ...prevState,
            ["billingDate"]: date
        }))
    }

    const handleBusinessLineChange = (event) => {
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["businessLine"]: val
        }))
    }

    const handleCustomerNameChange = (event) =>{
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["customerName"]: val
        }))
    }

    const handleBillingAmount = (event) => {
        let val = event.target.value;
        const convertedAmount = getCurrencyUnits(val)
        setCurrencyUnits(convertedAmount)
        setFormValues((prevState) =>({
            ...prevState, 
            ["billingAmount"]: val,
        }))
    }

    const handleInvoiceNumber = (event) =>{
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["invoiceNumber"]: val.trim()
        }))
    }

    const updateJob = () => {
        setLoader(true)
        const data = {
            "businessLine": formValues.businessLine,
            "customerName": formValues.customerName.trim(),
            "amount": formValues.billingAmount,
            "billingDate": moment(formValues.billingDate).format('YYYY-MM-DDTHH:mm:ss'),
            "invoiceNumber": formValues.invoiceNumber
        }

        console.log(props.match.path)
        if(props.match.path == addLGBillPath){
            addLGBill(adminId, data)
            .then(res => {
                if (res.data.status == 'success') {
                    toast.info('Bill addition success');
                    setTimeout(() => {
                        props.history.push(lGBillsListPath)
                        }, 1500);
                }
            })
            .catch(err => {
                console.log(err.response);
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        else{
            updateLGBill(adminId, props.match.params.id, data)
            .then(res => {
                
                if (res.data.status == 'success') {
                    toast.info('Bill updation success');
                    setTimeout(() => {
                        props.history.push(lGBillsListPath)
                        }, 1500);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        setLoader(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const validation = {
            billingDate: (formValues.billingDate != null && formValues.billingDate <= moment()) ? false: true,
            businessLine: (formValues.businessLine != null && formValues.businessLine != 0)? false: true,
            customerName: formValues.customerName != null ? false: true,
            billingAmount: formValues.billingAmount != null && formValues.billingAmount > 0 ? false: true,
            invoiceNumber: formValues.invoiceNumber != null? false: true
        }

        setFormValidation(validation);

        let flag = 1
        for (var prop in validation) {
            if (validation[prop] == true) flag = 0;
        }

        if(flag == 0){
            if (validation.billingDate)
                scrollToElement('.billingDate');
            if (validation.businessLine)
                scrollToElement('.businessLine');
            if (validation.customerName)
                scrollToElement('.customerName');
            if (validation.billingAmount)
                scrollToElement('.billingAmount');
            if(validation.invoiceNumber)
                scrollToElement('.invoiceNumber')
        }
        else{
            updateJob() 
        }
    }

    useEffect(()=>{
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(profile => {
            if (profile.adminType == 2 || profile.adminType == 3) {
                const adminId = profile.id
                const type = profile.type;
                const adminType = profile.adminType;
                setAdminType(adminType)
                setAdminId(adminId)
                const billId = props.match.params.id
                getBusinessLines(adminId)
                console.log(props.match.path);
                if(props.match.path != addLGBillPath){
                    const adminAccess = user.checkFeatureAccess(adminFeatureMapping.LEARN_AND_GROW_UPDATE_BILLS);
                    if(!adminAccess)
                        window.location.href='/notAdmin';
                    alert("fetching details")
                    getLGBill(adminId, billId)
                    setAddBill(false)
                }                      
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, [])

    return (
        <>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />
            <div className='lGBillFormContainer'>
                <div className='lGBillFormHeadingContainer'>
                {
                    addBill ? 
                    <>
                        <Heading text = "Add Bill"/>
                    </>
                    :
                    <Heading text = "Edit Bill"/>
                }
                </div>
                <form>
                    <div className='form-group billingDate'>
                        <label className='col-sm-2 left'>*Billing Date*</label>
                        <div className='col-sm-6 left valueBox'>
                            <ReactDatePicker selected={formValues.billingDate} onChange={(date) => handleBillingDate(date)}/>
                            {formValidation.billingDate ? <p className='validatorError'> Please Enter valid billing date </p> : null}
                        </div>
                    </div>
                    <div className='form-group businessLine'>
                        <label className='col-sm-2 left'>Business Line*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container'>
                                <Dropdown className='block-dropdown' data={formValues.availableBusinessLines} name='available-business-lines' defaultValue={formValues.businessLine} dropdownChange={handleBusinessLineChange} />
                                {formValidation.businessLine ? <p className='validatorError'> Please Enter business line </p> : null}
                            </div>
                        </div>
                    </div>
                    <div className='form-group customerName'>
                        <label className='col-sm-2 left'>Customer Name*</label>
                        <div className='col-sm-6 left valueBox'>
                            <InputBox type='text' value={formValues.customerName} name='customer-name' onChange={handleCustomerNameChange}/>
                            {formValidation.customerName ? <p className='validatorError'> Please Enter customer name </p> : null}
                        </div>
                    </div>
                    <div className='form-group billingAmount'>
                        <label className='col-sm-2 left'>Billing Amount*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container lGBillAmountInput'>
                                <div className='block-dropdown'>
                                    <InputBox type='number' value={formValues.billingAmount} name='billing-amount' onChange={handleBillingAmount}/>
                                    {formValidation.billingAmount ? <p className='validatorError'> Please Enter billing amount </p> : null}
                                </div>
                                <div className='block-dropdown right-dropdown'>
                                    <InputBox className='block-dropdown' value={currencyUnits} name='available-business-lines' placeholder = "units"/>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='form-group invoiceNumber'>
                        <label className='col-sm-2 left'>Invoice Number*</label>
                        <div className='col-sm-6 left valueBox'>
                            <InputBox type='text' value={formValues.invoiceNumber} name='invoice-number' onChange={handleInvoiceNumber}/>
                            {formValidation.invoiceNumber ? <p className='validatorError'> Please Enter invoice number </p> : null}
                        </div> 
                    </div>
                </form>    
                <div className='submitButtonContainer'>
                    <Aux>
                        <Button skin='dark' disabled={false} text={props.match.path == addLGBillPath?'Submit': 'Update'} clickButton={(e) => handleSubmit(e)} />
                    </Aux>
                </div>
            </div>
        </>
    );
};

export default LGBillContainer;