import axios from '../utils/axios';

const activateRecruiter = (adminId, recruiterId, data) => {
    return (
        axios.post('/admin/' + adminId + '/recruiter/' + recruiterId + '/activate', data)
            .then((response) => {
                return response
            }).catch((error) => {
                return error
            })
    )
}

export default activateRecruiter;