import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import InputBox from '../../components/InputBox';
import Button from '../../components/Button';

import './TableItem.scss';

const TableItem = props => {
    const data = props.data;
    const inputRef = useRef(null);
    
    let domainType = '';
    const btnArray = [];
    
    useEffect(() => {
        if (inputRef && data.comments) {
            inputRef.current.value = data.comments;
        }
    }, [inputRef]);

    const handleButtonClick = (event) => {
        const name = event.target.className;
        const params = {
            domain: [data.domain],
            premium: 0,
            blacklist: 0,
            paid_client: 0
        };

        if (name.includes('premium')) {
            params.premium = data.premium == 1 ? 0 : 1;    
        } else if (name.includes('blacklist')) {
            params.blacklist = data.blacklist == 1 ? 0 : 1;
        } else if (name.includes('paid')) {
            params.paid_client = data.paid_client == 1 ? 0 : 1;
        }

        props.onUpdate(params);
    }

    const onKeyPressHandler = (event) => {
        if (event.key === 'Enter') {
            handleCommentsSave();
        }
    }

    const handleCommentsSave = () => {
        const comments = inputRef.current.value;

        props.onUpdate({
            domain: [data.domain],
            comments
        });
    }

    const getButton = (className, text, skin, isLoading) => {
        return <Button clickButton={handleButtonClick} className={className} skin={skin} text={text} loading={isLoading} />; 
    };

    if (data.blacklist == 1) {
        domainType = 'Blacklist';
        btnArray.push(getButton('btn btn-premium', 'Make Premium', 'light', data.isLoading==3 ? true : false));
        btnArray.push(getButton('btn btn-blacklist', 'Remove Blacklist', 'dark', data.isLoading==1 ? true : false));
        btnArray.push(getButton('btn btn-paid', 'Make Paid Client', 'light', data.isLoading==2 ? true : false));
    } else if(data.paid_client == 1) {
        domainType = 'Paid Client';
        btnArray.push(getButton('btn btn-premium', 'Make Premium', 'light', data.isLoading==3 ? true : false));
        btnArray.push(getButton('btn btn-blacklist', 'Make Blacklist', 'light', data.isLoading==1 ? true : false));
        btnArray.push(getButton('btn btn-paid', 'Remove Paid Client', 'dark', data.isLoading==2 ? true : false));
    } else if(data.premium == 1) {
        domainType = 'Premium';
        btnArray.push(getButton('btn btn-premium', 'Remove Premium', 'dark', data.isLoading==3 ? true : false));
        btnArray.push(getButton('btn btn-blacklist', 'Make Blacklist', 'light', data.isLoading==1 ? true : false));
        btnArray.push(getButton('btn btn-paid', 'Make Paid Client', 'light', data.isLoading==2 ? true : false));
    } else {
        domainType = '-';
        btnArray.push(getButton('btn btn-premium', 'Make Premium', 'light', data.isLoading==3 ? true : false));
        btnArray.push(getButton('btn btn-blacklist', 'Make Blacklist', 'light', data.isLoading==1 ? true : false));
        btnArray.push(getButton('btn btn-paid', 'Make Paid Client', 'light', data.isLoading==2 ? true : false));
    }
    
    const domain = (
        <div className='div_domain'>
            <InputBox className="domain_checkbox" type="checkbox" options={{ 'data-domain': `${data.domain}` }} />
            <span>{data.domain}</span>
        </div>
    );

    const commentContent = (
        <div className='div_comments'>
            <InputBox className="comments" type="text"  _ref={inputRef} onKeyPress={onKeyPressHandler} placeholder="Add a Comment" />
            <Button text='Save' skin='light' clickButton={handleCommentsSave} loading={data.isLoading == 4 ? true : false}/>
        </div>
    )
    
    return (
        <Table.Row verticalAlign='top' className='table__row'>
            <Table.Cell width="1">{domain}</Table.Cell>
            <Table.Cell width="1">{domainType}</Table.Cell>
            <Table.Cell width="3">{btnArray}</Table.Cell>
            <Table.Cell width="2">{commentContent}</Table.Cell>
        </Table.Row>
    )
};

TableItem.propTypes = {
    data: PropTypes.object,
    onUpdate: PropTypes.func,
};

export default TableItem;