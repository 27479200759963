import React, { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import InputBox from "../../../components/InputBoxWithoutCheck";
import Button from "../../../components/Button";

import Heading from "../../../components/Heading";
import "react-toastify/dist/ReactToastify.css";
import anyRecruiterLogin from "../../../services/anyRecruiterLogin";

import user from "../../../store/user";
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';

import './anyRecruiterLogin.scss'
import { Link } from "react-router-dom";


const validationMsg = {
	emailReq: "Please enter email",
	invalidEmail: "Please enter a valid email",
	passwordReq: "Please enter password",

}

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/


const AnyRecruiterLogin = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
	const [validations, setValidations]= useState({})
	const [submitApiCall, setSubmitApiCall] = useState(false)


	const checkForAdminUser = (type) => {
        if (type == 2 || type == 3 || user.checkFeatureAccess(adminFeatureMapping.ANY_RECRUITER_LOGIN)) {
            return true;
        }
        return false;
    }

	useEffect(() => {
	
        user.getProfile().then(userData => {
            if (userData.type != 1 && !checkForAdminUser(userData.adminType)) {
				window.location.href = '/notAdmin';
			}
        })

	}, [])

	const setLoginToken = (headers) => {
		var token = headers['set-token'];
		var universalToken = headers['set-universal-token'];
		var today = new Date();
		today.setTime(today.getTime());
		var expires = 1 * 1000 * 60 * 60 * 24;
		var expires_date = new Date(today.getTime() + expires);
	
		token = escape(token);
		var cookieName = process.env.REACT_APP_COOKIE_NAME;
		var oldCookie = process.env.REACT_APP_OLD_COOKIE_NAME;
		var domain = process.env.REACT_APP_DOMAIN;
	
		document.cookie =
			cookieName +
			'=' +
			token +
			(expires ? ';expires=' + expires_date.toGMTString() : '') +
			';path=/' +
			';domain=' +
			domain;
		document.cookie =
			oldCookie +
			'=' +
			universalToken +
			(expires ? ';expires=' + expires_date.toGMTString() : '') +
			';path=/' +
			';domain=' +
			domain;
	}
	
	const onSubmit = async (e) => {

		e.preventDefault();
		const profile = await user.getProfile();

		if(validate()) {
			try{
				setSubmitApiCall(true)
				const res = await anyRecruiterLogin(profile.id, {
					email,
					password
				})

				setSubmitApiCall(false)

				console.log(res.data);

				if(res.response && res.response.data && res.response.data.message.includes('not found')) {
					throw new Error('Not Found')
				}

				if(res.data && !res.data.data && res.data.includes("incorrect password")) {
					throw new Error('incorrect password')
				}

				
				setLoginToken(res.headers)

			
				window.location.href = process.env.REACT_APP_LOGIN_URL;
			}
			catch(e) {
				console.log(e);
				setSubmitApiCall(false)

				if(e.message == 'Not Found') {
					return toast.error("Recruiter Not Found")
				}

				if(e.message == 'incorrect password') {
					return toast.error('Incorrect password')
				}

				toast.error("Something went wrong")
			}
		}
		
	}

	const validate = () => {

		setValidations({})

		if(!email.trim().length){

			setValidations({
				emailReq: true
			})

			return false;

		}

		if(!emailRegex.test(email)) {
			setValidations({
				invalidEmail: true
			})

			return false;
		}

		if(!password.trim().length){
			setValidations({
				passwordReq: true
			})

			return false;
		}

		return true;

		
	}

    return (
        <div className="any-recruiter-login-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="recruiter-login-pageHeading">
                <Heading text="Recruiter Login" />
				<a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/login_xwpm.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
            </div>
            <hr />
            <div className="recruiter-login">
                <form>
                    <div className="row section details-section">
                        <p className="label-heading"> Login by any Recruiter </p>

                        <div className="form-group email">
                            <label className="col-sm-2 left"> Email *</label>
                            <div className="col-sm-6 left valueBox">
                                <InputBox
                                    type="text"
                                    value={email}
                                    placeholder="Recruiter email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {validations.emailReq ? (
                                    <p className="validatorError">
                                        {" "}
                                        {validationMsg.emailReq}{" "}
                                    </p>
                                ) : null}
								{validations.invalidEmail ? (
                                    <p className="validatorError">
                                        {" "}
                                        {validationMsg.invalidEmail}{" "}
                                    </p>
                                ) : null}
                            </div>
                        </div>

						<div className="form-group password">
                            <label className="col-sm-2 left"> Password *</label>
                            <div className="col-sm-6 left valueBox">
                                <InputBox
                                    type="password"
                                    value={password}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {validations.passwordReq ? (
                                    <p className="validatorError">
                                        {" "}
                                        {validationMsg.passwordReq}{" "}
                                    </p>
                                ) : null}
                            </div>
                        </div>

						<div className="actions">
							<Button
								skin="dark"
								disabled={submitApiCall}
								loading={submitApiCall}
								text="Login"
								clickButton={onSubmit}
							/>

							<Link className="forgot-password" to={'/resetPasswordLink'}>Forgot Password?</Link>
						</div>

                        
                    </div>

                   
                </form>
            </div>
        </div>
    );
};

export default AnyRecruiterLogin;
