import axios from '../utils/axios';

export default function verifyCompanyManagement(recId,companyId,data) {
    return (
        axios.post(`/admin/${recId}/company-management/verify-company/${companyId}`, data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}