import React, { useEffect, useState } from 'react'
import Button from '../../components/Button';
import { Table } from 'semantic-ui-react'
import fetchFollowUpCount from '../../services/fetchFollowUpCount';
import user from '../../store/user';

import moment from 'moment';

import './FollowerReport.scss'

const FollowerReport = () => {

    const [followData, setFollowData] = useState({});

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();

            const [today, yesterday, dayBeforeYesterday, thisMonth, lastMonth, secondLastMonth] = [
                moment().format('DD/M/YYYY'),
                moment().subtract(1, 'day').format('DD/M/YYYY'),
                moment().subtract(2, 'day').format('DD/M/YYYY'),
                moment().format('MMM YYYY'),
                moment().subtract(1, 'M').format('MMM YYYY'),
                moment().subtract(2, 'M').format('MMM YYYY'),
            ]

            // eslint-disable-next-line no-undef
            const [todayData, yesterdayData, dayBeforeYesterdayData, thisMonthData, lastMonthData, secondLastMonthData, total] = await Promise.all([
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().subtract(2, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().subtract(2, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().startOf('M').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().endOf('M').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().subtract(1, 'M').startOf('M').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().subtract(1, 'M').endOf('M').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, followedAfter: moment().subtract(2, 'M').startOf('M').format('YYYY-MM-DD HH:mm:ss'), followedBefore: moment().subtract(2, 'M').endOf('M').format('YYYY-MM-DD HH:mm:ss') }),
                fetchFollowUpCount(profile.id, { status: 1, }),
            ],);

            setFollowData({
                [today]: todayData.data.data,
                [yesterday]: yesterdayData.data.data,
                [dayBeforeYesterday]: dayBeforeYesterdayData.data.data,
                [thisMonth]: thisMonthData.data.data,
                [lastMonth]: lastMonthData.data.data,
                [secondLastMonth]: secondLastMonthData.data.data,
                Total: total.data.data,
            });
        })()
    }, []);

    return (
        <div className='follower-report-container'>
            <div className='topbar'>
                <h2 className='page-heading'>Showcase Follower Report</h2>
                <Button
                    className="older-version-btn"
                    text="Go to Older Version"
                    skin="light"
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/follower-report/`}
                />
            </div>
            <div className='follower-report-list'>
                <Table
                    fixed
                    selectable
                    className="follower-list"
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Date
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                No Of Followers
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Object.keys(followData).map((key) => {
                            return (
                                <Table.Row>
                                    <Table.Cell>
                                        {key}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {followData[key]}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default FollowerReport