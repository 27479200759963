import React from "react";
import { useState } from "react";
import InputBox from "../../../components/InputBoxWithoutCheck";
import Button from "../../../components/Button";

import Heading from "../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var scrollToElement = require("scroll-to-element");

import user from "../../../store/user";
import FromToDatePicker from "../../../components/FromToDatepicker";
import { useEffect } from "react";
import validator from "validator";

import "./styles.scss";
import { courseTypeCourses } from "../../../models/courseType";
import { industry as industryList } from "../../../models/industry";
import { maxSalary, minSalary } from "../../../models/salary";
import { maxExperience, minExperience } from "../../../models/experience";
import { deliveryMethodObj } from "../../../models/deliveryMethod";
import Dropdown from "../../../components/Dropdown/Dropdown";
import PillButton from "../../../components/PillButton";
import { getCategoryPill } from "../../../models/categories";
import SuggestedTags from "../../../components/SuggestedTagsPill";
import { locationListArr } from "../../../models/locationSeeker";
import fetchJobTags from "../../../services/fetchJobTags";
import Aux from "../../../components/Aux";
import Spinner from "../../../components/Spinner";
import { getQueryParams } from "../../../utils/utilFunctions";
import fetchCourseDetail from "../../../services/fetchCourseDetail";
import moment from "moment";
import Loader from "../../../components/Loader";
import updateCourse from "../../../services/updateCourse";
import addCourse from "../../../services/addCourse";
import striptags from "striptags";
import replacer, { cleanIntroText, replacerCourse } from "../../../utils/replacer";
import MediumEditor from "../../../components/MediumEditor";

const validationMsg = {
    titleReq: 'Please enter course title',
    minExpReq: 'Please select min experience',
    maxExpReq: 'Please select max experience',
    logoReq: 'Please upload course logo',
    courseImgReq: 'Please upload campus Image',
    ctaTextReq: 'Please enter cta text',
    maxExpLimit: 'Maximum years of experience should be greater than minimum years of experience',
    invalidEmail: 'Please enter a valid email',
    invalidUrl: 'Please enter a valid url',
    otherLocationReq: 'Please enter other location',
    maxLogoSize: 'Logo must be less than 4 MB',
    maxBrochureSize: 'Brochure must be less than 4 MB',
    maxCourseImgSize: 'Course Image must be less than 4 MB',
    maxAttachmentSize: 'All attachments combined must be less than 12 MB',
    pushNotifTitleReq: 'Please enter push notification title',
    categoryReq: 'Please select category'
};

const validationClass = {
    titleReq: '.title',
    greaterErrorSal: '.sal',
    maxExpLimit: '.exp',
    maxExpReq: '.exp',
    minExpReq: '.exp',
    logoReq: '.courseLogo',
    courseImgReq: '.courseImg',
    ctaTextReq: '.ctaText',
    invalidEmail: '.notificationEmail',
    invalidVideoLink: '.videoLink',
    invalidRedirectUrl: '.redirectUrl',
    invalidLearningPartner: '.learningPartner',
    otherLocationReq: '.otherLocation',
    maxLogoSize: '.courseLogo',
    maxBrochureSize: '.brochure',
    maxAttachmentSize: '.courseLogo',
    categoryReq: '.category',
    pushNotifTitleReq: '.pushNotifTitle'
}

const diversityOptions = [
    {
        name: "sheBack",
        text: "She's Back",
    },
    {
        name: "armyProf",
        text: "Army Professionals",
    },
    {
        name: "phyAble",
        text: "Physically Able",
    },
];

const homePageOptions = [
    {
        name: "web",
        text: "Web featured",
    },
    {
        name: "mob",
        text: "Mobile Featured",
    },
    {
        name: "webCat",
        text: "Web Category",
    },
    {
        name: "mobCat",
        text: "Mobile Category",
    },
];

const otherOptions = [
    {
        name: "webDisplay",
        text: `${process.env.REACT_APP_PLATFORM} Web Display`,
    },
    {
        name: "mobApp",
        text: "Mobile Application",
    },
    {
        name: "simWeb",
        text: "Similar Jobs Web",
    },
    {
        name: "simMob",
        text: "Similar Jobs Mobile",
    },
    {
        name: "premiumEnable",
        text: "Premium Enable",
    },
    {
        name: "enquiryEnable",
        text: "Enquiry Enable",
    },
];

const intitalDetails = {
    title: "",
    oneLiner: "",
    courseType: "Full Time",
    approved: "",
    institute: "",
    location: [],
    otherLocation: "",
    category: [],
    categoryStatus: 0,
    industry: [],
    // directCompany: 0,
    // companyName: "",
    minSal: "",
    maxSal: "",
    showSalary: 1,
    fee: "",
    duration: "",
    deliveryMethod: "Online",
    regStartDate: "",
    regEndDate: "",
    courseStartDate: "",
    courseEndDate: "",
    notification: "all",
    minExp: -1,
    maxExp: -1,
    expGT: "",
    expLT: "",
    notificationEmail: "",
    courseDetails: "",
    courseSchedule: "",
    courseDeadlines: "",
    studyCenter: "",
    desiredCandidate: "",
    courseModules: "",
    videoLink: "",
    logo: "",
    offers: "",
    redirectUrl: "",
    webDisplay: 0,
    mobApp: 0,
    simWeb: 0,
    simMob: 0,
    premiumEnable: 0,
    enquiryEnable: 1,
    diversity: {
        sheBack: 0,
        armyProf: 0,
        phyAble: 0,
    },
    homePage: {
        web: 0,
        mob: 0,
        webCat: 0,
        mobCat: 0,
    },
    pushNotifTitle: "",
    learningPartner: "",
    brochureFile: '',
    blacklistedCourse: 0,
    ctaText: "",
    courseImage: "",
    showOnSimilarCoursesList: true,
    strictExperienceFilterEnabled: false
}

const AddEdit = (props) => {
    const id = props.match.params.id;

    const [courseId, setCourseId] = useState('');

    const [lists, setLists] = useState({
        location: locationListArr,
        industry: industryList,
        category: getCategoryPill()
    })

    const [details, setDetails] = useState(intitalDetails);
    
    const [isOtherLocationSelected,setIsOtherLocationSelected] = useState(false);

    const [mediumText, setMediumText] = useState({
        courseDetails: "",
        courseSchedule: "",
        courseDeadlines: "",
        studyCenter: "",
        desiredCandidate: "",
        courseModules: ""
    })
    const [tags, setTags] = useState({
        selected: [],
        source: [],
        total: 0,
        copied: false
    });

    const [pillOpen, setPillOpen] = useState({
        openindustry: false,
        openlocation: false,
        opencategory: false
    });

    const [validations, setValidations] = useState({});
    const [updateLoader, setUpdateLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [admin, setAdmin] = useState({
        adminId: null,
    });
    const [logoCdn, setLogoCdn] = useState('');
    const [brochureCdn, setBrochureCdn] = useState('');
    const [courseImgCdn, setCourseImgCdn] = useState('');

    const [logoSize, setLogoSize] = useState()
    const [brochureSize, setBrochureSize] = useState()
    const [courseImgSize, setCourseImgSize] = useState()

    useEffect(() => {

        const queryObj = getQueryParams(props.location.search);
        const courseId = queryObj['courseId'];

        setCourseId(courseId);


        if(courseId) {
            setIsLoading(true);
        }
        const fetchProfile = async () => {
            return await user.getProfile();
        };
        fetchProfile().then((userData) => {
            if (
                userData.adminType == 2 ||
                userData.adminType == 3 ||
                userData.adminType == 4
            ) {
                setAdmin({
                    adminId: userData.id,
                });

                const jobTagsObject = {};
                const source = [];

                fetchJobTags(userData.id, {
                    noCache: 1
                }).then(resp => {
                    resp.data.forEach((aTag, i) => {
                        const sourceATag = {
                            value: aTag.id,
                            label: aTag.name
                        }
                        source[i] = sourceATag;
                        jobTagsObject[aTag.name] = +aTag.id;
                    });
                })

                
                if(courseId) {
                    getCourseDetail(userData.id, courseId, jobTagsObject, source)
                    .catch(err => {
                        console.log(err);
                        setIsLoading(false);
                        toast.error('Something went wrong')
                    })
                }
                else if(props.match.path == '/addCourse') {
                    setIsLoading(true);
                    const selectedTags = [];
                    for (let i = 0; i < 11; i++) {
                        selectedTags.push({})
                    }
                    setTags(prev => ({
                        ...prev,
                        source,
                        selected: selectedTags
                    }))
                    setIsLoading(false);
                }

                
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, []);


    const isOtherLocSelected = (locArr) => {
        return locArr.includes('100000');
    }

    const getCourseDetail = async (adminId, courseId, jobTagsObject, tagSource) => {
        const res = await fetchCourseDetail(adminId, courseId);
        const data = res.data.data.data;
        const selectedTags = [];
        data.tags.forEach(aTag => {
            const aSelectedTag = {
                // key: jobTagsObject[aTag],
                label: aTag,
                value: jobTagsObject[aTag],
            }
            selectedTags.push(aSelectedTag)
        })

        if (data.tags.length < 11) {
            for (let i = data.tags.length; i < 11; i++) {
                selectedTags.push({})
            }
        }

        if(isOtherLocSelected(data['location'])) {
            setIsOtherLocationSelected(true)
        }
        setDetails(prev => ({
            ...prev,
            title: data.title,
            oneLiner: data.oneLiner,
            courseType: data.courseType,
            approved: data.approved,
            institute: data.institute,
            location: data.location,
            otherLocation: data.otherLocation,
            category: data.category,
            categoryStatus: data.categoryStatus,
            industry: data.industry,
            // directCompany: data.directCompany,
            // companyName: data.companyName,
            minSal: data.minSal,
            maxSal: data.maxSal,
            showSalary: data.showSalary,
            fee: data.fee,
            duration: data.duration,
            deliveryMethod: data.deliveryMethod,
            regStartDate: moment(data.regStartDate).isValid() ? new Date(data.regStartDate) : null,
            regEndDate: moment(data.regEndDate).isValid() ? new Date(data.regEndDate) : null,
            courseStartDate: moment(data.courseStartDate).isValid() ? new Date(data.courseStartDate) : null,
            courseEndDate: moment(data.courseEndDate).isValid() ? new Date(data.courseEndDate) : null,
            notification: data.notification,
            minExp: data.minExp,
            maxExp: data.maxExp,
            expGT: data.expGT,
            expLT: data.expLT,
            notificationEmail: data.notificationEmail,
            courseDetails: sanitizeInput(data.courseDetails),
            courseSchedule: sanitizeInput(data.courseSchedule),
            courseDeadlines: sanitizeInput(data.courseDeadlines),
            studyCenter: sanitizeInput(data.studyCenter),
            desiredCandidate: sanitizeInput(data.desiredCandidate),
            courseModules: sanitizeInput(data.courseModules),
            videoLink: data.videoLink,
            offers: data.offers,
            redirectUrl: data.redirectUrl,
            webDisplay: data.webDisplay,
            mobApp: data.mobApp,
            simWeb: data.simWeb,
            simMob: data.simMob,
            premiumEnable: data.premiumEnable,
            enquiryEnable: data.enquiryEnable,
            diversity: {
                sheBack: data.diversity.sheBack,
                armyProf: data.diversity.armyProf,
                phyAble: data.diversity.phyAble,
            },
            homePage: {
                web: data.homePage.web,
                mob: data.homePage.mob,
                webCat: data.homePage.webCat,
                mobCat: data.homePage.mobCat,
            },
            pushNotifTitle: data.pushNotifTitle,
            learningPartner: data.learningPartner,
            blacklistedCourse: data.blacklistedCourse || 0,
            ctaText: data.ctaText,
            showOnSimilarCoursesList: data.showOnSimilarCoursesList ? true : false,
            strictExperienceFilterEnabled: data.strictExperienceFilterEnabled ? true : false
        }));
        setTags(prev => ({
            ...prev,
            source: tagSource,
            selected: selectedTags
        }))
        setLogoCdn(processFileCdn(data.logoCdn, 'courselogo'))
        setBrochureCdn(processFileCdn(data.brochureCdn, 'coursebrochure'))
        setCourseImgCdn(processFileCdn(data.courseImgCdn, 'courseimage'))

        setIsLoading(false);
    };

    const sanitizeInput = (txt) => {
        let sanitized = (striptags(txt, '<p>,<a>,<b>,<u>,<i>,<br>,<br/>,<br />,<ul>,<li>', ''));

        sanitized = replacer(cleanIntroText(sanitized));

        sanitized = alterFormatText(sanitized);


        return sanitized;
    }

    const onInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onCheckboxChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        setDetails((prev) => ({
            ...prev,
            [name]: checked ? 1 : 0,
        }));
    };

    const onSimilarCourseCheckboxChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        const newDetails = {
            ...details,
            [name]: checked ? true : false,
        }

        if(name == 'showOnSimilarCoursesList' && !checked) {
            newDetails['strictExperienceFilterEnabled'] = false
        }

        setDetails(newDetails);
    }

    const onCheckboxDiversityChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        const newDiversity = {...details['diversity']};
        newDiversity[name] = checked ? 1 : 0;

        setDetails((prev) => ({
            ...prev,
            diversity: newDiversity
        }));
    };

    const onCheckboxHomePageChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        const newHomePage = {...details['homePage']};
        newHomePage[name] = checked ? 1 : 0;

        setDetails((prev) => ({
            ...prev,
            homePage: newHomePage
        }));
    };

    const validate = () => {
        const validations = {
            titleReq: checkEmpty(details.title),
            ctaTextReq: checkEmpty(details.ctaText),
            greaterErrorSal: parseInt(details.maxSal) < parseInt(details.minSal),
            maxExpLimit: parseInt(details.maxExp) < parseInt(details.minExp),
            maxExpReq: checkEmpty(details.maxExp) || details.maxExp == -1,
            minExpReq: details.minExp != 0 && checkEmpty(details.minExp) || details.minExp == -1,
            categoryReq: checkEmpty(details.category),
            pushNotifTitleReq: checkEmpty(details.pushNotifTitle),
            invalidEmail: !checkEmpty(details.notificationEmail) && !validator.isEmail(details.notificationEmail),
            invalidVideoLink: !checkEmpty(details.videoLink) && !validator.isURL(details.videoLink, {require_protocol: true}),
            invalidRedirectUrl: !checkEmpty(details.redirectUrl) && !validator.isURL(details.redirectUrl, {require_protocol: true}),
            invalidLearningPartner: !checkEmpty(details.learningPartner) && !validator.isURL(details.learningPartner, {require_protocol: true}),
            otherLocationReq: isOtherLocationSelected && checkEmpty(details.otherLocation),
            maxLogoSize: logoSize && !isValidSize(logoSize),
            maxBrochureSize: brochureSize && !isValidSize(brochureSize),
            maxCourseImgSize: courseImgSize && !isValidSize(courseImgSize),
            maxAttachmentSize: !isValidSize((logoSize || 0)+(brochureSize || 0) + (courseImgSize || 0), true),
            courseImgReq: !courseImgSize && !courseImgCdn,
            logoReq: !logoSize && !logoCdn
        };
        setValidations({ ...validations });

        for (let key in validations) {
            if (validations[key]) {
                scrollToElement(validationClass[key]);
                return false;
            }
        }
        return true;
    };

    const checkEmpty = (val) => {
        if (!val) return true;
        if (typeof val == "string" && validator.isEmpty(val)) return true;
        if( Array.isArray && val.length == 0 ) return true;
        return false;
    };

    const handleTextDropdownChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const keyPress = (event) => {
        if (event.key == "Enter") {
            event.preventDefault();
        }
    };

    const handlePillChange = (event, data) => {
        const stateName = "open" + data.name;
        if (data.maximumValues) {
            if (details[data.name].length < data.maximumValues - 1) {
                setPillOpen((prev) => ({
                    ...prev,
                    [stateName]: true,
                }));
            } else {
                setPillOpen((prev) => ({
                    ...prev,
                    [stateName]: false,
                }));
            }
        }

        const newDetails = {...details};
        newDetails[data.name] = data.value;

        if(data.name == 'location') {
            if(!data.value.includes('100000')) {
                newDetails['otherLocation'] = '';
                setIsOtherLocationSelected(false);
            }
            else {
                setIsOtherLocationSelected(true)
            }
        }
        setDetails(newDetails);
    };
    const onAddOtherLocation = (e, { value }) => {
        setLists((prev) => ({
            ...prev,
            location: [{ text: value, value }, ...prev.location],
        }));
    };

    const onClose = (event, data) => {
        const stateName = "open" + data.name;
        setPillOpen(prev => ({
            ...prev,
            [stateName]: false,
        }));
    };

    const onClick = (event, data) => {
        const stateName = "open" + data.name;
        if (data.maximumValues) {
            if (details[data.name].length <= data.maximumValues - 1) {
                setPillOpen((prev) => ({
                    ...prev,
                    [stateName]: true,
                }));
                return;
            } else {
                setPillOpen((prev) => ({
                    ...prev,
                    [stateName]: false,
                }));
                return;
            }
        }
        setPillOpen((prev) => ({
            ...prev,
            [stateName]: true,
        }));
    };

    const handleFileChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        let size = 0;
        if(file) {
            size = (file.size || 0) / (1024 * 1024).toFixed(2);
        }

        if(name == 'brochureFile'){
            setBrochureSize(size);
        }
        else if(name == 'logo') {
            setLogoSize(size);
        }
        else if(name == 'courseImage') {
            setCourseImgSize(size);
        }

        if(size > 4){
            return;
        }
        const newDetails = {
            ...details,
            [name]: file,
        };
        setDetails(newDetails);
    };

    const isValidSize = (fileSize, isCombined) => {
        if(isCombined && fileSize > 12) {
            return false;
        }
        if(!isCombined && fileSize > 4) {
           return false;
        }

        return true;
    }

    const addSuggestedTag = (event, tag, index) => {
        event.preventDefault();
        const selectedTags = [...tags.selected];
        const totalTags = tags.total;
        selectedTags[totalTags] = tag;
        setTags((prev) => ({
            ...prev,
            selected: selectedTags,
            total: totalTags + 1,
        }));
    };

    const copyToClipBoard = (event, tag) => {
        var el = document.createElement("textarea");
        el.value = tag.label ? tag.label : " ";
        el.innerText = tag.label;
        el.setAttribute("readonly", "");
        el.style = { position: "absolute", left: "-9999px" };
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setTags((prev) => ({
            ...prev,
            copied: true,
        }));
    };

    const handleResultSelect = (i, selectedOption) => {
        const selectedTags = [...tags.selected];
        let totalTags = tags.totalTags;
        selectedTags[i] = selectedOption;

        if (!selectedOption) {
            selectedTags.splice(i, 1);
            selectedTags[10] = {};

            totalTags--;
        } else {
            totalTags++;
        }
        setTags((prev) => ({
            ...prev,
            selected: selectedTags,
            total: totalTags,
        }));
    };

    const formatText = (text) => {
        text = text.replace(/<p><b><br\/><\/b><\/p>/g, '<p></p>')
        text = text.replace(/<p><br\/><\/p>/g, '<p></p>')

        return text;
    }

    const alterFormatText = (text) => {
        text = text.replace(/<p><\/p>/g, '<p><br/></p>')

        return text;
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault();

        if (validate()) {
            setUpdateLoader(true);
            const data = {
                ...details
            };
            
            const mediumArr = ['courseDetails','courseDeadlines', 'courseSchedule', 'studyCenter', 'desiredCandidate', 'courseModules'];

            mediumArr.forEach(el => {

                // if(mediumText[el]) {
                //     let formattedText =  replacer(mediumText[el])
                //     data[el] =  formattedText;

                // }
                if(mediumText[el]) {
                    let formattedText =  replacerCourse(mediumText[el])
                    data[el] =  formatText(formattedText);
                }
                else if(details[el]) {
                    let formattedText =  replacerCourse(details[el])
                    data[el] =  formatText(formattedText);

                }
                
            })


            delete data['logo'];
            delete data['brochureFile'];
            delete data['courseImage'];
    
            const keywords = [];
            tags.selected.forEach(tag => {
                if(tag['label']) {
                    keywords.push(tag['label'])
                }
            });
            
            data['keywords'] = keywords;

            const formData = new FormData();

            formData.append('logo', details['logo']);
            formData.append('brochure', details['brochureFile']);
            formData.append('courseImage', details['courseImage']);

            formData.append('details', JSON.stringify(data));

            
            try {
                if (type == 'post') {
                    await addCourse(admin.adminId, formData);
                    toast.success("Course added successfully");

                    setUpdateLoader(false);

                    setDetails(intitalDetails);

                    setTimeout(
                        () =>
                            props.history.push("/courseList"),
                        2000
                    );
                }
                else if (type == 'update') {
                    await updateCourse(admin.adminId, courseId, formData);

                    toast.success("Course updated successfully");

                    setUpdateLoader(false);
                    setTimeout(
                        () =>
                            props.history.push("/courseList"),
                        2000
                    );
                } 
                else if (type == 'publish') {
                    formData.append('publish', 1);
                    await updateCourse(admin.adminId, courseId, formData);

                    toast.success("Course published successfully");

                    setUpdateLoader(false);
                    setTimeout(
                        () =>
                            props.history.push("/courseList"),
                        2000
                    );
                } 
            } catch (e) {
                console.log(e);
                setUpdateLoader(false);

                if (e.message.includes("404")) {
                    return toast.error("Course not found");
                }
                toast.error("Something went wrong");
            }
        }

        
    }

    const processFileCdn = (cdn, endpoint) => {
        
        if(cdn && cdn.trim()) {
            let baseUrl = `${process.env.REACT_APP_COURSE_CDN_BASE_URL}/${endpoint}/`;
            let date = cdn.substring(0, 10);
            date = date.replace(/-/g, '/')
            baseUrl += date + '/' + cdn;
            return baseUrl;
        }
        
        return '';

    }

    const handleMediumEditor = (text, medium, name) => {
        
        var desc = (striptags(text, '<p>,<a>,<b>,<u>,<i>,<br>,<br/>,<br />,<ul>,<li>', '\n'));
        
        setMediumText(prev => ({
            ...prev,
            [name]: desc
        }))
           }

    return (
        <div className="addEditCourseContainer">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

    
            {isLoading ? (
                <Loader />
            ) : (
                <div className="pageContent">
                    <div className="editJob">

                        <div className="course-add-heading">
                            <Heading text={courseId ? 'Edit Course' : 'Add Course'} />
                            
                            <Button  skin='dark' className="btn_course_mapping" text="Course List" clickButton={() => { props.history.push('/courseList') }}  />

                            <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/course_admin_new.php`} target='_blank'>
                                <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
                            </a>

                        </div>
                        <hr />

                        <form>
                            <div className="form-group title">
                                <label className="col-sm-2 left">
                                    Course Title*
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.title}
                                        name="title"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.titleReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.titleReq}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group oneLiner">
                                <label className="col-sm-2 left">
                                    One Liner
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.oneLiner}
                                        name="oneLiner"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div>

                            <div className="form-group courseType">
                                <label className="col-sm-2 left">
                                    Course Type
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <Dropdown
                                        data={courseTypeCourses}
                                        name="courseType"
                                        defaultValue={details.courseType}
                                        dropdownChange={
                                            handleTextDropdownChange
                                        }
                                    />
                                    {/* {validations.courseType ? (
                                        <p className="validatorError">
                                            Please select Course Type{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group approved">
                                <label className="col-sm-2 left">
                                    Approved
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.approved}
                                        name="approved"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div>

                            <div className="form-group institute">
                                <label className="col-sm-2 left">
                                    Institute
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.institute}
                                        name="institute"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div>

                            <div className="form-group location">
                                <label className="col-sm-2 left">
                                    Location
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <PillButton
                                        data={lists.location}
                                        placeholder="Location"
                                        name="location"
                                        onChange={handlePillChange}
                                        open={pillOpen.openlocation}
                                        onClick={onClick}
                                        onClose={onClose}
                                        defaultValue={details.location}
                                        onAddOtherLocation={onAddOtherLocation}
                                    />
                                    {/* {validations.location ? (
                                        <p className="validatorError">
                                            {" "}
                                            Please Enter Location{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group otherLocation">
                                <label className="col-sm-2 left">
                                    Other Location
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.otherLocation}
                                        name="otherLocation"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                        disabled={isOtherLocationSelected ? false : true}
                                    />{" "}
                                    {validations.otherLocationReq ? (
                                        <p className="validatorError">
                                            {validationMsg['otherLocationReq']}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group category">
                                <label className="col-sm-2 left">
                                    Category *
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    
                                    <PillButton
                                        data={lists.category}
                                        defaultValue={details.category}
                                        placeholder="Category"
                                        name="category"
                                        onChange={handlePillChange}
                                        maximumValues={5}
                                        open={pillOpen.opencategory}
                                        onClick={onClick}
                                        onClose={onClose}
                                    />
                                    {validations.categoryReq ? (
                                        <p className="validatorError">
                                            {validationMsg['categoryReq']}
                                        </p>
                                    ) : null}
                                    <label className="block">
                                        <InputBox
                                            type="checkbox"
                                            value={details.categoryStatus}
                                            checked={
                                                details.categoryStatus ? true : false
                                            }
                                            name="categoryStatus"
                                            onChange={onCheckboxChange}
                                        />{" "}
                                        Select to Enable All selected categories
                                    </label>
                                </div>
                            </div>

                            {["iimjobs", "updazz"].includes(
                                process.env.REACT_APP_PLATFORM
                            ) ? (
                                <div className="form-group industry">
                                    <label className="col-sm-2 left">
                                        Industry
                                    </label>
                                    <div className="col-sm-6 left valueBox">
                                        <PillButton
                                            data={lists.industry}
                                            defaultValue={details.industry}
                                            placeholder="Industry"
                                            name="industry"
                                            onChange={handlePillChange}
                                            maximumValues={5}
                                            open={pillOpen.openindustry}
                                            onClick={onClick}
                                            onClose={onClose}
                                        />
                                        <span>
                                            You can select upto 5 industries.
                                        </span>
                                        {validations.industry ? (
                                            <p className="validatorError">
                                                {" "}
                                                Please select at least one
                                                industry{" "}
                                            </p>
                                        ) : null}
                                        {/* <label className="block">
                                            <InputBox
                                                type="checkbox"
                                                value={details.directCompany}
                                                checked={
                                                    details.directCompany
                                                        ? true
                                                        : false
                                                }
                                                name="directCompany"
                                                onChange={onCheckboxChange}
                                            />{" "}
                                            Direct from company
                                        </label> */}
                                    </div>
                                </div>
                            ) : null}

                            {/* <div className="form-group companyName">
                                <label className="col-sm-2 left">
                                    Company Name
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.companyName}
                                        name="companyName"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div> */}

                            <div className="form-group sal">
                                <label className="col-sm-2 left">Salary</label>
                                <div className="col-sm-6 left valueBox">
                                    <div className="block-dropdown-container">
                                        <div className="block-dropdown">
                                            <Dropdown
                                                data={minSalary}
                                                name="minSal"
                                                defaultValue={details.minSal}
                                                dropdownChange={onInputChange}
                                            />
                                        </div>
                                        <div className="block-dropdown right-dropdown">
                                            <Dropdown
                                                data={maxSalary}
                                                name="maxSal"
                                                defaultValue={details.maxSal}
                                                dropdownChange={onInputChange}
                                            />
                                            {validations.greaterErrorSal ? (
                                            <p className="validatorError">
                                                {" "}
                                                Maximum salary should be greater
                                                than minimum salary.{" "}
                                            </p>
                                            ) : null}
                                        </div>
                                        <span>in lakhs per annum</span>

                                    </div>
                                    
                                    <label className="block">
                                        <InputBox
                                            type="checkbox"
                                            value={details.showSalary}
                                            checked={
                                                details.showSalary ? true : false
                                            }
                                            name="showSalary"
                                            onChange={onCheckboxChange}
                                        />{" "}
                                        Don't show this to candidates
                                    </label>
                                </div>
                            </div>

                            <div className="form-group exp">
                                <label className="col-sm-2 left">
                                    Yrs of exp*
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <div className="block-dropdown">
                                        <Dropdown
                                            dropdownChange={onInputChange}
                                            name="minExp"
                                            defaultValue={details.minExp}
                                            data={minExperience}
                                        />
                                        {validations.minExpReq ? (
                                            <p className="validatorError">
                                                {validationMsg.minExpReq}.{" "}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="block-dropdown right-dropdown">
                                        <Dropdown
                                            dropdownChange={onInputChange}
                                            defaultValue={details.maxExp}
                                            name="maxExp"
                                            data={maxExperience}
                                        />
                                        
                                        {validations.maxExpReq ? (
                                            <p className="validatorError">
                                                {validationMsg.maxExpReq}.{" "}
                                            </p>
                                        ) : null}
                                        {!validations.maxExpReq && validations.maxExpLimit ? (
                                            <p className="validatorError">
                                                {" "}
                                                {validationMsg.maxExpLimit}{" "}
                                            </p>
                                        ) : null}
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="form-group fee">
                                <label className="col-sm-2 left">Fee</label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.fee}
                                        name="fee"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div>

                            <div className="form-group duration">
                                <label className="col-sm-2 left">
                                    Duration
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.duration}
                                        name="duration"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                </div>
                            </div>

                            <div className="form-group deliveryMethod">
                                <label className="col-sm-2 left">
                                    Delivery Method
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <Dropdown
                                        data={deliveryMethodObj}
                                        name="deliveryMethod"
                                        defaultValue={details.deliveryMethod}
                                        dropdownChange={
                                            handleTextDropdownChange
                                        }
                                    />
                                    {/* {validations.deliveryMethod ? (
                                        <p className="validatorError">
                                            Please select Course Type{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group regDate">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Registration Date
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <FromToDatePicker
                                        name="date"
                                        startDate={details.regStartDate}
                                        endDate={details.regEndDate}
                                        handleChangeStart={(e) => {
                                            setDetails({
                                                ...details,
                                                regStartDate: e,
                                            });
                                        }}
                                        handleChangeEnd={(e) => {
                                            setDetails({
                                                ...details,
                                                regEndDate: e,
                                            });
                                        }}
                                    />
                                    {/* {validations.dateReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.dateReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group courseDate">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Close Date
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <FromToDatePicker
                                        name="date"
                                        startDate={details.courseStartDate}
                                        endDate={details.courseEndDate}
                                        handleChangeStart={(e) => {
                                            setDetails({
                                                ...details,
                                                courseStartDate: e,
                                            });
                                        }}
                                        handleChangeEnd={(e) => {
                                            setDetails({
                                                ...details,
                                                courseEndDate: e,
                                            });
                                        }}
                                    />
                                    {/* {validations.dateReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.dateReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group emailNotif">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Email Notification
                                </label>
                                <div className="col-sm-6 left valueBox emailNotif">
                                    <div>
                                        <InputBox
                                            type="radio"
                                            value="all"
                                            checked={
                                                details.notification ==
                                                "all"
                                            }
                                            name="notification"
                                            onChange={onInputChange}
                                        />{" "}
                                        All
                                    </div>
                                    <div>
                                        <InputBox
                                            type="radio"
                                            value="exp"
                                            checked={
                                                details.notification ==
                                                "exp"
                                            }
                                            name="notification"
                                            onChange={onInputChange}
                                        />{" "}
                                        Experience
                                    </div>

                                    {/* {validations.activityReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.activityReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group expGT">
                                <label className="col-sm-2 left">
                                    Exp Greater
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.expGT}
                                        name="expGT"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {/* {validations.expGT ? (
                                        <p className="validatorError">
                                            {" "}
                                            Please Enter Title{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group expLT">
                                <label className="col-sm-2 left">
                                    Exp Less
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.expLT}
                                        name="expLT"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {/* {validations.expLT ? (
                                        <p className="validatorError">
                                            {" "}
                                            Please Enter Title{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group notificationEmail">
                                <label className="col-sm-2 left">
                                    Email Id for Notification
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.notificationEmail}
                                        name="notificationEmail"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.invalidEmail ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg['invalidEmail']}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group courseDetails">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Course Detail
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter course detail"
                                        name="courseDetails"
                                        value={details.courseDetails}
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'courseDetails')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.courseDetailReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group courseDeadlines">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Course Deadlines
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter course deadlines"
                                        className="inputBox new-ce-testimonial"
                                        name="courseDeadlines"
                                        value={details.courseDeadlines}
                                        rows="5"
                                        maxLength="500"
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'courseDeadlines')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.courseDeadlinesReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group courseSchedule">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Course Schedule
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter course schedule"
                                        className="inputBox new-ce-testimonial"
                                        name="courseSchedule"
                                        value={details.courseSchedule}
                                        rows="5"
                                        maxLength="500"
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'courseSchedule')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.courseDetailReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group studyCenter">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Study Center
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter study center"
                                        className="inputBox new-ce-testimonial"
                                        name="studyCenter"
                                        value={details.studyCenter}
                                        rows="5"
                                        maxLength="500"
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'studyCenter')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.studyCenterReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group desiredCandProfile">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Desired Candidate Profiles
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter desired candidate profile"
                                        className="inputBox new-ce-testimonial"
                                        name="desiredCandidate"
                                        value={details.desiredCandidate}
                                        rows="5"
                                        maxLength="500"
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'desiredCandidate')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.desiredCandidateReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group courseModules">
                                <label className="col-sm-2 left">
                                    {" "}
                                    Course Modules
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <MediumEditor
                                        placeholder="Enter course modules"
                                        className="inputBox new-ce-testimonial"
                                        name="courseModules"
                                        value={details.courseModules}
                                        rows="5"
                                        maxLength="500"
                                        onChange={(text, medium) => {
                                            handleMediumEditor(text, medium, 'courseModules')
                                        }}
                                    ></MediumEditor>
                                    {/* {validations.courseModulesReq ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg.courseDetailReq}{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group videoLink">
                                <label className="col-sm-2 left">
                                    Video Link
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.videoLink}
                                        name="videoLink"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.invalidVideoLink ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg['invalidUrl']}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group courseLogo">
                                <label className="col-sm-2 left">Logo*</label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="file"
                                        name="logo"
                                        onChange={handleFileChange}
                                        onKeyPress={keyPress}
                                        accept="image/gif, image/jpg, image/jpeg, image/pjpeg, image/bmp, image/png"
                                    />{" "}
                                    {validations.maxLogoSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxLogoSize']}
                                        </p>
                                    ) : null}
                                    {logoSize && validations.maxAttachmentSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxAttachmentSize']}
                                        </p>
                                    ) : null}
                                    {validations.logoReq ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['logoReq']}
                                        </p>
                                    ) : null}
                                    
                                </div>
                                <div className="logo-container"> {
                                    details.logo || logoCdn ? (
                                        <img src= {details.logo ? URL.createObjectURL(details.logo) : logoCdn ? logoCdn : null}/>

                                    ): null
                                }
                                </div>
                            </div>

                            <div className="form-group brochure">
                                <label className="col-sm-2 left">
                                    Brochure
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="file"
                                        name="brochureFile"
                                        onChange={handleFileChange}
                                        onKeyPress={keyPress}
                                        accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />{" "}
                                    {validations.maxBrochureSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxBrochureSize']}
                                        </p>
                                    ) : null}
                                    {brochureSize && validations.maxAttachmentSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxAttachmentSize']}
                                        </p>
                                    ) : null}
                                    
                                </div>
                                <div className="brochure-container">
                                    {
                                        brochureCdn ? (
                                            <a href={brochureCdn} target='_blank'>Brochure File</a>
                                            ): null
                                    }
                                </div>
                            </div>

                            <div className="form-group offers">
                                <label className="col-sm-2 left">Offers</label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.offers}
                                        name="offers"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {/* {validations.offers ? (
                                        <p className="validatorError">
                                            {" "}
                                            Please Enter Title{" "}
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="form-group redirectUrl">
                                <label className="col-sm-2 left">
                                    Redirect Url
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.redirectUrl}
                                        name="redirectUrl"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.invalidRedirectUrl ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg['invalidUrl']}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            {otherOptions.map((option) => {
                                return (
                                    <div
                                        className={`form-group ${option.name}`}
                                    >
                                        <div className="left col-sm-2 check-input">
                                            <InputBox
                                                type="checkbox"
                                                value={details[option.name]}
                                                checked={
                                                    details[option.name]
                                                        ? true
                                                        : false
                                                }
                                                name={option.name}
                                                onChange={onCheckboxChange}
                                            />{" "}
                                            <br />
                                            {validations[option.name] ? (
                                                <p className="validatorError">
                                                    {" "}
                                                    Please Enter Title{" "}
                                                </p>
                                            ) : null}
                                        </div>
                                        <label className="col-sm-2">
                                            {option.text}
                                        </label>
                                    </div>
                                );
                            })}

                            <div className="form-group">
                                <label className="col-sm-2 left">
                                    Diversity
                                </label>
                            </div>
                            {diversityOptions.map((option) => {
                                return (
                                    <div
                                        className={`form-group ${option.name}`}
                                    >
                                        <div className="col-sm-2 left check-input">
                                            <InputBox
                                                type="checkbox"
                                                value={details['diversity'][option.name]}
                                                checked={
                                                    details['diversity'][option.name]
                                                        ? true
                                                        : false
                                                }
                                                name={option.name}
                                                onChange={onCheckboxDiversityChange}
                                            />{" "}
                                            <br />
                                            {validations[option.name] ? (
                                                <p className="validatorError">
                                                    {" "}
                                                    Please Enter Title{" "}
                                                </p>
                                            ) : null}
                                        </div>
                                        <label className="col-sm-2">
                                            {option.text}
                                        </label>
                                    </div>
                                );
                            })}

                            <div className="form-group">
                                <label className="col-sm-2 left">
                                    Home Page
                                </label>
                            </div>
                            {homePageOptions.map((option) => {
                                return (
                                    <div
                                        className={`form-group ${option.name}`}
                                    >
                                        <div className="col-sm-2 left check-input">
                                            <InputBox
                                                type="checkbox"
                                                value={details['homePage'][option.name]}
                                                checked={
                                                    details['homePage'][option.name]
                                                        ? true
                                                        : false
                                                }
                                                name={option.name}
                                                onChange={onCheckboxHomePageChange}
                                            />{" "}
                                            <br />
                                            {validations[option.name] ? (
                                                <p className="validatorError">
                                                    {" "}
                                                    Please Enter Title{" "}
                                                </p>
                                            ) : null}
                                        </div>
                                        <label className="col-sm-2">
                                            {option.text}
                                        </label>
                                    </div>
                                );
                            })}

                            <div className="form-group pushNotifTitle">
                                <label className="col-sm-2 left">
                                    Push Notification Title *
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.pushNotifTitle}
                                        name="pushNotifTitle"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.pushNotifTitleReq ? (
                                        <p className="validatorError">
                                            {validationMsg['pushNotifTitleReq']}
                                           
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group learningPartner">
                                <label className="col-sm-2 left">
                                    Learning Partner
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.learningPartner}
                                        name="learningPartner"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    <br />
                                    {validations.invalidLearningPartner ? (
                                        <p className="validatorError">
                                            {" "}
                                            {validationMsg['invalidUrl']}{" "}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group courseImage">
                                <label className="col-sm-2 left">Campus Image*</label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="file"
                                        name="courseImage"
                                        onChange={handleFileChange}
                                        onKeyPress={keyPress}
                                        accept="image/gif, image/jpg, image/jpeg, image/pjpeg, image/bmp, image/png"
                                    />{" "}
                                    {validations.maxCourseImgSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxCourseImgSize']}
                                        </p>
                                    ) : null}
                                    {courseImgSize && validations.maxAttachmentSize ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['maxAttachmentSize']}
                                        </p>
                                    ) : null}
                                    {validations.courseImgReq ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['courseImgReq']}
                                        </p>
                                    ) : null}
                                    
                                </div>
                                <div className="logo-container"> {
                                    details.courseImage || courseImgCdn ? (
                                        <img src= {details.courseImage ? URL.createObjectURL(details.courseImage) : courseImgCdn ? courseImgCdn : null}/>

                                    ): null
                                }
                                </div>
                            </div>

                            <div className="form-group ctaText">
                                <label className="col-sm-2 left">
                                    CTA Text*
                                </label>
                                <div className="col-sm-6 left valueBox">
                                    <InputBox
                                        type="text"
                                        value={details.ctaText}
                                        name="ctaText"
                                        onChange={handleTextDropdownChange}
                                        onKeyPress={keyPress}
                                    />{" "}
                                    {validations.ctaTextReq ? (
                                        <p className="validatorError">
                                            
                                            {validationMsg['ctaTextReq']}
                                        </p>
                                    ) : null}
                                    <br />
                                    

                                    
                                </div>
                                
                                    
                            </div>

                            {
                                ['hirist','iimjobs'].includes(process.env.REACT_APP_PLATFORM) ? (
                                    <>
                                        <div className="form-group showSimilarCourse">
                                            <label className="col-sm-2 left">
                                                Show on Similar Course
                                            </label>
                                            <div className="col-sm-6 left valueBox">
                                                <InputBox
                                                    type="checkbox"
                                                    value={details.showOnSimilarCoursesList}
                                                    checked={
                                                        details.showOnSimilarCoursesList
                                                            ? true
                                                            : false
                                                    }
                                                    name='showOnSimilarCoursesList'
                                                    onChange={onSimilarCourseCheckboxChange}
                                                />{" "}
                                            
                                                <br />
                                            </div>
                                        </div>

                                        <div className="form-group strictExpFilter">
                                            <label className="col-sm-2 left">
                                                Strict Experience Filter
                                            </label>
                                            <div className="col-sm-6 left valueBox">
                                                <InputBox
                                                    type="checkbox"
                                                    disabled = {
                                                        !details.showOnSimilarCoursesList
                                                    }
                                                    value={details.strictExperienceFilterEnabled}
                                                    checked={
                                                        details.strictExperienceFilterEnabled
                                                            ? true
                                                            : false
                                                    }
                                                    name='strictExperienceFilterEnabled'
                                                    onChange={onSimilarCourseCheckboxChange}
                                                />{" "}
                                                <br />
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }

                           

                            <div className='submitButtonContainer'>
                                    {
                                        updateLoader ? <Spinner /> : (
                                            <>
                                                <label className="block">
                                                    <InputBox
                                                        type="checkbox"
                                                        value={details.blacklistedCourse}
                                                        checked={
                                                            details.blacklistedCourse ? true : false
                                                        }
                                                        name="blacklistedCourse"
                                                        onChange={onCheckboxChange}
                                                    />{" "}
                                                    Blacklist course
                                                </label>
                                                {!courseId ? (
                                                    <Aux>
                                                        <Button skin='dark' disabled={false} text='Submit' clickButton={(e) => handleSubmit(e, 'post')} />
                                                        <Button skin='light' disabled={false} text='Cancel' clickButton={(e) => handleSubmit(e, '')} />
                                                    </Aux>
                                                ) : (
                                                        <Aux>
                                                            <Button skin='dark' disabled={false} text='Update' clickButton={(e) => handleSubmit(e, 'update')} />
                                                            <Button skin='dark' disabled={false} text='Update+publish' clickButton={(e) => handleSubmit(e, 'publish')} />
                                                        </Aux>
                                                    )}
                                            </>
                                        )
                                    }

                                </div>
                        </form>
                    </div>

                    <div className="editJobTags">
                        <SuggestedTags
                            postJob={true}
                            selectedTags={tags['selected']}
                            totalTags={tags['selected'].length}
                            addSuggestedTag={addSuggestedTag}
                            copyToClipBoard={copyToClipBoard}
                            copied={tags.copied}
                            handleResultSelect={handleResultSelect}
                            allTags={tags.source}
                            isCourse = {true}
                        />
                    </div>


                </div>
            )}
        </div>
    );
};

export default AddEdit;
