var degree=[
    {
      "val": 1,
      "text": "MBA / PGDM"
    },
    {
      "val": 2,
      "text": "CA"
    },
    {
      "val": 3,
      "text": "CS"
    },
    {
      "val": 4,
      "text": "ICWA"
    },
    {
      "val": 5,
      "text": "LLB / LLM"
    },
    {
      "val": 6,
      "text": "B Tech / BE"
    },
    {
      "val": 21,
      "text": " BArch"
    },
    {
      "val": 7,
      "text": "MBBS"
    },
    {
      "val": 8,
      "text": "BSc"
    },
    {
      "val": 9,
      "text": "BA"
    },
    {
      "val": 10,
      "text": "Bcom"
    },
    {
      "val": 11,
      "text": "BBA"
    },
    {
      "val": 19,
      "text": "BCA"
    },
    {
      "val": 13,
      "text": "PhD"
    },
    {
      "val": 14,
      "text": "MSc / MS / M Tech"
    },
    {
      "val": 20,
      "text": "MCA"
    },
    {
      "val": 15,
      "text": "MA"
    },
    {
      "val": 16,
      "text": "Integrated Masters Program"
    },
    {
      "val": 17,
      "text": "CFA"
    },
    {
      "val": 18,
      "text": "FRM"
    },
    {
      "val": 22,
      "text": "CWM"
    },
    {
      "val": 23,
      "text": "AFA"
    },
    {
      "val": 27,
      "text": "PGDDA"
    }
]


export function getDegrees() {

    return degree.map(item => {
        return {
            label: item.text,
            value: item.val.toString(),
            key: item.val
        }
    })
}