import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './InputBox.scss';

class InputBox extends Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        return nextProps.value !== this.props.value || nextProps.checked !== this.props.checked || nextProps.className !== this.props.className
    }
    render() {
        return(
            <input className={`inputBox ${this.props.className || ''}`} type={this.props.type} checked={this.props.checked} disabled={this.props.disabled} value={this.props.value} name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} onKeyPress={this.props.onKeyPress} ref={this.props._ref} {...this.props.options} ></input>
        )
    }
}

InputBox.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([ 'text', 'checkbox', 'number', 'radio', 'email', 'file', 'email', 'password']),
    name: PropTypes.string,
    checked: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyPress:PropTypes.func,
    onKeyDown:PropTypes.func,
    disabled: PropTypes.bool,
    _ref: PropTypes.object,
    options: PropTypes.object,
}

export default InputBox;