import React from "react";

import Button from "../../../components/Button";

import Heading from "../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var scrollToElement = require("scroll-to-element");

import user from "../../../store/user";

import validator from "validator";

import "./showcaseReport.scss";
import "../../../themes/grid.scss";

import fetchActiveShowcaseCompanies from "../../../services/fetchActiveShowcaseCompanies";

import FromToDatePicker from "../../../components/FromToDatepicker/FromToDatepicker";
import PillButton from "../../../components/PillButton";

import moment from "moment";
import { key } from "localforage";
import { adminFeatureMapping } from "../../../models/adminFeatureMapping";

const VIEW_ID = process.env.REACT_APP_ANALYSIS_VIEWID;
const SHOWCASE_VIEW_ID = process.env.REACT_APP_ANALYSIS_SHOWCASE_VIEWID;
const DIVERSITY_VIEW_ID = process.env.REACT_APP_ANALYSIS_DIVERSITY_VIEWID;

const platform = process.env.REACT_APP_PLATFORM;
var completeAnalyticsData = [];

var count = 0;
var index = 0;

const headers = {
  CompanyName: "Company Name",
  CompanyKeyWords: "Company KeyWords",
  FirebaseEventCount: "Firebase Event Count",
  FirebaseUsers: "Firebase Users",
  GAiimjobsShowcasePageViews: "GA iimjobs Showcase PageViews",
  GAiimjobsShowcaseUniquePageViews: "GA iimjobs Showcase Unique PageViews",
  GAiimjobsShowcaseAverageTimeOnPage: "GA iimjobs Showcase Average Time On Page",
  GAhiristShowcasePageViews: "GA hirist Showcase PageViews",
  GAhiristShowcaseUniquePageViews: "GA hirist Showcase Unique PageViews",
  GAhiristShowcaseAverageTimeOnPage: "GA hirist Showcase AverageTime On Page",
  GAiimjobsPageViews: `GA ${platform} PageViews`,
  GAiimjobsUniquePageViews: `GA ${platform} UniquePageViews`,
  GAiimjobsAvgTimeOnPage: `GA ${platform} Avg Time On Page`,
  FirebaseDiversityEventCount: "Firebase Diversity Event Count",
  GADiversityPageViews: "GA Diversity PageViews",
  GADiversityAverageTimeOnPage: "GA Diversity Average Time On Page",
  GAiimjobsDiversityTotalEvents: `GA ${platform} Diversity Total Events`,
  ShowcasePrice: 'Showcase Price',
  DiversityPrice: 'Diversity Price'
};

const headersNoFirebase = {
  CompanyName: "Company Name",
  CompanyKeyWords: "Company KeyWords",
  GAiimjobsShowcasePageViews: "GA iimjobs Showcase PageViews",
  GAiimjobsShowcaseUniquePageViews: "GA iimjobs Showcase Unique PageViews",
  GAiimjobsShowcaseAverageTimeOnPage: "GA iimjobs Showcase Average Time On Page",
  GAhiristShowcasePageViews: "GA hirist Showcase PageViews",
  GAhiristShowcaseUniquePageViews: "GA hirist Showcase Unique PageViews",
  GAhiristShowcaseAverageTimeOnPage: "GA hirist Showcase Average Time On Page",
  GAiimjobsPageViews: `GA ${platform} PageViews`,
  GAiimjobsUniquePageViews: `GA ${platform} UniquePageViews`,
  GAiimjobsAvgTimeOnPage: `GA ${platform} Avg Time On Page`,
  FirebaseDiversityEventCount: "Firebase Diversity Event Count",
  GADiversityPageViews: "GADiversity PageViews",
  GADiversityAverageTimeOnPage: "GA Diversity Average Time On Page",
  GAiimjobsDiversityTotalEvents: `GA ${platform} Diversity Total Events`,
  ShowcasePrice: 'Showcase Price',
  DiversityPrice: 'Diversity Price'
};

const headersNoDiversityFirebase = {
  CompanyName: "Company Name",
  CompanyKeyWords: "Company KeyWords",
  FirebaseEventCount: "Firebase EventCount",
  FirebaseUsers: "Firebase Users",
  GAiimjobsShowcasePageViews: "GA iimjobs Showcase PageViews",
  GAiimjobsShowcaseUniquePageViews: "GA iimjobs Showcase Unique PageViews",
  GAiimjobsShowcaseAverageTimeOnPage: "GA iimjobs Showcase Average Time On Page",
  GAhiristShowcasePageViews: "GA hirist Showcase Page Views",
  GAhiristShowcaseUniquePageViews: "GA hirist Showcase Unique PageViews",
  GAhiristShowcaseAverageTimeOnPage: "GA hirist Showcase Average Time On Page",
  GAiimjobsPageViews: `GA ${platform} PageViews`,
  GAiimjobsUniquePageViews: `GA ${platform} Unique PageViews`,
  GAiimjobsAvgTimeOnPage: `GA ${platform} Avg Time On Page`,
  GADiversityPageViews: "GA Diversity PageViews",
  GADiversityAverageTimeOnPage: "GA Diversity Average Time On Page",
  GAiimjobsDiversityTotalEvents: `GA ${platform} Diversity Total Events`,
  ShowcasePrice: 'Showcase Price',
  DiversityPrice: 'Diversity Price'
};

const headersOnlyGA = {
  CompanyName: "Company Name",
  CompanyKeyWords: "Company KeyWords",
  GAiimjobsShowcasePageViews: "GA iimjobs Showcase PageViews",
  GAiimjobsShowcaseUniquePageViews: "GA iimjobs Showcase Unique PageViews",
  GAiimjobsShowcaseAverageTimeOnPage: "GA iimjobs Showcase Average Time On Page",
  GAhiristShowcasePageViews: "GA hirist Showcase PageViews",
  GAhiristShowcaseUniquePageViews: "GA hirist Showcase Unique PageViews",
  GAhiristShowcaseAverageTimeOnPage: "GA hirist Showcase Average Time On Page",
  GAiimjobsPageViews: `GA ${platform} PageViews`,
  GAiimjobsUniquePageViews: `GA ${platform} Unique PageViews`,
  GAiimjobsAvgTimeOnPage: `GA ${platform} AvgTime On Page`,
  GADiversityPageViews: "GA Diversity PageViews",
  GADiversityAverageTimeOnPage: "GA Diversity Average Time On Page",
  GAiimjobsDiversityTotalEvents: `GA ${platform} Diversity Total Events`,
  ShowcasePrice: 'Showcase Price',
  DiversityPrice: 'Diversity Price'
};

const keywords = ['firebaseKeywords', 'gtagKeywords', 'jobKeywords', 'diversityFirebaseKeywords', 'diversityGtagKeywords', 'diversityJobKeywords']
var firebaseCSV = null;
var diversityFirebaseCSV = null;

const validationMsg = {
  companyNameReq: "Name is Required",
  startDateReq: "Start Date Range is Required",
  endDateReq: "End Date Range is Required"
};

const validationClass = {
  companyNameReq: ".companyName",
  startDate: ".date",
  endDate: ".date"
};

class ShowcaseReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        companyName: "",
        company: [],
      },
      list: [],
      selectedCompaniesList: [],
      companies: [],
      id: null,
      startDate: "",
      endDate: "",
      viewAccess: false,
      validations: {},
      submitApiCall: false,
      firebaseStartDate: "",
      firebaseEndDate: "",
      diversityFirebaseStartDate: "",
      diversityFirebaseEndDate: "",
      openselectedCompaniesList: false,
      currCompanyList: []
    };
    this.reactTags = React.createRef();
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.currentCompanyData = {

    };
  }

  async componentDidMount() {
    const profile = await user.getProfile();
    const adminId = profile.id;
    const type = profile.type;

    if (this.props.location.state) company = this.props.location.state.company;

    // const viewAccess = process.env.REACT_APP_SHOWCASE_COMPANIES_REPORTING_ACCESS_EMAILS.includes(
    //   profile.email.toLowerCase()
    // );

    this.setState({
      viewAccess: user.checkFeatureAccess(adminFeatureMapping.SHOWCASE_REPORT),
    });

    fetchActiveShowcaseCompanies(adminId).then(async (resp) => {
      const companies = resp.data.data.data.map((company, i) => {
        return {
          id: company.id,
          company: company,
        };
      });
      const currCompanyList = companies;
      const list = resp.data.data.data.map((company, i) => {
        return {
          text: company.companyName,
          value: company.id,
          key: company.id,
        };
      });
      this.setState({ list, companies, currCompanyList });
    });

    var script = document.createElement("script");
    script.type = "text/javascript"; // create a script DOM node
    script.src = "https://apis.google.com/js/client:platform.js"; // set its src to the provided URL
    document.head.appendChild(script);
  }

  onChange(key, ev) {
    try {
      firebaseCSV = ev.target.files[0];

      const reader = new FileReader();

      reader.onload = async (event) => {
        const file = event.target.result;
        const allLines = file.split(/\r\n|\n/);
        // Reading line by line

        allLines.forEach((line) => {
          if (line.includes("Start")) {
            var lineSplit = line.split(" ");
            var startDateString = lineSplit[lineSplit.length - 1];

            var firebaseStartDate =
              startDateString.substring(0, 4) +
              "-" +
              startDateString.substring(4, 6) +
              "-" +
              startDateString.substring(6);

            this.setState({ firebaseStartDate });
          }

          if (line.includes("End")) {
            var lineSplit = line.split(" ");
            var endDateString = lineSplit[lineSplit.length - 1];

            var firebaseEndDate =
              endDateString.substring(0, 4) +
              "-" +
              endDateString.substring(4, 6) +
              "-" +
              endDateString.substring(6);

            this.setState({ firebaseEndDate });

            reader.abort();
          }
        });
      };

      reader.onerror = (event) => {
        toast.error(event.target.error.name);
      };

      reader.readAsText(firebaseCSV);
    } catch (e) {
      this.setState({ firebaseStartDate: '', firebaseEndDate: '' })
      firebaseCSV = null;
    }
  }

  onDiversityChange(key, ev) {
    try {
      diversityFirebaseCSV = ev.target.files[0];

      const reader = new FileReader();

      reader.onload = async (event) => {
        const file = event.target.result;
        const allLines = file.split(/\r\n|\n/);
        // Reading line by line

        allLines.forEach((line) => {
          if (line.includes("Start")) {
            var lineSplit = line.split(" ");
            var startDateString = lineSplit[lineSplit.length - 1];

            var diversityFirebaseStartDate =
              startDateString.substring(0, 4) +
              "-" +
              startDateString.substring(4, 6) +
              "-" +
              startDateString.substring(6);

            this.setState({ diversityFirebaseStartDate });
          }

          if (line.includes("End")) {
            var lineSplit = line.split(" ");
            var endDateString = lineSplit[lineSplit.length - 1];

            var diversityFirebaseEndDate =
              endDateString.substring(0, 4) +
              "-" +
              endDateString.substring(4, 6) +
              "-" +
              endDateString.substring(6);

            this.setState({ diversityFirebaseEndDate });

            reader.abort();
          }
        });
      };

      reader.onerror = (event) => {
        alert(event.target.error.name);
      };

      reader.readAsText(diversityFirebaseCSV);
    } catch (e) {
      this.setState({ diversityFirebaseStartDate: '', diversityFirebaseEndDate: '' })
      diversityFirebaseCSV = null;
    }
  }

  onClose = (event, data) => {
    const open = { ...this.state.open };
    open[data.name] = false;
    this.setState({
      open,
    });
  };

  validate() {
    const { data } = this.state;

    let validations = {
      startDateReq: this.state.startDate == "" ? true : false,
      endDateReq: this.state.endDate == "" ? true : false,
    };
    this.setState({ validations });
    for (let key in validations) {
      if (validations[key]) {
        scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;
  }

  checkEmpty(val) {
    if (!val) return true;
    if (Array.isArray(val) && !val.length) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  async onSubmit(e) {
    e.preventDefault();

    completeAnalyticsData = [];
    this.currentCompanyData = {
      CompanyName: '',
      CompanyKeyWords: '',
      ...(firebaseCSV && { FirebaseEventCount: '' }),
      ...(firebaseCSV && { FirebaseUsers: '' }),
      GAiimjobsShowcasePageViews: '',
      GAiimjobsShowcaseUniquePageViews: '',
      GAiimjobsShowcaseAverageTimeOnPage: '',
      GAhiristShowcasePageViews: '',
      GAhiristShowcaseUniquePageViews: '',
      GAhiristShowcaseAverageTimeOnPage: '',
      GAiimjobsPageViews: '',
      GAiimjobsUniquePageViews: '',
      GAiimjobsAvgTimeOnPage: '',
      ...(diversityFirebaseCSV && { FirebaseDiversityEventCount: '' }),
      GADiversityPageViews: '',
      GADiversityAverageTimeOnPage: '',
      GAiimjobsDiversityTotalEvents: '',
      ShowcasePrice: '',
      DiversityPrice: ''
    }
    count = 0;
    index = 0;

    var company;
    if(this.state.selectedCompaniesList.length > 0) {
        this.setState({
          currCompanyList: this.state.selectedCompaniesList
        })

        company = this.state.selectedCompaniesList[index].company;
    }

    else {
      this.setState({
        currCompanyList: this.state.companies
      })

      company = this.state.companies[index].company;
    }


    if (this.validate()) {
      this.setState({ submitApiCall: true });
      const {
        startDate,
        endDate,
        firebaseStartDate,
        firebaseEndDate,
        diversityFirebaseStartDate,
        diversityFirebaseEndDate
      } = this.state;

      if (firebaseCSV === null && diversityFirebaseCSV == null) {

        try {
          this.queryReportsFromiimjobsGAShowcase(company, this.currentCompanyData);
        } catch (e) {
          console.log(e);
        }
      }

      else if (firebaseCSV == null) {

        if (
          moment(this.state.startDate).format("YYYY-MM-DD") !=
          diversityFirebaseStartDate ||
          moment(this.state.endDate).format("YYYY-MM-DD") != diversityFirebaseEndDate

        ) {
          toast.error("Entered Date Range must match with Diversity Firebase CSV");
          this.setState({ submitApiCall: false });
        } else {
          try {
            this.queryReportsFromiimjobsGAShowcase(company, this.currentCompanyData);
          } catch (e) {
            console.log(e);
          }
        }
      }

      else if (diversityFirebaseCSV === null) {

        if (
          moment(this.state.startDate).format("YYYY-MM-DD") !=
          firebaseStartDate ||
          moment(this.state.endDate).format("YYYY-MM-DD") != firebaseEndDate

        ) {
          this.setState({ submitApiCall: false });
          toast.error("Entered Date Range must match with Firebase CSV");
        } else {
          try {
            this.extractFirebaseDataFromCSV(
              company,
              this.currentCompanyData
            );
          } catch (e) {
            console.log(e);
          }
        }
      }
      else {

        if (
          moment(this.state.startDate).format("YYYY-MM-DD") !=
          firebaseStartDate ||
          moment(this.state.endDate).format("YYYY-MM-DD") != firebaseEndDate
          || firebaseStartDate != diversityFirebaseStartDate || firebaseEndDate != diversityFirebaseEndDate
        ) {
          this.setState({ submitApiCall: false })
          toast.error("Entered Date Range must match with Firebase CSV");
        } else {
          try {
            this.extractFirebaseDataFromCSV(
              company,
              this.currentCompanyData
            );
          } catch (e) {
            console.log(e);
          }
        }
      }

    }
  }

  //extracting data from firebase CSV
  extractFirebaseDataFromCSV(company, currentCompanyData) {
    const keywords = company.firebaseKeywords.split(",");
    const reader = new FileReader();
    var done = false;

    try {
      reader.onload = async (event) => {
        let file = event.target.result;
        const labelStart = '# companyName[Showcase]';
        const labelEnd = "# Start date";
        let labelStartIndex = file.indexOf(labelStart);
        let labelEndIndex = this.locations(labelEnd, file).filter(i => i > labelStartIndex);

        if (labelEndIndex.length > 2) labelEndIndex = labelEndIndex[2];
        else labelEndIndex = file.length;

        file = file.substring(labelStartIndex, labelEndIndex);
        const labelStart1Index = file.indexOf('Custom parameter');
        file = file.substring(labelStart1Index);

        const allLines = file.split(/\r\n|\n/);
        const { firebaseKeywords, gtagKeywords, jobKeywords, diversityFirebaseKeywords, diversityGtagKeywords, diversityJobKeywords } = company;
        const keys = []
        firebaseKeywords != [] ? keys.push(firebaseKeywords) : undefined
        gtagKeywords != [] ? keys.push(gtagKeywords) : undefined
        jobKeywords != [] ? keys.push(jobKeywords) : undefined
        diversityFirebaseKeywords != [] ? keys.push(diversityFirebaseKeywords) : undefined
        diversityGtagKeywords != [] ? keys.push(diversityGtagKeywords) : undefined
        diversityJobKeywords != [] ? keys.push(diversityJobKeywords) : undefined
        // Reading line by line

        allLines.forEach((line) => {
          keywords.forEach((keyword) => {
            var lineSplit = line.split(",");
            if (lineSplit[0].toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
              done = true;

              currentCompanyData.CompanyKeyWords = `"${keys.join(',')}"`;
              currentCompanyData.FirebaseEventCount = parseInt(lineSplit[1]) ? parseInt(lineSplit[1]) : '';
              currentCompanyData.FirebaseUsers = parseInt(lineSplit[2]) ? parseInt(lineSplit[2]) : '';
            }
          });
        });

        if (!done) {
          currentCompanyData.CompanyKeyWords = `"${keys.join(',')}"`;
          currentCompanyData.FirebaseEventCount = '';
          currentCompanyData.FirebaseUsers = '';
        }

        this.queryReportsFromiimjobsGAShowcase(company, currentCompanyData);
      };

      reader.onerror = (event) => {
        toast.error(`${event.target.error.name}`)
      };

      reader.readAsText(firebaseCSV);
    } catch (e) {
      toast.error("Something went wrong");
      this.setState({ submitApiCall: false });
    }
  };

  //quering data from GA iimjobs showcase
  async queryReportsFromiimjobsGAShowcase(company, currentCompanyData) {

    const keywords = company.gtagKeywords.replace(",", "|");

    if (keywords != '') {
      gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: SHOWCASE_VIEW_ID,
                dateRanges: [
                  {
                    startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                  },
                ],
                metrics: [
                  {
                    expression: "ga:pageviews",
                  },
                  {
                    expression: "ga:avgTimeOnPage",
                  },
                  {
                    expression: "ga:uniquePageviews",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:pagePath",
                  },
                ],
                dimensionFilterClauses: [
                  {
                    operator: "AND",
                    filters: [
                      {
                        dimensionName: "ga:pagePath",
                        operator: "REGEXP",
                        expressions: [`${keywords}`],
                      },
                      {
                        dimensionName: "ga:pagePath",
                        operator: "REGEXP",
                        not: true,
                        expressions: ['.*src=hirist'],
                      }
                    ],
                  },
                ],
                orderBys: [
                  { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
                ],
              },
            ],
          },
        })
        .then((response) => {
          var totals = response.result.reports[0].data.totals[0].values;

          var pageViews = parseInt(totals[0]);
          var avgTimeOnPageInSeconds = Math.round(Number(totals[1]));
          var uniquePageViews = parseInt(totals[2]);

          if (firebaseCSV == null) {

            const { firebaseKeywords, gtagKeywords, jobKeywords, diversityFirebaseKeywords, diversityGtagKeywords, diversityJobKeywords } = company;
            const keys = []
            firebaseKeywords != [] ? keys.push(firebaseKeywords) : undefined
            gtagKeywords != [] ? keys.push(gtagKeywords) : undefined
            jobKeywords != [] ? keys.push(jobKeywords) : undefined
            diversityFirebaseKeywords != [] ? keys.push(diversityFirebaseKeywords) : undefined
            diversityGtagKeywords != [] ? keys.push(diversityGtagKeywords) : undefined
            diversityJobKeywords != [] ? keys.push(diversityJobKeywords) : undefined
            if (diversityFirebaseCSV != null)
              currentCompanyData.CompanyKeyWords = `"${keys.join(',')}"`;

            else {
              currentCompanyData.CompanyKeyWords = `"${keys.join(',')}"`;
            }
          }

          currentCompanyData.GAiimjobsShowcasePageViews = pageViews;
          currentCompanyData.GAiimjobsShowcaseUniquePageViews = uniquePageViews;
          currentCompanyData.GAiimjobsShowcaseAverageTimeOnPage = this.getTimeHHMMSS(
            avgTimeOnPageInSeconds
          );

          this.queryReportsFromhiristGAShowcase(company, currentCompanyData);
        })
        .catch((e) => {
          if (e.result.error.code == 401) {
            toast.error('Please sign in first')
          }
          else toast.error("Something went wrong");
          this.setState({ submitApiCall: false });
        });
    }

    else this.queryReportsFromhiristGAShowcase(company, currentCompanyData);
  }

  //quering data from GA hirist showcase
  async queryReportsFromhiristGAShowcase(company, currentCompanyData) {

    const keywords = company.gtagKeywords.replace(",", "|");
    if (keywords != '') {
      gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: SHOWCASE_VIEW_ID,
                dateRanges: [
                  {
                    startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                  },
                ],
                metrics: [
                  {
                    expression: "ga:pageviews",
                  },
                  {
                    expression: "ga:avgTimeOnPage",
                  },
                  {
                    expression: "ga:uniquePageviews",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:pagePath",
                  },
                ],
                dimensionFilterClauses: [
                  {
                    filters: [
                      {
                        dimensionName: "ga:pagePath",
                        operator: "REGEXP",
                        expressions: [`${keywords}.*src=hirist`],
                      },
                    ],
                  },
                ],
                orderBys: [
                  { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
                ],
              },
            ],
          },
        })
        .then((response) => {
          var totals = response.result.reports[0].data.totals[0].values;

          var pageViews = parseInt(totals[0]);
          var avgTimeOnPageInSeconds = Math.round(Number(totals[1]));
          var uniquePageViews = parseInt(totals[2]);

          currentCompanyData.GAhiristShowcasePageViews = pageViews;
          currentCompanyData.GAhiristShowcaseUniquePageViews = uniquePageViews;
          currentCompanyData.GAhiristShowcaseAverageTimeOnPage = this.getTimeHHMMSS(
            avgTimeOnPageInSeconds
          );

          this.queryReportsFromGAiimjobs(company, currentCompanyData);
        })
        .catch((e) => {
          if (e.result.error.code == 401) {
            toast.error('Please sign in first')
          }
          else toast.error("Something went wrong");
          this.setState({ submitApiCall: false });
        });
    }
    else this.queryReportsFromGAiimjobs(company, currentCompanyData);

  }
  //quering data from GA iimjobs
  async queryReportsFromGAiimjobs(company, currentCompanyData) {

    const keywords = company.jobKeywords.replace(",", "|");
    if (keywords != '') {
      gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: VIEW_ID,
                dateRanges: [
                  {
                    startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                  },
                ],
                metrics: [
                  {
                    expression: "ga:pageviews",
                  },
                  {
                    expression: "ga:avgTimeOnPage",
                  },
                  {
                    expression: "ga:uniquePageviews",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:pagePath",
                  },
                ],
                dimensionFilterClauses: [
                  {
                    filters: [
                      {
                        dimensionName: "ga:pagePath",
                        operator: "REGEXP",
                        expressions: [`${keywords}`],
                      },

                    ],
                  },
                ],
                orderBys: [
                  { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
                ],
              },
            ],
          },
        })
        .then((response) => {

          var totals = response.result.reports[0].data.totals[0].values;

          var pageViews = parseInt(totals[0]);
          var avgTimeOnPageInSeconds = Math.round(Number(totals[1]));
          var uniquePageViews = parseInt(totals[2]);

          currentCompanyData.GAiimjobsPageViews = pageViews;
          currentCompanyData.GAiimjobsUniquePageViews = uniquePageViews;
          currentCompanyData.GAiimjobsAvgTimeOnPage = this.getTimeHHMMSS(
            avgTimeOnPageInSeconds
          );

          if (diversityFirebaseCSV != null)
            this.extractDiversityFirebaseDataFromCSV(company, currentCompanyData);

          else this.queryReportsFromGADiversity(company, currentCompanyData);

        })
        .catch((e) => {
          console.log(e);
          if (e.result.error.code == 401) {
            toast.error('Please sign in first')
          }
          else toast.error("Something went wrong");
          this.setState({ submitApiCall: false });
        });
    }
    else {
      if (diversityFirebaseCSV != null)
        this.extractDiversityFirebaseDataFromCSV(company, currentCompanyData);

      else this.queryReportsFromGADiversity(company, currentCompanyData);
    }

  }

  //extracting data from diversity firebase csv
  extractDiversityFirebaseDataFromCSV = (company, currentCompanyData) => {

    const keywords = [`${company.diversityFirebaseKeywords}`];
    const reader = new FileReader();
    var done = false;

    try {
      reader.onload = async (event) => {
        let file = event.target.result;
        const labelStart = "# companyName[Showcase]";
        const labelEnd = "# Start date";
        const labelStartIndex = file.indexOf(labelStart);
        let labelEndIndex = this.locations(labelEnd, file).filter(
          (i) => i > labelStartIndex
        );

        if (labelEndIndex.length > 2) labelEndIndex = labelEndIndex[2];
        else labelEndIndex = file.length;

        file = file.substring(labelStartIndex, labelEndIndex);
        const labelStart1Index = file.indexOf("Custom parameter");
        file = file.substring(labelStart1Index);

        const allLines = file.split(/\r\n|\n/);
        // Reading line by line

        allLines.forEach((line) => {
          keywords.forEach((keyword) => {
            var lineSplit = line.split(",");
            if (lineSplit[0].indexOf(keyword) != -1) {

              done = true;

              currentCompanyData.FirebaseDiversityEventCount = parseInt(lineSplit[1]) ? parseInt(lineSplit[1]) : '';
            }
          });
        });

        if (!done) {

          currentCompanyData.FirebaseDiversityEventCount = '';
        }

        this.queryReportsFromGADiversity(company, currentCompanyData);
      };

      reader.onerror = (event) => {
        alert(event.target.error.name);
      };

      reader.readAsText(diversityFirebaseCSV);
    } catch (e) {
      toast.error("Something went wrong");
      this.setState({ submitApiCall: false });
    }
  };

  //quering data from GA diversity
  async queryReportsFromGADiversity(company, currentCompanyData) {

    const keywords = company.diversityGtagKeywords.replace(",", "|");
    if (keywords != '') {
      gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: DIVERSITY_VIEW_ID,
                dateRanges: [
                  {
                    startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                  },
                ],
                metrics: [
                  {
                    expression: "ga:pageviews",
                  },
                  {
                    expression: "ga:avgTimeOnPage",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:pagePath",
                  },
                ],
                dimensionFilterClauses: [
                  {
                    filters: [
                      {
                        dimensionName: "ga:pagePath",
                        operator: "REGEXP",
                        expressions: [`${keywords}`],
                      },
                    ],
                  },
                ],
                orderBys: [
                  { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
                ],
              },
            ],
          },
        })
        .then((response) => {
          var totals = response.result.reports[0].data.totals[0].values;

          var pageViews = parseInt(totals[0]);
          var avgTimeOnPageInSeconds = Math.round(Number(totals[1]));

          currentCompanyData.GADiversityPageViews = pageViews;
          currentCompanyData.GADiversityAverageTimeOnPage = this.getTimeHHMMSS(
            avgTimeOnPageInSeconds
          );

          this.queryEventReportsFromGADiversity(company, currentCompanyData);
        })
        .catch((e) => {
          if (e.result.error.code == 401) {
            toast.error("Please sign in first");
          } else toast.error("Something went wrong");
          this.setState({ submitApiCall: false });
        });
    }
    else this.queryEventReportsFromGADiversity(company, currentCompanyData);

  }

  //quering data from GA iimjobs diversity
  async queryEventReportsFromGADiversity(company, currentCompanyData) {

    const keywords = `dvViewTestimonial_${company.diversityJobKeywords}`;
    if (keywords != 'dvViewTestimonial_') {
      gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: VIEW_ID,
                dateRanges: [
                  {
                    startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                  },
                ],
                metrics: [
                  {
                    expression: "ga:totalEvents",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:eventAction",
                  },
                ],
                dimensionFilterClauses: [
                  {
                    filters: [
                      {
                        dimensionName: "ga:eventAction",
                        operator: "EXACT",
                        expressions: [`${keywords}`],
                      },
                    ],
                  },
                ],
                orderBys: [
                  { fieldName: "ga:totalEvents", sortOrder: "DESCENDING" },
                ],
              },
            ],
          },
        })
        .then((response) => {

          var totals = response.result.reports[0].data.totals[0].values;

          var totalEvents = parseInt(totals[0]);

          currentCompanyData.GAiimjobsDiversityTotalEvents = totalEvents;

          const currCompany = this.state.currCompanyList[index].company;
          currentCompanyData.CompanyName = currCompany.companyName;
          currentCompanyData.ShowcasePrice = currCompany.showcasePrice ? currCompany.showcasePrice : '';
          currentCompanyData.DiversityPrice = currCompany.diversityPrice ? currCompany.diversityPrice : '';

          completeAnalyticsData.push(currentCompanyData);
          count++;

          if (count >= this.state.currCompanyList.length) {

            let header;
            if (firebaseCSV == null && diversityFirebaseCSV == null) header = headersOnlyGA
            else if (firebaseCSV == null) header = headersNoFirebase
            else if (diversityFirebaseCSV == null) header = headersNoDiversityFirebase
            else header = headers

            this.exportCSVFile(header, completeAnalyticsData, "analytics");
            this.setState({ submitApiCall: false });

            completeAnalyticsData = [];
            this.currentCompanyData = {
              CompanyName: '',
              CompanyKeyWords: '',
              ...(firebaseCSV && { FirebaseEventCount: '' }),
              ...(firebaseCSV && { FirebaseUsers: '' }),
              GAiimjobsShowcasePageViews: '',
              GAiimjobsShowcaseUniquePageViews: '',
              GAiimjobsShowcaseAverageTimeOnPage: '',
              GAhiristShowcasePageViews: '',
              GAhiristShowcaseUniquePageViews: '',
              GAhiristShowcaseAverageTimeOnPage: '',
              GAiimjobsPageViews: '',
              GAiimjobsUniquePageViews: '',
              GAiimjobsAvgTimeOnPage: '',
              ...(diversityFirebaseCSV && { FirebaseDiversityEventCount: '' }),
              GADiversityPageViews: '',
              GADiversityAverageTimeOnPage: '',
              GAiimjobsDiversityTotalEvents: '',
              ShowcasePrice: '',
              DiversityPrice: ''
            };
            count = 0;
            index = 0;

            return;
          }

          if (index < this.state.currCompanyList.length - 1) {
            currentCompanyData = {
              CompanyName: '',
              CompanyKeyWords: '',
              ...(firebaseCSV && { FirebaseEventCount: '' }),
              ...(firebaseCSV && { FirebaseUsers: '' }),
              GAiimjobsShowcasePageViews: '',
              GAiimjobsShowcaseUniquePageViews: '',
              GAiimjobsShowcaseAverageTimeOnPage: '',
              GAhiristShowcasePageViews: '',
              GAhiristShowcaseUniquePageViews: '',
              GAhiristShowcaseAverageTimeOnPage: '',
              GAiimjobsPageViews: '',
              GAiimjobsUniquePageViews: '',
              GAiimjobsAvgTimeOnPage: '',
              ...(diversityFirebaseCSV && { FirebaseDiversityEventCount: '' }),
              GADiversityPageViews: '',
              GADiversityAverageTimeOnPage: '',
              GAiimjobsDiversityTotalEvents: '',
              ShowcasePrice: '',
              DiversityPrice: ''
            };
            index++;
            if (firebaseCSV != null) {
              this.extractFirebaseDataFromCSV(
                this.state.currCompanyList[index].company,
                currentCompanyData
              );
            }

            else this.queryReportsFromiimjobsGAShowcase(this.state.currCompanyList[index].company, currentCompanyData);

          }
        })
        .catch((e) => {
          console.log(e);
          if (e.result.error.code == 401) {
            toast.error("Please sign in first");
          } else toast.error("Something went wrong");
          this.setState({ submitApiCall: false });
        });
    }
    else {
      const currCompany = this.state.currCompanyList[index].company;
      currentCompanyData.CompanyName = currCompany.companyName;
      currentCompanyData.ShowcasePrice = currCompany.showcasePrice ? currCompany.showcasePrice : '';
      currentCompanyData.DiversityPrice = currCompany.diversityPrice ? currCompany.diversityPrice : '';
      completeAnalyticsData.push(currentCompanyData);
      count++;

      if (count >= this.state.currCompanyList.length) {

        let header;
        if (firebaseCSV == null && diversityFirebaseCSV == null) header = headersOnlyGA
        else if (firebaseCSV == null) header = headersNoFirebase
        else if (diversityFirebaseCSV == null) header = headersNoDiversityFirebase
        else header = headers

        this.exportCSVFile(header, completeAnalyticsData, "analytics");
        this.setState({ submitApiCall: false });

        completeAnalyticsData = [];
        this.currentCompanyData = {
          CompanyName: '',
          CompanyKeyWords: '',
          ...(firebaseCSV && { FirebaseEventCount: '' }),
          ...(firebaseCSV && { FirebaseUsers: '' }),
          GAiimjobsShowcasePageViews: '',
          GAiimjobsShowcaseUniquePageViews: '',
          GAiimjobsShowcaseAverageTimeOnPage: '',
          GAhiristShowcasePageViews: '',
          GAhiristShowcaseUniquePageViews: '',
          GAhiristShowcaseAverageTimeOnPage: '',
          GAiimjobsPageViews: '',
          GAiimjobsUniquePageViews: '',
          GAiimjobsAvgTimeOnPage: '',
          ...(diversityFirebaseCSV && { FirebaseDiversityEventCount: '' }),
          GADiversityPageViews: '',
          GADiversityAverageTimeOnPage: '',
          GAiimjobsDiversityTotalEvents: '',
          ShowcasePrice: '',
          DiversityPrice: ''
        };
        count = 0;
        index = 0;

        return;
      }

      if (index < this.state.currCompanyList.length - 1) {
        currentCompanyData = {
          CompanyName: '',
          CompanyKeyWords: '',
          ...(firebaseCSV && { FirebaseEventCount: '' }),
          ...(firebaseCSV && { FirebaseUsers: '' }),
          GAiimjobsShowcasePageViews: '',
          GAiimjobsShowcaseUniquePageViews: '',
          GAiimjobsShowcaseAverageTimeOnPage: '',
          GAhiristShowcasePageViews: '',
          GAhiristShowcaseUniquePageViews: '',
          GAhiristShowcaseAverageTimeOnPage: '',
          GAiimjobsPageViews: '',
          GAiimjobsUniquePageViews: '',
          GAiimjobsAvgTimeOnPage: '',
          ...(diversityFirebaseCSV && { FirebaseDiversityEventCount: '' }),
          GADiversityPageViews: '',
          GADiversityAverageTimeOnPage: '',
          GAiimjobsDiversityTotalEvents: '',
          ShowcasePrice: '',
          DiversityPrice: ''
        };
        index++;
        if (firebaseCSV != null) {
          this.extractFirebaseDataFromCSV(
            this.state.currCompanyList[index].company,
            currentCompanyData
          );
        }

        else this.queryReportsFromiimjobsGAShowcase(this.state.currCompanyList[index].company, currentCompanyData);

      }
    }

  }

  //convert seconds to HHMMSS
  getTimeHHMMSS(valueInSeconds) {
    var times = [3600, 60, 1];
    var formattedValue = times
      .map(function (t) {
        var value = parseInt(valueInSeconds / t);
        valueInSeconds %= t;
        return value;
      })
      .map(function (v) {
        return v.toString().padStart(2, 0);
      })
      .join(":");

    return formattedValue;
  }

  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    const startDate = moment(this.state.startDate).format("DD-MM-YYYY");
    const endDate = moment(this.state.endDate).format("DD-MM-YYYY");

    for (var i = 0; i < array.length; i++) {
      if (i > 0) {
        var line = `${startDate},${endDate}`;
      } else {
        var line = `From,To`;
      }
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  handleSelectChange(key, selectedOptions) {
    const data = { ...this.state.data };
    data[key] = selectedOptions;
    this.setState({
      data,
      selectedId: selectedOptions ? selectedOptions.value : null,
    });
  }

  companyList() {
    this.props.history.push(`/showcase`);
  }

  locations(substring, string) {
    var a = [], i = -1;
    while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
    return a;
  }

  handlePillChange = (event, data) => {
    
    let values = [];
    data.value.map(value => {
        const company = this.state.companies.filter(company => company.id == value)
        
        values.push(company[0])
        
    })

    this.setState({
        [data.name]: values
    })
    
}

onPillOpen = (event, data) => {
    const stateName = 'open' + data.name;
    
    this.setState({
        [stateName]: true
    })
}

onPillClose = (event, data) => {
    const stateName = 'open' + data.name;
    this.setState({
        [stateName]: false
    })
}

  render() {
    const { validations, submitApiCall, viewAccess } = this.state;

    return (
      <div>
        {viewAccess ? (
          <div className="wrapper showcase-report">
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
            />

            <div className="pageHeading">
              <Heading text="Analytics Reporting" />
              <Button
                skin="dark"
                className="company_btn"
                text="Company List"
                clickButton={() => {
                  this.companyList();
                }}
              />
            </div>
            <hr />
            <div className="company-analytics-reporting">
              <form>
                <div className="row section details-section">
                  <p className="label-heading"> Company Details </p>
                  
                  <div className='form-group companies'>
                  <label className='col-sm-2 left'>Companies</label>
                  <div className='col-sm-6 left valueBox'>
                    <PillButton data={this.state.list} defaultValue={1} placeholder='Select Companies' name='selectedCompaniesList' onChange={this.handlePillChange} open={this.state.openselectedCompaniesList} onClick={this.onPillOpen} onClose={this.onPillClose} />
                  </div>
                </div>

                  <div className="form-group date">
                    <label className="col-sm-2 left"> Date *</label>
                    <div className="col-sm-6 left valueBox">
                      <FromToDatePicker
                        name="date"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        handleChangeStart={(e) =>
                          this.setState({ startDate: e })
                        }
                        handleChangeEnd={(e) => this.setState({ endDate: e })}
                      />
                      {validations.startDateReq || validations.startDateReq ? (
                        <p className="validatorError">
                          Please Enter both Start and End Date
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group firebaseCSV">
                    <label className="col-sm-2 left csv" htmlFor="firebaseCSV" > Firebase CSV </label>
                    <div className="col-sm-6 left valueBox">
                      <input
                        id="firebaseCSV"
                        type="file"
                        name="firebaseCSV"
                        value={this.state.data.cutOff}
                        placeholder="Upload Firebase CSV"
                        onChange={this.onChange.bind(this, "firebaseCSV")}
                      />
                      {validations.firebaseCSVReq ? (
                        <p className="validatorError">
                          {validationMsg.firebaseCSVReq}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group diversityFirebaseCSV">
                    <label className="col-sm-2 left csv" htmlFor="diversityFirebaseCSV"> Diversity Firebase CSV </label>
                    <div className="col-sm-6 left valueBox">
                      <input
                        id="diversityFirebaseCSV"
                        type="file"
                        name="diversityFirebaseCSV"
                        value={this.state.data.cutOff}
                        placeholder="Upload Diversity Firebase CSV"
                        onChange={this.onDiversityChange.bind(this, "diversityFirebaseCSV")}
                      />
                      {validations.diversityFirebaseCSVReq ? (
                        <p className="validatorError">
                          {validationMsg.diversityFirebaseCSVReq}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group googleAuth">
                    <label className="col-sm-2 left">
                      Google Authentication *
                    </label>
                    <div className="col-sm-6 left valueBox">
                      <p className="g-signin2"></p>
                    </div>
                  </div>

                  <Button
                    skin="dark"
                    disabled={submitApiCall}
                    loading={submitApiCall}
                    text="Download CSV"
                    clickButton={this.onSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>You don't have Showcase Company Access</div>
        )}
      </div>
    );
  }
}

export default ShowcaseReport;
