import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "../Spinner";
import './Button.scss';

const button = (props) => {
    const className = [];
    className.push(props.skin)
    if (props.className) {
        className.push(props.className);
    }
    return(
        <button className={className.join(' ')} value={props.value} disabled={props.disabled} onClick={props.clickButton}>
            {props.text}
            {
                props.loading ?
                <i className="spinner loading icon"></i>:null
            }
        </button>
    )
}

button.propTypes = {
    disabled: PropTypes.bool,
    clickButton: PropTypes.func,
    text: PropTypes.string,
    value: PropTypes.string,
    skin: PropTypes.oneOf([ 'dark', 'light' ]),
    className: PropTypes.string,
    loading: PropTypes.bool,
}

export default button;