import axios from '../utils/axios';

export default function fetchVerifiedRecruiters(adminId, params) {
    return (
        axios.get(`/admin/${adminId}/verified-recruiters`, { params })
            .then(res => {
                return res.data
            })
    )
}

