import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react';
import Button from '../../components/Button'
import Loader from '../../components/Loader';
import fetchTagsCount from '../../services/fetchTagsCount';
import fetchTagsList from '../../services/fetchTagsList';
import fetchUserTagsCount from '../../services/fetchUserTagsCount';
import user from '../../store/user';

import './TagsAnalysis.scss'

const TagsAnalysis = () => {

    const [tagsCount, setTagsCount] = useState(0);
    const [userTagsCount, setUserTagsCount] = useState(0);
    const [totalDistinctUser, setTotalDistinctUser] = useState(0);
    const [totalTagsFollowed, setTotalTagsFollowed] = useState(0);
    const [topTagsList, setTopTagsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const profile = await user.getProfile();
                setIsLoading(true);
                // eslint-disable-next-line no-undef
                const [tagData, userTagsData, userData, followerData, tagsList] = await Promise.all([
                    fetchTagsCount(profile.id, { notStatus: 1 }),
                    fetchUserTagsCount(profile.id, { notStatus: 1, format: 1, }),
                    fetchUserTagsCount(profile.id, { notStatus: 1, }),
                    fetchUserTagsCount(profile.id, { notStatus: 1, format: 2, }),
                    fetchTagsList(profile.id, { pageNumber: 1, pageContent: 100 }),
                ],);
                setTagsCount(tagData.data.data[0].cnt);
                setUserTagsCount(userTagsData.data.data[0].cnt);
                setTotalDistinctUser(userData.data.data[0].cnt);
                setTotalTagsFollowed(followerData.data.data[0].cnt);
                setTopTagsList(tagsList.data.data);
                setIsLoading(false);
            } catch (error) {
                // eslint-disable-next-line no-undef
                console.log(error);
            }
        })()
    }, [])

    return (
        <div className='tags-analysis-container'>
            <div className='topbar'>
                <h2 className='page-heading'>Tags Analysis</h2>
                <div className='older-version-btn'>
                    <Button
                        text="Go to Older Version"
                        skin="light"
                        // eslint-disable-next-line no-return-assign, no-undef
                        clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/tagsanalysis.php`}
                    />
                </div>
            </div>
            <div className='content-container'>
                <div className='count-info'>
                    <label>Total Unique users following tags :</label>
                    <span>{totalDistinctUser}</span>
                </div>
                <div className='count-info'>
                    <label className='count-label'>Tags followed :</label>
                    <span>{totalTagsFollowed}</span>
                </div>
                <div className='count-info'>
                    <label>Average of tags followed by every user :</label>
                    <span>{tagsCount ? Math.round(userTagsCount / tagsCount * 100 *100 ) /100  : 0}%</span>
                </div>
                <br/>
                <h2>Top 100 tags followed</h2>
                <Table
                    className='tags-list'
                    fixed

                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Tag Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Total Users
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoading ?
                            <Loader /> :
                            topTagsList.map((tag) => {
                                return (
                                    <Table.Row key={tag.id}>
                                        <Table.Cell>
                                            {tag.tag_name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tag.totaluser}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default TagsAnalysis