import React, { useEffect, useState } from "react"
import fetchLGCollectionTargets from "../../../../services/lGBillingCrud/fetchLGCollectionTargets"
import fetchLGCollectionTargetsCount from "../../../../services/lGBillingCrud/fetchLGCollectionTargetsCount"
import deleteLGCollectionTarget from "../../../../services/lGBillingCrud/deleteLGCollectionTarget"
import fetchBusinessLines from "../../../../services/lGBillingCrud/getBusinessLines"
import { ToastContainer, toast } from 'react-toastify';
import Heading from "../../../../components/Heading";
import ButtonContainer from "../../../../components/ButtonContainer";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Button from "../../../../components/Button";
import InputBox from "../../../../components/InputBox";
import Pagination from "../../../../components/Pagination";
import { Table } from "semantic-ui-react";
import user from "../../../../store/user";
import Loader from "../../../../components/Loader";
import moment from "moment";
import { Link } from "react-router-dom";
import ActionIcons from "../../../../components/ActionIcons";
import { sortCategories } from "../../../../models/LGCollectionTargetSortCategories";
import LinkButtons from "../../../../components/LinkButtons";
import FromToDatePicker from "../../../../components/FromToDatepicker";
import CountDisplay from "../../../../components/CountDisplay"
import { adminFeatureMapping } from '../../../../models/adminFeatureMapping';

const LGCollectionTargetsListContainer = (props) => {
    const editLGCollectionTargetRoute = "/lgCollectionTarget"
    const addCollectionTargetRoute = "/addLGCollectionTarget"
    const lgCollectionTargetsListRoute = "/lGCollectionTargets"

    const [adminType, setAdminType] = useState(null)
    const [adminId, setAdminId] = useState(null)
    const [collectionTargetsList, setCollectionTargetsList] = useState([])
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const [billingDate, setBillingDate] = useState(null)
    const [availableBusinessLines, setAvailableBusinessLines] = useState([])
    const [updateAccess, setUpdateAccess] = useState(false)
    const [filterInputs, setFilterInputs] = useState({
        'businessLine': null,
        'pageNumber': 1,
        'pageContent': 10,
        'targetFromDate': null,
        'targetToDate': null,
        'totalItemsCount': 1000,
        'arrow': false,
        'asc': null,
        'orderBy': null,

    })

    const fetchAllLGBills = (adminId = adminId, parameters = filterInputs, routeRequestCheck = true) => {
        fetchLGCollectionTargets(adminId, parameters)
            .then(res => {
                const data = res.data.data;
                setCollectionTargetsList(data)
                fetchLGCollectionTargetsCount(adminId, parameters)
                    .then(res => {
                        const cnt = res.data.data;
                        setTotalItemsCount(cnt)
                    })
                    .catch(err => {
                        setTotalItemsCount(0)
                        toast.error(err.response.data.message);
                    })
                // setTotalItemsCount(data.totalCount)
                setLoader(false)
                routeRequestCheck && routeRequest(parameters)
            })
            .catch(err => {
                setLoader(false)
                setCollectionTargetsList([])
                toast.error(err.response.data.message);
            })
    }

    const getParameters = (state) => {
        const parameters = {
            order: state.asc ? 'asc' : 'desc',
            pageNumber: state.pageNumber,
            pageContent: state.pageContent,
        }

        if(state.orderBy){
            parameters['orderBy'] = state.orderBy  
        }        

        if (state.businessLine > 0)
            parameters['businessLine'] = state.businessLine

        if(state.targetFromDate != null && state.targetToDate != null){
            parameters['targetFromDate'] = moment(state.targetFromDate).format('YYYY-MM-DD')
            parameters['targetToDate'] = moment(state.targetToDate).format('YYYY-MM-DD')

        }

        return parameters;

    }

    const getBusinessLines = async (adminId) => {
        fetchBusinessLines(adminId, {})
            .then(res => {
                if (res.data) {
                    let resBuisenessLines = [{ "id": 0, "name": "Select Business Line" }]
                    Object.keys(res.data["BusinessLine"]).forEach(key => {
                        resBuisenessLines.push({ "id": key, "name": res.data["BusinessLine"][key] })
                    });
                    setAvailableBusinessLines(resBuisenessLines)
                }
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });
    }

    const onChangeCategorizedInputButton = (event) => {
        let val = event.target.value
        const filterInputsTemp = filterInputs
        filterInputsTemp.businessLine = val
        filterInputsTemp.pageNumber = 1
        setFilterInputs((prevState) => ({
            ...prevState,
            ["businessLine"]: val,
            ["pageNumber"]: 1
        }))
        const parameters = getParameters(filterInputsTemp)
        // call fetchBills with temp variable
        fetchAllLGBills(adminId, parameters)
    }

    const onClickSearchInputs = (event) => {
        // call fetch bills with parameters
        const parameters = getParameters(filterInputs)
        fetchAllLGBills(adminId, parameters)
    }

    const handlePageChange = (pg) => {
        const filterInputsTemp = filterInputs;
        setLoader(true)
        filterInputsTemp.pageNumber = pg
        setFilterInputs((prevState) => ({
            ...prevState,
            ["pageNumber"]: pg
        }))
        const parameters = getParameters(filterInputsTemp);
        fetchAllLGBills(adminId, parameters);
    }

    const findBusinessLineFromCode = (code) => {
        const business = availableBusinessLines.find(obj => obj.id == code);
        return business ? business["name"] : null
    }

    const deletebill = (id) =>{
        setLoader(true)
        if (window.confirm('Are you sure to delete this collection target ? ')) {
            deleteLGCollectionTarget(adminId, id)
                .then(res => {
                    setLoader(false)
                    if (res.data.status == 'success') {
                        toast.info('Target deleted successfully');
                        const parameters = getParameters(filterInputs);
                        fetchAllLGBills(adminId, parameters)
                        // this.fetchCount(this.state.adminId ,parameters)
                    } else {
                        toast.error('Target could not be deleted');
                    }
                })
                .catch(err => {
                    setLoader(false)
                    toast.error('Target could not be deleted');
                })
        } else {
            setLoader(false);
        }
    }

    const onClickSortButton = (sortId, index) => {

        //Sort categories change
        sortCategories.forEach(category => {
            category.arrow = false
        })

        if (sortId != 'all') {
            sortCategories[index].arrow = true
            sortCategories[index].asc = !sortCategories[index].asc;
        }

        //change query parameters
        if (sortId != 'all') {

            const filterInputsTemp = filterInputs;
            filterInputsTemp.arrow = true;
            filterInputsTemp.orderBy = sortId;
            filterInputsTemp.asc = sortCategories[index].asc;
            filterInputsTemp.pageNumber = 1;

            setFilterInputs((prevState)=>({
                ...prevState, 
                ["arrow"] : true,
                ["orderBy"] : sortId,
                ["asc"] : sortCategories[index].asc,
                ["pageNumber"] : 1
            }))

            setCollectionTargetsList([])
            setLoader(true)
            const parameters = getParameters(filterInputsTemp);
            // fetchCount(this.state.adminId ,parameters)
            fetchAllLGBills(adminId, parameters)
            routeRequest(filterInputsTemp)
        } else {        
            setFilterInputs((prevState)=>({
                ...prevState, 
                ["orderBy"] : null,
                ["pageNumber"] : 1
            }))

            const filterInputsTemp = filterInputs;
            filterInputsTemp.orderBy = null;
            filterInputsTemp.pageNumber = 1;
            const parameters = getParameters(filterInputsTemp)
            props.history.push(lgCollectionTargetsListRoute)
            setCollectionTargetsList([])  
            setLoader(true)
            // this.fetchCount(this.state.adminId ,{})
            fetchAllLGBills(adminId, parameters)
            routeRequest(filterInputsTemp)
        }
    }

    const handleDateRangeFilter = (props) =>{
        setFilterInputs((prevState) => ({
            ...prevState,
            [props.title] : props.value,
        }))
        if((props.title == "targetFromDate" && filterInputs.targetToDate != null) || (props.title == "targetToDate" && filterInputs.targetFromDate != null)){
            setFilterInputs((prevState) => ({
                ...prevState,
                [props.title] : props.value,
                ["pageNumber"]: 1
            }))
            const filterInputsTemp = filterInputs;
            filterInputsTemp.pageNumber = 1;
            const a = (props.title === "targetFromDate") ? (filterInputsTemp.targetFromDate = props.value) : (filterInputsTemp.targetToDate = props.value)
            const parameters = getParameters(filterInputsTemp)
            routeRequest(parameters)
                setLoader(true)
                fetchAllLGBills(adminId, parameters)
                routeRequest(filterInputsTemp)
        } 
    }

    // const onClickClearDate = () => {
    //     setLoader(true)
    //     setCollectionTargetsList([])  
    //     setFilterInputs((prevState) => ({
    //         ...prevState,
    //         ["targetFromDate"]: null,
    //         ["targetToDate"]: null,
    //         ["pageNumber"]: 1
    //     }))

    //     const filterInputsTemp = filterInputs;
    //     filterInputsTemp.pageNumber = 1;
    //     filterInputsTemp.targetFromDate = null;
    //     filterInputsTemp.targetToDate = null;
    //     const parameters = getParameters(filterInputsTemp)
    //     fetchAllLGBills(adminId, parameters)
    //     routeRequest(filterInputsTemp)
    //     props.history.push(lgCollectionTargetsListRoute)
    // }

    function getQuarterAndYearFromDate(dateString) {
        const date = moment(dateString, "YYYY-MM-DD");
        let fiscalYearStartDate = moment([parseInt(date.year())+1, 0]).startOf('month');
        const year = fiscalYearStartDate.year()
        const quarter = date.quarter();
        return [ year, quarter ];
    }

    const routeRequest = (parameters) => {
        let array = [];
        for (const key in parameters) {
            if (parameters[key] != null && key !== 'arrow' && key !== 'loader' && key !== 'totalItemsCount' && key !== 'collectionTargetsList') {
                if (key == 'asc') array.push('order=' + (parameters[key] ? 'asc' : 'desc'))
                else {
                    if (typeof (parameters[key]) == 'number' && parameters[key] > 0)
                        array.push(key + '=' + parameters[key])
                    if (typeof (parameters[key]) == 'string')
                        array.push(key + '=' + parameters[key])
                }

            }
        }

        array = array.join('&')
        props.history.push(lgCollectionTargetsListRoute + '?' + array)
    }

    useEffect(() => {
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(profile => {
            if (profile.adminType == 2 || profile.adminType == 3) {
                const adminId = profile.id
                const type = profile.type;
                const adminType = profile.adminType;
                setUpdateAccess(user.checkFeatureAccess(adminFeatureMapping.LEARN_AND_GROW_UPDATE_BILLS));
                setAdminType(adminType)
                setAdminId(adminId)
                getBusinessLines(adminId)
                fetchAllLGBills(adminId, filterInputs, false)
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, [])

    return (
        <div className='page-content-bills'>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
            <div className='page-heading'>
                <Heading text='Collection Targets' />
                <div className="right">
                    <Link to={`${addCollectionTargetRoute}`}>Add new collection target</Link>
                </div>
                <ButtonContainer heading='Sort By: '>
                    {
                        sortCategories.map((category, i) => (
                            <li key={category.id}> <LinkButtons name={category.name} arrow={category.arrow} arrowType={category.asc} id={category.id} sortClick={() => onClickSortButton(category.id, i)} /> </li>
                        ))
                    }
                </ButtonContainer>
            </div>
            <div className='row category-filter'>
                <ButtonContainer heading='Categorize By: '>
                    <li key='businessLines'> <Dropdown data={availableBusinessLines} dropdownChange={onChangeCategorizedInputButton} defaultValue={filterInputs.businessLine} /> </li>
                </ButtonContainer>
                {/* <div className="rec-input right">
                    <InputBox className="inputBox" placeholder='Search Target' value={filterInputs.customerNameLike} onChange={onChangeCustomerName} />
                    <Button skin='dark' disabled={false} text='Search' clickButton={onClickSearchInputs} />
                    <InputBox className="inputBox" placeholder='Search Achieved Target' value={filterInputs.invoiceNumberLike} onChange={onChangeInvoiceNumberLike} />
                    <Button skin='dark' disabled={false} text='Search' clickButton={onClickSearchInputs} />
                </div> */}
            </div>
            {/* date range input filter */}
            {/* <div className="row">
                <ButtonContainer heading= 'Date:'>
                    <li>
                        <div className='date-picker'>
                            <div className="form-group date">
                                <div className="col-sm-6 left valueBox">
                                    <FromToDatePicker
                                        name="date"
                                        startDate={filterInputs.targetFromDate}
                                        endDate={filterInputs.targetToDate}
                                        handleChangeStart={(e) => {
                                            handleDateRangeFilter({"title": "targetFromDate", "value": e})
                                        }}
                                        handleChangeEnd={(e) => {
                                            handleDateRangeFilter({"title": "targetToDate", "value": e})
                                        }}
                                    />
                                </div>
                            </div>       
                        </div>
                            
                    </li>
                    <li>
                        <Button skin='light' disabled={false} text='Clear' clickButton={onClickClearDate} ></Button>
                    </li>
                </ButtonContainer>
            </div> */}
            {
                loader ? null : (
                    <div className='page-row text-center'>
                        <Pagination totalItemsCount={filterInputs.totalItemsCount} content={collectionTargetsList.length} pageNumber={filterInputs.pageNumber} handlePageChange={handlePageChange} />
                        <CountDisplay start={(filterInputs.pageNumber - 1) * filterInputs.pageContent + 1} end={filterInputs.pageNumber * filterInputs.pageContent} total={totalItemsCount} />
                    </div>
                )
            }
            <br/>
            <div>
                <Table fixed selectable structured className="bills-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5} >Target Year</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Target Quarter</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Business Line</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Target</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Achieved Target</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {loader ? (
                            <div className="loader-container">
                                <Loader />
                            </div>
                        ) : (
                            collectionTargetsList.map((item) => {
                                return (
                                    <Table.Row key={item.id}>
                                        <Table.Cell className="not-capitalized">{getQuarterAndYearFromDate(item.targetFromDate)[0]}</Table.Cell>
                                        <Table.Cell className="not-capitalized">{getQuarterAndYearFromDate(item.targetFromDate)[1]}</Table.Cell>
                                        <Table.Cell>{findBusinessLineFromCode(item.businessLine)}</Table.Cell>
                                        <Table.Cell className="not-capitalized">{updateAccess ? <Link to={`${editLGCollectionTargetRoute}/${item.id}`}>{item.target}</Link> : item.target}</Table.Cell>
                                        <Table.Cell className="not-capitalized">{item.achievedTarget ? (item.achievedTarget + " (" + ((item.achievedTarget/item.target) * 100).toFixed(2)) + "%)"  : 0}</Table.Cell>
                                        <Table.Cell className="not-capitalized">
                                            <ActionIcons type='deleteBill' onclick={() => deletebill(item.id)} />
                                        </Table.Cell>
                                    </Table.Row>

                                )
                            })
                        )

                        }
                    </Table.Body>
                </Table>
            </div>
            {
                loader ? null : (
                    <div className='page-row text-center pagination-bottom'>
                        <Pagination totalItemsCount={filterInputs.totalItemsCount} content={filterInputs.pageContent} pageNumber={filterInputs.pageNumber} handlePageChange={handlePageChange} />
                    </div>
                )
            }
        </div>
    )
}

export default LGCollectionTargetsListContainer;
