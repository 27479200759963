import React from 'react';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const PlacementList = Loadable({
  loader: () => import('../CandidateList'),
  loading: Loading,
});

const PlacementListing = () => (
    <PlacementList />
)

PlacementListing.propTypes = {
};

export default PlacementListing;
