import React, { useState } from 'react'
import user from '../../store/user';
import InputBox from '../../components/InputBox'
import Button from '../../components/Button'
import { toast, ToastContainer } from 'react-toastify'
import './JobHistory.scss'
import fetchJobHistory from '../../services/fetchJobHistory';
import Loader from '../../components/Loader';
import { Table } from 'semantic-ui-react';

const JobHistory = () => {

    const [metaId, setMetaId] = useState(null);
    const [publishId, setPublishId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [jobData, setJobData] = useState([]);

    const searchByMetaId = async () => {
        if (!metaId) return;
        const params = { metaId, }
        await searchHistory(params);
    };

    const searchByPublishId = async () => {
        if (!publishId) return;
        const params = { publishId, };
        await searchHistory(params)
    };

    const searchHistory = async (params) => {
        try {
            const profile = await user.getProfile();
            setIsLoading(true);
            const { data } = await fetchJobHistory(profile.id, params);
            setJobData(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message || 'Something Went Wrong');
        }

    }

    return (
        <div className='job-history-page-container'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <h2 className='job-history-page-heading'>Job History</h2>
            <div className='search-container'>
                <div className='search-field-container'>
                    <InputBox placeholder="Enter Job Id" type="number" name="metaId" value={metaId} onChange={(e) => setMetaId(e.target.value)} />
                    <Button skin="dark" text="Search" clickButton={searchByMetaId} />
                </div>
                <div className='search-field-container'>
                    <InputBox placeholder="Enter Publish Id" type="number" name="publishId" value={publishId} onChange={(e) => setPublishId(e.target.value)} />
                    <Button skin="dark" text="Search" clickButton={searchByPublishId} />
                </div>
            </div>
            {isLoading ?
                <Loader /> :
                jobData.length ?
                    <Table
                        fixed
                        selectable
                        className="job-history-list"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Meta Id
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Publish Id
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Premium Post
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Refresh Status
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Rejected
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Unpublished
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Publish
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Updated At
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Updated By
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Created
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {jobData.map(data => {
                                return (
                                    <Table.Row key={data.meta_id}>
                                        <Table.Cell>
                                            {data.meta_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.published_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.premium_post}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.refresh_status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.rejected}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.unpublish_msg}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.publish}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.updated_at}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.updated_by}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.created}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                    : 'No Search Result'
            }
        </div>
    )
}

export default JobHistory