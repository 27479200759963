import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Table, Checkbox } from 'semantic-ui-react';

import './styles.scss';

const TableItem = props => {
    const recruiter = props.item;
    const accessMap = props.accessMap;
    const adminType = props.adminType;

    // handlers 
    const updateProfileHandler = (message, data, displayText) => {
        const userResponse = confirm(message)
        if (userResponse) {
            props.onProfileUpdate(data, recruiter.id, displayText)
        }
    }

    const onActivateRecruiter = () => {
        const userResponse = confirm('Are you sure to activate this recruiter?')
        if (userResponse) {
            props.onActivate(recruiter.id, recruiter.email, recruiter.recname)
        }
    }

    const onResendActivationEmail = () => {
        const userResponse = confirm('Are you sure to resend activation email to this recruiter?')
        if (userResponse) {
            props.onResendActLink(recruiter.id)
        }
    }

    // last post on
    const postOn = recruiter.last_posted ? moment(recruiter.last_posted).format('DD-MM-YYYY') : 'NA';

    // view profile url
    const recnameSmall = recruiter.recname.split(' ').map(part => part.toLowerCase()).join('-')
    const viewProfileURL = `${process.env.REACT_APP_CLIENT_URL}/r/${recruiter.id}-${recnameSmall}`

    // view post jobs url
    const viewPostJobsURL = `/?recruiterId=${recruiter.id}`

    const actions = [];

    if (accessMap['recruiter_profile'] && accessMap['recruiter_profile']['update_status']) {
        if (recruiter.status == 0) {
            actions.push(<span key='activate' onClick={onActivateRecruiter}> <a className="link" title='Click to activate'>Activate</a></span >);
    
            if (recruiter.hasPasskey) {
                actions.push(<span key='resend' onClick={onResendActivationEmail}><a className="link" title='Click to resend activation email'>Resend Act. Email</a></span>);
            }
        } else {
            actions.push(<span key='deactivate' onClick={() => {
                updateProfileHandler('Are you sure to deactivate this recruiter?', {
                    status: 0
                }, 'Recruiter deactivated successfully')
            }}><a className="link" title='Click to deactivate'>Deactivate</a></span>);
            actions.push(<span key='change-pass'><Link className='nav-link' to={`/changepasswordByAdmin?recid=${recruiter.id}`}>Change Password</Link></span >)
        }
    }

    if (accessMap['recruiter_profile'] && accessMap['recruiter_profile']['update_company_status']) {
        if (recruiter.company_status == 1) {
            actions.push(<span key='add-firm' onClick={() => {
                updateProfileHandler('Are you sure to activate this user as a Recruiter Firm?', {
                    type: 2
                })
            }}><a title='Click to add Recruitment firm'>Add Recruit. Firm</a></span>)
        } else if (recruiter.company_status == 2) {
            actions.push(<span key='add-emp' onClick={() => {
                updateProfileHandler('Are you sure to activate this user a Direct Employer?', {
                    type: 1
                })
            }}><a title='Click to add Direct Employer'>Add Direct Employer</a></span>)
        }
    }

    if (accessMap['recruiter_profile'] && accessMap['recruiter_profile']['update_admin_type']) {
        if (recruiter.admin_type == 0) {
            actions.push(<span key='add-admin' onClick={() => {
                updateProfileHandler('Are you sure to activate this user as admin?', {
                    adminType: 2
                })
            }}><a title='Click to add Recruitment as Admin'>Add As Admin</a></span>)

            actions.push(<span key='add-sales' onClick={() => {
                updateProfileHandler('Are you sure to activate this user as sales admin?', {
                    adminType: 4
                })
            }}><a title='Click to add Recruitment as Sales Admin'>Add As Sales Admin</a></span>)

        } else if (recruiter.admin_type == 4) {
            actions.push(<span key='rem-sales' onClick={() => {
                updateProfileHandler('Are you sure to remove this user from sales admin?', {
                    adminType: 0
                })
            }}><a title='Click to Remove From Sales Admin'>Remove From Sales Admin</a></span>)

        } else {
            actions.push(<span key='rem-admin' onClick={() => {
                updateProfileHandler('Are you sure to remove this user from admin?', {
                    adminType: 0
                })
            }}><a title='Click to Remove From Admin'>Remove From Admin</a></span>)
        }
    }

    if (recruiter.mgr_status == 1) {
        actions.push(<span key='rem-mgr' onClick={() => {
            updateProfileHandler('Are you sure to remove this user as manager?', {
                mgrStatus: 0
            })
        }}><a title='Click to remove from manager'>Remove Manager</a></span>)

    } else {
        actions.push(<span key='add-mgr' onClick={() => {
            updateProfileHandler('Are you sure to add this user as manager?', {
                mgrStatus: 1
            })
        }}><a title='Click to add as manager'>Add as Manager</a></span>)

    }
    // removing the option to verify profile from here
    // to verify profiles, visit the recruiter verification dashboard
    // if (props.verifyAccess) {

    //     if (recruiter.verificationStatus == 1) {
    //         actions.push(<span key='unverify-rec' onClick={() => {
    //             updateProfileHandler('Are you sure to unverify this recruiter?', {
    //                 verificationStatus: 0
    //             })
    //         }}><a title='Click to unverify this recruiter'>Unverify</a></span>)

    //     } else {
    //         actions.push(<span key='verify-rec' onClick={() => {
    //             updateProfileHandler('Are you sure to verify this recruiter?', {
    //                 verificationStatus: 1
    //             })
    //         }}><a title='Click to verify this recruiter'>Verify</a></span>)

    //     }
    // }

    // view profile
    actions.push(<span key='view-profile'><a href={viewProfileURL} target='_blank'>View Profile</a></span>)

    // view post jobs
    actions.push(<span key='view-jobs'><a href={viewPostJobsURL} target='_blank'>View Post Jobs</a></span>)

    if (accessMap['recruiter_profile'] && accessMap['recruiter_profile']['edit_profile']) {
        const editProfileURL = `/recruiter/recruiter_profile_mgtedit?recid=${recruiter.id}`
        actions.push(<span key='edit-profile'><a href={editProfileURL} target='_blank'>Edit Profile</a></span>)
    }

    if (accessMap['plan_management'] && accessMap['plan_management']['view']) {
        const planManagementURL = `${process.env.REACT_APP_CLIENT_URL}/recruiter/recruiter_plan_mgt.php?searchtext=${recruiter.id}`;
        actions.push(<span key='plan-mgt'><a href={planManagementURL} target='_blank'>Plan Management</a></span>)
    }

    if (accessMap['plan_management'] && accessMap['plan_management']['search']) {
        const searchPlanManagementURL = process.env.REACT_APP_CLIENT_URL + '/recruiter/dbsearchplanadd.php';
        actions.push(<span key='search-plan'><a href={searchPlanManagementURL} target='_blank'>Search Plan Management</a></span>)
    }

    if (props.showAdminAccess) {
        actions.push(<span key='admin-access' onClick={() => {
            props.onAdminAccessModal(recruiter.id, recruiter.email, recruiter.featuresAccess ? recruiter.featuresAccess.split(',') : [], recruiter.overrideInitials);
        }}><a title='Click to Remove From Admin'>Admin Access</a></span>)
    }

    if (accessMap['recruiter_profile'] && accessMap['recruiter_profile']['view_verification_document']) {
        if (recruiter.isGmailType && recruiter.docName) {
            actions.push(<span key='view-verify-doc' onClick={() => {
                props.loadDocument(recruiter.docName);
            }}><a title='Click to View Verification Document'>Show Verification Document</a></span>);
        }
    }
    
    return (
        <Table.Row className="recruiter_mgt_row">
            <Table.Cell width='one' verticalAlign="top">
                <div className='checkbox-container'>
                    <Checkbox checked={props.isSelected} onChange={(event, data) => {
                        props.onRecruiterSelected(recruiter.id, recruiter.email, recruiter.recname, data.checked, recruiter.status);
                    }} />
                    {recruiter.id}
                </div>
            </Table.Cell>
            <Table.Cell width='two' className="name" verticalAlign="top">
                <span className='recname'>{recruiter.recname}</span><br />
                <span>Total Jobs: {recruiter.totalJobs || 0}</span><br />
                <span>Last Post on: {postOn}</span>
            </Table.Cell>
            <Table.Cell width='one' verticalAlign="top">
                {recruiter.email}
            </Table.Cell>
            <Table.Cell width='one' verticalAlign="top">
                {recruiter.phone}
            </Table.Cell>
            <Table.Cell width='one' verticalAlign="top">
                {recruiter.organisation}
            </Table.Cell>
            <Table.Cell width='two' className="actions" verticalAlign="top">
                {actions}
            </Table.Cell>
        </Table.Row>
    )
}

TableItem.propTypes = {
    item: PropTypes.object,
    email: PropTypes.string,
    accessMap: PropTypes.object,
    onProfileUpdate: PropTypes.func,
    onResendActLink: PropTypes.func,
    onActivate: PropTypes.func,
    onAdminAccessModal: PropTypes.func,
    onRecruiterSelected: PropTypes.func,
    isSelected: PropTypes.bool,
    showAdminAccess: PropTypes.bool,
    loadDocument: PropTypes.func
}

export default TableItem;