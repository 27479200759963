import React from "react";
import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import user from '../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from 'react-datepicker';

import fetchRecruiterId from '../../../services/fetchRecruiterId';
import addCredits from '../../../services/addCredits';
import { RECRUITER_CREDIT_MANAGEMENT } from '../../../models/routes';

import { getQueryParams, getQueryString } from '../../../utils/utilFunctions';
import "./styles.scss"

const validationMsgs = {
    creditsReq: "Credits Required!",
    startDateReq: "Start Date Required!",
    endDateReq: "End Date Required!",
    // creditsReq: "Credits Required!",
}
class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recInfo: {},
            loader: false,
            credInfo: {},
            profile: {},
            idNotFound: false,
            validations: {}
        };
    }

    async componentDidMount() {
        const params = getQueryParams(window.location.search);
        const profile = await user.getProfile();
        const resp = await fetchRecruiterId(profile.id, {
            id: params.rec_id
        });
        console.log(resp);
        this.setState({
            profile,
            recInfo: resp.data
        });
    }


    onChange(key, ev) {
        console.log(key, ev);
        let { credInfo } = this.state;

        if (["startDate", "endDate"].includes(key)) {
            credInfo[key] = ev;
        } else {
            const { value } = ev.target;
            credInfo[key] = value;
        }
        // console.log(state);
        this.setState({
            credInfo
        });
    }

    validate() {
        const { credInfo } = this.state;
        const validations = {};

        if (!credInfo.credits) {
            validations.credits = validationMsgs.creditsReq;
        }

        if (!credInfo.startDate) {
            validations.startDate = validationMsgs.startDateReq;
        }

        if (!credInfo.endDate) {
            validations.endDate = validationMsgs.endDateReq;
        }

        return validations;
    }

    async onSubmit() {
        const validations = this.validate();
        if (Object.keys(validations).length) {
            this.setState({
                validations
            });
            return;
        }

        try {
            await addCredits(this.state.profile.id, {
                ...this.state.credInfo,
                rec_id: this.state.recInfo.id
            });
            toast.info('Credits Added successfully');

            setTimeout(() => {
                this.goToListing();
            }, 1000);

        } catch (err) {
            if (err.response && err.response.data)
                toast.error(err.response.data.message);
            else
                toast.error("Some Error Occured!");
        }
    }

    goToListing() {
        this.props.history.push(RECRUITER_CREDIT_MANAGEMENT);
    }

    render() {
        const { validations, recInfo, credInfo } = this.state;
        console.log(credInfo);

        return (
            <div className="credit-management-add">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <Heading text='Recruiter Credit Management > Add' />
                <div className="row">
                    <Button skin='dark' className="right-btn" text="Go To Credits Listing" clickButton={this.goToListing.bind(this)} />
                </div>
                <br />
                <br />
                <br />
                <div className="row form-container">
                    <div className="form-group cutoff">
                        <label className="col-sm-6 sub-head"> Recruiter Information </label>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> ID </label>
                        <div className="col-sm-6 left valueBox">
                            {recInfo.id}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Name </label>
                        <div className="col-sm-6 left valueBox">
                            {recInfo.name}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Email </label>
                        <div className="col-sm-6 left valueBox">
                            {recInfo.email}
                        </div>
                    </div>
                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Credit No. </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <InputBox
                                    type='number'
                                    name='credits'
                                    value={credInfo.credits}
                                    placeholder='Credits'
                                    onChange={this.onChange.bind(this, "credits")} />
                            </div>
                            {validations.credits ? <p className='validatorError'> {validations.credits} </p> : null}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Reason </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <InputBox
                                    type="text"
                                    options={{ list: "planreasons" }}
                                    value={credInfo.reason}
                                    onChange={this.onChange.bind(this, "reason")}
                                    placeholder='Reason' />
                                {/* </textarea> */}
                                <datalist id="planreasons">
                                    <option value="Plan activation">Plan activation</option>
                                    <option value="Transferred from hirist">Transferred from hirist</option>
                                    <option value="Transferred from iimjobs">Transferred from iimjobs</option>
                                    <option value="Transferred from updazz">Transferred from updazz</option>
                                    <option value="Transferred from engineeristic">Transferred from engineeristic</option>
                                    <option value="Transferred from another id">Transferred from another id</option>
                                    <option value="International sales">International sales</option>
                                    <option value="Others">Others </option>
                                </datalist>
                            </div>
                            {validations.reason ? <p className='validatorError'> {validations.reason} </p> : null}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> Start Date </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <DatePicker
                                    className='datePicker'
                                    selected={credInfo.startDate}
                                    placeholderText='Start'
                                    selectsStart
                                    onChange={this.onChange.bind(this, "startDate")}
                                    dateFormat='dd/MM/yyyy'
                                // minDate={ }
                                />
                            </div>
                            {validations.startDate ? <p className='validatorError'> {validations.startDate} </p> : null}
                        </div>
                    </div>

                    <div className="form-group cutoff">
                        <label className="col-sm-6 left"> End Date </label>
                        <div className="col-sm-6 left valueBox">
                            <div className="block-dropdown">
                                <DatePicker
                                    className='datePicker'
                                    selected={credInfo.endDate}
                                    placeholderText='End'
                                    selectsStart
                                    onChange={this.onChange.bind(this, "endDate")}
                                    dateFormat='dd/MM/yyyy'
                                // minDate={ }
                                />
                            </div>
                            {validations.endDate ? <p className='validatorError'> {validations.endDate} </p> : null}
                        </div>
                    </div>

                    <div className="row actions">
                        <Button skin='dark' text="Submit" clickButton={this.onSubmit.bind(this)} />
                    </div>

                </div>
            </div>
        )
    }

}

export default Add;