import React, { useState, useEffect } from 'react';
import user from '../../../../store/user';
import fetchCourseEnquiry from '../../../../services/fetchCourseEnquiry';
import './styles.scss';
import { Item, Table } from 'semantic-ui-react';
import Loader from '../../../../components/Loader';
import CountDisplay from '../../../../components/CountDisplay';
import Pagination from '../../../../components/Pagination';
import Button from '../../../../components/Button';
import Heading from '../../../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { getQueryParams } from "../../../../utils/utilFunctions";
import { coursePlanMap } from '../../../../models/coursePlanMap';
import downloadCourseEnquiry from '../../../../services/downloadCourseEnquiry';


const PAGE_CONTENT = 50;


const CourseEnquiry = (props) => {

  const [content, setContent] = useState({
    courseId: '',
    courseName: '',
    list: [],
    count: 0,
    pageNumber: 1
  })

  const [admin, setAdmin] = useState({
    adminId: null,
    adminType: null
  })

  const [fetchApiCall, setFetchApiCall] = useState(true)

  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id,
          adminType: userData.adminType

        })
        const queryObj = getQueryParams(props.location.search);
        setContent(prev => ({
          ...prev,
          pageNumber: queryObj.pageNumber,
          courseId: queryObj.courseId
        }))
        const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
        getCourseEquiry(userData.id, queryObj.courseId, pgNo);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])

  const getCourseEquiry = async (adminId, courseId, pageNumber) => {
    setFetchApiCall(true);
    try {
      const res = await fetchCourseEnquiry(adminId, courseId, { page: pageNumber, pageSize: PAGE_CONTENT })
      setContent(prev => ({
        ...prev,
        courseName: res.data.data.courseName,
        pageNumber: pageNumber,
        list: res.data.data.data,
        count: res.data.data.totalCount
      }))
      setFetchApiCall(false)
    }
    catch (err) {
      console.log(err);
      setFetchApiCall(false)
    }
  }

  const handlePageChange = async(n) => {
      await getCourseEquiry(admin.adminId, content.courseId, n);
      routeRequest(n, content.courseId)
  }

  const routeRequest = (pageNumber, courseId) => {
    let array = [];

    array.push(`courseId=${courseId}`);
    array.push(`pageNumber=${pageNumber}`);
   
    array = array.join("&");
    props.history.push("?" + array);
};

  const getExperience = (details) => {
    let exp = '';
    const expYr = details['exp_yr'] ? details['exp_yr'] + ' years' :  '';
    const expMonth = details['exp_month'] ? details['exp_month'] + ' months' :  '';
    const expSep = expYr && expMonth ? ' - ' : ''

    exp = expYr + expSep + expMonth;

    return exp;

  }

  const downloadFile = async () => {

    const params = {
      page: content.pageNumber,
      pageSize: PAGE_CONTENT,
    }
    downloadCourseEnquiry(admin.adminId, content.courseId, params)
    .then(res => {
      let filename = content['courseName'].replace(' | ', '_');
      filename += '-leads.xlsx';
      const blobType = res['headers']['content-type']

      var blob = new Blob([res.data], { type: blobType });
      var url = URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(e => {
        console.log(e)
    })
  }

  return (
      <div className="course-enquiry-wrapper">
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
          />

          <div className="course-enquiry-heading">
              <Heading text={`Course Enquiry - ${content.courseName}`} />
              <Button
                  skin="dark"
                  className="btn_course_list"
                  text="Course List"
                  clickButton={() => {
                      props.history.push(`/courseList`);
                  }}
              />
              <Button
                  skin="dark"
                  className="btn_course_view_leads"
                  text="Course Page Visited Leads"
                  clickButton={() => {
                      props.history.push(`/courseViewLeads?courseId=${content.courseId}`);
                  }}
              />

              <Button
                  skin="dark"
                  className="btn_dwnld_file"
                  text="Download File"
                  clickButton={downloadFile}
                  disabled={content.count > 0 ? false : true}
              />                
          </div>

          <div className="row text-center">
              <Pagination
                  totalItemsCount={content.count}
                  content={PAGE_CONTENT}
                  pageNumber={content.pageNumber}
                  handlePageChange={(n) => handlePageChange(n)}
              />
              <CountDisplay
                  start={content.list.length == 0
                    ? content.list.length
                    : (content.pageNumber - 1) * PAGE_CONTENT + 1}
                  end={content.pageNumber * PAGE_CONTENT -
                    (content.list.length < PAGE_CONTENT
                        ? PAGE_CONTENT - content.list.length
                        : 0)}
                  total={content.count}
              />

              {fetchApiCall ? (
                  <Loader />
              ) : content.list.length ? (
                  <div className="content">
                      <Table fixed selectable className="mailers-table">
                          <Table.Header>
                              <Table.Row>
                                  <Table.HeaderCell width={4}>
                                      Date
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={4}>
                                      Name
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={6}>
                                      Organization
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={6}>
                                      Contacts
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={3}>
                                      Experience
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={3}>
                                      Actions
                                  </Table.HeaderCell>
                              </Table.Row>
                          </Table.Header>

                          <Table.Body>
                              {content.list.map((item, i) => {
                                  return (
                                      <Table.Row>
                                          <Table.Cell>
                                            {moment(item.date).format('DD-MM-YYYY hh:mm a')} <br />
                                          </Table.Cell>
                                          <Table.Cell>{item.name}</Table.Cell>
                                          <Table.Cell>
                                                <>
                                                    {item.designation} <br />
                                                    {item.organization ? (
                                                        <>
                                                            {
                                                                item.organization
                                                            }{" "}
                                                            <br />
                                                        </>
                                                    ) : null}
                                                </>
                                          </Table.Cell>
                                          <Table.Cell>
                                              {item["email"] ? (
                                                  <>
                                                      Email: {item["email"]}{" "}
                                                      <br />
                                                  </>
                                              ) : null}
                                              {item["mobile"] ? (
                                                  <>
                                                      Mobile: {item["mobile"]}{" "}
                                                      <br />
                                                  </>
                                              ) : null}
                                              {item["city"] ? (
                                                  <>City: {item["city"]} </>
                                              ) : null}
                                          </Table.Cell>

                                          <Table.Cell>
                                              {getExperience(item)}
                                          </Table.Cell>

                                          <Table.Cell>
                                              {item["action"]} <br />
                                              {/* {admin["adminType"] == 3 ? (
                                                  <>
                                                      {`${
                                                          coursePlanMap[
                                                              item["plan"]
                                                          ]
                                                      } - ${item["device"]}`}
                                                  </>
                                              ) : null} */}
                                              {
                                                <>
                                                {`${
                                                    coursePlanMap[
                                                        item["plan"]
                                                    ]
                                                } - ${item["device"]}`}
                                            </>
                                              }
                                          </Table.Cell>
                                      </Table.Row>
                                  );
                              })}
                          </Table.Body>
                      </Table>
                      {/* {
                        admin['adminType'] == 3 ? (
                            <p className='course-plan'>
                                <b>
                                    Course Planing staging
                                    <br />
                                    100% = I definitely want to take this course now.
                                    <br />
                                    75% = I am definitely taking this course, not sure
                                    if I will take it now.
                                    <br />
                                    50% = Still considering. Not sure if I am going to
                                    take this course.
                                    <br />
                                    25% = I know I am going to take some course, not
                                    sure which course.
                                    <br />
                                    0% = Skip
                                </b>
                            </p>
                        ) : null */}
                        {<p className='course-plan'>
                            <b>
                                Course Planing staging
                                <br />
                                100% = I definitely want to take this course now.
                                <br />
                                75% = I am definitely taking this course, not sure
                                if I will take it now.
                                <br />
                                50% = Still considering. Not sure if I am going to
                                take this course.
                                <br />
                                25% = I know I am going to take some course, not
                                sure which course.
                                <br />
                                0% = Skip
                            </b>
                        </p>
                      }

                      
                  </div>
              ) : (
                  <div>No Records Found</div>
              )}
          </div>
      </div>
  );
};

export default CourseEnquiry;