import React, { useEffect, useState } from "react";
import PaidJob from "./PaidJob";
import "./Notifications.scss";

function Notifications() {
    window.notify_audio = new Audio(process.env.REACT_APP_NOTIFICATION);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (window.notificationHandler) {
            notificationHandler.subscribe(window.notificationHandler.types.paidJobNotify, listener)
        }

        return () => {
            console.log("Unmounting .... ");
            if (window.notificationHandler) {
                notificationHandler.unsubscribe(window.notificationHandler.types.paidJobNotify, listener)
            }
        }
    }, [])

    function listener(data) {
        const { state } = data;
        // console.log(Date.now(), data);

        switch(state) {
            case 1: 
                setNotifications(n => [data, ...n]);
                // console.log(Date.now());
                if (notificationHandler.isLatestTab()) {
                    // console.log("PLAYING AUDIO ------------");
                    window.notify_audio.play();
                    // console.log("PLAYED AUDIO ------------");
                }
                break;
            case 2: 
            case 3: 
                // console.log("CANCELING ------ ");
                window.notify_audio.pause();
                window.notify_audio.currentTime = 0;
                close(data.jobId);
                break;
        }
    }

    function closeClick(id) {
        console.log("Calling Close");
        notificationHandler.sendLocal({
            type: window.notificationHandler.types.paidJobNotify,
            payload: {
                jobId: id,
                state: 3
            }
        })
        close(id);
    }

    function close(id) {
        setNotifications(notifications => notifications.filter(n => n.jobId != id));
    }

    return (
        <div className="notifications-section">
            {
                notifications.length > 2 ?
                    <div className="more-jobs-section">
                        <img src="/static/suitcase.png" />
                        <span className="more-jobs-text">{notifications.length - 2} more jobs </span>
                    </div> : null
            }
            {
                notifications.slice(0, 2).map(notification => {
                    return <PaidJob
                        key={notification.jobId}
                        {...notification}
                        closeClick={closeClick.bind(null, notification.jobId)}
                        close={close.bind(null, notification.jobId)}
                    />
                })
            }
        </div>
    )
}

export default Notifications;
