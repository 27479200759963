import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const JobsList = Loadable({
  loader: () => import('../JobList'),
  loading: Loading,
});

const DisplayTagsWrapper = styled.div`
  font-size: 15px;
`

const DisplayTag = () => (
  <DisplayTagsWrapper>
      <JobsList />
  </DisplayTagsWrapper>
)

DisplayTag.propTypes = {
};

export default DisplayTag;
