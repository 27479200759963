import React, { useEffect, useState } from 'react';
import fetchLGCollectionTargets from '../../../../services/lGBillingCrud/fetchLGCollectionTargets';
import user from '../../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import Heading from '../../../../components/Heading';
import DatePicker from 'react-datepicker';
import InputBox from '../../../../components/InputBox';
import { currencyUnitsGlobal } from '../../../../models/currencyUnits';
import fetchBusinessLines from '../../../../services/lGBillingCrud/getBusinessLines';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Aux from '../../../../components/Aux'
import Button from '../../../../components/Button'
import moment from 'moment'
import "./Container.scss"
import scrollToElement from 'scroll-to-element';
import addLGCollectionTarget from '../../../../services/lGBillingCrud/addLGCollectionTarget';
import updateLGCollectionTarget from '../../../../services/lGBillingCrud/updateLGCollectionTarget'
import { yearlyQuarters } from '../../../../models/yearlyQuarters';
import { adminFeatureMapping } from '../../../../models/adminFeatureMapping';

const currentYear = new Date().getFullYear();

const LGCollectionTargetContainer = (props) => {
    const addLGCollectionTargetPath = "/addLGCollectionTarget"
    const lGCollectionTargetsListRoute = "/LGCollectionTargets" 

    const [adminType, setAdminType] = useState(null);
    const [adminId, setAdminId] = useState(null)
    const [addCollectionTarget, setAddCollectionTarget] = useState(true);
    const [loader, setLoader] = useState(false)
    const [currencyUnits, setCurrencyUnits] = useState(null)
    const [formValues, setFormValues] = useState({
        collectionTargetData : null,
        targetYear: null,
        businessLine : 0,
        availableBusinessLines: [],
        targetAmount: null,
        targetQuarter: 1,
        // achievedTarget: 0
    })

    const [formValidation, setFormValidation] = useState({
        businessLine : null,
        targetAmount: null,
        targetYear: null,
        targetQuarter: null
    })

    const getCurrencyUnits = (amt) => {
        let formattedValue, formattedUnit;
        if(!amt){
            formattedValue = amt
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1)
        }
        else
        if (amt >= 10000000) {
            formattedValue = (amt / 10000000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 10000000);
          } else if (amt >= 100000) {
            formattedValue = (amt / 100000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 100000);
          } else if (amt >= 1000) {
            formattedValue = (amt / 1000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1000);
          } else {
            formattedValue = amt;
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1);
        }
        
        return `${formattedValue} ${formattedUnit.unit}`
    }

    const getLGCollectionTarget = async (adminId, billId) => {
        fetchLGCollectionTargets(adminId, { billId })
        .then(info => {
            if(info.data && info.data.data){
                const { year, quarter } = getQuarterAndYearFromDate(info.data.data[0]["targetFromDate"]);
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["targetYear"]: year,
                    ["targetQuarter"]: quarter,
                    ["businessLine"] : info.data.data[0]["businessLine"],
                    ["targetAmount"]: info.data.data[0]["target"],
                    ["collectionTargetData"]: info.data.data[0],
                    // ["achievedTarget"]: info.data.data[0]["achievedTarget"]
                }))
                const convertedAmount = getCurrencyUnits(info.data.data[0]["target"])
                setCurrencyUnits(convertedAmount)
            }
        })
        .catch(err => {
            toast.error(err.response.data.message);
        });
    }

    const getBusinessLines = async(adminId) => {
        fetchBusinessLines(adminId, { })
        .then(res => {
            if(res.data){
                let resBuisenessLines = [{"id": 0, "name": "Select Business Line"}]
                Object.keys(res.data["BusinessLine"]).forEach(key => {
                    resBuisenessLines.push({"id": key, "name": res.data["BusinessLine"][key]})
                });
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["availableBusinessLines"]: resBuisenessLines
                }))
            }
        })
        .catch(err => {
            toast.error(err.response.data.message);
        });
    }

    const handleTargetYear= (year) =>{
        setFormValues((prevState) => ({
            ...prevState,
            ["targetYear"]: year
        }))
    }


    const handleBusinessLineChange = (event) => {
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["businessLine"]: val
        }))
    }

    const handleQuarterChange = (event) => {
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["targetQuarter"]: val
        }))
    }

    const handleTargetAmount = (event) => {
        let val = event.target.value
        const convertedAmount = getCurrencyUnits(val)
        setCurrencyUnits(convertedAmount)
        setFormValues((prevState) =>({
            ...prevState, 
            ["targetAmount"]: val
        }))
    }

    const getDateFromQuarterAndYear = () => {
        let startDate, endDate;
        const tYear = moment(formValues.targetYear).year()
        switch (parseInt(formValues.targetQuarter)) {
            case 4:
                startDate = moment([tYear, 0]).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                endDate = moment([tYear, 2]).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                break;
            case 1:
                startDate = moment([tYear-1, 3]).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                endDate = moment([tYear-1, 5]).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                break;
            case 2:
                startDate = moment([tYear-1, 6]).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                endDate = moment([tYear-1, 8]).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                break;
            case 3:
                startDate = moment([tYear-1, 9]).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                endDate = moment([tYear-1, 11]).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                break;
            default:
                throw new Error("Invalid quarter. Quarter must be between 1 and 4.");
        }
        return {startDate, endDate};
    }

    function getQuarterAndYearFromDate(dateString) {
        const date = moment(dateString, "YYYY-MM-DD");
        let fiscalYearStartDate = moment([parseInt(date.year())+1, 0]).startOf('month').format('YYYY-MM-DD');
        let fiscalYearStartDateObj = moment(fiscalYearStartDate, "YYYY-MM-DD")
        const year = fiscalYearStartDateObj.toDate() 
        const quarter = date.quarter();
        return { year, quarter };
    }

    const updateJob = () => {
        setLoader(true)
        const {startDate, endDate} = getDateFromQuarterAndYear()
        const data = {
            "businessLine": formValues.businessLine,
            "target": formValues.targetAmount,
            "targetFromDate": startDate,
            "targetToDate": endDate,
        }

        if(props.match.path == addLGCollectionTargetPath){
            addLGCollectionTarget(adminId, data)
            .then(res => {
                if (res.data.status == 'success') {
                    toast.info('Target addition success');
                    setTimeout(() => {
                        props.history.push(lGCollectionTargetsListRoute)
                        }, 1500);
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        else{
            updateLGCollectionTarget(adminId, props.match.params.id, data)
            .then(res => {
                if (res.data.status == 'success') {
                    toast.info('Updated successfully');
                    setTimeout(() => {
                        props.history.push(lGCollectionTargetsListRoute)
                        }, 1500);
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        setLoader(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const validation = {
            businessLine: (formValues.businessLine != null && formValues.businessLine != 0)? false: true,
            targetAmount: formValues.targetAmount != null ? false: true,
            targetQuarter: formValues.targetQuarter != null ? false: true,
            targetYear: formValues.targetYear != null ? false: true
        }

        setFormValidation(validation);

        let flag = 1
        for (var prop in validation) {
            if (validation[prop] == true) flag = 0;
        }

        if(flag == 0){
            if (validation.businessLine)
                scrollToElement('.businessLine');
            if (validation.targetAmount)
                scrollToElement('.targetAmount');
        }
        else{
            updateJob() 
        }
    }

    useEffect(()=>{
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(profile => {
            if (profile.adminType == 2 || profile.adminType == 3) {
                const adminAccess = user.checkFeatureAccess(adminFeatureMapping.LEARN_AND_GROW_UPDATE_BILLS);
                if(!adminAccess)
                    window.location.href='/notAdmin';
                const adminId = profile.id
                const type = profile.type;
                const adminType = profile.adminType;
                setAdminType(adminType)
                setAdminId(adminId)
                const billId = props.match.params.id
                getBusinessLines(adminId)
                if(props.match.path != addLGCollectionTargetPath){
                    alert("fetching details")
                    getLGCollectionTarget(adminId, billId)
                    setAddCollectionTarget(false)
                }                  
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, [])

    return (
        <>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />
            <div className='lGCollectionTargetFormContainer'>
                <div className='lGCollectionTargetFormHeadingContainer'>
                {
                    addCollectionTarget ? 
                    <>
                        <Heading text = "Add Collection Target"/>
                    </>
                    :
                    <Heading text = "Edit Collection Target"/>
                }
                </div>
                <form>
                    <div className='form-group targetQuarter'>
                        <label className='col-sm-2 left'>Quarter*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container'>
                                <Dropdown className='block-dropdown' data={yearlyQuarters} dropdownChange={handleQuarterChange} defaultValue={formValues.targetQuarter} /> 
                                {formValidation.targetQuarter ? <p className='validatorError'> Please Enter target quarter</p> : null}
                            </div>
                        </div>
                    </div>
                    <div className='form-group targetToDate'>
                        <label className='col-sm-2 left'>Target Year*</label>
                        <div className='col-sm-6 left valueBox'>
                            <DatePicker dateFormat="yyyy" showYearPicker yearItemNumber={5} minDate={new Date(currentYear, 0, 1)} selected={formValues.targetYear} onChange={(year) => handleTargetYear(year)}/>
                            {formValidation.targetYear ? <p className='validatorError'> Please Enter target year </p> : null}
                        </div>
                    </div>
                    <div className='form-group businessLine'>
                        <label className='col-sm-2 left'>Business Line*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container'>
                                <Dropdown className='block-dropdown' data={formValues.availableBusinessLines} name='available-business-lines' defaultValue={formValues.businessLine} dropdownChange={handleBusinessLineChange} />
                                {formValidation.businessLine ? <p className='validatorError'> Please Enter business line </p> : null}
                            </div>
                        </div>
                    </div>
                    <div className='form-group targetAmount'>
                        <label className='col-sm-2 left'>Target amount*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container lGCollectionTargetAmountInput'>
                                <div className='block-dropdown'>
                                    <InputBox type='number' value={formValues.targetAmount} name='target-amount' onChange={handleTargetAmount}/>
                                    {formValidation.targetAmount ? <p className='validatorError'> Please Enter target amount </p> : null}
                                </div>
                                <div className='block-dropdown right-dropdown'>
                                    <InputBox className='block-dropdown' value={currencyUnits} name='available-business-lines' placeholder = "units"/>
                                </div>
                            </div>
                        </div> 
                    </div>
                    {/* <div className='form-group Amount'>
                        <label className='col-sm-2 left'>Target achieved*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container targetAmountInput'>
                                <div className='block-dropdown'>
                                    <InputBox type='number' value={formValues.achievedTarget} name='target-amount'/>
                                </div>
                            </div>
                        </div> 
                    </div> */}
                </form>    
                <div className='submitButtonContainer'>
                    <Aux>
                        <Button skin='dark' disabled={false} text={props.match.path == addLGCollectionTargetPath ?'Submit': 'Update'} clickButton={(e) => handleSubmit(e)} />
                    </Aux>
                </div>
            </div>
        </>
    );
};

export default LGCollectionTargetContainer;