import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown } from 'semantic-ui-react';

import './PillButton.scss';

class PillButton extends Component {

    render () {
        return (
            <Dropdown 
                placeholder={this.props.placeholder}
                maximumValues={this.props.maximumValues}
                fluid
                openOnFocus
                multiple 
                maximumValues={this.props.maximumValues}
                search 
                name = {this.props.name}
                options={this.props.data} 
                onChange={this.props.onChange}
                onSearchChange={this.props.onSearchChange}
                defaultValue={this.props.defaultValue}
                loading={this.props.isLoading}
                //  value={this.props.value || []}
                selection
                onClick={this.props.onClick}
                onClose = {this.props.onClose}
                open={this.props.open}
                allowAdditions={this.props.allowAdditions}
                onAddItem={this.props.onAddOtherLocation}
                error={this.props.error}
            />
        )

    }
}

PillButton.propTypes = {
    placeholder: PropTypes.string,
    maximumValues: PropTypes.number,
    name: PropTypes.string,
    data: PropTypes.array,
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    defaultValue: PropTypes.array,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    allowAdditions: PropTypes.bool,
    onAddOtherLocation: PropTypes.func,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default PillButton