import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Loader from '../../components/Loader';
import TableItem from './Components/TableItem';

import user from '../../store/user';
import updateRecruiterProfile from '../../services/updateRecruiterProfile';
import fetchManagedRecruiters from '../../services/fetchManagedRecruiters';
import fetchManagedRecruitersCount from '../../services/fetchManagedRecruitersCount';

import './ManagerList.scss';

class ManagerList extends Component {
    state = {
        adminId: null,
        pageNumber: 1,
        pageContent: 50,
        searchtext: null,
        start: 0,
        mgrList: [],
        total: 0,
        isLoading: true,
        isPageLoading: false,
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef(null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.history && this.props.history.action === 'POP') {
                this.onRouteChange(this.props);
            }
        }
    }

    onRouteChange = (props) => {
        let queryString = props.location.search;
        const obj = {};

        queryString = queryString.replace('?', '');
        queryString = queryString.split('&');
        for (let i = 0; i < queryString.length; i++) {
            const temp = queryString[i].split('=');
            obj[temp[0]] = temp[1];
        }

        const state = this.state;
        state.pageNumber = 1;
        state.searchtext = null;

        if (obj['pg']) {
            state.pageNumber = obj['pg'];
        }

        if (obj['st']) {
            state.start = obj['st'];
        }

        if (obj['searchtext']) {
            state.searchtext = obj['searchtext'];
        } 

        this.setState(state);
        this.getRecruitersList();
        this.getRecruitersCount();

    }

    checkForAdminUser = (type) => {
        if (type == 2 || type == 3 || type == 4) {
            return true;
        }
        return false;
    }

    componentDidMount = async () => {
        const profile = await user.getProfile();
        this.setState({ adminId: profile.id });

        if (profile.type != 1 && !this.checkForAdminUser(profile.adminType)) {
            window.location.href = '/notAdmin';
        }
        
        this.onRouteChange(this.props);
    }

    checkIfNumeric = (text) => {
        return !isNaN(text) && !isNaN(parseInt(text));
    }

    getRecruitersList = async () => {
        const params = {
            pageNumber: this.state.pageNumber,
            pageContent: this.state.pageContent,
        };

        if (this.state.searchtext) {
            this.inputRef.current.value = this.state.searchtext;

            if (this.checkIfNumeric(this.state.searchtext)) {
                params.id = this.state.searchtext;
            } else {
                params.email = this.state.searchtext;
            }
        }
    
        const response = await fetchManagedRecruiters(this.state.adminId, params);

        if(response.data) {
            this.setState({
                mgrList: response.data,
                isLoading: false,
                isPageLoading: false,
            })
        }
    }

    getRecruitersCount = async () => {
        const params = {};

        if (this.state.searchtext) {
            if (this.checkIfNumeric(this.state.searchtext)) {
                params.id = this.state.searchtext;
            } else {
                params.email = this.state.searchtext;
            }
        }
        const response = await fetchManagedRecruitersCount(this.state.adminId, params);

        if (response.data) {
            this.setState({ total: response.data.count });
        }
    }

    handlePageChange = (page) => {
        const state = this.state;
        state.isPageLoading = true;
        state.pageNumber = page;
        this.setState(state);

        this.changeRoute();
        this.getRecruitersList();
    }

    onKeyPressHandler(event) {
        if (event.key === 'Enter') {
            this.onSearchHandler();
        }
    }

    onSearchHandler() {
        let searchtext = this.inputRef.current.value;
        
        if (searchtext) {
            searchtext = searchtext.trim();
        }
        const state = this.state;
        state.pageNumber = 1;
        state.isPageLoading = true;
        state.searchtext = searchtext;
        this.setState(state);

        this.changeRoute();
        this.getRecruitersList();
        this.getRecruitersCount();
    }

    onRemoveManagerHandler = async (recruiterId) => {
        const response = await updateRecruiterProfile(this.state.adminId, recruiterId, { 'mgrStatus': 0 });

        if (response && response.status === 200) {
            toast.success('Profile updated successfully');
            const state = this.state;
            state.isPageLoading = true;
            this.setState(state);
            this.getRecruitersList();
        } else {
            toast.error('Failed to update profile');
        }
    }

    changeRoute = () => {
        let array = [];
        array.push('st=' + this.state.start);
        array.push('pg=' + this.state.pageNumber);

        if(this.state.searchtext) {
            array.push('searchtext=' + this.state.searchtext);
        }

        array = array.join('&');
        this.props.history.push('/recruiter/mgr_mgt?' + array);
    }

    render() {
        const {
            mgrList,
            isPageLoading,
            isLoading,
        } = this.state;

        const searchBoxContent = (
            <div className='search-container'>
                <div className='search-container--input'>
                    <InputBox type='text' _ref={this.inputRef} placeholder="search with id/email" onKeyPress={this.onKeyPressHandler.bind(this)}/>
                    <Button text='Search' skin='dark' clickButton={this.onSearchHandler.bind(this)} />
                </div>
                <div>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/mgr_mgt.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
                </div>
            </div>
        );

        const tableHeading = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="one">id</Table.HeaderCell>
                    <Table.HeaderCell width="two">Name</Table.HeaderCell>
                    <Table.HeaderCell width="one">Email</Table.HeaderCell>
                    <Table.HeaderCell width="one">Phone</Table.HeaderCell>
                    <Table.HeaderCell width="two">Organisation</Table.HeaderCell>
                    <Table.HeaderCell width="two">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )

        let topPageIndicators, bottomPageIndicators;

        if (!isLoading && mgrList.length > 0) {
            // there is some data
            topPageIndicators = (
                <div className='row text-center'>
                    <Pagination 
                        totalItemsCount={this.state.total}
                        content={this.state.pageContent} 
                        pageNumber={this.state.pageNumber} 
                        handlePageChange={this.handlePageChange} />

                    <CountDisplay
                        start={(this.state.pageNumber - 1) * this.state.pageContent + 1}
                        end={this.state.pageNumber * this.state.pageContent}
                        total={this.state.total}
                    />
                </div>
            )
        } 
        if (!isPageLoading && mgrList.length > 0) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination
                        totalItemsCount={this.state.total}
                        content={this.state.pageContent}
                        pageNumber={this.state.pageNumber}
                        handlePageChange={this.handlePageChange} />
                </div>
            )
        }

        let mainContent;
        if (isPageLoading) {
            mainContent = (
                <>
                    <Table>{tableHeading}</Table>
                    <Loader />
                </>
            );
        } else if(!mgrList || mgrList.length === 0) {
            mainContent = (
                <div>
                    No record found!
                </div>
            );
        } else {
            mainContent = (
                <>
                    <div>
                        <Table>
                            {tableHeading}
                            <tbody>
                                {mgrList.map((item) => {
                                    const data = {
                                        ...item,
                                        email: '---',
                                        phone: '---',
                                        name: item.recname,
                                    };
                                    return <TableItem key={item.id} data={data} onRemoveManager={this.onRemoveManagerHandler}/>
                                })}
                            </tbody>

                        </Table>
                    </div>
                </>
            )
        }

        return (
            <div className='page-content'>
                <ToastContainer
                    position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover
                />

                <div className='page-heading'>
                    <Heading text='Manager Management' />
                </div>

                {searchBoxContent}

                {isLoading ? <Loader /> : (
                    <>
                        {topPageIndicators}
                        <div className='row'>
                            {mainContent}
                        </div>
                        {bottomPageIndicators}
                    </>
                )}
            </div>
        );
    }
}

export default ManagerList;