const age = [
    {name: '22', id: 22}, 
    {name: '23', id: 23}, 
    {name: '24', id: 24}, 
    {name: '25', id: 25}, 
    {name: '26', id: 26}, 
    {name: '27', id: 27}, 
    {name: '28', id: 28}, 
    {name: '29', id: 29}, 
    {name: '30', id: 30}, 
    {name: '31', id: 31}, 
    {name: '32', id: 32}, 
    {name: '33', id: 33}, 
    {name: '34', id: 34}, 
    {name: '35', id: 35}, 
    {name: '36', id: 36}, 
    {name: '37', id: 37}, 
    {name: '38', id: 38}, 
    {name: '39', id: 39}, 
    {name: '40', id: 40}, 
    {name: '41', id: 41}, 
    {name: '42', id: 42}, 
    {name: '43', id: 43}, 
    {name: '44', id: 44}, 
    {name: '45', id: 45}, 
    {name: '46', id: 46}, 
    {name: '47', id: 47}, 
    {name: '48', id: 48}, 
    {name: '49', id: 49}, 
    {name: '50', id: 50}, 
    {name: '51', id: 51}, 
    {name: '52', id: 52}, 
    {name: '53', id: 53}, 
    {name: '54', id: 54}, 
    {name: '55', id: 55}, 
    {name: '56', id: 56}, 
    {name: '57', id: 57}, 
    {name: '58', id: 58}, 
    {name: '59', id: 59}, 
    {name: '60', id: 60}, 
    {name: '61', id: 61}, 
    {name: '62', id: 62}, 
    {name: '63', id: 63}, 
    {name: '64', id: 64}, 
    {name: '65', id: 65}

]

const copyMinAge = [...age]
copyMinAge.unshift({ id: '', name: 'Min Age' })
export const minAge = copyMinAge;

const copyMaxAge = [...age]
copyMaxAge.unshift({ id: '', name: 'Max Age' });
export const maxAge = copyMaxAge;