import React, { useEffect, useState } from "react";
import getSearchQueryReport from "../../services/getSearchQueryReport";
import updateSearchQueryReport from "../../services/updateSearchQueryReport";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import Moment from "react-moment";
import InputBox from '../../components/InputBox';

import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';

const UsersAnalysis = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [page, search]);

    async function getData() {
        try {
            setIsLoading(true);
            const profile = await user.getProfile();
            const resp = await getSearchQueryReport(profile.id, {
                pageNo: page,
                search
            });
            setData(resp.data.data.data);
            setCount(resp.data.data.count);
            setIsLoading(false);
        }
        catch (err) {
            console.log("Err");
            console.log(err);
            setIsLoading(false);

        }
    }

    async function updateStatus(item) {
        try {
            console.log("update --", item);
            const profile = await user.getProfile();
            const newStatus = item.status ? 0 : 1;

            try {
                const resp = await updateSearchQueryReport(profile.id, item.id, {
                    status: newStatus
                });
                const d = data.map(i => {
                    if (i.id == item.id) {
                        return {
                            ...item,
                            status: newStatus
                        }
                    }
                    return i;
                })
                setData(d);
                toast.success("Updated Successfully!");
            } catch (err) {
                console.log(err);
                toast.error("Update Failed!");
            }

        }
        catch (err) {
            console.log("Err");
            console.log(err);
        }
    }

    async function handlePageChange(page) {
        setPage(page);
    }

    function onChange(ev) {
        setText(ev.target.value);
    }

    function onClickSearch() {
        setPage(1);
        setSearch(text);
    }

    function onClickClear() {
        setText("");
        setPage(1);
        setSearch("");
    }

    function handleKeyPress(ev) {
        if (ev.key == 'Enter') {
            onClickSearch();
        }
    }

    return (
        <div className="search-query-report">

            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className="pageHeading">
                <Heading text='Search Query Report' />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/searchqueryreport.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>

            <div className='row text-center filter-panel'>
                <div className='right search-field'>
                    <InputBox placeholder='Enter recruiter id or email' value={text} onChange={onChange} onKeyPress={handleKeyPress} />
                    <Button skin='dark' disabled={false} text='Search' clickButton={onClickSearch} />
                    <Button skin='light' disabled={false} text='Clear' clickButton={onClickClear} ></Button>
                </div>

                {
                    data.length > 1 ?
                        <>
                            <Pagination totalItemsCount={count} content={50} pageNumber={page} handlePageChange={handlePageChange} />
                            <CountDisplay start={(page - 1) * 50 + 1} end={page * 50} total={count} />
                        </> : null
                }
            </div>
            {
                <div className="row">
                    {
                        data.length ?
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Query Comment</Table.HeaderCell>
                                        <Table.HeaderCell>Recruiter Info</Table.HeaderCell>
                                        <Table.HeaderCell>View Query</Table.HeaderCell>
                                        <Table.HeaderCell>Action Date</Table.HeaderCell>
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        data.map(item => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.comment}</Table.Cell>
                                                    <Table.Cell>
                                                        <div>{item.recid}</div>
                                                        <div>{item.recname}</div>
                                                        <div>{item.email}</div>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <a href={item.querytext} target="_blank">View</a>
                                                    </Table.Cell>
                                                    <Table.Cell><Moment format="YYYY-MM-DD">{item.actiondate}</Moment></Table.Cell>
                                                    <Table.Cell>
                                                        <Button
                                                            clickButton={() => updateStatus(item)}
                                                            skin="dark" text={item.status ? "Deactivate" : "Activate"} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }

                                </Table.Body>
                            </Table> : null
                    }
                </div>

            }
            {
                isLoading ?
                    <Loader /> : null
            }
            {
                !isLoading && !data.length ?
                    <div className="no-data"> No Records Found </div> : null
            }
        </div >
    )

}

export default UsersAnalysis;