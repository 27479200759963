import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, Table, Checkbox }from 'semantic-ui-react';

import Spinner from '../../../../components/Spinner';
import InputBox from '../../../../components/InputBox';
import Button from '../../../../components/Button';

import './styles.scss';

const AdminAccessModal = (props) => {
    const [searchText, setSearchText] = useState('');
    const [featureList, setFeatureList] = useState([]);

    const data = props.modalData;

    useEffect(() => {
        let featureArr = [];

        if (!searchText) {
            featureArr = data.adminFeatureMap;
        } else {
            featureArr = data.adminFeatureMap.filter(item => {
                if ((item.pageCode && item.pageCode.toLowerCase().includes(searchText.toLowerCase())) || 
                    (item.label && item.label.toLowerCase().includes(searchText.toLowerCase()))){
                        return true; 
                } else {
                    return false;
                }
            });
        }

        setFeatureList(featureArr);
    }, [searchText]);

    const handleSearchClear = () => {
        setSearchText('');
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchText(value);
    };

    return (
        <Modal
            className='admin-access-modal'
            open={props.openModal && props.modalData!==null}
            onClose={props.onModalClose}
            size="large">
                
            {props.modalData && (
                <>
                    <Modal.Header content="Enable Admin Dashboard Access for Recruiter" />
                    <Modal.Content scrolling>
                        <div className='search__controller'>
                            <InputBox
                                type="text"
                                value={searchText} 
                                placeholder="Search by Page Code or Feature Name"
                                onChange={handleSearchChange}
                            />
                            <Button text="Clear" skin="dark" clickButton={handleSearchClear}/>
                        </div>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width="one">Feature</Table.HeaderCell>
                                    <Table.HeaderCell width="one">Description</Table.HeaderCell>
                                    <Table.HeaderCell width="one">Enable</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {featureList.map(item => {
                                    if (props.modalData.isBulkAction && item.pageCode === 'DISPLAY_TAGS') {
                                        return;
                                    }
                                    return (
                                        <Table.Row>
                                            <Table.Cell width="one" verticalAlign="top">{item.label}</Table.Cell>
                                            <Table.Cell width="one" verticalAlign="top">{item.desc}</Table.Cell>
                                            <Table.Cell width="one" verticalAlign="top">
                                                <>
                                                    {!props.modalData.isBulkAction && item.pageCode === 'DISPLAY_TAGS' ? (
                                                        <InputBox 
                                                            value={props.modalData.overrideInitials} 
                                                            onChange={props.onDisplayTagChange}
                                                        />
                                                    ) : (
                                                        <Checkbox 
                                                            defaultChecked={props.modalData.featuresAccess && props.modalData.featuresAccess.includes(item.id.toString())} 
                                                            onChange={(event, data) => {
                                                                props.onAccessChange(data, item.id);
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}

                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        {props.isLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Button clickButton={props.onModalSave} skin="dark" text="Save" />
                                <Button clickButton={props.onModalClose} skin="light" text="Close" />
                            </>
                        )}
                    </Modal.Actions>
                </>
            )}
        </Modal>
    );
};

AdminAccessModal.propTypes = {
    openModal: PropTypes.bool,
    modalData: PropTypes.object.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onDisplayTagChange: PropTypes.func,
    onAccessChange: PropTypes.func,
    onModalSave: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default AdminAccessModal;