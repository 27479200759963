import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const ProjectsList = Loadable({
  loader: () => import('../Container'),
  loading: Loading,
});

const ProjectsListing = () => (
    <ProjectsList />
)

ProjectsListing.propTypes = {
};

export default ProjectsListing;
