import React, { useState, useEffect, useRef } from "react";
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import "./resetPasswordLink.scss";
import user from "../../store/user";
import resetPasswordLink from "../../services/resetPasswordLink";

const ResetPasswordLink = (props) => {
    const [email, setEmail] = useState("");
    const [apiCall, setApiCall] = useState(false);
    const [apiResp, setApiResp] = useState("");
    const [profile, setProfile] = useState({});
    const [link, setLink] = useState("");
    const textArea = useRef(null);

    useEffect(() => {

        (async () => {
            const data = await user.getProfile();
            setProfile(data);
        })();

    }, []);

    function onChange(key, ev) {
        if (key == "email") {
            setEmail(ev.target.value);
        }
    }

    function copyLink(ev) {
        /* Select the text field */
        textArea.current.select();
        textArea.current.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    async function onSubmit() {

        if (!email.trim().length) {
            return;
        }

        let data = {}
        if (+email) {
            data.recruiterId = email;
        } else {
            data.email = email;
        }
        setApiCall(true);
        setApiResp("");
        setLink("");
        try {
            const res = await resetPasswordLink(profile.id, data);
            console.log(res);
            setApiCall(false);
            setLink(res.data.data);
            setApiResp("Link Generated Successfully");
        }
        catch (err) {
            console.log(err);
            setApiCall(false);
            setApiResp("Account not found");
        }

    }

    const onEnterPress = (e) => {
        if(e.key == 'Enter') {
            onSubmit();
        }
    }

    return (
        <div className="change-password">
            <h3>Recruiter Reset Password Link Generation</h3>
            <br />
            <label>Email/RecruiterId</label>
            <InputBox type='text' value={email} placeholder='Email or Recruiter Id' onChange={onChange.bind(null, "email")} onKeyPress={onEnterPress} />
            <br />
            <br />
            <Button skin='dark' disabled={apiCall} text="Generate" clickButton={() => { onSubmit() }} />
            {
                link ?
                    <div style={{ position: "relative" }}>
                        <textarea ref={textArea} onClick={copyLink} value={link}></textarea>
                        <span className="info" onClick={copyLink}>Click To Copy</span>
                    </div> : null
            }
            <div className="api-response">
                {apiResp}
            </div>
        </div>
    )

}

export default ResetPasswordLink;