var institutesHirist = [
    {
        "id": 1,
        "name": "Aalim Muhammed Salegh College of Engineering, Chennai"
    },
    {
        "id": 2,
        "name": "ABES Engineering College, Ghaziabad"
    },
    {
        "id": 3,
        "name": "ABV- Indian Institute of Information Technology and Management, Gwalior"
    },
    {
        "id": 4,
        "name": "Acharya Institute of Technology, Bangalore"
    },
    {
        "id": 5,
        "name": "AD Patel Institute of Technology, Anand"
    },
    {
        "id": 6,
        "name": "Adesh Institute of Engineering and Technology, Faridkot"
    },
    {
        "id": 7,
        "name": "Adhiparasakthi Engineering College, Kancheepuram"
    },
    {
        "id": 11,
        "name": "Aditya Engineering College, Surampalem"
    },
    {
        "id": 14,
        "name": "Aditya Institute of Technology and Management, Tekkali"
    },
    {
        "id": 15,
        "name": "Ajay Kumar Garg Engineering College, Ghaziabad"
    },
    {
        "id": 16,
        "name": "Alagappa Chettiar College of Engineering and Technology, Karaikudi"
    },
    {
        "id": 17,
        "name": "Alagappa College of Technology, Chennai"
    },
    {
        "id": 18,
        "name": "Aligarh Muslim University, Aligarh"
    },
    {
        "id": 19,
        "name": "Alliance College of Engineering and Design, Bangalore"
    },
    {
        "id": 20,
        "name": "Ambedkar Institute of Advanced Communication Technologies and Research, Delhi"
    },
    {
        "id": 21,
        "name": "Amity School of Engineering and Technology, Delhi"
    },
    {
        "id": 22,
        "name": "Amity School of Engineering and Technology, Noida"
    },
    {
        "id": 23,
        "name": "Amity University, Noida"
    },
    {
        "id": 24,
        "name": "Amrita School of Engineering, Coimbatore"
    },
    {
        "id": 25,
        "name": "Anand Institute of Higher Technology, Chennai"
    },
    {
        "id": 26,
        "name": "Anil Neerukonda Institute of Technology and Sciences, Vishakhapatnam"
    },
    {
        "id": 103,
        "name": "Anna University Chennai, CEG Campus"
    },
    {
        "id": 27,
        "name": "Anna University Tiruchirappalli, BIT Campus"
    },
    {
        "id": 28,
        "name": "Annamacharya Institute of Technology and Sciences, Rajampet"
    },
    {
        "id": 29,
        "name": "Arunai Engineering College, Tiruvannamalai"
    },
    {
        "id": 30,
        "name": "Asansol Engineering College, Asansol"
    },
    {
        "id": 31,
        "name": "Assam University, Silchar"
    },
    {
        "id": 33,
        "name": "AU College of Engineering for Women, Vishakapattnam"
    },
    {
        "id": 34,
        "name": "AU College of Engineering, Vishakhapatnam"
    },
    {
        "id": 35,
        "name": "Audisankara College of Engineering and Technology, Gudur"
    },
    {
        "id": 36,
        "name": "Aurora's Engineering College, Nalgonda"
    },
    {
        "id": 37,
        "name": "Aurora's Scientific, Technological and Research Academy, Hyderabad"
    },
    {
        "id": 38,
        "name": "Aurora's Technological and Research Institute, Hyderabad"
    },
    {
        "id": 39,
        "name": "Avanthi Institute of Engineering and Technology, Vishakhapatnam"
    },
    {
        "id": 40,
        "name": "B K Birla Institute of Engineering and Technology, Pilani"
    },
    {
        "id": 41,
        "name": "Baddi University of Emerging Sciences and Technologies, Baddi"
    },
    {
        "id": 42,
        "name": "Banasthali University, Banasthali"
    },
    {
        "id": 43,
        "name": "Bangalore Institute of Technology, Bangalore"
    },
    {
        "id": 44,
        "name": "Bankura Unnayani Institute of Engineering, Bankura"
    },
    {
        "id": 45,
        "name": "Bannari Amman Institute of Technology, Erode"
    },
    {
        "id": 46,
        "name": "Bapatala Engineering College, Bapatala"
    },
    {
        "id": 47,
        "name": "Bapuji Institute of Engineering and Technology, Davangere"
    },
    {
        "id": 49,
        "name": "Barkatullah University Institute of Technology, Barkatullah University, Bhopal"
    },
    {
        "id": 50,
        "name": "Basaveshwar Engineering College, Bagalkot"
    },
    {
        "id": 51,
        "name": "Beant College of Engineering and Technology, Gurdaspur"
    },
    {
        "id": 52,
        "name": "Bhagalpur College of Engineering, Bhagalpur"
    },
    {
        "id": 55,
        "name": "Bharath University, Chennai"
    },
    {
        "id": 56,
        "name": "Bharati Vidyapeeth Deemed University College of Engineering, Pune"
    },
    {
        "id": 57,
        "name": "Bharati Vidyapeeth's College of Engineering, Delhi"
    },
    {
        "id": 58,
        "name": "Bhartiya Vidya Bhavans, Sardar Patel College of Engineering, Mumbai"
    },
    {
        "id": 59,
        "name": "Bhilai Institute of Technology, Durg"
    },
    {
        "id": 60,
        "name": "Bhilai Institute of Technology, Raipur"
    },
    {
        "id": 62,
        "name": "Bidhan Chandra Krishi Viswa Vidyalaya, Nadia"
    },
    {
        "id": 63,
        "name": "Bipin Tripathi Kumaon Institute of Technology, Dwarahat"
    },
    {
        "id": 64,
        "name": "Birbhum Institute of Engineering Technology, Birbhum"
    },
    {
        "id": 53,
        "name": "Birla Institute of Technology and Science, Pilani"
    },
    {
        "id": 65,
        "name": "Birla Institute of Technology, Mesra"
    },
    {
        "id": 66,
        "name": "Birla Institute of Technology, Patna"
    },
    {
        "id": 67,
        "name": "BIT Sindri, Dhanbad"
    },
    {
        "id": 68,
        "name": "BITS Pilani- Hyderabad Campus, Hyderabad"
    },
    {
        "id": 69,
        "name": "BITS Pilani, Goa Campus"
    },
    {
        "id": 70,
        "name": "BMS College of Engineering, Bangalore"
    },
    {
        "id": 71,
        "name": "BMS Institute of Technology, Bangalore"
    },
    {
        "id": 72,
        "name": "BNM Institute of Technology, Bangalore"
    },
    {
        "id": 102,
        "name": "BS Abdur Rahman University, Chennai"
    },
    {
        "id": 73,
        "name": "Bundelkhand Institute of Engineering and Technology, Jhansi"
    },
    {
        "id": 74,
        "name": "BV Bhoomaraddi College of Engineering and Technology, Hubli"
    },
    {
        "id": 75,
        "name": "BVRIT Padmasri Dr BV Raju Institute of Technology, Hyderabad"
    },
    {
        "id": 76,
        "name": "CBS Group of Instiutions, Jhajjar"
    },
    {
        "id": 77,
        "name": "Central Institute of Plastic Engineering Technology, Lucknow"
    },
    {
        "id": 78,
        "name": "Central Institute of Plastics Engineering and Technology, Chennai"
    },
    {
        "id": 79,
        "name": "Centurion University, Paralakhemundi"
    },
    {
        "id": 80,
        "name": "Chaitanya Bharathi Institute of Technology, Hyderabad"
    },
    {
        "id": 81,
        "name": "Chaitanya Engineering College, Vishakhapatnam"
    },
    {
        "id": 82,
        "name": "Chandigarh College of Engineering and Technology, Chandigarh"
    },
    {
        "id": 83,
        "name": "Charotar University of Science and Technology, Petlad"
    },
    {
        "id": 84,
        "name": "Chaudhary Charan Singh Haryana Agricultural University, Hisar"
    },
    {
        "id": 85,
        "name": "Chitkara University, Patiala"
    },
    {
        "id": 86,
        "name": "Christ University, Bangalore"
    },
    {
        "id": 87,
        "name": "CMR Engineering College, Hyderabad"
    },
    {
        "id": 88,
        "name": "CMR Institute of Technology, Bangalore"
    },
    {
        "id": 90,
        "name": "CMR Institute of Technology, Hyderabad"
    },
    {
        "id": 91,
        "name": "CMR Technical Campus, Hyderabad"
    },
    {
        "id": 92,
        "name": "Coimbatore Institute of Technology, Coimbatore"
    },
    {
        "id": 93,
        "name": "College of Dairy Science and Technology, Mannuthi, Thrissur"
    },
    {
        "id": 101,
        "name": "College of Engineering Adoor"
    },
    {
        "id": 104,
        "name": "College of Engineering and Management, Kolaghat"
    },
    {
        "id": 105,
        "name": "College of Engineering and Technology ,Bhubaneswar"
    },
    {
        "id": 107,
        "name": "College of Engineering Roorkee, Roorkee"
    },
    {
        "id": 108,
        "name": "College of Engineering Studies, University of Petroleum and Energy Studies, Dehradun"
    },
    {
        "id": 109,
        "name": "College of Engineering, Chengannur"
    },
    {
        "id": 113,
        "name": "College of Engineering, Pune"
    },
    {
        "id": 119,
        "name": "College of Engineering, Trivandrum"
    },
    {
        "id": 121,
        "name": "College of Technology and Engineering, Maharana Pratap University of Agriculture and Technology, Udaipur"
    },
    {
        "id": 127,
        "name": "College of Technology, GB Pant University of Agriculture and Technology, Pantnagar"
    },
    {
        "id": 132,
        "name": "CV Raman College of Engineering, Bhubaneswar"
    },
    {
        "id": 138,
        "name": "CVR College of Engineering, Hyderabad"
    },
    {
        "id": 139,
        "name": "DAV Institute of Engineering and Technology, Jalandhar"
    },
    {
        "id": 140,
        "name": "Dayalbagh Educational Institute, Agra"
    },
    {
        "id": 141,
        "name": "Dayanand Sagar College of Engineering, Bangalore"
    },
    {
        "id": 142,
        "name": "Deenbandhu Chhotu Ram University of Science and Technology, Murthal"
    },
    {
        "id": 110,
        "name": "Delhi Technological University, Delhi"
    },
    {
        "id": 147,
        "name": "Dharmsinh Desai University, Nadiad"
    },
    {
        "id": 111,
        "name": "Dhirubhai Ambani Institute of Information and Communication Technology, Gandhinagar"
    },
    {
        "id": 149,
        "name": "DIT University, Dehradun"
    },
    {
        "id": 155,
        "name": "Don Bosco Institute of Technology, Mumbai"
    },
    {
        "id": 156,
        "name": "Dr B R Ambedkar National Institute of Technology, Jalandhar (NITJ)"
    },
    {
        "id": 157,
        "name": "Dr S and SS Ghandhy College of Engineering and Technology, Surat"
    },
    {
        "id": 158,
        "name": "Dr. Ambedkar Institute of Technology for Handicapped, Kanpur  "
    },
    {
        "id": 159,
        "name": "Dr. Ambedkar Institute of Technology, Bangalore"
    },
    {
        "id": 160,
        "name": "Dr. Babasaheb Ambedkar Technological University, Lonere"
    },
    {
        "id": 161,
        "name": "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Banda"
    },
    {
        "id": 162,
        "name": "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Bijnor"
    },
    {
        "id": 163,
        "name": "Dr. Mahalingam College of Engineering and Technology, Pollachi"
    },
    {
        "id": 164,
        "name": "Dr. Sivanthi Aditanar College of Engineering, Thoothukudi"
    },
    {
        "id": 165,
        "name": "Dronacharya College of Engineering, Gurgaon"
    },
    {
        "id": 166,
        "name": "Easwari Engineering College, Chennai"
    },
    {
        "id": 167,
        "name": "Faculty of Engineering and Technology, Manav Rachna International University, Faridabad"
    },
    {
        "id": 168,
        "name": "Feroze Gandhi Institute of Engineering and Technology, Raibareli"
    },
    {
        "id": 169,
        "name": "Fr. C Rodrigues Institute of Technology, Navi Mumbai"
    },
    {
        "id": 170,
        "name": "Fr. Conceicao Rodrigues College of Engineering, Mumbai"
    },
    {
        "id": 171,
        "name": "G Narayanamma Institute of Technology and Science (For Women), Hyderabad"
    },
    {
        "id": 172,
        "name": "G Pulla Reddy Engineering College, Kurnool"
    },
    {
        "id": 173,
        "name": "Galgotias College of Engineering and Technology, Greater Noida"
    },
    {
        "id": 174,
        "name": "Galgotias University, Greater Noida"
    },
    {
        "id": 175,
        "name": "Gandhi Institute of Engineering and Technology, Gunupur"
    },
    {
        "id": 176,
        "name": "Gayatri Vidya Parishad College of Engineering, Vishakhapatnam"
    },
    {
        "id": 177,
        "name": "GH Patel College of Engineering and Technology, Anand"
    },
    {
        "id": 178,
        "name": "GH Raisoni College of Engineering, Nagpur"
    },
    {
        "id": 179,
        "name": "Giani Zail Singh Punjab Technical University Campus, Bathinda"
    },
    {
        "id": 180,
        "name": "GLA University, Mathura"
    },
    {
        "id": 181,
        "name": "GMR Institute of Technology, Rajam"
    },
    {
        "id": 182,
        "name": "Gokaraju Rangaraju Institute of Engineering and Technology, Hyderabad"
    },
    {
        "id": 183,
        "name": "Government College of Engineering and Ceramic Technology, Kolkata"
    },
    {
        "id": 184,
        "name": "Government College of Engineering and Leather Technology,Kolkata"
    },
    {
        "id": 185,
        "name": "Government College of Engineering and Technology, Bikaner"
    },
    {
        "id": 186,
        "name": "Government College of Engineering and Technology, Jammu"
    },
    {
        "id": 187,
        "name": "Government College of Engineering and Textile Technology, Berhampore"
    },
    {
        "id": 188,
        "name": "Government College of Engineering, Aurangabad"
    },
    {
        "id": 189,
        "name": "Government College of Engineering, Bargur"
    },
    {
        "id": 190,
        "name": "Government College of Engineering, Kalahandi"
    },
    {
        "id": 191,
        "name": "Government College of Engineering, Kannur"
    },
    {
        "id": 192,
        "name": "Government College of Engineering, Karad"
    },
    {
        "id": 193,
        "name": "Government College of Engineering, Salem"
    },
    {
        "id": 194,
        "name": "Government College of Engineering, Tirunelveli"
    },
    {
        "id": 195,
        "name": "Government College of Technology, Coimbatore"
    },
    {
        "id": 196,
        "name": "Government Engineering College, Ajmer"
    },
    {
        "id": 197,
        "name": "Government Engineering College, Barton Hill, Thiruvananthapuram "
    },
    {
        "id": 198,
        "name": "Government Engineering College, Bastar"
    },
    {
        "id": 199,
        "name": "Government Engineering College, Bhavnagar"
    },
    {
        "id": 200,
        "name": "Government Engineering College, Bikaner"
    },
    {
        "id": 201,
        "name": "Government Engineering College, Bilaspur"
    },
    {
        "id": 202,
        "name": "Government Engineering College, Gandhinagar"
    },
    {
        "id": 203,
        "name": "Government Engineering College, Jhalawar"
    },
    {
        "id": 204,
        "name": "Government Engineering College, Kozhikode"
    },
    {
        "id": 205,
        "name": "Government Engineering College, Modasa"
    },
    {
        "id": 206,
        "name": "Government Engineering College, Patan"
    },
    {
        "id": 207,
        "name": "Government Engineering College, Raipur"
    },
    {
        "id": 208,
        "name": "Government Engineering College, Rajkot"
    },
    {
        "id": 209,
        "name": "Government Engineering College, Sreekrishnapuram"
    },
    {
        "id": 210,
        "name": "Government Engineering College, Surat"
    },
    {
        "id": 211,
        "name": "Government Engineering College, Thrissur"
    },
    {
        "id": 212,
        "name": "Government Engineering College, Wayanad"
    },
    {
        "id": 125,
        "name": "Government Model Engineering College, Thrikkakara"
    },
    {
        "id": 213,
        "name": "Government Sri Krishnarajendra Silver Jubilee Technological Institute, Bangalore"
    },
    {
        "id": 214,
        "name": "Government Women Engineering College, Ajmer"
    },
    {
        "id": 215,
        "name": "Govind Ballabh Pant Engineering College, Pauri-Garhwal"
    },
    {
        "id": 216,
        "name": "Gudlavalleru Engineering College, Gudlavalleru"
    },
    {
        "id": 217,
        "name": "Guru Ghasidas Vishwavidyalaya, Bilaspur"
    },
    {
        "id": 116,
        "name": "Guru Gobind Singh Indraprastha University, Delhi"
    },
    {
        "id": 218,
        "name": "Guru Jambheshwar University of Science and Technology, Hisar"
    },
    {
        "id": 219,
        "name": "Guru Nanak Dev Engineering College, Bidar"
    },
    {
        "id": 220,
        "name": "Guru Nanak Dev Engineering College, Ludhiana"
    },
    {
        "id": 221,
        "name": "Guru Nanak Dev University Regional Campus Jalandhar"
    },
    {
        "id": 222,
        "name": "Guru Nanak Dev University, Amritsar"
    },
    {
        "id": 223,
        "name": "Guru Nanak Institutions Technical Campus, Secunderabad"
    },
    {
        "id": 224,
        "name": "Gurukul Kangri Vishwavidyalaya, Haridwar"
    },
    {
        "id": 225,
        "name": "Haldia Institute of Technology, Haldia"
    },
    {
        "id": 114,
        "name": "Harcourt Butler Technological Institute, Kanpur"
    },
    {
        "id": 226,
        "name": "Heritage Institute of Technology, Kolkata"
    },
    {
        "id": 227,
        "name": "Hindu College of Engineering, Sonipat"
    },
    {
        "id": 228,
        "name": "Hindustan University, Kelambakkam"
    },
    {
        "id": 229,
        "name": "Hindusthan College of Engineering and Technology, Coimbatore"
    },
    {
        "id": 32,
        "name": "ICFAI Foundation for Higher Education, Hyderabad"
    },
    {
        "id": 230,
        "name": "Indian Institute of Carpet Technology, Bhadohi"
    },
    {
        "id": 231,
        "name": "Indian Institute of Engineering Science and Technology, Shibpur"
    },
    {
        "id": 117,
        "name": "Indian Institute of Information Technology, Allahabad"
    },
    {
        "id": 232,
        "name": "Indian Institute of Information Technology, Design and Manufacturing, Jabalpur"
    },
    {
        "id": 233,
        "name": "Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram"
    },
    {
        "id": 234,
        "name": "Indian Institute of Science Education and Research, Bhopal"
    },
    {
        "id": 235,
        "name": "Indian Institute of Science Education and Research, Kolkata"
    },
    {
        "id": 236,
        "name": "Indian Institute of Science Education and Research, Mohali"
    },
    {
        "id": 237,
        "name": "Indian Institute of Science Education and Research, Pune"
    },
    {
        "id": 238,
        "name": "Indian Institute of Science Education and Research, Thiruvananthapuram"
    },
    {
        "id": 239,
        "name": "Indian Institute of Space Science and Technology, Thiruvananthapuram"
    },
    {
        "id": 89,
        "name": "Indian Institute of Technology, Banaras Hindu University, Varanasi (IITBHU)"
    },
    {
        "id": 48,
        "name": "Indian Institute of Technology, Bhubaneswar (IITBBS)"
    },
    {
        "id": 9,
        "name": "Indian Institute of Technology, Bombay (IITB)"
    },
    {
        "id": 8,
        "name": "Indian Institute of Technology, Delhi (IITD)"
    },
    {
        "id": 94,
        "name": "Indian Institute of Technology, Gandhinagar (IITGN)"
    },
    {
        "id": 54,
        "name": "Indian Institute of Technology, Guwahati (IITG)"
    },
    {
        "id": 95,
        "name": "Indian Institute of Technology, Hyderabad (IITH)"
    },
    {
        "id": 96,
        "name": "Indian Institute of Technology, Indore (IITI)"
    },
    {
        "id": 97,
        "name": "Indian Institute of Technology, Jodhpur (IITJ)"
    },
    {
        "id": 12,
        "name": "Indian Institute of Technology, Kanpur (IITK)"
    },
    {
        "id": 13,
        "name": "Indian Institute of Technology, Kharagpur (IITKGP)"
    },
    {
        "id": 10,
        "name": "Indian Institute of Technology, Madras (IITM)"
    },
    {
        "id": 98,
        "name": "Indian Institute of Technology, Mandi (IITMANDI)"
    },
    {
        "id": 99,
        "name": "Indian Institute of Technology, Patna (IITP)"
    },
    {
        "id": 61,
        "name": "Indian Institute of Technology, Roorkee (IITR)"
    },
    {
        "id": 100,
        "name": "Indian Institute of Technology, Ropar (IITRPR)"
    },
    {
        "id": 118,
        "name": "Indian School of Mines, Dhanbad"
    },
    {
        "id": 240,
        "name": "Indira Gandhi Institute of Technology, Delhi"
    },
    {
        "id": 241,
        "name": "Indira Gandhi Institute of Technology, Sarang"
    },
    {
        "id": 242,
        "name": "Indraprastha Institute of Information Technology, Delhi"
    },
    {
        "id": 243,
        "name": "Institute of Chemical Technology, Mumbai"
    },
    {
        "id": 244,
        "name": "Institute of Engineering and Management, Kolkata"
    },
    {
        "id": 245,
        "name": "Institute of Engineering and Rural Technology, Allahabad"
    },
    {
        "id": 246,
        "name": "Institute of Engineering and Science, IPS Academy, Indore"
    },
    {
        "id": 247,
        "name": "Institute of Engineering and Technology, Alwar"
    },
    {
        "id": 248,
        "name": "Institute of Engineering and Technology, Indore"
    },
    {
        "id": 249,
        "name": "Institute of Engineering and Technology, Lucknow"
    },
    {
        "id": 250,
        "name": "Institute of Information and Communication Technology, Ahmedabad"
    },
    {
        "id": 251,
        "name": "Institute of Infrastructure, Technology, Research and Management, Ahmedabad"
    },
    {
        "id": 252,
        "name": "Institute of Road and Transport Technology, Erode"
    },
    {
        "id": 253,
        "name": "Institute of Science and Technology, Jawaharlal Nehru Technological University, Hyderabad"
    },
    {
        "id": 254,
        "name": "Integral University, Lucknow"
    },
    {
        "id": 255,
        "name": "International Institute of Information Technology, Bangalore"
    },
    {
        "id": 256,
        "name": "International Institute of Information Technology, Bhubaneswar"
    },
    {
        "id": 120,
        "name": "International Institute of Information Technology, Hyderabad"
    },
    {
        "id": 257,
        "name": "International Institute of Information Technology, Pune"
    },
    {
        "id": 258,
        "name": "Islamic University of Science and Technology, Pulwama"
    },
    {
        "id": 259,
        "name": "ITM University, Gwalior"
    },
    {
        "id": 260,
        "name": "Jabalpur Engineering College, Jabalpur"
    },
    {
        "id": 261,
        "name": "Jadavpur University, Kolkata"
    },
    {
        "id": 262,
        "name": "Jaipur Engineering College and Research Centre, Jaipur"
    },
    {
        "id": 263,
        "name": "Jaipur National University, Jaipur"
    },
    {
        "id": 264,
        "name": "Jalpaiguri Government Engineering College, Jalpaiguri"
    },
    {
        "id": 265,
        "name": "Jamia Millia Islamia, New Delhi"
    },
    {
        "id": 266,
        "name": "Jawaharlal Nehru Technological University College of Engineering, Kakinada"
    },
    {
        "id": 267,
        "name": "Jawaharlal Nehru Technological University, Kakinada"
    },
    {
        "id": 268,
        "name": "Jaypee Institute of Information Technology, Noida"
    },
    {
        "id": 269,
        "name": "Jaypee University of Information Technology, Solan"
    },
    {
        "id": 270,
        "name": "Jiwaji University, Gwalior"
    },
    {
        "id": 271,
        "name": "JNTU College of Engineering, Hyderabad"
    },
    {
        "id": 272,
        "name": "JNTU, University College of Engineering, Vizianagaram "
    },
    {
        "id": 273,
        "name": "JNTUH College of Engineering, Karimnagar"
    },
    {
        "id": 274,
        "name": "Jodhpur National University, Jodhpur"
    },
    {
        "id": 275,
        "name": "Joginpally B R Engineering College, Moinabad"
    },
    {
        "id": 276,
        "name": "JSS Academy of Technical Education, Bangalore"
    },
    {
        "id": 277,
        "name": "JSS Academy of Technical Education, Noida"
    },
    {
        "id": 278,
        "name": "Kakatiya Institute of Technology and Science, Warangal"
    },
    {
        "id": 279,
        "name": "Kalinga Institute of Industrial Technology, Bhubaneswar"
    },
    {
        "id": 280,
        "name": "Kamla Nehru Institute of Technology, Sultanpur"
    },
    {
        "id": 281,
        "name": "Karunya University, Coimbatore"
    },
    {
        "id": 282,
        "name": "Kasegaon Education Society's Rajarambapu Institute of Technology, Sangli"
    },
    {
        "id": 283,
        "name": "KJ Somaiya College of Engineering, Mumbai"
    },
    {
        "id": 284,
        "name": "KJ Somaiya College of Engineering, Mumbai"
    },
    {
        "id": 285,
        "name": "KLS Gogte Institute of Technology, Belgaum"
    },
    {
        "id": 286,
        "name": "Kongu Engineering College, Erode"
    },
    {
        "id": 287,
        "name": "Krishna Institute of Engineering and Technology, Ghaziabad"
    },
    {
        "id": 288,
        "name": "Kumaraguru College of Technology, Coimbatore"
    },
    {
        "id": 289,
        "name": "Lakshmi Narain College of Technology, Bhopal"
    },
    {
        "id": 290,
        "name": "LBS College of Engineering, Kasargod"
    },
    {
        "id": 291,
        "name": "LBS Institute of Technology for Women, Thiruvananthapuram"
    },
    {
        "id": 292,
        "name": "LD College of Engineering, Ahmedabad"
    },
    {
        "id": 293,
        "name": "Lovely Professional University, Jalandhar"
    },
    {
        "id": 294,
        "name": "Lukhdhirji Engineering College, Morbi"
    },
    {
        "id": 295,
        "name": "M Kumarasamy College of Engineering, Karur"
    },
    {
        "id": 296,
        "name": "Madan Mohan Malaviya University of Technology. Gorakhpur"
    },
    {
        "id": 297,
        "name": "Madanpalle Institute of Technology and Science, Madanapalle"
    },
    {
        "id": 298,
        "name": "Madhav Institute of Technology and Science, Gwalior"
    },
    {
        "id": 299,
        "name": "Madras Institute of Technology, Chennai"
    },
    {
        "id": 300,
        "name": "Maharaj Vijayaram Gajapathi Raj College of Engineering, Vizianagaram"
    },
    {
        "id": 301,
        "name": "Maharaja Agrasen Institute of Technology, Delhi"
    },
    {
        "id": 302,
        "name": "Maharaja Sayajirao University of Baroda, Vadodara"
    },
    {
        "id": 303,
        "name": "Maharashtra Institute of Technology, Pune"
    },
    {
        "id": 304,
        "name": "Maharishi Markandeshwar University, Ambala "
    },
    {
        "id": 305,
        "name": "Mahatma Gandhi Institute of Technology, Hyderabad"
    },
    {
        "id": 306,
        "name": "Mahatma Jyotiba Phule Rohilkhand University, Bareilly"
    },
    {
        "id": 112,
        "name": "Malaviya National Institute of Technology, Jaipur (MNIT)"
    },
    {
        "id": 307,
        "name": "Malla Reddy College of Engineering and Technology, Hyderabad"
    },
    {
        "id": 308,
        "name": "Malla Reddy Engineering College, Hyderabad"
    },
    {
        "id": 309,
        "name": "Malnad College of Engineering, Hassan"
    },
    {
        "id": 310,
        "name": "Manav Rachna College of Engineering, Faridabad"
    },
    {
        "id": 122,
        "name": "Manipal Institute of Technology, Manipal"
    },
    {
        "id": 311,
        "name": "Manyawar Kansi Ram Engineering College of Information Technology, Ambedkar Nagar"
    },
    {
        "id": 312,
        "name": "Manyawar Kansi Ram Engineering College of Information Technology, Azamgarh"
    },
    {
        "id": 313,
        "name": "Mar Athanasius College of Engineering, Kothamangalam"
    },
    {
        "id": 314,
        "name": "Maturi Venkata Subba Rao Engineering College, Hyderabad"
    },
    {
        "id": 315,
        "name": "Maulana Azad National Institute of Technology, Bhopal (MANIT)"
    },
    {
        "id": 316,
        "name": "MBM Engineering College, Jodhpur"
    },
    {
        "id": 317,
        "name": "MCT's Rajiv Gandhi Institute of Technology, Mumbai"
    },
    {
        "id": 318,
        "name": "Medi-Caps Institute of Science and Technology, Indore"
    },
    {
        "id": 319,
        "name": "Medi-Caps Institute of Technology and Management, Indore"
    },
    {
        "id": 320,
        "name": "Meenakshi Sundararajan Engineering College, Chennai"
    },
    {
        "id": 124,
        "name": "Mepco Schlenk Engineering College, Sivakasi"
    },
    {
        "id": 321,
        "name": "MES College of Engineering, Malappuram"
    },
    {
        "id": 322,
        "name": "Mizoram University, Aizawl"
    },
    {
        "id": 323,
        "name": "MLV Government Textile and Engineering College, Bhilwara"
    },
    {
        "id": 324,
        "name": "Mody University of Science and Technology, Sikar"
    },
    {
        "id": 126,
        "name": "Motilal Nehru National Institute of Technology, Allahabad (MNNIT)"
    },
    {
        "id": 325,
        "name": "MS Engineering College, Bangalore"
    },
    {
        "id": 123,
        "name": "MS Ramaiah Institute of Technology, Bangalore"
    },
    {
        "id": 326,
        "name": "Mukesh Patel School of Technology Management and Engineering, Mumbai"
    },
    {
        "id": 327,
        "name": "Muzaffarpur Institute of Technology, Muzaffarpur"
    },
    {
        "id": 328,
        "name": "MVJ College of Engineering, Bangalore"
    },
    {
        "id": 329,
        "name": "Narasaraopeta Engineering College, Narasaraopet"
    },
    {
        "id": 330,
        "name": "National Dairy Research Institute, Karnal"
    },
    {
        "id": 331,
        "name": "National Institute of Foundry and Forge Technology, Ranchi"
    },
    {
        "id": 332,
        "name": "National Institute of Science Education and Research, Bhubaneswar"
    },
    {
        "id": 333,
        "name": "National Institute of Technology, Agartala (NITA)"
    },
    {
        "id": 129,
        "name": "National Institute of Technology, Calicut (NITC)"
    },
    {
        "id": 334,
        "name": "National Institute of Technology, Delhi (NITDELHI)"
    },
    {
        "id": 130,
        "name": "National Institute of Technology, Durgapur (NITDGP)"
    },
    {
        "id": 335,
        "name": "National Institute of Technology, Goa (NITGOA)"
    },
    {
        "id": 131,
        "name": "National Institute of Technology, Hamirpur (NITH)"
    },
    {
        "id": 336,
        "name": "National Institute of Technology, Jamshedpur (NITJSR)"
    },
    {
        "id": 337,
        "name": "National Institute of Technology, Kurukshetra (NITKKR)"
    },
    {
        "id": 338,
        "name": "National Institute of Technology, Mizoram (NITMZ)"
    },
    {
        "id": 339,
        "name": "National Institute of Technology, Nagaland (NITNAGALAND)"
    },
    {
        "id": 340,
        "name": "National Institute of Technology, Patna (NITP)"
    },
    {
        "id": 341,
        "name": "National Institute of Technology, Puducherry (NITPY)"
    },
    {
        "id": 342,
        "name": "National Institute of Technology, Raipur (NITRR)"
    },
    {
        "id": 133,
        "name": "National Institute of Technology, Rourkela (NITRKL)"
    },
    {
        "id": 343,
        "name": "National Institute of Technology, Sikkim (NITSIKKIM)"
    },
    {
        "id": 134,
        "name": "National Institute of Technology, Silchar (NITS)"
    },
    {
        "id": 128,
        "name": "National Institute of Technology, Srinagar (NITSRI)"
    },
    {
        "id": 344,
        "name": "National Institute of Technology, Surathkal (NITK)"
    },
    {
        "id": 135,
        "name": "National Institute of Technology, Tiruchirappalli (NITT)"
    },
    {
        "id": 345,
        "name": "National Institute of Technology, Uttarakhand (NITUK)"
    },
    {
        "id": 136,
        "name": "National Institute of Technology, Warangal (NITW)"
    },
    {
        "id": 346,
        "name": "National Power Training Institute, Durgapur"
    },
    {
        "id": 347,
        "name": "National Power Training Institute, Faridabad"
    },
    {
        "id": 348,
        "name": "NC College of Engineering, Israna"
    },
    {
        "id": 137,
        "name": "Netaji Subhas Institute of Technology, Delhi"
    },
    {
        "id": 349,
        "name": "Netaji Subhash Engineering College, Garia"
    },
    {
        "id": 350,
        "name": "New Horizon College of Engineering, Bangalore"
    },
    {
        "id": 351,
        "name": "NIIT University, Neemrana"
    },
    {
        "id": 352,
        "name": "Nirma University, Ahmedabad"
    },
    {
        "id": 353,
        "name": "Nitte Meenakshi Institute of Technology, Bangalore"
    },
    {
        "id": 354,
        "name": "Nizam Institute of Engineering and Technology, Hyderabad"
    },
    {
        "id": 355,
        "name": "NMAM Institute of Technology, Karkala Taluk"
    },
    {
        "id": 356,
        "name": "North Eastern Regional Institute of Science and Technology, Nirjuli"
    },
    {
        "id": 357,
        "name": "NSS College of Engineering, Palakkad"
    },
    {
        "id": 358,
        "name": "Orissa School of Mining Engineering, Keonjhar"
    },
    {
        "id": 359,
        "name": "PA College of Engineering, Mangalore"
    },
    {
        "id": 360,
        "name": "Pandit Deendayal Petroleum University- School of Technology, Gandhinagar"
    },
    {
        "id": 361,
        "name": "Panimalar Engineering College, Chennai"
    },
    {
        "id": 362,
        "name": "Panipat Institute of Engineering and Technology, Panipat"
    },
    {
        "id": 363,
        "name": "Panjab University, Chandigarh"
    },
    {
        "id": 364,
        "name": "Panjab University, SSG Regional Centre, Hoshiarpur"
    },
    {
        "id": 365,
        "name": "Parala Maharaja Engineering College, Berhampur"
    },
    {
        "id": 366,
        "name": "Parvatha Reddy Babul Reddy Visvodaya Institute of Technology and Science, Nellore"
    },
    {
        "id": 367,
        "name": "PES College of Engineering, Mandya"
    },
    {
        "id": 368,
        "name": "PES Institute of Technology, Bangalore South Campus, Bangalore"
    },
    {
        "id": 369,
        "name": "Pondicherry Engineering College, Puducherry"
    },
    {
        "id": 370,
        "name": "Poojya Doddappa Appa College, Gulbarga"
    },
    {
        "id": 371,
        "name": "Poornima College of Engineering, Jaipur"
    },
    {
        "id": 372,
        "name": "Pragati Engineering College, Kakinada"
    },
    {
        "id": 373,
        "name": "PSG College of Technology, Coimbatore"
    },
    {
        "id": 374,
        "name": "Punjab Agricultural University, Ludhiana"
    },
    {
        "id": 375,
        "name": "Punjab Engineering College University of Technology, Chandigarh"
    },
    {
        "id": 376,
        "name": "QIS College of Engineering and Technology, Ongole"
    },
    {
        "id": 377,
        "name": "Quantum School of Technology, Roorkee"
    },
    {
        "id": 143,
        "name": "Rajagiri School of Engineering and Technology, Ernakulam"
    },
    {
        "id": 378,
        "name": "Rajasthan Technical University, Kota"
    },
    {
        "id": 379,
        "name": "Rajeev Gandhi Memorial College of Engineering and Technology, Kurnool"
    },
    {
        "id": 380,
        "name": "Rajiv Gandhi Institute of Technology, Kottayam"
    },
    {
        "id": 381,
        "name": "Rajiv Gandhi Proudyogiki Vishwavidyalaya, Bhopal"
    },
    {
        "id": 382,
        "name": "Rayat Bahra Faculty of Engineering and Technology, Patiala Campus, Patiala"
    },
    {
        "id": 383,
        "name": "RCC Institute of Information Technology, Beliaghata"
    },
    {
        "id": 384,
        "name": "Reva Institute of Technology and Management, Bangalore"
    },
    {
        "id": 385,
        "name": "Rewa Engineering College, Rewa"
    },
    {
        "id": 386,
        "name": "RMK Engineering College, Thiruvallur"
    },
    {
        "id": 387,
        "name": "RNS Institute of Technology, Bangalore"
    },
    {
        "id": 388,
        "name": "RTE Sociey's Rural Engineering College, Hulkoti"
    },
    {
        "id": 389,
        "name": "Rungta College of Engineering and Technology, Bhilai"
    },
    {
        "id": 390,
        "name": "Rustamji Institute of Technology, Gwalior"
    },
    {
        "id": 391,
        "name": "RV College of Engineering, Bangalore"
    },
    {
        "id": 392,
        "name": "Sagi Ramakrishnam Raju Engineering College, Bhimavaram"
    },
    {
        "id": 393,
        "name": "Sam Higginbottom Institute of Agriculture, Technology and Science, Allahabad"
    },
    {
        "id": 394,
        "name": "Samrat Ashok Technological Institute, Vidisha"
    },
    {
        "id": 395,
        "name": "Sant Longowal Institute of Engineering and Technology, Longowal"
    },
    {
        "id": 144,
        "name": "Sardar Vallabhbhai National Institute of Technology, Surat (SVNIT)"
    },
    {
        "id": 396,
        "name": "SASTRA University, Thanjavur"
    },
    {
        "id": 397,
        "name": "Sathyabama University, Chennai"
    },
    {
        "id": 398,
        "name": "Satyam Institute of Engineering and Technology, Amritsar"
    },
    {
        "id": 399,
        "name": "Saveetha Engineering College, Chennai"
    },
    {
        "id": 400,
        "name": "School of Engineering and Technology, Jain University, Bangalore"
    },
    {
        "id": 401,
        "name": "School of Engineering, Cochin University of Science and Technology, Ernakulam"
    },
    {
        "id": 402,
        "name": "SCMS School of Engineering and Technology, Ernakulum"
    },
    {
        "id": 403,
        "name": "SDM College of Engineering and Technology, Dharwad"
    },
    {
        "id": 404,
        "name": "Seth Jai Parkash Mukand Lal Institute of Engineering and Technology, Yamunanagar"
    },
    {
        "id": 405,
        "name": "Shaheed Bhagat Singh State Technical Campus, Firozpur"
    },
    {
        "id": 406,
        "name": "Shantilal Shah Engineering College, Bhavnagar"
    },
    {
        "id": 407,
        "name": "Sharda University, Greater Noida"
    },
    {
        "id": 408,
        "name": "Shiv Nadar University, Dadri"
    },
    {
        "id": 409,
        "name": "Shivaji University, Kolhapur"
    },
    {
        "id": 410,
        "name": "Shobhit Institute of Engineering and  Technology, Meerut"
    },
    {
        "id": 411,
        "name": "Shri GS Institute of Technology and Science, Indore"
    },
    {
        "id": 412,
        "name": "Shri Guru Gobind Singhji Institute of Engineering and Technology, Vishnupuri"
    },
    {
        "id": 145,
        "name": "Shri Mata Vaishno Devi University, Katra"
    },
    {
        "id": 413,
        "name": "Shri Ram Murti Smarak College of Engineering and Technology, Bareilly"
    },
    {
        "id": 414,
        "name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon"
    },
    {
        "id": 415,
        "name": "Shri Shankaracharya Engineering College, Bhilai"
    },
    {
        "id": 416,
        "name": "Shri Vaishnav Institute of Technology and Science, Indore"
    },
    {
        "id": 417,
        "name": "Siddaganga Institute of Technology, Tumkur"
    },
    {
        "id": 418,
        "name": "Siksha O' Anusandhan University, Bhubaneshwar"
    },
    {
        "id": 419,
        "name": "Silicon Institute of Technology, Bhubaneswar"
    },
    {
        "id": 420,
        "name": "Sinhgad College of Engineering, Pune"
    },
    {
        "id": 146,
        "name": "Sir M Visvesvaraya Institute of Technology, Bangalore"
    },
    {
        "id": 421,
        "name": "SJB Institute of Technology, Bangalore"
    },
    {
        "id": 422,
        "name": "Sona College of Technology, Salem"
    },
    {
        "id": 423,
        "name": "Sree Chitra Thirunal College of Engineering, Thiruvananthapuram "
    },
    {
        "id": 424,
        "name": "Sree Vidyanikethan Engineering College, Tirupati"
    },
    {
        "id": 425,
        "name": "Sreenidhi Institute of Science and Technology, Ghatkesar"
    },
    {
        "id": 426,
        "name": "Sri Aditya Engineering College, Surampalem"
    },
    {
        "id": 427,
        "name": "Sri Jayachamarajendra College of Engineering, Mysore"
    },
    {
        "id": 428,
        "name": "Sri Krishna College of Engineering and Technology, Coimbatore"
    },
    {
        "id": 429,
        "name": "Sri Manakula Vinayagar Engineering College, Puducherry"
    },
    {
        "id": 430,
        "name": "Sri Ramakrishna Engineering College, Coimbatore"
    },
    {
        "id": 431,
        "name": "Sri Sai Ram Engineering College, Chennai"
    },
    {
        "id": 432,
        "name": "Sri Siddhartha Institute of Technology, Tumkur"
    },
    {
        "id": 433,
        "name": "Sri Venkateswara College of Engineering, Sriperumbudur"
    },
    {
        "id": 434,
        "name": "Sri Venkateswara University, Tirupati"
    },
    {
        "id": 435,
        "name": "Sri Vishnu Engineering College for Women, Bhimavaram"
    },
    {
        "id": 148,
        "name": "SRM University, Chennai"
    },
    {
        "id": 436,
        "name": "St. Ann's College of Engineering and Technology, Chirala"
    },
    {
        "id": 437,
        "name": "Swami Keshvanand Institute of Technology, Management and Gramothan, Jaipur"
    },
    {
        "id": 438,
        "name": "Symbiosis International University, Pune"
    },
    {
        "id": 439,
        "name": "Techno India University, Kolkata"
    },
    {
        "id": 440,
        "name": "Tezpur University, Tezpur"
    },
    {
        "id": 150,
        "name": "Thapar University, Patiala"
    },
    {
        "id": 441,
        "name": "The LNM Institute of Information Technology, Jaipur"
    },
    {
        "id": 442,
        "name": "The National Institute of Engineering, Mysore"
    },
    {
        "id": 443,
        "name": "The Oxford College of Engineering, Bangalore"
    },
    {
        "id": 444,
        "name": "The Technological Institute of Textile and Sciences, Bhiwani"
    },
    {
        "id": 445,
        "name": "Thiagarajar College of Engineering, Madurai"
    },
    {
        "id": 446,
        "name": "TKM College of Engineering, Kollam"
    },
    {
        "id": 447,
        "name": "Tripura Institute of Technology, Agartala"
    },
    {
        "id": 448,
        "name": "Tula's Institute, The Engineering and Management College, Dehradun"
    },
    {
        "id": 449,
        "name": "UBDT College of Engineering, Davangere"
    },
    {
        "id": 450,
        "name": "UIET, Chhatrapati Sahuji Maharaj University, Kanpur"
    },
    {
        "id": 451,
        "name": "Ujjain Engineering College, Ujjain"
    },
    {
        "id": 106,
        "name": "University College of Engineering, Osmania University, Hyderabad"
    },
    {
        "id": 452,
        "name": "University College of Engineering, Punjabi University, Patiala"
    },
    {
        "id": 453,
        "name": "University College of Technology, Osmania University, Hyderabad"
    },
    {
        "id": 454,
        "name": "University Institute of Chemical Engineering and Technology, Chandigarh"
    },
    {
        "id": 455,
        "name": "University Institute of Engineering and Technology, Chandigarh"
    },
    {
        "id": 456,
        "name": "University Institute of Engineering and Technology, Kurukshetra University, Kurukshetra"
    },
    {
        "id": 457,
        "name": "University Institute of Engineering and Technology, Maharshi Dayanand University, Rohtak"
    },
    {
        "id": 458,
        "name": "University Institute of Information Technology, Shimla"
    },
    {
        "id": 459,
        "name": "University Institute of Technology, Burdwan"
    },
    {
        "id": 460,
        "name": "University of Kalyani, Kalyani"
    },
    {
        "id": 461,
        "name": "University Visvesvaraya College of Engineering, Bangalore"
    },
    {
        "id": 462,
        "name": "UP Textile Technology Institute, Kanpur"
    },
    {
        "id": 463,
        "name": "Uttar Banga Krishi Vishwavidyalaya, Pundibari"
    },
    {
        "id": 464,
        "name": "Uttaranchal Institute of Technology, Dehradun"
    },
    {
        "id": 465,
        "name": "Vaagdevi College of Engineering, Warangal"
    },
    {
        "id": 466,
        "name": "Vaish College of Engineering, Rohtak"
    },
    {
        "id": 467,
        "name": "Vardhaman College of Engineering, Hyderabad"
    },
    {
        "id": 468,
        "name": "Vasavi College of Engineering, Hyderabad"
    },
    {
        "id": 469,
        "name": "Veer Surendra Sai University of Technology, Sambalpur"
    },
    {
        "id": 151,
        "name": "Veermata Jijabai Technological Institute, Mumbai"
    },
    {
        "id": 470,
        "name": "Vel Tech Dr Rangrajan Dr Sakunthala Technical University, Chennai"
    },
    {
        "id": 471,
        "name": "Vel Tech High Tech Dr. Rangarajan Dr. Sakunthala Engineering College, Chennai"
    },
    {
        "id": 472,
        "name": "Velagapudi Ramakrishna Siddhartha Engineering College, Vijaywada"
    },
    {
        "id": 473,
        "name": "Velammal Engineering College, Chennai"
    },
    {
        "id": 474,
        "name": "Vels University, Chennai"
    },
    {
        "id": 475,
        "name": "Vidya Jyothi Institute of Technology, Hyderabad"
    },
    {
        "id": 476,
        "name": "Vidyavardhaka College of Engineering, Mysore"
    },
    {
        "id": 477,
        "name": "Vignan University, Guntur"
    },
    {
        "id": 478,
        "name": "Vishnu Institute of Technology, Bhimavaram"
    },
    {
        "id": 479,
        "name": "Vishwakarma Government Engineering College, Ahmedabad"
    },
    {
        "id": 480,
        "name": "Vishwakarma Institute of Information Technology, Pune"
    },
    {
        "id": 481,
        "name": "Vishwakarma Institute of Technology, Pune"
    },
    {
        "id": 153,
        "name": "Visvesvaraya National Institute of Technology, Nagpur (VNIT)"
    },
    {
        "id": 152,
        "name": "VIT University, Vellore"
    },
    {
        "id": 482,
        "name": "VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad"
    },
    {
        "id": 483,
        "name": "VSB Engineering College, Karur"
    },
    {
        "id": 115,
        "name": "Walchand College of Engineering, Sangli"
    },
    {
        "id": 154,
        "name": "West Bengal University of Technology, Kolkata"
    },
    {
        "id": 484,
        "name": "Yeshwantrao Chavan College of Engineering, Nagpur"
    },
    {
        "id": 485,
        "name": "YMCA University of Science and Technology, Faridabad"
    },
    {
        "id": 487,
        "name": "National Institute of Technology, Kurukshetra (NIT KKR)"
    },
    {
        "id": 488,
        "name": "Indian Institute of Science (IISc)"
    },
    {
        "id": 489,
        "name": "IIM Sirmaur"
    },
    {
        "id": 490,
        "name": "Indraprastha Institute of Information Technology"
    },
    {
        "id": 491,
        "name": "IIM Amritsar"
    },
    {
        "id": 486,
        "name": "Other"
    },
    {
        "id": 492,
        "name": "Indian Statistical Institute, Kolkata"
    },
    {
        "id": 493,
        "name": "Indian Statistical Institute, Delhi"
    },
    {
        "id": 494,
        "name": "Indian Statistical Institute, Bangalore"
    },
    {
        "id": 495,
        "name": "Indian Statistical Institute, Chennai"
    },
    {
        "id": 486,
        "name": "Other"
    }
].sort(function (a, b) {
    return a["name"].localeCompare(b.name)
});

var instituteIimjobs = [
    {
        "name": "Indian Institute of Management, Ahmedabad (IIMA)"
        , "id": 1
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Bangalore (IIMB)"
        , "id": 2
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Calcutta (IIMC)"
        , "id": 3
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Indore (IIMI)"
        , "id": 4
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Kozhikode (IIMK)"
        , "id": 5
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Lucknow (IIML)"
        , "id": 6
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Shillong (IIMS)"
        , "id": 45
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Rohtak (IIMRohtak)"
        , "id": 46
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Ranchi (IIMR)"
        , "id": 47
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Raipur (IIMRaipur)"
        , "id": 48
        , "isTop": ""
    },
    {
        "name": "Indian Institute of Management Tiruchirappalli (IIMT)"
        , "id": 49
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Udaipur (IIMU)"
        , "id": 50
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management Kashipur (IIMKashipur)"
        , "id": 51
        , "isTop": "topinst"
    },
    {
        "name": "Jamnalal Bajaj Institute of Management Studies (JBIMS)"
        , "id": 52
        , "isTop": "topinst"
    },
    {
        "name": "Birla Institute of Technology and Science (BITS), Pilani"
        , "id": 53
        , "isTop": "topinst"
    },
    {
        "name": "Birla Institute of Technology, Mesra"
        , "id": 160
        , "isTop": ""
    },
    {
        "name": "Institute of Chartered Accountants of India (ICAI)"
        , "id": 55
        , "isTop": "topinst"
    },
    {
        "name": "Indian School of Business, Hyderabad (ISB)"
        , "id": 7
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Delhi (IITD)"
        , "id": 8
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Bombay (IITB)"
        , "id": 9
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Madras (IITM)"
        , "id": 10
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Roorkee (IITR)"
        , "id": 11
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Kanpur (IITK)"
        , "id": 12
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Kharagpur (IITKGP)"
        , "id": 13
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Guwahati (IITG)"
        , "id": 54
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Varanasi (IIT BHU)"
        , "id": 89
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Bhubaneswar (IITBBS)"
        , "id": 93
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Gandhinagar (IITGN)"
        , "id": 94
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Hyderabad (IITH)"
        , "id": 95
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Indore (IITI)"
        , "id": 96
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Jodhpur (IITJ)"
        , "id": 97
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Mandi (IITMandi)"
        , "id": 98
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Patna (IITP)"
        , "id": 99
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Technology, Ropar (IITRPR)"
        , "id": 100
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Science (IISc)"
        , "id": 57
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Company Secretaries of India (ICSI)"
        , "id": 101
        , "isTop": "topinst"
    },
    {
        "name": "B. S. Abdur Rahman University"
        , "id": 102
        , "isTop": ""
    },
    {
        "name": "Anna University"
        , "id": 103
        , "isTop": ""
    },
    {
        "name": "Visvesvaraya Technological University"
        , "id": 104
        , "isTop": ""
    },
    {
        "name": "Birsa Institute of Technology Sindri"
        , "id": 105
        , "isTop": ""
    },
    {
        "name": "Osmania University"
        , "id": 106
        , "isTop": ""
    },
    {
        "name": "College of Engineering, Guindy"
        , "id": 107
        , "isTop": ""
    },
    {
        "name": "University of Mumbai"
        , "id": 108
        , "isTop": ""
    },
    {
        "name": "Uttarakhand Technical University"
        , "id": 109
        , "isTop": ""
    },
    {
        "name": "Delhi Technological University"
        , "id": 110
        , "isTop": "topinst"
    },
    {
        "name": "Dhirubhai Ambani Institute of Information and Communication Technology"
        , "id": 111
        , "isTop": ""
    },
    {
        "name": "Malaviya National Institute of Technology, Jaipur"
        , "id": 112
        , "isTop": ""
    },
    {
        "name": "Government College of Engineering, Amravati"
        , "id": 113
        , "isTop": ""
    },
    {
        "name": "Harcourt Butler Technological Institute Kanpur (HBTI)"
        , "id": 114
        , "isTop": ""
    },
    {
        "name": "Walchand Institute of Technology"
        , "id": 115
        , "isTop": ""
    },
    {
        "name": "Guru Gobind Singh Indraprastha University"
        , "id": 116
        , "isTop": ""
    },
    {
        "name": "Indian Institute of Information Technology, Allahabad (IITA)"
        , "id": 117
        , "isTop": ""
    },
    {
        "name": "IIT, Dhanbad"
        , "id": 118
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Technology, Nirma University"
        , "id": 119
        , "isTop": ""
    },
    {
        "name": "International Institute of Information Technology, Hyderabad"
        , "id": 120
        , "isTop": ""
    },
    {
        "name": "University of Pune"
        , "id": 121
        , "isTop": ""
    },
    {
        "name": "Manipal Institute of Technology Manipal University"
        , "id": 122
        , "isTop": ""
    },
    {
        "name": "M.S.Ramaiah Institute of Technology"
        , "id": 123
        , "isTop": ""
    },
    {
        "name": "Mepco Schlenk Engineering College"
        , "id": 124
        , "isTop": ""
    },
    {
        "name": "Model Engineering College"
        , "id": 125
        , "isTop": ""
    },
    {
        "name": "Motilal Nehru National Institute of Technology, Allahabad (NIT)"
        , "id": 126
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Construction Management and Research"
        , "id": 159
        , "isTop": ""
    },
    {
        "name": "National Institute of Engineering Visvesvaraya Technological University"
        , "id": 127
        , "isTop": ""
    },
    {
        "name": "National Institute of Technology Srinagar (NIT)"
        , "id": 128
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Calicut (NIT)"
        , "id": 129
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Durgapur (NIT)"
        , "id": 130
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Hamirpur (NIT)"
        , "id": 131
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology Karnataka (NIT)"
        , "id": 132
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Rourkela (NIT)"
        , "id": 133
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Silchar (NIT)"
        , "id": 134
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Tiruchirappalli (NIT)"
        , "id": 135
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Warangal (NIT)"
        , "id": 136
        , "isTop": "topinst"
    },
    {
        "name": "Netaji Subhas Institute of Technology (NSIT)"
        , "id": 137
        , "isTop": "topinst"
    },
    {
        "name": "P.S.G. College of Technology"
        , "id": 138
        , "isTop": ""
    },
    {
        "name": "P.E.S. College of Engineering"
        , "id": 139
        , "isTop": ""
    },
    {
        "name": "P.E.S. Institute of Technology"
        , "id": 140
        , "isTop": ""
    },
    {
        "name": "PEC University of Technology"
        , "id": 141
        , "isTop": ""
    },
    {
        "name": "R.V. College of Engineering"
        , "id": 142
        , "isTop": ""
    },
    {
        "name": "Rajagiri School of Engineering & Technology"
        , "id": 143
        , "isTop": ""
    },
    {
        "name": "Sardar Vallabhbhai National Institute of Technology, Surat"
        , "id": 144
        , "isTop": ""
    },
    {
        "name": "Shri Mata Vaishno Devi University"
        , "id": 145
        , "isTop": ""
    },
    {
        "name": "Sir M. Visvesvaraya Institute of Technology"
        , "id": 146
        , "isTop": ""
    },
    {
        "name": "SSN College of Engineering"
        , "id": 147
        , "isTop": ""
    },
    {
        "name": "SRM University"
        , "id": 148
        , "isTop": ""
    },
    {
        "name": "Thadomal Shahani Engineering College"
        , "id": 149
        , "isTop": ""
    },
    {
        "name": "Thapar University"
        , "id": 150
        , "isTop": ""
    },
    {
        "name": "Veermata Jijabai Technological Institute"
        , "id": 151
        , "isTop": ""
    },
    {
        "name": "Vellore Institute of Technology"
        , "id": 152
        , "isTop": ""
    },
    {
        "name": "Visvesvaraya National Institute of Technology"
        , "id": 153
        , "isTop": ""
    },
    {
        "name": "West Bengal University of Technology"
        , "id": 154
        , "isTop": ""
    },
    {
        "name": "Xavier Institute Of Management - Bhubaneswar (XIMB)"
        , "id": 56
        , "isTop": "topinst"
    },
    {
        "name": "Xavier Institute of Management and Entrepreneurship (XIME)"
        , "id": 62
        , "isTop": ""
    },
    {
        "name": "XLRI - Xavier School Of Management, Jamshedpur (XLRI)"
        , "id": 14
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Cost and Management Accountants of India (ICMAI)"
        , "id": 86
        , "isTop": ""
    },
    {
        "name": "Management Development Institute (MDI)"
        , "id": 15
        , "isTop": "topinst"
    },
    {
        "name": "Narsee Monjee Institute of Management Studies (NMIMS)"
        , "id": 16
        , "isTop": "topinst"
    },
    {
        "name": "Faculty of Management Studies (FMS) - Delhi University"
        , "id": 17
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Management, Mumbai (IIM Mumbai formerly known as NITIE)"
        , "id": 18
        , "isTop": "topinst"
    },
    {
        "name": "SP Jain Institute of Management - Research (SPJIMR)"
        , "id": 19
        , "isTop": "topinst"
    },
    {
        "name": "Symbiosis Institute of Business Management (SIBM)"
        , "id": 20
        , "isTop": "topinst"
    },
    {
        "name": "TA Pai Management Institute (TAPMI)"
        , "id": 21
        , "isTop": "topinst"
    },
    {
        "name": "Tata Institute of Social Sciences (TISS)"
        , "id": 22
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Foreign Trade (IIFT)"
        , "id": 23
        , "isTop": "topinst"
    },
    {
        "name": "Mudra Institute of Communications, Ahmedabad (MICA)"
        , "id": 24
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Rural Management Anand (IRMA)"
        , "id": 25
        , "isTop": ""
    },
    {
        "name": "University Business School, Chandigarh (UBS)"
        , "id": 26
        , "isTop": ""
    },
    {
        "name": "Symbiosis Centre for Management and Human Resource Development (SCMHRD)"
        , "id": 27
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Management Technology (IMT), Ghaziabad"
        , "id": 28
        , "isTop": "topinst"
    },
    {
        "name": "International Management Institute (IMI), New Delhi"
        , "id": 29
        , "isTop": "topinst"
    },
    {
        "name": "Birla Institute of Management Technology (BIMTECH), Greater Noida"
        , "id": 155
        , "isTop": ""
    },
    {
        "name": "Lal Bahadur Shastri Institute of Management (LBSIM), New Delhi"
        , "id": 156
        , "isTop": ""
    },
    {
        "name": "Fore School of Management Delhi"
        , "id": 30
        , "isTop": ""
    },
    {
        "name": "KJ Somaiya Institute of Management Studies and Research, Mumbai"
        , "id": 31
        , "isTop": "topinst"
    },
    {
        "name": "ICFAI Business School (IBS), Hyderabad"
        , "id": 32
        , "isTop": ""
    },
    {
        "name": "ICFAI, Gurgaon"
        , "id": 33
        , "isTop": ""
    },
    {
        "name": "Institute of Management Technology (IMT), Nagpur"
        , "id": 34
        , "isTop": ""
    },
    {
        "name": "Symbiosis Institute of International Business, Pune"
        , "id": 35
        , "isTop": ""
    },
    {
        "name": "ISB&M Pune"
        , "id": 36
        , "isTop": ""
    },
    {
        "name": "Loyola Institute of Business Administration (LIBA), Chennai"
        , "id": 37
        , "isTop": ""
    },
    {
        "name": "BIM Trichy"
        , "id": 38
        , "isTop": ""
    },
    {
        "name": "Lal Bahadur Shastri Institute of Management (LBSIM), New Delhi"
        , "id": 39
        , "isTop": ""
    },
    {
        "name": "Institute of Management, Nirma University, Ahmedabad"
        , "id": 40
        , "isTop": ""
    },
    {
        "name": "SIMS"
        , "id": 41
        , "isTop": ""
    },
    {
        "name": "Welingkar Institute of Management Development & Research, Mumbai"
        , "id": 42
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Social Welfare and Business Management (IISWBM), Kolkata"
        , "id": 43
        , "isTop": ""
    },
    {
        "name": "Delhi School of Economics (DSE)"
        , "id": 59
        , "isTop": "topinst"
    },
    {
        "name": "Great Lakes Institute of Management"
        , "id": 60
        , "isTop": ""
    },
    {
        "name": "Institute for Financial Management and Research (IFMR)"
        , "id": 61
        , "isTop": ""
    },
    {
        "name": "Columbia Business School"
        , "id": 63
        , "isTop": "topinst"
    },
    {
        "name": "Harvard Business School"
        , "id": 64
        , "isTop": "topinst"
    },
    {
        "name": "Johnson Graduate School of Management, Cornell University"
        , "id": 65
        , "isTop": "topinst"
    },
    {
        "name": "The Wharton School of the University of Pennsylvania"
        , "id": 66
        , "isTop": "topinst"
    },
    {
        "name": "Tuck School of Business at Dartmouth"
        , "id": 67
        , "isTop": "topinst"
    },
    {
        "name": "Yale School of Management"
        , "id": 68
        , "isTop": "topinst"
    },
    {
        "name": "INSEAD (France or Singapore Campus)"
        , "id": 69
        , "isTop": "topinst"
    },
    {
        "name": "Kellogg School of Management - Northwestern University"
        , "id": 70
        , "isTop": "topinst"
    },
    {
        "name": "London Business School, UK"
        , "id": 71
        , "isTop": "topinst"
    },
    {
        "name": "London School of Economics (LSE)"
        , "id": 87
        , "isTop": "topinst"
    },
    {
        "name": "MIT Sloan School of Management"
        , "id": 72
        , "isTop": "topinst"
    },
    {
        "name": "NYU Stern School of Business"
        , "id": 73
        , "isTop": "topinst"
    },
    {
        "name": "Stanford Graduate School of Business"
        , "id": 74
        , "isTop": "topinst"
    },
    {
        "name": "ESCP Europe Business School"
        , "id": 75
        , "isTop": "topinst"
    },
    {
        "name": "University of Oxford - Said, UK"
        , "id": 76
        , "isTop": "topinst"
    },
    {
        "name": "University of Cambridge - Judge, UK"
        , "id": 77
        , "isTop": "topinst"
    },
    {
        "name": "IE Business School, Spain"
        , "id": 78
        , "isTop": "topinst"
    },
    {
        "name": "HEC Paris, France"
        , "id": 79
        , "isTop": "topinst"
    },
    {
        "name": "Esade Business School, Spain"
        , "id": 80
        , "isTop": "topinst"
    },
    {
        "name": "Iese Business School, Spain"
        , "id": 81
        , "isTop": "topinst"
    },
    {
        "name": "IMD, Switzerland"
        , "id": 82
        , "isTop": "topinst"
    },
    {
        "name": "University of St Gallen, Switzerland"
        , "id": 83
        , "isTop": "topinst"
    },
    {
        "name": "Rotterdam School of Management, Erasmus University, Netherlands"
        , "id": 84
        , "isTop": "topinst"
    },
    {
        "name": "SDA Bocconi, Italy"
        , "id": 85
        , "isTop": "topinst"
    },
    {
        "name": "Asian Institute of Management (AIM), Philippines"
        , "id": 88
        , "isTop": "topinst"
    },
    {
        "name": "Haas School of Business, University of California Berkeley"
        , "id": 90
        , "isTop": "topinst"
    },
    {
        "name": "National University Of Singapore(NUS) Business School"
        , "id": 91
        , "isTop": "topinst"
    },
    {
        "name": "Institute of Management Technology (IMT) Dubai"
        , "id": 92
        , "isTop": ""
    },
    {
        "name": "Department of Financial Studies, University of Delhi"
        , "id": 157
        , "isTop": ""
    },
    {
        "name": "Goa Institute of Management (GIM)"
        , "id": 158
        , "isTop": ""
    },
    {
        "name": "Dr. B. R. Ambedkar National Institute of Technology (NIT) Jalandhar"
        , "id": 161
        , "isTop": ""
    },
    {
        "name": "MYRA School of Business"
        , "id": 162
        , "isTop": ""
    },
    {
        "name": "Maulana Azad National Institute of Technology (MANIT) Bhopal"
        , "id": 163
        , "isTop": "topinst"
    },
    {
        "name": "National Institute of Technology, Kurukshetra (NIT KKR)"
        , "id": 164
        , "isTop": "topinst"
    },
    {
        "name": "Faculty of Management Studies, Banaras Hindu University (FMS, BHU)"
        , "id": 165
        , "isTop": ""
    },
    {
        "name": "National Institute of Technology (NIT), Patna"
        , "id": 166
        , "isTop": ""
    },
    {
        "name": "Indian Institute of Management, Visakhapatnam"
        , "id": 167
        , "isTop": "topinst"
    },
    {
        "name": "Indian Institute of Forest Management, Bhopal"
        , "id": 168
        , "isTop": "topinst"
    },
    {
        "name": "Other"
        , "id": 44
        , "isTop": ""
    },
    {
        "name": "IIM-Nagpur"
        , "id": 169
        , "isTop": "topinst"
    },
    {
        "name": "IIM-Vishakhapatnam"
        , "id": 170
        , "isTop": "topinst"
    },
    {
        "name": "IFIM Business School, Bangalore"
        , "id": 171
        , "isTop": ""
    },
    {
        "name": "VES Institute of Management Studies and Research"
        , "id": 172
        , "isTop": ""
    },
    {
        "name": "IIM Sirmaur"
        , "id": 173
        , "isTop": "topinst"
    },
    {
        "name": "Indraprastha Institute of Information Technology"
        , "id": 174
        , "isTop": ""
    },
    {
        "name": "IIM Amritsar"
        , "id": 175
        , "isTop": "topinst"
    },
    {
        "name": "National Law University, Bangalore"
        , "id": 176
        , "isTop": "lawinst"
    },
    {
        "name": "National Law Institute University, Bhopal"
        , "id": 177
        , "isTop": "lawinst"
    },
    {
        "name": "ILS Law College, Pune"
        , "id": 178
        , "isTop": "lawinst"
    },
    {
        "name": "Gujarat National Law University"
        , "id": 179
        , "isTop": "lawinst"
    },
    {
        "name": "Symbiosis Law School, Pune"
        , "id": 180
        , "isTop": "lawinst"
    },
    {
        "name": "National Law University, Delhi"
        , "id": 181
        , "isTop": "lawinst"
    },
    {
        "name": "Bharathi Vidyapeeth"
        , "id": 182
        , "isTop": "lawinst"
    },
    {
        "name": "NALSAR University of Law, Hyderabad"
        , "id": 183
        , "isTop": "lawinst"
    },
    {
        "name": "Faculty of Law, Delhi University"
        , "id": 184
        , "isTop": "lawinst"
    },
    {
        "name": "Hidayatullah National Law University, Raipur"
        , "id": 185
        , "isTop": "lawinst"
    },
    {
        "name": "Faculty of Law, Varanasi"
        , "id": 186
        , "isTop": "lawinst"
    },
    {
        "name": "Amity Law School, Delhi"
        , "id": 187
        , "isTop": "lawinst"
    },
    {
        "name": "MS Ramaiah College of Law"
        , "id": 188
        , "isTop": "lawinst"
    },
    {
        "name": "Government Law College, Mumbai"
        , "id": 189
        , "isTop": "lawinst"
    },
    {
        "name": "Jindal Global Law School"
        , "id": 190
        , "isTop": "lawinst"
    }, {
        "name": "Xavier Institute of Social Service, Ranchi (XISS)"
        , "id": 191
        , "isTop": ""
}].sort(function (a, b) {
    return a["name"].localeCompare(b.text)
});

export function getInstitutesName() {
    const institutes = process.env.REACT_APP_PLATFORM == 'iimjobs' ? instituteIimjobs : institutesHirist;
    return institutes.map(item => {
        return {
            label: item.name,
            value: item.name,
            key: item.name
        }
    })
}

export function getInstitutesNameFromId(id) {
    const institutes = process.env.REACT_APP_PLATFORM == 'iimjobs' ? instituteIimjobs : institutesHirist;
    return institutes.find(item => {
        if (item.id == id) {
            return true;
        }
    });
}