import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Header, Modal, Table } from "semantic-ui-react";
import Button from "../../components/Button";
import CountDisplay from "../../components/CountDisplay";
import Heading from "../../components/Heading";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import fetchApplications from "../../services/fetchApplications";
import user from "../../store/user";
import { getQueryParams, isEmptyObject, isTechDomain } from "../../utils/utilFunctions";
import {
    gender,
    maritalStatus,
    workPermit,
    willingTravel,
    militaryOptMap,
    binaries,
    subDisabilityMap,
} from "../../../src/models/candidateAdditionalInfo";

import "./applicationList.scss";
import fetchAdditionalInfo from "../../services/fetchAdditionalInfo";
import getSingleJob from "../../services/getSingleJob";
import ButtonContainer from "../../components/ButtonContainer";
import LinkButtons from "../../components/LinkButtons";
import {
    applicationCategories,
    applicationCategoryMap,
    applicationStatusMap
} from "../../models/applicationCategories";
import { applicationSort } from "../../models/applicationSort";
import InputBox from "../../components/InputBox";
import Dropdown from "../../components/Dropdown";
import MultiFilterModal from "../../components/MultiFilterModal/MultiFilterModal";
import fetchTags from "../../services/fetchTags";
import fetchFilters from "../../services/fetchFilters";
import fetchApplicationCount from "../../services/fetchApplicationCount";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const PAGE_CONTENT = 50;
let jobid;

const initialSelectedFilters = {
    curLoc: {},
    prefLoc: {},
    institute: {},
    degree: {},
    fa: {},
    language: {},
    courseType: {},
    tags: {},
    org: {},
    curOrg: {},
    noticePeriod: "",
    applicationDate: "",
    lastSeen: "",
    relocate: "",
    workPermit: "",
    handledTeam: "",
    differentlyAbled: "",
    filterAssessment: "",
};

const ApplicationList = (props) => {
    const [jobId, setJobId] = useState(0);
    const [searchJobId, setSearchJobId] = useState(null);
    const [job, setJob] = useState({});

    const [selectedFilters, setSelectedFilters] = useState(
        initialSelectedFilters
    );
    const [filterList, setFilterList] = useState({})
    const [salaryFilter, setSalaryFilter] = useState({
        min: 0,
        max: 0,
    });

    const [experienceFilter, setExperienceFilter] = useState({
        min: -1,
        max: -1,
    });

    const [batchFilter, setBatchFilter] = useState({
        min: "",
        max: "",
    });

    const [ageFilter, setAgeFilter] = useState({
        min: "",
        max: "",
    });

    const [content, setContent] = useState({
        applicationList: [],
        pageNumber: 0,
        count: 0,
    });
    const [applicationCountByType, setApplicationCountByType] = useState({
        rejected: 0,
        reviewed: 0,
        save: 0,
        shortlisted: 0,
        total: 0,
        unread: 0,
    });

    const [admin, setAdmin] = useState({
        adminId: null,
        viewAccess: true,
    });
    const [fetchApiCall, setFetchApiCall] = useState(false);

    const [candidateDetailModal, setCandidateDetailModal] = useState(false);
    const [applicationFilterModal, setApplicationFilterModal] = useState(false);
    const [viewResumeModal, setViewResumeModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const [additionalInformation, setAdditionalInformation] = useState({});

    const [filter, setFilter] = useState(1);
    const [category, setCategory] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState("");

    const [tags, setTags] = useState([]);
    const [currOrg, setCurrOrg] = useState([]);
    const [org, setOrg] = useState([]);

    const [resumePageNumber, setResumePageNumber] = useState(1)
    const [resumeNumPages, setResumeNumPages] = useState(1)

    useEffect(() => {
        const params = getQueryParams(props.location.search);
        jobid = params.jobId;
        setCategory(params['status'] || 'all');
        setJobId(jobid);
    }, [])

    useEffect(() => {

        try {
            if (jobId) {
                fetchProfile();
                getJobDetails();
                if (jobId == searchJobId) {
                    const params = getParams(filterList);
                    getApplications(1, params);
                    routeRequest(params);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [jobId]);

    useEffect(() => {
        handleModalOpen();
    }, [modalItem]);

    useEffect(() => {
        const params = getParams(filterList);
        if(category){
            getApplications(1, params);
        }
    }, [filter, category]);

    const fetchProfile = async () => {
        const profile = await user.getProfile();
        setAdmin({
            adminId: profile.id,
            viewAccess: true,
        });
    };

    const getJobDetails = async () => {
        try {
            const profile = await user.getProfile();

            const res = await getSingleJob(profile.id, jobId, {
                idType: "publish",
            });

            const recId = res.data[0]["uid"];
            const tags = await fetchTags(profile.id, recId);
            const tagsData = formatTags(tags.data);

            const count = await fetchApplicationCount(profile.id, jobId);
            const applicationCount = count.data.data.total;

            if (applicationCount) {
                const filters = await fetchFilters(admin.adminId, recId, jobId);
                if(filters.data) {
                    const organization = formatOrganization(
                        filters.data.organization
                    );
                    const currOrganization = formatOrganization(
                        filters.data.currentOrganization
                    );
    
                    setOrg(organization);
                    setCurrOrg(currOrganization);
                }
                
            }

            setTags(tagsData);
            setJob(res.data[0]);
        } catch (e) {
            console.log(e);
        }
    };

    const searchByJobId = () => {
        if (!searchJobId) return;
        setJobId(searchJobId);
    }

    const getApplications = async (page, filters = {}) => {
        if(!filters['download'])
            setFetchApiCall(true);
        try {
            const profile = await user.getProfile();
            const offset = page * 50 - 50;
            const res = await fetchApplications(profile.id, jobId || jobid, {
                offset: offset,
                pageContent: PAGE_CONTENT,
                ...filters,
            });

            if(filters['download']) {
                var blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', `${process.env.REACT_APP_PLATFORM}_report.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                return;
            }
            if (res.data.message) {
                setContent({
                    applicationList: [],
                    pageNumber: page,
                    count: 0,
                });
                setFetchApiCall(false);
            }
            const data = res.data.stats;
            const unread =
                data["total"] -
                (data["shortlisted"] +
                    data["rejected"] +
                    data["save"] +
                    data["reviewed"]);
            data["unread"] = unread;
            setApplicationCountByType({
                ...data,
            });
            setContent({
                applicationList: res.data.data,
                pageNumber: page,
                count: res.data.stats.total,
            });
            setFetchApiCall(false);
        } catch (e) {
            toast.error(!jobId ? 'Job ID not found' : 'Something went wrong');
            setFetchApiCall(false);
            console.log(e);
        }
    };

    const handlePageChange = (n) => {
        const params = getParams(filterList);
        getApplications(n, params);
    };

    const onCandidateClick = (candidate) => {
        setCandidateDetailModal(true);
        setModalItem(candidate);
    };

    const handleModalOpen = async () => {
        let res;
        if (modalItem) {
            res = await fetchAdditionalInfo(admin.adminId, jobId, modalItem.id);
            setAdditionalInformation({ ...res.data.data[0] });
        }
    };
    const handleModalClose = () => {
        setCandidateDetailModal(false);
        setModalItem(null);
    };

    const handleViewResumeModalClose = () => {
        setViewResumeModal(false);
        setModalItem(null);
    };

    const downloadResume = () => {
        window.open(
            `${ isTechDomain() ? process.env.REACT_APP_BASE_URL_TECH : process.env.REACT_APP_BASE_URL}${modalItem.resume}`,
            "_blank"
        );
    };

    const populateRecommendation = () => {
        if (modalItem.recommendation.length) {
            return (
                <div className="candidate-details recommendations">
                    <h3 className="details-header">Recommendations</h3>
                    <div className="details-content">
                        {
                            <ul>
                                {modalItem.recommendation.map((rec) => {
                                    // name: "Anuj Patel"
                                    // text: "He is a great developer with versatile knowledge and broad experience."
                                    // url: "0"
                                    return (
                                        <>
                                            <div className="recommendation-header">
                                                <div className="user-details">
                                                    <p className="user-name">
                                                        {rec.name}
                                                    </p>
                                                    <p className="user-link">
                                                        <a
                                                            href={rec.url}
                                                            target="_blank"
                                                        >
                                                            {rec.url}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="recommendation-body">
                                                <p className="user-body">
                                                    {rec.text}
                                                </p>
                                            </div>
                                            <div className="separator"></div>
                                        </>
                                    );
                                })}
                            </ul>
                        }
                    </div>
                </div>
            );
        }
    };

    const populateAdditionalInfo = () => {
        let additionalInfo = {};

        if (modalItem.maritalStatus)
            additionalInfo["Marital Status"] =
                maritalStatus[modalItem.maritalStatus];
        if (modalItem.languages)
            additionalInfo["Languages"] = formatLanguages(modalItem.languages);
        if (modalItem.permit)
            additionalInfo["Work Permit for USA"] =
                workPermit[modalItem.permit];
        if (modalItem.score) {
            if (modalItem.score.cat)
                additionalInfo["CAT Percentile"] = modalItem.score.cat;
            if (modalItem.score.iit)
                additionalInfo["IIT-JEE All India Rank (AIR)"] =
                    modalItem.score.iit;
            if (modalItem.score.gmat)
                additionalInfo["GMAT Score"] = modalItem.score.gmat;
        }
        if (modalItem.handleTeam)
            additionalInfo["Handled a team?"] = binaries[modalItem.handleTeam];
        if (modalItem.sixDays)
            additionalInfo["Willing to work 6 days?"] =
                binaries[modalItem.sixDays];
        if (modalItem.differentlyAbled)
            additionalInfo["Differently Abled?"] =
                binaries[modalItem.differentlyAbled];
        if (modalItem.relocate)
            additionalInfo["Willing to relocate?"] =
                binaries[modalItem.relocate];
        if (modalItem.joinStartup)
            additionalInfo["Willing to join an early-stage startup?"] =
                binaries[modalItem.joinStartup];
        if (modalItem.travel)
            additionalInfo["Willingness to travel?"] =
                willingTravel[modalItem.travel];
        if (additionalInformation.military)
            additionalInfo["Ex-Defence Personnel"] =
                binaries[+additionalInformation.military];
        if (additionalInformation.singleParent)
            additionalInfo["Single Parent"] =
                binaries[+additionalInformation.singleParent];
        if (additionalInformation.workingMother)
            additionalInfo["Working Mother"] =
                binaries[+additionalInformation.workingMother];
        if (additionalInformation.militaryOpt)
            additionalInfo["Ex-Defence Personnel"] =
                militaryOptMap[+additionalInformation.militaryOpt];
        if (additionalInformation.disability)
            additionalInfo["Differently Abled?"] =
                subDisabilityMap[+additionalInformation.disability];

        if (Object.keys(additionalInfo).length > 0) {
            return (
                <div className="candidate-details additional-info">
                    <h3 className="details-header">Additional Information</h3>
                    <div className="details-content">
                        {
                            <ul>
                                {Object.entries(additionalInfo).map((info) => {
                                    return (
                                        <li>
                                            <p>
                                                <span className="key">
                                                    {info[0]}
                                                </span>
                                                <span className="val">
                                                    {info[1]}
                                                </span>
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        }
                    </div>
                </div>
            );
        }
    };

    const formatLanguages = (data) => {
        if (!data) return;
        var ob = JSON.parse(data);
        var langArray = [];
        for (var key in ob) {
            langArray.push(ob[key]["language_text"]);
        }
        return langArray.join(", ");
    };

    const onClickSortButton = (category, index) => {
        setCategory(category);
        const oldParams = getParams(filterList);
        const newParams = {
            ...oldParams,
            status: category
        }
        routeRequest(newParams);
    };

    const routeRequest = (parameters) => {
        let array = [];
        for (const key in parameters) {
            if (
                parameters[key] != null &&
                key !== "arrow" &&
                key !== "loader" &&
                key !== "totalItemsCount" &&
                key !== "joblist"
            ) {
                if (key == "asc")
                    array.push("order=" + (parameters[key] ? "asc" : "desc"));
                else {
                    if (
                        typeof parameters[key] == "number" &&
                        parameters[key] > 0
                    )
                        array.push(key + "=" + parameters[key]);
                    if (typeof parameters[key] == "string")
                        array.push(key + "=" + parameters[key]);
                }
            }
        }

        array.push(`jobId=${jobId || jobid}`);
        array = array.join("&");
        props.history.push("?" + array);
    };

    const handleKeyPress = (event) => {
        if (event.key == "Enter") {
            onClickSearch();
        }
    };

    const onClickSearch = () => {
        const params = getParams(filterList);
        getApplications(1, params);
        routeRequest(params);
    };

    const onClickClearSearch = () => {
        setSearchKeyword("");
        const params = getParams(filterList);
        params['searchString'] = ''
        getApplications(1, params);
        routeRequest(params);
    };

    const onFilterCheckboxChange = (val, name) => {
        const data = selectedFilters[name]
        if (!data[val]) {
            setSelectedFilters(prev => ({
                ...prev,
                [name]: {...prev[name], [val]:true}
            }))
            
        } else {
            setSelectedFilters(prev => ({
                ...prev,
                [name]: {...prev[name], [val]:false}
            }))
        }
    }

    const onFilterDropdownChange = (val, name) => {
        setSelectedFilters(prev => ({
            ...prev,
            [name]: val
        }))
    }
    const onChangeFilter = (event) => {
        setFilter(event.target.value);
        const oldParams = getParams(filterList);
        const newParams = {
            ...oldParams,
            orderBy: event.target.value
        }
        routeRequest(newParams);
    };

    const onChangeSearch = (event) => {
        setSearchKeyword(event.target.value);
    };

    const onClickFilter = () => {
        setApplicationFilterModal(true);
    };

    const handleFilterModalClose = () => {
        setApplicationFilterModal(false);
    };

    const onClickViewResume = (e, candidate) => {
        e.preventDefault();
        e.stopPropagation();
        setModalItem(candidate);
        setViewResumeModal(true);

    }

    const handleViewResumeClose = () => {
        setViewResumeModal(false);
        setModalItem(null);
    };

    function onResumeLoadSuccess({ numPages }) {
        setResumeNumPages(numPages);
      }

    const getParams = (filters, download) => {
        const params = {...filters};

        params["orderBy"] = filter;
        params["status"] = category;
        if (searchKeyword) params["searchString"] = searchKeyword;
        if(download) params['download'] = true;
        return params;
    };

    const formatTags = (tags) => {
        return tags.map((item) => {
            return {
                label: item.name,
                value: item.id.toString(),
                key: item.id,
            };
        });
    };

    const formatOrganization = (org) => {
        return org.map((item) => {
            return {
                label: item.name,
                value: item.name.toString(),
                key: item.name,
            };
        });
    };

    const getParamsList = (obj) => {
        return Object.keys(obj).filter(key => obj[key])
    }

    const onApplyFilter = async () => {
        const params = {};
        
        if(getParamsList(selectedFilters.curLoc).join(',')) {
           params.location = getParamsList(selectedFilters.curLoc).join(',');
        }
        if (getParamsList(selectedFilters.prefLoc).join(',')) {
            params.preferredLocation = getParamsList(selectedFilters.prefLoc).join(',');
        }
        if (getParamsList(selectedFilters.institute).join(',')) {
            params.institute = getParamsList(selectedFilters.institute).join(',');
        }
        if (getParamsList(selectedFilters.degree).join(',')) {
            params.degree = getParamsList(selectedFilters.degree).join(',');
        }
        if (getParamsList(selectedFilters.fa).join(',')) {
            params.functionalArea = getParamsList(selectedFilters.fa).join(',');
        }
        if (getParamsList(selectedFilters.language).join(',')) {
            params.language = getParamsList(selectedFilters.language).join(',');
        }
        if (getParamsList(selectedFilters.courseType).join(',')) {
            params.courseType = getParamsList(selectedFilters.courseType).join(',');
        }
        if (getParamsList(selectedFilters.tags).join(',')) {
            params.tags = getParamsList(selectedFilters.tags).join(',');
        }
        if (getParamsList(selectedFilters.org).join('|')) {
            params.organization = getParamsList(selectedFilters.org).join('|');
        }
        if (getParamsList(selectedFilters.curOrg).join('|')) {
            params.currentOrganization = getParamsList(selectedFilters.curOrg).join('|');
        }
        if (selectedFilters.noticePeriod) {
            params.notice = selectedFilters.noticePeriod;            
        }
        if (selectedFilters.applicationDate) {
            params.applicationDate = selectedFilters.applicationDate;            
        }
        if (selectedFilters.lastSeen) {
            params.lastActive = selectedFilters.lastSeen;            
        }
        if (selectedFilters.relocate) {
            params.relocate = selectedFilters.relocate;            
        }
        if (selectedFilters.workPermit) {
            params.permit = selectedFilters.workPermit;            
        }
        if (selectedFilters.differentlyAbled) {
            params.differentlyAbled = selectedFilters.differentlyAbled;            
        }
        if (selectedFilters.handledTeam) {
            params.handleTeam = selectedFilters.handledTeam;            
        }
        if (selectedFilters.filterAssessment) {
            params.filterassessment = selectedFilters.filterAssessment;            
        }
        if (salaryFilter.min) {
            params.minCtc = salaryFilter.min;
        }
        if (salaryFilter.max) {
            params.maxCtc = salaryFilter.max;
        }
        if (experienceFilter.min != -1) {
            params.minExp = experienceFilter.min;
        }
        if (experienceFilter.max != -1) {
            params.maxExp = experienceFilter.max;
        }
        if (batchFilter.min) {
            params.minBatch = batchFilter.min;
        }
        if (batchFilter.max) {
            params.maxBatch = batchFilter.max;
        }
        if (ageFilter.min) {
            params.minAge = ageFilter.min;
        }
        if (ageFilter.max) {
            params.maxAge = ageFilter.max;
        }
        setFilterList(params);
        handleFilterModalClose();
        const parameters = getParams(params)
        await getApplications(1, parameters);
        routeRequest(parameters)
    };

    const onResetFilter = async () => {
        setSelectedFilters(initialSelectedFilters);
        setSalaryFilter({
            min: 0,
            max: 0
        });
        setAgeFilter({
            min: '',
            max: ''
        });
        setBatchFilter({
            min: '',
            max: ''
        });
        setExperienceFilter({
            min: -1,
            max: -1
        });
        handleFilterModalClose();
        setFilterList({})
        const parameters = getParams({})
        await getApplications(1, parameters);
        routeRequest(parameters)
 
    }

    const downloadExcel = async() => {
        const params = getParams(filterList, true);
        getApplications(content.pageNumber || 1, params);
    }

    return (
        <div className="applications-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="pageHeading">
                <Heading text="Applications" />
                <div className="job-details">
                    <div className="job-title">
                        <span className="title">Job Title: </span>
                        <span className="body">{job.title}</span>
                    </div>

                    <span className="title">Job Code: </span>
                    <span className="body">{jobId}</span>
                </div>
                <ButtonContainer heading="Categorize By: ">
                    {applicationCategories.map((category, i) => (
                        <li key={category.id}>
                            {" "}
                            <LinkButtons
                                name={`${category.name} (${
                                    applicationCountByType[category.text]
                                })`}
                                arrow={category.arrow}
                                arrowType={category.asc}
                                id={category.id}
                                sortClick={() =>
                                    onClickSortButton(category.id, i)
                                }
                            />{" "}
                        </li>
                    ))}
                </ButtonContainer>
                <Button  skin= 'dark' className="premium_listing_btn" text="Premium Job List" clickButton={() => props.history.push(`/premiumJobList`)} />                               
                <Button  skin= 'dark' className="download_excel" text="Download Excel" clickButton={downloadExcel} />                               

            </div>
            <div className="row">
                <ButtonContainer heading="Sort By: ">
                    <li key="filter">
                        {" "}
                        <Dropdown
                            data={applicationSort || []}
                            dropdownChange={onChangeFilter}
                            defaultValue={filter}
                        />{" "}
                    </li>
                </ButtonContainer>
                <div className="filter-container">
                    <ButtonContainer heading="Filter By: ">
                        <Button
                            skin="light"
                            disabled={false}
                            text="Select"
                            clickButton={onClickFilter}
                        />
                    </ButtonContainer>
                </div>

            </div>
            <div className="input-search-field">
                <div className="job-id-search-field">
                    <InputBox
                        type="number"
                        placeholder="Search Job Id"
                        value={searchJobId}
                        onChange={(e) => setSearchJobId(e.target.value)}
                    />
                    <Button
                        className="job-id-search-btn"
                        skin="dark"
                        disabled={false}
                        text="Search"
                        clickButton={searchByJobId}
                    />
                </div>
                <div className="keyword-search-field">
                    <InputBox
                        placeholder="Search Keyword or Candidates"
                        value={searchKeyword}
                        onChange={onChangeSearch}
                        onKeyPress={handleKeyPress}
                    />
                    <Button
                        skin="dark"
                        disabled={false}
                        text="Search"
                        clickButton={onClickSearch}
                    />
                    <Button
                        skin="light"
                        disabled={false}
                        text="Clear"
                        clickButton={onClickClearSearch}
                    ></Button>
                </div>
            </div>
            <div className="row text-center">
                <Pagination
                    totalItemsCount={
                        applicationCountByType[applicationCategoryMap[category]]
                    }
                    content={PAGE_CONTENT}
                    pageNumber={content.pageNumber}
                    handlePageChange={(n) => handlePageChange(n)}
                />
                <CountDisplay
                    start={
                        content.applicationList.length == 0
                            ? content.applicationList.length
                            : (content.pageNumber - 1) * PAGE_CONTENT + 1
                    }
                    end={
                        content.pageNumber * PAGE_CONTENT -
                        (content.applicationList.length < PAGE_CONTENT
                            ? PAGE_CONTENT - content.applicationList.length
                            : 0)
                    }
                    total={
                        applicationCountByType[applicationCategoryMap[category]]
                    }
                />

                {fetchApiCall ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Application Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Personal Details
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Professional Details
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Educational Details
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Salary
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Status
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Actions
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.applicationList.map((item, i) => {
                                    return (
                                        <Table.Row
                                            onClick={() =>
                                                onCandidateClick(item)
                                            }
                                        >
                                            <Table.Cell>{item.id}</Table.Cell>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell>
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>Experience:</td>
                                                        </b>{" "}
                                                        <td>{`${item.exp.year}y ${item.exp.month}m`}</td>
                                                    </tr>
                                                }
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>Location:</td>
                                                        </b>{" "}
                                                        <td>{`${item.currentLocation}`}</td>
                                                    </tr>
                                                }
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>Applied on:</td>
                                                        </b>{" "}
                                                        <td>{`${moment(
                                                            item.date
                                                        ).format(
                                                            "DD-MM-YYYY"
                                                        )}`}</td>
                                                    </tr>
                                                }
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>
                                                                Notice Period:
                                                            </td>
                                                        </b>{" "}
                                                        <td>
                                                            {item.notice == 7
                                                                ? "Immediately available"
                                                                : `${item.notice} months`}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>Email:</td>
                                                        </b>{" "}
                                                        <td>{item['email']}</td>
                                                    </tr>
                                                }
                                                {
                                                    <tr>
                                                        <b>
                                                            <td>Phone:</td>
                                                        </b>{" "}
                                                        <td>{item['phone']}</td>
                                                    </tr>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.jobs
                                                    ? item.jobs.map((job) => {
                                                          let expStart = `${moment(
                                                              job.exp.from
                                                                  .month,
                                                              "M"
                                                          ).format("MMMM")}, ${
                                                              job.exp.from.year
                                                          }`;
                                                          let expEnd = job.is_current
                                                              ? "Present"
                                                              : `${moment(
                                                                    job.exp.to
                                                                        .month,
                                                                    "M"
                                                                ).format(
                                                                    "MMMM"
                                                                )}, ${
                                                                    job.exp.to
                                                                        .year
                                                                }`;
                                                          return (
                                                              <tr>
                                                                  <tr>
                                                                      <b>
                                                                          <td className="candidate-heading-row">
                                                                              {
                                                                                  job.organization
                                                                              }
                                                                          </td>
                                                                      </b>
                                                                  </tr>
                                                                  <tr>
                                                                      <td className="candidate-data-row">
                                                                          {
                                                                              job.designation
                                                                          }
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td className="candidate-data-row">{`${expStart} to ${expEnd}`}</td>
                                                                  </tr>
                                                              </tr>
                                                          );
                                                      })
                                                    : null}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.education
                                                    ? item.education.map(
                                                          (edu) => {
                                                              let batchStart =
                                                                  edu.batch
                                                                      .from;
                                                              let batchEnd =
                                                                  edu.batch.to;
                                                              return (
                                                                  <tr>
                                                                      <tr>
                                                                          <b>
                                                                              <td className="candidate-heading-row">
                                                                                  {
                                                                                      edu.institute
                                                                                  }
                                                                              </td>
                                                                          </b>
                                                                      </tr>
                                                                      <tr>
                                                                          <td className="candidate-data-row">{`${edu.degree} (${edu.courseType})`}</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td className="candidate-data-row">{`${batchStart} to ${batchEnd}`}</td>
                                                                      </tr>
                                                                  </tr>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item['ctc']}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {applicationStatusMap[item['status']]}
                                                <br/>
                                                {
                                                    applicationStatusMap[item['status']]=='Reviewed' ? 
                                                        item['status'] == 4 ? 'Action - Viewed' 
                                                        : item['status'] == 5 ? 'Action - Downloaded'
                                                        : item['status'] == 9 ? 'Action - Viewed Round Zero'
                                                        : null
                                                    : null 
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                <a href="" onClick={(e) => onClickViewResume(e,item)}>View Resume</a>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Modal
                            className="applications-detail-modal"
                            open={candidateDetailModal}
                            onClose={handleModalClose}
                            size="large"
                        >
                            {modalItem && (
                                <>
                                    <Header content={modalItem.name} />
                                    <Modal.Content scrolling>
                                        {
                                            <>
                                                <div className="candidate-details professional">
                                                    <h3 className="details-header">
                                                        Professional Details
                                                    </h3>
                                                    <div className="details-content">
                                                        {modalItem.jobs
                                                            ? modalItem.jobs.map(
                                                                  (job) => {
                                                                      let expStart = `${moment(
                                                                          job
                                                                              .exp
                                                                              .from
                                                                              .month,
                                                                          "M"
                                                                      ).format(
                                                                          "MMMM"
                                                                      )}, ${
                                                                          job
                                                                              .exp
                                                                              .from
                                                                              .year
                                                                      }`;
                                                                      let expEnd = job.is_current
                                                                          ? "Present"
                                                                          : `${moment(
                                                                                job
                                                                                    .exp
                                                                                    .to
                                                                                    .month,
                                                                                "M"
                                                                            ).format(
                                                                                "MMMM"
                                                                            )}, ${
                                                                                job
                                                                                    .exp
                                                                                    .to
                                                                                    .year
                                                                            }`;
                                                                      return (
                                                                          <>
                                                                              <b>
                                                                                  <div className="candidate-details-heading">
                                                                                      {
                                                                                          job.organization
                                                                                      }
                                                                                  </div>
                                                                              </b>
                                                                              <div className="candidate-details-data">
                                                                                  {
                                                                                      job.designation
                                                                                  }
                                                                              </div>
                                                                              <div className="candidate-details-data">{`${expStart} to ${expEnd}`}</div>
                                                                              <div className="separator"></div>
                                                                          </>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </div>
                                                </div>

                                                <div className="candidate-details educational">
                                                    <h3 className="details-header">
                                                        Educational Details
                                                    </h3>
                                                    <div className="details-content">
                                                        {modalItem.education
                                                            ? modalItem.education.map(
                                                                  (edu) => {
                                                                      let batchStart =
                                                                          edu
                                                                              .batch
                                                                              .from;
                                                                      let batchEnd =
                                                                          edu
                                                                              .batch
                                                                              .to;
                                                                      return (
                                                                          <>
                                                                              <b>
                                                                                  <div className="candidate-details-heading">
                                                                                      {
                                                                                          edu.institute
                                                                                      }
                                                                                  </div>
                                                                              </b>
                                                                              <div className="candidate-details-data">{`${edu.degree} (${edu.courseType})`}</div>
                                                                              <div className="candidate-details-data">{`${batchStart} to ${batchEnd}`}</div>
                                                                              <div className="separator"></div>
                                                                          </>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </div>
                                                </div>

                                                <div className="candidate-details more-info">
                                                    <h3 className="details-header">
                                                        More Information
                                                    </h3>
                                                    <div className="details-content">
                                                        {
                                                            <ul>
                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Location
                                                                        </span>
                                                                        <span className="val">
                                                                            {
                                                                                modalItem.currentLocation
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Preferred
                                                                            Location
                                                                        </span>
                                                                        <span className="val">
                                                                            {modalItem.preferredLocation
                                                                                ? modalItem.preferredLocation.join(
                                                                                      ","
                                                                                  )
                                                                                : null}
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Current
                                                                            Salary
                                                                        </span>
                                                                        <span className="val">
                                                                            {isNaN(
                                                                                modalItem.ctc
                                                                            )
                                                                                ? "Confidential"
                                                                                : `${modalItem.ctc} lakhs`}
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Expected
                                                                            Salary
                                                                        </span>
                                                                        <span className="val">
                                                                            {isNaN(
                                                                                modalItem.expectedCtc
                                                                            )
                                                                                ? "Confidential"
                                                                                : `${modalItem.expectedCtc} lakhs`}</span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Experience
                                                                        </span>
                                                                        <span className="val">{`${modalItem.exp.year}y ${modalItem.exp.month}m`}</span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Gender
                                                                        </span>
                                                                        <span className="val">
                                                                            {
                                                                                gender[
                                                                                    modalItem
                                                                                        .sex
                                                                                ]
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Age
                                                                        </span>
                                                                        <span className="val">{`${moment().diff(
                                                                            moment(
                                                                                modalItem.dob
                                                                            ),
                                                                            "years"
                                                                        )} years`}</span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Notice
                                                                            Period
                                                                        </span>
                                                                        <span className="val">
                                                                            {modalItem.notice ==
                                                                            7
                                                                                ? "Immediately available"
                                                                                : `${modalItem.notice} months`}
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Application
                                                                            Date
                                                                        </span>
                                                                        <span className="val">{`${moment(
                                                                            modalItem.date
                                                                        ).format(
                                                                            "DD-MM-YYYY"
                                                                        )}`}</span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="candidate-details contact-info">
                                                    <h3 className="details-header">
                                                        Contact Information
                                                    </h3>
                                                    <div className="details-content">
                                                        {
                                                            <ul>
                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Phone
                                                                        </span>
                                                                        <span className="val">
                                                                            {
                                                                                modalItem.phone
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </li>

                                                                <li>
                                                                    <p>
                                                                        <span className="key">
                                                                            Email
                                                                        </span>
                                                                        <span className="val">
                                                                            {
                                                                                modalItem.email
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>

                                                {populateAdditionalInfo()}

                                                {populateRecommendation()}
                                            </>
                                        }
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button
                                            clickButton={handleModalClose}
                                            skin="light"
                                            text="Close"
                                        />
                                    </Modal.Actions>
                                </>
                            )}
                        </Modal>
                        <MultiFilterModal
                            onApply={onApplyFilter}
                            open={applicationFilterModal}
                            onClose={handleFilterModalClose}
                            tags={tags}
                            currOrg={currOrg}
                            org={org}
                            selectedFilters={selectedFilters}
                            salaryFilter={salaryFilter}
                            batchFilter={batchFilter}
                            experienceFilter={experienceFilter}
                            ageFilter={ageFilter}
                            onCheckboxChange={onFilterCheckboxChange}
                            onDropdownChange={onFilterDropdownChange}
                            setSalaryFilter={setSalaryFilter}
                            setAgeFilter={setAgeFilter}
                            setBatchFilter={setBatchFilter}
                            setExperienceFilter={setExperienceFilter}
                            onReset={onResetFilter}
                        ></MultiFilterModal>
                        <Modal
                            className="applications-resume-modal"
                            open={viewResumeModal}
                            onClose={handleViewResumeClose}
                            size="large"
                        >
                            {
                                modalItem && (
                                    <>
                                        <Header content={modalItem.name} />
                                            <Modal.Content scrolling>
                                            {
                                                modalItem.resume ? (
                                                    <div className="resume-container">
                                                        <Document file={`${isTechDomain() ? process.env.REACT_APP_BASE_URL_TECH : process.env.REACT_APP_BASE_URL}${modalItem.resume}`} onLoadSuccess={onResumeLoadSuccess} error="No Resume Found!">
                                                        {
                                                            Array.from(Array(resumeNumPages).keys()).map(key => 
                                                                <Page pageNumber={key + 1}/>
                                                            )
                                                        }
                                                    </Document>
                                                    </div>
                                                    
                                                    
                                                ): <div>No Resume Found!</div>
                                            }
                                            </Modal.Content>
                                            <Modal.Actions>
                                            <Button
                                                clickButton={downloadResume}
                                                skin="dark"
                                                text="Download Resume"
                                            />
                                            <Button
                                                clickButton={handleViewResumeModalClose}
                                                skin="light"
                                                text="Close"
                                            />
                                        </Modal.Actions>
                                    </>
                                )
                            }
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApplicationList;
