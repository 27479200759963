import axios from '../utils/axios';

export default function getDomainListCount(adminId, params) {
    return (
        axios.get('/admin/' + adminId + '/domain-info-count', { params })
            .then(res => {
                return res.data;
            }).catch(err => {
                return err;
            })
    )
}