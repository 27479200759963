import React, { useState, useEffect } from "react";
import user from "../../store/user";
import fetchUserAppliedJobs from "../../services/fetchUserAppliedJobs";

import "./userAppliedJobs.scss";
import { Table } from "semantic-ui-react";
import Loader from "../../components/Loader";
import CountDisplay from "../../components/CountDisplay";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import { toast, ToastContainer } from "react-toastify";
import InputBox from "../../components/InputBox";
import moment from "moment";
import { getQueryParams } from "../../utils/utilFunctions";

const PAGE_CONTENT = 20;
const UserAppliedJobs = (props) => {

    const params = getQueryParams(window.location.search);
    const seekerId = params.seekerId;

    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 0
    })
    const [admin, setAdmin] = useState({
        adminId: null,
    })
    const [fetchApiCall, setFetchApiCall] = useState(false)

    const [searchKeyword, setSearchKeyword] = useState('')

    const [validation, setValidation] = useState({})

    const [searchedOnce, setSearchedOnce] = useState(false);

    useEffect(() => {

        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3) {
                setAdmin({
                    adminId: userData.id,
                })

                if(seekerId) {
                    getUserAppliedJobs(userData.id, 1, {seekerId})
                }
                        
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });

        

    }, [])

    const getUserAppliedJobs = async (adminId, page, filters = {}) => {
        setFetchApiCall(true);
        try {
            
            const res = await fetchUserAppliedJobs(adminId, { pageNumber: page, pageContent: PAGE_CONTENT, ...filters })
            setContent({
                pageNumber: page,
                list: res.data.data.data,
                count: res.data.data.totalCount
            })
            setFetchApiCall(false)
        }
        catch (err) {
            console.log(err);
            setFetchApiCall(false)
        }
    }

    const handlePageChange = async (n) => {
        const queryParams = getQueryParams(props.location.search);
        const params =  {};
        if(searchKeyword){
            params['email'] = searchKeyword
        }

        else if (queryParams['seekerId']) {
            params['seekerId'] = queryParams['seekerId'];
        }

        await getUserAppliedJobs(admin.adminId, n, params)
        
    }

    const searchUserAppliedJobs = async () => {
    
      if (searchKeyword) {
        setValidation({})
        props.history.push('?');
          if(!searchedOnce) {
            setSearchedOnce(true);
          }
          await getUserAppliedJobs(admin.adminId, 1, {email: searchKeyword})
      }
      else {
        setValidation({
            keywordReq: true
        })
      }
    };

    const clearUserJobsSearch = async (e) =>  {
        setValidation({})
        setSearchKeyword('');
    };


    const onEnter = async (e) => {
        if(e.key === 'Enter') {
            await searchUserAppliedJobs()
        }
    }

    return (
        <div className="usr-applied-jobs-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="usr-applied-jobs-heading">
                <Heading text="Company List" />
            </div>


            <div className='seeker-search-field'>
                <div>
                <label>Search By Email: </label>
                    <InputBox placeholder="Enter seeker email" name="seekerSearch" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} onKeyPress={onEnter} />

                    <Button skin="dark" text="Search" clickButton={searchUserAppliedJobs}/>
                    <Button skin="light" text="Clear" clickButton={clearUserJobsSearch} />
                    {validation.keywordReq ? <p className='validatorError'> Enter seeker email to search</p> : null}

                </div>
                <div className='right-aligned'>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/userapplyjobs.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
                </div>
            </div>
            <hr />

            {
                (searchedOnce || seekerId) ? (
                    <div className="row text-center">
                        <Pagination
                            totalItemsCount={content.count}
                            content={PAGE_CONTENT}
                            pageNumber={content.pageNumber}
                            handlePageChange={(n) => handlePageChange(n)}
                        />
                        <CountDisplay
                            start={content.list.length == 0 ? content.list.length : (content.pageNumber - 1) * PAGE_CONTENT + 1}
                            end={content.pageNumber * PAGE_CONTENT - ((content.list.length < PAGE_CONTENT ? PAGE_CONTENT - content.list.length : 0))}
                            total={content.count}
                        />

                        {fetchApiCall ? (
                            <Loader />
                        ) : (
                            content.count ? (
                                <div className="content">
                                <Table
                                    fixed
                                    selectable
                                    className="mailers-table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={4}>
                                                Apply Date
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={8}>
                                                Title
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={8}>
                                                Location
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={8}>
                                                Application Status
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={8}>
                                                Action Date / Time
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {content.list.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>
                                                        {moment(item['applydate']).format('D-M-Y')}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        { <a target='_blank' href={`${process.env.REACT_APP_CLIENT_URL}/j/${item['title']}-${item['id']}.html?ref=apj`}> {item['title']} </a> }
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {item['created_by_alias'].length > 25 ? 'Multiple Location' : item['created_by_alias']}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {item['status']}
                                                        {
                                                            item['premiumApply'] ? (
                                                                <div className="usr_premium">
                                                                    <p>Premium</p>
                                                                </div>
                                                            ): null
                                                        }
                                                        
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(item['applydate']).format('D-M-Y hh:mm:ss a')}
                                                    </Table.Cell>
                                                    
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>

                            </div>
                    ) : (
                        <div>
                            No Records Found
                        </div>
                    )
                        )
                        }
                    </div>
                ) : null
            }
            
        </div>
    );
};

export default UserAppliedJobs;
