const recruiterTypes = [
    { text: 'Direct Employer', value: '1' },
    { text: 'Recruitment Firm', value: '2' },
    { text: 'Individual Consultant', value: '3' }
];

export function getRecruiterTypeArr() {
    return recruiterTypes;
}

export function getValueFromRecruiterType(type) {
    for (let i = 0; i < recruiterTypes.length; i++) {
        if (recruiterTypes[i].value == type) {
            return recruiterTypes[i].text;
        }
    }

    return '';
}