import React, { useEffect, useState } from "react";
import './jobVisibility.scss'
import user from "../../store/user";
import { adminFeatureMapping } from "../../models/adminFeatureMapping";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import { getCategoryMap } from "../../models/categories";
import { cleanText } from "../../utils/utilFunctions";
import { getLocationMap } from "../../models/location";
import moment from "moment";
import fetchJobVisibilityDetails from "../../services/fetchJobVisibilityDetails";



const JobVisibility = (props) => {

  const jobId = props.match.params.jobId;

  const [details, setDetails] = useState({tags: [], location: []});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    setIsLoading(true);

    const fetchProfile = async () => {
        return await user.getProfile();
      };

    
    fetchProfile().then(userData => {
        if (!user.checkFeatureAccess(adminFeatureMapping.JOBS_LIST)) {
            window.location.href='/notAdmin'
        }

        getJobVisibility(userData.id);

    })


  }, []);


  const getJobVisibility = async (adminId) => {

    try {

        const jobData = await fetchJobVisibilityDetails(adminId, jobId);
        setDetails(jobData.data.data[0]);
        setIsLoading(false);

    }

    catch(e) {
        console.log(e);
        toast.error('Something went wrong!');
        setIsLoading(false);
    }

  }

  return (
    <div className="job-visibility-container">
        <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
        />

        {
            isLoading && !details ? <Loader/> : (
                    <div className="job-details-container">
                        <div className="left">
                            <div className="job-title">{details.title}</div>
                            <div className="tags-container">
                                {   
                                    details.tags.map((tag, index) => (
                                        <a target="_blank" href={`${process.env.REACT_APP_CLIENT_URL}/k/${cleanText(`${tag['name']} Jobs`)}-${tag['id']}.html`} className="tags">#{tag['name']}  </a>
                                    ))
                                }
                            </div>
                    
                            <div className="job-description" dangerouslySetInnerHTML={{ __html: details.description }}  >
                            </div>
                        </div>

                        <div className="right">

                        <div className="job-details">
                            <span className="job-heading">
                                Written by
                            </span>
                            <span className="job-value">
                                MBA Jobs on {moment(details.created).format('dddd, MMMM D YYYY')}
                            </span>
                        </div>
                        <div className="job-details">
                            <span className="job-heading">
                                Posted In
                            </span>
                            <a target="_blank" className="job-value job-value-link" href={`${process.env.REACT_APP_CLIENT_URL}/c/${cleanText(`${getCategoryMap()[details.category]} Jobs`)}-${details.category}.html`}>
                                {getCategoryMap()[details.category]} Jobs
                            </a>
                        </div>
                        <div className="job-details">
                            <span className="job-heading">
                                View All Jobs Posted By
                            </span>
                            <a target="_blank" className="job-value job-value-link" href={`${process.env.REACT_APP_CLIENT_URL}/r/${details.uid}`}>
                                {details.recname}
                            </a>
                        </div>
                        <div className="job-details">
                            <span className="job-heading">
                                Job Code
                            </span>
                            <span className="job-value">
                                {details.id}
                            </span>
                        </div>
                        <div className="job-details">
                            <span className="job-heading">
                                Location
                            </span>
                            <span className="job-value">
                                {details.location.join('/')}
                            </span>
                        </div>
                            
                        </div>
                    
                        
                    </div>
               
            )
        }
    </div>
  )
    
    
};

export default JobVisibility;
