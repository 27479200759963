import React from "react";
import Dropdown from "../../../components/Dropdown";
import InputBox from "../../../components/InputBoxWithoutCheck";
import Button from "../../../components/Button";
import Select, { Creatable } from "react-select";

import Heading from "../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var scrollToElement = require("scroll-to-element");

import user from "../../../store/user";

import updateShowcaseCompany from "../../../services/updateShowcaseCompany";
import createShowcaseCompany from "../../../services/createShowcaseCompany";

import validator from "validator";

import "./showcaseCreate.scss";
import "../../../themes/grid.scss";
import { adminFeatureMapping } from "../../../models/adminFeatureMapping";

const reactSelectKeys = ["firebaseKeywords", "gtagKeywords", "jobKeywords", "diversityFirebaseKeywords", "diversityGtagKeywords", "diversityJobKeywords"];

const validationMsg = {
  companyNameReq: "Name is Required",
  
};

const validationClass = {
  classNameReq: ".companyName",
  
};

class ShowcaseCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      data: {
        companyName: '',
        firebaseKeywords: [],
        gtagKeywords: [],
        jobKeywords: [],
        diversityFirebaseKeywords: [],
        diversityGtagKeywords: [],
        diversityJobKeywords: [],
        showcasePrice: '',
        diversityPrice: ''
      },
      cutOff: 1,
      viewAccess: false,
      open: {},
      validations: {},
      submitApiCall: false,
    };
    this.reactTags = React.createRef();
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    const profile = await user.getProfile();
    const adminId = profile.id;
    const type = profile.type;
    var company = null;

    if (this.props.location.state) company = this.props.location.state.company;

    const viewAccess = user.checkFeatureAccess(adminFeatureMapping.SHOWCASE_VIEW);

    // const viewAccess = process.env.REACT_APP_SHOWCASE_COMPANIES_LIST_ACCESS_EMAILS.includes(
    //   profile.email.toLowerCase()
    // );
    
    this.setState({
      viewAccess,
    });

    if (company) {
      this.setData(company);
    } else {
      let data = { ...this.state.data };
      this.setState({ data });
    }
  }

  setData(resp) {
    const {
      id,
      companyName,
      firebaseKeywords,
      gtagKeywords,
      jobKeywords,
      diversityFirebaseKeywords,
      diversityGtagKeywords,
      diversityJobKeywords,
      showcasePrice,
      diversityPrice
      
    } = resp;

    let data = {
      companyName: companyName,
      firebaseKeywords: firebaseKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      gtagKeywords: gtagKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      jobKeywords: jobKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      diversityFirebaseKeywords: diversityFirebaseKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      diversityGtagKeywords: diversityGtagKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      diversityJobKeywords: diversityJobKeywords.split(",").map((i) => {
        return { label: i, value: i };
      }),
      showcasePrice: { label: showcasePrice, value: showcasePrice },
      diversityPrice:{ label: diversityPrice, value: diversityPrice }
    };

    this.setState({ data });
  }

  onChange(key, ev) {
    let data = { ...this.state.data };
    data[key] = ev.target.value;
    if (ev.target.type === "checkbox") {
      data[key] = ev.target.checked;
    }
    this.setState({
      data,
    });
  }


  onClose = (event, data) => {
    const open = { ...this.state.open };
    open[data.name] = false;
    this.setState({
      open,
    });
  };

  validate() {
    const { data, cutOff } = this.state;

    let validations = {
      companyNameReq: this.checkEmpty(data.companyName),
      
    };
    this.setState({ validations });
    for (let key in validations) {
      if (validations[key]) {
        
        scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;
  }

  checkEmpty(val) {
    if (!val) return true;
    if (Array.isArray(val) && !val.length) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  async onSubmit(e) {

    const company = this.props.location.state ? this.props.location.state.company: null;
    
    const data = JSON.parse(JSON.stringify(this.state.data));

    reactSelectKeys.forEach((key) => {
      data[key] = data[key].map((i) => i.value);
      data[key] = data[key].toString();
    });
    data['showcasePrice']= data['showcasePrice'] ? data['showcasePrice'].value : '';
    data['diversityPrice']= data['diversityPrice'] ? data['diversityPrice'].value : ''

    e.preventDefault();

    if (this.validate()) {
      this.setState({ submitApiCall: true });

      const profile = await user.getProfile();
      const adminId = profile.id;

      try {
        if (company) {
          const id = company.id;
          await updateShowcaseCompany(adminId, id, data);
        } else {
          await createShowcaseCompany(adminId, data);

        }

        if (company) {
          toast.info("Company Details Updated Successfully");
        } else {
          toast.info("Company Created Successfully");
        }

        this.setState({ submitApiCall: false, submitApiCallTest: false });

        setTimeout(() => {
          this.props.history.push("/showcase");
        }, 100);
      } catch (err) {
        console.log(err);
        this.setState({ submitApiCall: false, submitApiCallTest: false });
        if (company) {
          toast.error("Error while updating company details");
        } else {
          toast.error("Error while creating company");
        }
      }
    }
  }

  companyList() {
    this.props.history.push(`/showcase`);
  }

  handleSelectChange(key, selectedOptions) {
    const data = { ...this.state.data };
    data[key] = selectedOptions;
    this.setState({ data });
  }

  render() {
    const {
      validations,
      submitApiCall,
      viewAccess,
    } = this.state;

    return (
      <div>
        {viewAccess ? (
          <div className="wrapper">
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
            />

            <div className="pageHeading">
              <Heading text="Showcase Company" />
              <Button
                skin="dark"
                text="Company List"
                clickButton={() => {
                  this.companyList();
                }}
              />
            </div>
            <hr />
            <div className="create-tag-mailer">
              <form>
                <div className="row section details-section">
                  <p className="label-heading"> New Company Details </p>

                  <div className="form-group companyName">
                    <label className="col-sm-2 left"> Name *</label>
                    <div className="col-sm-6 left valueBox">
                      <InputBox
                        type="text"
                        value={this.state.data.companyName}
                        placeholder="Company Name"
                        onChange={this.onChange.bind(this, "companyName")}
                      />
                      {validations.companyNameReq ? <p className='validatorError'> {validationMsg.companyNameReq} </p> : null}
                    </div>
                  </div>

                  <div className="form-group firebaseKeywords">
                    <label className="col-sm-2 left"> Firebase Keywords</label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Firebase Keywords"
                        name="firebaseKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "firebaseKeywords"
                        )}
                        value={this.state.data.firebaseKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group gtagKeywords">
                    <label className="col-sm-2 left"> GTAG Keywords</label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="GTAG Keywords"
                        name="gtagKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "gtagKeywords"
                        )}
                        value={this.state.data.gtagKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group jobDetailKeywords">
                    <label className="col-sm-2 left">
                      {" "}
                      Job Detail Keywords
                    </label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Job Detail Keywords"
                        name="jobKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "jobKeywords"
                        )}
                        value={this.state.data.jobKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group diversityFirebaseKeywords">
                    <label className="col-sm-2 left"> Diversity Firebase Keywords</label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Diversity Firebase Keywords"
                        name="diversityFirebaseKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "diversityFirebaseKeywords"
                        )}
                        value={this.state.data.diversityFirebaseKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group diversityGtagKeywords">
                    <label className="col-sm-2 left"> Diversity GTAG Keywords</label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Diversity GTAG Keywords"
                        name="diversityGtagKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "diversityGtagKeywords"
                        )}
                        value={this.state.data.diversityGtagKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group diversityJobDetailKeywords">
                    <label className="col-sm-2 left">
                      {" "}
                      Company Code
                    </label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Company Code"
                        name="diversityJobKeywords"
                        isClearable={true}
                        isMulti={true}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "diversityJobKeywords"
                        )}
                        value={this.state.data.diversityJobKeywords}
                      />
                    </div>
                  </div>

                  <div className="form-group showcasePrice">
                    <label className="col-sm-2 left">
                      {" "}
                      Showcase Price
                    </label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Showcase Price"
                        name="showcasePrice"
                        isClearable={true}
                        isMulti={false}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "showcasePrice"
                        )}
                        value={this.state.data.showcasePrice}
                      />
                    </div>
                  </div>

                  <div className="form-group diversityPrice">
                    <label className="col-sm-2 left">
                      {" "}
                      Diversity Price
                    </label>
                    <div className="col-sm-6 left valueBox">
                      <Creatable
                        placeholder="Diversity Price"
                        name="diversityPrice"
                        isClearable={true}
                        isMulti={false}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "diversityPrice"
                        )}
                        value={this.state.data.diversityPrice}
                      />
                    </div>
                  </div>
                </div>




                <div className="actions">
                  <Button
                    skin="dark"
                    disabled={submitApiCall}
                    loading={submitApiCall}
                    text={this.state.id ? "Update" : "Submit"}
                    clickButton={this.onSubmit.bind(this)}
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>You don't have Showcase Company Access</div>
        )}
      </div>
    );
  }
}

export default ShowcaseCreate;
