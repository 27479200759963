import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user from '../../../store/user';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import Heading from '../../../components/Heading';
import Moment from 'react-moment';
import Loader from '../../../components/Loader';
import Button from "../../../components/Button";
import ButtonContainer from "../../../components/ButtonContainer";
import Dropdown from "../../../components/Dropdown/Dropdown";

import { Header, Modal } from 'semantic-ui-react';

import { Table } from "semantic-ui-react";

import mailerStatus from "../../../models/mailerStatus";

import fetchTagMailers from "../../../services/fetchTagMailers";
import fetchJobTags from '../../../services/fetchJobTags';
import executeMailer from "../../../services/executeMailer";
import getMailerAudienceCount from "../../../services/getMailerAudienceCount";
import cloneMailer from "../../../services/cloneMailer";
import getMailerStats from "../../../services/getMailerStats";
import fetchTagMailerCreators from "../../../services/fetchTagMailerCreators";
import { criteriaMap } from "../../../models/criterias";
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';


import "./list.scss";

const PAGE_CONTENT = 50;
class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            count: 0,
            pageNumber: 0,
            fetchApiCall: false,
            adminId: null,
            tagsMap: {},
            cloneApiCall: false,
            executeApiCall: false,
            refreshApiCall: false,
            viewAccess: true,
            createdBy: -1,
            createdByData: [],
        }
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        try {

            const profile = await user.getProfile();

            // const access = process.env.REACT_APP_TAG_MAILER_ACCESS_EMAILS.includes(profile.email.toLowerCase());
            // const execAccess = process.env.REACT_APP_TAG_MAILER_EXECUTE_ACCESS_EMAILS.includes(profile.email.toLowerCase());

            const access = user.checkFeatureAccess(adminFeatureMapping.TAG_MAILER_VIEW);
            const execAccess = user.checkFeatureAccess(adminFeatureMapping.TAG_MAILER_EXEC);


            this.setState({
                viewAccess: access,
                executeAccess: execAccess
            })

            const { data } = await fetchTagMailerCreators(profile.id);
            this.setState({ createdByData: [{id: -1, name: 'Select Creator' } , ...data.data] });

            const res = await fetchJobTags(profile.id);
            const tagsMap = res.data.reduce((prev, curr) => {
                prev[curr.id] = curr.name;
                return prev;
            }, {});

            this.setState({ tagsMap });
            await this.getMailers(1);

        } catch (err) {
            console.log(err);
        }
    }

    async getMailers(page) {
        const profile = await user.getProfile();

        this.setState({
            fetchApiCall: true
        })
        try {
            const params = { pageNumber: page };
            if(this.state.createdBy != -1 ) {
                params.createdBy = this.state.createdBy
            }
            const res = await fetchTagMailers(profile.id, params);

            const list = this.formatMailers(res.data.data.data);

            this.setState({
                pageNumber: page,
                list,
                count: res.data.data.count,
                fetchApiCall: false
            })

        } catch (err) {
            this.setState({ fetchApiCall: false });
        }
    }

    formatMailers(mailers) {
        return mailers.map(item => {
            item.tags = item.tags
                .split(",")
                .map(item => this.state.tagsMap[item])
                .join(", ");
            item.statusName = mailerStatus[item.status];
            return item;
        })
    }

    handlePageChange(n) {
        // console.log(n);
        this.getMailers(n);
    }

    edit(id) {
        this.props.history.push(`/tagMailers/${id}`);
    }

    async clone(id) {
        try {
            this.setState({ cloneApiCall: id });

            const profile = await user.getProfile();
            const result = await cloneMailer(profile.id, id);

            const [mailer] = this.formatMailers([result.data.data]);
            const list = [mailer, ...this.state.list];

            this.setState({
                list,
                cloneApiCall: false
            });

            toast.info("Mailer Cloned Successfully");
        } catch (err) {
            this.setState({ cloneApiCall: false });
            toast.error("Error While Cloning Mailer");
            console.log(err);

        }
    }

    async execute(id) {
        try {
            this.setState({ executeApiCall: id });
            const profile = await user.getProfile();
            const countRes = await getMailerAudienceCount(profile.id, id);
            // console.log(countRes);
            const count = countRes.data.data.totalResultSize;

            const resp = confirm(`This mail send to ${count} Jobseekers, Please confirm for final execution!!!`)

            let newState = { executeApiCall: false }
            if (resp) {
                await executeMailer(profile.id, id);
                toast.info("Mailer Execution Started Successfully");

                let list = JSON.parse(JSON.stringify(this.state.list));
                const index = list.findIndex(i => i.id === id);
                list[index].status = 6;
                list[index].statusName = mailerStatus[6];
                newState.list = list;
            }
            this.setState(newState);

        } catch (err) {
            console.log(err);
            this.setState({ executeApiCall: false });
            toast.error("Error While Executing Mailer");
        }
    }

    newMailer() {
        this.props.history.push(`/newTagMailer`);
    }

    async refreshAudience(i, id) {

        try {
            const list = [...this.state.list];
            this.setState({ refreshApiCall: id });
            const profile = await user.getProfile();
            const countRes = await getMailerAudienceCount(profile.id, id);
            // console.log(countRes);
            const count = countRes.data.data.totalResultSize;

            list[i] = { ...list[i], total_email: count };
            this.setState({
                list,
                refreshApiCall: false
            });
            toast.info("Audience Refreshed Successfully");
        } catch (err) {
            console.log(err);
            toast.error("Error in Refreshing Audience");
        }
    }

    handleModalClose() {
        this.setState({ showStats: false });
    }

    handleChange(ev) {
        this.setState({ createdBy: ev.target.value });
        this.getMailers(1);
    }

    async stats(id) {

        try {
            this.setState({ statApiCall: id });
            const profile = await user.getProfile();
            const { data } = await getMailerStats(profile.id, id);
            // console.log("here - ", data);
            this.setState({ stats: data.data.data, showStats: true, statApiCall: null });
        } catch (err) {
            console.log(err);
            this.setState({ statApiCall: null });
            toast.error("Error while loading stats");
        }

    }

    render() {
        const { list, executeApiCall, cloneApiCall, refreshApiCall, statApiCall, createdBy, createdByData } = this.state;

        return (
            <div>
                {
                    this.state.viewAccess ?
                        <div className="wrapper">

                            <ToastContainer position='bottom-left'
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover />

                            <div className="pageHeading">
                                <Heading text='Tag Based Mailers' />
                                <Button skin='dark' text="New Mailer" clickButton={() => { this.newMailer(); }} />
                            </div>
                            <hr />
                            <div className='tag-mailer-filter-container'>
                                <ButtonContainer heading='Filter-By: '>
                                    <li key='created-by'><Dropdown name='createdBy' data={createdByData} dropdownChange={this.handleChange} defaultValue={createdBy} /></li>
                                </ButtonContainer>
                            </div>
                            <div className='row text-center'>
                                <Pagination
                                    totalItemsCount={this.state.count}
                                    content={PAGE_CONTENT}
                                    pageNumber={this.state.pageNumber}
                                    handlePageChange={(n) => this.handlePageChange(n)} />
                                <CountDisplay start={(this.state.pageNumber - 1) * PAGE_CONTENT + 1} end={this.state.pageNumber * PAGE_CONTENT} total={this.state.count} />

                                {
                                    this.state.fetchApiCall ?
                                        <Loader /> :
                                        <div className="content">
                                            <Table fixed selectable className="mailers-table">
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={4} >ID</Table.HeaderCell>
                                                        <Table.HeaderCell width={4} >Date</Table.HeaderCell>
                                                        <Table.HeaderCell width={10} >Tags / Criteria</Table.HeaderCell>
                                                        <Table.HeaderCell width={10} >Subject / Applications</Table.HeaderCell>
                                                        <Table.HeaderCell width={4}>Status</Table.HeaderCell>
                                                        <Table.HeaderCell width={4}>Remarks</Table.HeaderCell>
                                                        <Table.HeaderCell width={4}>Audience</Table.HeaderCell>
                                                        <Table.HeaderCell width={12}>Actions</Table.HeaderCell>
                                                        <Table.HeaderCell width={6}>Created By</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {
                                                        list.map((item, i) => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>{item.id} <br /> {item.otherconditions && item.otherconditions.jobId ? <><b>JobId:</b> {item.otherconditions.jobId}</> : null} </Table.Cell>
                                                                    <Table.Cell><Moment format='DD-MM-YYYY hh:mm A'>{item.created_on}</Moment></Table.Cell>
                                                                    <Table.Cell>{item.tags} <br /> <br /> <b>Criteria : </b> {criteriaMap[item.mailer_type]} </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.subject}
                                                                        { item.otherconditions && item.otherconditions.jobId ?
                                                                            <>
                                                                                <br />
                                                                                <b>Application: </b>{item.applicationCount}
                                                                                <br/>
                                                                                <b>Emailer Applications: </b>{item.emailerApplicationCount}
                                                                            </>:
                                                                        null }
                                                                    </Table.Cell>
                                                                    <Table.Cell>{item.statusName}</Table.Cell>
                                                                    <Table.Cell>{item.remarks}</Table.Cell>
                                                                    <Table.Cell>{item.total_email}</Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            item.status <= 5 ?
                                                                                <Button skin='dark' text="Edit" clickButton={this.edit.bind(this, item.id)} /> : null
                                                                        }
                                                                        {
                                                                            item.status <= 5 ?
                                                                                <Button skin='dark' disabled={refreshApiCall == item.id} loading={refreshApiCall == item.id} text="Refresh Audience" clickButton={this.refreshAudience.bind(this, i, item.id)} /> : null
                                                                        }
                                                                        {
                                                                            (item.status == 5 && this.state.executeAccess) ?
                                                                                <Button skin='dark' disabled={executeApiCall == item.id} loading={executeApiCall == item.id} text="Execute" clickButton={this.execute.bind(this, item.id)} /> : null
                                                                        }
                                                                        {
                                                                            item.status == 7 ?
                                                                                <Button skin='dark' disabled={cloneApiCall == item.id} loading={cloneApiCall == item.id} text="Clone" clickButton={this.clone.bind(this, item.id)} /> : null
                                                                        }
                                                                        {
                                                                            item.status == 7 ?
                                                                                <Button skin='dark' disabled={statApiCall == item.id} loading={statApiCall == item.id} text="Stats" clickButton={this.stats.bind(this, item.id)} /> : null
                                                                        }
                                                                        {/* <Button skin='dark' text="Delete" clickButton={this.edit.bind(this, item.id)} /> */}
                                                                    </Table.Cell>
                                                                    <Table.Cell>{item.recname}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                    }
                                                </Table.Body>
                                            </Table>
                                            <Modal open={this.state.showStats} onClose={this.handleModalClose}>
                                                <Header content={"Mailer Stats"} />
                                                <Modal.Content>
                                                    {
                                                        (this.state.stats && Object.keys(this.state.stats).length) ?
                                                            <Table fixed selectable className="mailers-table">
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={5} >Type</Table.HeaderCell>
                                                                        <Table.HeaderCell width={5} >Count</Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell>Sent</Table.Cell>
                                                                        <Table.Cell>{this.state.stats.send}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Delivered</Table.Cell>
                                                                        <Table.Cell>{this.state.stats.delivery}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Bounce</Table.Cell>
                                                                        <Table.Cell>{this.state.stats.bounce}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Open</Table.Cell>
                                                                        <Table.Cell>{this.state.stats.open}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Click</Table.Cell>
                                                                        <Table.Cell>{this.state.stats.click}</Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                            : <p style={{ textAlign: "center" }}>Stats Not Found</p>
                                                    }
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button clickButton={this.handleModalClose} skin="light" text="Close">
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>
                                        </div>

                                }


                            </div>

                        </div>
                        : <div>
                            You don't have tag mailers access
                        </div>
                }
            </div>
        )
    }

}

export default List;