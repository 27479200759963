import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { USER_APPLY_JOBS } from '../../models/routes';

import './JobSeekerItem.scss';
import moment from 'moment';

export const JOBSEEKER_MGT_ACTIONS = {
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    BLACKLIST: 'blacklist',
    WHITELIST: 'whitelist',
    SHOW_RESUME: 'show-resume',
    HIDE_RESUME: 'hide-resume',
    SHOW_STAR: 'show-star',
    HIDE_STAR: 'hide-star',
    DELETE_PROFILE: 'delete'
};

export const JobSeekerItem = props => {
    const data = props.data;
    const isPhoneMasked = data.phone === '----------' ? true : false;

    const updateSeekerProfile = action => {
        if (action === JOBSEEKER_MGT_ACTIONS.ACTIVATE) {
            const resp = confirm('Are you sure to activate this jobseeker ?');
            if (resp) {
                if (process.env.REACT_APP_PLATFORM === 'hirist') {
                    props.changeSeekerStatus(action, data.id);
                } else {
                    props.updateSeeker(action, {
                        status: 1,
                        scriptStatus: 0,
                        seekerId: data.id,
                    });
                }
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.DEACTIVATE) {
            const resp = confirm('Are you sure to deactivate this jobseeker ?');
            if (resp) {
                if (process.env.REACT_APP_PLATFORM === 'hirist') {
                    props.changeSeekerStatus(action, data.id);
                } else {
                    props.updateSeeker(action, {
                        status: 0,
                        applicationStatus: 0,
                        scriptStatus: 12,
                        seekerId: data.id,
                    });
                }
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.BLACKLIST) {
            const resp = confirm('Are you sure to blacklist this jobseeker ?')
            if (resp) {
                props.updateSeeker(action, {
                    applicationStatus: 0,
                    seekerId: data.id,
                });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.WHITELIST) {
            const resp = confirm('Are you sure to remove this jobseeker from blacklist ?')
            if (resp) {
                props.updateSeeker(action, {
                    applicationStatus: 1,
                    seekerId: data.id,
                });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.SHOW_RESUME) {
            const resp = confirm('Are you sure to show resume ?');
            if (resp) {
                props.updateSeeker(action, {
                    seekerId: data.id,
                    hideResume: 0,
                    showToEmployer: 1
                });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.HIDE_RESUME) {
            const resp = confirm('Are you sure to hide resume ?')
            if (resp) {
                props.updateSeeker(action, {
                    seekerId: data.id,
                    hideResume: 1,
                    showToEmployer: 0
                });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.SHOW_STAR) {
            const resp = confirm('Are you sure to show star ?')
            if (resp) {
                props.updateSeekerSubscription({ proId: data.proId, showStar: 1 });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.HIDE_STAR) {
            const resp = confirm('Are you sure to hide star ?')
            if (resp) {
                props.updateSeekerSubscription({ proId: data.proId, showStar: 0 });
            }
        } else if (action === JOBSEEKER_MGT_ACTIONS.DELETE_PROFILE) {
            props.sendOtp().then(r => {
                const resp = prompt('Are you sure you want to delete this profile? Please enter OTP');
                if (resp) {
                    props.updateSeeker(action, {
                        seekerId: data.id,
                        deleteProfile: true,
                        otp: resp
                    });
                }    
            });
        }
    };



    let actions = null;
    if (data.email && data.email.includes('deleted_user')) {
        const emailParts = data.email.split('_');
        const date = moment(parseFloat(emailParts[2])).format('DD-MM-YYYY HH:mm:ss');

        actions = (
            <div className='danger-text'>This user has been deleted on {date}</div>
        )
    }
    else if (data.status == 0) {
        actions = (
            <>
                <span title='Click to activate' onClick={() => updateSeekerProfile('activate')}>Activate</span>
            </>
        );
    } else {
        actions = (
            <>
                <span title='Click to deactivate' onClick={() => updateSeekerProfile('deactivate')}>Deactivate</span>
                <span>
                    {process.env.REACT_APP_PLATFORM === 'updazz' ? (
                        <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/changepasswordSeekerByAdmin.php?userid=${data.id}&email=${data.email}`} target="_blank">Change Password</a>
                    ) : (
                        <a href={`/recruiter/changepasswordSeekerByAdmin?userid=${data.id}`} target="_blank">Change Password</a>
                    )}
                </span>
                
                {data.application_status ? (
                    <span title='Click to blacklist' onClick={() => updateSeekerProfile('blacklist')}>Blacklist</span>
                ) : (
                    <span title='Click to whitelist' onClick={() => updateSeekerProfile('whitelist')}>Whitelist</span>
                )}
                
                {!data.emailIsMasked && !isPhoneMasked && data.resume ? (
                    <span><a onClick={() => props.onClickViewResume(data)} target="_blank">View Resume</a></span>
                ) : null}
                {/* {!data.emailIsMasked && !isPhoneMasked && data.resume ? (
                    <span><a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/downloadresume.php?uid=${data.id}`}>Download Resume</a></span>
                ) : null} */}

                <span><a href={`${USER_APPLY_JOBS}?seekerId=${data.id}`} target='_blank'>Applied Jobs</a></span>
                {data.hideresume ? (
                    <span onClick={() => updateSeekerProfile('show-resume')}>Show Resume</span>
                ) : null}
                {!data.hideresume ? (
                    <span onClick={() => updateSeekerProfile('hide-resume')}>Hide Resume</span>
                ) : null}

                {data.isProActive && data.showStar ? (
                    <span onClick={() => updateSeekerProfile('hide-star')}>Hide Star</span>
                ) : null}
                {data.isProActive && !data.showStar ? (
                    <span onClick={() => updateSeekerProfile('show-star')}>Show Star</span>
                ) : null}

                <span>
                    <a href={`/seekerNotificationSettings?email=${data.email}`} target="_blank">Notifications Settings</a>
                </span>

                <span>
                    <a href="/manageSubscriptions" target="_blank">Early Applicant & Resume Builder</a>
                </span>

                <span>
                    <a href={`/recruiter/jobseeker_plan_active?email=${data.email}`} target="_blank">Plan Activation</a>
                </span>

                <span>
                    <a href={`/unverifieduser?user=${data.email}`} target="_blank">Verification Settings</a>
                </span>

                <span>
                    <a href="/pro-membership-invoice-cn" target="_blank">Pro membership invoice</a>
                </span>

                {
                    props.showDelete ? (
                        <span className='danger-text' onClick={() => updateSeekerProfile('delete')}>Delete Profile</span>
                    ) : null
                }



            </>
        )
    }

    return (
        <Table.Row className="seekerlist-item">
            <Table.Cell width="one" verticalAlign='top'>
                <div className="item-cell">
                    <span>{data.id}</span>
                </div>
            </Table.Cell>
            <Table.Cell width="one" verticalAlign='top'>
                <div className="item-cell">
                    <span>{data.email}</span>
                    <span>{data.phone}</span>
                    <span>Ref: {data.vsrc}</span>
                    {data.isPro ? (
                        data.isProActive ? (
                            <span><img src="https://static.hirist.com/resources/images/star_app.png" /></span>
                        ) : <div className='expired-text'>(Expired)</div>
                    ) : null}
                </div>
            </Table.Cell>
            <Table.Cell width="one" verticalAlign='top'>
                <div className="item-cell actions">
                    {actions}
                </div>
            </Table.Cell>
        </Table.Row>
    );
}

JobSeekerItem.propTypes = {
    data: PropTypes.object,
    showDelete: PropTypes.bool,
    updateSeeker: PropTypes.func,
    updateSeekerSubscription: PropTypes.func,
    onClickViewResume: PropTypes.func,
    changeSeekerStatus: PropTypes.func,
};