/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import InputBox from '../../../../components/InputBox/InputBox';
import Button from '../../../../components/Button';
import Pagination from '../../../../components/Pagination';
import CountDisplay from '../../../../components/CountDisplay';

import { Table } from 'semantic-ui-react';

import fetchShowcaseV2Plans from '../../../../services/fetchShowcaseV2Plans';
import fetchShowcaseV2PlansCount from '../../../../services/fetchShowcasev2PlansCount';
import updateShowcaseV2Plan from '../../../../services/updateShowcaseV2Plan';

import user from '../../../../store/user';

import './PlanManagementList.scss'

export const PlanManagementList = (props) => {

    const PAGE_CONTENT = 100;
    const [plans, setPlans] = useState([]);
    const [query, setQuery] = useState(null);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const seacrhQuery = queryParams.get('e_search');
        fetchCount();
    }, [])

    // useEffect(() => {
    //     fetchCount();
    // }, [count])

    useEffect(() => {
        fetchPlans();
    }, [pageNumber])

    const fetchCount = async () => {
        const profile = await user.getProfile();
        const adminId = profile.id;
        const queryParams = new URLSearchParams(window.location.search);
        const seacrhQuery = queryParams.get('e_search');
        const data = await fetchShowcaseV2PlansCount(adminId, { recruiterId: seacrhQuery });
        setCount(data.data.data.count)
    }

    const fetchPlans = async () => {
        const profile = await user.getProfile();
        const adminId = profile.id;
        const queryParams = new URLSearchParams(window.location.search);
        const seacrhQuery = queryParams.get('e_search');
        const { data } = await fetchShowcaseV2Plans(adminId, { pageNumber, PAGE_CONTENT, recruiterId: seacrhQuery });
        setPlans(data.data.data);
    }

    const onClickSearch = (ev) => {
        if (!query) return;
        window.location.href = `/showcasePlanList?e_search=${query}`
        // props.history.push(`showcasePlanList?e_search=${query}`);
        // setPageNumber(1);
        // setCount(1);
        // fetchCount();
        // fetchPlans();
    }

    const handleChange = (ev) => {
        setQuery(ev.target.value)
    }

    const handleKeyPress = (ev) => {
        if (ev.key == 'Enter') {
            onClickSearch();
        }
    }

    const handlePageChange = (num) => {
        setPageNumber(num);
    }

    const getConfirmation = async (ev, status, id) => {
        ev.preventDefault();
        const result = window.confirm(`Are you sure you want to ${status === 1 ? 'Activate' : 'Deactivate'} Plan?`);
        if (!result) {
            return result;
        }
        const profile = await user.getProfile();
        const adminID = profile.id;
        await updateShowcaseV2Plan(adminID, { status, autoId: id });
        window.location.reload(false);
        return result
    }

    return (
        <div className='showcase-plan-page-wrapper'>
            <div className='showcase-plan-page-header'>
                <div className='rightnav'>
                    <InputBox
                        type="number"
                        name="e_search"
                        value={query}
                        className="seach-input"
                        placeholder="Search Recruiter Id"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                    />
                    <Button
                        text="search"
                        className="search-btn btn"
                        skin='light'
                        clickButton={onClickSearch}
                    />
                </div>
                <div className='leftnav'>
                    <div className='page-title'>
                        <span className='txt20 strong'>Showcasev2 Plan Management</span>
                    </div>
                    <div className='action-buttons'>
                        <Button
                            text="Reload"
                            className="reload-btn btn"
                            skin='light'
                            // eslint-disable-next-line no-return-assign
                            clickButton={() => window.location.href = '/showcasePlanList'}
                        />
                        <Button
                            text="Add Plan"
                            className="add-plan-btn btn"
                            skin='light'
                            clickButton={() => { props.history.push('/showcasePlanAdd') }}
                        />
                        <Button
                            className='older-version-btn'
                            text='Go to Older Version'
                            skin='light'
                            // eslint-disable-next-line no-return-assign, no-undef
                            clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/showcasePlanList.php`}
                        />
                    </div>
                </div>
            </div>
            <div className='row text-center'>
                <Pagination
                    totalItemsCount={count}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={(num) => handlePageChange(num)} />
                <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={(pageNumber - 1) * PAGE_CONTENT + plans.length} total={count} />
            </div>
            <div className='showcase-plan-list'>
                <Table fixed selectable striped className='plan-list-table' >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10} >Recruiter Details</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Company Details</Table.HeaderCell>
                            <Table.HeaderCell width={5} >Domain</Table.HeaderCell>
                            <Table.HeaderCell width={2} >Url</Table.HeaderCell>
                            <Table.HeaderCell width={3} >Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            plans.map((plan, i) => {
                                return (
                                    <Table.Row >
                                        <Table.Cell>{`${plan.recruiterId} - ${plan.recName}`} <br /> {plan.email}</Table.Cell>
                                        <Table.Cell>{`${plan.companyId} - ${plan.companyName}`}</Table.Cell>
                                        <Table.Cell>{plan.domain}</Table.Cell>
                                        <Table.Cell>{plan.url}</Table.Cell>
                                        <Table.Cell>
                                            {plan.status === 1 ?
                                                <div>
                                                    <a className='edit-showcase-plan' href={`/showcasePlanAdd?id=${plan.id}`} >Edit Plan</a>
                                                    <br />
                                                    <span className='turn-activate' onClick={(ev) => getConfirmation(ev, 0, plan.id)} >Deactivate</span>
                                                </div> :
                                                <span className='turn-activate' onClick={(ev) => getConfirmation(ev, 1, plan.id)} >Activate</span>}
                                        </Table.Cell>
                                    </Table.Row>)
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
};
