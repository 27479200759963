import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Table } from 'semantic-ui-react';

const TableItem = props => {
    const date = moment(props.data.date).format('MMM DD, YYYY');

    let itemContent = null;

    if (props.type === 'unicorn' || props.type === 'premium') {
        itemContent = (
            <Table.Row>
                <Table.Cell width='one' verticalAlign='top'>{date}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.jobs_posted}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.published_jobs_posted}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.applications}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.active_companies}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.new_recruiter_reg}</Table.Cell>
            </Table.Row>
        );
    } else if (props.type === 'unicorn_cummulative' || props.type === 'premium_cummulative') {
        itemContent = (
            <Table.Row>
                <Table.Cell width='one' verticalAlign='top'>{props.type === 'unicorn_cummulative' ? 'Unicorn' : 'Premium'}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.new_companies_reg}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.new_recruiter_reg}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.active_companies_six}</Table.Cell>
                <Table.Cell width='one' verticalAlign='top'>{props.data.active_companies_three}</Table.Cell>
            </Table.Row>
        );
    }
    
    return itemContent;
};

TableItem.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string
};

export default TableItem;