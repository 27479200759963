import axios from '../utils/axios';

export default function fetchJobTags(adminId, params = {}) {
    return (
        axios.get('/recruiter/'+adminId+'/jobTags', {
            params
        })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
