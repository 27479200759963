import React from "react";
import Dropdown from "../../../components/Dropdown";
import InputBox from "../../../components/InputBoxWithoutCheck";
import Button from "../../../components/Button";
import PillButton from "../../../components/PillButton";
import Select, { Creatable } from 'react-select';
import Heading from '../../../components/Heading';
import Loader from '../../../components/Loader';
import MediumEditor from "../../../components/MediumEditor";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var scrollToElement = require('scroll-to-element');

import user from '../../../store/user';

import { getCategory } from '../../../models/categories';
import { getOriginalFunctionalArea, getMappedFunctionalArea, getFunctionalArea } from '../../../models/functionalAreasSeeker';
import { getInstitutesName } from '../../../models/institutesSeeker';
import { getDesignations } from "../../../models/designations";
import { minSalary, maxSalary } from '../../../models/salary';
import { minExperience, maxExperience } from '../../../models/experience';
import { minYear, maxYear } from '../../../models/year';
import { locationListLabel as locationList, seekerLocationMapping } from '../../../models/locationSeeker';
import { industryLabel as industry } from '../../../models/industrySeeker';
import Criteria, { criteriaDesc } from "../../../models/criterias";
import footer from "../../../models/footer";

import fetchJobTags from '../../../services/fetchJobTags';
import fetchTagMailer from "../../../services/fetchTagMailer";
import createMailer from "../../../services/createMailer";
import updateMailer from "../../../services/updateMailer";
import executeMailerText from "../../../services/executeMailerTest";
import getSingleJob from "../../../services/getSingleJob";
import getMailerAudienceCount from '../../../services/getMailerAudienceCount';

import validator from 'validator';
import getTagMailerContent from "../../../models/tagMailerContent";

import "./create.scss";
import '../../../themes/grid.scss';
import replacer, { cleanIntroText } from "../../../utils/replacer";
import striptags from "striptags";
import { adminFeatureMapping } from "../../../models/adminFeatureMapping";

const tagsCutOff = 3;
const Emailer_Types = [
    {
        name: "Select Type",
        id: 0
    },
    {
        name: "Job",
        id: 1
    },
    {
        name: "Other",
        id: 2
    },
    {
        name: "External",
        id: 3
    }
];

const Gender = [
    {
        name: "All",
        id: 0
    },
    {
        name: "Male",
        id: 1
    },
    {
        name: "Female",
        id: 2
    }
]

const maxLimit = {
    // industry: 5
}

const validationMsg = {
    typeReq: "Type is Required",
    contentReq: "Content is Required",
    jobIdReq: "Job Id is Required",
    subjectReq: "Subject is Required",
    testEmailReq: "Test Emails are requried",
    tagsReq: `Minimum %cutOff% Tags Are Required for selected criteria`,
    designationsReq: "Designations Are Required for selected criteria",
    criteriaReq: "Criteria is Required",
    expMinMax: "Maximum years of experience should be greater than minimum years of experience.",
    salMinMax: "Maximum salary should be greater than minimum salary.",
    batchMinMax: "Maximum graduation year should be greater than minimum graduation year.",
    salutationReq: "Salutation is Required, If you include this",
    cutOffReq: "CutOff is required for selected criteria",
}

const validationClass = {
    typeReq: ".type",
    contentReq: ".content",
    jobIdReq: ".jobId",
    subjectReq: ".subject",
    testEmailReq: ".testEmails",
    tagsReq: ".tags",
    designationsReq: ".designations",
    criteriaReq: ".criteria",
    expMinMax: ".exp",
    salMinMax: ".sal",
    batchMinMax: ".batch",
    salutationReq: ".salutation",
    cutOffReq: ".cutoff"
}

const categoryObj = getCategory();
const functionalArea = getFunctionalArea(1);
const institutes = getInstitutesName();
const tagsReqFormailerTypes = ["1", "2", "4", "5", "6"];
const desigReqFormailerTypes = ["3", "7", "8", "9", "10", "11"];
const reactSelectKeys = ["tags", "designations", "industry", "institutes", "location"];
const NAME_STRING = "%recipient.name%";

class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            adminId: null,
            data: {
                tags: [],
                // exp: {},
                // sal: {},
                // batch: {},
                industry: [],
                location: [],
                institutes: [],
                designations: [],
                salutation: "Hi",
                dontInclude: "",
                testEmail: "",
                subject: "",
                content: "",
                description: "",
                cutOff: 2,
                viewAccess: false
            },
            open: {},
            validations: {},
            jobTags: [],
            designations: getDesignations(),
            submitApiCall: false,
            submitApiCallTest: false,
            isFetchingJobData: false,
            audienceCount: null,
        };
        this.reactTags = React.createRef()
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    async componentDidMount() {
        const profile = await user.getProfile();
        const adminId = profile.id;
        const type = profile.type;
        const { id } = this.props.match.params;

        // const viewAccess = process.env.REACT_APP_TAG_MAILER_ACCESS_EMAILS.includes(profile.email.toLowerCase());
        const viewAccess = user.checkFeatureAccess(adminFeatureMapping.TAG_MAILER_VIEW);
        this.setState({
            adminId,
            viewAccess
        })

        // fetch tag mailer audience count 
        if (id) {
            getMailerAudienceCount(adminId, id).then(async (response) => {
                this.setState({ audienceCount: response.data.data.totalResultSize });
            }).catch(err => {
                this.setState({ audienceCount: 0 });
            });
        } else {
            this.setState({ audienceCount: 0 });
        }

        fetchJobTags(adminId).then(async (resp) => {
            const jobTags = resp.data.map((aTag, i) => {
                return {
                    label: aTag.name,
                    value: aTag.id.toString()
                }
            });
            this.setState({ jobTags });

            if (id) {
                const res = await fetchTagMailer(adminId, id);
                if (res.data.data) {
                    this.setData(res.data.data, id, jobTags);
                }
            } else {
                let data = { ...this.state.data };
                this.setState({ data });
            }

        })
    }

    setData(resp, id, jobTags) {
        const { mailerType, salutation, content, footer, subject, remarks, tags, otherconditions, testEmail, gender, cut_off } = resp;

        let desc = content;
        desc = (striptags(desc, '<p>,<a>,<b>,<u>,<i>,<br>,<br/>,<br />,<button>', ''));
        desc = replacer(cleanIntroText(desc));

        let data = {
            mailerType: mailerType.toString(),
            content: desc,
            description: desc,
            cutOff: cut_off,
            subject,
            footer,
            remarks,
            gender,
            testEmail,
            tags: tags.split(","),
            ...otherconditions
        }

        data.functionalArea = getOriginalFunctionalArea(data.functionalArea);

        data.dontInclude = !salutation;
        data.salutation = salutation.split(NAME_STRING)[0] || "Hi";
        data = {
            ...data,
            tags: jobTags.filter(t => data.tags.includes(t.value)),
            designations: data.designations.map(i => { return { label: i, value: i } }),
            industry: industry.filter(i => data.industry.includes(i.value)),
            institutes: institutes.filter(i => data.institutes.includes(i.value)),
            location: locationList.filter(l => data.location.includes(l.value))
        };
        this.setState({ data, id });
    }

    async onRefreshAudience(event, mailertype) {

        if (!this.validate(false)) {
            return
        }
        
        this.setState({ audienceCount: null });
        const data = this.state.data;
        const params = { 
            checkOnly: true,
            type: data.type,
            jobId: data.jobId,
            location: data.location,
            industry: data.industry,
            institutes: data.institutes,
            functionalArea: data.functionalArea,
            designations: data.designations,
            mailerType: mailertype || data.mailerType,
            tags: data.tags,
            cutOff: data.cutOff,
            minExp: data.minExp,
            maxExp: data.maxExp,
            minBatch: data.minBatch,
            maxBatch: data.maxBatch,
            gender: data.gender,
        }

        reactSelectKeys.forEach(key => {
            params[key] = params[key].map(i => i.value);
        })
        
        try {
            const response = await getMailerAudienceCount(this.state.adminId, this.state.id, params)
            this.setState({ audienceCount: response.data.data.totalResultSize });
        }catch(error) {
            this.setState({ audienceCount: 0 });
        }
    }

    async setJobData(refreshCount = false) {
        this.setState({ isFetchingJobData: true });
        let { tags, minExp, maxExp, minBatch, maxBatch, location, subject, content } = { ...this.state.data }
        const jobId = this.state.data.jobId;

        if (jobId){
            const response = await getSingleJob(this.state.adminId, jobId, {
                idType: 'publish'
            });

            if (response.data && response.data.length > 0){
                
                const respData = response.data[0];

                tags = this.state.jobTags.filter(t => respData.tags.includes(t.label));

                if (respData.exp) {
                    minExp = respData.exp.min;
                    maxExp = respData.exp.max;
                }

                // removing this from auto fill temporarily untill elacticsearch
                // api gets fixed for batch parameters
                // if (respData.batch) {
                //     minBatch = respData.batch.min;
                //     maxBatch = respData.batch.max;
                // }

                const mappedLocation = seekerLocationMapping(respData.location);
                
                location = locationList.filter(l => mappedLocation.includes(l.value));
                const orgName = respData.organisation || "";

                const jobUrl = respData.url ? `https://www.${process.env.REACT_APP_PLATFORM}.com/j${respData.url}?ref=tm` : "";
                subject = respData.cnfi == 1 ? respData.title : `${orgName} is hiring for: ${respData.title}`;

                let desc = respData.description;
                desc = (striptags(desc, '<p>,<a>,<b>,<u>,<i>,<br>,<br/>,<br />,<button>', ''));
                desc = replacer(cleanIntroText(desc));

                content = getTagMailerContent(respData.title, jobUrl, desc);

            }
            this.setState({ data: { ...this.state.data, tags, location, subject, content, description: content, minExp, maxExp, minBatch, maxBatch } });
        }

        if (refreshCount) {
            this.onRefreshAudience();
        }

        this.setState({ isFetchingJobData: false });
    }

    onKeyPress(key, event) {
        if (key === 'jobId' && event.key === 'Enter') {
            event.preventDefault();
            this.setJobData(true);
        }
    }

    onBlur(key, event) {

        // When user moves away from the job id input field, fetch the job details and 
        // auto fill the required fields in the form alongwith subject and content
        if(key === 'jobId') {
            this.setJobData()
        }
    }

    onMediumEditorChange(text, _) {
        let data = { ...this.state.data };
        data.description = (striptags(text, '<p>,<a>,<b>,<u>,<i>,<br>,<br/>,<br />,<button>', '\n'));
        this.setState({ data });
    }

    onChange(key, ev) {
        let data = { ...this.state.data };
        data[key] = ev.target.value;
        if (ev.target.type === 'checkbox') {
            data[key] = ev.target.checked;
        }
        if (key === 'type' && ev.target.value!=1) {
            data.jobId = null;
        }
        this.setState({
            data
        });

        if (key === 'mailerType') {
            this.onRefreshAudience(null, ev.target.value);
        }

        if (key === 'type' && ev.target.value == 1) {
            if (this.state.data.jobId) {
                this.setJobData()
            }
        }
    }

    handlePillChange = (event, pillData) => {
        // console.log("HERRE", event, pillData);
        const data = { ...this.state.data };
        const open = { ...this.state.open };

        // console.log("HERRE", maxLimit[pillData.name] - 1, " ", pillData);
        if (maxLimit[pillData.name] && maxLimit[pillData.name] - 1 == data[pillData.name].length) {
            open[pillData.name] = false;
            this.setState({ open });
            return;
        }

        data[pillData.name] = pillData.value.filter(i => i);
        open[pillData.name] = true;

        this.setState({
            data,
            open
        });
    }

    handleMinMaxChange = (event) => {
        const name = event.target.name;
        const data = { ...this.state.data, [name]: event.target.value };
        // data[name[0]] = {
        //     ...data[name[0]],
        //     [name[1]]: event.target.value
        // }
        this.setState({
            data
        })
    }

    onClose = (event, data) => {
        const open = { ...this.state.open };
        open[data.name] = false;
        this.setState({
            open
        })
    }

    // onAddItem = (e, { value }) => {
    //     this.setState(prevState => ({
    //         designations: [{ text: value, value }, ...prevState.designations],
    //     }))
    // }

    onClick = (event, data) => {
        const open = { ...this.state.open };
        open[data.name] = true;
        if (maxLimit[data.name] && maxLimit[data.name] - 1 == this.state.data[data.name].length) {
            open[data.name] = false;
        }
        this.setState({
            open
        })
    }

    validate(isSubmit = true) {
        const { data } = this.state;

        // console.log(tagsReqFormailerTypes.includes(data.mailerType), this.checkEmpty(data.tags), data.mailerType);
        let validations = {
            typeReq: this.checkEmpty(data.type),
            jobIdReq: data.type == 1 && this.checkEmpty(data.jobId),
            criteriaReq: this.checkEmpty(data.mailerType),
            tagsReq: tagsReqFormailerTypes.includes(data.mailerType) && (data.tags.length < +data.cutOff),
            cutOffReq: tagsReqFormailerTypes.includes(data.mailerType) && +data.cutOff < 1,
            designationsReq: desigReqFormailerTypes.includes(data.mailerType) && this.checkEmpty(data.designations),
            expMinMax: parseInt(data.minExp) > parseInt(data.maxExp),
            salMinMax: parseInt(data.minSal) > parseInt(data.maxSal),
            batchMinMax: parseInt(data.minBatch) > parseInt(data.maxBatch),
        }

        if (isSubmit) {
            validations = {
                ...validations,
                testEmailReq: this.checkEmpty(data.testEmail.trim()),
                subjectReq: this.checkEmpty(data.subject.trim()),
                contentReq: this.checkEmpty(striptags(data.description.trim(), '', '')),
                salutationReq: !data.dontInclude && this.checkEmpty(data.salutation.trim())
            }
        }

        // console.log()
        this.setState({ validations });
        for (let key in validations) {
            if (validations[key]) {
                // console.log
                scrollToElement(validationClass[key]);
                return false;
            }
        }
        return true;
    }

    checkEmpty(val) {
        if (!val)
            return true;
        if (Array.isArray(val) && !val.length)
            return true;
        if (typeof val == "string" && validator.isEmpty(val))
            return true;
        return false;
    }

    async onSubmit(withTest, e) {
        const { id } = this.state;
        const data = JSON.parse(JSON.stringify(this.state.data));
        reactSelectKeys.forEach(key => {
            data[key] = data[key].map(i => i.value);
        })
        if (data.dontInclude) {
            data.salutation = "";
        } else {
            data.salutation = data.salutation.trim() + " " + NAME_STRING + ",<br><br>";
        }
        data.cutOff = Number(data.cutOff) || 0;
        data.functionalArea = getMappedFunctionalArea(data.functionalArea);
        
        data.content = replacer(data.description);
        delete data.description;
        
        if (!data.maxBatch) {
            delete data.maxBatch;
        }
        if (!data.minBatch) {
            delete data.minBatch;
        }

        e.preventDefault();
        if (this.validate()) {

            if (withTest) {
                this.setState({ submitApiCallTest: true });
            } else {
                this.setState({ submitApiCall: true });
            }

            const profile = await user.getProfile();
            const adminId = profile.id;

            let testId = id;

            try {
 
                if (id) {
                    await updateMailer(adminId, id, data);
                } else {
                    const resp = await createMailer(adminId, data);
                    // console.log(resp);
                    testId = resp.data.data.id;
                }

                if (withTest) {
                    await executeMailerText(adminId, testId);
                }

                if (id) {
                    toast.info('Mailer Updated Successfully');
                } else {
                    toast.info('Mailer Created Successfully');
                }

                this.setState({ submitApiCall: false, submitApiCallTest: false });

                setTimeout(() => {
                    this.props.history.push("/tagMailers");
                }, 100)

            } catch (err) {
                console.log(err);
                this.setState({ submitApiCall: false, submitApiCallTest: false });
                if (id) {
                    toast.error('Error while updating mailer');
                } else {
                    toast.error('Error while creating mailer');
                }
            }

        }
    }

    mailerList() {
        this.props.history.push(`/tagMailers`);
    }

    handleSelectChange(key, selectedOptions) {
        const data = { ...this.state.data };
        data[key] = selectedOptions;
        this.setState({ data });
    }

    render() {
        const { validations, submitApiCall, submitApiCallTest, viewAccess } = this.state;

        return (
            <div>
                {
                    viewAccess ?
                        <div className="wrapper">
                            <ToastContainer position='bottom-left'
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover />

                            <div className="pageHeading">
                                <Heading text='Tag Based Mailer' />
                                <Button skin='dark' text="Mailer List" clickButton={() => { this.mailerList(); }} />
                            </div>
                            <hr />
                            <div className="create-tag-mailer">

                                <form>
                                    <div className="row section basic-section">
                                        <p className="label-heading"> Basic Information </p>
                                        <div className="form-group type">
                                            <label className="col-sm-2 left">Type *</label>
                                            <div className="col-sm-6 left valueBox">
                                                <Dropdown
                                                    dropdownChange={this.onChange.bind(this, "type")}
                                                    defaultValue={this.state.data.type}
                                                    data={Emailer_Types} />
                                                {validations.typeReq ? <p className='validatorError'> {validationMsg.typeReq} </p> : null}
                                            </div>
                                        </div>

                                        {
                                            this.state.data.type == 1 ?
                                                <div className="form-group jobId">
                                                    <label className="col-sm-2 left">Job Id *</label>
                                                    <div className="col-sm-6 left valueBox">
                                                        <InputBox
                                                            type='number'
                                                            value={this.state.data.jobId}
                                                            placeholder='Job ID'
                                                            min={0}
                                                            onKeyPress={(e) => {
                                                                if (!['+', '-', 'e', 'E'].includes(e.key)) {
                                                                    this.onKeyPress.bind(this, 'jobId')
                                                                }else {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={this.onChange.bind(this, "jobId")}
                                                            onBlur={this.onBlur.bind(this, 'jobId')} />
                                                        {validations.jobIdReq ? <p className='validatorError'> {validationMsg.jobIdReq} </p> : null}

                                                    </div>
                                                </div> : null
                                        }
                                    </div>

                                    {
                                        this.state.isFetchingJobData ? (
                                            <div className="form__loader">
                                                <Loader />
                                            </div>
                                        ) : null
                                    }

                                    <div className="row section targeting-section">
                                        <p className="label-heading"> Targeting Criteria for users </p>

                                        <div className="form-group criteria">
                                            <label className="col-sm-2 left"> Criteria *</label>
                                            <div className="col-sm-6 left valueBox">
                                                <Dropdown
                                                    dropdownChange={this.onChange.bind(this, "mailerType")}
                                                    defaultValue={this.state.data.mailerType}
                                                    data={Criteria} />
                                                {validations.criteriaReq ? <p className='validatorError'> {validationMsg.criteriaReq} </p> : null}
                                                {
                                                    this.state.data.mailerType && criteriaDesc[this.state.data.mailerType] ?
                                                        <div className="info">
                                                            {criteriaDesc[this.state.data.mailerType]}
                                                        </div> : null
                                                }

                                                <div className="audience__count">
                                                    <span className="audience__count--label">Audience: </span>
                                                    <span className="audience__count--count">{this.state.audienceCount==null ? 'Calculating...' : this.state.audienceCount}</span> | 
                                                    <span className="audience__count--refresh" onClick={this.onRefreshAudience.bind(this)}>Refresh Count</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group cutoff">
                                            <label className="col-sm-2 left"> Cutoff </label>
                                            <div className="col-sm-6 left valueBox">
                                                <div className="block-dropdown">
                                                    <InputBox
                                                        type='number'
                                                        value={this.state.data.cutOff}
                                                        placeholder='Cut Off'
                                                        onChange={this.onChange.bind(this, "cutOff")} />
                                                </div>
                                                <p className='cutoff-info'>Cut off criteria will be used to match minimum number of tags necessarily. for example, if 2 is selected, then minimum 2 tags must match with the job seekers tags.</p>
                                                {validations.cutOffReq ? <p className='validatorError'> {validationMsg.cutOffReq} </p> : null}
                                            </div>
                                        </div>

                                        <div className="form-group tags">
                                            <label className="col-sm-2 left"> Tags</label>
                                            <div className="col-sm-6 left valueBox">

                                                <Select
                                                    options={this.state.jobTags}
                                                    placeholder='Tags'
                                                    name='tags'
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    onChange={this.handleSelectChange.bind(this, "tags")}
                                                    value={this.state.data.tags}
                                                />
                                                {validations.tagsReq ? <p className='validatorError'> {validationMsg.tagsReq.replace('%cutOff%', this.state.data.cutOff)} </p> : null}

                                            </div>
                                        </div>
                                        {
                                            process.env.REACT_APP_PLATFORM != 'iimjobs' ?
                                                <div className="form-group designations">
                                                    <label className="col-sm-2 left"> Designations</label>
                                                    <div className="col-sm-6 left valueBox">
                                                        <Creatable
                                                            options={this.state.designations}
                                                            placeholder='Designations'
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            onChange={this.handleSelectChange.bind(this, 'designations')}
                                                            value={this.state.data.designations}
                                                        />
                                                        {validations.designationsReq ? <p className='validatorError'> {validationMsg.designationsReq} </p> : null}

                                                    </div>
                                                </div> : null
                                        }
                                        <div className='form-group exp'>
                                            <label className='col-sm-2 left'>Yrs of exp</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <div className='block-dropdown'>
                                                    <Dropdown dropdownChange={this.handleMinMaxChange} name='minExp' defaultValue={this.state.data.minExp} data={minExperience} />
                                                    {/* {this.state.formValidation.exp.min ? <p className='validatorError'>Please select min exp. </p> : null} */}
                                                </div>
                                                <div className='block-dropdown right-dropdown'>
                                                    <Dropdown dropdownChange={this.handleMinMaxChange} defaultValue={this.state.data.maxExp} name='maxExp' data={maxExperience} />
                                                    {/* {this.state.formValidation.exp.max ? <p className='validatorError'>Please select max exp. </p> : null} */}
                                                </div>
                                                {validations.expMinMax ? <p className='validatorError'> {validationMsg.expMinMax} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorExp ? <p className='validatorError'> Maximum years of experience should be greater than minimum years of experience. </p> : null} */}
                                            </div>
                                        </div>


                                        {
                                            process.env.REACT_APP_PLATFORM == 'iimjobs' ? (
                                                <div className='form-group industry'>
                                                    <label className='col-sm-2 left'>Industry</label>
                                                    <div className='col-sm-6 left valueBox'>
                                                        <Select
                                                            isMulti
                                                            isClearable
                                                            isSearchable
                                                            options={industry}
                                                            value={this.state.data.industry}
                                                            placeholder='Industry'
                                                            onChange={this.handleSelectChange.bind(this, "industry")}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null
                                        }

                                        <div className='form-group functionalArea'>
                                            <label className='col-sm-2 left'>Functional Areas</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <Dropdown name='functionalArea' dropdownChange={this.onChange.bind(this, "functionalArea")} defaultValue={this.state.data.functionalArea} data={functionalArea} />
                                                {/* {this.state.formValidation.functionalArea ? <p className='validatorError'>Please select Functional Area </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group location'>
                                            <label className='col-sm-2 left'>Location</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <Select
                                                    isMulti
                                                    isClearable
                                                    isSearchable
                                                    options={locationList}
                                                    isOptionDisabled={(option) => option.disabled == true}
                                                    value={this.state.data.location}
                                                    placeholder='Location'
                                                    onChange={this.handleSelectChange.bind(this, "location")}
                                                />
                                                {/* {this.state.formValidation.location ? <p className='validatorError'> Please Enter Location </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group institutes'>
                                            <label className='col-sm-2 left'>Institutes</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <Select
                                                    isMulti
                                                    isClearable
                                                    isSearchable
                                                    options={institutes}
                                                    value={this.state.data.institutes}
                                                    placeholder='Institutes'
                                                    onChange={this.handleSelectChange.bind(this, "institutes")} />

                                                {/* {this.state.formValidation.location ? <p className='validatorError'> Please Enter Location </p> : null} */}
                                            </div>
                                        </div>

                                        {
                                            process.env.REACT_APP_PLATFORM == 'iimjobs' ? (
                                                <div className='form-group sal'>
                                                    <label className='col-sm-2 left'>Salary</label>
                                                    <div className='col-sm-6 left valueBox'>
                                                        <div className='block-dropdown-container'>
                                                            <Dropdown className='block-dropdown' data={minSalary} name='minSal' defaultValue={this.state.data.minSal} dropdownChange={this.handleMinMaxChange} />
                                                            <Dropdown className='block-dropdown right-dropdown' data={maxSalary} name='maxSal' defaultValue={this.state.data.maxSal} dropdownChange={this.handleMinMaxChange} />
                                                            <span>in lakhs per annum</span>
                                                        </div>
                                                        {validations.salMinMax ? <p className='validatorError'> {validationMsg.salMinMax} </p> : null}
                                                        {/* {this.state.formValidation.greaterErrorSal ? <p className='validatorError'> Maximum salary should be greater than minimum salary. </p> : null} */}
                                                    </div>
                                                </div>
                                            ) : null
                                        }

                                        <div className='form-group batch'>
                                            <label className='col-sm-2 left'>Batch</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <Dropdown className='block-dropdown' data={minYear} name='minBatch' defaultValue={this.state.data.minBatch} dropdownChange={this.handleMinMaxChange} />
                                                <Dropdown className='block-dropdown right-dropdown' data={maxYear} defaultValue={this.state.data.maxBatch} name='maxBatch' dropdownChange={this.handleMinMaxChange} />
                                                {validations.batchMinMax ? <p className='validatorError'> {validationMsg.batchMinMax} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group gender'>
                                            <label className='col-sm-2 left'>Gender</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <Dropdown className='block-dropdown' data={Gender} name='gender' defaultValue={this.state.data.gender} dropdownChange={this.onChange.bind(this, "gender")} />
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row section email-section">
                                        <p className="label-heading"> Email Content</p>

                                        <div className='form-group testEmails'>
                                            <label className='col-sm-2 left'>Test Emails *</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <InputBox
                                                    type='text'
                                                    value={this.state.data.testEmail}
                                                    placeholder='Test Emails'
                                                    onChange={this.onChange.bind(this, "testEmail")} />
                                                {validations.testEmailReq ? <p className='validatorError'> {validationMsg.testEmailReq} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group subject'>
                                            <label className='col-sm-2 left'>Subject *</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <InputBox
                                                    type='text'
                                                    value={this.state.data.subject}
                                                    placeholder='Subject'
                                                    onChange={this.onChange.bind(this, "subject")} />
                                                {validations.subjectReq ? <p className='validatorError'> {validationMsg.subjectReq} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group remarks'>
                                            <label className='col-sm-2 left'>Remarks</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <InputBox
                                                    type='text'
                                                    value={this.state.data.remarks}
                                                    placeholder='Remarks'
                                                    onChange={this.onChange.bind(this, "remarks")} />
                                                {validations.remarksReq ? <p className='validatorError'> {validationMsg.remarksReq} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group content'>
                                            <label className='col-sm-2 left'>Content *</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <div>
                                                    <div className='block-dropdown salutation left'>
                                                        <InputBox
                                                            disabled={this.state.data.dontInclude}
                                                            value={this.state.data.salutation}
                                                            onChange={this.onChange.bind(this, "salutation")} />
                                                        {validations.salutationReq ? <p className='validatorError'> {validationMsg.salutationReq} </p> : null}
                                                    </div>
                                                    <div className='block-dropdown right-dropdown'>
                                                        <InputBox
                                                            value={NAME_STRING}
                                                            disabled={true} />
                                                    </div>
                                                    <div className="dont-include-section left">
                                                        <InputBox
                                                            id='dont-include'
                                                            type="checkbox"
                                                            value={this.state.data.dontInclude}
                                                            checked={this.state.data.dontInclude}
                                                            onChange={this.onChange.bind(this, "dontInclude")} />
                                                        <label for='dont-include'>Don't include</label>
                                                    </div>
                                                    <br />
                                                    <br />

                                                </div>
                                                <MediumEditor name="mediumEditor" value={this.state.data.content} onChange={this.onMediumEditorChange.bind(this)} />
                                                
                                                {validations.contentReq ? <p className='validatorError'> {validationMsg.contentReq} </p> : null}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>

                                        <div className='form-group footer'>
                                            <label className='col-sm-2 left'>Footer *</label>
                                            <div className='col-sm-6 left valueBox'>
                                                <textarea
                                                    disabled={true}
                                                    value={footer}
                                                    placeholder='Footer'>
                                                </textarea>
                                                {/* {validations.contentReq ? <p className='validatorError'> {validationMsg.contentReq} </p> : null} */}
                                                {/* {this.state.formValidation.greaterErrorBatch ? <p className='validatorError'> Maximum graduation year should be greater than minimum graduation year. </p> : null} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <Button skin='dark' disabled={submitApiCall} loading={submitApiCall} text={this.state.id ? "Update" : "Submit"} clickButton={this.onSubmit.bind(this, false)} />
                                        <Button skin='dark' disabled={submitApiCallTest} loading={submitApiCallTest} text={this.state.id ? "Update + Test" : "Submit + Test"} clickButton={this.onSubmit.bind(this, true)} />
                                    </div>
                                </form>
                            </div>
                        </div>
                        : 
                        <div>
                            You don't have Tag Mailer Access
                        </div>
                }
            </div>
        )
    }

}

export default Create;