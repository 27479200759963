import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import fetchPremiumPostingAdoption from '../../services/fetchPremiumPostingAdoption';

import user from '../../store/user'

import './PremiumPostingAdoption.scss'

const PremiumPostingAdoption = () => {

    const [adminId, setAdminId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [premiumPostingData, setpremiumPostingData] = useState([]);

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();
            setAdminId(profile.id);
        })()
    }, []);

    useEffect(() => {
        (async () => {
            if (adminId) {
                setIsLoading(true)
                const data = await fetchPremiumPostingAdoptionData();
                setpremiumPostingData(data);
                setIsLoading(false);
            }
        })();
    }, [adminId]);

    const fetchPremiumPostingAdoptionData = async () => {
        const { data } = await fetchPremiumPostingAdoption(adminId, {});
        return data;
    } 
    return (
        <div className='premium-posting-adoption-container'>
            <h2 className='premium-posting-adoption-list heading'>
                Premium Posting Adoption
            </h2>
            <div className='premium-posting-adoption-table'>
                {isLoading ?
                    <Loader /> :
                    <Table
                        fixed
                        selectable
                        className="premium-posting-adoption-list"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={4}>
                                    Month/Year
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Total Activations
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Current Activations
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Used atleast 1 credit
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    % Adoption (PP)
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Change from last week
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Published at least 1 job
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    % Adoption (Any)
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Posted at least 1 job
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    % Adoption (Job Posted)
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Current Available Credits
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Used Credits
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {premiumPostingData.map(aData => {
                                return(
                                    <Table.Row key={aData.period}>
                                        <Table.Cell>
                                            {aData.period}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.totalActivation}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.currentActivation}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.atLeastOneUsedCredit}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {Math.round(aData.adoptionRate*100)}%
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.lastWeekChange}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.publishCount}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {Math.round(aData.publishRate * 100)}%
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.postCount}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {Math.round(aData.postRate * 100)}%
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.availableCredits}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {aData.usedCredits}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>

                }
            </div>
        </div>
    )
}

export default PremiumPostingAdoption