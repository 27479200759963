import axios from '../../utils/axios';

export default function addLGBill(adminId, data) {
    return (
        axios.post(`/admin/${adminId}/lGBill`,  data )
        // .then(res => {
        //     return res.data
        // })
        // .catch(err => {
        //     return err;
        // })
    )
}
