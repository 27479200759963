import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const EditJob = Loadable({
  loader: () => import('../Container'),
  loading: Loading,
});

const EditJobContainer = () => (
    <EditJob />
)

EditJobContainer.propTypes = {
};

export default EditJobContainer;
