/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import user from '../../store/user';
import fetchAllJobs from '../../services/fetchAllJobs';
import './PremiumJobList.scss'
import { Checkbox, Comment, Form, Header, Modal, Table, TextArea } from 'semantic-ui-react';
import { getCategoryMap } from '../../models/categories';
import { getFunctionalAreaMap } from '../../models/functionalAreas';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import fetchJobsCount from '../../services/fetchJobsCount';
import ButtonContainer from '../../components/ButtonContainer';
import Dropdown from '../../components/Dropdown/Dropdown';
import { branding } from '../../models/branding';
import FromToDatePicker from '../../components/FromToDatepicker';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import findCandidate from '../../services/findCandidate';
import fetchAllJobComments from '../../services/fetchJobComments';
import postJobComment from '../../services/postJobComment';
import fetchJobRefreshHistory from '../../services/fetchJobRefreshHistory';
import assignJob from '../../services/assignJobs';
import fetchAssignees from '../../services/fetchAssignees';
import fetchJobAssignees from '../../services/fetchJobAssignees';
import { toast, ToastContainer } from 'react-toastify';
import fetchPaidJobsCount from '../../services/fetchPaidJobsCount';
import fetchPaidJobs from '../../services/fetchPaidJobs';
import fetchJobPremiumData from '../../services/fetchJobPremiumData';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';
import fetchCompanyDetails from '../../services/fetchCompanyDetails';
import addToJD from '../../services/addToJD';
import { getIndustryListFromId } from '../../models/newIndustry';
import { getCompanyTypeFromId } from '../../models/companyType';
import { getFundingStageFromId } from '../../models/fundingStage';
import { getHeadquarterCityFromId } from '../../models/headquarterCity';
import { getNatureOfOfferingsFromId } from '../../models/natureOfOfferings';
import { getNoOfEmpFromId } from '../../models/numberOfEmployees';

const categoryMap = getCategoryMap();
const functionalAreaMap = getFunctionalAreaMap();

const minApplicationDropdown = [
    { id: -1, name: 'Min Application' },
    { id: 0, name: '0' },
    { id: 11, name: '11' },
    { id: 21, name: '21' },
    { id: 31, name: '31' },
    { id: 41, name: '41' },
    { id: 51, name: '51' },
    { id: 81, name: '81' },
    { id: 101, name: '> 100' },
];
const maxApplicationDropdown = [
    { id: -1, name: 'Max Application' },
    { id: 0, name: '0' },
    { id: 10, name: '10' },
    { id: 20, name: '20' },
    { id: 30, name: '30' },
    { id: 40, name: '40' },
    { id: 50, name: '50' },
    { id: 80, name: '80' },
    { id: 100, name: '100' },
    { id: 150, name: '150' },
    { id: 200, name: '200' },
    { id: 250, name: '250' },
    { id: 300, name: '300' },
    { id: 350, name: '350' },
    { id: 400, name: '400' },
];

const premiumStatusDropdown = [
    { id: 'premiumPublished', name: 'Paid' },
    { id: 'freePublished', name: 'Free' }

];
const jobUniverseDropdown = [
    { id: '', name: 'Select' },
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' }
]

const locationDropdown = [
    { id: '', name: 'Select'},
    { id: 'bangalore', name: 'Bangalore'}
]

const filtersInitialState = {
    minApplicationRecieved: -1,
    maxApplicationRecieved: -1,
    branded: -1,
    postStartDate: '',
    postEndDate: '',
    assignedTo: -1,
    jobUniverse: '',
    premiumStatus: 'premiumPublished',
    location: ''
};

const hideCompanyReasonMap = {
    1: "This is a confidential job. Company doesn't wish to disclose",
    2: 'We are hiring for multiple companies',
}

const PremiumJobList = () => {
    const [adminId, setAdminId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    const [btnLoader, setBtnLoader] =  useState(false);
    const [premiumJobs, setPremiumJobs] = useState([]);
    const [jobTotalCount, setJobTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchData, setSearchData] = useState({});
    const PAGE_CONTENT = 50;

    const [filters, setFilters] = useState(filtersInitialState);
    const [jobIdToSearch, setJobIdToSearch] = useState(null);
    const [hasAccess, setHasAccess] = useState(true);

    const [comment, setComment] = useState('');
    const [commentData, setCommentData] = useState({});
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [jobIdForCommentModal, setJobIdForCommentModal] = useState(null);
    const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);
    const [jobIdForCompDetailsModal, setJobIdForCompDetailsModal] = useState(null);

    const [jobRefreshHistoryData, setJobRefreshHistoryData] = useState({});

    const [checkedIds, setCheckedIds] = useState([]);
    const [assignTo, setAssignTo] = useState(-1);
    const [assignees, setAssignees] = useState([]);
    const [assigneeData, setAssigneeData] = useState({});
    const [premiumHistoryData, setPremiumHistoryData] = useState({});
    const [companyDetails, setCompanyDetails] = useState({});

    const [oldPaidJobs, setOldPaidJobs] = useState(false);

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();
            setAdminId(profile.id);

            // check if given profile is authorised to view the page
            const premiumListAccess = user.checkFeatureAccess(adminFeatureMapping.PREMIUM_JOBS_LIST);
            const jobListViewAccess = user.checkFeatureAccess(adminFeatureMapping.JOBS_LIST);
            if (!premiumListAccess && !jobListViewAccess) {
                setHasAccess(false);
            }
            //fetch assginees
            const { data } = await fetchAssignees(profile.id);
            setAssignees([{ name: 'Select', id: -1 }, ...data.data ]);
        })();

        // set initial value
        // let initialPostStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD'); 
        // let initialPostEndDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        // initialPostStartDate = new Date(initialPostStartDate);
        // initialPostEndDate = new Date(initialPostEndDate);
        setFilters({ ...filters, minApplicationRecieved: 0, maxApplicationRecieved: 20, postStartDate: '', postEndDate: '' });

    }, []);

    useEffect(() => {
        (async () => {
            if (adminId) {
                const count = await fetchPremiumJobsCount();
                setJobTotalCount(count);
                setIsLoading(true);
                const jobs = await fetchPremiumJobs(1);
                setPremiumJobs(jobs);
                await loadOtherData(jobs);
                setIsLoading(false);
            }
        })();
    }, [adminId, filters]);

    useEffect(() => {
        if (oldPaidJobs) {
            setFilters({
                ...filters,
                minApplicationRecieved: -1,
                maxApplicationRecieved: -1,
                branded: -1,
                postStartDate: '',
                postEndDate: '',
                assignedTo: -1,
            });
        } else {
            // let initialPostStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
            // let initialPostEndDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            // initialPostStartDate = new Date(initialPostStartDate);
            // initialPostEndDate = new Date(initialPostEndDate);
            setFilters({ ...filters, minApplicationRecieved: 0, maxApplicationRecieved: 20, postStartDate: '', postEndDate: '' });
        }
    }, [oldPaidJobs]);

    const fetchPremiumJobs = async (page) => {
        let params = getParameters();
        params.pageNumber = page;
        params.pageContent = PAGE_CONTENT;
        if (oldPaidJobs) {
            const { data } = await fetchPaidJobs(adminId, params);
            if (!data.length)  return [];
            params = {};
            params.jobId = data.join(',');
            params.format = 'brief';
        }
        params.withCompanyDetails = 1;
        const { data } = await fetchAllJobs(adminId, params);
        return data;
    };

    const fetchPremiumJobsCount = async () => {
        const params = getParameters();
        params.noCache = 1;
        if (oldPaidJobs) {
            const { data } = await fetchPaidJobsCount(adminId, params);
            return data;
        }
        const { data } = await fetchJobsCount(adminId, params);
        return data;
    };

    const fetchComments = async (jobIdArray) => {
        const { data } = await fetchAllJobComments(adminId, { jobIdArray: jobIdArray.join(',') });
        return data;
    }

    const fetchJobHistory = async (jobIdArray) => {
        const { data } = await fetchJobRefreshHistory(adminId, { jobIdArray: jobIdArray.join(',') });
        return data;
    }

    const fetchAssignee =  async (jobIdArray) => {
        const { data } = await fetchJobAssignees(adminId, { jobIdArray: jobIdArray.join(',') });
        return data;
    }

    const fetchPremiumData = async (metaIdArray) => {
        const { data } = await fetchJobPremiumData(adminId, { jobIdArray: metaIdArray.join(',') });
        return data;
    }

    const fetchJobCompanyDetails = async (jobId) => {
        const { data } = await fetchCompanyDetails(adminId, jobId);
        return data.data;
    }

    const handlePageChange = async (page) => {
        setPageNumber(page);
        setIsLoading(true);
        const jobs = await fetchPremiumJobs(page);
        setPremiumJobs(jobs);
        await loadOtherData(jobs);
        setIsLoading(false);
    };

    const handleFilterChange = (ev) => {
        const oldFilters = {...filters};

        if(ev.target.name == 'location' && ev.target.value == '') {
            oldFilters['premiumStatus'] = 'premiumPublished'
        }
        setFilters({ ...oldFilters, [ev.target.name]: ev.target.value });
    };

    const searchJob = async () => {
        if (jobIdToSearch) {
            setIsLoading(true);
            const params = { publishId: jobIdToSearch, format: 'brief', withCompanyDetails: 1 };
            if (!oldPaidJobs) {
                params['type'] = 'premiumPublished';
            }
            const { data } = await fetchAllJobs(adminId, params);
            setPremiumJobs(data);
            setJobTotalCount(1);
            await loadOtherData(data);
            setIsLoading(false);
        }
    };

    const loadOtherData = async (jobs) => {
        const jobIdArray = jobs.map(item => item.publishId);
        const metaIdArray = jobs.map(item => item.id)
        const [comments, refreshHistoryData, assigneesData, premiumHistory ] = await Promise.all([
            fetchComments(jobIdArray),
            fetchJobHistory(jobIdArray),
            fetchAssignee(jobIdArray),
            fetchPremiumData(metaIdArray),
        ],);
        setCommentData(comments);
        setJobRefreshHistoryData(refreshHistoryData);
        setAssigneeData(assigneesData);
        setPremiumHistoryData(premiumHistory)
        setCheckedIds([]);
    };

    const clearJobSearch = async (e) =>  {
        if (jobIdToSearch) {
            setJobIdToSearch('');
            // search for job
            const count = await fetchPremiumJobsCount();
            setJobTotalCount(count);
            setIsLoading(true);
            const jobs = await fetchPremiumJobs(1);
            setPremiumJobs(jobs);
            await loadOtherData(jobs);
            setIsLoading(false);
        }
    };
    const handleSearchChange = (jobId, ev) => {
        const newVal = ev.target.value;
        setSearchData(prevData => { return { ...prevData, [jobId]: { ...prevData[jobId], value: newVal } }});
    };

    const searchCandidate = async (jobId, ev) => {
        ev.preventDefault();
        if (!searchData[jobId] || !searchData[jobId].value) {
            setSearchData(prevData => { return { ...prevData, [jobId]: { ...prevData[jobId], result: 'Please enter email or phone' } }})
        } else {
            const value = searchData[jobId].value;
            const { data } = await findCandidate(adminId, jobId, { searchValue: searchData[jobId].value });
            setSearchData(prevData => { return { ...prevData, [jobId]: { ...prevData[jobId], result: data.message } }})
        }
    }

    const getParameters = () => {
        const params = {
            order: 'desc',
            format: 'brief',
        };

        if (filters.minApplicationRecieved != -1) {
            params.applyCountGreaterThan = filters.minApplicationRecieved;
        }
        if (filters.maxApplicationRecieved != -1) {
            params.applyCountLessThan = filters.maxApplicationRecieved;
        }
        if (filters.branded != -1) {
            params.premiumCompany = filters.branded;
        }
        if (filters.postStartDate) {
            const startDate = new Date(filters.postStartDate);
            startDate.setHours(5,30,0);
            params.publishFrom = startDate;
        }
        if (filters.postEndDate) {
            const endDate = new Date(filters.postEndDate);
            endDate.setUTCHours(23, 59, 59);
            params.publishBy = endDate;
        }
        if (filters.assignedTo != -1) {
            params.assignedTo = filters.assignedTo;
        }
        if(filters.premiumStatus != '') {
            params.type =  filters.premiumStatus
        }
        if(filters.jobUniverse != ''){
            params.fromUniverse = filters.jobUniverse
        }
        if(filters.location != '') {
            params.location = filters.location
        }
        return params;
    }

    const openCommentModal = (jobId) => {
        setShowCommentModal(true);
        setJobIdForCommentModal(jobId);
    };

    const closeCommentModal = () => {
        setShowCommentModal(false);
        setJobIdForCommentModal(null);
    };

    const addComment = async () => {
        if(!jobIdForCommentModal) return; // ideally in this situation there should not be modal open
        const { data } = await postJobComment(adminId, jobIdForCommentModal, { comment: comment });
        setCommentData({ ...commentData, [jobIdForCommentModal]: data });
        setComment('');
    };

    const openCompanyDetailsModal = async (jobId) => {
        setShowCompanyDetailsModal(true);
        setJobIdForCompDetailsModal(jobId);
        setModalLoader(true);
        try {
            const companyData = await fetchJobCompanyDetails(jobId);
            const { companies } = companyData.data;
            setModalLoader(false)
            let companyObj = {};
            if (companies.length) { 
                companyObj = {
                    name: companies[0].displayName,
                    industries: companies[0].industries.map(id => getIndustryListFromId()[id]),
                    companyType: getCompanyTypeFromId()[companies[0].companyType],
                    fundingStage: getFundingStageFromId()[companies[0].fundingStage],
                    headquarterCity: getHeadquarterCityFromId()[companies[0].headquaterCity],
                    natureOfOffering: getNatureOfOfferingsFromId()[companies[0].natureOfOffering],
                    foundingYear: companies[0].companyFounded,
                    noOfEmployees: getNoOfEmpFromId()[companies[0].noOfEmployees],
                    showComp: companyData.showComp,
                    reasonId: companyData.reasonId,
                    reasonMsg: companyData.reasonMsg
                }
            }      
            setCompanyDetails(companyObj);
        } catch (error) {
            toast.error(error.response.data.message || 'Something Went Wrong');
            closeCompanyDetailsModal();
        }
       
    };

    const closeCompanyDetailsModal = () => {
        setShowCompanyDetailsModal(false);
        setJobIdForCompDetailsModal(null);
        setCompanyDetails({});
        setModalLoader(false);
    };

    const addCompDetailsToJD = async () => {
        if (jobIdForCompDetailsModal) {
            setBtnLoader(true);
            try {
                const res = await addToJD(adminId, jobIdForCompDetailsModal, companyDetails);
                toast.info(res.data.message);     
            } catch (error) {
                toast.error(error.response.data.message || 'Something Went Wrong');            
            }
            setBtnLoader(false);
            closeCompanyDetailsModal();
        }
    };

    const downloadExcel = async () => {
        let params = getParameters();
        params.pageNumber = 1;
        params.pageContent = jobTotalCount;
        if (oldPaidJobs) {
            const { data } = await fetchPaidJobs(adminId, params);
            if (!data.length)  return [];
            params = {};
            params.jobId = data.join(',');
            params.format = 'brief';
            params.pageNumber = 1;
            params.pageContent = jobTotalCount;
        }
        params.withCompanyDetails = 1;
        params.downloadExcel = 1;
        const data = await fetchAllJobs(adminId, params);

        // down load logic
        const outputFilename = `${moment().format('YYYY-MM-DD hh:mm:ss')}.csv`;
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        return ;
    };

    const onChangeCheckBox = (ev, data) => {
        if (data.name === 'selectAll') {
            if(checkedIds.length) {
                setCheckedIds([]);
            } else {
                const jobIdArray = premiumJobs.map(item => item.publishId);
                setCheckedIds(jobIdArray);
            }
            return;
        }
        if (checkedIds.includes(data.id)) {
            const newIds = checkedIds.filter(id => id !== data.id);
            setCheckedIds(newIds);
        } else  {
            setCheckedIds([...checkedIds, data.id]);
        }
    };

    const onAssign = async () => {
        if (!assignTo || !checkedIds) {
            toast.error('Select Jobs and Person to assign');
        }
        try {
            const assignData = assignees.filter(item => item.id == assignTo)
            await assignJob(adminId, { assignTo: assignData[0], jobIds: checkedIds });
            const jobIdArray = premiumJobs.map(item => item.publishId);
            const result = await fetchAssignee(jobIdArray);
            setAssigneeData(result);
            setAssignTo(-1);
            setCheckedIds([]);
            toast.info('Assigned Successfully');
        } catch (error) {
            toast.error(error.message || 'Something Went Wrong');
        }
        
    }

    const isConfidentialJob = (jobId) => {
        for (const job of premiumJobs) {
            if (job.id == jobId && job.cnfi == 1) {
                return true;
            }
        } 
        return false;
    }

    if (!hasAccess) {
        return (
            <div> You don't have access to this page </div>
        )
    }

    return (
        <div className='premium-job-list-main-container'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
            <h2 className='premium-job-list-heading'>
                {oldPaidJobs ? 'All Paid Jobs' : 'Current Paid Jobs'}
            </h2>
            <div className='filter-container'>
                <ButtonContainer heading='Filter By: '>
                    <li key='minApplicationRecieved'><label>Min Applications</label> <Dropdown name="minApplicationRecieved" data={minApplicationDropdown} dropdownChange={handleFilterChange} defaultValue={filters.minApplicationRecieved} /> </li>
                    <li key='maxApplicationRecieved'><label>Max Applications</label> <Dropdown name="maxApplicationRecieved" data={maxApplicationDropdown} dropdownChange={handleFilterChange} defaultValue={filters.maxApplicationRecieved} /> </li>
                    <li key='companyRank'><label>Branding</label> <Dropdown name="branded" data={branding} dropdownChange={handleFilterChange} defaultValue={filters.branded} /> </li>
                    <li key="postDate">
                        <label>Post Start & End Date</label>
                        <FromToDatePicker
                            name="date"
                            startDate={filters.postStartDate}
                            endDate={filters.postEndDate}
                            handleChangeStart={(e) => setFilters({ ...filters, postStartDate: e })}
                            handleChangeEnd={(e) => setFilters({ ...filters, postEndDate: new Date(new Date(e).setHours(23, 59 ,59)) })}
                        />
                    </li>
                    <li key="assignedTo"><label>Assigned To</label><Dropdown name="assignedTo" data={assignees} dropdownChange={handleFilterChange} defaultValue={filters.assignedTo} /></li>
                    {
                        filters.location != '' ? (
                                <li key="premiumStatus"><label>Premium Status</label><Dropdown name="premiumStatus" data={premiumStatusDropdown} dropdownChange={handleFilterChange} defaultValue={filters.premiumStatus} /></li>
                        ) : null
                    }
                    <li key="jobUniverse"><label>Job Universe</label><Dropdown name="jobUniverse" data={jobUniverseDropdown} dropdownChange={handleFilterChange} defaultValue={filters.jobUniverse} /></li>
                    <li key="location"><label>Job Location</label><Dropdown name="location" data={locationDropdown} dropdownChange={handleFilterChange} defaultValue={filters.location} /></li>
                </ButtonContainer>
                <Button skin="light" text="Clear Filter" clickButton={() => setFilters(filtersInitialState)} />
            </div>
            <div className='job-search-field'>
                <label>Search By Job Id: </label>
                <InputBox placeholder="Enter Job Id" type="number" name="jobIdToSearch" value={jobIdToSearch} onChange={(e) => setJobIdToSearch(e.target.value)} />
                <Button skin="dark" text="Search" clickButton={searchJob}/>
                <Button skin="light" text="Clear" clickButton={clearJobSearch} />
                <div className='right-aligned'>
                    <Button skin="light" text={oldPaidJobs ? 'Current Paid Jobs' : 'All Paid Jobs'} clickButton={() => setOldPaidJobs(!oldPaidJobs)} />
                    <Button skin="dark" text="Download Excel" clickButton={downloadExcel} />
                </div>

            </div>
            <div className='premium-job-assign-container'>
                { checkedIds.length ? 
                    <>
                        <label>Select to Assign: </label>
                        <Dropdown data={assignees} defaultValue={assignTo} dropdownChange={(ev) => setAssignTo(ev.target.value)} />
                        <Button skin="dark" text="Assign" disabled={assignTo == -1} clickButton={onAssign}/>
                    </> 
                    : null }
            </div>
            <div className='text-center'>
                <div className='left'>
                    <Checkbox name="selectAll" onChange={onChangeCheckBox} checked={checkedIds.length===(jobTotalCount < (pageNumber * PAGE_CONTENT) ? jobTotalCount : (pageNumber * PAGE_CONTENT)) - ((pageNumber - 1) * PAGE_CONTENT)} label="Select All"/>
                </div>
                <Pagination
                    totalItemsCount={jobTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
                <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={jobTotalCount < (pageNumber * PAGE_CONTENT) ? jobTotalCount : (pageNumber * PAGE_CONTENT)} total={jobTotalCount} />
            </div>

            {isLoading ?
                <Loader /> :
                <Table
                    fixed
                    selectable
                    className="job-list"
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={6} >
                                Job Id
                            </Table.HeaderCell>
                            <Table.HeaderCell width={10} >
                                Title
                            </Table.HeaderCell>
                            <Table.HeaderCell width={7} >
                                Recruiter Name / Email
                            </Table.HeaderCell>
                            <Table.HeaderCell width={10} >
                                Details
                            </Table.HeaderCell>
                            <Table.HeaderCell width={4} >
                                Views / Applied
                            </Table.HeaderCell>
                            <Table.HeaderCell width={6} >
                                Post Date / Premium Details
                            </Table.HeaderCell>
                            <Table.HeaderCell width={8} >
                                Company Details / Refresh Status
                            </Table.HeaderCell>
                            <Table.HeaderCell width={4} >
                                URL
                            </Table.HeaderCell>
                            <Table.HeaderCell width={6} >
                                Search candidate (Email / Mobile)
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {premiumJobs.map((job) => {
                            return (
                                <Table.Row key={job.publishId}>
                                    <Table.Cell>
                                        <Checkbox id={job.publishId} onChange={onChangeCheckBox} checked={checkedIds.includes(job.publishId)} />
                                        {' '}
                                        {job.publishId}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a href={`/applications?jobId=${job.publishId}`}>{job.title}</a>
                                        <br/>
                                        <a onClick={() => openCommentModal(job.publishId)}>Comments({commentData[job.publishId].length})</a>
                                        <br/>
                                        <strong>Assigned to: </strong>{assigneeData[job.publishId] ? assigneeData[job.publishId]['admin_name']  : 'None'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {job.recruiterName}
                                        <br/>
                                        {job.email}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            <>
                                                <strong>Category</strong> - {categoryMap[job.category]} <br />
                                                <strong> Functional Area - </strong> {functionalAreaMap[job.functionalArea]} <br />
                                                <strong> Tags - </strong>{job.tags.join(', ')} <br />
                                                {/* Industry - {job.industry.join(', ')} <br/>  */}
                                                <strong> Location - </strong>{job.location.join(', ')} <br />
                                                <strong> Salary - </strong>{job.sal.min} - {job.sal.max} LPA<br />
                                                <strong> Exp -  </strong>{job.exp.min} - {job.exp.max} years
                                            </>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {job.views} / {' '}
                                        <a href={`/applications?jobId=${job.publishId}`}>{job.totalApplications}</a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(job.created).format('DD-MM-YYYY')}
                                        <br/>
                                        {`Last Premium On : ${premiumHistoryData[job.id] ? moment(premiumHistoryData[job.id].lastPremium).format('YYYY-MM-DD') : ''}`}
                                        <br />
                                        {`No Of Times Premium : ${ premiumHistoryData[job.id] ? premiumHistoryData[job.id].noOfTimesPremium : ''}`}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            <>
                                                <strong> Company Name: </strong> {job.companyDetails.companyName}<br />
                                                <strong> Type: </strong>{job.companyDetails.recruiterType == 1 ? 'Direct Employer' : 'Recruitment Firm'} <br />
                                                <strong>  Branded: </strong>{job.companyDetails.isBranded ? 'Yes' : 'No'}
                                                {job.companyDetails.recruiterType != 1 ?
                                                    <><br /><Button skin="dark" text="See Details" clickButton={() => openCompanyDetailsModal(job.id)} /></>
                                                     : null}
                                                <br/><br/>
                                                <strong>Refreshed: </strong>{job.refreshStatus ? 'Yes' : 'No'}
                                                {jobRefreshHistoryData[job.publishId] ?
                                                    <>
                                                        <br/>
                                                        <strong>No of times refreshed: </strong>{jobRefreshHistoryData[job.publishId].noOfTimeRefreshed}
                                                        <br/>
                                                        <strong>Last Refrehed on: </strong>{moment(jobRefreshHistoryData[job.publishId].lastRefreshedOn).format('YYYY-MM-DD')}
                                                    </>
                                                 : null}
                                            </>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a target="blank" href={`${process.env.REACT_APP_JOBSEEKER_BASE_URL}/j${job.url}?ref=AT_${adminId}`}>Job Link</a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <InputBox className="candidate-search" defaultValue={(searchData[job.publishId] || {}).value || ''} onChange={(ev) => handleSearchChange(job.publishId, ev)}  />
                                        <Button skin="dark" text="Search" clickButton={(ev) => {searchCandidate(job.publishId, ev)}} />
                                        {searchData[job.publishId] &&searchData[job.publishId].result &&  <><br/><strong>Status: </strong> {searchData[job.publishId].result} </>}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            }
            <div className='text-center'>
                <Pagination
                    totalItemsCount={jobTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
            </div>
            <Modal className='premium-jobs-comment-modal' open={showCommentModal} onClose={closeCommentModal} closeIcon>
                <Header content={`Comments for Job Id - ${jobIdForCommentModal}`}/>
                <Modal.Content>
                    <div>
                        {jobIdForCommentModal && commentData[jobIdForCommentModal].length ?
                            <div className='premium-job-comment-container'>
                                <Comment.Group>
                                    {commentData[jobIdForCommentModal].map(cmnt => 
                                        <Comment key={cmnt.id} className='premium-job-comment'>
                                            <Comment.Avatar src='' alt={cmnt.admin_name.charAt(0)} />
                                            <Comment.Content>
                                                <Comment.Author as='a'>{cmnt.admin_name}</Comment.Author>
                                                <Comment.Metadata>
                                                    <div>{moment(cmnt.created_at).format('YYYY-MM-DD hh:mm:ss')}</div>
                                                </Comment.Metadata>
                                                <Comment.Text>{cmnt.comment}</Comment.Text>
                                                {/* <Comment.Actions>
                                                    <Comment.Action>Reply</Comment.Action>
                                                </Comment.Actions> */}
                                            </Comment.Content>
                                        </Comment>
                                    )}
                                </Comment.Group>                         
                            </div>
                            :
                            <h2>No Comments!</h2>
                        }
                    </div>
                    <Form>
                        <TextArea placeholder="Add your comment (max 1000 characters)" value={comment} onChange={(ev) => setComment(ev.target.value)}/>
                        <Button className="premium-jobs-add-comment-btn" skin="light" text="Comment" clickButton={addComment}  />
                    </Form>
                </Modal.Content>
            </Modal>
            <Modal className='premium-jobs-recruiter-firm-company-details-modal' open={showCompanyDetailsModal} onClose={closeCompanyDetailsModal} closeIcon>
                <Header content={'Company Details'}/>
                <Modal.Content>
                    {modalLoader ?
                        <Loader /> :
                        <div className='company-details-modal-container'>
                            <ul>
                                <li><strong className='field-label'>Company Name :</strong>{companyDetails.name}</li>
                                <br/>
                                <li><strong className='field-label'>Industries :</strong>{companyDetails.industries? companyDetails.industries.join(', ') : null}</li>
                                <br/>
                                <li><strong className='field-label'>Company Type :</strong>{companyDetails.companyType}</li>
                                <br/>
                                <li><strong className='field-label'>Funding Stage :</strong>{companyDetails.fundingStage}</li>
                                <br/>
                                <li><strong className='field-label'>Headquarter Location :</strong>{companyDetails.headquarterCity}</li>
                                <br/>
                                <li><strong className='field-label'>Nature of offering :</strong>{companyDetails.natureOfOffering}</li>
                                <br/>
                                <li><strong className='field-label'>Founding Year :</strong>{companyDetails.foundingYear}</li>
                                <br/>
                                <li><strong className='field-label'>No of Employees :</strong>{companyDetails.noOfEmployees}</li>
                                <br/>    
                            </ul>
                            {isConfidentialJob(jobIdForCompDetailsModal) && <>Note: This Job is confidential and hence compnay details can't be added<br/></>}
                            {companyDetails.showComp == 0 && <>Recruiter has request to hide company details. Reason - {companyDetails.reasonId == 3? companyDetails.reasonMsg : hideCompanyReasonMap[companyDetails.reasonId]}</>}
                            <div className='modal-action-section'>
                                <Button skin="dark" text="Add to JD" clickButton={addCompDetailsToJD} loading={btnLoader} disabled={ isConfidentialJob(jobIdForCompDetailsModal) || companyDetails.showComp == 0 ||btnLoader} />
                            </div>
                        </div>
                    }
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default PremiumJobList