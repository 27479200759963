import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../../../components/Spinner';

import './styles.scss';

const CustomDropdown = props => {
    const [showList, setShowList] = useState(false);
    const [selected, setSelected] = useState(props.isSelected ? props.isSelected.displayName : null);
    const inputRef = useRef(null);
    const listRef = useRef(null);

    const handleInputChange = event => {
        props.onInputChange(event.target.value);
    };

    const handleOnFocus = () => {
        setShowList(true);
    };

    const handleOnBlur = () => {
        setShowList(false);
        props.clearList();

        if (inputRef && inputRef.current) {
            if (selected) {
                inputRef.current.value = selected;
            } else if(props.isSelected) {
                inputRef.current.value = props.isSelected.displayName;
            }
        }
    };

    const handleChangeSelected = () => {
        // props.clearSelected();
        setSelected('');
    };

    let dropdownItems = null;

    if (props.dropdownList && props.dropdownList.length > 0) {
        dropdownItems = props.dropdownList.map(item => {
            return (
                <div
                    key={item.value} 
                    className="dropdown-list-item"
                    onMouseDown={(event) => {
                        if (inputRef && inputRef.current) {
                            inputRef.current.value = item.label;
                        }
    
                        // store selected company data inside respective recruiter object
                        props.onItemSelected(item.value, item.label);
                        event.preventDefault();
                        setSelected(item.label);
                        handleOnBlur();
                    }}
                >
                    {item.label}
                </div>
            );
        });
    } else if (props.noResultFound) {
        dropdownItems = (
            <div
                className="dropdown-list-item">
                No companies found
            </div>
        );
    }

    return (
        <div className={`dropdown-wrapper ${props.className ? props.className : null}`}>
            <div className="dropdown-content">
                {!selected ? (
                    <>
                        <input
                            ref={inputRef}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            onFocus={handleOnFocus}
                            placeholder={props.placeholder}
                        />

                        <div 
                            className={`dropdown-list ${showList ? 'open' : 'close'}`}
                            ref={listRef}   
                        >
                            {
                                props.isLoading ? (
                                    <div className="dropdown-loader">
                                        <Spinner />
                                    </div>
                                ) : dropdownItems
                            }
                        </div>
                    </>
                ) : null}

                {selected ? (
                    <div className='selected-company-actions'>
                        <div className='company-name'>{selected}</div>
                        <div className='btn-change' onClick={handleChangeSelected}>Change</div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

CustomDropdown.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isSelected: PropTypes.any,
    value: PropTypes.string,
    onInputChange: PropTypes.func,
    isLoading: PropTypes.bool,
    allLoaded: PropTypes.bool,
    dropdownList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    onItemSelected: PropTypes.func,
    clearList: PropTypes.func,
    clearSelected: PropTypes.func,
    noResultFound: PropTypes.bool
};

export default CustomDropdown;
