import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';

import getVerificationDocument from '../../../../services/getVerificationDocument';

import './styles.scss';

const DisplayDocumentModal = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [imgLink, setImgLink] = useState(null);
    const [isPdf, setIsPdf] = useState(false);

    useEffect(() => {
        const getDocument = async () => {
            const data = await getVerificationDocument(props.adminId, props.documentName);
            return data;
        }

        try {
            const parts = props.documentName.split('.');
            if (parts[1] === 'pdf') {
                setIsPdf(true);
            }
        } catch (error) {
            // console.log(error);
        }

        getDocument().then(data => {
            setIsLoading(false);
            setImgLink(data.data.data.url);
        }).catch(error => {
            // console.log(error);
            setIsLoading(false);
            setImgLink(null);
        })
    }, []);

    return (
        <Modal className="display-document-modal" open={props.openModal} onClose={props.onCloseModal}>
            <Modal.Header>Uploaded Document</Modal.Header>
            <Modal.Content>
                {isLoading ? (<Loader />) : null}
                {!isLoading && imgLink ? (
                    <div className="document-image">
                        {isPdf ? (
                            // <iframe style={{ width: '100%', height: '300px' }} src={imgLink}></iframe>
                            <>File is a PDF. <a href={imgLink} download target="_blank">Click to download.</a></>
                        ) : (
                            <img alt='document' src={imgLink} />
                        )}
                    </div>
                ) : null}
                {!isLoading && !imgLink ? (
                    <div>
                        Something went wrong! Please try again later
                    </div>
                ) : null}
            </Modal.Content>
            <Modal.Actions>
                <Button text="Close" clickButton={props.onCloseModal} skin="dark" />
            </Modal.Actions>
        </Modal>
    )
};

DisplayDocumentModal.propTypes = {
    openModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    adminId: PropTypes.string,
    documentName: PropTypes.string,
    recruiterId: PropTypes.string
};

export default DisplayDocumentModal;