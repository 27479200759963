import React, { useState } from 'react';

import './competitionMailerModal.scss';
import { Header, Modal, Table } from 'semantic-ui-react';
import moment from 'moment';
import Button from '../../../../components/Button';
import { EMAIL_TYPES } from '../../constants';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { toast } from 'react-toastify';



const CompetitionMailerModal = (props) => {


    const [isEmailSending, setIsEmailSending] = useState(null);


    const onSendEmail = async (e, emailType) => {

        try {

            setIsEmailSending(emailType);

            await props.sendEntireEmail();;

            setIsEmailSending(null);

        }

        catch (e) {
            console.log(e)
            toast.error("Something went wrong")
            setIsEmailSending(null);

        }

    }

    return (
        <Modal
            className="competition-mailer-modal"
            open={props.open}
            onClose={props.onClose}
            size="large"
        >
            <>
                <Header content='Bulk Mailer' />
                <Modal.Content>
                    {
                        <div>

                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell width={3}>
                                        Reminders Before Start
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Round Start Emailers
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Submission Reminders
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Result
                                    </Table.HeaderCell>

                                </Table.Header>

                                <Table.Body>

                                    {
                                        [1, 2, 3, 4].map((item, ind) => (
                                            <Table.Cell key={ind}>
                                                <p >

                                                    <Dropdown
                                                        name='email'
                                                        data={EMAIL_TYPES[item]}
                                                        dropdownChange={(e) => props.setSelectedEmail(e.target.value)}
                                                        defaultValue={props.selectedEmail}
                                                    />
                                                </p>
                                                <Button
                                                    clickButton={(e) => onSendEmail(e, item)}
                                                    skin="light"
                                                    text="Send Mail"
                                                    loading={isEmailSending == item}
                                                    disabled={!props.selectedEmail}
                                                />
                                            </Table.Cell>
                                        ))
                                    }

                                </Table.Body>
                            </Table>

                        </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        clickButton={props.onClose}
                        skin="light"
                        text="Close"
                    />
                </Modal.Actions>
            </>
        </Modal >
    )
}

export default CompetitionMailerModal