/**
*
* SearchFlaggedUsers
*
*/
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user from '../../store/user';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Heading from '../../components/Heading';
import Loader from '../../components/Loader';

import Input from "../../components/InputBox";
import Button from "../../components/Button";

import { Table } from "semantic-ui-react";

import getSearchFlaggedUsers from "../../services/getSearchFlaggedUsers";
import updateSearchFlaggedUser from "../../services/updateSearchFlaggedUser";

import "./styles.scss";
import Moment from "react-moment";

const PAGE_CONTENT = 50;
const STATUS = {
    1: 'Flagged By Recruiter',
    2: 'Profile Whitelisted',
    3: 'Profile Blacklisted',
    def: 'No Flag'
}
function SearchFlaggedUsers(props) {
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        getData();
    }, [pageNumber, search]);

    async function getData() {
        try {
            setIsLoading(true);
            const profile = await user.getProfile();
            const adminId = profile.id;

            const res = await getSearchFlaggedUsers(adminId, {
                pageNo: pageNumber,
                search
            });

            setIsLoading(false);
            setList(res.data.data.data);
            setCount(res.data.data.count);

        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }

    function handlePageChange(page) {
        setPageNumber(page);
    }

    function onChange(ev) {
        setSearchText(ev.target.value);
    }

    function handleKeyPress(event) {
        if (event.key == 'Enter') {
            setSearch(searchText);
        }
    }

    function onClickSearch() {
        setSearch(searchText);
    }

    function onClickClear() {
        setSearch('');
        setSearchText('');
    }

    async function onUpdate(id, status, index) {
        try {
            const profile = await user.getProfile();
            const adminId = profile.id;
            const res = await updateSearchFlaggedUser(adminId, id, {
                status
            })

            const updatedList = [...list];
            updatedList[index] = {
                ...updatedList[index],
                status
            };
            setList(updatedList);

            toast.success("Updated Successfully!");

        } catch (err) {
            toast.error("Error while updating");
        }

    }

    return (
        <div className="reported-search-users">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className="pageHeading">
                <Heading text='Reported Search Users' />
                <a className="right" href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/searchFlaggedUserReport.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>

            <div className='search-bar left'>
                <Input placeholder='Enter User ID' value={searchText} onChange={onChange} onKeyPress={handleKeyPress} />
                <Button skin='dark' disabled={false} text='Search' clickButton={onClickSearch} />
                <Button skin='light' disabled={false} text='Clear' clickButton={onClickClear} ></Button>
            </div>
            {
                list.length > 1 ?
                    <div className='row text-center'>
                        <Pagination
                            totalItemsCount={count}
                            content={PAGE_CONTENT}
                            pageNumber={pageNumber}
                            handlePageChange={(n) => handlePageChange(n)} />
                        <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={pageNumber * PAGE_CONTENT} total={count} />
                    </div> : null
            }

            {
                list.length ?
                    <div class="content">

                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                    <Table.HeaderCell>Query Comment</Table.HeaderCell>
                                    <Table.HeaderCell>Recruiter Info</Table.HeaderCell>
                                    <Table.HeaderCell>View Query</Table.HeaderCell>
                                    <Table.HeaderCell>Action Date</Table.HeaderCell>
                                    <Table.HeaderCell>Current status</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    list.map((item, index) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>
                                                <div>{item.user_id}</div>
                                                <div>{item.seeker_name}</div>
                                            </Table.Cell>
                                            <Table.Cell>{item.comment}</Table.Cell>
                                            <Table.Cell>
                                                <div>{item.rec_id}</div>
                                                <div>{item.recname}</div>
                                                <div>{item.email}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <a href={item.querytext} target="_blank">View</a>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Moment format="YYYY-MM-DD">{item.created_on}</Moment>
                                            </Table.Cell>
                                            <Table.Cell>{STATUS[item.status] || STATUS.def}</Table.Cell>
                                            <Table.Cell>
                                                {
                                                    item.status != 2 ? <Button skin='dark' disabled={false} text='Whitelist' clickButton={() => onUpdate(item.id, 2, index)}></Button> : null
                                                }
                                                {
                                                    item.status != 3 ? <Button skin='dark' disabled={false} text='Blacklist' clickButton={() => onUpdate(item.id, 3, index)}></Button> : null
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }

                            </Table.Body>
                        </Table>

                    </div>
                    : null
            }
            <div>
                {
                    isLoading ?
                        <Loader /> : null
                }
            </div>
            <div>
                {
                    !isLoading && list.length == 0 ?
                        <div className="no-content">No Data Found</div> : null
                }
            </div>
        </div>
    )
}

export default SearchFlaggedUsers;
