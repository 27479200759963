export const clientEngagementFilters = [
    { name: 'All', id: '' },
    { name: 'Current', id: '1' },
    { name: 'Churned', id: '2' },
    { name: 'Renew', id: '3' },
    { name: 'Moved', id: '4' },
    
]

export const statusMap = {
    0: 'Inactive',
    1: 'Active',
    2: 'Churned',
    3: 'Renew',
    4: 'Moved'
}

export const clientEngagementStatus = [
    { name: 'Inactive', id: '0' },
    { name: 'Renew', id: '3' },
    { name: 'Moved', id: '4' },
    { name: 'Active', id: '1' },
    { name: 'Churned', id: '2' },
    { name: 'Edit', id: '5' },
]