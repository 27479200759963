import axios from '../utils/axios';

export default function updateRecruiterVerificationStatus(adminId, recruiterId, data) {
    return (
        axios.post(`admin/${adminId}/recruiter/${recruiterId}/verification-status`,  data )
        .then(res => {
            return res
        })
    )
}
