import axios from '../utils/axios';

const updateRecruiterProfile = (adminId, recruiterId, data) => {
    return (
        axios.post('admin/' + adminId + '/recruiter/' + recruiterId, data)
            .then((response) => {
                return response
            }).catch((error) => {
                return error
            })
    )
}

export default updateRecruiterProfile;