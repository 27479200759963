const functionalAreaIimjobs = [
    {
        'id': '-1',
        'name': 'Select Functional area'
    },
    {
        'id': '1',
        'name': 'Accounting / Taxation / Audit'
    },
    {
        'id': '7',
        'name': 'Administration'
    },
    {
        'id': '2',
        'name': 'Analytics & Business Intelligence'
    },
    {
        'id': '3',
        'name': 'Banking / Financial Services'
    },
    {
        'id': '22',
        'name': 'Content Writer/ Editors'
    },
    {
        'id': '4',
        'name': 'Corporate Planning / Consulting / Strategy'
    },
    {
        'id': '31',
        'name': 'Design'
    },
    {
        'id': '28',
        'name': 'Education / Language Specialist'
    },
    {
        'id': '23',
        'name': 'Entrepreneur / Businessman / Outside Management Consultant'
    },
    {
        'id': '24',
        'name': 'Executive Assistant/Personal Secretary'
    },
    {
        'id': '5',
        'name': 'Export / Import / Merchandising'
    },
    {
        'id': '25',
        'name': 'Hotels / Restaurant Management'
    },
    {
        'id': '6',
        'name': 'HR / IR'
    },
    {
        'id': '21',
        'name': 'Instructional Designer'
    },
    {
        'id': '9',
        'name': 'Insurance'
    },
    {
        'id': '8',
        'name': 'IT'
    },
    {
        'id': '10',
        'name': 'ITeS / BPO / Customer Service'
    },
    {
        'id': '12',
        'name': 'KPO / Research'
    },
    {
        'id': '11',
        'name': 'Legal / Law / Company Secretary'
    },
    {
        'id': '13',
        'name': 'Marketing / Advertising / Public Relations'
    },
    {
        'id': '14',
        'name': 'Media / Entertainment'
    },
    {
        'id': '16',
        'name': 'Pharma / Biotech / Healthcare / Medical / R&D'
    },
    {
        'id': '17',
        'name': 'Production / Maintenance / Quality Assurance'
    },
    {
        'id': '18',
        'name': 'Purchase / Supply Chain / Logistics'
    },
    {
        'id': '26',
        'name': 'Research & Development'
    },
    {
        'id': '15',
        'name': 'Sales / Business Development / Client Servicing'
    },
    {
        'id': '29',
        'name': 'Top Management'
    },
    {
        'id': '30',
        'name': 'Training & Development'
    },
    {
        'id': '19',
        'name': 'Travel / Hospitality'
    },
    {
        'id': '32',
        'name': 'Visual Merchandising'
    },
    {
        'id': '33',
        'name': 'Presales/RFP',
    },
    {
        'id': '34',
        'name': 'Real Estate/Construction',
    },
    {
        'id': '35',
        'name': 'NGO/Social Services/CSR',
    },
    {
        'id': '36',
        'name': 'Miscellaneous',
    },
    {
        'id': '37',
        'name': 'Product Management',
    },
    {
        'id': '20',
        'name': 'Other'
    }
];

const functionalAreaHirist = [
    {
        'id': '-1',
        'name': 'Select Functional area'
    },
    {
        'id': '1',
        'name': 'Software Developer'
    },
    {
        'id': '2',
        'name': 'Technical Architect'
    },
    {
        'id': '3',
        'name': 'Project Lead / Manager'
    },
    {
        'id': '4',
        'name': 'Product Manager'
    },
    {
        'id': '5',
        'name': 'QA & Testing'
    },
    {
        'id': '6',
        'name': 'Design / Graphics'
    },
    {
        'id': '9',
        'name': 'Network Administrator'
    },
    {
        'id': '8',
        'name': 'System Administrator'
    },
    {
        'id': '10',
        'name': 'Database Administrator (DBA)'
    },
    {
        'id': '11',
        'name': 'Technical Support'
    },
    {
        'id': '12',
        'name': 'Technical Writer'
    },
    {
        'id': '13',
        'name': 'Business Analyst'
    },
    {
        'id': '14',
        'name': 'Senior Management'
    },
    {
        'id': '15',
        'name': 'Other'
    }
]

const functionalAreaUpdazz = [
    {
        'id': '-1',
        'name': 'Select Functional area'
    },
    {
        'id': '1',
        'name': 'Direct Sales',
    },
    {
        'id': '2',
        'name': 'Industrial Sales',
    },
    {
        'id': '3',
        'name': 'Business Development',
    },
    {
        'id': '4',
        'name': 'Tele Sales',
    },
    {
        'id': '5',
        'name': 'Sales Support',
    },
    {
        'id': '6',
        'name': 'Marketing / Communication',
    },
    {
        'id': '7',
        'name': 'Customer Service',
    },
    {
        'id': '9',
        'name': 'Real Estate Consultant / Agent',
    },
    {
        'id': '10',
        'name': 'Other',
    },
    {
        'id': '11',
        'name': 'Technical Support / Helpdesk',
    },
    {
        'id': '12',
        'name': 'Pharmacist/MR',
    },
    {
        'id': '13',
        'name': 'Key Account Management',
    },
    {
        'id': '14',
        'name': 'Client Servicing',
    },
    {
        'id': '15',
        'name': 'Brand Management',
    },
    {
        'id': '16',
        'name': 'Direct Marketing',
    },
    {
        'id': '17',
        'name': 'Product Management',
    },
    {
        'id': '18',
        'name': 'Retail Marketing Management',
    },
    {
        'id': '19',
        'name': 'Rural Marketing Management',
    },
    {
        'id': '20',
        'name': 'International Marketing Management',
    },
    {
        'id': '21',
        'name': 'SEO/SEM',
    },
    {
        'id': '22',
        'name': 'Email-Marketing',
    },
    {
        'id': '23',
        'name': 'PPC',
    },
    {
        'id': '24',
        'name': 'Social Media Marketing',
    },
    {
        'id': '25',
        'name': 'Display Marketing',
    },
    {
        'id': '26',
        'name': 'Graphic Designing',
    },
    {
        'id': '27',
        'name': 'Content Writing/Management',
    },
    {
        'id': '28',
        'name': 'Market Research Analytics',
    },
    {
        'id': '29',
        'name': 'PR and Media Relations',
    },
    {
        'id': '30',
        'name': 'Event Management',
    },
    {
        'id': '31',
        'name': 'Merchandising',
    },
    {
        'id': '32',
        'name': 'Business Development',
    },
    {
        'id': '33',
        'name': 'Retail-Store Mangement',
    },
    {
        'id': '34',
        'name': 'Sales Promotion Management',
    },
    {
        'id': '35',
        'name': 'Territory Management',
    },
    {
        'id': '36',
        'name': 'Sales Training',
    },
    {
        'id': '37',
        'name': 'Bval Management',
    },
    {
        'id': '38',
        'name': 'RFI/RFP Management',
    },
    {
        'id': '39',
        'name': 'Non-Tech - Team Lead',
    },
    {
        'id': '40',
        'name': 'Tech - Team Lead',
    },
    {
        'id': '41',
        'name': 'Relationship Management',
    },
    {
        'id': '42',
        'name': 'Investment Advisor',
    },
    {
        'id': '43',
        'name': 'Wealth Management',
    },
    {
        'id': '44',
        'name': 'Asset Management',
    },
    {
        'id': '45',
        'name': 'Banking/Insurance/Loan Sales'
    },
    {
        'id': '46',
        'name': 'Advertising'
    },
    {
        'id': '47',
        'name': 'IT Product & Service Sales'
    },
    {
        'id': '48',
        'name': 'Top Management ( For senior Level Positions)'
    },
    {
        'id': '49',
        'name': 'Online/Digital Marketing'
    },
    {
        'id': '50',
        'name': 'Distribution'
    }
]

const functionalAreaHiristNew = [
    {
        'id': '16',
        'value': 'Backend Development',
        'cat_id': '1',
    },
    {
        'id': '17',
        'value': 'Frontend Development',
        'cat_id': '1',
    },
    {
        'id': '18',
        'value': 'Full-Stack Development',
        'cat_id': '1',
    },
    {
        'id': '19',
        'value': 'Mobile Development - Android',
        'cat_id': '1',
    },
    {
        'id': '20',
        'value': 'Mobile Development - iOS',
        'cat_id': '1',
    },
    {
        'id': '21',
        'value': 'Mobile Development - Hybrid/Native',
        'cat_id': '1',
    },
    {
        'id': '22',
        'value': 'DevOps / Cloud',
        'cat_id': '1',
    },
    {
        'id': '23',
        'value': 'Site Reliability Engineering',
        'cat_id': '1',
    },
    {
        'id': '2',
        'value': 'Technical / Solution Architect',
        'cat_id': '1',
    },
    {
        'id': '5',
        'value': 'QA & Testing',
        'cat_id': '1',
    },
    {
        'id': '24',
        'value': 'Big Data / Data Warehousing / ETL',
        'cat_id': '1',
    },
    {
        'id': '25',
        'value': 'Embedded / Kernel Development',
        'cat_id': '1',
    },
    {
        'id': '26',
        'value': 'Other Software Development',
        'cat_id': '1',
    },
    {
        'id': '13',
        'value': 'Data Analysis / Business Analysis',
        'cat_id': '2',
    },
    {
        'id': '27',
        'value': 'Data Science',
        'cat_id': '2',
    },
    {
        'id': '28',
        'value': 'Data Mining / Analysis',
        'cat_id': '2',
    },
    {
        'id': '29',
        'value': 'Data Engineering',
        'cat_id': '2',
    },
    {
        'id': '30',
        'value': 'ML / DL Engineering',
        'cat_id': '2',
    },
    {
        'id': '31',
        'value': 'ML / DL / AI Research',
        'cat_id': '2',
    },
    {
        'id': '32',
        'value': 'Engineering Management',
        'cat_id': '3',
    },
    {
        'id': '33',
        'value': 'Release Management',
        'cat_id': '3',
    },
    {
        'id': '4',
        'value': 'Product Management',
        'cat_id': '3',
    },
    {
        'id': '3',
        'value': 'Project Management',
        'cat_id': '3',
    },
    {
        'id': '34',
        'value': 'Program Management',
        'cat_id': '3',
    },
    {
        'id': '14',
        'value': 'Senior Management',
        'cat_id': '3',
    },
    {
        'id': '35',
        'value': 'UI / UX Design',
        'cat_id': '4',
    },
    {
        'id': '36',
        'value': 'Product Design',
        'cat_id': '4',
    },
    {
        'id': '37',
        'value': 'UX Architect',
        'cat_id': '4',
    },
    {
        'id': '38',
        'value': 'Web Design',
        'cat_id': '4',
    },
    {
        'id': '39',
        'value': 'Graphic Design / Animation',
        'cat_id': '4',
    },
    {
        'id': '40',
        'value': 'Illustrator',
        'cat_id': '4',
    },
    {
        'id': '41',
        'value': 'Interaction Design',
        'cat_id': '4',
    },
    {
        'id': '42',
        'value': 'AR / VR Design',
        'cat_id': '4',
    },
    {
        'id': '43',
        'value': 'User Researcher',
        'cat_id': '4',
    },
    {
        'id': '44',
        'value': 'Other Design',
        'cat_id': '4',
    },
    {
        'id': '45',
        'value': 'Blockchain',
        'cat_id': '5',
    },
    {
        'id': '46',
        'value': 'IoT',
        'cat_id': '5',
    },
    {
        'id': '47',
        'value': 'Computer Vision',
        'cat_id': '5',
    },
    {
        'id': '48',
        'value': 'Cloud Computing',
        'cat_id': '5',
    },
    {
        'id': '49',
        'value': 'AR / VR',
        'cat_id': '5',
    },
    {
        'id': '50',
        'value': 'Cyber Security',
        'cat_id': '5',
    },
    {
        'id': '10',
        'value': 'Database Admin / Development',
        'cat_id': '6',
    },
    {
        'id': '9',
        'value': 'Network Administration',
        'cat_id': '6',
    },
    {
        'id': '8',
        'value': 'Systems Administration',
        'cat_id': '6',
    },
    {
        'id': '51',
        'value': 'Functional / Technical Consulting',
        'cat_id': '6',
    },
    {
        'id': '11',
        'value': 'Technical / Production Support',
        'cat_id': '6',
    },
    {
        'id': '52',
        'value': 'IT Management / IT Support',
        'cat_id': '6',
    },
    {
        'id': '53',
        'value': 'IT Security',
        'cat_id': '6',
    },
    {
        'id': '54',
        'value': 'IT Infrastructure Services',
        'cat_id': '6',
    },
    {
        'id': '55',
        'value': 'Networking & Wireless',
        'cat_id': '6',
    },
    {
        'id': '12',
        'value': 'Technical Writing',
        'cat_id': '6',
    },
    {
        'id': '15',
        'value': 'Other',
        'cat_id': '7',
    }
]

const functionalAreaCategory = {
    1: 'Software Engineering',
    2: 'Data Science and Analysis',
    3: 'Management',
    4: 'Design',
    5: 'Emerging Technologies & Roles',
    6: 'IT Operations and Support',
    7: 'Other',
};

export function getFunctionalArea() {
    if (process.env.REACT_APP_PLATFORM == 'iimjobs')
        return functionalAreaIimjobs
    if(process.env.REACT_APP_PLATFORM == 'updazz')
        return functionalAreaUpdazz
    if (process.env.REACT_APP_SHOW_NEW_FUNCTIONAL_AREA_LIST == 1) {
        const dropdown = [];

        for (let i = 0; i < functionalAreaHiristNew.length; i++) {
            if (!dropdown[functionalAreaHiristNew[i].cat_id - 1]) {
                dropdown[functionalAreaHiristNew[i].cat_id - 1] = {
                    isCategory: true,
                    id: functionalAreaHiristNew[i].cat_id,
                    label: functionalAreaCategory[functionalAreaHiristNew[i].cat_id],
                    items: []
                }
            }

            dropdown[functionalAreaHiristNew[i].cat_id - 1].items.push({
                name: functionalAreaHiristNew[i].value,
                id: functionalAreaHiristNew[i].id,
            })
        }
        return [
            {
                isCategory: false,
                id: '',
                value: '',
                name: 'Select Functional Area'
            },
            ...dropdown,
        ];
    }
    return functionalAreaHirist
}

export function getFunctionalAreaMap() {

    const arr = getFunctionalArea();
    if (process.env.REACT_APP_SHOW_NEW_FUNCTIONAL_AREA_LIST == 1) {
        let functionalAreaMap = {}
        for (const category of arr) {
            if (category.isCategory) {
                const aCategoryMap = category.items.reduce((prev, curr) => {
                    prev[curr.id] = curr.name;
                    return prev;
                }, {});
                functionalAreaMap = { ...functionalAreaMap, ...aCategoryMap };
            }
        }
        return functionalAreaMap;
    }
    return arr.reduce((prev, curr) => {
        prev[curr.id] = curr.name;
        return prev;
    }, {});

}

export function getFA() {
    let fa = [];
    if (process.env.REACT_APP_PLATFORM == 'iimjobs'){
        fa = functionalAreaIimjobs
    }else{
        fa = functionalAreaHirist
    }
    return fa.filter(item => item.id != -1).map(item => { 
        return {
            label: item.name,
            value: item.id.toString(),
            key: item.id,
        }
    })
}