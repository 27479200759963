import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Button from '../Button';
import Dropdown from '../Dropdown/Dropdown';
import Loader from '../Loader';

import './styles.scss';
import InputBox from '../InputBox';

const SeekerActionModal = (props) => {
    const [error, setError] = useState(null);
    const [paymentGatewayId, setPaymentGatewayId] = useState(null);
    const [paymentError, setPaymentError] = useState(null);

    const handleSubmit = () => {
        if (!props.reason || props.reason == 0) {
            setError(props.errorMsg ? props.errorMsg : 'Please select reason');
            return;
        }

        if (props.type == 'activate' && [1, 2].includes(+props.reason) && (!paymentGatewayId || !paymentGatewayId.trim())) {
            setPaymentError("Please enter Payment Gateway ID");
            setError(null)
            return;
        }

        props.onActionSubmit(paymentGatewayId);
    };

    const handleDropdownChange = (event) => {
        const value = event.target.value;

        if (value) {
            props.setReason(value);
        }
    };

    const handleInputChange = (event) => {
        setPaymentGatewayId(event.target.value);
    }

    return (
        <Modal
            className='action-modal-action'
            open={true}
            onClose={props.onClose}
        >
            <Modal.Header>Are you sure you want to {props.type} this order?</Modal.Header>
            {props.isLoading ? (
                <Loader />
            ) : (
                <>
                    <Modal.Content>
                        <div>
                            <div>Please select reason for {props.type === 'activate' ? 'activation' : 'deactivation'}:</div>
                            <Dropdown
                                data={props.reasonsDropdownList}
                                dropdownChange={handleDropdownChange}
                            />
                            {error ? <div className='error-text'>{error}</div> : null}
                        </div>
                        <br/>
                        {
                            props.type == 'activate' ?
                                <div>
                                    <div>Please enter Payment Gateway ID:</div>
                                    <InputBox
                                        options={{ marginLeft: "0px" }}
                                        type="text"
                                        placeholder="Enter Payment Gateway ID"
                                        value={paymentGatewayId}
                                        onChange={handleInputChange}
                                    />
                                    {paymentError ? <div className='error-text'>{paymentError}</div> : null}
                                </div> : null
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <Button text={`${props.type[0].toUpperCase()}${props.type.substring(1)}`} skin="dark" clickButton={handleSubmit} />
                            <Button text="Cancel" skin="light" clickButton={props.onClose} />
                        </div>
                    </Modal.Actions>
                </>
            )}
        </Modal>
    )
};

SeekerActionModal.propTypes = {
    type: PropTypes.oneOf(['activate', 'deactivate']).isRequired,
    reason: PropTypes.number,
    setReason: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onActionSubmit: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
    isLoading: PropTypes.bool,
    reasonsDropdownList: PropTypes.array.isRequired,
};

export default SeekerActionModal;