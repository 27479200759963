import React, { useState, useEffect } from "react";
import user from "../../store/user";
import fetchAllOtherFunctionalArea from "../../services/fetchAllOtherFunctionalArea";


import "./otherFunctionalArea.scss";
import { Table } from "semantic-ui-react";
import Loader from "../../components/Loader";
import CountDisplay from "../../components/CountDisplay";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import { ToastContainer } from "react-toastify";
import moment from "moment";

const PAGE_CONTENT = 50;
const OtherFunctionalArea = (props) => {

    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 0
    })
    const [admin, setAdmin] = useState({
        adminId: null,
        viewAccess: true
    })
    const [filter, setFilter] = useState(false)
    const [fetchApiCall, setFetchApiCall] = useState(false)

    useEffect(() => {

        //implement try catch
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        const profile = fetchProfile();

        setAdmin({
            adminId: profile.id,
            viewAccess: true
        })

        getAllOtherFunctionalAreas(1);

    }, [])

    useEffect(() => {
         getAllOtherFunctionalAreas(1, { type: filter ? "published" : "all" });
    }, [filter])

    const getAllOtherFunctionalAreas = async (page, filters = {}) => {
        setFetchApiCall(true);
        try {
            const profile = await user.getProfile();

            const res = await fetchAllOtherFunctionalArea(profile.id, { pageNumber: page, pageContent: PAGE_CONTENT, ...filters })
            setContent({
                pageNumber: page,
                list: res.data.data.data,
                count: res.data.data.totalCount
            })
            setFetchApiCall(false)
        }
        catch (err) {
            console.log(err);
            setFetchApiCall(false)
        }
    }

    const handlePageChange = (n) => {
        getAllOtherFunctionalAreas(n)
    }

    const filterOtherFunctionalArea = async() => {
        setFilter(prev => !prev);
    }
    return (
        <div className="wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="pageHeading">
                <Heading text="Other Functional Areas" />

                <Button
                    skin="dark"
                    text={`Show ${!filter ? "Published" : "All"
                        }`}
                    clickButton={() => {
                        filterOtherFunctionalArea();
                    }}
                />
            </div>
            <hr />
            <div className="row text-center">
                <Pagination
                    totalItemsCount={content.count}
                    content={PAGE_CONTENT}
                    pageNumber={content.pageNumber}
                    handlePageChange={(n) => handlePageChange(n)}
                />
                <CountDisplay
                    start={content.list.length == 0 ? content.list.length : (content.pageNumber - 1) * PAGE_CONTENT + 1}
                    end={content.pageNumber * PAGE_CONTENT - ((content.list.length < PAGE_CONTENT ? PAGE_CONTENT - content.list.length : 0))}
                    total={content.count}
                />

                {fetchApiCall ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <Table
                            fixed
                            selectable
                            className="mailers-table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={8}>
                                        Created On
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Recruiter ID
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Recruiter Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Job Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Publish Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={12}>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Text Other Functional Area
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.list.map((item, i) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                {moment(item.createdOn).format('DD-MM-YYYY hh:mm A')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.recId}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.recname}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.jobId}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.publishedId != 0 ? item.publishedId : ''}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.textOtherFA}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>

                    </div>
                )}
            </div>
        </div>
    );
};

export default OtherFunctionalArea;
