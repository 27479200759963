const { locationList } = require('./location.js')

export const locationListLabel = [
    {
        "label": "Ahmedabad",
        "value": "1",
        "key": "1"
    },
    {
        "label": "Bengaluru / Bangalore",
        "value": "2",
        "key": "2"
    },
    {
        "label": "Chandigarh",
        "value": "41",
        "key": "41"
    },
    {
        "label": "Chennai",
        "value": "3",
        "key": "3"
    },
    {
        "label": "Delhi",
        "value": "221",
        "key": "221"
    },
    {
        "label": "Gurgaon",
        "value": "5",
        "key": "5"
    },
    {
        "label": "Hyderabad",
        "value": "6",
        "key": "6"
    },
    {
        "label": "Kolkata",
        "value": "7",
        "key": "7"
    },
    {
        "label": "Mumbai",
        "value": "8",
        "key": "8"
    },
    {
        "label": "Noida",
        "value": "9",
        "key": "9"
    },
    {
        "label": "Pune",
        "value": "10",
        "key": "10"
    },
    {
        "label": "Guntur",
        "value": "62",
        "key": "62"
    },
    {
        "label": "Kakinada",
        "value": "82",
        "key": "82"
    },
    {
        "label": "Kurnool",
        "value": "98",
        "key": "98"
    },
    {
        "label": "Nellore",
        "value": "113",
        "key": "113"
    },
    {
        "label": "Nizamabad",
        "value": "114",
        "key": "114"
    },
    {
        "label": "Rajamundry",
        "value": "129",
        "key": "129"
    },
    {
        "label": "Tirupati",
        "value": "150",
        "key": "150"
    },
    {
        "label": "Vijayawada",
        "value": "160",
        "key": "160"
    },
    {
        "label": "Visakhapatnam",
        "value": "161",
        "key": "161"
    },
    {
        "label": "Warangal",
        "value": "162",
        "key": "162"
    },
    {
        "label": "Anantapur",
        "value": "222",
        "key": "222"
    },
    {
        "label": "Guntakal",
        "value": "223",
        "key": "223"
    },
    {
        "label": "Rajahmundry",
        "value": "225",
        "key": "225"
    },
    {
        "label": "Secunderabad",
        "value": "226",
        "key": "226"
    },
    {
        "label": "Andhra Pradesh - Other",
        "value": "227",
        "key": "227"
    },
    {
        "label": "Itanagar",
        "value": "72",
        "key": "72"
    },
    {
        "label": "Arunachal Pradesh - Other",
        "value": "228",
        "key": "228"
    },
    {
        "label": "Dispur",
        "value": "51",
        "key": "51"
    },
    {
        "label": "Guwahati",
        "value": "63",
        "key": "63"
    },
    {
        "label": "Silchar",
        "value": "140",
        "key": "140"
    },
    {
        "label": "Assam - Other",
        "value": "229",
        "key": "229"
    },
    {
        "label": "Bhilaigarh",
        "value": "34",
        "key": "34"
    },
    {
        "label": "Patna",
        "value": "122",
        "key": "122"
    },
    {
        "label": "Bihar - Other",
        "value": "230",
        "key": "230"
    },
    {
        "label": "Bhagalpur",
        "value": "26",
        "key": "26"
    },
    {
        "label": "Bilaspur",
        "value": "39",
        "key": "39"
    },
    {
        "label": "Raipur",
        "value": "128",
        "key": "128"
    },
    {
        "label": "Bhillai",
        "value": "231",
        "key": "231"
    },
    {
        "label": "Chhattisgarh - Other",
        "value": "232",
        "key": "232"
    },
    {
        "label": "Goa",
        "value": "59",
        "key": "59"
    },
    {
        "label": "Panaji",
        "value": "233",
        "key": "233"
    },
    {
        "label": "Panjim",
        "value": "234",
        "key": "234"
    },
    {
        "label": "Vasco Da Gama",
        "value": "235",
        "key": "235"
    },
    {
        "label": "Goa - Other",
        "value": "236",
        "key": "236"
    },
    {
        "label": "Anand",
        "value": "21",
        "key": "21"
    },
    {
        "label": "Ankleshwar",
        "value": "22",
        "key": "22"
    },
    {
        "label": "Baroda",
        "value": "28",
        "key": "28"
    },
    {
        "label": "Bharuch",
        "value": "31",
        "key": "31"
    },
    {
        "label": "Bhavnagar",
        "value": "33",
        "key": "33"
    },
    {
        "label": "Bhuj",
        "value": "37",
        "key": "37"
    },
    {
        "label": "Gandhinagar",
        "value": "56",
        "key": "56"
    },
    {
        "label": "Jamnagar",
        "value": "79",
        "key": "79"
    },
    {
        "label": "Kandla",
        "value": "83",
        "key": "83"
    },
    {
        "label": "Porbandar",
        "value": "124",
        "key": "124"
    },
    {
        "label": "Rajkot",
        "value": "130",
        "key": "130"
    },
    {
        "label": "Surat",
        "value": "145",
        "key": "145"
    },
    {
        "label": "Vadodara",
        "value": "155",
        "key": "155"
    },
    {
        "label": "Valsad",
        "value": "156",
        "key": "156"
    },
    {
        "label": "Vapi",
        "value": "157",
        "key": "157"
    },
    {
        "label": "Gir",
        "value": "238",
        "key": "238"
    },
    {
        "label": "Gujarat - Other",
        "value": "239",
        "key": "239"
    },
    {
        "label": "Ambala",
        "value": "19",
        "key": "19"
    },
    {
        "label": "Faridabad",
        "value": "55",
        "key": "55"
    },
    {
        "label": "Hisar",
        "value": "66",
        "key": "66"
    },
    {
        "label": "Karnal",
        "value": "86",
        "key": "86"
    },
    {
        "label": "Kurukshetra",
        "value": "99",
        "key": "99"
    },
    {
        "label": "Panipat",
        "value": "117",
        "key": "117"
    },
    {
        "label": "Rohtak",
        "value": "132",
        "key": "132"
    },
    {
        "label": "Sonipat / Sonepat",
        "value": "143",
        "key": "143"
    },
    {
        "label": "Yamunanagar",
        "value": "164",
        "key": "164"
    },
    {
        "label": "Haryana - Other",
        "value": "242",
        "key": "242"
    },
    {
        "label": "Baddi",
        "value": "25",
        "key": "25"
    },
    {
        "label": "Dalhousie",
        "value": "45",
        "key": "45"
    },
    {
        "label": "Dharamsala",
        "value": "49",
        "key": "49"
    },
    {
        "label": "Kulu/Manali",
        "value": "97",
        "key": "97"
    },
    {
        "label": "Shimla",
        "value": "137",
        "key": "137"
    },
    {
        "label": "Himachal Pradesh - Other",
        "value": "244",
        "key": "244"
    },
    {
        "label": "Jammu",
        "value": "78",
        "key": "78"
    },
    {
        "label": "Srinagar",
        "value": "144",
        "key": "144"
    },
    {
        "label": "Jammu & Kashmir - Other",
        "value": "245",
        "key": "245"
    },
    {
        "label": "Bokaro",
        "value": "40",
        "key": "40"
    },
    {
        "label": "Dhanbad",
        "value": "48",
        "key": "48"
    },
    {
        "label": "Jamshedpur",
        "value": "80",
        "key": "80"
    },
    {
        "label": "Ranchi",
        "value": "131",
        "key": "131"
    },
    {
        "label": "Jharkhand - Other",
        "value": "246",
        "key": "246"
    },
    {
        "label": "Belgaum",
        "value": "29",
        "key": "29"
    },
    {
        "label": "Bellary",
        "value": "30",
        "key": "30"
    },
    {
        "label": "Bidar",
        "value": "38",
        "key": "38"
    },
    {
        "label": "Dharwad",
        "value": "50",
        "key": "50"
    },
    {
        "label": "Gulbarga",
        "value": "61",
        "key": "61"
    },
    {
        "label": "Hubli",
        "value": "68",
        "key": "68"
    },
    {
        "label": "Kolar",
        "value": "91",
        "key": "91"
    },
    {
        "label": "Mangalore",
        "value": "104",
        "key": "104"
    },
    {
        "label": "Mysore",
        "value": "109",
        "key": "109"
    },
    {
        "label": "Shimoga",
        "value": "138",
        "key": "138"
    },
    {
        "label": "Karnataka - Other",
        "value": "248",
        "key": "248"
    },
    {
        "label": "Alappuzha / Alleppey",
        "value": "16",
        "key": "16"
    },
    {
        "label": "Cochin / Kochi / Ernakulam",
        "value": "42",
        "key": "42"
    },
    {
        "label": "Idukki",
        "value": "69",
        "key": "69"
    },
    {
        "label": "Kannur",
        "value": "84",
        "key": "84"
    },
    {
        "label": "Kasargod",
        "value": "87",
        "key": "87"
    },
    {
        "label": "Kollam",
        "value": "93",
        "key": "93"
    },
    {
        "label": "Kottayam",
        "value": "95",
        "key": "95"
    },
    {
        "label": "Kozhikode / Calicut",
        "value": "96",
        "key": "96"
    },
    {
        "label": "Malappuram",
        "value": "103",
        "key": "103"
    },
    {
        "label": "Pallakad",
        "value": "116",
        "key": "116"
    },
    {
        "label": "Pathanamthitta",
        "value": "119",
        "key": "119"
    },
    {
        "label": "Thrissur / Trissur",
        "value": "147",
        "key": "147"
    },
    {
        "label": "Thiruvananthapuram / Trivandrum",
        "value": "148",
        "key": "148"
    },
    {
        "label": "Wayanad",
        "value": "163",
        "key": "163"
    },
    {
        "label": "Palakkad",
        "value": "249",
        "key": "249"
    },
    {
        "label": "Palghat",
        "value": "250",
        "key": "250"
    },
    {
        "label": "Kerala - Other",
        "value": "251",
        "key": "251"
    },
    {
        "label": "Bhopal",
        "value": "35",
        "key": "35"
    },
    {
        "label": "Gwalior",
        "value": "64",
        "key": "64"
    },
    {
        "label": "Indore",
        "value": "71",
        "key": "71"
    },
    {
        "label": "Jabalpur",
        "value": "73",
        "key": "73"
    },
    {
        "label": "Ujjain",
        "value": "154",
        "key": "154"
    },
    {
        "label": "Madhya Pradesh - Other",
        "value": "252",
        "key": "252"
    },
    {
        "label": "Ahmednagar",
        "value": "13",
        "key": "13"
    },
    {
        "label": "Aurangabad",
        "value": "24",
        "key": "24"
    },
    {
        "label": "Jalgaon",
        "value": "77",
        "key": "77"
    },
    {
        "label": "Kolhapur",
        "value": "92",
        "key": "92"
    },
    {
        "label": "Nagpur",
        "value": "111",
        "key": "111"
    },
    {
        "label": "Nasik",
        "value": "112",
        "key": "112"
    },
    {
        "label": "Sholapur",
        "value": "139",
        "key": "139"
    },
    {
        "label": "Mumbai Suburbs",
        "value": "254",
        "key": "254"
    },
    {
        "label": "Navi Mumbai",
        "value": "255",
        "key": "255"
    },
    {
        "label": "Solapur",
        "value": "257",
        "key": "257"
    },
    {
        "label": "Maharashtra - Other",
        "value": "258",
        "key": "258"
    },
    {
        "label": "Imphal",
        "value": "70",
        "key": "70"
    },
    {
        "label": "Manipur - Other",
        "value": "259",
        "key": "259"
    },
    {
        "label": "Shillong",
        "value": "136",
        "key": "136"
    },
    {
        "label": "Meghalaya - Other",
        "value": "260",
        "key": "260"
    },
    {
        "label": "Aizawal",
        "value": "14",
        "key": "14"
    },
    {
        "label": "Mizoram - Other",
        "value": "261",
        "key": "261"
    },
    {
        "label": "Kohima",
        "value": "90",
        "key": "90"
    },
    {
        "label": "Dimapur",
        "value": "262",
        "key": "262"
    },
    {
        "label": "Nagaland - Other",
        "value": "263",
        "key": "263"
    },
    {
        "label": "Bhubaneshwar",
        "value": "36",
        "key": "36"
    },
    {
        "label": "Cuttack",
        "value": "44",
        "key": "44"
    },
    {
        "label": "Paradeep",
        "value": "118",
        "key": "118"
    },
    {
        "label": "Puri",
        "value": "126",
        "key": "126"
    },
    {
        "label": "Rourkela",
        "value": "134",
        "key": "134"
    },
    {
        "label": "Odisha - Other",
        "value": "264",
        "key": "264"
    },
    {
        "label": "Amritsar",
        "value": "20",
        "key": "20"
    },
    {
        "label": "Bhatinda",
        "value": "32",
        "key": "32"
    },
    {
        "label": "Jalandhar",
        "value": "76",
        "key": "76"
    },
    {
        "label": "Ludhiana",
        "value": "101",
        "key": "101"
    },
    {
        "label": "Mohali",
        "value": "107",
        "key": "107"
    },
    {
        "label": "Pathankot",
        "value": "120",
        "key": "120"
    },
    {
        "label": "Patiala",
        "value": "121",
        "key": "121"
    },
    {
        "label": "Bathinda",
        "value": "265",
        "key": "265"
    },
    {
        "label": "Punjab - Other",
        "value": "267",
        "key": "267"
    },
    {
        "label": "Ajmer",
        "value": "15",
        "key": "15"
    },
    {
        "label": "Jaipur",
        "value": "74",
        "key": "74"
    },
    {
        "label": "Jaisalmer",
        "value": "75",
        "key": "75"
    },
    {
        "label": "Jodhpur",
        "value": "81",
        "key": "81"
    },
    {
        "label": "Kota",
        "value": "94",
        "key": "94"
    },
    {
        "label": "Udaipur",
        "value": "153",
        "key": "153"
    },
    {
        "label": "Rajasthan - Other",
        "value": "268",
        "key": "268"
    },
    {
        "label": "Gangtok",
        "value": "57",
        "key": "57"
    },
    {
        "label": "Sikkim - Other",
        "value": "269",
        "key": "269"
    },
    {
        "label": "Coimbatore",
        "value": "43",
        "key": "43"
    },
    {
        "label": "Erode",
        "value": "53",
        "key": "53"
    },
    {
        "label": "Hosur",
        "value": "67",
        "key": "67"
    },
    {
        "label": "Madurai",
        "value": "102",
        "key": "102"
    },
    {
        "label": "Nagercoil",
        "value": "110",
        "key": "110"
    },
    {
        "label": "Ooty",
        "value": "115",
        "key": "115"
    },
    {
        "label": "Salem",
        "value": "135",
        "key": "135"
    },
    {
        "label": "Thanjavur",
        "value": "146",
        "key": "146"
    },
    {
        "label": "Tirunalveli",
        "value": "149",
        "key": "149"
    },
    {
        "label": "Trichy",
        "value": "151",
        "key": "151"
    },
    {
        "label": "Tuticorin",
        "value": "152",
        "key": "152"
    },
    {
        "label": "Vellore",
        "value": "159",
        "key": "159"
    },
    {
        "label": "Cuddalore",
        "value": "271",
        "key": "271"
    },
    {
        "label": "Tamil Nadu - Other",
        "value": "272",
        "key": "272"
    },
    {
        "label": "Agartala",
        "value": "11",
        "key": "11"
    },
    {
        "label": "Tripura - Other",
        "value": "273",
        "key": "273"
    },
    {
        "label": "Daman",
        "value": "46",
        "key": "46"
    },
    {
        "label": "Kavaratti",
        "value": "88",
        "key": "88"
    },
    {
        "label": "Pondicherry",
        "value": "123",
        "key": "123"
    },
    {
        "label": "Port Blair",
        "value": "125",
        "key": "125"
    },
    {
        "label": "Silvassa",
        "value": "142",
        "key": "142"
    },
    {
        "label": "Dadra & Nagar Haveli",
        "value": "275",
        "key": "275"
    },
    {
        "label": "Agra",
        "value": "12",
        "key": "12"
    },
    {
        "label": "Aligarh",
        "value": "17",
        "key": "17"
    },
    {
        "label": "Allahabad",
        "value": "18",
        "key": "18"
    },
    {
        "label": "Bareilly",
        "value": "27",
        "key": "27"
    },
    {
        "label": "Faizabad",
        "value": "54",
        "key": "54"
    },
    {
        "label": "Ghaziabad",
        "value": "58",
        "key": "58"
    },
    {
        "label": "Gorakhpur",
        "value": "60",
        "key": "60"
    },
    {
        "label": "Kanpur",
        "value": "85",
        "key": "85"
    },
    {
        "label": "Lucknow",
        "value": "100",
        "key": "100"
    },
    {
        "label": "Mathura",
        "value": "105",
        "key": "105"
    },
    {
        "label": "Meerut",
        "value": "106",
        "key": "106"
    },
    {
        "label": "Moradabad",
        "value": "108",
        "key": "108"
    },
    {
        "label": "Varanasi",
        "value": "158",
        "key": "158"
    },
    {
        "label": "Uttar Pradesh - Other",
        "value": "278",
        "key": "278"
    },
    {
        "label": "Dehradun",
        "value": "47",
        "key": "47"
    },
    {
        "label": "Roorkee",
        "value": "133",
        "key": "133"
    },
    {
        "label": "Uttaranchal - Other",
        "value": "279",
        "key": "279"
    },
    {
        "label": "Asansol",
        "value": "23",
        "key": "23"
    },
    {
        "label": "Durgapur",
        "value": "52",
        "key": "52"
    },
    {
        "label": "Haldia",
        "value": "65",
        "key": "65"
    },
    {
        "label": "Kharagpur",
        "value": "89",
        "key": "89"
    },
    {
        "label": "Siliguri",
        "value": "141",
        "key": "141"
    },
    {
        "label": "West Bengal - Other",
        "value": "281",
        "key": "281"
    },
    {
        "label": "Other India",
        "value": "165",
        "key": "165"
    },
    {
        "label": "Quilon",
        "value": "127",
        "key": "127"
    },
    {
        "label": "Australia",
        "value": "166",
        "key": "166"
    },
    {
        "label": "Bahrain",
        "value": "167",
        "key": "167"
    },
    {
        "label": "Bangladesh",
        "value": "168",
        "key": "168"
    },
    {
        "label": "Belgium",
        "value": "169",
        "key": "169"
    },
    {
        "label": "Canada",
        "value": "170",
        "key": "170"
    },
    {
        "label": "China",
        "value": "171",
        "key": "171"
    },
    {
        "label": "Doha",
        "value": "214",
        "key": "214"
    },
    {
        "label": "Dubai",
        "value": "215",
        "key": "215"
    },
    {
        "label": "Egypt",
        "value": "172",
        "key": "172"
    },
    {
        "label": "France",
        "value": "173",
        "key": "173"
    },
    {
        "label": "Germany",
        "value": "174",
        "key": "174"
    },
    {
        "label": "Gulf",
        "value": "175",
        "key": "175"
    },
    {
        "label": "Hongkong",
        "value": "176",
        "key": "176"
    },
    {
        "label": "Indonesia",
        "value": "177",
        "key": "177"
    },
    {
        "label": "INDIA",
        "value": "216",
        "key": "216"
    },
    {
        "label": "Ireland",
        "value": "178",
        "key": "178"
    },
    {
        "label": "Italy",
        "value": "217",
        "key": "217"
    },
    {
        "label": "Japan",
        "value": "179",
        "key": "179"
    },
    {
        "label": "Jordan",
        "value": "180",
        "key": "180"
    },
    {
        "label": "Kenya",
        "value": "218",
        "key": "218"
    },
    {
        "label": "Kuwait",
        "value": "181",
        "key": "181"
    },
    {
        "label": "Lebanon",
        "value": "182",
        "key": "182"
    },
    {
        "label": "Libya",
        "value": "219",
        "key": "219"
    },
    {
        "label": "Macau",
        "value": "183",
        "key": "183"
    },
    {
        "label": "Malaysia",
        "value": "184",
        "key": "184"
    },
    {
        "label": "Maldives",
        "value": "185",
        "key": "185"
    },
    {
        "label": "Mauritius",
        "value": "186",
        "key": "186"
    },
    {
        "label": "Mexico",
        "value": "187",
        "key": "187"
    },
    {
        "label": "Nepal",
        "value": "188",
        "key": "188"
    },
    {
        "label": "Netherlands",
        "value": "189",
        "key": "189"
    },
    {
        "label": "New Zealand",
        "value": "190",
        "key": "190"
    },
    {
        "label": "Norway",
        "value": "220",
        "key": "220"
    },
    {
        "label": "Oman",
        "value": "191",
        "key": "191"
    },
    {
        "label": "Pakistan",
        "value": "192",
        "key": "192"
    },
    {
        "label": "Philippines",
        "value": "193",
        "key": "193"
    },
    {
        "label": "Qatar",
        "value": "194",
        "key": "194"
    },
    {
        "label": "Russia",
        "value": "195",
        "key": "195"
    },
    {
        "label": "Saudi Arabia",
        "value": "196",
        "key": "196"
    },
    {
        "label": "Singapore",
        "value": "197",
        "key": "197"
    },
    {
        "label": "South Africa",
        "value": "198",
        "key": "198"
    },
    {
        "label": "South Korea",
        "value": "199",
        "key": "199"
    },
    {
        "label": "Spain",
        "value": "200",
        "key": "200"
    },
    {
        "label": "SriLanka",
        "value": "201",
        "key": "201"
    },
    {
        "label": "Sweden",
        "value": "202",
        "key": "202"
    },
    {
        "label": "Switzerland",
        "value": "203",
        "key": "203"
    },
    {
        "label": "Taiwan",
        "value": "204",
        "key": "204"
    },
    {
        "label": "Thailand",
        "value": "205",
        "key": "205"
    },
    {
        "label": "United Kingdom",
        "value": "206",
        "key": "206"
    },
    {
        "label": "United Arab Emirates",
        "value": "207",
        "key": "207"
    },
    {
        "label": "United States",
        "value": "208",
        "key": "208"
    },
    {
        "label": "Vietnam",
        "value": "209",
        "key": "209"
    },
    {
        "label": "Yemen",
        "value": "210",
        "key": "210"
    },
    {
        "label": "Zimbabwe",
        "value": "211",
        "key": "211"
    },
    {
        "label": "Luxembourg",
        "value": "282",
        "key": "282"
    },
    {
        "label": "Other International",
        "value": "212",
        "key": "212"
    },
    {
        "label": "Anywhere",
        "value": "213",
        "key": "213"
    }
]

export const locationListArr = locationListLabel.map(location => { 
    return {
        ...location,
        text: location['label']
    }
})

const seekerLocationMap = {
    "0": "213",
    "87": "2, 3, 4, 5, 6, 7, 8, 9, 10, 41, 221",
    "88": "213",
    "89": "166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178,179,180,181, 182, 183, 184, 185, 186, 187, 188, 189,190, 191,192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212",
    "1": "4",
    "2": "8",
    "3": "2",
    "4": "6",
    "5": "7",
    "6": "3",
    "7": "10",
    "8": "56, 145, 155",
    "9": "8, 111, 112, 255, 258",
    "10": "35, 64, 71, 73, 154, 252",
    "11": "74",
    "12": "63",
    "13": "59",
    "14": "41",
    "36": "221",
    "37": "5",
    "38": "9",
    "39": "9",
    "40": "55",
    "41": "58",
    "43": "78",
    "44": "144",
    "45": "20",
    "46": "76",
    "47": "121",
    "48": "101",
    "49": "143",
    "50": "117",
    "51": "153",
    "52": "81",
    "53": "1",
    "54": "145",
    "55": "56",
    "56": "155",
    "58": "47",
    "60": "100",
    "61": "122",
    "62": "131",
    "63": "80",
    "65": "36",
    "66": "111",
    "67": "112",
    "68": "255",
    "70": "42",
    "71": "67",
    "72": "68",
    "73": "109",
    "74": "128",
    "75": "148",
    "76": "160",
    "77": "62",
    "79": "24",
    "80": "130",
    "81": "158",
    "82": "162",
    "83": "102",
    "84": "43",
    "85": "123",
    "86": "44",
    "22": "208",
    "23": "206",
    "24": "197",
    "27": "184",
    "30": "176",
    "90": "167",
    "91": "215",
    "93": "181",
    "96": "191",
    "98": "214",
    "99": "194",
    "100": "207",
    "101": "196",
    "103": "177",
    "104": "188",
    "107": "168",
    "108": "171",
    "110": "192",
    "111": "201",
    "113": "172",
    "114": "218",
    "117": "198",
    "118": "211",
    "120": "193",
}

export const seekerLocationMapping = (locationArr) => {
    const mappedLocation = [];

    locationArr.forEach(item => {
        console.log(item);
        if (seekerLocationMap[item]) {
            seekerLocationMap[item].split(',').map(value => {
                mappedLocation.push(value.trim());
            });
        }
    });

    return mappedLocation;
}

