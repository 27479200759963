import moment from "moment";
import React, { useEffect, useState } from "react";
import getJobsAnalysis from "../../services/getJobsAnalysis";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import { 
    dates,
    categories,
    actions,
    socials
} from "./constants";

const UsersAnalysis = (props) => {
    const [data, setData] = useState({});

    const current = moment().format("YYYY-MM-DD");
    const days = {
        today: moment(current).format("DD-MM-YYYY"),
        yesterday: moment(current).subtract(1, "day").format("DD-MM-YYYY"),
        dayBeforeYesterday: moment(current).subtract(2, "day").format("DD-MM-YYYY"),
        currMonth: moment(current).format("MMM YYYY"),
        lastMonth: moment(current).subtract(1, "month").format("MMM YYYY"),
        lastToLastMonth: moment(current).subtract(2, "month").format("MMM YYYY")
    }

    useEffect(() => {
        user.getProfile().then(profile => {
            getJobsAnalysis(profile.id)
                .then(resp => {
                    setData(resp.data.data);
                }).catch(err => {
                    console.log("Err");
                    console.log(err);
                })
        })
    }, []);

    return (
        <div className="users-analysis">
            <div className="pageHeading">
                <Heading text='Jobs Analysis' />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/jobsanalysis.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>
            <div className="row">
                {
                    data.pjs ?
                        <div>
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{(data.pjs.t || {})[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Dashboard</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{(data.djs.count || {})[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>

                                    {
                                        categories.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.pjs[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }

                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>

            <div className="row">
                {
                    data.alg ?
                        <div>
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        actions.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.alg[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }

                                    <Table.Row>
                                        <Table.Cell>Shared on Linkedin</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{(data.llg.count || {})[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>

                                    {
                                        socials.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.slg[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
        </div>
    )

}

export default UsersAnalysis;