export const companyClassification = [
    { label: 'IT', value: 1 },
    { label: 'NON IT', value: 2 },
    { label: 'Consultant', value: 3 },
];

export const getCompanyClassificationValue = (classification) => {
    if (!classification) {
        return null;
    }

    for (let i = 0; i<companyClassification.length; i++) {
        if (companyClassification[i].label.toLowerCase() == classification.toLowerCase()) {
            return companyClassification[i].value;
        }
    }

    return null;
}
