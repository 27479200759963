import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Table } from 'semantic-ui-react';
import moment from 'moment/moment';

import Heading from '../../components/Heading';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import InputBox from '../../components/InputBox';
import Loader from '../../components/Loader';
import SeekerActionModal from '../../components/SeekerActionModal';

import user from '../../store/user';
import { getSeekerPlansArray } from '../../models/seekerPlans';
import { getQueryParams } from '../../utils/utilFunctions';
import { JOBSEEKER_PLAN_ACTIVE } from '../../models/routes';
import { seekerPlanActivationReasons } from '../../models/seekerPlanActivationReasons';
import { seekerPlanDeactivationReasons } from '../../models/seekerPlanDeactivationReasons';

import getSeekerLogsSubs from '../../services/getSeekerLogsSubs';
import checkSeekerExists from '../../services/checkSeekerExists';
import getSeekerProfile from '../../services/getSeekerProfile';
import getCurrentSubscription from '../../services/getCurrentSubscription';
import getLastActiveSubscription from '../../services/getLastActiveSubscription';
import addSeekerPlan from '../../services/addSeekerPlan';
import deactivateSeekerPlan from '../../services/deactivateSeekerPlan';
import renewSeekerPlan from '../../services/renewSeekerPlan';
import activateSeekerPlan from '../../services/activateSeekerPlan';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

import './styles.scss';

const JobseekerPlanActive = props => {
    const seekerPlans = getSeekerPlansArray();
    const activeSeekerPlans = seekerPlans.filter(item => item.active);

    const currentDate = moment();

    const [adminEmail, setAdminEmail] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [isNewOrder, setIsNewOrder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [actionModalData, setActionModalData] = useState({
        actionType: '',
        data: {},
        isLoading: false,
    });

    const CONTROLLER_KEYS = {
        email: 'emailInput',
        pid: 'productId',
        err: 'message',
        fetch: 'fetchData',
        list: 'seekerList',
        userData: 'userData',
        seekerId: 'seekerId',
        orderId: 'orderId',
        currActiveSub: 'currActiveSub',
        lastActiveSub: 'lastActiveSub',
        response: 'response',
        reason: 'reason',
        paymentGatewayId: 'paymentGatewayId'
    };

    const [searchData, setSearchData] = useState({
        emailInput: '',
        productId: '',
        message: null,
        fetchData: false,
        seekerList: [],
        currActiveSub: null,
        lastActiveSub: null
    });

    const [newOrderData, setNewOrderData] = useState({
        userData: null,
        seekerId: null,
        orderId: null,
        productId: activeSeekerPlans[0].id,
        fetchData: false,
        response: null,
        reason: 0,
        message: null,
        paymentGatewayId: null
    });

    const setNewOrderValues = (keyValueObj) => {
        setNewOrderData(prevObj => {
            const newObj = { ...prevObj };
            for (const key in keyValueObj) {
                newObj[key] = keyValueObj[key];
            }
            return newObj;
        });
    };

    const setSearchValues = (keyValueObj) => {
        setSearchData(prevObj => {
            const newObj = { ...prevObj };
            for (const key in keyValueObj) {
                newObj[key] = keyValueObj[key];
            }
            return newObj;
        });
    };

    // handle dropdown change in search
    const handleDropdownChange = (event) => {
        const pid = event.target.value;
        setSearchValues({ [CONTROLLER_KEYS.pid]: pid });
    }

    // handle input change in search 
    const handleSearchInputChange = (event) => {
        setSearchValues({
            [CONTROLLER_KEYS.email]: event.target.value,
            [CONTROLLER_KEYS.err]: null
        });
    };

    // handle pressing 'Enter' on inputs
    const handleSearchInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const validateSearchData = (email, pid = '') => {

        let errorMsg = null;

        if (pid != '' && isNaN(pid)) {
            errorMsg = 'Product ID entered should be numeric only.';
        }

        const product = seekerPlans.find(it => it.id == pid);

        if (pid != '' && (!product || product.length < 1)) {
            errorMsg = 'Product ID entered is not a valid ID.'
        }

        if (!email) {
            errorMsg = 'Seeker Email is required.';
        }

        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (!emailRegex.test(email)) {
            errorMsg = 'Seeker Email is not valid.'
        }

        if (errorMsg) {
            setSearchValues({
                [CONTROLLER_KEYS.err]: errorMsg
            });
            return false;
        }

        return true;
    }

    // handle search form submit
    const handleSearchSubmit = () => {
        if (validateSearchData(searchData.emailInput, searchData.productId)) {
            updateQueryParams();
            setSearchValues({
                [CONTROLLER_KEYS.fetch]: true
            });
        }
    };

    const getSearchParams = () => {
        return {
            email: searchData.emailInput,
            pid: searchData.productId === '' ? null : searchData.productId
        };
    };

    // get seeker subscription logs 
    const getSeekerLogsSubsData = async () => {
        setIsLoading(true);

        try {
            const params = getSearchParams();

            const response = await getSeekerLogsSubs(adminId, params);
            const runningSubs = await getCurrentSubscription(adminId, { seekerEmail: params.email });
            const lastActiveSub = await getLastActiveSubscription(adminId, { seekerEmail: params.email });

            let msg = null;
            if (response && response.data && response.data.data && response.data.data.data) {
                if (response.data.data.data.length > 0) {
                    const userid = response.data.data.data[0].userid;
                    msg = `<br>If you want to create new order for this user <a href="${JOBSEEKER_PLAN_ACTIVE}?action=neworder&userid=${userid}">CLICK HERE</a>`;
                } else {
                    msg = 'No Record found in order log and subscription';

                    const seekerExists = await checkSeekerExists(adminId, { email: params.email });
                    const seekerProfile = await getSeekerProfile(adminId, { seekerEmail: params.email });

                    if (!seekerExists) {
                        if (!seekerProfile) {
                            msg += '<br>This is not a registered email.';
                        } else {
                            msg += `<br>If you want to create new order for this user <a href="${JOBSEEKER_PLAN_ACTIVE}?action=neworder&userid=${seekerProfile.id}">CLICK HERE</a>`;
                        }
                    } else {
                        if (!seekerProfile) {
                            msg += '<br>This is not a registered email.';
                        } else {
                            msg += '<br>Email exists, but log may not be present for product id ,';
                            msg += `<br> If you want to create a new order for this user <a href="${JOBSEEKER_PLAN_ACTIVE}?action=neworder&userid=${seekerProfile.id}">CLICK HERE</a>`;
                        }
                    }
                }
            }

            setSearchValues({
                [CONTROLLER_KEYS.err]: msg,
                [CONTROLLER_KEYS.list]: response.data.data.data,
                [CONTROLLER_KEYS.currActiveSub]: runningSubs.plan ? runningSubs.plan : null,
                [CONTROLLER_KEYS.lastActiveSub]: lastActiveSub.plan ? lastActiveSub.plan : null
            });
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong! Please try again later');
        }

        setIsNewOrder(false);
        setIsLoading(false);
    };

    // get jobseeker profile data
    const fetchSeekerData = async () => {
        setIsLoading(true);

        try {
            const userDetails = await getSeekerProfile(adminId, {
                seekerId: newOrderData.seekerId
            });

            setNewOrderValues({
                [CONTROLLER_KEYS.userData]: {
                    id: userDetails.id,
                    email: userDetails.email,
                    name: userDetails.name,
                },
                [CONTROLLER_KEYS.orderId]: `${moment().unix()}j${userDetails.id}`
            });

            setSearchValues({
                [CONTROLLER_KEYS.email]: userDetails.email
            });
        } catch (error) {
            toast.error('Could not fetch seeker profile! Please try again later.');
        }
        setIsLoading(false);
    };

    // update query params if search clicked
    const updateQueryParams = () => {
        if (searchData.emailInput) {
            props.history.push(`${JOBSEEKER_PLAN_ACTIVE}?email=${searchData.emailInput}`);
        }
    };

    const handleSubmitNewOrder = async () => {

        if (!newOrderData.reason) {
            setNewOrderValues({
                [CONTROLLER_KEYS.err]: 'Please select reason for activation'
            });
            return;
        }

        if ([1, 2].includes(+newOrderData.reason) && (!newOrderData.paymentGatewayId || !newOrderData.paymentGatewayId.trim())) {
            setNewOrderValues({
                [CONTROLLER_KEYS.err]: 'Please enter payment gateway id'
            });
            return;
        }

        setIsLoading(true);

        try {
            const pid = newOrderData.productId ? newOrderData.productId : 50;
            const data = {};
            data['orderId'] = newOrderData.orderId;
            data['userId'] = newOrderData.seekerId;
            data['email'] = newOrderData.userData.email;
            data['productId'] = pid;
            data['recruiterId'] = adminId;
            data['recruiterEmail'] = adminEmail;
            data['reason'] = newOrderData.reason;
            data['paymentGatewayId'] = newOrderData.paymentGatewayId;

            const selectedProduct = getSeekerPlansArray(true).find(item => item.id == pid);

            if (!selectedProduct || selectedProduct.length < 1) {
                toast.error('Invalid product id!');
                return;
            }

            data['amount'] = selectedProduct.price;

            const response = await addSeekerPlan(adminId, data);

            toast.success('Order created successfully');

            setNewOrderValues({
                [CONTROLLER_KEYS.response]: response.data.data
            });
        } catch (error) {
            // console.log(error);
            toast.error('Failed to add new order. Please try again later!');
        }

        setIsLoading(false);
    };

    const deactivateOrder = async (subsId, reason) => {
        try {
            await deactivateSeekerPlan(adminId, {
                recruiterId: adminId,
                recruiterEmail: adminEmail,
                subsId,
                reason,
            });

            toast.success('Order deactivated successfully');

            setSearchValues({
                [CONTROLLER_KEYS.fetch]: true
            });

            setActionModalData({ actionType: null, data: { reason: 0 }, isLoading: false });

        } catch (err) {
            toast.error('Failed to deactivate order. Please try again later');
        }
    };

    const renewOrder = async (logId, subsId) => {
        try {
            const response = await renewSeekerPlan(adminId, {
                recruiterId: adminId,
                recruiterEmail: adminEmail,
                logId,
                subsId,
            });

            toast.success('Order renewed successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to renew order. Please try again later');
        }
    };

    const activateOrder = async (logId, reason, paymentGatewayId) => {
        try {
            const response = await activateSeekerPlan(adminId, {
                recruiterId: adminId,
                recruiterEmail: adminEmail,
                logId,
                reason,
                paymentGatewayId
            });

            setActionModalData({ actionType: null, data: { reason: 0 }, isLoading: false });

            toast.success('Order activated successfully');
        } catch (error) {
            // console.log(error);
            toast.error('Failed to activate order. Please try again later');
        }
    };

    const handleOnActionClick = async (action, data) => {
        if (action === 'deactivate') {
            setActionModalData({
                actionType: 'deactivate',
                data: {
                    reason: null,
                    subsId: data.subsId,
                },
                // if activation reason is 4 i.e. testing, then deactivation reason
                // must also be 4 i.e. testing
                reasons: data.initialReason && data.initialReason == 4 ? seekerPlanDeactivationReasons.filter(item => item.id == 0 || item.id == 4) : seekerPlanDeactivationReasons
            });
            return;
        } else if (action === 'activate') {
            setActionModalData({
                actionType: 'activate',
                data: {
                    reason: null,
                    logId: data.logId,
                },
                reasons: seekerPlanActivationReasons
            });
            return;
        }
    }

    const onActionModalClose = () => {
        setActionModalData({});
    };

    const onActionModalSubmit = (id) => {
        setActionModalData(prevObj => {
            const newObj = { ...prevObj };
            newObj.isLoading = true;
            return newObj;
        });

        if (actionModalData.actionType === 'deactivate') {
            deactivateOrder(actionModalData.data.subsId, actionModalData.data.reason);
        } else if (actionModalData.actionType === 'activate') {
            activateOrder(actionModalData.data.logId, actionModalData.data.reason, id);
        }
    };

    const onActionSelectReason = (reason) => {
        setActionModalData(prevObj => {
            const newObj = { ...prevObj };
            newObj.data.reason = reason;
            return newObj;
        });
    };

    // fetch admin user and check page access
    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        getProfile().then(response => {
            if (response.adminType == 2 || response.adminType == 3) {

                // restrict access
                if (!user.checkFeatureAccess(adminFeatureMapping.SEEKER_PLAN_ACTIVATE)) {
                    window.location.href = '/notAdmin';
                }

                setAdminEmail(response.email);
                setAdminId(response.id);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        }).catch(err => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        });

    }, []);

    useEffect(() => {
        // call fetch seeker logs and subs
        if (searchData.fetchData) {
            getSeekerLogsSubsData();
            setSearchValues({
                [CONTROLLER_KEYS.fetch]: false
            });
        }
    }, [searchData.fetchData]);

    useEffect(() => {
        // call fetch seeker logs and subs
        if (newOrderData.fetchData) {
            fetchSeekerData();
            setNewOrderValues({
                [CONTROLLER_KEYS.fetch]: false
            });
        }
    }, [newOrderData.fetchData]);

    useEffect(() => {
        if (adminId) {
            const params = getQueryParams(props.location.search);

            if (params.action == 'neworder' && params.userid) {
                setIsNewOrder(true);
                setIsLoading(true);
                setNewOrderValues({
                    [CONTROLLER_KEYS.seekerId]: params.userid,
                    [CONTROLLER_KEYS.fetch]: true
                });
            } else if (params.email) {
                if (validateSearchData(params.email, params.pid)) {
                    setSearchValues({
                        [CONTROLLER_KEYS.email]: params.email,
                        [CONTROLLER_KEYS.pid]: params.pid || '',
                        [CONTROLLER_KEYS.fetch]: true
                    });
                }
            }
        }
    }, [adminId]);

    const tableHeading = (
        <Table.Header>
            <Table.HeaderCell>Seeker ID</Table.HeaderCell>
            <Table.HeaderCell>Order ID</Table.HeaderCell>
            <Table.HeaderCell>Plan ID</Table.HeaderCell>
            <Table.HeaderCell>Log Date From IP Address</Table.HeaderCell>
            <Table.HeaderCell>Response Code - Description</Table.HeaderCell>
            <Table.HeaderCell>Dates: From - To</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Header>
    )

    let mainContent = null, noNewOrderDone = false;

    if (isNewOrder) {
        if (isLoading) {
            mainContent = <Loader />
        } else if (newOrderData.response) {
            const selectedProduct = getSeekerPlansArray(true).find(item => item.id == newOrderData.productId);

            const productID = `${selectedProduct.value}, ID ${selectedProduct.id}`;

            mainContent = (
                <div className='neworder__response'>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>ID</Table.Cell>
                                <Table.Cell>{newOrderData.response.adminLogId}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>User ID</Table.Cell>
                                <Table.Cell>{newOrderData.seekerId}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Order ID</Table.Cell>
                                <Table.Cell>{newOrderData.orderId}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Product ID</Table.Cell>
                                <Table.Cell>{productID}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Email</Table.Cell>
                                <Table.Cell>{newOrderData.userData.email}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Start Date:</Table.Cell>
                                <Table.Cell>{moment().format('DD-MM-YYYY')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>End Date:</Table.Cell>
                                <Table.Cell>{moment().add(selectedProduct.validity, 'months').format('DD-MM-YYYY')}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            )
        } else {
            mainContent = (
                <div className='neworder__container'>
                    <div className='heading'>Seeker Plans Information</div>
                    <div className='input-row'>
                        <div className='label'>User Name</div>
                        <div className='value'>
                            <InputBox
                                value={newOrderData && newOrderData.userData ? newOrderData.userData.name : ''}
                                options={{
                                    readOnly: true
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>User Email</div>
                        <div className='value'>
                            <InputBox
                                value={newOrderData && newOrderData.userData ? newOrderData.userData.email : ''}
                                options={{
                                    readOnly: true
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>Plan ID</div>
                        <div className='value'>
                            <Dropdown
                                data={activeSeekerPlans.map(plan => {
                                    return {
                                        id: plan.id,
                                        name: plan.value
                                    }
                                })}
                                dropdownChange={event => {
                                    setNewOrderValues({
                                        [CONTROLLER_KEYS.pid]: event.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>Reason for Activation:</div>
                        <div className='value'>
                            <Dropdown
                                data={seekerPlanActivationReasons}
                                dropdownChange={event => {
                                    setNewOrderValues({
                                        [CONTROLLER_KEYS.reason]: event.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>Payment Gateway ID:</div>
                        <div className='value'>
                            <InputBox
                                type="text"
                                placeholder="Enter Payment Gateway ID"
                                value={newOrderData && (newOrderData.paymentGatewayId || '')}
                                onChange={event => {
                                    setNewOrderValues({
                                        [CONTROLLER_KEYS.paymentGatewayId]: event.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>Order ID</div>
                        <div className='value'>
                            <InputBox
                                value={newOrderData && newOrderData.orderId ? newOrderData.orderId : ''}
                                options={{
                                    readOnly: true
                                }}
                            />
                        </div>
                    </div>
                    {newOrderData.message ? <div className='error-text'>{newOrderData.message}</div> : null}
                    <div className='input-row'>
                        <div className='label'></div>
                        <Button text="Submit" skin="dark" clickButton={handleSubmitNewOrder} />
                    </div>
                </div>
            )
        }
    } else if (isLoading) {
        mainContent = <Loader />
    } else if (!isLoading && searchData.seekerList && searchData.seekerList.length > 0) {
        mainContent = (
            <div className='table__container'>
                <div className='heading'>Seeker Plans Information</div>
                <Table className="table__container--body" celled>
                    {tableHeading}
                    <Table.Body>
                        {searchData.seekerList.map(item => {
                            const actions = [];
                            const pid = item.product_id;
                            const product = seekerPlans.find(it => it.id == pid);
                            const dateCreated = moment(item.date_created).format('DD-MMM-YYYY HH:mm:ss');

                            let startDate = null, endDate = null;
                            if (moment(item.start_date).isValid()) {
                                startDate = moment(item.start_date).format('DD-MM-YYYY HH:mm:ss');
                            }

                            if (moment(item.end_date).isValid()) {
                                endDate = moment(item.end_date).format('DD-MM-YYYY HH:mm:ss');
                            }

                            let logStatus = '', remain = null, diff = 0;

                            if (item.log_status == 0) {
                                if (item.subs_status == null) {

                                    if (searchData.currActiveSub) {
                                        if (moment(item.date_created) > moment(searchData.currActiveSub.start_date)) {
                                            // Renewing order ID: currActiveSub.orderid and activating with item.orderid 
                                            // Remove renew option
                                            // actions.push(
                                            //     <div className='action-link' onClick={() => handleOnActionClick('renew', { logId: item.log_id, subsId: searchData.currActiveSub.id })}>Renew Order</div>
                                            // );
                                        } else {
                                            if (!noNewOrderDone) {
                                                actions.push(
                                                    <div>No new order</div>
                                                );
                                            }
                                            noNewOrderDone = true;

                                        }
                                    } else if (searchData.lastActiveSub) {
                                        if (moment(item.date_created) > moment(searchData.lastActiveSub.start_date)) {
                                            // Activating with item.orderid
                                            actions.push(
                                                <div className='action-link' onClick={() => handleOnActionClick('activate', { logId: item.log_id })}>Activate Order</div>
                                            )
                                        }
                                    }

                                    logStatus = 'Order not used.';
                                } else {
                                    logStatus = 'Order not used but present in subscription check entry in DB';
                                }
                            } else if (item.log_status == 1) {
                                if (item.end_date == null) {
                                    remain = 'NULL';
                                } else {
                                    if (moment(item.end_date) > currentDate) {
                                        diff = Math.round(moment(item.end_date).diff(currentDate, 'days', true));
                                        remain = `${diff} days to expire`;
                                    } else if (currentDate > moment(item.end_date)) {
                                        remain = `curr = ${currentDate.format('YYYY-MM-DD HH:mm:ss')} end = ${moment(item.end_date).format('YYYY-MM-DD HH:mm:ss')} date exceeded`;
                                    }
                                }

                                if (item.subs_status != null) {
                                    logStatus = 'Order missed not in subscription check entry in DB';
                                }

                                if (item.subs_status == 0) {
                                    logStatus = `${remain} and Subscription Stopped`;
                                } else if (item.subs_status == 1) {
                                    logStatus = `${remain} and Subscription Active`;
                                }
                            }

                            if (searchData.currActiveSub && searchData.currActiveSub.orderid == item.orderid && currentDate < moment(item.end_date)) {
                                // current subscription
                                actions.push(
                                    <div className='action-link' onClick={() => handleOnActionClick('deactivate', { subsId: item.subs_id, initialReason: item.reason })}>De Activate Order</div>
                                );
                            }

                            return (
                                <Table.Row>
                                    <Table.Cell verticalAlign="top">
                                        <div>
                                            <div>{item.userid}</div>
                                            <div>{item.email}</div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top">{item.orderid}</Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        <div>
                                            <div>{product.value}</div>
                                            <div>( {pid} )</div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        {dateCreated} from {item.ip_address}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        {item.response_code} - {item.response_desc}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        <div>
                                            <div>{startDate}</div>
                                            <div>{endDate}</div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top">{logStatus}</Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        {actions.map(it => {
                                            return it;
                                        })}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        )
    };

    return (
        <div className='page-content jobseeker-plan-active'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className='page-heading'>
                <Heading text="Jobseeker Transaction Fail Plan Activation" />
                {/* <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/jobseeker_plan_active.php`} target="_blank">
                    <Button skin="dark" text="Use Old Version"/> 
                </a> */}
            </div>

            <div className='search__controller'>
                <div className='search__controller--input'>
                    <div className='input-row'>
                        <div className='label'>Product ID</div>
                        <div className='value'>
                            <Dropdown
                                data={seekerPlans.map(plan => {
                                    return {
                                        id: plan.id,
                                        name: plan.value
                                    }
                                })}
                                dropdownChange={handleDropdownChange}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'>Seeker Email</div>
                        <div className='value'>
                            <InputBox
                                type="email"
                                placeholder="Enter Job Seeker Email"
                                value={searchData.emailInput}
                                onChange={handleSearchInputChange}
                                onKeyPress={handleSearchInputKeyPress}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='label'></div>
                        <div className='value'>
                            <Button text="Search" skin="dark" clickButton={handleSearchSubmit} />
                        </div>
                    </div>
                </div>

                <div
                    dangerouslySetInnerHTML={{ __html: searchData.message }}
                    className='search__controller--message'>
                </div>
            </div>

            <div className='page-body'>
                {mainContent}
            </div>

            {actionModalData && actionModalData.actionType === 'deactivate' ? (
                <SeekerActionModal
                    type="deactivate"
                    errorMsg="Please select reason for deactivation"
                    onClose={onActionModalClose}
                    onActionSubmit={onActionModalSubmit}
                    reason={actionModalData.data.reason}
                    reasonsDropdownList={actionModalData.reasons}
                    setReason={onActionSelectReason}
                    isLoading={actionModalData.isLoading}
                />
            ) : null}

            {actionModalData && actionModalData.actionType === 'activate' ? (
                <SeekerActionModal
                    type="activate"
                    errorMsg="Please select reason for activation"
                    onClose={onActionModalClose}
                    onActionSubmit={onActionModalSubmit}
                    reason={actionModalData.data.reason}
                    reasonsDropdownList={actionModalData.reasons}
                    setReason={onActionSelectReason}
                    isLoading={actionModalData.isLoading}
                />
            ) : null}
        </div>
    );
};

export default JobseekerPlanActive;