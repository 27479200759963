import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';

import './Pagination.scss';

const paginationUi = (props) => {
    return (
        <Pagination
            itemClass="items-display"	
            itemClassFirst="item-first"
            itemClassPrev="item-prev"
            itemClassNext="item-next"
            itemClassLast="item-last"
            hideDisabled
            prevPageText='« Prev'
            nextPageText='Next »'
            firstPageText='« Start'
            lastPageText='End »'
            activePage={+(props.pageNumber || '')}
            itemsCountPerPage={props.content}
            totalItemsCount={props.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={props.handlePageChange.bind(this)}
        />
    )
}

paginationUi.propTypes = {
    pageNumber: PropTypes.number,
    content: PropTypes.number,
    totalItemsCount: PropTypes.number,
    handlePageChange: PropTypes.func
}

export default paginationUi;
