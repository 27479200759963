var instituteMetaData = {
  "data-attribute": "menu-institute",
  "name": "institute"
}

var instituteTagsDataIimjobs = [
  {
      "text": "Indian Institute of Management, Ahmedabad (IIMA)"
      , "val": 1
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Bangalore (IIMB)"
      , "val": 2
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Calcutta (IIMC)"
      , "val": 3
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Indore (IIMI)"
      , "val": 4
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Kozhikode (IIMK)"
      , "val": 5
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Lucknow (IIML)"
      , "val": 6
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Shillong (IIMS)"
      , "val": 45
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Rohtak (IIMRohtak)"
      , "val": 46
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Ranchi (IIMR)"
      , "val": 47
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Raipur (IIMRaipur)"
      , "val": 48
      , "isTop": ""
  },
  {
      "text": "Indian Institute of Management Tiruchirappalli (IIMT)"
      , "val": 49
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Udaipur (IIMU)"
      , "val": 50
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management Kashipur (IIMKashipur)"
      , "val": 51
      , "isTop": "topinst"
  },
  {
      "text": "Jamnalal Bajaj Institute of Management Studies (JBIMS)"
      , "val": 52
      , "isTop": "topinst"
  },
  {
      "text": "Birla Institute of Technology and Science (BITS), Pilani"
      , "val": 53
      , "isTop": "topinst"
  },
  {
      "text": "Birla Institute of Technology, Mesra"
      , "val": 160
      , "isTop": ""
  },
  {
      "text": "Institute of Chartered Accountants of India (ICAI)"
      , "val": 55
      , "isTop": "topinst"
  },
  {
      "text": "Indian School of Business, Hyderabad (ISB)"
      , "val": 7
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Delhi (IITD)"
      , "val": 8
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Bombay (IITB)"
      , "val": 9
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Madras (IITM)"
      , "val": 10
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Roorkee (IITR)"
      , "val": 11
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Kanpur (IITK)"
      , "val": 12
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Kharagpur (IITKGP)"
      , "val": 13
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Guwahati (IITG)"
      , "val": 54
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Varanasi (IIT BHU)"
      , "val": 89
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Bhubaneswar (IITBBS)"
      , "val": 93
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Gandhinagar (IITGN)"
      , "val": 94
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Hyderabad (IITH)"
      , "val": 95
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Indore (IITI)"
      , "val": 96
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Jodhpur (IITJ)"
      , "val": 97
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Mandi (IITMandi)"
      , "val": 98
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Patna (IITP)"
      , "val": 99
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Technology, Ropar (IITRPR)"
      , "val": 100
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Science (IISc)"
      , "val": 57
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Company Secretaries of India (ICSI)"
      , "val": 101
      , "isTop": "topinst"
  },
  {
      "text": "B. S. Abdur Rahman University"
      , "val": 102
      , "isTop": ""
  },
  {
      "text": "Anna University"
      , "val": 103
      , "isTop": ""
  },
  {
      "text": "Visvesvaraya Technological University"
      , "val": 104
      , "isTop": ""
  },
  {
      "text": "Birsa Institute of Technology Sindri"
      , "val": 105
      , "isTop": ""
  },
  {
      "text": "Osmania University"
      , "val": 106
      , "isTop": ""
  },
  {
      "text": "College of Engineering, Guindy"
      , "val": 107
      , "isTop": ""
  },
  {
      "text": "University of Mumbai"
      , "val": 108
      , "isTop": ""
  },
  {
      "text": "Uttarakhand Technical University"
      , "val": 109
      , "isTop": ""
  },
  {
      "text": "Delhi Technological University"
      , "val": 110
      , "isTop": "topinst"
  },
  {
      "text": "Dhirubhai Ambani Institute of Information and Communication Technology"
      , "val": 111
      , "isTop": ""
  },
  {
      "text": "Malaviya National Institute of Technology, Jaipur"
      , "val": 112
      , "isTop": ""
  },
  {
      "text": "Government College of Engineering, Amravati"
      , "val": 113
      , "isTop": ""
  },
  {
      "text": "Harcourt Butler Technological Institute Kanpur (HBTI)"
      , "val": 114
      , "isTop": ""
  },
  {
      "text": "Walchand Institute of Technology"
      , "val": 115
      , "isTop": ""
  },
  {
      "text": "Guru Gobind Singh Indraprastha University"
      , "val": 116
      , "isTop": ""
  },
  {
      "text": "Indian Institute of Information Technology, Allahabad (IITA)"
      , "val": 117
      , "isTop": ""
  },
  {
      "text": "IIT, Dhanbad"
      , "val": 118
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Technology, Nirma University"
      , "val": 119
      , "isTop": ""
  },
  {
      "text": "International Institute of Information Technology, Hyderabad"
      , "val": 120
      , "isTop": ""
  },
  {
      "text": "University of Pune"
      , "val": 121
      , "isTop": ""
  },
  {
      "text": "Manipal Institute of Technology Manipal University"
      , "val": 122
      , "isTop": ""
  },
  {
      "text": "M.S.Ramaiah Institute of Technology"
      , "val": 123
      , "isTop": ""
  },
  {
      "text": "Mepco Schlenk Engineering College"
      , "val": 124
      , "isTop": ""
  },
  {
      "text": "Model Engineering College"
      , "val": 125
      , "isTop": ""
  },
  {
      "text": "Motilal Nehru National Institute of Technology, Allahabad (NIT)"
      , "val": 126
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Construction Management and Research"
      , "val": 159
      , "isTop": ""
  },
  {
      "text": "National Institute of Engineering Visvesvaraya Technological University"
      , "val": 127
      , "isTop": ""
  },
  {
      "text": "National Institute of Technology Srinagar (NIT)"
      , "val": 128
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Calicut (NIT)"
      , "val": 129
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Durgapur (NIT)"
      , "val": 130
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Hamirpur (NIT)"
      , "val": 131
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology Karnataka (NIT)"
      , "val": 132
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Rourkela (NIT)"
      , "val": 133
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Silchar (NIT)"
      , "val": 134
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Tiruchirappalli (NIT)"
      , "val": 135
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Warangal (NIT)"
      , "val": 136
      , "isTop": "topinst"
  },
  {
      "text": "Netaji Subhas Institute of Technology (NSIT)"
      , "val": 137
      , "isTop": "topinst"
  },
  {
      "text": "P.S.G. College of Technology"
      , "val": 138
      , "isTop": ""
  },
  {
      "text": "P.E.S. College of Engineering"
      , "val": 139
      , "isTop": ""
  },
  {
      "text": "P.E.S. Institute of Technology"
      , "val": 140
      , "isTop": ""
  },
  {
      "text": "PEC University of Technology"
      , "val": 141
      , "isTop": ""
  },
  {
      "text": "R.V. College of Engineering"
      , "val": 142
      , "isTop": ""
  },
  {
      "text": "Rajagiri School of Engineering & Technology"
      , "val": 143
      , "isTop": ""
  },
  {
      "text": "Sardar Vallabhbhai National Institute of Technology, Surat"
      , "val": 144
      , "isTop": ""
  },
  {
      "text": "Shri Mata Vaishno Devi University"
      , "val": 145
      , "isTop": ""
  },
  {
      "text": "Sir M. Visvesvaraya Institute of Technology"
      , "val": 146
      , "isTop": ""
  },
  {
      "text": "SSN College of Engineering"
      , "val": 147
      , "isTop": ""
  },
  {
      "text": "SRM University"
      , "val": 148
      , "isTop": ""
  },
  {
      "text": "Thadomal Shahani Engineering College"
      , "val": 149
      , "isTop": ""
  },
  {
      "text": "Thapar University"
      , "val": 150
      , "isTop": ""
  },
  {
      "text": "Veermata Jijabai Technological Institute"
      , "val": 151
      , "isTop": ""
  },
  {
      "text": "Vellore Institute of Technology"
      , "val": 152
      , "isTop": ""
  },
  {
      "text": "Visvesvaraya National Institute of Technology"
      , "val": 153
      , "isTop": ""
  },
  {
      "text": "West Bengal University of Technology"
      , "val": 154
      , "isTop": ""
  },
  {
      "text": "Xavier Institute Of Management - Bhubaneswar (XIMB)"
      , "val": 56
      , "isTop": "topinst"
  },
  {
      "text": "Xavier Institute of Management and Entrepreneurship (XIME)"
      , "val": 62
      , "isTop": ""
  },
  {
      "text": "XLRI - Xavier School Of Management, Jamshedpur (XLRI)"
      , "val": 14
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Cost and Management Accountants of India (ICMAI)"
      , "val": 86
      , "isTop": ""
  },
  {
      "text": "Management Development Institute (MDI)"
      , "val": 15
      , "isTop": "topinst"
  },
  {
      "text": "Narsee Monjee Institute of Management Studies (NMIMS)"
      , "val": 16
      , "isTop": "topinst"
  },
  {
      "text": "Faculty of Management Studies (FMS) - Delhi University"
      , "val": 17
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Management, Mumbai (IIM Mumbai formerly known as NITIE)"
      , "val": 18
      , "isTop": "topinst"
  },
  {
      "text": "SP Jain Institute of Management - Research (SPJIMR)"
      , "val": 19
      , "isTop": "topinst"
  },
  {
      "text": "Symbiosis Institute of Business Management (SIBM)"
      , "val": 20
      , "isTop": "topinst"
  },
  {
      "text": "TA Pai Management Institute (TAPMI)"
      , "val": 21
      , "isTop": "topinst"
  },
  {
      "text": "Tata Institute of Social Sciences (TISS)"
      , "val": 22
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Foreign Trade (IIFT)"
      , "val": 23
      , "isTop": "topinst"
  },
  {
      "text": "Mudra Institute of Communications, Ahmedabad (MICA)"
      , "val": 24
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Rural Management Anand (IRMA)"
      , "val": 25
      , "isTop": ""
  },
  {
      "text": "University Business School, Chandigarh (UBS)"
      , "val": 26
      , "isTop": ""
  },
  {
      "text": "Symbiosis Centre for Management and Human Resource Development (SCMHRD)"
      , "val": 27
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Management Technology (IMT), Ghaziabad"
      , "val": 28
      , "isTop": "topinst"
  },
  {
      "text": "International Management Institute (IMI), New Delhi"
      , "val": 29
      , "isTop": "topinst"
  },
  {
      "text": "Birla Institute of Management Technology (BIMTECH), Greater Noida"
      , "val": 155
      , "isTop": ""
  },
  {
      "text": "Lal Bahadur Shastri Institute of Management (LBSIM), New Delhi"
      , "val": 156
      , "isTop": ""
  },
  {
      "text": "Fore School of Management Delhi"
      , "val": 30
      , "isTop": ""
  },
  {
      "text": "KJ Somaiya Institute of Management Studies and Research, Mumbai"
      , "val": 31
      , "isTop": "topinst"
  },
  {
      "text": "ICFAI Business School (IBS), Hyderabad"
      , "val": 32
      , "isTop": ""
  },
  {
      "text": "ICFAI, Gurgaon"
      , "val": 33
      , "isTop": ""
  },
  {
      "text": "Institute of Management Technology (IMT), Nagpur"
      , "val": 34
      , "isTop": ""
  },
  {
      "text": "Symbiosis Institute of International Business, Pune"
      , "val": 35
      , "isTop": ""
  },
  {
      "text": "ISB&M Pune"
      , "val": 36
      , "isTop": ""
  },
  {
      "text": "Loyola Institute of Business Administration (LIBA), Chennai"
      , "val": 37
      , "isTop": ""
  },
  {
      "text": "BIM Trichy"
      , "val": 38
      , "isTop": ""
  },
  {
      "text": "Lal Bahadur Shastri Institute of Management (LBSIM), New Delhi"
      , "val": 39
      , "isTop": ""
  },
  {
      "text": "Institute of Management, Nirma University, Ahmedabad"
      , "val": 40
      , "isTop": ""
  },
  {
      "text": "SIMS"
      , "val": 41
      , "isTop": ""
  },
  {
      "text": "Welingkar Institute of Management Development & Research, Mumbai"
      , "val": 42
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Social Welfare and Business Management (IISWBM), Kolkata"
      , "val": 43
      , "isTop": ""
  },
  {
      "text": "Delhi School of Economics (DSE)"
      , "val": 59
      , "isTop": "topinst"
  },
  {
      "text": "Great Lakes Institute of Management"
      , "val": 60
      , "isTop": ""
  },
  {
      "text": "Institute for Financial Management and Research (IFMR)"
      , "val": 61
      , "isTop": ""
  },
  {
      "text": "Columbia Business School"
      , "val": 63
      , "isTop": "topinst"
  },
  {
      "text": "Harvard Business School"
      , "val": 64
      , "isTop": "topinst"
  },
  {
      "text": "Johnson Graduate School of Management, Cornell University"
      , "val": 65
      , "isTop": "topinst"
  },
  {
      "text": "The Wharton School of the University of Pennsylvania"
      , "val": 66
      , "isTop": "topinst"
  },
  {
      "text": "Tuck School of Business at Dartmouth"
      , "val": 67
      , "isTop": "topinst"
  },
  {
      "text": "Yale School of Management"
      , "val": 68
      , "isTop": "topinst"
  },
  {
      "text": "INSEAD (France or Singapore Campus)"
      , "val": 69
      , "isTop": "topinst"
  },
  {
      "text": "Kellogg School of Management - Northwestern University"
      , "val": 70
      , "isTop": "topinst"
  },
  {
      "text": "London Business School, UK"
      , "val": 71
      , "isTop": "topinst"
  },
  {
      "text": "London School of Economics (LSE)"
      , "val": 87
      , "isTop": "topinst"
  },
  {
      "text": "MIT Sloan School of Management"
      , "val": 72
      , "isTop": "topinst"
  },
  {
      "text": "NYU Stern School of Business"
      , "val": 73
      , "isTop": "topinst"
  },
  {
      "text": "Stanford Graduate School of Business"
      , "val": 74
      , "isTop": "topinst"
  },
  {
      "text": "ESCP Europe Business School"
      , "val": 75
      , "isTop": "topinst"
  },
  {
      "text": "University of Oxford - Said, UK"
      , "val": 76
      , "isTop": "topinst"
  },
  {
      "text": "University of Cambridge - Judge, UK"
      , "val": 77
      , "isTop": "topinst"
  },
  {
      "text": "IE Business School, Spain"
      , "val": 78
      , "isTop": "topinst"
  },
  {
      "text": "HEC Paris, France"
      , "val": 79
      , "isTop": "topinst"
  },
  {
      "text": "Esade Business School, Spain"
      , "val": 80
      , "isTop": "topinst"
  },
  {
      "text": "Iese Business School, Spain"
      , "val": 81
      , "isTop": "topinst"
  },
  {
      "text": "IMD, Switzerland"
      , "val": 82
      , "isTop": "topinst"
  },
  {
      "text": "University of St Gallen, Switzerland"
      , "val": 83
      , "isTop": "topinst"
  },
  {
      "text": "Rotterdam School of Management, Erasmus University, Netherlands"
      , "val": 84
      , "isTop": "topinst"
  },
  {
      "text": "SDA Bocconi, Italy"
      , "val": 85
      , "isTop": "topinst"
  },
  {
      "text": "Asian Institute of Management (AIM), Philippines"
      , "val": 88
      , "isTop": "topinst"
  },
  {
      "text": "Haas School of Business, University of California Berkeley"
      , "val": 90
      , "isTop": "topinst"
  },
  {
      "text": "National University Of Singapore(NUS) Business School"
      , "val": 91
      , "isTop": "topinst"
  },
  {
      "text": "Institute of Management Technology (IMT) Dubai"
      , "val": 92
      , "isTop": ""
  },
  {
      "text": "Department of Financial Studies, University of Delhi"
      , "val": 157
      , "isTop": ""
  },
  {
      "text": "Goa Institute of Management (GIM)"
      , "val": 158
      , "isTop": ""
  },
  {
      "text": "Dr. B. R. Ambedkar National Institute of Technology (NIT) Jalandhar"
      , "val": 161
      , "isTop": ""
  },
  {
      "text": "MYRA School of Business"
      , "val": 162
      , "isTop": ""
  },
  {
      "text": "Maulana Azad National Institute of Technology (MANIT) Bhopal"
      , "val": 163
      , "isTop": "topinst"
  },
  {
      "text": "National Institute of Technology, Kurukshetra (NIT KKR)"
      , "val": 164
      , "isTop": "topinst"
  },
  {
      "text": "Faculty of Management Studies, Banaras Hindu University (FMS, BHU)"
      , "val": 165
      , "isTop": ""
  },
  {
      "text": "National Institute of Technology (NIT), Patna"
      , "val": 166
      , "isTop": ""
  },
  {
      "text": "Indian Institute of Management, Visakhapatnam"
      , "val": 167
      , "isTop": "topinst"
  },
  {
      "text": "Indian Institute of Forest Management, Bhopal"
      , "val": 168
      , "isTop": "topinst"
  },
  {
      "text": "Other"
      , "val": 44
      , "isTop": ""
  },
  {
      "text": "IIM-Nagpur"
      , "val": 169
      , "isTop": "topinst"
  },
  {
      "text": "IIM-Vishakhapatnam"
      , "val": 170
      , "isTop": "topinst"
  },
  {
      "text": "IFIM Business School, Bangalore"
      , "val": 171
      , "isTop": ""
  },
  {
      "text": "VES Institute of Management Studies and Research"
      , "val": 172
      , "isTop": ""
  },
  {
      "text": "IIM Sirmaur"
      , "val": 173
      , "isTop": "topinst"
  },
  {
      "text": "Indraprastha Institute of Information Technology"
      , "val": 174
      , "isTop": ""
  },
  {
      "text": "IIM Amritsar"
      , "val": 175
      , "isTop": "topinst"
  },
  {
      "text": "National Law University, Bangalore"
      , "val": 176
      , "isTop": "lawinst"
  },
  {
      "text": "National Law Institute University, Bhopal"
      , "val": 177
      , "isTop": "lawinst"
  },
  {
      "text": "ILS Law College, Pune"
      , "val": 178
      , "isTop": "lawinst"
  },
  {
      "text": "Gujarat National Law University"
      , "val": 179
      , "isTop": "lawinst"
  },
  {
      "text": "Symbiosis Law School, Pune"
      , "val": 180
      , "isTop": "lawinst"
  },
  {
      "text": "National Law University, Delhi"
      , "val": 181
      , "isTop": "lawinst"
  },
  {
      "text": "Bharathi Vidyapeeth"
      , "val": 182
      , "isTop": "lawinst"
  },
  {
      "text": "NALSAR University of Law, Hyderabad"
      , "val": 183
      , "isTop": "lawinst"
  },
  {
      "text": "Faculty of Law, Delhi University"
      , "val": 184
      , "isTop": "lawinst"
  },
  {
      "text": "Hidayatullah National Law University, Raipur"
      , "val": 185
      , "isTop": "lawinst"
  },
  {
      "text": "Faculty of Law, Varanasi"
      , "val": 186
      , "isTop": "lawinst"
  },
  {
      "text": "Amity Law School, Delhi"
      , "val": 187
      , "isTop": "lawinst"
  },
  {
      "text": "MS Ramaiah College of Law"
      , "val": 188
      , "isTop": "lawinst"
  },
  {
      "text": "Government Law College, Mumbai"
      , "val": 189
      , "isTop": "lawinst"
  },
  {
      "text": "Jindal Global Law School"
      , "val": 190
      , "isTop": "lawinst"
  }, {
      "text": "Xavier Institute of Social Service, Ranchi (XISS)"
      , "val": 191
      , "isTop": ""
  }].sort(function (a, b) {
      return a["text"].localeCompare(b.text)
  });

var instituteTagsDataHirist = [
  {
      "val": "1",
      "text": "Aalim Muhammed Salegh College of Engineering, Chennai",
  },
  {
      "val": "2",
      "text": "ABES Engineering College, Ghaziabad",

  },
  {
      "val": "3",
      "text": "ABV- Indian Institute of Information Technology and Management, Gwalior",
      "isTop": "topinst"
  },
  {
      "val": "4",
      "text": "Acharya Institute of Technology, Bangalore",
  },
  {
      "val": "5",
      "text": "AD Patel Institute of Technology, Anand",
  },
  {
      "val": "6",
      "text": "Adesh Institute of Engineering and Technology, Faridkot",
  },
  {
      "val": "7",
      "text": "Adhiparasakthi Engineering College, Kancheepuram",
  },
  {
      "val": "11",
      "text": "Aditya Engineering College, Surampalem",
  },
  {
      "val": "14",
      "text": "Aditya Institute of Technology and Management, Tekkali",
  },
  {
      "val": "15",
      "text": "Ajay Kumar Garg Engineering College, Ghaziabad",
  },
  {
      "val": "16",
      "text": "Alagappa Chettiar College of Engineering and Technology, Karaikudi",
  },
  {
      "val": "17",
      "text": "Alagappa College of Technology, Chennai",
  },
  {
      "val": "18",
      "text": "Aligarh Muslim University, Aligarh",
  },
  {
      "val": "19",
      "text": "Alliance College of Engineering and Design, Bangalore",
  },
  {
      "val": "20",
      "text": "Ambedkar Institute of Advanced Communication Technologies and Research, Delhi",
  },
  {
      "val": "21",
      "text": "Amity School of Engineering and Technology, Delhi",
  },
  {
      "val": "22",
      "text": "Amity School of Engineering and Technology, Noida",
  },
  {
      "val": "23",
      "text": "Amity University, Noida",
  },
  {
      "val": "24",
      "text": "Amrita School of Engineering, Coimbatore",
  },
  {
      "val": "25",
      "text": "Anand Institute of Higher Technology, Chennai",
  },
  {
      "val": "26",
      "text": "Anil Neerukonda Institute of Technology and Sciences, Vishakhapatnam",
  },
  {
      "val": "103",
      "text": "Anna University Chennai, CEG Campus",
  },
  {
      "val": "27",
      "text": "Anna University Tiruchirappalli, BIT Campus",
  },
  {
      "val": "28",
      "text": "Annamacharya Institute of Technology and Sciences, Rajampet",
  },
  {
      "val": "29",
      "text": "Arunai Engineering College, Tiruvannamalai",
  },
  {
      "val": "30",
      "text": "Asansol Engineering College, Asansol",
  },
  {
      "val": "31",
      "text": "Assam University, Silchar",
  },
  {
      "val": "33",
      "text": "AU College of Engineering for Women, Vishakapattnam",
  },
  {
      "val": "34",
      "text": "AU College of Engineering, Vishakhapatnam",
  },
  {
      "val": "35",
      "text": "Audisankara College of Engineering and Technology, Gudur",
  },
  {
      "val": "36",
      "text": "Aurora's Engineering College, Nalgonda",
  },
  {
      "val": "37",
      "text": "Aurora's Scientific, Technological and Research Academy, Hyderabad",

  },
  {
      "val": "38",
      "text": "Aurora's Technological and Research Institute, Hyderabad",

  },
  {
      "val": "39",
      "text": "Avanthi Institute of Engineering and Technology, Vishakhapatnam",
  },
  {
      "val": "40",
      "text": "B K Birla Institute of Engineering and Technology, Pilani",
  },
  {
      "val": "41",
      "text": "Baddi University of Emerging Sciences and Technologies, Baddi",
  },
  {
      "val": "42",
      "text": "Banasthali University, Banasthali",
  },
  {
      "val": "43",
      "text": "Bangalore Institute of Technology, Bangalore",
  },
  {
      "val": "44",
      "text": "Bankura Unnayani Institute of Engineering, Bankura",
  },
  {
      "val": "45",
      "text": "Bannari Amman Institute of Technology, Erode",
  },
  {
      "val": "46",
      "text": "Bapatala Engineering College, Bapatala",
  },
  {
      "val": "47",
      "text": "Bapuji Institute of Engineering and Technology, Davangere",
  },
  {
      "val": "49",
      "text": "Barkatullah University Institute of Technology, Barkatullah University, Bhopal",
  },
  {
      "val": "50",
      "text": "Basaveshwar Engineering College, Bagalkot",
  },
  {
      "val": "51",
      "text": "Beant College of Engineering and Technology, Gurdaspur",
  },
  {
      "val": "52",
      "text": "Bhagalpur College of Engineering, Bhagalpur",
  },
  {
      "val": "55",
      "text": "Bharath University, Chennai",
  },
  {
      "val": "56",
      "text": "Bharati Vidyapeeth Deemed University College of Engineering, Pune",
  },
  {
      "val": "57",
      "text": "Bharati Vidyapeeth",

  },
  {
      "val": "58",
      "text": "Bhartiya Vidya Bhavans, Sardar Patel College of Engineering, Mumbai",
  },
  {
      "val": "59",
      "text": "Bhilai Institute of Technology, Durg",
  },
  {
      "val": "60",
      "text": "Bhilai Institute of Technology, Raipur",
  },
  {
      "val": "62",
      "text": "Bidhan Chandra Krishi Viswa Vidyalaya, Nadia",
  },
  {
      "val": "63",
      "text": "Bipin Tripathi Kumaon Institute of Technology, Dwarahat",
  },
  {
      "val": "64",
      "text": "Birbhum Institute of Engineering Technology, Birbhum",

  },
  {
      "val": "53",
      "text": "Birla Institute of Technology and Science, Pilani",
      "isTop": "topinst"

  },
  {
      "val": "65",
      "text": "Birla Institute of Technology, Mesra",
      "isTop": "topinst"
  },
  {
      "val": "66",
      "text": "Birla Institute of Technology, Patna",
  },
  {
      "val": "67",
      "text": "BIT Sindri, Dhanbad",
  },
  {
      "val": "68",
      "text": "BITS Pilani- Hyderabad Campus, Hyderabad",
  },
  {
      "val": "69",
      "text": "BITS Pilani, Goa Campus",
  },
  {
      "val": "70",
      "text": "BMS College of Engineering, Bangalore",
  },
  {
      "val": "71",
      "text": "BMS Institute of Technology, Bangalore",
  },
  {
      "val": "72",
      "text": "BNM Institute of Technology, Bangalore",
  },
  {
      "val": "102",
      "text": "BS Abdur Rahman University, Chennai",
  },
  {
      "val": "73",
      "text": "Bundelkhand Institute of Engineering and Technology, Jhansi",
  },
  {
      "val": "74",
      "text": "BV Bhoomaraddi College of Engineering and Technology, Hubli",
  },
  {
      "val": "75",
      "text": "BVRIT Padmasri Dr BV Raju Institute of Technology, Hyderabad",
  },
  {
      "val": "76",
      "text": "CBS Group of Instiutions, Jhajjar",
  },
  {
      "val": "77",
      "text": "Central Institute of Plastic Engineering Technology, Lucknow",
  },
  {
      "val": "78",
      "text": "Central Institute of Plastics Engineering and Technology, Chennai",
  },
  {
      "val": "79",
      "text": "Centurion University, Paralakhemundi",
  },
  {
      "val": "80",
      "text": "Chaitanya Bharathi Institute of Technology, Hyderabad",
  },
  {
      "val": "81",
      "text": "Chaitanya Engineering College, Vishakhapatnam",
  },
  {
      "val": "82",
      "text": "Chandigarh College of Engineering and Technology, Chandigarh",
  },
  {
      "val": "83",
      "text": "Charotar University of Science and Technology, Petlad",
  },
  {
      "val": "84",
      "text": "Chaudhary Charan Singh Haryana Agricultural University, Hisar",
  },
  {
      "val": "85",
      "text": "Chitkara University, Patiala",
  },
  {
      "val": "86",
      "text": "Christ University, Bangalore",
  },
  {
      "val": "87",
      "text": "CMR Engineering College, Hyderabad",
  },
  {
      "val": "88",
      "text": "CMR Institute of Technology, Bangalore",
  },
  {
      "val": "90",
      "text": "CMR Institute of Technology, Hyderabad",
  },
  {
      "val": "91",
      "text": "CMR Technical Campus, Hyderabad",
  },
  {
      "val": "92",
      "text": "Coimbatore Institute of Technology, Coimbatore",
  },
  {
      "val": "93",
      "text": "College of Dairy Science and Technology, Mannuthi, Thrissur",
  },
  {
      "val": "101",
      "text": "College of Engineering Adoor",
  },
  {
      "val": "104",
      "text": "College of Engineering and Management, Kolaghat",
  },
  {
      "val": "105",
      "text": "College of Engineering and Technology ,Bhubaneswar",
  },
  {
      "val": "107",
      "text": "College of Engineering Roorkee, Roorkee",
  },
  {
      "val": "108",
      "text": "College of Engineering Studies, University of Petroleum and Energy Studies, Dehradun",
  },
  {
      "val": "109",
      "text": "College of Engineering, Chengannur",

  },
  {
      "val": "113",
      "text": "College of Engineering, Pune",
      "isTop": "topinst"
  },
  {
      "val": "119",
      "text": "College of Engineering, Trivandrum",

  },
  {
      "val": "121",
      "text": "College of Technology and Engineering, Maharana Pratap University of Agriculture and Technology, Udaipur",
      "isTop": "topinst"
  },
  {
      "val": "127",
      "text": "College of Technology, GB Pant University of Agriculture and Technology, Pantnagar",
  },
  {
      "val": "132",
      "text": "CV Raman College of Engineering, Bhubaneswar",
  },
  {
      "val": "138",
      "text": "CVR College of Engineering, Hyderabad",
  },
  {
      "val": "139",
      "text": "DAV Institute of Engineering and Technology, Jalandhar",
  },
  {
      "val": "140",
      "text": "Dayalbagh Educational Institute, Agra",
  },
  {
      "val": "141",
      "text": "Dayanand Sagar College of Engineering, Bangalore",
  },
  {
      "val": "142",
      "text": "Deenbandhu Chhotu Ram University of Science and Technology, Murthal",

  },
  {
      "val": "110",
      "text": "Delhi Technological University, Delhi",
      "isTop": "topinst"
  },
  {
      "val": "147",
      "text": "Dharmsinh Desai University, Nadiad",
  },
  {
      "val": "111",
      "text": "Dhirubhai Ambani Institute of Information and Communication Technology, Gandhinagar",
  },
  {
      "val": "149",
      "text": "DIT University, Dehradun",
  },
  {
      "val": "155",
      "text": "Don Bosco Institute of Technology, Mumbai",

  },
  {
      "val": "156",
      "text": "Dr B R Ambedkar National Institute of Technology, Jalandhar (NITJ)",
      "isTop": "topinst"
  },
  {
      "val": "157",
      "text": "Dr S and SS Ghandhy College of Engineering and Technology, Surat",
  },
  {
      "val": "158",
      "text": "Dr. Ambedkar Institute of Technology for Handicapped, Kanpur  ",
  },
  {
      "val": "159",
      "text": "Dr. Ambedkar Institute of Technology, Bangalore",
  },
  {
      "val": "160",
      "text": "Dr. Babasaheb Ambedkar Technological University, Lonere",
  },
  {
      "val": "161",
      "text": "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Banda",
  },
  {
      "val": "162",
      "text": "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Bijnor",
  },
  {
      "val": "163",
      "text": "Dr. Mahalingam College of Engineering and Technology, Pollachi",
  },
  {
      "val": "164",
      "text": "Dr. Sivanthi Aditanar College of Engineering, Thoothukudi",
  },
  {
      "val": "165",
      "text": "Dronacharya College of Engineering, Gurgaon",
  },
  {
      "val": "166",
      "text": "Easwari Engineering College, Chennai",
  },
  {
      "val": "167",
      "text": "Faculty of Engineering and Technology, Manav Rachna International University, Faridabad",
  },
  {
      "val": "168",
      "text": "Feroze Gandhi Institute of Engineering and Technology, Raibareli",
  },
  {
      "val": "169",
      "text": "Fr. C Rodrigues Institute of Technology, Navi Mumbai",
  },
  {
      "val": "170",
      "text": "Fr. Conceicao Rodrigues College of Engineering, Mumbai",
  },
  {
      "val": "171",
      "text": "G Narayanamma Institute of Technology and Science (For Women), Hyderabad",
  },
  {
      "val": "172",
      "text": "G Pulla Reddy Engineering College, Kurnool",
  },
  {
      "val": "173",
      "text": "Galgotias College of Engineering and Technology, Greater Noida",
  },
  {
      "val": "174",
      "text": "Galgotias University, Greater Noida",
  },
  {
      "val": "175",
      "text": "Gandhi Institute of Engineering and Technology, Gunupur",
  },
  {
      "val": "176",
      "text": "Gayatri Vidya Parishad College of Engineering, Vishakhapatnam",
  },
  {
      "val": "177",
      "text": "GH Patel College of Engineering and Technology, Anand",
  },
  {
      "val": "178",
      "text": "GH Raisoni College of Engineering, Nagpur",
  },
  {
      "val": "179",
      "text": "Giani Zail Singh Punjab Technical University Campus, Bathinda",
  },
  {
      "val": "180",
      "text": "GLA University, Mathura",
  },
  {
      "val": "181",
      "text": "GMR Institute of Technology, Rajam",
  },
  {
      "val": "182",
      "text": "Gokaraju Rangaraju Institute of Engineering and Technology, Hyderabad",
  },
  {
      "val": "183",
      "text": "Government College of Engineering and Ceramic Technology, Kolkata",
  },
  {
      "val": "184",
      "text": "Government College of Engineering and Leather Technology,Kolkata",
  },
  {
      "val": "185",
      "text": "Government College of Engineering and Technology, Bikaner",
  },
  {
      "val": "186",
      "text": "Government College of Engineering and Technology, Jammu",
  },
  {
      "val": "187",
      "text": "Government College of Engineering and Textile Technology, Berhampore",
  },
  {
      "val": "188",
      "text": "Government College of Engineering, Aurangabad",
  },
  {
      "val": "189",
      "text": "Government College of Engineering, Bargur",
  },
  {
      "val": "190",
      "text": "Government College of Engineering, Kalahandi",
  },
  {
      "val": "191",
      "text": "Government College of Engineering, Kannur",
  },
  {
      "val": "192",
      "text": "Government College of Engineering, Karad",
  },
  {
      "val": "193",
      "text": "Government College of Engineering, Salem",
  },
  {
      "val": "194",
      "text": "Government College of Engineering, Tirunelveli",
  },
  {
      "val": "195",
      "text": "Government College of Technology, Coimbatore",
  },
  {
      "val": "196",
      "text": "Government Engineering College, Ajmer",
  },
  {
      "val": "197",
      "text": "Government Engineering College, Barton Hill, Thiruvananthapuram ",
  },
  {
      "val": "198",
      "text": "Government Engineering College, Bastar",
  },
  {
      "val": "199",
      "text": "Government Engineering College, Bhavnagar",
  },
  {
      "val": "200",
      "text": "Government Engineering College, Bikaner",
  },
  {
      "val": "201",
      "text": "Government Engineering College, Bilaspur",
  },
  {
      "val": "202",
      "text": "Government Engineering College, Gandhinagar",
  },
  {
      "val": "203",
      "text": "Government Engineering College, Jhalawar",
  },
  {
      "val": "204",
      "text": "Government Engineering College, Kozhikode",
  },
  {
      "val": "205",
      "text": "Government Engineering College, Modasa",
  },
  {
      "val": "206",
      "text": "Government Engineering College, Patan",
  },
  {
      "val": "207",
      "text": "Government Engineering College, Raipur",
  },
  {
      "val": "208",
      "text": "Government Engineering College, Rajkot",
  },
  {
      "val": "209",
      "text": "Government Engineering College, Sreekrishnapuram",
  },
  {
      "val": "210",
      "text": "Government Engineering College, Surat",
  },
  {
      "val": "211",
      "text": "Government Engineering College, Thrissur",
  },
  {
      "val": "212",
      "text": "Government Engineering College, Wayanad",
  },
  {
      "val": "125",
      "text": "Government Model Engineering College, Thrikkakara",
  },
  {
      "val": "213",
      "text": "Government Sri Krishnarajendra Silver Jubilee Technological Institute, Bangalore",
  },
  {
      "val": "214",
      "text": "Government Women Engineering College, Ajmer",
  },
  {
      "val": "215",
      "text": "Govind Ballabh Pant Engineering College, Pauri-Garhwal",
  },
  {
      "val": "216",
      "text": "Gudlavalleru Engineering College, Gudlavalleru",
  },
  {
      "val": "217",
      "text": "Guru Ghasidas Vishwavidyalaya, Bilaspur",
  },
  {
      "val": "116",
      "text": "Guru Gobind Singh Indraprastha University, Delhi",
  },
  {
      "val": "218",
      "text": "Guru Jambheshwar University of Science and Technology, Hisar",
  },
  {
      "val": "219",
      "text": "Guru Nanak Dev Engineering College, Bidar",
  },
  {
      "val": "220",
      "text": "Guru Nanak Dev Engineering College, Ludhiana",
  },
  {
      "val": "221",
      "text": "Guru Nanak Dev University Regional Campus Jalandhar",
  },
  {
      "val": "222",
      "text": "Guru Nanak Dev University, Amritsar",
  },
  {
      "val": "223",
      "text": "Guru Nanak Institutions Technical Campus, Secunderabad",
  },
  {
      "val": "224",
      "text": "Gurukul Kangri Vishwavidyalaya, Haridwar",
  },
  {
      "val": "225",
      "text": "Haldia Institute of Technology, Haldia",
  },
  {
      "val": "114",
      "text": "Harcourt Butler Technological Institute, Kanpur",
  },
  {
      "val": "226",
      "text": "Heritage Institute of Technology, Kolkata",
  },
  {
      "val": "227",
      "text": "Hindu College of Engineering, Sonipat",
  },
  {
      "val": "228",
      "text": "Hindustan University, Kelambakkam",
  },
  {
      "val": "229",
      "text": "Hindusthan College of Engineering and Technology, Coimbatore",
  },
  {
      "val": "32",
      "text": "ICFAI Foundation for Higher Education, Hyderabad",
  },
  {
      "val": "230",
      "text": "Indian Institute of Carpet Technology, Bhadohi",

  },
  {
      "val": "231",
      "text": "Indian Institute of Engineering Science and Technology, Shibpur",
      "isTop": "topinst"

  },
  {
      "val": "117",
      "text": "Indian Institute of Information Technology, Allahabad",
      "isTop": "topinst"

  },
  {
      "val": "232",
      "text": "Indian Institute of Information Technology, Design and Manufacturing, Jabalpur",
      "isTop": "topinst"
  },
  {
      "val": "233",
      "text": "Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram",
  },
  {
      "val": "234",
      "text": "Indian Institute of Science Education and Research, Bhopal",

  },
  {
      "val": "235",
      "text": "Indian Institute of Science Education and Research, Kolkata",
      "isTop": "topinst"

  },
  {
      "val": "236",
      "text": "Indian Institute of Science Education and Research, Mohali",
      "isTop": "topinst"
  },
  {
      "val": "237",
      "text": "Indian Institute of Science Education and Research, Pune",
  },
  {
      "val": "238",
      "text": "Indian Institute of Science Education and Research, Thiruvananthapuram",
  },
  {
      "val": "239",
      "text": "Indian Institute of Space Science and Technology, Thiruvananthapuram",

  },
  {
      "val": "89",
      "text": "Indian Institute of Technology, Banaras Hindu University, Varanasi (IITBHU)",
      "isTop": "topinst"

  },
  {
      "val": "48",
      "text": "Indian Institute of Technology, Bhubaneswar (IITBBS)",
      "isTop": "topinst"

  },
  {
      "val": "9",
      "text": "Indian Institute of Technology, Bombay (IITB)",
      "isTop": "topinst"

  },
  {
      "val": "8",
      "text": "Indian Institute of Technology, Delhi (IITD)",
      "isTop": "topinst"

  },
  {
      "val": "94",
      "text": "Indian Institute of Technology, Gandhinagar (IITGN)",
      "isTop": "topinst"

  },
  {
      "val": "54",
      "text": "Indian Institute of Technology, Guwahati (IITG)",
      "isTop": "topinst"

  },
  {
      "val": "95",
      "text": "Indian Institute of Technology, Hyderabad (IITH)",
      "isTop": "topinst"

  },
  {
      "val": "96",
      "text": "Indian Institute of Technology, Indore (IITI)",
      "isTop": "topinst"

  },
  {
      "val": "97",
      "text": "Indian Institute of Technology, Jodhpur (IITJ)",
      "isTop": "topinst"

  },
  {
      "val": "12",
      "text": "Indian Institute of Technology, Kanpur (IITK)",
      "isTop": "topinst"

  },
  {
      "val": "13",
      "text": "Indian Institute of Technology, Kharagpur (IITKGP)",
      "isTop": "topinst"

  },
  {
      "val": "10",
      "text": "Indian Institute of Technology, Madras (IITM)",
      "isTop": "topinst"

  },
  {
      "val": "98",
      "text": "Indian Institute of Technology, Mandi (IITMANDI)",
      "isTop": "topinst"

  },
  {
      "val": "99",
      "text": "Indian Institute of Technology, Patna (IITP)",
      "isTop": "topinst"

  },
  {
      "val": "61",
      "text": "Indian Institute of Technology, Roorkee (IITR)",
      "isTop": "topinst"

  },
  {
      "val": "100",
      "text": "Indian Institute of Technology, Ropar (IITRPR)",
      "isTop": "topinst"

  },
  {
      "val": "118",
      "text": "Indian School of Mines, Dhanbad",
      "isTop": "topinst"
  },
  {
      "val": "240",
      "text": "Indira Gandhi Institute of Technology, Delhi",
  },
  {
      "val": "241",
      "text": "Indira Gandhi Institute of Technology, Sarang",
  },
  {
      "val": "242",
      "text": "Indraprastha Institute of Information Technology, Delhi",
  },
  {
      "val": "243",
      "text": "Institute of Chemical Technology, Mumbai",
  },
  {
      "val": "244",
      "text": "Institute of Engineering and Management, Kolkata",
  },
  {
      "val": "245",
      "text": "Institute of Engineering and Rural Technology, Allahabad",
  },
  {
      "val": "246",
      "text": "Institute of Engineering and Science, IPS Academy, Indore",
  },
  {
      "val": "247",
      "text": "Institute of Engineering and Technology, Alwar",
  },
  {
      "val": "248",
      "text": "Institute of Engineering and Technology, Indore",
  },
  {
      "val": "249",
      "text": "Institute of Engineering and Technology, Lucknow",
  },
  {
      "val": "250",
      "text": "Institute of Information and Communication Technology, Ahmedabad",
  },
  {
      "val": "251",
      "text": "Institute of Infrastructure, Technology, Research and Management, Ahmedabad",
  },
  {
      "val": "252",
      "text": "Institute of Road and Transport Technology, Erode",
  },
  {
      "val": "253",
      "text": "Institute of Science and Technology, Jawaharlal Nehru Technological University, Hyderabad",
  },
  {
      "val": "254",
      "text": "Integral University, Lucknow",
  },
  {
      "val": "255",
      "text": "International Institute of Information Technology, Bangalore",
  },
  {
      "val": "256",
      "text": "International Institute of Information Technology, Bhubaneswar",

  },
  {
      "val": "120",
      "text": "International Institute of Information Technology, Hyderabad",
      "isTop": "topinst"
  },
  {
      "val": "257",
      "text": "International Institute of Information Technology, Pune",
  },
  {
      "val": "258",
      "text": "Islamic University of Science and Technology, Pulwama",
  },
  {
      "val": "259",
      "text": "ITM University, Gwalior",
  },
  {
      "val": "260",
      "text": "Jabalpur Engineering College, Jabalpur",

  },
  {
      "val": "261",
      "text": "Jadavpur University, Kolkata",
      "isTop": "topinst"
  },
  {
      "val": "262",
      "text": "Jaipur Engineering College and Research Centre, Jaipur",
  },
  {
      "val": "263",
      "text": "Jaipur National University, Jaipur",
  },
  {
      "val": "264",
      "text": "Jalpaiguri Government Engineering College, Jalpaiguri",
  },
  {
      "val": "265",
      "text": "Jamia Millia Islamia, New Delhi",
  },
  {
      "val": "266",
      "text": "Jawaharlal Nehru Technological University College of Engineering, Kakinada",
  },
  {
      "val": "267",
      "text": "Jawaharlal Nehru Technological University, Kakinada",
  },
  {
      "val": "268",
      "text": "Jaypee Institute of Information Technology, Noida",

  },
  {
      "val": "269",
      "text": "Jaypee University of Information Technology, Solan",
      "isTop": "topinst"

  },
  {
      "val": "270",
      "text": "Jiwaji University, Gwalior",
      "isTop": "topinst"

  },
  {
      "val": "271",
      "text": "JNTU College of Engineering, Hyderabad",
      "isTop": "topinst"
  },
  {
      "val": "272",
      "text": "JNTU, University College of Engineering, Vizianagaram ",
  },
  {
      "val": "273",
      "text": "JNTUH College of Engineering, Karimnagar",
  },
  {
      "val": "274",
      "text": "Jodhpur National University, Jodhpur",
  },
  {
      "val": "275",
      "text": "Joginpally B R Engineering College, Moinabad",
  },
  {
      "val": "276",
      "text": "JSS Academy of Technical Education, Bangalore",
  },
  {
      "val": "277",
      "text": "JSS Academy of Technical Education, Noida",
  },
  {
      "val": "278",
      "text": "Kakatiya Institute of Technology and Science, Warangal",
  },
  {
      "val": "279",
      "text": "Kalinga Institute of Industrial Technology, Bhubaneswar",
  },
  {
      "val": "280",
      "text": "Kamla Nehru Institute of Technology, Sultanpur",
  },
  {
      "val": "281",
      "text": "Karunya University, Coimbatore",
  },
  {
      "val": "282",
      "text": "Kasegaon Education Society’s Rajarambapu Institute of Technology, Sangli",
  },
  {
      "val": "283",
      "text": "KJ Somaiya College of Engineering, Mumbai",
  },
  {
      "val": "284",
      "text": "KJ Somaiya College of Engineering, Mumbai",
  },
  {
      "val": "285",
      "text": "KLS Gogte Institute of Technology, Belgaum",
  },
  {
      "val": "286",
      "text": "Kongu Engineering College, Erode",
  },
  {
      "val": "287",
      "text": "Krishna Institute of Engineering and Technology, Ghaziabad",
  },
  {
      "val": "288",
      "text": "Kumaraguru College of Technology, Coimbatore",
  },
  {
      "val": "289",
      "text": "Lakshmi Narain College of Technology, Bhopal",
  },
  {
      "val": "290",
      "text": "LBS College of Engineering, Kasargod",
  },
  {
      "val": "291",
      "text": "LBS Institute of Technology for Women, Thiruvananthapuram",
  },
  {
      "val": "292",
      "text": "LD College of Engineering, Ahmedabad",
  },
  {
      "val": "293",
      "text": "Lovely Professional University, Jalandhar",
  },
  {
      "val": "294",
      "text": "Lukhdhirji Engineering College, Morbi",
  },
  {
      "val": "295",
      "text": "M Kumarasamy College of Engineering, Karur",
  },
  {
      "val": "296",
      "text": "Madan Mohan Malaviya University of Technology. Gorakhpur",
  },
  {
      "val": "297",
      "text": "Madanpalle Institute of Technology and Science, Madanapalle",
  },
  {
      "val": "298",
      "text": "Madhav Institute of Technology and Science, Gwalior",
  },
  {
      "val": "299",
      "text": "Madras Institute of Technology, Chennai",
  },
  {
      "val": "300",
      "text": "Maharaj Vijayaram Gajapathi Raj College of Engineering, Vizianagaram",
  },
  {
      "val": "301",
      "text": "Maharaja Agrasen Institute of Technology, Delhi",
  },
  {
      "val": "302",
      "text": "Maharaja Sayajirao University of Baroda, Vadodara",
  },
  {
      "val": "303",
      "text": "Maharashtra Institute of Technology, Pune",
  },
  {
      "val": "304",
      "text": "Maharishi Markandeshwar University, Ambala ",
  },
  {
      "val": "305",
      "text": "Mahatma Gandhi Institute of Technology, Hyderabad",
  },
  {
      "val": "306",
      "text": "Mahatma Jyotiba Phule Rohilkhand University, Bareilly",

  },
  {
      "val": "112",
      "text": "Malaviya National Institute of Technology, Jaipur (MNIT)",
      "isTop": "topinst"
  },
  {
      "val": "307",
      "text": "Malla Reddy College of Engineering and Technology, Hyderabad",
  },
  {
      "val": "308",
      "text": "Malla Reddy Engineering College, Hyderabad",
  },
  {
      "val": "309",
      "text": "Malnad College of Engineering, Hassan",
  },
  {
      "val": "310",
      "text": "Manav Rachna College of Engineering, Faridabad",

  },
  {
      "val": "122",
      "text": "Manipal Institute of Technology, Manipal",
      "isTop": "topinst"
  },
  {
      "val": "311",
      "text": "Manyawar Kansi Ram Engineering College of Information Technology, Ambedkar Nagar",
  },
  {
      "val": "312",
      "text": "Manyawar Kansi Ram Engineering College of Information Technology, Azamgarh",
  },
  {
      "val": "313",
      "text": "Mar Athanasius College of Engineering, Kothamangalam",
  },
  {
      "val": "314",
      "text": "Maturi Venkata Subba Rao Engineering College, Hyderabad",

  },
  {
      "val": "315",
      "text": "Maulana Azad National Institute of Technology, Bhopal (MANIT)",
      "isTop": "topinst"
  },
  {
      "val": "316",
      "text": "MBM Engineering College, Jodhpur",
  },
  {
      "val": "317",
      "text": "MCT’s Rajiv Gandhi Institute of Technology, Mumbai",
  },
  {
      "val": "318",
      "text": "Medi-Caps Institute of Science and Technology, Indore",
  },
  {
      "val": "319",
      "text": "Medi-Caps Institute of Technology and Management, Indore",
  },
  {
      "val": "320",
      "text": "Meenakshi Sundararajan Engineering College, Chennai",
  },
  {
      "val": "124",
      "text": "Mepco Schlenk Engineering College, Sivakasi",
  },
  {
      "val": "321",
      "text": "MES College of Engineering, Malappuram",
  },
  {
      "val": "322",
      "text": "Mizoram University, Aizawl",
  },
  {
      "val": "323",
      "text": "MLV Government Textile and Engineering College, Bhilwara",
  },
  {
      "val": "324",
      "text": "Mody University of Science and Technology, Sikar",

  },
  {
      "val": "126",
      "text": "Motilal Nehru National Institute of Technology, Allahabad (MNNIT)",
      "isTop": "topinst"
  },
  {
      "val": "325",
      "text": "MS Engineering College, Bangalore",
  },
  {
      "val": "123",
      "text": "MS Ramaiah Institute of Technology, Bangalore",
  },
  {
      "val": "326",
      "text": "Mukesh Patel School of Technology Management and Engineering, Mumbai",
  },
  {
      "val": "327",
      "text": "Muzaffarpur Institute of Technology, Muzaffarpur",
  },
  {
      "val": "328",
      "text": "MVJ College of Engineering, Bangalore",
  },
  {
      "val": "329",
      "text": "Narasaraopeta Engineering College, Narasaraopet",
  },
  {
      "val": "330",
      "text": "National Dairy Research Institute, Karnal",
  },
  {
      "val": "331",
      "text": "National Institute of Foundry and Forge Technology, Ranchi",
  },
  {
      "val": "332",
      "text": "National Institute of Science Education and Research, Bhubaneswar",

  },
  {
      "val": "333",
      "text": "National Institute of Technology, Agartala (NITA)",
      "isTop": "topinst"

  },
  {
      "val": "129",
      "text": "National Institute of Technology, Calicut (NITC)",
      "isTop": "topinst"
  },
  {
      "val": "334",
      "text": "National Institute of Technology, Delhi (NITDELHI)",

  },
  {
      "val": "130",
      "text": "National Institute of Technology, Durgapur (NITDGP)",
      "isTop": "topinst"
  },
  {
      "val": "335",
      "text": "National Institute of Technology, Goa (NITGOA)",

  },
  {
      "val": "131",
      "text": "National Institute of Technology, Hamirpur (NITH)",
      "isTop": "topinst"

  },
  {
      "val": "336",
      "text": "National Institute of Technology, Jamshedpur (NITJSR)",
      "isTop": "topinst"

  },
  {
      "val": "337",
      "text": "National Institute of Technology, Kurukshetra (NITKKR)",
      "isTop": "topinst"
  },
  {
      "val": "338",
      "text": "National Institute of Technology, Mizoram (NITMZ)",
  },
  {
      "val": "339",
      "text": "National Institute of Technology, Nagaland (NITNAGALAND)",
  },
  {
      "val": "340",
      "text": "National Institute of Technology, Patna (NITP)",
  },
  {
      "val": "341",
      "text": "National Institute of Technology, Puducherry (NITPY)",

  },
  {
      "val": "342",
      "text": "National Institute of Technology, Raipur (NITRR)",
      "isTop": "topinst"

  },
  {
      "val": "133",
      "text": "National Institute of Technology, Rourkela (NITRKL)",
      "isTop": "topinst"
  },
  {
      "val": "343",
      "text": "National Institute of Technology, Sikkim (NITSIKKIM)",

  },
  {
      "val": "134",
      "text": "National Institute of Technology, Silchar (NITS)",
      "isTop": "topinst"
  },
  {
      "val": "128",
      "text": "National Institute of Technology, Srinagar (NITSRI)",

  },
  {
      "val": "344",
      "text": "National Institute of Technology, Surathkal (NITK)",
      "isTop": "topinst"

  },
  {
      "val": "135",
      "text": "National Institute of Technology, Tiruchirappalli (NITT)",
      "isTop": "topinst"
  },
  {
      "val": "345",
      "text": "National Institute of Technology, Uttarakhand (NITUK)",

  },
  {
      "val": "136",
      "text": "National Institute of Technology, Warangal (NITW)",
      "isTop": "topinst"
  },
  {
      "val": "346",
      "text": "National Power Training Institute, Durgapur",
  },
  {
      "val": "347",
      "text": "National Power Training Institute, Faridabad",
  },
  {
      "val": "348",
      "text": "NC College of Engineering, Israna",

  },
  {
      "val": "137",
      "text": "Netaji Subhas Institute of Technology, Delhi",
      "isTop": "topinst"
  },
  {
      "val": "349",
      "text": "Netaji Subhash Engineering College, Garia",
  },
  {
      "val": "350",
      "text": "New Horizon College of Engineering, Bangalore",
  },
  {
      "val": "351",
      "text": "NIIT University, Neemrana",
  },
  {
      "val": "352",
      "text": "Nirma University, Ahmedabad",
  },
  {
      "val": "353",
      "text": "Nitte Meenakshi Institute of Technology, Bangalore",
  },
  {
      "val": "354",
      "text": "Nizam Institute of Engineering and Technology, Hyderabad",
  },
  {
      "val": "355",
      "text": "NMAM Institute of Technology, Karkala Taluk",
  },
  {
      "val": "356",
      "text": "North Eastern Regional Institute of Science and Technology, Nirjuli",
  },
  {
      "val": "357",
      "text": "NSS College of Engineering, Palakkad",
  },
  {
      "val": "358",
      "text": "Orissa School of Mining Engineering, Keonjhar",
  },
  {
      "val": "359",
      "text": "PA College of Engineering, Mangalore",
  },
  {
      "val": "360",
      "text": "Pandit Deendayal Petroleum University- School of Technology, Gandhinagar",
  },
  {
      "val": "361",
      "text": "Panimalar Engineering College, Chennai",
  },
  {
      "val": "362",
      "text": "Panipat Institute of Engineering and Technology, Panipat",

  },
  {
      "val": "363",
      "text": "Panjab University, Chandigarh",
      "isTop": "topinst"
  },
  {
      "val": "364",
      "text": "Panjab University, SSG Regional Centre, Hoshiarpur",
  },
  {
      "val": "365",
      "text": "Parala Maharaja Engineering College, Berhampur",
  },
  {
      "val": "366",
      "text": "Parvatha Reddy Babul Reddy Visvodaya Institute of Technology and Science, Nellore",
  },
  {
      "val": "367",
      "text": "PES College of Engineering, Mandya",
  },
  {
      "val": "368",
      "text": "PES Institute of Technology, Bangalore South Campus, Bangalore",

  },
  {
      "val": "369",
      "text": "Pondicherry Engineering College, Puducherry",
      "isTop": "topinst"
  },
  {
      "val": "370",
      "text": "Poojya Doddappa Appa College, Gulbarga",
  },
  {
      "val": "371",
      "text": "Poornima College of Engineering, Jaipur",
  },
  {
      "val": "372",
      "text": "Pragati Engineering College, Kakinada",
  },
  {
      "val": "373",
      "text": "PSG College of Technology, Coimbatore",
  },
  {
      "val": "374",
      "text": "Punjab Agricultural University, Ludhiana",
  },
  {
      "val": "375",
      "text": "Punjab Engineering College University of Technology, Chandigarh",
  },
  {
      "val": "376",
      "text": "QIS College of Engineering and Technology, Ongole",
  },
  {
      "val": "377",
      "text": "Quantum School of Technology, Roorkee",
  },
  {
      "val": "143",
      "text": "Rajagiri School of Engineering and Technology, Ernakulam",
  },
  {
      "val": "378",
      "text": "Rajasthan Technical University, Kota",
  },
  {
      "val": "379",
      "text": "Rajeev Gandhi Memorial College of Engineering and Technology, Kurnool",
  },
  {
      "val": "380",
      "text": "Rajiv Gandhi Institute of Technology, Kottayam",
  },
  {
      "val": "381",
      "text": "Rajiv Gandhi Proudyogiki Vishwavidyalaya, Bhopal",
  },
  {
      "val": "382",
      "text": "Rayat Bahra Faculty of Engineering and Technology, Patiala Campus, Patiala",
  },
  {
      "val": "383",
      "text": "RCC Institute of Information Technology, Beliaghata",
  },
  {
      "val": "384",
      "text": "Reva Institute of Technology and Management, Bangalore",
  },
  {
      "val": "385",
      "text": "Rewa Engineering College, Rewa",
  },
  {
      "val": "386",
      "text": "RMK Engineering College, Thiruvallur",
  },
  {
      "val": "387",
      "text": "RNS Institute of Technology, Bangalore",
  },
  {
      "val": "388",
      "text": "RTE Sociey",

  },
  {
      "val": "389",
      "text": "Rungta College of Engineering and Technology, Bhilai",
  },
  {
      "val": "390",
      "text": "Rustamji Institute of Technology, Gwalior",
  },
  {
      "val": "391",
      "text": "RV College of Engineering, Bangalore",
  },
  {
      "val": "392",
      "text": "Sagi Ramakrishnam Raju Engineering College, Bhimavaram",
  },
  {
      "val": "393",
      "text": "Sam Higginbottom Institute of Agriculture, Technology and Science, Allahabad",
  },
  {
      "val": "394",
      "text": "Samrat Ashok Technological Institute, Vidisha",
  },
  {
      "val": "395",
      "text": "Sant Longowal Institute of Engineering and Technology, Longowal",

  },
  {
      "val": "144",
      "text": "Sardar Vallabhbhai National Institute of Technology, Surat (SVNIT)",
      "isTop": "topinst"

  },
  {
      "val": "396",
      "text": "SASTRA University, Thanjavur",
      "isTop": "topinst"
  },
  {
      "val": "397",
      "text": "Sathyabama University, Chennai",
  },
  {
      "val": "398",
      "text": "Satyam Institute of Engineering and Technology, Amritsar",
  },
  {
      "val": "399",
      "text": "Saveetha Engineering College, Chennai",
  },
  {
      "val": "400",
      "text": "School of Engineering and Technology, Jain University, Bangalore",
  },
  {
      "val": "401",
      "text": "School of Engineering, Cochin University of Science and Technology, Ernakulam",
  },
  {
      "val": "402",
      "text": "SCMS School of Engineering and Technology, Ernakulum",
  },
  {
      "val": "403",
      "text": "SDM College of Engineering and Technology, Dharwad",
  },
  {
      "val": "404",
      "text": "Seth Jai Parkash Mukand Lal Institute of Engineering and Technology, Yamunanagar",
  },
  {
      "val": "405",
      "text": "Shaheed Bhagat Singh State Technical Campus, Firozpur",
  },
  {
      "val": "406",
      "text": "Shantilal Shah Engineering College, Bhavnagar",
  },
  {
      "val": "407",
      "text": "Sharda University, Greater Noida",
  },
  {
      "val": "408",
      "text": "Shiv Nadar University, Dadri",
  },
  {
      "val": "409",
      "text": "Shivaji University, Kolhapur",
  },
  {
      "val": "410",
      "text": "Shobhit Institute of Engineering and  Technology, Meerut",
  },
  {
      "val": "411",
      "text": "Shri GS Institute of Technology and Science, Indore",
  },
  {
      "val": "412",
      "text": "Shri Guru Gobind Singhji Institute of Engineering and Technology, Vishnupuri",
  },
  {
      "val": "145",
      "text": "Shri Mata Vaishno Devi University, Katra",
  },
  {
      "val": "413",
      "text": "Shri Ram Murti Smarak College of Engineering and Technology, Bareilly",
  },
  {
      "val": "414",
      "text": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
  },
  {
      "val": "415",
      "text": "Shri Shankaracharya Engineering College, Bhilai",
  },
  {
      "val": "416",
      "text": "Shri Vaishnav Institute of Technology and Science, Indore",
  },
  {
      "val": "417",
      "text": "Siddaganga Institute of Technology, Tumkur",
  },
  {
      "val": "418",
      "text": "Siksha O' Anusandhan University, Bhubaneshwar",
  },
  {
      "val": "419",
      "text": "Silicon Institute of Technology, Bhubaneswar",
  },
  {
      "val": "420",
      "text": "Sinhgad College of Engineering, Pune",
  },
  {
      "val": "146",
      "text": "Sir M Visvesvaraya Institute of Technology, Bangalore",
  },
  {
      "val": "421",
      "text": "SJB Institute of Technology, Bangalore",
  },
  {
      "val": "422",
      "text": "Sona College of Technology, Salem",
  },
  {
      "val": "423",
      "text": "Sree Chitra Thirunal College of Engineering, Thiruvananthapuram ",
  },
  {
      "val": "424",
      "text": "Sree Vidyanikethan Engineering College, Tirupati",
  },
  {
      "val": "425",
      "text": "Sreenidhi Institute of Science and Technology, Ghatkesar",
  },
  {
      "val": "426",
      "text": "Sri Aditya Engineering College, Surampalem",
  },
  {
      "val": "427",
      "text": "Sri Jayachamarajendra College of Engineering, Mysore",
  },
  {
      "val": "428",
      "text": "Sri Krishna College of Engineering and Technology, Coimbatore",
  },
  {
      "val": "429",
      "text": "Sri Manakula Vinayagar Engineering College, Puducherry",
  },
  {
      "val": "430",
      "text": "Sri Ramakrishna Engineering College, Coimbatore",
  },
  {
      "val": "431",
      "text": "Sri Sai Ram Engineering College, Chennai",
  },
  {
      "val": "432",
      "text": "Sri Siddhartha Institute of Technology, Tumkur",
  },
  {
      "val": "433",
      "text": "Sri Venkateswara College of Engineering, Sriperumbudur",
  },
  {
      "val": "434",
      "text": "Sri Venkateswara University, Tirupati",
  },
  {
      "val": "435",
      "text": "Sri Vishnu Engineering College for Women, Bhimavaram",
  },
  {
      "val": "148",
      "text": "SRM University, Chennai",
  },
  {
      "val": "436",
      "text": "St. Ann",

  },
  {
      "val": "437",
      "text": "Swami Keshvanand Institute of Technology, Management and Gramothan, Jaipur",
  },
  {
      "val": "438",
      "text": "Symbiosis International University, Pune",
  },
  {
      "val": "439",
      "text": "Techno India University, Kolkata",
  },
  {
      "val": "440",
      "text": "Tezpur University, Tezpur",

  },
  {
      "val": "150",
      "text": "Thapar University, Patiala",
      "isTop": "topinst"
  },
  {
      "val": "441",
      "text": "The LNM Institute of Information Technology, Jaipur",
  },
  {
      "val": "442",
      "text": "The National Institute of Engineering, Mysore",
  },
  {
      "val": "443",
      "text": "The Oxford College of Engineering, Bangalore",
  },
  {
      "val": "444",
      "text": "The Technological Institute of Textile and Sciences, Bhiwani",

  },
  {
      "val": "445",
      "text": "Thiagarajar College of Engineering, Madurai",
      "isTop": "topinst"
  },
  {
      "val": "446",
      "text": "TKM College of Engineering, Kollam",
  },
  {
      "val": "447",
      "text": "Tripura Institute of Technology, Agartala",
  },
  {
      "val": "448",
      "text": "Tula’s Institute, The Engineering and Management College, Dehradun",
  },
  {
      "val": "449",
      "text": "UBDT College of Engineering, Davangere",
  },
  {
      "val": "450",
      "text": "UIET, Chhatrapati Sahuji Maharaj University, Kanpur",
  },
  {
      "val": "451",
      "text": "Ujjain Engineering College, Ujjain",
  },
  {
      "val": "106",
      "text": "University College of Engineering, Osmania University, Hyderabad",
  },
  {
      "val": "452",
      "text": "University College of Engineering, Punjabi University, Patiala",
  },
  {
      "val": "453",
      "text": "University College of Technology, Osmania University, Hyderabad",
  },
  {
      "val": "454",
      "text": "University Institute of Chemical Engineering and Technology, Chandigarh",
  },
  {
      "val": "455",
      "text": "University Institute of Engineering and Technology, Chandigarh",

  },
  {
      "val": "456",
      "text": "University Institute of Engineering and Technology, Kurukshetra University, Kurukshetra",
      "isTop": "topinst"
  },
  {
      "val": "457",
      "text": "University Institute of Engineering and Technology, Maharshi Dayanand University, Rohtak",
  },
  {
      "val": "458",
      "text": "University Institute of Information Technology, Shimla",
  },
  {
      "val": "459",
      "text": "University Institute of Technology, Burdwan",
  },
  {
      "val": "460",
      "text": "University of Kalyani, Kalyani",
  },
  {
      "val": "461",
      "text": "University Visvesvaraya College of Engineering, Bangalore",
  },
  {
      "val": "462",
      "text": "UP Textile Technology Institute, Kanpur",
  },
  {
      "val": "463",
      "text": "Uttar Banga Krishi Vishwavidyalaya, Pundibari",
  },
  {
      "val": "464",
      "text": "Uttaranchal Institute of Technology, Dehradun",
  },
  {
      "val": "465",
      "text": "Vaagdevi College of Engineering, Warangal",
  },
  {
      "val": "466",
      "text": "Vaish College of Engineering, Rohtak",
  },
  {
      "val": "467",
      "text": "Vardhaman College of Engineering, Hyderabad",
  },
  {
      "val": "468",
      "text": "Vasavi College of Engineering, Hyderabad",
  },
  {
      "val": "469",
      "text": "Veer Surendra Sai University of Technology, Sambalpur",
  },
  {
      "val": "151",
      "text": "Veermata Jijabai Technological Institute, Mumbai",
  },
  {
      "val": "470",
      "text": "Vel Tech Dr Rangrajan Dr Sakunthala Technical University, Chennai",
  },
  {
      "val": "471",
      "text": "Vel Tech High Tech Dr. Rangarajan Dr. Sakunthala Engineering College, Chennai",
  },
  {
      "val": "472",
      "text": "Velagapudi Ramakrishna Siddhartha Engineering College, Vijaywada",
  },
  {
      "val": "473",
      "text": "Velammal Engineering College, Chennai",
  },
  {
      "val": "474",
      "text": "Vels University, Chennai",
  },
  {
      "val": "475",
      "text": "Vidya Jyothi Institute of Technology, Hyderabad",
  },
  {
      "val": "476",
      "text": "Vidyavardhaka College of Engineering, Mysore",
  },
  {
      "val": "477",
      "text": "Vignan University, Guntur",
  },
  {
      "val": "478",
      "text": "Vishnu Institute of Technology, Bhimavaram",
  },
  {
      "val": "479",
      "text": "Vishwakarma Government Engineering College, Ahmedabad",
  },
  {
      "val": "480",
      "text": "Vishwakarma Institute of Information Technology, Pune",
  },
  {
      "val": "481",
      "text": "Vishwakarma Institute of Technology, Pune",
  },
  {
      "val": "153",
      "text": "Visvesvaraya National Institute of Technology, Nagpur (VNIT)",

  },
  {
      "val": "152",
      "text": "VIT University, Vellore",
      "isTop": "topinst"
  },
  {
      "val": "482",
      "text": "VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad",
  },
  {
      "val": "483",
      "text": "VSB Engineering College, Karur",
  },
  {
      "val": "115",
      "text": "Walchand College of Engineering, Sangli",
  },
  {
      "val": "154",
      "text": "West Bengal University of Technology, Kolkata",
  },
  {
      "val": "484",
      "text": "Yeshwantrao Chavan College of Engineering, Nagpur",
  },
  {
      "val": "485",
      "text": "YMCA University of Science and Technology, Faridabad",
  },
  {
      "val": "487",
      "text": "National Institute of Technology, Kurukshetra (NIT KKR)",

  },
  {
      "val": "488",
      "text": "Indian Institute of Science (IISc)",
      "isTop": "topinst"

  },
  {
      "val": "489",
      "text": "IIM Sirmaur",
      "isTop": "topinst"
  },
  {
      "val": "490",
      "text": "Indraprastha Institute of Information Technology",

  },
  {
      "val": "491",
      "text": "IIM Amritsar",
      "isTop": "topinst"
  }
]

var instituteTagsDataUpdazz = [
  {
  text: "Aalim Muhammed Salegh College of Engineering, Chennai",
  val: "1"
}, {
  text: "ABES Engineering College, Ghaziabad",
  val: "2"
}, {
  text: "ABV- Indian Institute of Information Technology and Management, Gwalior",
  val: "3"
}, {
  text: "Acharya Institute of Technology, Bangalore",
  val: "4"
}, {
  text: "AD Patel Institute of Technology, Anand",
  val: "5"
}, {
  text: "Adesh Institute of Engineering and Technology, Faridkot",
  val: "6"
}, {
  text: "Adhiparasakthi Engineering College, Kancheepuram",
  val: "7"
}, {
  text: "Indian Institute of Technology, Delhi (IITD)",
  val: "8"
}, {
  text: "Indian Institute of Technology, Bombay (IITB)",
  val: "9"
}, {
  text: "Indian Institute of Technology, Madras (IITM)",
  val: "10"
}, {
  text: "Aditya Engineering College, Surampalem",
  val: "11"
}, {
  text: "Indian Institute of Technology, Kanpur (IITK)",
  val: "12"
}, {
  text: "Indian Institute of Technology, Kharagpur (IITKGP)",
  val: "13"
}, {
  text: "Aditya Institute of Technology and Management, Tekkali",
  val: "14"
}, {
  text: "Ajay Kumar Garg Engineering College, Ghaziabad",
  val: "15"
}, {
  text: "Alagappa Chettiar College of Engineering and Technology, Karaikudi",
  val: "16"
}, {
  text: "Alagappa College of Technology, Chennai",
  val: "17"
}, {
  text: "Aligarh Muslim University, Aligarh",
  val: "18"
}, {
  text: "Alliance College of Engineering and Design, Bangalore",
  val: "19"
}, {
  text: "Ambedkar Institute of Advanced Communication Technologies and Research, Delhi",
  val: "20"
}, {
  text: "Amity School of Engineering and Technology, Delhi",
  val: "21"
}, {
  text: "Amity School of Engineering and Technology, Noida",
  val: "22"
}, {
  text: "Amity University, Noida",
  val: "23"
}, {
  text: "Amrita School of Engineering, Coimbatore",
  val: "24"
}, {
  text: "Anand Institute of Higher Technology, Chennai",
  val: "25"
}, {
  text: "Anil Neerukonda Institute of Technology and Sciences, Vishakhapatnam",
  val: "26"
}, {
  text: "Anna University Tiruchirappalli, BIT Campus",
  val: "27"
}, {
  text: "Annamacharya Institute of Technology and Sciences, Rajampet",
  val: "28"
}, {
  text: "Arunai Engineering College, Tiruvannamalai",
  val: "29"
}, {
  text: "Asansol Engineering College, Asansol",
  val: "30"
}, {
  text: "Assam University, Silchar",
  val: "31"
}, {
  text: "ICFAI Foundation for Higher Education, Hyderabad",
  val: "32"
}, {
  text: "AU College of Engineering for Women, Vishakapattnam",
  val: "33"
}, {
  text: "AU College of Engineering, Vishakhapatnam",
  val: "34"
}, {
  text: "Audisankara College of Engineering and Technology, Gudur",
  val: "35"
}, {
  text: "Aurora's Engineering College, Nalgonda",
  val: "36"
}, {
  text: "Aurora's Scientific, Technological and Research Academy, Hyderabad",
  val: "37"
}, {
  text: "Aurora's Technological and Research Institute, Hyderabad",
  val: "38"
}, {
  text: "Avanthi Institute of Engineering and Technology, Vishakhapatnam",
  val: "39"
}, {
  text: "B K Birla Institute of Engineering and Technology, Pilani",
  val: "40"
}, {
  text: "Baddi University of Emerging Sciences and Technologies, Baddi",
  val: "41"
}, {
  text: "Banasthali University, Banasthali",
  val: "42"
}, {
  text: "Bangalore Institute of Technology, Bangalore",
  val: "43"
}, {
  text: "Bankura Unnayani Institute of Engineering, Bankura",
  val: "44"
}, {
  text: "Bannari Amman Institute of Technology, Erode",
  val: "45"
}, {
  text: "Bapatala Engineering College, Bapatala",
  val: "46"
}, {
  text: "Bapuji Institute of Engineering and Technology, Davangere",
  val: "47"
}, {
  text: "Indian Institute of Technology, Bhubaneswar (IITBBS)",
  val: "48"
}, {
  text: "Barkatullah University Institute of Technology, Barkatullah University, Bhopal",
  val: "49"
}, {
  text: "Basaveshwar Engineering College, Bagalkot",
  val: "50"
}, {
  text: "Beant College of Engineering and Technology, Gurdaspur",
  val: "51"
}, {
  text: "Bhagalpur College of Engineering, Bhagalpur",
  val: "52"
}, {
  text: "Birla Institute of Technology and Science, Pilani",
  val: "53"
}, {
  text: "Indian Institute of Technology, Guwahati (IITG)",
  val: "54"
}, {
  text: "Bharath University, Chennai",
  val: "55"
}, {
  text: "Bharati Vidyapeeth Deemed University College of Engineering, Pune",
  val: "56"
}, {
  text: "Bharati Vidyapeeth",
  val: "57"
}, {
  text: "Bhartiya Vidya Bhavans, Sardar Patel College of Engineering, Mumbai",
  val: "58"
}, {
  text: "Bhilai Institute of Technology, Durg",
  val: "59"
}, {
  text: "Bhilai Institute of Technology, Raipur",
  val: "60"
}, {
  text: "Indian Institute of Technology, Roorkee (IITR)",
  val: "61"
}, {
  text: "Bidhan Chandra Krishi Viswa Vidyalaya, Nadia",
  val: "62"
}, {
  text: "Bipin Tripathi Kumaon Institute of Technology, Dwarahat",
  val: "63"
}, {
  text: "Birbhum Institute of Engineering Technology, Birbhum",
  val: "64"
}, {
  text: "Birla Institute of Technology, Mesra",
  val: "65"
}, {
  text: "Birla Institute of Technology, Patna",
  val: "66"
}, {
  text: "BIT Sindri, Dhanbad",
  val: "67"
}, {
  text: "BITS Pilani- Hyderabad Campus, Hyderabad",
  val: "68"
}, {
  text: "BITS Pilani, Goa Campus",
  val: "69"
}, {
  text: "BMS College of Engineering, Bangalore",
  val: "70"
}, {
  text: "BMS Institute of Technology, Bangalore",
  val: "71"
}, {
  text: "BNM Institute of Technology, Bangalore",
  val: "72"
}, {
  text: "Bundelkhand Institute of Engineering and Technology, Jhansi",
  val: "73"
}, {
  text: "BV Bhoomaraddi College of Engineering and Technology, Hubli",
  val: "74"
}, {
  text: "BVRIT Padmasri Dr BV Raju Institute of Technology, Hyderabad",
  val: "75"
}, {
  text: "CBS Group of Instiutions, Jhajjar",
  val: "76"
}, {
  text: "Central Institute of Plastic Engineering Technology, Lucknow",
  val: "77"
}, {
  text: "Central Institute of Plastics Engineering and Technology, Chennai",
  val: "78"
}, {
  text: "Centurion University, Paralakhemundi",
  val: "79"
}, {
  text: "Chaitanya Bharathi Institute of Technology, Hyderabad",
  val: "80"
}, {
  text: "Chaitanya Engineering College, Vishakhapatnam",
  val: "81"
}, {
  text: "Chandigarh College of Engineering and Technology, Chandigarh",
  val: "82"
}, {
  text: "Charotar University of Science and Technology, Petlad",
  val: "83"
}, {
  text: "Chaudhary Charan Singh Haryana Agricultural University, Hisar",
  val: "84"
}, {
  text: "Chitkara University, Patiala",
  val: "85"
}, {
  text: "Christ University, Bangalore",
  val: "86"
}, {
  text: "CMR Engineering College, Hyderabad",
  val: "87"
}, {
  text: "CMR Institute of Technology, Bangalore",
  val: "88"
}, {
  text: "Indian Institute of Technology, Banaras Hindu University, Varanasi (IITBHU)",
  val: "89"
}, {
  text: "CMR Institute of Technology, Hyderabad",
  val: "90"
}, {
  text: "CMR Technical Campus, Hyderabad",
  val: "91"
}, {
  text: "Coimbatore Institute of Technology, Coimbatore",
  val: "92"
}, {
  text: "College of Dairy Science and Technology, Mannuthi, Thrissur",
  val: "93"
}, {
  text: "Indian Institute of Technology, Gandhinagar (IITGN)",
  val: "94"
}, {
  text: "Indian Institute of Technology, Hyderabad (IITH)",
  val: "95"
}, {
  text: "Indian Institute of Technology, Indore (IITI)",
  val: "96"
}, {
  text: "Indian Institute of Technology, Jodhpur (IITJ)",
  val: "97"
}, {
  text: "Indian Institute of Technology, Mandi (IITMANDI)",
  val: "98"
}, {
  text: "Indian Institute of Technology, Patna (IITP)",
  val: "99"
}, {
  text: "Indian Institute of Technology, Ropar (IITRPR)",
  val: "100"
}, {
  text: "College of Engineering Adoor",
  val: "101"
}, {
  text: "BS Abdur Rahman University, Chennai",
  val: "102"
}, {
  text: "Anna University Chennai, CEG Campus",
  val: "103"
}, {
  text: "College of Engineering and Management, Kolaghat",
  val: "104"
}, {
  text: "College of Engineering and Technology ,Bhubaneswar",
  val: "105"
}, {
  text: "University College of Engineering, Osmania University, Hyderabad",
  val: "106"
}, {
  text: "College of Engineering Roorkee, Roorkee",
  val: "107"
}, {
  text: "College of Engineering Studies, University of Petroleum and Energy Studies, Dehradun",
  val: "108"
}, {
  text: "College of Engineering, Chengannur",
  val: "109"
}, {
  text: "Delhi Technological University, Delhi",
  val: "110"
}, {
  text: "Dhirubhai Ambani Institute of Information and Communication Technology, Gandhinagar",
  val: "111"
}, {
  text: "Malaviya National Institute of Technology, Jaipur (MNIT)",
  val: "112"
}, {
  text: "College of Engineering, Pune",
  val: "113"
}, {
  text: "Harcourt Butler Technological Institute, Kanpur",
  val: "114"
}, {
  text: "Walchand College of Engineering, Sangli",
  val: "115"
}, {
  text: "Guru Gobind Singh Indraprastha University, Delhi",
  val: "116"
}, {
  text: "Indian Institute of Information Technology, Allahabad",
  val: "117"
}, {
  text: "Indian School of Mines, Dhanbad",
  val: "118"
}, {
  text: "College of Engineering, Trivandrum",
  val: "119"
}, {
  text: "International Institute of Information Technology, Hyderabad",
  val: "120"
}, {
  text: "College of Technology and Engineering, Maharana Pratap University of Agriculture and Technology, Udaipur",
  val: "121"
}, {
  text: "Manipal Institute of Technology, Manipal",
  val: "122"
}, {
  text: "MS Ramaiah Institute of Technology, Bangalore",
  val: "123"
}, {
  text: "Mepco Schlenk Engineering College, Sivakasi",
  val: "124"
}, {
  text: "Government Model Engineering College, Thrikkakara",
  val: "125"
}, {
  text: "Motilal Nehru National Institute of Technology, Allahabad (MNNIT)",
  val: "126"
}, {
  text: "College of Technology, GB Pant University of Agriculture and Technology, Pantnagar",
  val: "127"
}, {
  text: "National Institute of Technology, Srinagar (NITSRI)",
  val: "128"
}, {
  text: "National Institute of Technology, Calicut (NITC)",
  val: "129"
}, {
  text: "National Institute of Technology, Durgapur (NITDGP)",
  val: "130"
}, {
  text: "National Institute of Technology, Hamirpur (NITH)",
  val: "131"
}, {
  text: "CV Raman College of Engineering, Bhubaneswar",
  val: "132"
}, {
  text: "National Institute of Technology, Rourkela (NITRKL)",
  val: "133"
}, {
  text: "National Institute of Technology, Silchar (NITS)",
  val: "134"
}, {
  text: "National Institute of Technology, Tiruchirappalli (NITT)",
  val: "135"
}, {
  text: "National Institute of Technology, Warangal (NITW)",
  val: "136"
}, {
  text: "Netaji Subhas Institute of Technology, Delhi",
  val: "137"
}, {
  text: "CVR College of Engineering, Hyderabad",
  val: "138"
}, {
  text: "DAV Institute of Engineering and Technology, Jalandhar",
  val: "139"
}, {
  text: "Dayalbagh Educational Institute, Agra",
  val: "140"
}, {
  text: "Dayanand Sagar College of Engineering, Bangalore",
  val: "141"
}, {
  text: "Deenbandhu Chhotu Ram University of Science and Technology, Murthal",
  val: "142"
}, {
  text: "Rajagiri School of Engineering and Technology, Ernakulam",
  val: "143"
}, {
  text: "Sardar Vallabhbhai National Institute of Technology, Surat (SVNIT)",
  val: "144"
}, {
  text: "Shri Mata Vaishno Devi University, Katra",
  val: "145"
}, {
  text: "Sir M Visvesvaraya Institute of Technology, Bangalore",
  val: "146"
}, {
  text: "Dharmsinh Desai University, Nadiad",
  val: "147"
}, {
  text: "SRM University, Chennai",
  val: "148"
}, {
  text: "DIT University, Dehradun",
  val: "149"
}, {
  text: "Thapar University, Patiala",
  val: "150"
}, {
  text: "Veermata Jijabai Technological Institute, Mumbai",
  val: "151"
}, {
  text: "VIT University, Vellore",
  val: "152"
}, {
  text: "Visvesvaraya National Institute of Technology, Nagpur (VNIT)",
  val: "153"
}, {
  text: "West Bengal University of Technology, Kolkata",
  val: "154"
}, {
  text: "Don Bosco Institute of Technology, Mumbai",
  val: "155"
}, {
  text: "Dr B R Ambedkar National Institute of Technology, Jalandhar (NITJ)",
  val: "156"
}, {
  text: "Dr S and SS Ghandhy College of Engineering and Technology, Surat",
  val: "157"
}, {
  text: "Dr. Ambedkar Institute of Technology for Handicapped, Kanpur  ",
  val: "158"
}, {
  text: "Dr. Ambedkar Institute of Technology, Bangalore",
  val: "159"
}, {
  text: "Dr. Babasaheb Ambedkar Technological University, Lonere",
  val: "160"
}, {
  text: "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Banda",
  val: "161"
}, {
  text: "Dr. Bhimrao Ambedkar Engineering College of Information Technology, Bijnor",
  val: "162"
}, {
  text: "Dr. Mahalingam College of Engineering and Technology, Pollachi",
  val: "163"
}, {
  text: "Dr. Sivanthi Aditanar College of Engineering, Thoothukudi",
  val: "164"
}, {
  text: "Dronacharya College of Engineering, Gurgaon",
  val: "165"
}, {
  text: "Easwari Engineering College, Chennai",
  val: "166"
}, {
  text: "Faculty of Engineering and Technology, Manav Rachna International University, Faridabad",
  val: "167"
}, {
  text: "Feroze Gandhi Institute of Engineering and Technology, Raibareli",
  val: "168"
}, {
  text: "Fr. C Rodrigues Institute of Technology, Navi Mumbai",
  val: "169"
}, {
  text: "Fr. Conceicao Rodrigues College of Engineering, Mumbai",
  val: "170"
}, {
  text: "G Narayanamma Institute of Technology and Science (For Women), Hyderabad",
  val: "171"
}, {
  text: "G Pulla Reddy Engineering College, Kurnool",
  val: "172"
}, {
  text: "Galgotias College of Engineering and Technology, Greater Noida",
  val: "173"
}, {
  text: "Galgotias University, Greater Noida",
  val: "174"
}, {
  text: "Gandhi Institute of Engineering and Technology, Gunupur",
  val: "175"
}, {
  text: "Gayatri Vidya Parishad College of Engineering, Vishakhapatnam",
  val: "176"
}, {
  text: "GH Patel College of Engineering and Technology, Anand",
  val: "177"
}, {
  text: "GH Raisoni College of Engineering, Nagpur",
  val: "178"
}, {
  text: "Giani Zail Singh Punjab Technical University Campus, Bathinda",
  val: "179"
}, {
  text: "GLA University, Mathura",
  val: "180"
}, {
  text: "GMR Institute of Technology, Rajam",
  val: "181"
}, {
  text: "Gokaraju Rangaraju Institute of Engineering and Technology, Hyderabad",
  val: "182"
}, {
  text: "Government College of Engineering and Ceramic Technology, Kolkata",
  val: "183"
}, {
  text: "Government College of Engineering and Leather Technology,Kolkata",
  val: "184"
}, {
  text: "Government College of Engineering and Technology, Bikaner",
  val: "185"
}, {
  text: "Government College of Engineering and Technology, Jammu",
  val: "186"
}, {
  text: "Government College of Engineering and Textile Technology, Berhampore",
  val: "187"
}, {
  text: "Government College of Engineering, Aurangabad",
  val: "188"
}, {
  text: "Government College of Engineering, Bargur",
  val: "189"
}, {
  text: "Government College of Engineering, Kalahandi",
  val: "190"
}, {
  text: "Government College of Engineering, Kannur",
  val: "191"
}, {
  text: "Government College of Engineering, Karad",
  val: "192"
}, {
  text: "Government College of Engineering, Salem",
  val: "193"
}, {
  text: "Government College of Engineering, Tirunelveli",
  val: "194"
}, {
  text: "Government College of Technology, Coimbatore",
  val: "195"
}, {
  text: "Government Engineering College, Ajmer",
  val: "196"
}, {
  text: "Government Engineering College, Barton Hill, Thiruvananthapuram ",
  val: "197"
}, {
  text: "Government Engineering College, Bastar",
  val: "198"
}, {
  text: "Government Engineering College, Bhavnagar",
  val: "199"
}, {
  text: "Government Engineering College, Bikaner",
  val: "200"
}, {
  text: "Government Engineering College, Bilaspur",
  val: "201"
}, {
  text: "Government Engineering College, Gandhinagar",
  val: "202"
}, {
  text: "Government Engineering College, Jhalawar",
  val: "203"
}, {
  text: "Government Engineering College, Kozhikode",
  val: "204"
}, {
  text: "Government Engineering College, Modasa",
  val: "205"
}, {
  text: "Government Engineering College, Patan",
  val: "206"
}, {
  text: "Government Engineering College, Raipur",
  val: "207"
}, {
  text: "Government Engineering College, Rajkot",
  val: "208"
}, {
  text: "Government Engineering College, Sreekrishnapuram",
  val: "209"
}, {
  text: "Government Engineering College, Surat",
  val: "210"
}, {
  text: "Government Engineering College, Thrissur",
  val: "211"
}, {
  text: "Government Engineering College, Wayanad",
  val: "212"
}, {
  text: "Government Sri Krishnarajendra Silver Jubilee Technological Institute, Bangalore",
  val: "213"
}, {
  text: "Government Women Engineering College, Ajmer",
  val: "214"
}, {
  text: "Govind Ballabh Pant Engineering College, Pauri-Garhwal",
  val: "215"
}, {
  text: "Gudlavalleru Engineering College, Gudlavalleru",
  val: "216"
}, {
  text: "Guru Ghasidas Vishwavidyalaya, Bilaspur",
  val: "217"
}, {
  text: "Guru Jambheshwar University of Science and Technology, Hisar",
  val: "218"
}, {
  text: "Guru Nanak Dev Engineering College, Bidar",
  val: "219"
}, {
  text: "Guru Nanak Dev Engineering College, Ludhiana",
  val: "220"
}, {
  text: "Guru Nanak Dev University Regional Campus Jalandhar",
  val: "221"
}, {
  text: "Guru Nanak Dev University, Amritsar",
  val: "222"
}, {
  text: "Guru Nanak Institutions Technical Campus, Secunderabad",
  val: "223"
}, {
  text: "Gurukul Kangri Vishwavidyalaya, Haridwar",
  val: "224"
}, {
  text: "Haldia Institute of Technology, Haldia",
  val: "225"
}, {
  text: "Heritage Institute of Technology, Kolkata",
  val: "226"
}, {
  text: "Hindu College of Engineering, Sonipat",
  val: "227"
}, {
  text: "Hindustan University, Kelambakkam",
  val: "228"
}, {
  text: "Hindusthan College of Engineering and Technology, Coimbatore",
  val: "229"
}, {
  text: "Indian Institute of Carpet Technology, Bhadohi",
  val: "230"
}, {
  text: "Indian Institute of Engineering Science and Technology, Shibpur",
  val: "231"
}, {
  text: "Indian Institute of Information Technology, Design and Manufacturing, Jabalpur",
  val: "232"
}, {
  text: "Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram",
  val: "233"
}, {
  text: "Indian Institute of Science Education and Research, Bhopal",
  val: "234"
}, {
  text: "Indian Institute of Science Education and Research, Kolkata",
  val: "235"
}, {
  text: "Indian Institute of Science Education and Research, Mohali",
  val: "236"
}, {
  text: "Indian Institute of Science Education and Research, Pune",
  val: "237"
}, {
  text: "Indian Institute of Science Education and Research, Thiruvananthapuram",
  val: "238"
}, {
  text: "Indian Institute of Space Science and Technology, Thiruvananthapuram",
  val: "239"
}, {
  text: "Indira Gandhi Institute of Technology, Delhi",
  val: "240"
}, {
  text: "Indira Gandhi Institute of Technology, Sarang",
  val: "241"
}, {
  text: "Indraprastha Institute of Information Technology, Delhi",
  val: "242"
}, {
  text: "Institute of Chemical Technology, Mumbai",
  val: "243"
}, {
  text: "Institute of Engineering and Management, Kolkata",
  val: "244"
}, {
  text: "Institute of Engineering and Rural Technology, Allahabad",
  val: "245"
}, {
  text: "Institute of Engineering and Science, IPS Academy, Indore",
  val: "246"
}, {
  text: "Institute of Engineering and Technology, Alwar",
  val: "247"
}, {
  text: "Institute of Engineering and Technology, Indore",
  val: "248"
}, {
  text: "Institute of Engineering and Technology, Lucknow",
  val: "249"
}, {
  text: "Institute of Information and Communication Technology, Ahmedabad",
  val: "250"
}, {
  text: "Institute of Infrastructure, Technology, Research and Management, Ahmedabad",
  val: "251"
}, {
  text: "Institute of Road and Transport Technology, Erode",
  val: "252"
}, {
  text: "Institute of Science and Technology, Jawaharlal Nehru Technological University, Hyderabad",
  val: "253"
}, {
  text: "Integral University, Lucknow",
  val: "254"
}, {
  text: "International Institute of Information Technology, Bangalore",
  val: "255"
}, {
  text: "International Institute of Information Technology, Bhubaneswar",
  val: "256"
}, {
  text: "International Institute of Information Technology, Pune",
  val: "257"
}, {
  text: "Islamic University of Science and Technology, Pulwama",
  val: "258"
}, {
  text: "ITM University, Gwalior",
  val: "259"
}, {
  text: "Jabalpur Engineering College, Jabalpur",
  val: "260"
}, {
  text: "Jadavpur University, Kolkata",
  val: "261"
}, {
  text: "Jaipur Engineering College and Research Centre, Jaipur",
  val: "262"
}, {
  text: "Jaipur National University, Jaipur",
  val: "263"
}, {
  text: "Jalpaiguri Government Engineering College, Jalpaiguri",
  val: "264"
}, {
  text: "Jamia Millia Islamia, New Delhi",
  val: "265"
}, {
  text: "Jawaharlal Nehru Technological University College of Engineering, Kakinada",
  val: "266"
}, {
  text: "Jawaharlal Nehru Technological University, Kakinada",
  val: "267"
}, {
  text: "Jaypee Institute of Information Technology, Noida",
  val: "268"
}, {
  text: "Jaypee University of Information Technology, Solan",
  val: "269"
}, {
  text: "Jiwaji University, Gwalior",
  val: "270"
}, {
  text: "JNTU College of Engineering, Hyderabad",
  val: "271"
}, {
  text: "JNTU, University College of Engineering, Vizianagaram ",
  val: "272"
}, {
  text: "JNTUH College of Engineering, Karimnagar",
  val: "273"
}, {
  text: "Jodhpur National University, Jodhpur",
  val: "274"
}, {
  text: "Joginpally B R Engineering College, Moinabad",
  val: "275"
}, {
  text: "JSS Academy of Technical Education, Bangalore",
  val: "276"
}, {
  text: "JSS Academy of Technical Education, Noida",
  val: "277"
}, {
  text: "Kakatiya Institute of Technology and Science, Warangal",
  val: "278"
}, {
  text: "Kalinga Institute of Industrial Technology, Bhubaneswar",
  val: "279"
}, {
  text: "Kamla Nehru Institute of Technology, Sultanpur",
  val: "280"
}, {
  text: "Karunya University, Coimbatore",
  val: "281"
}, {
  text: "Kasegaon Education Society’s Rajarambapu Institute of Technology, Sangli",
  val: "282"
}, {
  text: "KJ Somaiya College of Engineering, Mumbai",
  val: "283"
}, {
  text: "KJ Somaiya College of Engineering, Mumbai",
  val: "284"
}, {
  text: "KLS Gogte Institute of Technology, Belgaum",
  val: "285"
}, {
  text: "Kongu Engineering College, Erode",
  val: "286"
}, {
  text: "Krishna Institute of Engineering and Technology, Ghaziabad",
  val: "287"
}, {
  text: "Kumaraguru College of Technology, Coimbatore",
  val: "288"
}, {
  text: "Lakshmi Narain College of Technology, Bhopal",
  val: "289"
}, {
  text: "LBS College of Engineering, Kasargod",
  val: "290"
}, {
  text: "LBS Institute of Technology for Women, Thiruvananthapuram",
  val: "291"
}, {
  text: "LD College of Engineering, Ahmedabad",
  val: "292"
}, {
  text: "Lovely Professional University, Jalandhar",
  val: "293"
}, {
  text: "Lukhdhirji Engineering College, Morbi",
  val: "294"
}, {
  text: "M Kumarasamy College of Engineering, Karur",
  val: "295"
}, {
  text: "Madan Mohan Malaviya University of Technology. Gorakhpur",
  val: "296"
}, {
  text: "Madanpalle Institute of Technology and Science, Madanapalle",
  val: "297"
}, {
  text: "Madhav Institute of Technology and Science, Gwalior",
  val: "298"
}, {
  text: "Madras Institute of Technology, Chennai",
  val: "299"
}, {
  text: "Maharaj Vijayaram Gajapathi Raj College of Engineering, Vizianagaram",
  val: "300"
}, {
  text: "Maharaja Agrasen Institute of Technology, Delhi",
  val: "301"
}, {
  text: "Maharaja Sayajirao University of Baroda, Vadodara",
  val: "302"
}, {
  text: "Maharashtra Institute of Technology, Pune",
  val: "303"
}, {
  text: "Maharishi Markandeshwar University, Ambala ",
  val: "304"
}, {
  text: "Mahatma Gandhi Institute of Technology, Hyderabad",
  val: "305"
}, {
  text: "Mahatma Jyotiba Phule Rohilkhand University, Bareilly",
  val: "306"
}, {
  text: "Malla Reddy College of Engineering and Technology, Hyderabad",
  val: "307"
}, {
  text: "Malla Reddy Engineering College, Hyderabad",
  val: "308"
}, {
  text: "Malnad College of Engineering, Hassan",
  val: "309"
}, {
  text: "Manav Rachna College of Engineering, Faridabad",
  val: "310"
}, {
  text: "Manyawar Kansi Ram Engineering College of Information Technology, Ambedkar Nagar",
  val: "311"
}, {
  text: "Manyawar Kansi Ram Engineering College of Information Technology, Azamgarh",
  val: "312"
}, {
  text: "Mar Athanasius College of Engineering, Kothamangalam",
  val: "313"
}, {
  text: "Maturi Venkata Subba Rao Engineering College, Hyderabad",
  val: "314"
}, {
  text: "Maulana Azad National Institute of Technology, Bhopal (MANIT)",
  val: "315"
}, {
  text: "MBM Engineering College, Jodhpur",
  val: "316"
}, {
  text: "MCT’s Rajiv Gandhi Institute of Technology, Mumbai",
  val: "317"
}, {
  text: "Medi-Caps Institute of Science and Technology, Indore",
  val: "318"
}, {
  text: "Medi-Caps Institute of Technology and Management, Indore",
  val: "319"
}, {
  text: "Meenakshi Sundararajan Engineering College, Chennai",
  val: "320"
}, {
  text: "MES College of Engineering, Malappuram",
  val: "321"
}, {
  text: "Mizoram University, Aizawl",
  val: "322"
}, {
  text: "MLV Government Textile and Engineering College, Bhilwara",
  val: "323"
}, {
  text: "Mody University of Science and Technology, Sikar",
  val: "324"
}, {
  text: "MS Engineering College, Bangalore",
  val: "325"
}, {
  text: "Mukesh Patel School of Technology Management and Engineering, Mumbai",
  val: "326"
}, {
  text: "Muzaffarpur Institute of Technology, Muzaffarpur",
  val: "327"
}, {
  text: "MVJ College of Engineering, Bangalore",
  val: "328"
}, {
  text: "Narasaraopeta Engineering College, Narasaraopet",
  val: "329"
}, {
  text: "National Dairy Research Institute, Karnal",
  val: "330"
}, {
  text: "National Institute of Foundry and Forge Technology, Ranchi",
  val: "331"
}, {
  text: "National Institute of Science Education and Research, Bhubaneswar",
  val: "332"
}, {
  text: "National Institute of Technology, Agartala (NITA)",
  val: "333"
}, {
  text: "National Institute of Technology, Delhi (NITDELHI)",
  val: "334"
}, {
  text: "National Institute of Technology, Goa (NITGOA)",
  val: "335"
}, {
  text: "National Institute of Technology, Jamshedpur (NITJSR)",
  val: "336"
}, {
  text: "National Institute of Technology, Kurukshetra (NITKKR)",
  val: "337"
}, {
  text: "National Institute of Technology, Mizoram (NITMZ)",
  val: "338"
}, {
  text: "National Institute of Technology, Nagaland (NITNAGALAND)",
  val: "339"
}, {
  text: "National Institute of Technology, Patna (NITP)",
  val: "340"
}, {
  text: "National Institute of Technology, Puducherry (NITPY)",
  val: "341"
}, {
  text: "National Institute of Technology, Raipur (NITRR)",
  val: "342"
}, {
  text: "National Institute of Technology, Sikkim (NITSIKKIM)",
  val: "343"
}, {
  text: "National Institute of Technology, Surathkal (NITK)",
  val: "344"
}, {
  text: "National Institute of Technology, Uttarakhand (NITUK)",
  val: "345"
}, {
  text: "National Power Training Institute, Durgapur",
  val: "346"
}, {
  text: "National Power Training Institute, Faridabad",
  val: "347"
}, {
  text: "NC College of Engineering, Israna",
  val: "348"
}, {
  text: "Netaji Subhash Engineering College, Garia",
  val: "349"
}, {
  text: "New Horizon College of Engineering, Bangalore",
  val: "350"
}, {
  text: "NIIT University, Neemrana",
  val: "351"
}, {
  text: "Nirma University, Ahmedabad",
  val: "352"
}, {
  text: "Nitte Meenakshi Institute of Technology, Bangalore",
  val: "353"
}, {
  text: "Nizam Institute of Engineering and Technology, Hyderabad",
  val: "354"
}, {
  text: "NMAM Institute of Technology, Karkala Taluk",
  val: "355"
}, {
  text: "North Eastern Regional Institute of Science and Technology, Nirjuli",
  val: "356"
}, {
  text: "NSS College of Engineering, Palakkad",
  val: "357"
}, {
  text: "Orissa School of Mining Engineering, Keonjhar",
  val: "358"
}, {
  text: "PA College of Engineering, Mangalore",
  val: "359"
}, {
  text: "Pandit Deendayal Petroleum University- School of Technology, Gandhinagar",
  val: "360"
}, {
  text: "Panimalar Engineering College, Chennai",
  val: "361"
}, {
  text: "Panipat Institute of Engineering and Technology, Panipat",
  val: "362"
}, {
  text: "Panjab University, Chandigarh",
  val: "363"
}, {
  text: "Panjab University, SSG Regional Centre, Hoshiarpur",
  val: "364"
}, {
  text: "Parala Maharaja Engineering College, Berhampur",
  val: "365"
}, {
  text: "Parvatha Reddy Babul Reddy Visvodaya Institute of Technology and Science, Nellore",
  val: "366"
}, {
  text: "PES College of Engineering, Mandya",
  val: "367"
}, {
  text: "PES Institute of Technology, Bangalore South Campus, Bangalore",
  val: "368"
}, {
  text: "Pondicherry Engineering College, Puducherry",
  val: "369"
}, {
  text: "Poojya Doddappa Appa College, Gulbarga",
  val: "370"
}, {
  text: "Poornima College of Engineering, Jaipur",
  val: "371"
}, {
  text: "Pragati Engineering College, Kakinada",
  val: "372"
}, {
  text: "PSG College of Technology, Coimbatore",
  val: "373"
}, {
  text: "Punjab Agricultural University, Ludhiana",
  val: "374"
}, {
  text: "Punjab Engineering College University of Technology, Chandigarh",
  val: "375"
}, {
  text: "QIS College of Engineering and Technology, Ongole",
  val: "376"
}, {
  text: "Quantum School of Technology, Roorkee",
  val: "377"
}, {
  text: "Rajasthan Technical University, Kota",
  val: "378"
}, {
  text: "Rajeev Gandhi Memorial College of Engineering and Technology, Kurnool",
  val: "379"
}, {
  text: "Rajiv Gandhi Institute of Technology, Kottayam",
  val: "380"
}, {
  text: "Rajiv Gandhi Proudyogiki Vishwavidyalaya, Bhopal",
  val: "381"
}, {
  text: "Rayat Bahra Faculty of Engineering and Technology, Patiala Campus, Patiala",
  val: "382"
}, {
  text: "RCC Institute of Information Technology, Beliaghata",
  val: "383"
}, {
  text: "Reva Institute of Technology and Management, Bangalore",
  val: "384"
}, {
  text: "Rewa Engineering College, Rewa",
  val: "385"
}, {
  text: "RMK Engineering College, Thiruvallur",
  val: "386"
}, {
  text: "RNS Institute of Technology, Bangalore",
  val: "387"
}, {
  text: "RTE Sociey",
  val: "388"
}, {
  text: "Rungta College of Engineering and Technology, Bhilai",
  val: "389"
}, {
  text: "Rustamji Institute of Technology, Gwalior",
  val: "390"
}, {
  text: "RV College of Engineering, Bangalore",
  val: "391"
}, {
  text: "Sagi Ramakrishnam Raju Engineering College, Bhimavaram",
  val: "392"
}, {
  text: "Sam Higginbottom Institute of Agriculture, Technology and Science, Allahabad",
  val: "393"
}, {
  text: "Samrat Ashok Technological Institute, Vidisha",
  val: "394"
}, {
  text: "Sant Longowal Institute of Engineering and Technology, Longowal",
  val: "395"
}, {
  text: "SASTRA University, Thanjavur",
  val: "396"
}, {
  text: "Sathyabama University, Chennai",
  val: "397"
}, {
  text: "Satyam Institute of Engineering and Technology, Amritsar",
  val: "398"
}, {
  text: "Saveetha Engineering College, Chennai",
  val: "399"
}, {
  text: "School of Engineering and Technology, Jain University, Bangalore",
  val: "400"
}, {
  text: "School of Engineering, Cochin University of Science and Technology, Ernakulam",
  val: "401"
}, {
  text: "SCMS School of Engineering and Technology, Ernakulum",
  val: "402"
}, {
  text: "SDM College of Engineering and Technology, Dharwad",
  val: "403"
}, {
  text: "Seth Jai Parkash Mukand Lal Institute of Engineering and Technology, Yamunanagar",
  val: "404"
}, {
  text: "Shaheed Bhagat Singh State Technical Campus, Firozpur",
  val: "405"
}, {
  text: "Shantilal Shah Engineering College, Bhavnagar",
  val: "406"
}, {
  text: "Sharda University, Greater Noida",
  val: "407"
}, {
  text: "Shiv Nadar University, Dadri",
  val: "408"
}, {
  text: "Shivaji University, Kolhapur",
  val: "409"
}, {
  text: "Shobhit Institute of Engineering and  Technology, Meerut",
  val: "410"
}, {
  text: "Shri GS Institute of Technology and Science, Indore",
  val: "411"
}, {
  text: "Shri Guru Gobind Singhji Institute of Engineering and Technology, Vishnupuri",
  val: "412"
}, {
  text: "Shri Ram Murti Smarak College of Engineering and Technology, Bareilly",
  val: "413"
}, {
  text: "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
  val: "414"
}, {
  text: "Shri Shankaracharya Engineering College, Bhilai",
  val: "415"
}, {
  text: "Shri Vaishnav Institute of Technology and Science, Indore",
  val: "416"
}, {
  text: "Siddaganga Institute of Technology, Tumkur",
  val: "417"
}, {
  text: "Siksha O' Anusandhan University, Bhubaneshwar",
  val: "418"
}, {
  text: "Silicon Institute of Technology, Bhubaneswar",
  val: "419"
}, {
  text: "Sinhgad College of Engineering, Pune",
  val: "420"
}, {
  text: "SJB Institute of Technology, Bangalore",
  val: "421"
}, {
  text: "Sona College of Technology, Salem",
  val: "422"
}, {
  text: "Sree Chitra Thirunal College of Engineering, Thiruvananthapuram ",
  val: "423"
}, {
  text: "Sree Vidyanikethan Engineering College, Tirupati",
  val: "424"
}, {
  text: "Sreenidhi Institute of Science and Technology, Ghatkesar",
  val: "425"
}, {
  text: "Sri Aditya Engineering College, Surampalem",
  val: "426"
}, {
  text: "Sri Jayachamarajendra College of Engineering, Mysore",
  val: "427"
}, {
  text: "Sri Krishna College of Engineering and Technology, Coimbatore",
  val: "428"
}, {
  text: "Sri Manakula Vinayagar Engineering College, Puducherry",
  val: "429"
}, {
  text: "Sri Ramakrishna Engineering College, Coimbatore",
  val: "430"
}, {
  text: "Sri Sai Ram Engineering College, Chennai",
  val: "431"
}, {
  text: "Sri Siddhartha Institute of Technology, Tumkur",
  val: "432"
}, {
  text: "Sri Venkateswara College of Engineering, Sriperumbudur",
  val: "433"
}, {
  text: "Sri Venkateswara University, Tirupati",
  val: "434"
}, {
  text: "Sri Vishnu Engineering College for Women, Bhimavaram",
  val: "435"
}, {
  text: "St. Ann",
  val: "436"
}, {
  text: "Swami Keshvanand Institute of Technology, Management and Gramothan, Jaipur",
  val: "437"
}, {
  text: "Symbiosis International University, Pune",
  val: "438"
}, {
  text: "Techno India University, Kolkata",
  val: "439"
}, {
  text: "Tezpur University, Tezpur",
  val: "440"
}, {
  text: "The LNM Institute of Information Technology, Jaipur",
  val: "441"
}, {
  text: "The National Institute of Engineering, Mysore",
  val: "442"
}, {
  text: "The Oxford College of Engineering, Bangalore",
  val: "443"
}, {
  text: "The Technological Institute of Textile and Sciences, Bhiwani",
  val: "444"
}, {
  text: "Thiagarajar College of Engineering, Madurai",
  val: "445"
}, {
  text: "TKM College of Engineering, Kollam",
  val: "446"
}, {
  text: "Tripura Institute of Technology, Agartala",
  val: "447"
}, {
  text: "Tula’s Institute, The Engineering and Management College, Dehradun",
  val: "448"
}, {
  text: "UBDT College of Engineering, Davangere",
  val: "449"
}, {
  text: "UIET, Chhatrapati Sahuji Maharaj University, Kanpur",
  val: "450"
}, {
  text: "Ujjain Engineering College, Ujjain",
  val: "451"
}, {
  text: "University College of Engineering, Punjabi University, Patiala",
  val: "452"
}, {
  text: "University College of Technology, Osmania University, Hyderabad",
  val: "453"
}, {
  text: "University Institute of Chemical Engineering and Technology, Chandigarh",
  val: "454"
}, {
  text: "University Institute of Engineering and Technology, Chandigarh",
  val: "455"
}, {
  text: "University Institute of Engineering and Technology, Kurukshetra University, Kurukshetra",
  val: "456"
}, {
  text: "University Institute of Engineering and Technology, Maharshi Dayanand University, Rohtak",
  val: "457"
}, {
  text: "University Institute of Information Technology, Shimla",
  val: "458"
}, {
  text: "University Institute of Technology, Burdwan",
  val: "459"
}, {
  text: "University of Kalyani, Kalyani",
  val: "460"
}, {
  text: "University Visvesvaraya College of Engineering, Bangalore",
  val: "461"
}, {
  text: "UP Textile Technology Institute, Kanpur",
  val: "462"
}, {
  text: "Uttar Banga Krishi Vishwavidyalaya, Pundibari",
  val: "463"
}, {
  text: "Uttaranchal Institute of Technology, Dehradun",
  val: "464"
}, {
  text: "Vaagdevi College of Engineering, Warangal",
  val: "465"
}, {
  text: "Vaish College of Engineering, Rohtak",
  val: "466"
}, {
  text: "Vardhaman College of Engineering, Hyderabad",
  val: "467"
}, {
  text: "Vasavi College of Engineering, Hyderabad",
  val: "468"
}, {
  text: "Veer Surendra Sai University of Technology, Sambalpur",
  val: "469"
}, {
  text: "Vel Tech Dr Rangrajan Dr Sakunthala Technical University, Chennai",
  val: "470"
}, {
  text: "Vel Tech High Tech Dr. Rangarajan Dr. Sakunthala Engineering College, Chennai",
  val: "471"
}, {
  text: "Velagapudi Ramakrishna Siddhartha Engineering College, Vijaywada",
  val: "472"
}, {
  text: "Velammal Engineering College, Chennai",
  val: "473"
}, {
  text: "Vels University, Chennai",
  val: "474"
}, {
  text: "Vidya Jyothi Institute of Technology, Hyderabad",
  val: "475"
}, {
  text: "Vidyavardhaka College of Engineering, Mysore",
  val: "476"
}, {
  text: "Vignan University, Guntur",
  val: "477"
}, {
  text: "Vishnu Institute of Technology, Bhimavaram",
  val: "478"
}, {
  text: "Vishwakarma Government Engineering College, Ahmedabad",
  val: "479"
}, {
  text: "Vishwakarma Institute of Information Technology, Pune",
  val: "480"
}, {
  text: "Vishwakarma Institute of Technology, Pune",
  val: "481"
}, {
  text: "VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad",
  val: "482"
}, {
  text: "VSB Engineering College, Karur",
  val: "483"
}, {
  text: "Yeshwantrao Chavan College of Engineering, Nagpur",
  val: "484"
}, {
  text: "YMCA University of Science and Technology, Faridabad",
  val: "485"
}, {
  text: "National Institute of Technology, Kurukshetra (NIT KKR)",
  val: "487"
}, {
  text: "Indian Institute of Science (IISc)",
  val: "488"
}, {
  text: "IIM Sirmaur",
  val: "489"
}, {
  text: "Indraprastha Institute of Information Technology",
  val: "490"
}, {
  text: "IIM Amritsar",
  val: "491"
}]


export function getInstitutesName() {
  let institutes = [];
  if (process.env.REACT_APP_PLATFORM == 'iimjobs'){
      institutes = instituteTagsDataIimjobs
  }
  else if(process.env.REACT_APP_PLATFORM == 'updazz'){
      institutes = instituteTagsDataUpdazz
  }
  else{
      institutes = instituteTagsDataHirist
  }
  return institutes.map(item => {
      return {
          label: item.text,
          value: item.text.toString(),
          key: item.text,
          isTop: item.isTop
      }
  })
}

export function getInstitutes() {
  let institutes = [];
  if (process.env.REACT_APP_PLATFORM == 'iimjobs'){
      institutes = instituteTagsDataIimjobs
  }else{
      institutes = instituteTagsDataHirist
  }
  return institutes.map(item => {
      return {
          label: item.text,
          value: item.val.toString(),
          key: item.val,
          isTop: item.isTop
      }
  })
}

export function getInstituteMap() {
    const instittutes = getInstitutes()
    return instittutes.reduce((p,c) => {
        p[c.value] = c.label;
        return p;
    }, {})
}