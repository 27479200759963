export const numberOfEmployeesObj = [
    {
        id: 0,
        name: 'Select number of employees',
        label: 'Select number of employees',
        value: 0
    },
    {
        id: 1,
        name: '1-10',
        value: 1,
        label: '1-10'
    },
    {
        id: 2,
        name: '11-50',
        value: 2,
        label: '11-50'
    },
    {
        id: 3,
        name: '51-200',
        value: 3,
        label: '51-200'
    },
    {
        id: 4,
        name: '201-500',
        value: 4,
        label: '201-500'
    },
    {
        id: 5,
        name: '501-1000',
        value: 5,
        label: '501-1000'
    },
    {
        id: 6,
        name: '1001-5000',
        value: 6,
        label: '1001-5000'
    },
    {
        id: 7,
        name: '5001-10000',
        value: 7,
        label: '5001-10000'
    },
    {
        id: 8,
        name: '10000+',
        value: 8,
        label: '10000+'
    }
]

export function getNumberOfEmployees() {
    return numberOfEmployeesObj
}

export function getNoOfEmpFromId () {
    return numberOfEmployeesObj.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}
