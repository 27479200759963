import React from 'react';
import PropTypes from 'prop-types';
import { refreshJob, unpublishJob, rejectJob, deleteJob, onHoldJob ,rejectPost, deleteBill } from '../../models/actionIcons';
import { Popup } from 'semantic-ui-react';

import './ActionIcons.scss';

const actionIcons = (props) => {
    let typeIcon;

    if (props.type == 'refreshJob') typeIcon = refreshJob;
    if (props.type == 'unpublishJob') typeIcon = unpublishJob;
    if (props.type == 'rejectJob') typeIcon = rejectJob;
    if (props.type == 'deleteJob') typeIcon = deleteJob;
    if (props.type == 'onHoldJob') typeIcon = onHoldJob;
    if (props.type == 'rejectPost') typeIcon = rejectPost;
    if (props.type == 'deleteBill') typeIcon = deleteBill


    return (
        <Popup
            trigger={<button className='imageBox' onClick={props.onclick}>
                <img src={typeIcon.src} />
            </button>}
            content={props.tooltipMessage ? props.tooltipMessage : typeIcon.title}
            position={props.positionTooltip ? props.positionTooltip : 'bottom center'}
            inverted disabled={false}
        />
    )
}

actionIcons.propTypes = {
    type: PropTypes.oneOf(['refreshJob', 'unpublishJob', 'rejectJob', 'deleteJob', 'onHoldJob','rejectPost', 'deleteBill']),
    onclick: PropTypes.func,
    tooltipMessage: PropTypes.string,
    positionTooltip: PropTypes.string
}

export default actionIcons;