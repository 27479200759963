import React from 'react';
import PropTypes from 'prop-types';

import './Heading.scss';

const heading = (props) => (
    <h2 className="heading">{props.text}</h2>
)

heading.propTypes = {
  text: PropTypes.string
}

export default heading;