import React from "react";
import { useState } from "react";
import InputBox from "../../../components/InputBoxWithoutCheck";
import Button from "../../../components/Button";

import Heading from "../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var scrollToElement = require("scroll-to-element");

import user from "../../../store/user";
import FromToDatePicker from "../../../components/FromToDatepicker";
import { useEffect } from "react";
import fetchClientDetails from "../../../services/fetchClientDetails";
import validator from "validator";

import './addClient.scss'
import updateClient from "../../../services/updateClient";
import addClient from "../../../services/addClient";

const validationMsg = {
  emailReq: 'Please enter email',
  domainReq: 'Please enter domain',
  dateReq: 'Please enter both plan start and end date',
  premiumPostingReq: 'Please enter premium posting',
  loggedInReq: 'Please enter no. of logged in',
  salesManagerReq: 'Please enter sales manager',
  locationReq: 'Please enter location',
  ticketSizeReq: 'Please enter ticket size',
  brandingReq: 'Please enter branding',
  pocReq: 'Please enter POC',
  headTaReq: 'Please enter Head TA',
  premiumPostingNotInt: 'Decimal is not allowed',
  loggedInNotInt: 'Decimal is not allowed',
  ticketSizeNotInt: 'Decimal is not allowed'
}

const AddClient = (props) => {

  const id = props.match.params.id;


  const [details, setDetails] = useState({
    email:'',
    domain: '',
    planStartDate: '',
    planEndDate: '',
    premiumPosting: '',
    loggedIn: '',
    salesManager: '',
    location: '',
    ticketSize: '',
    branding: '',
    poc: '',
    headTa: ''
  })

  const [validations, setValidations] = useState({})
  const [submitApiCall, setSubmitApiCall] = useState(false)
  const [admin, setAdmin] = useState({
    adminId: null
  })

  useEffect(() => {
    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id
        })

        if(id) {
          getClientDetail(userData.id);
        }

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });
  }, [])

  const getClientDetail = async(adminId) => {

    const res = await fetchClientDetails(adminId, id)
    const data = res.data.data;
    setDetails({
      email: data.mgr_email,
      domain: data.domain,
      planStartDate: new Date(data.plan_start_date),
      planEndDate: new Date(data.plan_end_date),
      premiumPosting: data.premium_posting,
      loggedIn: data.no_of_login,
      salesManager: data.sales_manager,
      location: data.location,
      ticketSize: data.ticket_size,
      branding: data.branding,
      poc: data.poc,
      headTa: data.head_ta
    })
    
  }

  const onInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setDetails(prev => ({
        ...prev,
        [name]: value
      }))
  }

  const validate = () => {
     const validations = {
      emailReq: checkEmpty(details.email),
      domainReq: checkEmpty(details.domain),
      dateReq: checkEmpty(details.planStartDate) && checkEmpty(details.planEndDate),
      premiumPostingReq: checkEmpty(details.premiumPosting),
      loggedInReq: checkEmpty(details.loggedIn),
      salesManagerReq: checkEmpty(details.salesManager),
      locationReq: checkEmpty(details.location),
      ticketSizeReq: checkEmpty(details.ticketSize),
      brandingReq: checkEmpty(details.branding),
      pocReq: checkEmpty(details.poc),
      headTaReq: checkEmpty(details.headTa),
      premiumPostingNotInt: !Number.isInteger(+details.premiumPosting),
      loggedInNotInt: !Number.isInteger(+details.loggedIn),
      ticketSizeNotInt: !Number.isInteger(+details.ticketSize),
     }
     setValidations({...validations})

     for (let key in validations) {
      if (validations[key]) {
        // scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;

  }

  const checkEmpty = (val) => {
    if (!val) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if(validate()) {
      const data = {
        status: 1,
        ...details
      }

      try {
        if(id) {
          await updateClient(admin.adminId, id, data);
          
          toast.success('Client updated successfully');
          
          setTimeout(() =>  props.history.push('/clientEngagementReportsheet'), 2000)
        }

        else {
          await addClient(admin.adminId, data);
          toast.success('Client added successfully');

          setDetails({});
          
          setTimeout(() =>  props.history.push('/clientEngagementReportsheet'), 2000)
        }

      }
      catch(e){
        console.log(e);

        if(e.message.includes('404')) {
          return toast.error('Enter Valid Manager Email Id');
        }
        toast.error('Something went wrong');
      }
      
    }
  }

  return (
    <div>
      <div className="wrapper">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />

        <div className="pageHeading">
          <Heading text={id ? 'Update Client': 'Add Client'}/>
          <Button
            className='btn_ce_view_report'
            skin="dark"
            text="View Report"
            clickButton={() => {
              props.history.push('/clientEngagementReportsheet')
            }}
          />
          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/add_client_engagement.php`} target='_blank'>
              <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
          </a>
        </div>
        <hr />
        <div className="create-tag-mailer">
          <form>
            <div className="row section details-section">
              <p className="label-heading"> {id ? "" : "New "}Client Details </p>

              <div className="form-group">
                <label className="col-sm-2 left"> Manager Email id *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="email"
                    value={details.email}
                    placeholder="Manager Email id"
                    onChange={onInputChange}
                  />
                  {validations.emailReq ? <p className='validatorError'> {validationMsg.emailReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left">Domain *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="domain"
                    value={details.domain}
                    placeholder="Domain"
                    onChange={onInputChange}
                  />
                  {validations.domainReq ? <p className='validatorError'> {validationMsg.domainReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Plan Duration *</label>
                <div className="col-sm-6 left valueBox">
                <FromToDatePicker
                    name="date"
                    startDate={details.planStartDate}
                    endDate={details.planEndDate}
                    handleChangeStart={(e) => {
                        setDetails({
                            ...details,
                            planStartDate: e,
                        });
                    }}
                    handleChangeEnd={(e) => {
                        setDetails({
                            ...details,
                            planEndDate: e,
                        });
                    }}
                />
                  {validations.dateReq ? <p className='validatorError'> {validationMsg.dateReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Premium Posting *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="number"
                    name="premiumPosting"
                    value={details.premiumPosting}
                    placeholder="Premium Posting"
                    onChange={onInputChange}
                  />
                  {validations.premiumPostingReq ? <p className='validatorError'> {validationMsg.premiumPostingReq} </p> : null}
                  {validations.premiumPostingNotInt ? <p className='validatorError'> {validationMsg.premiumPostingNotInt} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> No. of Logged in *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="number"
                    name="loggedIn"
                    value={details.loggedIn}
                    placeholder="No. of Logged in"
                    onChange={onInputChange}
                  />
                  {validations.loggedInReq ? <p className='validatorError'> {validationMsg.loggedInReq} </p> : null}
                  {validations.loggedInNotInt ? <p className='validatorError'> {validationMsg.loggedInNotInt} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Sales Manager *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="salesManager"
                    value={details.salesManager}
                    placeholder="Sales Manager"
                    onChange={onInputChange}
                  />
                  {validations.salesManagerReq ? <p className='validatorError'> {validationMsg.salesManagerReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Location *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="location"
                    value={details.location}
                    placeholder="Location"
                    onChange={onInputChange}
                  />
                  {validations.locationReq ? <p className='validatorError'> {validationMsg.locationReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Ticket Size *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="number"
                    name="ticketSize"
                    value={details.ticketSize}
                    placeholder="Ticket Size"
                    onChange={onInputChange}
                  />
                  {validations.ticketSizeReq ? <p className='validatorError'> {validationMsg.ticketSizeReq} </p> : null}
                  {validations.ticketSizeNotInt ? <p className='validatorError'> {validationMsg.ticketSizeNotInt} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Branding *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="branding"
                    value={details.branding}
                    placeholder="Branding"
                    onChange={onInputChange}
                  />
                  {validations.brandingReq ? <p className='validatorError'> {validationMsg.brandingReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> POC *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="poc"
                    value={details.poc}
                    placeholder="POC"
                    onChange={onInputChange}
                  />
                  {validations.pocReq ? <p className='validatorError'> {validationMsg.pocReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Head TA *</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="headTa"
                    value={details.headTa}
                    placeholder="Head TA"
                    onChange={onInputChange}
                  />
                  {validations.headTaReq ? <p className='validatorError'> {validationMsg.headTaReq} </p> : null}
                </div>
              </div>
            </div>

            <div className="actions">
              <Button
                skin="dark"
                disabled={submitApiCall}
                loading={submitApiCall}
                text={id ? "Update" : "Submit"}
                clickButton={onSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddClient