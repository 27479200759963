import React from "react";
import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBoxWithoutCheck';
import Button from '../../../components/Button';
import user from '../../../store/user';

import { Table } from "semantic-ui-react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import getManagedCreditRecruiters from '../../../services/getManagedCreditRecruiters';
import getCredInfo from '../../../services/getCreditInfo';
import distributeCredits from '../../../services/distributeCredits';
import reclaim from "../../../services/reclaimCredits";

import { getQueryParams, getQueryString } from '../../../utils/utilFunctions';
import { RECRUITER_CREDIT_MANAGEMENT_ADD, RECRUITER_CREDIT_MANAGEMENT_EDIT } from '../../../models/routes';
import "./styles.scss";

class Distribute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            domain: '',
            list: [],
            credits: {},
            credInfo: {},
            loading: true,
            remaining: 0
        };
    }

    async componentDidMount() {
        const profile = await user.getProfile();
        const params = getQueryParams(window.location.search);
        const credInfoResp = await getCredInfo(profile.id, params.rec_id);
        const resp = await getManagedCreditRecruiters(profile.id, params.rec_id);
        console.log(profile);
        this.setState({
            profile,
            list: resp.data.data,
            credInfo: credInfoResp.data.data,
            loading: false,
            mgr_id: params.rec_id,
            remaining: credInfoResp.data.data.totalAvailableCredits || 0
        });
    }

    onChange(item, ev) {
        const { id } = item;
        const { value } = ev.target;
        console.log(item, ev);
        const credits = {
            [id]: value
        };
        const remaining = this.state.credInfo.totalAvailableCredits - Object.values(credits).reduce((p, c) => p + +c, 0);
        this.setState({
            credits,
            remaining
        });
    }

    async onRemove(item) {
        try {
            if (!item.managed_cred_info) return toast.error("No credit available to remove");
            let remaining = item.managed_cred_info.total - item.managed_cred_info.used - item.managed_cred_info.distributed;

            if (!remaining || remaining <= 0) return toast.error("No credit available to remove");

            await reclaim(this.state.profile.id, this.state.mgr_id, {
                id: item.id,
                value: remaining
            });

            const list = this.state.list.map(i => {
                let it = { ...i };
                if (it.id === item.id) {
                    it.cred_info.total -= remaining;
                    it.managed_cred_info.total -= remaining;
                }
                return it;
            })

            this.setState({ list, remaining: this.state.remaining + remaining })

            toast.success("Credits Reclaimed Successfully!");

        } catch (err) {
            if (err.response && err.response.data)
                toast.error(err.response.data.message);
            else
                toast.error("Some Error Occured!");
        }
    }

    async onAdd() {
        const { credits, remaining, mgr_id, profile, credInfo } = this.state;
        console.log(this.state.credits);
        try {
            if (remaining < 0) return toast.error("Enough Credits Not Available!");

            const data = Object.keys(credits).map(i => {
                return {
                    id: i,
                    value: credits[i]
                }
            });

            if (!data.length) return toast.error("Add Credits to Distribute");

            await distributeCredits(profile.id, mgr_id, { data });

            const creds = {
                ...credInfo,
            }
            const list = this.state.list.map(i => {
                let it = {...i};
                if (credits[it.id]) {
                    it.cred_info =  { 
                        total: 0,
                        ...it.cred_info 
                    };
                    it.cred_info.total += +credits[it.id]

                    it.managed_cred_info =  { 
                        total: 0,
                        used: 0,
                        distributed: 0,
                        ...it.managed_cred_info 
                    };
                    it.managed_cred_info.total += +credits[it.id]

                    creds.totalAvailableCredits -= credits[it.id];
                }
                console.log(it, it.id, credits);
                return it;
            })

            this.setState({ list, credInfo: creds, credits: {} });

            toast.success("Credits Distributed Successfully!");
        } catch (err) {
            if (err.response && err.response.data)
                toast.error(err.response.data.message);
            else
                toast.error("Some Error Occured!");
        }
    }

    render() {
        const { credInfo, remaining } = this.state;
        return (
            <div className="credit-management">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <Heading text='Recruiter Credit Management > Distribute' />
                <br />
                {
                    credInfo.recname ?
                        <div className="rec-info">
                            <div>
                                <span className="name">{credInfo.recname}</span> (<span>{credInfo.email}</span>)
                            </div>
                            <div>
                                <span>{credInfo.designation} at {credInfo.organisation}</span>
                            </div>
                            <span className="remaining">Total Available Credits: {remaining}</span>
                        </div> : null
                }
                {
                    !this.state.loading && !this.state.list.length ?
                        <div className="no-records">
                            No Records Found
                        </div>
                        : null
                }
                {
                    this.state.list.length ?
                        <>
                            <Table fixed selectable className="credit-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2} >ID</Table.HeaderCell>
                                        <Table.HeaderCell width={4} >Name</Table.HeaderCell>
                                        <Table.HeaderCell width={6} >Email</Table.HeaderCell>
                                        <Table.HeaderCell width={4} >Phone</Table.HeaderCell>
                                        <Table.HeaderCell width={4} >Organisation</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Credits</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Credits Remaining</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Used Credits</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Credits</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {

                                        this.state.list.map((item, i) => {
                                            return (

                                                <Table.Row>
                                                    <Table.Cell>{item.id} </Table.Cell>
                                                    <Table.Cell>{item.recname} </Table.Cell>
                                                    <Table.Cell>{item.email} </Table.Cell>
                                                    <Table.Cell>{item.phone} </Table.Cell>
                                                    <Table.Cell>{item.organisation} </Table.Cell>

                                                    <Table.Cell>{item.cred_info.total || 0} </Table.Cell>
                                                    <Table.Cell>{(item.cred_info.total || 0) - (item.cred_info.used || 0) - (item.cred_info.distributed || 0)} </Table.Cell>
                                                    <Table.Cell>{item.cred_info.used || 0} </Table.Cell>
                                                    <Table.Cell>
                                                        <InputBox
                                                            key={i}
                                                            type="number"
                                                            placeholder=''
                                                            value={this.state.credits[item.id] || ""}
                                                            name="credits"
                                                            onChange={this.onChange.bind(this, item)} />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button skin='dark' disabled={false} text='Remove' clickButton={this.onRemove.bind(this, item)} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell>
                                            <Button skin='dark' disabled={false} text='Add' clickButton={this.onAdd.bind(this)} />
                                        </Table.Cell>
                                        <Table.Cell>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <div>
                            </div>
                        </> : null

                }
                <br />
                <br />
            </div>
        )
    }

}

export default Distribute;