export const adminFeatureMapping = {
    TAG_MAILER_VIEW: 'TAG_MAILER_VIEW',
    TAG_MAILER_EXEC: 'TAG_MAILER_EXEC',
    DOMAIN_MAPPING: 'DOMAIN_MAPPING',
    SHOWCASE_VIEW: 'SHOWCASE_VIEW',
    SHOWCASE_REPORT: 'SHOWCASE_REPORT',
    COMPANY_MGT: 'COMPANY_MGT',
    JOBSEEKER_MGT: 'JOBSEEKER_MGT',
    JOBS_LIST: 'JOBS_LIST',
    NOTIFY_USERS: 'NOTIFY_USERS',
    DISPLAY_TAGS: 'DISPLAY_TAGS',
    VERIFY_REC: 'VERIFY_REC',
    PREMIUM_JOBS_LIST: 'PREMIUM_JOBS_LIST',
    LEADS_BLOCKED_ACCESS: 'LEADS_BLOCKED_ACCESS',
    SEEKER_PROFILE_DELETE: 'SEEKER_PROFILE_DELETE',
    CHAT_FEED_ACCESS: 'CHAT_FEED_ACCESS',
    RECRUITER_INCOMPLETE: 'RECRUITER_INCOMPLETE',
    EDIT_CANDIDATE_RESUME: 'EDIT_CANDIDATE_RESUME',
    VIEW_CANDIDATE_LIST: 'VIEW_CANDIDATE_LIST',
    SEEKER_PLAN_ACTIVATE: 'SEEKER_PLAN_ACTIVATE',
    EDIT_TAGS: 'EDIT_TAGS',
    POTENTIAL_PAID_JOBS: 'POTENTIAL_PAID_JOBS',
    EDIT_RECRUITER_COMPANY: 'EDIT_RECRUITER_COMPANY',
    RECRUITER_VERIFICATION: 'RECRUITER_VERIFICATION',
    POTENTIAL_PAID_JOBS_LEAD: 'POTENTIAL_PAID_JOBS_LEAD',
    NAUKRI_CRM_SYNC_ACTIONS: 'NAUKRI_CRM_SYNC_ACTIONS',
    MANAGE_SUBSCRIPTIONS_FORM: 'MANAGE_SUBSCRIPTIONS_FORM',
    ANY_RECRUITER_LOGIN: 'ANY_RECRUITER_LOGIN',
    PRO_MEMBERSHIP_INVOICE: 'PRO_MEMBERSHIP_INVOICE',
    GENERATE_CREDIT_NOTE: 'GENERATE_CREDIT_NOTE',
    COMPANY_MGT_FIELDS_EDIT: 'COMPANY_MGT_FIELDS_EDIT',
    LEARN_AND_GROW_UPDATE_BILLS: 'LEARN_AND_GROW_UPDATE_BILLS' 
};