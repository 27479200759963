import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Table } from "semantic-ui-react";
import Button from "../../components/Button";
import CountDisplay from "../../components/CountDisplay";
import Heading from "../../components/Heading";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import user from "../../store/user";
import { getQueryParams } from "../../utils/utilFunctions";


import "./competitionDashboard.scss";

import fetchCompetitionData from "../../services/fetchCompetitionData";
import CompetitionModal from "./Components/CompetitionModal";
import InputBox from "../../components/InputBox";
// import fetchCompetitionDomain from "../../services/fetchCompetitionDomain";
// import fetchCompetitionSpecialisation from "../../services/fetchCompetitionSpecialisation";
import fetchCompetitionInstitute from "../../services/fetchCompetitionInstitute";
import ButtonContainer from "../../components/ButtonContainer";
import Dropdown from "../../components/Dropdown/Dropdown";
import postCompetitionAction from "../../services/postCompetitionAction";
import CompetitionAssessmentModal from "./Components/CompetitionAssessmentModal";
import VideoPlayer from "../../components/VideoPlayer";
import ModalWrapper from "../../components/Modalv2/Modal";
import postCompetitionScore from "../../services/postCompetitionScore";
import postCompetitionEmail from "../../services/postCompetitionEmail";
import downloadCompetitionData from "../../services/downloadCompetitionExcel";
import postCompetitionBulkAction from "../../services/postCompetitionBulkAction";
import CompetitionMailerModal from "./Components/CompetitionMailerModal/CompetitionMailerModal";

const PAGE_CONTENT = 50;

const STATUS_MAP = {
    0: 'Pending',
    1: 'Submitted'
}

const ACTION_MAP = {
    0: 'Pending',
    1: 'Selected, Moved to next round',
    2: 'Rejected'
}

const EMAIL_TYPES = [
    { id: '', name: 'Select email' },
    {
        name: 'CompetitionWelcomeEmail',
        id: '1'
    },
    {
        name: 'CompetitionRound1ReminderEmail 1',
        id: '2'
    },
    {
        name: 'CompetitionRound1ReminderEmail 2',
        id: '3'
    },
    {
        name: 'CompetitionRound1ReminderEmail Final',
        id: '4'
    },
    {
        name: 'CompetitionRound1AssesmentLink',
        id: '5'
    },
    {
        name: 'CompetitionRound1ResultsDeclared',
        id: '6'
    }
]


const DEFAULT_ROUND_STATE = {
    action: 0,
    score: '',
    status: 0,
    solutionLinks: []
}

const CompetitionDashboard = (props) => {

    const [content, setContent] = useState({
        list: [],
        pageNumber: 0,
        count: 0,
    });

    const [admin, setAdmin] = useState({
        adminId: null,
        email: null,
        viewAccess: true,
    });

    const [fetchApiCall, setFetchApiCall] = useState(false);

    const [modalItem, setModalItem] = useState(null);
    const [competitionModal, setCompetitionModal] = useState(false);
    const [actionDetails, setActionDetails] = useState([{
        seekerId: null,
        competitionId: null,
        round: null,
        action: null
    }]);
    const [isSelected, setIsSelected] = useState({});
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isEntireSelected, setIsEntireSelected] = useState(false);
    const [filters, setFilters] = useState({
        domain: [{ id: '', name: 'Domain' }],
        institute: [{ id: '', name: 'Institute' }],
        round: [
            { id: '', name: 'Select' },
            {
                name: 'Round 1',
                id: '1'
            },
            {
                name: 'Round 2',
                id: '2'
            },
            // {
            //     name: 'Round 3',
            //     id: '3'
            // },
            // {
            //     name: 'Round 4',
            //     id: '4'
            // }
        ],
        action: [
            {id : '', name: 'Select'},
            {id : '0', name: 'Pending'},
            {id : '1', name: 'Qualified'},
            {id : '2', name: 'Disqualified'},
        ],
        submit: [
            {id: '', name: 'Select'},
            {id: '0', name: 'Not Submitted'},
            {id: '1', name: 'Submitted'}
        ]
        // specialisation: [{ id: '', name: 'Specialisation' }]
    })

    const [selectedFilter, setSelectedFilters] = useState({
        domain: '',
        institute: '',
        competitionId: 1,
        round: '',
        action: '',
        submit: ''
        // specialisation: ''
    })

    const [appliedFilter, setAppliedFilter] = useState({})

    const [currentSeekerId, setCurrentSeekerId] = useState(null);

    const [videoSource, setVideoSource] = useState(null);

    const [selectedEmail, setSelectedEmail] = useState(null);

    const [scoreAction, setScoreAction] = useState({});

    const [isInvalidMsg, setIsInvalidMsg] = useState('')

    const [isScoreSubmitting, setIsScoreSubmitting] = useState(false);

    const [singleMail, setSingleMail] = useState({})

    const [currentSelected, setCurrentSelected] = useState(null);

    const [downloadingExcel, setDownloadingExcel] = useState(false)

    const [isEntireActionPerformed, setIsEntireActionPerformed] = useState(null)

    const [competitioMailerModalOpen, setCompetitionMailerModalOpen] = useState(false);


    useEffect(() => {
        const { page = 1, institute, domain, round, score, scoreLte, scoreGte, submit, action } = getQueryParams(props.location.search);
        // const { page = 1, institute, domain, specialisation } = getQueryParams(props.location.search);
        try {
            user.getProfile().then(userData => {
                if (userData.adminType == 2 || userData.adminType == 3) {
                    setAdmin({
                        adminId: userData.id,
                        email: userData.email
                    })

                    setSelectedFilters(prev => ({
                        ...prev,
                        domain,
                        institute,
                        round,
                        scoreLte,
                        scoreGte,
                        action,
                        submit
                        // specialisation
                    }))
                    setAppliedFilter(prev => ({
                        ...prev,
                        domain,
                        institute,
                        round,
                        scoreLte,
                        scoreGte,
                        action,
                        submit
                        // specialisation
                    }))
                    getCompetitionData(page, userData.id, { domain, institute, round, score, competitionId: selectedFilter.competitionId, scoreLte, scoreGte, submit, action });
                    // getCompetitionData(page, { domain, institute, specialisation });
                    getFilters(userData.id);

                } else {
                    window.location.href = process.env.REACT_APP_LOGIN_URL;
                }
            });
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong!')
        }
    }, [])


    useEffect(() => {

        const selectValues = {};

        const filteredList = content.list.filter(item => item.rounds[item['currentRound']]['status'] == 1 && item.rounds[item['currentRound']]['score']);

        if (isAllSelected) {
            filteredList.forEach(item => {
                selectValues[item['seekerId']] = item;
            })
        }

        setIsSelected({ ...selectValues });

    }, [isAllSelected])

    const getCompetitionData = async (page, adminId, filters = {}) => {
        try {
            setFetchApiCall(true);

            const params = {};
            for (const key in filters) {
                if (filters[key]) {
                    params[key] = filters[key];
                }
            }

            const res = await fetchCompetitionData(adminId, {
                pageNo: page,
                pageSize: PAGE_CONTENT,
                ...params,
            })

            setContent({
                list: res.data.data.data.data,
                pageNumber: page,
                count: res.data.data.data.totalCount,
            });

            routeRequest({ page, ...filters });
            setFetchApiCall(false);
        } catch (e) {
            toast.error('Something went wrong');
            setFetchApiCall(false);
            console.log(e);
        }
    };

    const getFilters = async (adminId) => {

        try {

            // const domain = await fetchCompetitionDomain(adminId);
            const insitute = await fetchCompetitionInstitute(adminId);
            // const specialisation = await fetchCompetitionSpecialisation(adminId);

            setFilters(prev => ({
                ...prev,
                // domain: formatList(domain.data.data.data, 'domain'),
                institute: formatList(insitute.data.data.data, 'institute'),
                // specialisation: formatList(specialisation.data.data.data, 'specialisation')
            }))
        }

        catch (e) {
            console.log(e);
            toast.error('Something went wrong!');
        }
    }

    const formatList = (data, name) => {

        const arr = [{ id: '', name: name.charAt(0).toUpperCase() + name.slice(1) }];

        for (const key in data) {
            arr.push({
                id: key,
                name: data[key]
            })
        }

        return arr;


    }

    const handlePageChange = (n) => {
        setIsSelected({});
        setIsAllSelected(false);

        const params = getParams();
        try {
            getCompetitionData(n, admin.adminId, { ...params });
        }
        catch (e) {
            console.log(e);
            toast.error('Something went wrong!')
        }


    };

    const onCompetitionClick = (competitionItem, ind) => {
        setCompetitionModal(true);
        setModalItem(competitionItem);
        setCurrentSelected(ind);
    }

    const handleModalClose = () => {
        setModalItem(null);
        setCompetitionModal(false);
    };

    const handleAssessmentModalClose = () => {
        setCurrentSeekerId(null)
    };


    const handleCompetitionMailerModalClose = () => {
        setSelectedEmail('');
        setCompetitionMailerModalOpen(false);
    };

    const routeRequest = (parameters) => {
        let array = [];
        for (const key in parameters) {
            if (parameters[key]) {

                if (
                    typeof parameters[key] == "number" &&
                    parameters[key] > 0
                )
                    array.push(key + "=" + parameters[key]);
                if (typeof parameters[key] == "string")
                    array.push(key + "=" + parameters[key]);
            }
        }

        array = array.join("&");
        props.history.push("?" + array);
    };

    const handleCompetitionAction = async (e, item, type, isMulti, ind) => {
        e.stopPropagation()

        try {
            let data = [];

            if (isMulti) {
                for (const key in isSelected) {
                    if (isSelected[key]) {
                        data.push({
                            seekerId: isSelected[key]['seekerId'],
                            competitionId: isSelected[key]['competitionId'],
                            round: isSelected[key]['currentRound'],
                            action: type

                        })
                    }
                }

            }
            else {
                data = [{
                    seekerId: item['seekerId'],
                    competitionId: item['competitionId'],
                    round: item['currentRound'],
                    action: type,
                    actionTakenBy: admin['adminId']

                }];

            }

            setActionDetails([...data]);

            await postCompetitionAction(admin.adminId, data);

            if (!isMulti) {
                const currentRound = content['list'][ind || currentSelected]['currentRound'];
                const currentList = [...content.list];

                currentList[ind || currentSelected]['rounds'][currentRound]['action'] = type;


                if (type == 1) {
                    currentList[ind || currentSelected]['rounds'][(currentRound) + 1] = DEFAULT_ROUND_STATE
                }

                setContent(prev => ({
                    ...prev,
                    list: currentList
                }))
            }

            setActionDetails([{
                seekerId: null,
                competitionId: null,
                round: null,
                action: null
            }])

            toast.success('Action Successful');

        }
        catch (e) {
            toast.error('Something went wrong!!');
            console.log(e);
            setActionDetails([{
                seekerId: null,
                competitionId: null,
                round: null,
                action: null
            }])
        }

    }

    const onCheckboxChange = (e, isAll, details) => {

        e.stopPropagation();
        const checked = e.target.checked;

        if (isAll) {
            setIsAllSelected(checked);
            if (checked) {
                setIsEntireSelected(false);
            }
            return;
        }


        if (checked) {
            setIsSelected(prev => ({
                ...prev,
                [details['seekerId']]: {
                    seekerId: details['seekerId'],
                    competitionId: details['competitionId'],
                    round: details['currentRound'],
                }

            }));
        }

        else {
            setIsSelected(prev => ({
                ...prev,
                [prev[details['seekerId']]]: null

            }));
        }


    }



    const showAllBtn = () => {


        if (!Object.keys(isSelected).length) return false;

        let show = false;

        for (const key in isSelected) {
            if (isSelected[key]) {
                show = true;
                break;
            }
        }

        return show;
    }

    const onChangeFilter = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        if (name == 'round' && !val) {
            setSelectedFilters(prev => ({
                ...prev,
                scoreLte: '',
                scoreGte: ''
            }))
        }

        setSelectedFilters(prev => ({
            ...prev,
            [name]: val
        }))

    }

    const getParams = () => {

        const params = {};

        for (const key in selectedFilter) {
            if (selectedFilter[key]) {
                params[key] = selectedFilter[key];
            }
        }

        return params;


    }

    const getDownloadParams = () => {

        const params = {};

        if (appliedFilter) {
            for (const key in appliedFilter) {
                if (appliedFilter[key]) {
                    params[key] = appliedFilter[key];
                }
            }

        }

        else {
            for (const key in selectedFilter) {
                if (selectedFilter[key]) {
                    params[key] = selectedFilter[key];
                }
            }
        }


        return params;


    }

    const onClickFilter = async () => {

        const params = getParams();
        const filters = { ...selectedFilter };
        setAppliedFilter(filters);
        setIsAllSelected(false);
        try {
            getCompetitionData(content.pageNumber, admin.adminId, { ...params });
        }
        catch (e) {
            console.log(e);
            toast.error('Something went wrong!')
        }

    }

    const clearFilters = async () => {
        setSelectedFilters(prev => ({
            ...prev,
            domain: '',
            institute: '',
            round: '',
            scoreGte: '',
            scoreLte: ''
            // specialisation: ''
        }));
        setAppliedFilter({})
        await getCompetitionData(1, admin.adminId)
        setIsAllSelected(false);
    }

    const clearScoreFilter = async () => {
        setSelectedFilters(prev => ({
            ...prev,
            scoreGte: '',
            scoreLte: ''
        }))

        setAppliedFilter(prev => ({
            ...prev,
            scoreGte: '',
            scoreLte: ''
        }))

        const params = getParams();

        await getCompetitionData(1, admin.adminId, { ...params, scoreLte: '', scoreGte: '' })
        setIsAllSelected(false);
    }

    const showBulkAction = () => {
        let show = false;

        for (const key in isSelected) {
            if (isSelected[key]) {
                show = true;
                break;
            }
        }

        return show;
    }

    const onClickDetailScore = (e, seekerId) => {

        e.preventDefault();
        e.stopPropagation();

        setCurrentSeekerId(seekerId);
    }

    const onCloseVideo = () => {
        setVideoSource(null);
    }

    const onScoreFilterChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setSelectedFilters(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const onScoreChange = (e, item) => {
        const value = e.target.value;

        const data = {
            seekerId: item['seekerId'],
            competitionId: item['competitionId'],
            round: item['currentRound'],
            score: value,
            scoredBy: admin['adminId']
        }

        if (value < 0 || value > 20) {
            setIsInvalidMsg({ [item['seekerId']]: '0 to 20 allowed' });
            return;
        }
        else {
            setIsInvalidMsg({})
        }

        setScoreAction(data);


    }

    const onSubmitScore = async (scoreAction, index) => {

        try {

            if (!scoreAction['score']) {
                setIsInvalidMsg({ [scoreAction['seekerId']]: '0 to 20 allowed' });
                return;
            }
            setIsScoreSubmitting(true);

            await postCompetitionScore(admin.adminId, scoreAction)

            const round = scoreAction.round;

            const currentList = [...content['list']];
            currentList[index || currentSelected]['rounds'][round]['score'] = scoreAction.score;

            setContent(prev => ({
                ...prev,
                list: currentList
            }))


            setIsScoreSubmitting(false);
            setScoreAction({})


        }
        catch (e) {

            console.log(e);
            setIsScoreSubmitting(false);

        }
    }

    const onChangeSingleEmail = (e, id, emailCategory) => {

        e.stopPropagation();
        const value = e.target.value;

        setSingleMail({
            [emailCategory]: {
                id,
                mail: value,
            }

        })
    }

    const sendSingleEmail = async (e, id) => {

        e.stopPropagation();

        const data = {
            seekers: `${id}`,
            requestedby: admin.email,
            type: 2
        }

        for(const [key, value] of Object.entries(singleMail)){
            data['templateType'] = value['mail']
        }

        try {
            await postCompetitionEmail(admin.adminId, data);
            toast.success('Email Sent Successfully');

            setSingleMail({});

        }

        catch (e) {
            console.log(e)
            toast.error('Something went wrong!');
            throw new Error()
        }


    }

    const sendBulkEmail = async (e) => {

        e.stopPropagation();


        let seekerList = Object.keys(isSelected).map(seekerId => seekerId);


        const data = {
            seekers: seekerList.join(','),
            requestedby: admin.email,
            templateType: selectedEmail,
            type: 2
        }

        try {

            await postCompetitionEmail(admin.adminId, data);
            toast.success('Email Sent Successfully');

            setSelectedEmail('');

        }

        catch (e) {
            toast.error('Something went wrong!');
        }


    }

    const sendEntireEmail = async () => {



        const data = {
            requestedby: admin.email,
            templateType: selectedEmail,
            type: 1
        }

        try {

            await postCompetitionEmail(admin.adminId, data);
            toast.success('Email Sent Successfully');

            setSelectedEmail('');

        }

        catch (e) {
            toast.error('Something went wrong!');
        }


    }

    const downloadExcel = async () => {

        const params = getDownloadParams();
        try {
            setDownloadingExcel(true);

            const res = await downloadCompetitionData(admin.adminId, {
                ...params,
            })

            setDownloadingExcel(false);


            const data = res.data;

            const outputFilename = `CompetitionReport.xlsx`;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);



        }
        catch (e) {
            console.log(e);
            toast.error('Something went wrong!')
        }
    }

    const onAllSelect = async (e) => {
        const checked = e.target.checked;

        setIsEntireSelected(checked);

        if (checked) {
            setIsAllSelected(false);
        }
    }

    const handleEntireAction = async (e, actionId) => {

        const competitionId = content.list[0]['competitionId'];

        try {

            setIsEntireActionPerformed(actionId);

            const minScore = selectedFilter['scoreGte'] || 0;
            const maxScore = selectedFilter['scoreLte'] || 20;

            const data = {
                actionTakenBy: admin.adminId,
                action: actionId,
                competitionId: competitionId,
                round: selectedFilter['round'],
                maxScore,
                minScore

            };

            await postCompetitionBulkAction(admin.adminId, data);

            setIsEntireActionPerformed(null);


            toast.success('Action Completed successfully');

        }

        catch (e) {

            toast.error('Something went wrong!!');
            console.log(e);

            setIsEntireActionPerformed(null);
        }


    }

    return (
        <div className="competition-dashboard-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="competition-dashboard-heading">
                <Heading text="Competition Dashboard" />

                <div className="filter-container">
                    <ButtonContainer heading="Filter By: ">

                        <li>

                            <Dropdown
                                name='institute'
                                data={filters['institute']}
                                dropdownChange={onChangeFilter}
                                defaultValue={selectedFilter['institute']}
                            />
                        </li>

                        <li>
                            <Button skin='dark' text="Filter" clickButton={onClickFilter} />
                            <Button skin='light' text="Clear" clickButton={clearFilters} />

                        </li>
                      
                    </ButtonContainer>


                </div>

                <div className="filter-container">
                    <ButtonContainer heading="Filter By Round: ">
                       
                        <li>
                            <span className="drpdwn-label">Round</span>


                            <Dropdown
                                name='round'
                                data={filters['round']}
                                dropdownChange={onChangeFilter}
                                defaultValue={selectedFilter['round']}
                            />
                        </li>

                        <li>

                            <span className="drpdwn-label">Status</span>

                            <Dropdown
                                name='submit'
                                data={filters['submit']}
                                dropdownChange={onChangeFilter}
                                defaultValue={selectedFilter['submit']}
                            />
                        </li>

                        <li>

                            <span className="drpdwn-label">Result</span>

                            <Dropdown
                                name='action'
                                data={filters['action']}
                                dropdownChange={onChangeFilter}
                                defaultValue={selectedFilter['action']}
                            />
                        </li>

                        <li>
                            <p></p>
                            <Button skin='dark' text="Filter" clickButton={onClickFilter} />
                            <Button skin='light' text="Clear" clickButton={clearFilters} />

                        </li>
                        {/* <li>
                            <Button skin='light' text="Clear" clickButton={onClickClearFilter} />

                        </li> */}
                    </ButtonContainer>


                </div>

                <div className="filter-container">
                    <ButtonContainer heading='Filter By Score:'>
                        {/* <li>
                            <Dropdown
                                name='email'
                                data={SCORE_FILTER_TYPES}
                                dropdownChange={(e) => setScoreFiterType(e.target.value)}
                                defaultValue={scoreFilterType}
                            />
                        </li> */}
                        <li>
                            <span>Greater than equals</span>

                            {
                                selectedFilter['round'] ? (
                                    <InputBox name='scoreGte' placeholder='Score' type='number' value={selectedFilter['scoreGte']} onChange={onScoreFilterChange} />

                                ) : (
                                    <>
                                        <p style={{ display: "none" }}>{selectedFilter['round']}</p>
                                        <InputBox name='scoreGte' placeholder='Score' type='number' value={selectedFilter['scoreGte']} onChange={onScoreFilterChange} disabled />
                                    </>

                                )
                            }

                        </li>

                        <li>
                            <span>Less than equals</span>

                            {
                                selectedFilter['round'] ? (
                                    <InputBox name='scoreLte' placeholder='Score' type='number' value={selectedFilter['scoreLte']} onChange={onScoreFilterChange} />

                                ) : (
                                    <>
                                        <p style={{ display: "none" }}>{selectedFilter['round']}</p>
                                        <InputBox name='scoreLte' placeholder='Score' type='number' value={selectedFilter['scoreLte']} onChange={onScoreFilterChange} disabled />
                                    </>

                                )
                            }

                        </li>

                        <li>
                            <p></p>
                            <Button skin='dark' text="Filter" clickButton={onClickFilter} />
                            <Button skin='light' text="Clear" clickButton={clearScoreFilter} />

                        </li>
                    </ButtonContainer>
                </div>


                <Button skin='light' className="competition-bulk-mailer-btn" text="Bulk Mailer" clickButton={() => setCompetitionMailerModalOpen(true)} />

                <Button skin='dark' className="competition-dwnld-btn" text="Download Excel" clickButton={downloadExcel} loading={downloadingExcel} disabled={downloadingExcel} />


            </div>
            <div className="row text-center">

                <div className="comp-bulk-action-wrapper">

                    <div>

                        {
                            showAllBtn() ? (
                                <div className="bulk-action-container">


                                    <div className="bulk-email-selector" onClick={e => e.stopPropagation()}>
                                        <Dropdown
                                            name='email'
                                            data={EMAIL_TYPES}
                                            dropdownChange={(e) => setSelectedEmail(e.target.value)}
                                            defaultValue={selectedEmail}
                                        />

                                        <Button skin='light' className="selected-email-btn" text="Email Selected" clickButton={sendBulkEmail} loading={showBulkAction() && actionDetails[0].action == 3} disabled={!selectedEmail} />

                                    </div>

                                    <div className="bulk-action-btns">
                                        <Button skin='dark' className="selected-shortlist-btn" text="Shortlist Selected" clickButton={(e) => handleCompetitionAction(e, null, 1, true)} loading={showBulkAction() && actionDetails[0].action == 1} />
                                        <Button skin='light' className="selected-reject-btn" text="Reject Selected" clickButton={(e) => handleCompetitionAction(e, null, 2, true)} loading={showBulkAction() && actionDetails[0].action == 2} />

                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            isEntireSelected ? (
                                <div className="bulk-action-container">
                                    <div className="bulk-email-selector" onClick={e => e.stopPropagation()}>
                                        {/* <Dropdown
                                            name='email'
                                            data={EMAIL_TYPES}
                                            dropdownChange={(e) => setSelectedEmail(e.target.value)}
                                            defaultValue={selectedEmail}
                                        />

                                        <Button skin='light' className="selected-email-btn" text="Email All" clickButton={sendEntireEmail} loading={showBulkAction() && actionDetails[0].action == 3} disabled={!selectedEmail} /> */}

                                    </div>
                                    <div className="bulk-action-btns">
                                        <Button skin='dark' className="selected-shortlist-btn" text="Shortlist All" clickButton={(e) => handleEntireAction(e, 1)} loading={isEntireActionPerformed == 1} />
                                        <Button skin='light' className="selected-reject-btn" text="Reject All" clickButton={(e) => handleEntireAction(e, 2)} loading={isEntireActionPerformed == 2} />

                                    </div>
                                </div>
                            ) : null

                        }

                    </div>

                    <div>

                        <Pagination
                            totalItemsCount={
                                content.count
                            }
                            content={PAGE_CONTENT}
                            pageNumber={content.pageNumber}
                            handlePageChange={(n) => handlePageChange(n)}
                        />
                        <CountDisplay
                            start={
                                content.list.length == 0
                                    ? content.list.length
                                    : (content.pageNumber - 1) * PAGE_CONTENT + 1
                            }
                            end={
                                content.pageNumber * PAGE_CONTENT -
                                (content.list.length < PAGE_CONTENT
                                    ? PAGE_CONTENT - content.list.length
                                    : 0)
                            }
                            total={
                                content.count
                            }
                        />
                    </div>

                </div>




                {fetchApiCall ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <div className='competition-select-all'>
                            {
                                content['list'].length && appliedFilter['round'] && (appliedFilter['scoreLte'] || appliedFilter['scoreGte']) ? (
                                    <>
                                        <InputBox type='checkbox' value={isEntireSelected} checked={isEntireSelected} onChange={onAllSelect} />
                                        <label>Select all candidates</label>
                                    </>

                                ) : null
                            }


                        </div>

                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={3}>
                                        <div onClick={e => e.stopPropagation()}>
                                            <InputBox type='checkbox' value={isAllSelected} checked={isAllSelected} onChange={(e) => onCheckboxChange(e, true)} />

                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Participant Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Email ID
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Contact Number
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Institute, Specialisation, Batch, Passing Year
                                    </Table.HeaderCell>
                                    {/* <Table.HeaderCell width={4}>
                                        Domain
                                    </Table.HeaderCell> */}
                                    <Table.HeaderCell width={6}>
                                        Round 1
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Round 2
                                    </Table.HeaderCell>
                                    {/* <Table.HeaderCell width={6}>
                                        Round 3
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Round 4
                                    </Table.HeaderCell> */}
                                    {/* <Table.HeaderCell width={4}>
                                        Details
                                    </Table.HeaderCell> */}
                                    <Table.HeaderCell width={4}>
                                        Review Action
                                    </Table.HeaderCell>
                                    {/* <Table.HeaderCell width={4}>
                                        Email Action
                                    </Table.HeaderCell> */}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.list.map((item, ind) => {
                                    return (
                                        <Table.Row
                                            onClick={() =>
                                                onCompetitionClick(item, ind)
                                            }
                                            key={item['seekerId']}

                                        >
                                            <Table.Cell>
                                                {
                                                    item.rounds[item['currentRound']] && item.rounds[item['currentRound']]['status'] == 1 && item.rounds[item['currentRound']]['score'] ? (
                                                        <div onClick={e => e.stopPropagation()}>
                                                            <InputBox type='checkbox' value={isSelected[item['seekerId']]} checked={isSelected[item['seekerId']]} onChange={(e) => onCheckboxChange(e, false, item)} />
                                                        </div>
                                                    ) : null

                                                }

                                            </Table.Cell>
                                            <Table.Cell>{item.seekerId}</Table.Cell>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell>{item.email}</Table.Cell>
                                            <Table.Cell>{item.phone}</Table.Cell>

                                            <Table.Cell verticalAlign="middle">
                                                {

                                                    <p>
                                                        <span><b>Inst: </b></span>
                                                        <span>{item['institute']['instituteName']}</span>
                                                        <br />

                                                    </p>

                                                }
                                                {

                                                    <p>
                                                        <span><b>Specialisation: </b></span>
                                                        <span>{item['institute']['specialisation']}</span>
                                                        <br />
                                                    </p>

                                                }
                                                {

                                                    <p>
                                                        <span><b>Batch: </b></span>
                                                        <span>{item['institute']['batch']}</span>
                                                        <br />
                                                    </p>

                                                }
                                            </Table.Cell>


                                            {/* <Table.Cell>{item.domain}</Table.Cell> */}

                                            <Table.Cell>
                                                {
                                                    <p>
                                                        <span><b>Status: </b></span>
                                                        <span>{item['rounds']['1'] ? STATUS_MAP[item['rounds']['1']['status']] : ''}</span>
                                                        <br />

                                                    </p>

                                                }
                                                {
                                                    <p>
                                                        <span><b>Score: </b></span>
                                                        <span>{item['rounds']['1'] && item['rounds']['1']['status'] == 1 && (item['rounds']['1']['score'] || item['rounds']['1']['score'] == 0) ? `${item['rounds']['1']['score']}/ ${JSON.parse(item['rounds']['1']['metaResult'] || "{}")['totalMarks']}` : ''}</span>
                                                        {
                                                            item['rounds']['1'] && item['rounds'][1]['problemStatement'] ? (
                                                                <div>
                                                                    <p><b>Problem Statement: </b></p>
                                                                    <p>{item['rounds'][1]['problemStatement']}</p>
                                                                    <br />
                                                                </div>
                                                            ) : null
                                                        }
                                                        <br />

                                                    </p>

                                                }
                                                {
                                                    <p>
                                                        <span><b>Round Result: </b></span>
                                                        <span>{item['rounds']['1'] ? ACTION_MAP[item['rounds']['1']['action']] : ''}</span>
                                                        <br />

                                                    </p>

                                                }
                                            </Table.Cell>

                                            {
                                                [2].map((i, ind) => {

                                                    return (item['rounds'][i] ? (
                                                        (<Table.Cell key={ind}>
                                                            {
                                                                <p>
                                                                    <span><b>Status: </b></span>
                                                                    <span>{item['rounds'][`${i}`] ? STATUS_MAP[item['rounds'][`${i}`]['status']] : ''}</span>
                                                                    <br />

                                                                </p>

                                                            }

                                                            <p>
                                                                <span><b>Score: </b></span>
                                                                <span>{item['rounds'][i] && item['rounds'][i]['score'] ? `${item['rounds'][i]['score']}/ 20` : ''}</span>
                                                            </p>


                                                            {

                                                                item['rounds'][i] && item['rounds'][i]['problemStatement'] ? (
                                                                    <div>
                                                                        <p><b>Problem Statement: </b></p>
                                                                        <p>{item['rounds'][i]['problemStatement']}</p>
                                                                        <br />
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {
                                                                <p>
                                                                    <span><b>Solution: </b></span>

                                                                    {
                                                                        item['rounds'][i]['solutionLinks'].map((solution, ind) => (

                                                                            <p onClick={e => { e.stopPropagation() }}>
                                                                                {
                                                                                    solution['mimeType'] && solution['mimeType'].includes('video') ? (
                                                                                        <p onClick={e => { e.preventDefault() }}>
                                                                                            <a onClick={(e) => setVideoSource(solution['fileUrl'])} href='' >{solution['fileName']}</a>
                                                                                        </p>

                                                                                    ) : (
                                                                                        <a key={ind} onClick={e => e.stopPropagation()} href={`${solution['fileUrl']}`} target="__blank">{solution['fileName']}</a>

                                                                                    )
                                                                                }
                                                                            </p>
                                                                        ))
                                                                    }


                                                                </p>

                                                            }

                                                            {
                                                                < p >
                                                                    <span><b>Round Result: </b></span>
                                                                    <span>{ACTION_MAP[item['rounds'][i]['action']]}</span>
                                                                    <br />

                                                                </p>

                                                            }
                                                        </Table.Cell>)
                                                    ) : (
                                                        <Table.Cell key={ind}>
                                                        </Table.Cell>
                                                    ))

                                                })
                                            }


                                            <Table.Cell>

                                                {
                                                    item.rounds[item['currentRound']] && item.rounds[item['currentRound']]['status'] == 1 && (item['currentRound'] != 1 && !item.rounds[item['currentRound']]['score']) ? (
                                                        <>
                                                            <label>{`Round ${item['currentRound']} Score`}</label>
                                                            <p className="score-input-container" onClick={(e) => e.stopPropagation()}>
                                                                <InputBox className='comp-score-input' name='score' type='number' max='20' value={scoreAction['seekerId'] == item['seekerId'] ? scoreAction['score'] : ''} onChange={(e) => onScoreChange(e, item)} />
                                                                <p className='validatorError'> {isInvalidMsg[item['seekerId']] ? '0 to 20 allowed' : ''} </p>

                                                                <Button
                                                                    text='Submit'
                                                                    skin='dark'
                                                                    clickButton={() => onSubmitScore(scoreAction, ind)}
                                                                    loading={scoreAction['seekerId'] == item['seekerId'] && isScoreSubmitting}
                                                                    disabled={scoreAction['seekerId'] != item['seekerId'] || isScoreSubmitting}
                                                                />
                                                            </p>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                clickButton={(e) => handleCompetitionAction(e, item, 1, null, ind)}
                                                                skin="dark"
                                                                text="Shortlist"
                                                                loading={actionDetails[0]['seekerId'] == item['seekerId'] && actionDetails[0]['action'] == 1 && !showBulkAction()}
                                                                // disabled={item.rounds[item['currentRound']]['status'] == 0 || item.rounds[item['currentRound']]['action'] > 0}

                                                            />

                                                            <Button
                                                                clickButton={(e) => handleCompetitionAction(e, item, 2, null, ind)}
                                                                skin="light"
                                                                text="Reject"
                                                                loading={actionDetails[0]['seekerId'] == item['seekerId'] && actionDetails[0]['action'] == 2 && !showBulkAction()}
                                                                // disabled={item.rounds[item['currentRound']]['status'] == 0 || item.rounds[item['currentRound']]['action'] > 0}

                                                            />
                                                        </>

                                                    )

                                                }


                                            </Table.Cell>

                                            {/* <Table.Cell>
                                                <p onClick={e => e.stopPropagation()}>

                                                    <Dropdown
                                                        name='email'
                                                        data={EMAIL_TYPES}
                                                        dropdownChange={(e) => onChangeSingleEmail(e, item['seekerId'])}
                                                        defaultValue={singleMail[item['seekerId']]}
                                                    />
                                                </p>
                                                <Button
                                                    clickButton={(e) => sendSingleEmail(e, item['seekerId'])}
                                                    skin="light"
                                                    text="Send Mail"
                                                    loading={actionDetails[0]['seekerId'] == item['seekerId'] && actionDetails[0]['action'] == 3 && !showBulkAction()}
                                                    disabled={singleMail['id'] != item['seekerId']}
                                                />
                                            </Table.Cell> */}



                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <CompetitionModal
                            open={competitionModal}
                            onClose={handleModalClose}
                            modalItem={modalItem}
                            handleCompetitionAction={handleCompetitionAction}
                            actionDetails={actionDetails} onClickDetailScore={onClickDetailScore}
                            setVideoSource={setVideoSource}
                            submitScore={onSubmitScore}
                            onChangeSingleEmail={onChangeSingleEmail}
                            sendSingleEmail={sendSingleEmail}
                            singleMail={singleMail}
                            adminId={admin['adminId']}
                        />

                        <CompetitionAssessmentModal
                            open={currentSeekerId}
                            onClose={handleAssessmentModalClose}
                            onClickDetailScore={onClickDetailScore}
                            seekerId={currentSeekerId}
                            adminId={admin.adminId}
                            jobId={process.env.REACT_APP_COMPETITION_JOB_ID} />

                        <CompetitionMailerModal
                            open={competitioMailerModalOpen}
                            onClose={handleCompetitionMailerModalClose}
                            sendEntireEmail={sendEntireEmail}
                            selectedEmail={selectedEmail}
                            setSelectedEmail={setSelectedEmail}
                        />

                        <ModalWrapper modalOpen={videoSource} onCloseModal={onCloseVideo}>
                            <VideoPlayer source={videoSource}></VideoPlayer>
                        </ModalWrapper>
                    </div>
                )
                }
            </div >
        </div >
    );
};

export default CompetitionDashboard;
