export const categories = [
    "Date",
    "Menu",
    "Status (Applied Jobs)",
    "Insights (Applied Jobs)",
    "Insights (Job Detail)",
    "Company Jobs",
    "Total"
];

export const todayValues = [
    "menu",
    "appstatus",
    "appinsight",
    "jd",
    "cj",
    "t"
];

export const todaySuccessValues = [
    "smenu",
    "sappstatus",
    "sappinsight",
    "sjd",
    "scj",
    "st"
]


export const getPlanwiseValues = (cnt) => {
    return [
        `menu${cnt}`,
        `appstatus${cnt}`,
        `appinsight${cnt}`,
        `jd${cnt}`,
        `cj${cnt}`,
        `t${cnt}`
    ]
}

export const dates = [
    "today",
    "yesterday",
    "dayBeforeYesterday",
    "currMonth",
    "lastMonth",
    "lastToLastMonth"
];

export const planWiseMap = {
    1: 'Plan 500',
    2: 'Plan 1250',
    3: 'Plan 2000',
    4: 'Plan 3000'
}