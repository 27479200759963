import moment from "moment";
import React, { useEffect, useState } from "react";
import getFunnelAnalysis from "../../services/getFunnelAnalysis";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import Moment from "react-moment";
import Datepicker from '../../components/FromToDatepicker';


const UsersAnalysis = (props) => {
    const today = moment().format("YYYY-MM-DD");
    const [from, setFrom] = useState(moment(today).subtract(30, "days").format("YYYY-MM-DD"));
    const [to, setTo] = useState(moment(today).subtract(1, "days").format("YYYY-MM-DD"));
    const [stDate, setStDate] = useState(moment(from).toDate());
    const [edDate, setEdDate] = useState(moment(to).toDate());
    const [data, setData] = useState([]);
    const [min, setMin] = useState(moment(today).subtract(1, "year").toDate());
    const [max, setMax] = useState(moment(to).toDate());

    useEffect(() => {
        user.getProfile().then(profile => {
            getFunnelAnalysis(profile.id, {
                from,
                to
            })
                .then(resp => {
                    setData((resp.data.data || []).reverse());
                }).catch(err => {
                    console.log("Err");
                    console.log(err);
                })
        })
    }, [from, to]);

    function handleChangeStart(date) {
        setStDate(date);
        const maxDate = moment(date).add(30, "days").toDate();
        setMax(moment(date).add(30, "days").toDate());
        if (+moment(maxDate) < +moment(edDate)) {
            setEdDate(null);
        }
    }

    function handleChangeEnd(date) {
        setEdDate(date);
    }

    function submit() {
        console.log("HHH");
        if (!stDate || !edDate) {
            return;
        }
        console.log("HHHdfdf");

        setFrom(moment(stDate).format("YYYY-MM-DD"));
        setTo(moment(edDate).format("YYYY-MM-DD"));
    }

    return (
        <div className="funnel-analysis">
            <div className="pageHeading">
                <Heading text='Funnel Analysis' />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/funneldataanalysis.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>
            <div className="row">            
                <Datepicker 
                    startDate={stDate} 
                    endDate={edDate} 
                    showLabels
                    minDate={min}
                    maxDate={max}
                    handleChangeStart={handleChangeStart} 
                    handleChangeEnd={handleChangeEnd} />
                <Button skin="dark" clickButton={submit} text="Search" ></Button>
            </div>
            <div className="row">
                {/* <Heading text='Overall' /> */}
                <Table fixed selectable className="mailers-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Unique Recruiter Login</Table.HeaderCell>
                            <Table.HeaderCell>Unique Seeker Login</Table.HeaderCell>
                            <Table.HeaderCell>Unique Applications</Table.HeaderCell>
                            <Table.HeaderCell>Unique Seekers With Recruiter Actions</Table.HeaderCell>
                            <Table.HeaderCell>Unique Seeker Bought Pro Product</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            data.map(item => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>
                                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.data.rl.c || 'N/A'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.data.sl.c || 'N/A'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.data.sa.c || 'N/A'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.data.sara.c || 'N/A'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.data.sbpp.c || 'N/A'}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )

}

export default UsersAnalysis;