import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FileInputBox = props => {
    const inputRef = useRef(null);
    const [error, setError] = useState(null);
    const [fileName, setFileName] = useState('');

    const handleInputError = (message) => {
        setError(message);
    };

    // this will check extension again, second check is not required since input 
    // already has accept parameter, but second check will be useful if any browser
    // does not support accept in input
    const handleInputChange = event => {
        try {
            if (event.target && event.target.files && event.target.files.length > 0) {
                const selectedFile = event.target.files[0];
                
                const fileExtension = selectedFile.name.toLowerCase().split('.').pop();
    
                // check if file extension is supported
                if (props.allowedExtensions && !props.allowedExtensions.includes(`.${fileExtension}`)) {
                    handleInputError('File type not supported.');
                    return;
                }
                
                // check if file size exeeds limit
                const fileSizeInMb = selectedFile.size ? selectedFile.size / Math.pow(10, 6) : 0;

                if (fileSizeInMb > props.fileSizeLimitInMb) {
                    handleInputError('File size has exceeded the limit. Please select a file less than 5Mb');
                    return;
                }
    
                props.onFileSelect(props.fileType, selectedFile);
                setFileName(selectedFile.name);
                handleInputError(null);
            }
        } catch (err){
            // console.log(err);
            handleInputError('File could not be selected. Please try again');
        }
    };

    return (
        <div className="inputbox__container">
            <input ref={inputRef} type="file" onChange={handleInputChange} accept={props.allowedExtensions.join(', ')} />
            <div className={`inputbox__container--content ${error ? 'error' : null}`}  onClick={() => {
                    if (inputRef && inputRef.current) {
                        inputRef.current.click();
                    }
                }}>
                <div className="select-file-btn">Choose File</div>
                <div className="file-name">{fileName ? fileName : (props.placeholder ? props.placeholder : null)}</div>
            </div>
            {error ? <div className="error-text">{error}</div> : null}
        </div>
    )
};

FileInputBox.propTypes = {
    onFileSelect: PropTypes.func,
    allowedExtensions: PropTypes.array,
    fileSizeLimitInMb: PropTypes.number,
    placeholder: PropTypes.string,
    fileType: PropTypes.number
};

export default FileInputBox;
