import React from "react";
import "./PaidJob.scss";

function PaidJob (props) {
    return (
        <div className="paid-job-card">
            <img className="icon-close" src="/static/close-copy.svg" onClick={props.closeClick} />
            <img src="/static/info.png" />
            <div className="heading">Premium Job Posted!</div>
            <div className="title">Title: {props.jobTitle}</div>
            <a className="job-link" target="_blank" href={props.jobLink} onClick={props.close}>
                Review Job
                <img className="icon-arrow" src="/static/farrow.svg" />
            </a>
        </div>
    )
}

export default PaidJob;