import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import Dropdown from '../../components/Dropdown/Dropdown';
import ButtonContainer from '../../components/ButtonContainer';
import FromToDatePicker from '../../components/FromToDatepicker';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Loader from '../../components/Loader';

import fetchPremiumRecruiters from '../../services/fetchPremiumRecruiters'
import fetchPremiumRecruitersCount from '../../services/fetchPremiumRecruitersCount';
import user from '../../store/user'

import './PremiumRecruiterList.scss'

import moment from 'moment'
import fetchRecruitersDetails from '../../services/fetchRecruitersDetails';

const filtersInitialState = {
    registerStartDate: '',
    registerEndDate: '',
    companyType: -1,
};

const companyTypeDropdown = [
    // { id: -1, name: 'Select' },
    { id: 1, name: 'All Premium Companies' },
    { id: 2, name: 'Unicorns' },
]

const PremiumRecruiterList = () => {

    const PAGE_CONTENT = 20;

    const [adminId, setAdminId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState(filtersInitialState);
    const [premiumRecruiter, setPremiumRecruiters] = useState([]);
    const [recruitersData, setRecruitersData] = useState({});
    const [registerTotalCount, setRegisterTotalCount] = useState(0);

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();
            setAdminId(profile.id);
        })()
        let initialRegisterStartDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        let initialRegisterEndDate = moment().format('YYYY-MM-DD');
        initialRegisterStartDate = new Date(initialRegisterStartDate);
        initialRegisterEndDate = new Date(initialRegisterEndDate);
        setFilters({ ...filters, companyType: 1, registerStartDate: initialRegisterStartDate, registerEndDate: initialRegisterEndDate });

    }, []);

    useEffect(() => {
        (async () => {
            if (adminId) {
                setIsLoading(true)
                const count = await fetchRecruitersListCount();
                setRegisterTotalCount(count)
                setPageNumber(1);
                await loadPage(1);
                setIsLoading(false);
            }
        })();
    }, [adminId, filters])

    const fetchRecruitersList = async (page) => {
        const { data } = await fetchPremiumRecruiters(adminId, { ...filters, pageNumber: page, pageContent: PAGE_CONTENT });
        return data;
    };

    const fetchRecruitersListCount = async () => {
        const { data } = await fetchPremiumRecruitersCount(adminId, filters);
        return data;
    };

    const fetchRecruitersData = async (ids) => {
        const recruiterIds = ids.join(',');
        const { data } = await fetchRecruitersDetails(adminId, { recruiterIds, });
        return data;
    };

    const handleFilterChange = (ev) => {
        setFilters({ ...filters, [ev.target.name]: ev.target.value });
    };

    const handlePageChange = async (page) => {
        setPageNumber(page);
        setIsLoading(true);
        await loadPage(page);
        setIsLoading(false);
    };

    const loadPage = async(page) => {
        const data = await fetchRecruitersList(page);
        setPremiumRecruiters(data);
        const recruiterIds = data.map(aData => aData.recruiter_id);
        if (recruiterIds.length) {
            const recData = await fetchRecruitersData(recruiterIds);
            setRecruitersData(recData);
        }
    }

    return (
        <div className='premium-recruiter-list-main-container'>
            <h2 className='premium-recruiter-list heading'>
                Premium Recruiter List
            </h2>
            <div className='filter-container'>
                <ButtonContainer heading='Filter By:'>
                    <li key="registraionDate">
                        <label>Registeration Start & End Date</label>
                        <FromToDatePicker
                            name="date"
                            startDate={filters.registerStartDate}
                            endDate={filters.registerEndDate}
                            handleChangeStart={(e) => setFilters({ ...filters, registerStartDate: e })}
                            handleChangeEnd={(e) => setFilters({ ...filters, registerEndDate: new Date(new Date(e).setHours(23, 59, 59)) })}
                        />
                    </li>
                    <li key="companyType">
                        <label>Company Type</label>
                        <Dropdown name="companyType" data={companyTypeDropdown} defaultValue={filters.companyType} dropdownChange={handleFilterChange} />
                    </li>
                </ButtonContainer>
            </div>
            <h1 className='number-of-recruiters heading'>
                {`${registerTotalCount} Premium Recruiter Registered`}
            </h1>
            <div className='text-center'>
                <Pagination
                    totalItemsCount={registerTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
                <CountDisplay 
                    start={(pageNumber - 1) * PAGE_CONTENT + 1} 
                    end={registerTotalCount < (pageNumber * PAGE_CONTENT) ? registerTotalCount : (pageNumber * PAGE_CONTENT)} total={registerTotalCount} 
                />
            </div>
            <div className='premium-recruiter-list-table'>
                {isLoading ?
                    <Loader /> :
                    <Table
                        fixed
                        selectable
                        className="recruiter-list"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={4} >
                                    Registration Date
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} >
                                    Recruiter Id
                                </Table.HeaderCell>
                                <Table.HeaderCell width={6} >
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4} >
                                    Designation
                                </Table.HeaderCell>
                                <Table.HeaderCell width={8} >
                                    Organisation Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={7} >
                                    Email
                                </Table.HeaderCell>
                                <Table.HeaderCell width={5} >
                                    Phone No
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} >
                                    Job Published till date
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} >
                                    Applications
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} >
                                    Job Posted till date
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} >
                                    Action
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {premiumRecruiter.map((recruiter) => {
                                return (
                                    <Table.Row key={recruiter.recruiter_id} >
                                        <Table.Cell>
                                            {moment(recruitersData[recruiter.recruiter_id].date_created).format('YYYY-MM-DD')}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruiter.recruiter_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].recname}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].designation}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].organisation}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].email}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].phone}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].published_job_count}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].total_applications}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {recruitersData[recruiter.recruiter_id].posted_job_count}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {<a href={`/?order=desc&recruiterId=${recruiter.recruiter_id}&pageNumber=1&pageContent=50&adminId=${adminId}`}>View Jobs</a>}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                }
            </div>
            <div className='text-center'>
                <Pagination
                    totalItemsCount={registerTotalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default PremiumRecruiterList