import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, Table } from 'semantic-ui-react';

import Button from '../../../../components/Button';

import './styles.scss';
import Loader from '../../../../components/Loader';

const BulkActionsModal = (props) => {
    let modalHeading = '';
    if (props.modalData.actiontype === 'bulk_action_deactivate') {
        modalHeading = 'Bulk Deactivate Response';
    } else if (props.modalData.actiontype === 'bulk_action_activate') {
        modalHeading = 'Bulk Activate Response';
    }
    
    return (
        <Modal
            open={props.openModal && props.modalData!==null}
            onClose={props.onModalClose}
        >
            <Modal.Header content={modalHeading} />

            <Modal.Content>
                {props.isLoading ? (
                    <Loader />
                ) : (
                    <Table celled>
                        <Table.Header>
                            <Table.HeaderCell>Recruiter ID</Table.HeaderCell>
                            <Table.HeaderCell>Action Status</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {props.modalData.response.map(item => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{item.id}</Table.Cell>
                                        <Table.Cell>{item.status}</Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                )}
                
            </Modal.Content>
            
            <Modal.Actions>
                <Button skin="dark" text="Close" clickButton={props.onModalClose} /> 
            </Modal.Actions>
        </Modal>
    );
};

BulkActionsModal.propTypes = {
    openModal: PropTypes.bool,
    isLoading: PropTypes.bool,
    modalData: PropTypes.object.isRequired,
    onModalClose: PropTypes.func.isRequired,
};

export default BulkActionsModal;