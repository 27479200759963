const seekerPlansHirist = [
    {
        id: 50,
        validity: 6,
        price: 500,
        value: 'Rs. 500 - 6 Months',
        active: true
    }
];

const seekerPlansIimjobs = [
    {
        id: 50,
        validity: 6,
        price: 500,
        value: 'Rs. 500 - 6 Months',
        active: false,
    },
    {
        id: 99,
        validity: 6,
        price: 999,
        value: 'Rs. 999 - 6 Months',
        active: false,
    },
    {
        id: 200,
        validity: 6,
        price: 2000,
        value: 'Rs. 2000 - 6 Months',
        active: true,
    }
];

const seekerPlansUpdazz = [
    {
        id: 50,
        validity: 6,
        price: 500,
        value: 'Rs. 500 - 6 Months',
        active: true,
    },
    {
        id: 99,
        validity: 6,
        price: 999,
        value: 'Rs. 999 - 6 Months',
        active: true,
    }
];

export function getSeekerPlansArray(skipAll = false) {
    let plans = [ 
        {
            id: '',
            value: 'All'
        }
    ];
    if  (process.env.REACT_APP_PLATFORM === 'hirist') {
        return skipAll ? seekerPlansHirist : [...plans, ...seekerPlansHirist];
    } else if (process.env.REACT_APP_PLATFORM === 'iimjobs') {
        return skipAll ? seekerPlansIimjobs : [...plans, ...seekerPlansIimjobs];
    } else if (process.env.REACT_APP_PLATFORM === 'updazz') {
        return skipAll ? seekerPlansUpdazz : [...plans, ...seekerPlansUpdazz];
    } else if (process.env.REACT_APP_PLATFORM === 'biojoby') {
        return skipAll ? seekerPlansIimjobs : [...plans, ...seekerPlansIimjobs]
    } else {
        return skipAll ? seekerPlansUpdazz : [...plans, ...seekerPlansUpdazz]
    }
};
