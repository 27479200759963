import React, { useState, useEffect } from "react";
import user from "../../../store/user";
import fetchRecruiterCompanies from "../../../services/fetchRecruiterCompanies";


import "./recruiterCompanyList.scss";
import { Table } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import CountDisplay from "../../../components/CountDisplay";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Button";
import Heading from "../../../components/Heading";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import InputBox from "../../../components/InputBox";

const PAGE_CONTENT = 50;
const RecruiterCompanyList = (props) => {

    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 0
    })
    const [admin, setAdmin] = useState({
        adminId: null,
    })
    const [fetchApiCall, setFetchApiCall] = useState(false)

    const [searchKeyword, setSearchKeyword] = useState('')

    const [validation, setValidation] = useState({})

    useEffect(() => {

        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3) {
                setAdmin({
                    adminId: userData.id,
                })
        
                getRecruiterCompanies(userData.id, 1);
                
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });

        

    }, [])

    const getRecruiterCompanies = async (adminId, page, filters = {}) => {
        setFetchApiCall(true);
        try {
            

            const res = await fetchRecruiterCompanies(adminId, { pageNumber: page, pageContent: PAGE_CONTENT, ...filters })
            setContent({
                pageNumber: page,
                list: res.data.data,
                count: res.data.totalCount
            })
            setFetchApiCall(false)
        }
        catch (err) {
            console.log(err);
            setFetchApiCall(false)
        }
    }

    const handlePageChange = (n) => {
        const params =  {};
        if(searchKeyword){
            params['name'] = searchKeyword
        }
        getRecruiterCompanies(admin.adminId, n, params)
        
    }

    const searchCompany = async () => {
        
      if (searchKeyword) {
        setValidation({})
          getRecruiterCompanies(admin.adminId, 1, {name: searchKeyword})
      }
      else {
        setValidation({
            keywordReq: true
        })
      }
    };

    const clearCompanySearch = async (e) =>  {
        setValidation({})
        setSearchKeyword('');
        getRecruiterCompanies(admin.adminId, 1)
    };

    const onAddCompanyBtnClick = () => {
      props.history.push('/addRecruiterCompany')
    }

    const onEnter = async (e) => {
        if(e.key === 'Enter') {
            await searchCompany()
        }
    }

    return (
        <div className="recruiter-company-list-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="company-list-heading">
                <Heading text="Company List" />
            </div>


            <div className='company-search-field'>
                <div>
                <label>Search By Name: </label>
                    <InputBox placeholder="Enter Company Name" name="companyToSearch" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} onKeyPress={onEnter} />

                    <Button skin="dark" text="Search" clickButton={searchCompany}/>
                    <Button skin="light" text="Clear" clickButton={clearCompanySearch} />
                    {validation.keywordReq ? <p className='validatorError'> Enter company name to search</p> : null}

                </div>
                <div className='right-aligned'>
                    <Button skin="dark" text="Add Company" clickButton={onAddCompanyBtnClick} />
                    <Button skin="dark" text="Use Old Version" clickButton={() => {window.location.href = process.env.REACT_APP_CLIENT_URL + '/recruiter/recruiter_company_list.php'}} />

            </div>
            </div>
            <hr />
            <div className="row text-center">
                <Pagination
                    totalItemsCount={content.count}
                    content={PAGE_CONTENT}
                    pageNumber={content.pageNumber}
                    handlePageChange={(n) => handlePageChange(n)}
                />
                <CountDisplay
                    start={content.list.length == 0 ? content.list.length : (content.pageNumber - 1) * PAGE_CONTENT + 1}
                    end={content.pageNumber * PAGE_CONTENT - ((content.list.length < PAGE_CONTENT ? PAGE_CONTENT - content.list.length : 0))}
                    total={content.count}
                />

                {fetchApiCall ? (
                    <Loader />
                ) : (
                    content.count ? (
                        <div className="content">
                        <Table
                            fixed
                            selectable
                            className="mailers-table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={8}>
                                        Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Suggestions
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.list.map((item, i) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                {item.id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.suggestions}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Link to={`/editRecruiterCompany/${item.id}`}>Edit</Link>
                                            </Table.Cell>
                                            
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>

                    </div>
                    ) : (
                        <div>
                            No Records Found
                        </div>
                    )
                )
                }
            </div>
        </div>
    );
};

export default RecruiterCompanyList;
