import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import InputBox from '../../components/InputBox';
import CustomDropdown from './components/CustomDropdown';
import UploadDocumentsModal from './components/UploadDocumentsModal';
import DisplayDocumentModal from './components/DisplayDocumentModal';

import user from '../../store/user';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';
import { getQueryParams } from '../../utils/utilFunctions';
import getUnverifiedRecruiters from '../../services/getUnverifiedRecruiters';
import getUnverifiedRecruitersCount from '../../services/getUnverifiedRecruitersCount';
import getUnverifiedCompanies from '../../services/getAllCompanies';
import verifyRecruiter from '../../services/verifyRecruiter';
import uploadVerificationDocument from '../../services/uploadVerificationDocument';

import './styles.scss';

const dropdownOptions = [
    {
        name: 'All',
        id: 0
    },
    {
        name: 'Recruiters of verified companies',
        id: 1,
    },
    {
        name: 'Rest of the recruiters',
        id: 2,
    }
]

const RecruiterVerificationDashboard = props => {
    const [adminId, setAdminId] = useState(null);
    const [searchtext, setSearchtext] = useState('');
    const [openDocModal, setOpenDocModal] = useState(false);
    const [openDisplayModal, setOpenDisplayModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [selectedRecruiter, setSelectedRecruiter] = useState(null);
    const [documentName, setDocumentName] = useState(null);
    
    const [companyData, setCompanyData] = useState({
        isLoading: false,
        companyList: [],
        searchText: null,
        noResultFound: false,
    });

    const [recruiterData, setRecruiterData] = useState({
        recruiterList: [],
        isLoading: true,
        isPageLoading: false,
        type: 0,
        pageNumber: 0,
        pageContent: 50,
        totalResults: 0
    });

    const handleClear = async () => {
        setRecruiterData(prevObj => {
            return {
                ...prevObj,
                isLoading: true
            }
        });
        
        await getRecruiterList(1, 0, '');
        await getRecruiterCount(0, 0);

        setSearchtext('');
        setRecruiterData(prevObj => {
            return {
                ...prevObj,
                type: 0,
                pageNumber: 1
            }
        });

        updateQueryParams(1, 0, null);
    };

    const handleSearch = async () => {
        await getRecruiterList(1, 0);
        await getRecruiterCount(0);
        updateQueryParams(recruiterData.pageNumber, recruiterData.type, searchtext);
    };

    const handleSearchChange = (event) => {
        setSearchtext(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event && event.key && event.key === 'Enter') {
            handleSearch();
        }
    };

    // when filter dropdown is changed
    const filterChangeHandler = event => {
        const type = event.target.value;

        // if selected filter is not the current filter
        if (type != recruiterData.type) {
            setSearchtext('');
            setRecruiterData(prevObj => {
                return {
                    ...prevObj,
                    isLoading: true,
                    type: type
                }
            });
            getRecruiterList(1, type, '');
            getRecruiterCount(type, '');
            updateQueryParams(1, type);
        }
    };

    // handles page change throught the pagination component
    const handlePageChange = page => {
        setRecruiterData(prevObj => {
            return {
                ...prevObj,
                isPageLoading: true
            }
        });
        getRecruiterList(page);
        updateQueryParams(page, recruiterData.type);
    }

    // get total count
    const getRecruiterCount = async (type = null, search = null) => {
        try {
            const params = {
                type: type!= null ? type : recruiterData.type,
            };

            const query = search !== null ? search : searchtext;

            if (query && query.length > 0) {
                if (+query) {
                    params.recruiterId = query;
                } else {
                    params.recruiterEmail = query;
                }
            }
            const response = await getUnverifiedRecruitersCount(adminId, params);

            if (response.status == 200) {
                setRecruiterData(prevObj => {
                    return {
                        ...prevObj,
                        totalResults: response.data.data[0].count
                    }
                });
            }
        } catch (error) {
            // console.log(error);
        }
    }

    const getRecruiterList = async (page = null, type = null, search = null) => {
        try {
            const params = {
                pageNumber: page != null ? page : recruiterData.pageNumber + 1,
                pageContent: recruiterData.pageContent,
                type: type != null ? type : recruiterData.type,
            };

            const query = search !== null ? search : searchtext;

            if (query && query.length > 0) {
                if (+query) {
                    params.recruiterId = query;
                } else {
                    params.recruiterEmail = query;
                }
            }
            const response = await getUnverifiedRecruiters(adminId, params);

            if (response.status == 200){
                setRecruiterData(prevObj => {
                    return {
                        ...prevObj,
                        recruiterList: response.data.data,
                        pageNumber: page != null ? page : prevObj.pageNumber + 1,
                        isLoading: false,
                        isPageLoading: false,
                        type: type != null ? type : prevObj.type
                    }
                });
            }
        } catch (error) {
            // console.log(error);
        }

        setRecruiterData(prevObj => {
            return {
                ...prevObj,
                isLoading: false,
                isPageLoading: false,
            }
        });
    };

    const getCompanies = async () => {
        setCompanyData(prevObj => {
            return {
                ...prevObj,
                isLoading: true,
                noResultFound: false
            }
        });
        try {
            const response = await getUnverifiedCompanies(adminId, {
                displayNameLike: companyData.searchText,
                nameSort: 1,
                pageNumber: 1,
                pageContent: 500,
                status: 1,
                targetDomain: process.env.REACT_APP_PLATFORM
            });
            
            if (response.status == 200) {
                let noResultFound = null;
                if (response.data.data.data.length == 0) {
                    noResultFound = true;
                }
                setCompanyData(prevObj => {                    
                    return {
                        ...prevObj,
                        companyList: response.data.data.data,
                        isLoading: false,
                        noResultFound,
                    };
                });
            }
        } catch (error){
            setCompanyData(prevObj => {
                return {
                    ...prevObj,
                    noResultFound: true,
                    isLoading: false,
                }
            });
            // console.log(error);
        }
    };

    const uploadRecruiterDocument = async data => {
        setIsLoadingModal(true);
        try {
            const response = await uploadVerificationDocument(adminId, data);

            if (response.status == 200) {
                toast.success('Recruiter Verified Successfully');
                setOpenDocModal(false);
                setTimeout(() => window.location.reload(), 300);
            } else {
                toast.error('Something went wrong. Please try again later.');
            }
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later.');
        }
        setIsLoadingModal(false);
    };

    // update search text on change
    const onSearchChangeHandler = text => {
        setCompanyData(prevObj => {
            return {
                ...prevObj,
                searchText: text,
                noResultFound: false
            };
        });
    };

    // store selected data in recruiter object
    const handleOnItemSelected = (companyId, label, index, recruiterId) => {
        setRecruiterData(prevObj => {
            let oldRecruiterArr = prevObj.recruiterList;
            const selectedCompany = companyData.companyList.filter(item => item.companyId == companyId);

            // if index is present
            if (index || index == 0) {
                oldRecruiterArr[index] = {
                    ...oldRecruiterArr[index],
                    selectedCompany: selectedCompany[0] || null
                }
            } else {
                // if recruiter Id is present
                oldRecruiterArr = oldRecruiterArr.map(item => {
                    if (item.id == recruiterId) {
                        return {
                            ...item,
                            selectedCompany: selectedCompany[0] || null
                        }
                    } 
                    return item;
                });
            }
            
            return {
                ...prevObj,
                recruiterList: oldRecruiterArr
            }
        })
    };

    // verify recruiter
    const handleVerifyRecruiter = async recruiterId => {
        const selectedRec = recruiterData.recruiterList.filter(item => item.id == recruiterId);

        if (selectedRec.length > 0) {
            if (!selectedRec[0].selectedCompany) {
                toast.error('No company selected for this recruiter');
                return;
            }
            
            try {
                const response = await verifyRecruiter(adminId, {
                    recruiterId: recruiterId,
                    status: 1,
                    companyId: selectedRec[0].selectedCompany.companyId,
                    unverifiedCompanyId: selectedRec[0].selectedCompany.id,
                    displayName: selectedRec[0].selectedCompany.displayName
                });
    
                if (response.status == 200) {
                    toast.success('Recruiter Verified Successfully');
                    setTimeout(() => window.location.reload(), 500);
                } else {
                    toast.error('Something went wrong. Please try again later');
                }
            } catch (error) {
                // console.log(error);
                toast.error('Something went wrong. Please try again later');
            }

        } 
    }

    // update query params in url
    const updateQueryParams = (page, type, search) => {
        const queryArr = [];
        if (page) {
            queryArr.push(`pg=${page}`);
        } 
        if (type) {
            queryArr.push(`type=${type}`);
        }
        if (search && search.length > 0) {
            queryArr.push(`search=${search}`);
        }

        props.history.push(`/recruiter_verification?${queryArr.join('&')}`);
    };

    const handleClearList = () => {
        setCompanyData(prevObj => {
            return {
                ...prevObj,
                companyList: [],
                searchText: null,
            }
        });
    };

    const handleOnCloseModal = () => {
        setOpenDocModal(false);
    };

    // fetch company after every 300 ms when search text changes
    useEffect(() => {
        let timerID = null;
        
        try {
            timerID = setTimeout(() => {
                if (!companyData.isLoading && companyData.searchText && companyData.searchText.length >= 2) {
                    getCompanies();
                }
            }, 300);
        } catch (error) {
            // console.log(error);
        }

        return () => {
            if (timerID) {
                clearTimeout(timerID);
            }
        }
    }, [companyData.searchText]);

    // fetch admin profile details on first page load
    useEffect(() => {
        async function getProfile() {
            const profile = await user.getProfile();

            const adminAccess = user.checkFeatureAccess(adminFeatureMapping.RECRUITER_VERIFICATION);

            if (!adminAccess)
                window.location.href = '/notAdmin';

            return profile;
        }

        getProfile().then(data => {
            setAdminId(data.id);

        });
    }, []);

    // check url query params and fetch recruiter list accordingly
    // after admin profile has been loaded
    useEffect(() => {
        if (adminId) {
            // check query params
            const queryParams = getQueryParams(window.location.search);

            const pg = queryParams.pg || 1;
            const type = queryParams.type || 0;
            const search = queryParams.search || '';

            if (search && search.length > 0){
                setSearchtext(search);
            }

            getRecruiterList(pg, type, search);
            getRecruiterCount(type, search);
        }
    }, [adminId]);

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width="one">ID</Table.HeaderCell>
                <Table.HeaderCell width="two">Name</Table.HeaderCell>
                <Table.HeaderCell width="two">Email</Table.HeaderCell>
                <Table.HeaderCell width="two">Company</Table.HeaderCell>
                <Table.HeaderCell width="one">Verification Status</Table.HeaderCell>
                <Table.HeaderCell width="two" className="actions">Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )

    let mainContent;
    if (recruiterData.isLoading || recruiterData.isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        );
    } else if (!recruiterData.isLoading && (!recruiterData.recruiterList || recruiterData.recruiterList.length === 0)) {
        mainContent = <>No results found</>;
    } else {
        mainContent = (
            <Table>
                {tableHeading}
                <Table.Body>
                    {
                        recruiterData.recruiterList.map((item, index) => {
                            let status = null;

                            if (item.company_id) {
                                status = 'Verified';
                            } else {
                                status = 'Unverified';
                            }

                            return (
                                <Table.Row className="table_row">
                                    <Table.Cell verticalAlign="top">{item.id}</Table.Cell>
                                    <Table.Cell verticalAlign="top" className="table_cell recname">{item.recname}</Table.Cell>
                                    <Table.Cell verticalAlign="top" className="table_cell email">{item.email}</Table.Cell>
                                    <Table.Cell verticalAlign="top" className="table_cell email">{item.companyName || ''}</Table.Cell>
                                    <Table.Cell verticalAlign="top" className="table_cell status">{status}</Table.Cell>
                                    <Table.Cell verticalAlign="top" className="actions">
                                        <div className="actions_container">
                                            {
                                                item.isNonCompanyDomain ? null : (
                                                    <div className="company_dropdown_container">
                                                        <CustomDropdown
                                                            placeholder="Select Verified Company"
                                                            className="company_dropdown"
                                                            dropdownList={companyData.companyList.map(item => {
                                                                return {
                                                                    label: item.displayName,
                                                                    value: item.companyId,
                                                                }
                                                            })}
                                                            isLoading={companyData.isLoading}
                                                            isSelected={item.selectedCompany}
                                                            clearSelected={() => handleOnItemSelected(null, null, index)}
                                                            onInputChange={onSearchChangeHandler}
                                                            onItemSelected={(id, label) => handleOnItemSelected(id, label, index)}
                                                            clearList={handleClearList}
                                                            noResultFound={companyData.noResultFound}
                                                        />
                                                        <Button skin="dark" text="Verify" clickButton={() => {
                                                            handleVerifyRecruiter(item.id);
                                                        }}/>
                                                    </div>
                                                )
                                            }
                                            
                                            <div className="actions_container-btns">
                                                {item.isNonCompanyDomain ? 
                                                    <Button skin="dark" text="Upload Documents" clickButton={() => {
                                                        setSelectedRecruiter(item.id);
                                                        setOpenDocModal(true);
                                                    }} /> : null}
                                                {item.documentName ? (
                                                    <Button skin="dark" text="View Existing Document" clickButton={() => {
                                                        setDocumentName(item.documentName);
                                                        setSelectedRecruiter(item.id);
                                                        setOpenDisplayModal(true);
                                                    }}/>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        )
    }

    let topPageIndicators;
    let bottomPageIndicators;

    if (!recruiterData.isLoading && recruiterData.recruiterList.length > 0) {
        topPageIndicators = (
            <div className="row text-center top-page-controller">
                <Pagination totalItemsCount={recruiterData.totalResults} content={recruiterData.pageContent} pageNumber={recruiterData.pageNumber} handlePageChange={handlePageChange} />
                <CountDisplay start={(recruiterData.pageNumber - 1) * recruiterData.pageContent + 1} end={recruiterData.pageNumber * recruiterData.pageContent} total={recruiterData.totalResults} />
            </div>
        );

        if (!recruiterData.isPageLoading) {
            bottomPageIndicators = (
                <div className="row text-center bottom-page-controller">
                    <Pagination totalItemsCount={recruiterData.totalResults} content={recruiterData.pageContent} pageNumber={recruiterData.pageNumber} handlePageChange={handlePageChange} />
                </div>
            )
        }
    } else {
        topPageIndicators = <div></div>;
        bottomPageIndicators = <div></div>;
    }

    return (
        <div className="page-content recruiter-verification">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className="page-heading">
                <Heading text={'Recruiter Verification Dashboard'} />
            </div>

            <div className="controller__container">
                <div className="controller__container--dropdown">
                    <label>Filter: </label>
                    <Dropdown 
                        defaultValue={recruiterData.type}
                        value={recruiterData.type}
                        data={dropdownOptions} 
                        name="filter" 
                        dropdownChange={filterChangeHandler}
                    />
                </div>
                <div className="controller__container--search">
                    <InputBox 
                        placeholder="Search with Email/ID" 
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}
                        value={searchtext}
                    />
                    <Button skin="dark" text="Search" clickButton={handleSearch}/>
                    <Button skin="light" text="Clear" clickButton={handleClear}/>
                </div>
            </div>

            {topPageIndicators}

            <div className="main-content">
                {mainContent}
            </div>

            {bottomPageIndicators}

            {openDocModal ? (
                <UploadDocumentsModal 
                    recruiterId={selectedRecruiter}
                    recruiterList={recruiterData.recruiterList}
                    openModal={openDocModal}
                    onCloseModal={handleOnCloseModal}
                    companyData={companyData}
                    onInputChange={onSearchChangeHandler}
                    onDocumentUpload={uploadRecruiterDocument}
                    clearSelected={() => handleOnItemSelected(null, null, index)}
                    onItemSelected={(id, label, recruiterId) => handleOnItemSelected(id, label, null, recruiterId)}
                    clearList={handleClearList}
                />
            ) : null}

            {openDisplayModal ? (
                <DisplayDocumentModal
                    adminId={adminId}
                    recruiterId={selectedRecruiter}
                    openModal={openDisplayModal}
                    documentName={documentName}
                    onCloseModal={() => setOpenDisplayModal(false)}
                />
            ) : null}
        </div>
    )
};

export default RecruiterVerificationDashboard;