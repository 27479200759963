import React from "react";
import { useState } from "react";
import InputBox from "../../../../components/InputBoxWithoutCheck";
import Button from "../../../../components/Button";

import Heading from "../../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import user from "../../../../store/user";
import { useEffect } from "react";
import validator from "validator";

import './styles.scss'
import { getQueryParams } from "../../../../utils/utilFunctions";
import fetchCourseRecruiter from "../../../../services/fetchCourseRecruiter";
import changeCourseRecruiter from "../../../../services/changeCourseRecruiter";

const validationMsg = {
    validRecId: 'Please enter valid recruiter id'
}

const ChangeCourseRecruiter = (props) => {

  const {courseId} = getQueryParams(props.location.search);

  const [details, setDetails] = useState({
    title: '',
    id: '',
    recruiter: ''
  })

  const [validations, setValidations] = useState({})
  const [submitApiCall, setSubmitApiCall] = useState(false)
  const [admin, setAdmin] = useState({
    adminId: null
  })
  const [newRecruiter, setNewRecruiter] = useState('')

  useEffect(() => {
    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
        //checkFeatureAccess
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id
        })

        if(courseId) {
          getRecruiterDetail(userData.id);
        }

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    }).catch(e => {
        toast.error('Something went wrong!')
        console.log(e);
    });
  }, [])

  const getRecruiterDetail = async (adminId) => {

    try {
        const res = await fetchCourseRecruiter(adminId, courseId)
        const data = res.data.data;
        setDetails({
        title: data['title'],
        id: data['id'],
        recruiter: data['uid']
        })
    }

    catch(e) {
        toast.error('Something went wrong!');
        console.log(e)
    }
    
    
  }

  const checkEmpty = (val) => {
    if (!val) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  const invalidInput = (val) => {
    if(+val && Number.isInteger(+val) && +val > 0){
      return false;
    }

    return true;
  }

  const validate = () => {
     const validations = {
      validRecId: invalidInput(newRecruiter) 
     }
     setValidations({...validations})

     for (let key in validations) {
      if (validations[key]) {
        // scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;

  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if(validate()) {
      const data = {
        courseId,
        newRecId: newRecruiter
      }

      try {
        if(courseId) {
          setSubmitApiCall(true);
          await changeCourseRecruiter(admin.adminId, data);
          
          toast.success('Recruiter updated successfully');
          
          setTimeout(() =>  props.history.goBack(), 2000)
        }

      }
      catch(e){
        console.log(e);

        if(e.message.includes('404')) {
          return toast.error('Enter Valid Manager Email Id');
        }
        toast.error('Something went wrong');
      }
      finally{
        setSubmitApiCall(false);
      }
      
    }
  }

  return (
    <div>
      <div className="change-recruiter-container">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />

        <div className="chng-rec-pageHeading">
          <Heading text= 'Change Course Provider'/>

          <Button
              skin="dark"
              className="btn_course_list"
              text="Course List"
              clickButton={() => {
                  props.history.push("/courseList");
              }}
          />

          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/course_recruiter_change_new.php?cid=${details['id']}`} target='_blank'>
              <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
          </a>
        </div>
        <hr />
        <div className="create-tag-mailer">
          <form>
            <div className="row section details-section">
              <p className="label-heading"> Details </p>

              <div className="form-group">
                <label className="col-sm-2 left"> Title</label>
                <div className="col-sm-6 left valueBox">
                    <p>{details['title']}</p>
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Id</label>
                <div className="col-sm-6 left valueBox">
                    <p>{details['id']}</p>
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Recruiter</label>
                <div className="col-sm-6 left valueBox">
                    <p>{details['recruiter']}</p>
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left">New Recruiter Id</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="newRecruiter"
                    value={newRecruiter}
                    placeholder="New Recruiter Id"
                    onChange={(e) => setNewRecruiter(e.target.value)}
                  />
                  {validations.validRecId ? <p className='validatorError'> {validationMsg.validRecId} </p> : null}
                </div>
              </div>
            </div>

            <div className="actions">
              <Button
                skin="dark"
                disabled={submitApiCall}
                loading={submitApiCall}
                text='Update'
                clickButton={onSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangeCourseRecruiter