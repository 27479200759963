import React from 'react';
import { Route, Redirect, Router, Switch } from 'react-router-dom';
import {
    BASE_ROUTE,
    JOB_LIST_ROUTE,
    RESUMELIST_ROUTE,
    POST_JOB_ROUTE,
    EDIT_JOB_ROUTE,
    DISPLAY_TAGS_ROUTE,
    NOT_ADMIN_ROUTE,
    PLACEMENT_ROUTE,
    EDIT_PLACEMENT_ROUTE,
    INTEGRATION_ROUTE,
    ADDINTEGRATION_ROUTE,
    POST_PLACEMENT_ROUTE,
    EDITINTEGRATION_ROUTE,
    CHAT_MESSAGES_ROUTE,
    PROJECT_LIST_ROUTE,
    EDIT_RECRUITER_PASSWORD_ROUTE, 
    RESET_RECRUITER_PASSWORD_LINK_ROUTE,
    CREATE_TAG_MAILER,
    EDIT_TAG_MAILER,
    LIST_TAG_MAILER,
    ORDERS_LIST,
    LIST_SHOWCASE_COMPANIES,
    CREATE_SHOWCASE_COMPANY,
    EDIT_SHOWCASE_COMPANY,
    REPORT_SHOWCASE_COMPANY,
    LIST_LEADS,
    CREATE_LEAD,
    Company_Management_List,
    Company_Management_NewCompany,
    Company_Management_EditCompany,
    Transfer_Job_Management,
    RECRUITER_PROFILE_MGT_EDIT,
    SHOWCASE_PLAN_LIST,
    SHOWCASE_PLAN_ADD,
    RECRUITER_INCOMPLETE_EDIT,
    RECRUITER_INCOMPLETE,
    RECRUITER_PROFILE_NOTIFICATION,
    RECRUITER_DOMAIN_MANAGEMENT,
    RECRUITER_MGR_MGT,
    RECRUITER_MGT,
    OTHER_FUNCTIONAL_AREA,
    DOMAIN_MAPPING,
    SEEKER_SUBSCRIPTIONS,
    SEEKER_SUBSCRIPTIONS_FORM,
    RESUME_BUILDER,
    RESUME_BUILDER_EDIT,
    RESUME_BUILDER_ADD,
    AVG_PUBLISH_TIME,
    INVOICE_UPLOAD,
    TRANSACTION_UPLOAD,
    JOBSEEKER_MGT,
    APPLICATIONS,
    PREMIUM_JOB_LIST,
    UNICORN_DASHBOARD,
    ANY_RECRUITER_LOGIN,
    ANY_RECRUITER_LOGIN_FORGOT_PSWRD,
    RECRUITER_CREDIT_MANAGEMENT,
    RECRUITER_CREDIT_MANAGEMENT_ADD,
    RECRUITER_CREDIT_MANAGEMENT_EDIT,
    RECRUITER_CREDIT_MANAGEMENT_DISTRIBUTE,

    RECRUITER_COMPANY_LIST,
    RECRUITER_NEW_COMPANY,
    RECRUITER_EDIT_COMPANY,
    PREMIUM_RECRUITER_LIST,
    RECRUITER_PROFILE_MGT,
    PREMIUM_POSTING_ADOPTION,
    NOTIFIER_LOGS,
    ADD_CLIENT_ENGAGEMENT,
    CLIENT_ENG_REPORTSHEET,
    EDIT_CLIENT_ENGAGEMENT,
    SEEKER_NOTIFICATION_EMAIL_SETTINGS,
    JOB_HISTORY,
    DIVERSITY_PLAN_ADD,
    DIVERSITY_PLAN_LIST,
    FOLLOW_UP_VIEW,
    USERS_ANALYSIS,
    JOBS_ANALYSIS,
    FUNNEL_ANALYSIS,
    FOLLOWER_REPORT,
    SEARCH_MAIL_REPORT, 
    SEARCH_QUERY_REPORT,
    SEARCH_FLAGGED_USERS,
    PREMIUM_JOB_LISTING,
    PREMIUM_JOB_DETAIL,
    LEARNING_SUBSCRIPTION,
    PRO_LEAD_GEN_REPORT,
    USER_APPLY_JOBS,
    TAGS_ANALYSIS,
    PAYMENT_SYNC_DASHBOARD,
    RECRUITER_VERIFICATION_DASHBOARD,
    COUNT_SEARCHED_KEYWORDS,
    REC_FOLLOW_UP_REPORT,
    CONFIDENTIAL_JOBS,
    APPLY_REFERENCE_ANALYSIS,
    REPORTS,
    ACTIVITY_DETAILS,
    ADMIN_USERS,
    SEEKER_BLACKLIST,
    XML_FEED_EXCLUDED_USERS,
    SEEKER_VERIFICATION_LIST,
    CHANGE_SEEKER_PASSWORD,
    SEEKER_FOLLOW_UP_MGT,
    ALL_TAG_COUNT,
    ANY_JOBSEEKER_LOGIN,
    COURSE_LIST,
    COURSE_MAPPING_LIST,
    ADD_EDIT_COURSE,
    COURSE_ENQUIRY,
    COURSE_VIEW_LEADS,
    CHANGE_COURSE_RECRUITER,
    MAPPING_ADD_EDIT,
    ATS_PANEL,
    ATS_PANEL_RECS,
    ATS_PANEL_ALL_RECS,
    JOBSEEKER_PLAN_ACTIVE,
    COMPETITION_DASHBOARD,
    RECRUITER_LOG_REPORT,
    MANAGER_TEAM_MGT,
    POTENTIAL_PAID_JOBS,
    PRO_MEMBERSHIP_INVOICE,
    PRO_MEMBERSHIP_INVOICE_CN,
    JOB_VISIBILITY,
    VALUE_DELIVERY_JOBS,
    LG_BILL,
    LG_BILL_EDIT,
    LG_BILLS,
    LG_COLLECTION,
    LG_COLLECTIONS,
    LG_COLLECTION_EDIT,
    LG_BILLING_TARGET,
    LG_BILLING_TARGET_EDIT,
    LG_BILLING_TARGETS,
    LG_COLLECTION_TARGETS,
    LG_COLLECTION_TARGET_EDIT,
    LG_COLLECTION_TARGET,
    COMPANY_ACQUISITION,
    JOB_ACQUISITION
} from '../models/routes';
import JobList from '../containers/JobList';
import ProjectList from '../containers/ProjectList';
import EditJob from '../containers/EditJob';
import DisplayTags from '../containers/DisplayTags';
import PlacementList from '../containers/PlacementList';
import IntegrationList from '../containers/IntegrationList';
import AddIntegration from '../containers/AddIntegration';
import EditPlacement from '../containers/EditPlacement';
import ResumeList from '../containers/ResumeList';
import ChangePassword from '../containers/ChangePassword';
import ResetPasswordLink from '../containers/ResetPasswordLink';
import history from './history';
import NotAdmin from '../components/NotAdmin';
import ChatListPage from '../containers/ChatList/pages/ChatListPage';
import Create from '../containers/TagMailer/Create';
import ShowcaseCreate from '../containers/ShowcaseCompany/Create';
import List from '../containers/TagMailer/List';
import ShowcaseList from '../containers/ShowcaseCompany/List';
import OrdersList from '../containers/Orders';
import ShowcaseReport from '../containers/ShowcaseCompany/Report';
import { ListList } from 'semantic-ui-react';
import LeadList from '../containers/Lead/List/LeadList';
import LeadCreate from '../containers/Lead/Create/LeadCreate';
import CompanyList from '../containers/CompanyManagement/CompanyList';
import NewCompany from '../containers/CompanyManagement/NewCompany';
import ShowcasePlanList from '../containers/ShowcaseCompany/PlanManagement/List'
import ShowcasePlanAdd from '../containers/ShowcaseCompany/PlanManagement/Add'
import TransferJob from '../containers/TransferJob';
import RecruiterProfileMgtEdit from '../containers/RecruiterProfileMgtEdit';
import RecruiterManagement from '../containers/RecruiterManagement';
import RecruiterIncomplete from '../containers/RecruiterIncomplete';
import Navbar from '../components/Navbar';
import RecruiterProfileNotification from '../containers/RecruiterProfileNotification';
import DomainManagement from '../containers/DomainManagement';
import ManagerManagement from '../containers/ManagerManagement';
import OtherFunctionalArea from '../containers/OtherFunctionalArea/OtherFunctionalArea';
import DomainMapping from '../containers/DomainMapping';
import paymentInfo from '../containers/PaymentJobSeekerInfo/paymentInfo/paymentInfo';
import transactionForm from '../containers/PaymentJobSeekerInfo/newTransaction/newTransaction';
import ResumeBuilder from '../containers/ResumeBuilder/CandidateList'
import ResumeBuilderCreate from '../containers/ResumeBuilder/EditCandidate'
import AvgTimeToPublishList from '../containers/AvgPublishTime/List/AvgTimeToPublishList';
import UploadInvoice from '../containers/UploadInvoice';
import JobSeekerManagement from '../containers/JobSeekerManagement';
import ApplicationList from '../containers/ApplicationList';
import PremiumJobList from '../containers/PremiumJobList/PremiumJobList';
import PremiumRecruiterList from '../containers/PremiumRecruiterList/PremiumRecruiterList';
import PremiumPostingAdoption from '../containers/PremiumPostingAdoption/PremiumPostingAdoption';
import UnicornDashboard from '../containers/UnicornDashboard';
import Login from '../containers/AnyRecruiterLogin/Login';
import * as CreditManagement from '../containers/CreditManagement';
import RecruiterProfileMgt from '../containers/RecruiterProfileMgt';
import RecruiterCompanyList from '../containers/RecruiterCompanyList/List/RecruiterCompanyList';
import EditRecruiterCompany from '../containers/RecruiterCompanyList/AddOrUpdate/EditRecruiterCompany';
import NotifierLogs from '../containers/NotifierLogs';
import RecruiterVerificationDashboard from '../containers/RecruiterVerificationDashboard';
import AddClientEngagement from '../containers/ClientEngagement/Add/AddClient'
import ClientEngagementList from '../containers/ClientEngagement/List/ClientEngagementReportsheet'
import SeekerEmailNotificationSettings from '../containers/SeekerEmailNotificationSettings';
import JobHistory from '../containers/JobHistory/JobHistory';
import DiversityPlanAdd from '../containers/DiversityPlan/Add/DiversityPlanAdd';
import DiversityPlanList from '../containers/DiversityPlan/List/DiversityPlanList';

import FollowUpView from '../containers/FollowUpView/FollowUpView';
import UsersAnalysis from '../containers/UsersAnalysis';
import JobAnalysis from '../containers/JobAnalysis';
import FunnelAnalysis from '../containers/FunnelAnalysis';
import FollowerReport from '../containers/FollowerReport/FollowerReport';
import SearchMailReport from '../containers/SearchMailReport'; 
import SearchQueryReport from '../containers/SearchQueryReport';
import SearchFlaggedUsers from '../containers/SearchFlaggedUsers'; 
import PremiumJobListing from '../containers/PremiumJobListing';
import PremiumJobDetail from '../containers/PremiumJobDetail';
import CountSearchedKeywords from '../containers/CountSearchedKeywords';
import FollowUpReport from '../containers/FollowUpReport';
import LearnAndGrowManagement from '../containers/Learn&GrowManagement/Learn&GrowManagement';
import UserAppliedJobs from '../containers/UserAppliedJobs/UserAppliedJobs';
import TagsAnalysis from '../containers/TagsAnalysis/TagsAnalysis'; 
import PaymentSyncDashboard from '../containers/PaymentSyncDashboard';
import ProLeadGenReport from '../containers/ProLeadGenReport'
import ConfidentialJobsList from '../containers/ConfidentialJobs';
import ApplyReferenceAnalysis from '../containers/ApplyReferenceAnalysis/ApplyReferenceAnalysis'
import ReportList from '../containers/Reports';
import RecruiterActivityList from '../containers/RecruiterActivity/RecruiterActivityList';
import AdminUsers from '../containers/AdminUsers';
import Blacklist from '../containers/Blacklist';
import XMLFeedExcludedUsers from '../containers/XMLFeedExcludedUsers';
import UnverifiedUserList from '../containers/UnverifiedUsers';
import ChangeSeekerPassword from '../containers/ChangeSeekerPassword';
import JobseekerFollowUpMgt from '../containers/JobseekerFollowUpMgt';
import AllTagsCount from '../containers/AllTagCount';
import AnyJobseekerLogin from '../containers/AnyJobseekerLogin/AnyJobseekerLogin';
import CourseList from '../containers/Courses/List';
import AddEdit from '../containers/Courses/AddEdit';
import CourseMappingList from '../containers/Courses/Mapping/List';
import CourseEnquiry from '../containers/Courses/Leads/Enquiry';
import CourseViewLeads from '../containers/Courses/Leads/ViewLeads';
import ChangeCourseRecruiter from '../containers/Courses/Leads/ChangeRecruiter';
import MappingAddEdit from '../containers/Courses/Mapping/AddEdit';
import ATSPanel from '../containers/ATSPanel';
import ATSPanelRecs from '../containers/ATSPanel/Recruiters';
import JobseekerPlanActive from '../containers/JobseekerPlanActive';
import UploadTransactions from '../containers/UploadTransactions';
import CompetitionDashboard from '../containers/CompetitionDashboard';
import RecruiterLogReport from '../containers/RecruiterLogReport';
import ManagerTeamMgt from '../containers/ManagerTeamMgt';
import { PotentialPaidJobs } from '../containers/PotentialPaidJobs/PotentialPaidJobs';
import ProMembershipInvoice from '../containers/ProMembership/Invoice';
import ProMembershipInvoiceCN from '../containers/ProMembership/InvoiceCreditNotes';
import LGBillingContainer from '../containers/LGBilling/LGBill';
import LGCollectionContainer from '../containers/LGBilling/LGCollection';
import LGBillTargetContainer from '../containers/LGBilling/LGBillTarget';
import LGCollectionTargetContainer from '../containers/LGBilling/LGCollectionTarget';
import JobVisibility from '../containers/JobVisibility/JobVisibility';
import ValueDeliveryJobs from '../containers/ValueDeliveryJobs/ValueDeliveryJobs';
import CompanyAcquisition from '../containers/Acquisition/Company/CompanyAcquisition';
import JobAcquisition from '../containers/Acquisition/Jobs/JobAcquisition';

export default (
    <Router history={history}>
        <div>
            <Navbar />
            <Switch>
                <Route path={JOB_LIST_ROUTE} exact component={JobList} />
                <Route path={POST_JOB_ROUTE} exact component={EditJob} />
                <Route path={EDIT_JOB_ROUTE} exact component={EditJob} />
                <Route path={DISPLAY_TAGS_ROUTE} exact component={DisplayTags} />
                <Route path={NOT_ADMIN_ROUTE} exact component={NotAdmin} />
                <Route path={PLACEMENT_ROUTE} exact component={PlacementList} />
                <Route path={EDIT_PLACEMENT_ROUTE} exact component={EditPlacement} />
                <Route path={POST_PLACEMENT_ROUTE} exact component={EditPlacement} />
                <Route path={INTEGRATION_ROUTE} exact component={IntegrationList} />
                <Route path={ADDINTEGRATION_ROUTE} exact component={AddIntegration} />
                <Route path={RESUMELIST_ROUTE} exact component={ResumeList} />
                <Route path={EDITINTEGRATION_ROUTE} exact component={AddIntegration} />
                <Route path={CHAT_MESSAGES_ROUTE} exact component={ChatListPage} />
                <Route path={PROJECT_LIST_ROUTE} exact component={ProjectList} />
                <Route path={EDIT_RECRUITER_PASSWORD_ROUTE} exact component={ChangePassword} />
                <Route path={RESET_RECRUITER_PASSWORD_LINK_ROUTE} exact component={ResetPasswordLink} />
                <Route path={CREATE_TAG_MAILER} exact component={Create} />
                <Route path={EDIT_TAG_MAILER} exact component={Create} />
                <Route path={LIST_TAG_MAILER} exact component={List} />
                <Route path={ORDERS_LIST} exact component={OrdersList} />
                <Route path={SHOWCASE_PLAN_LIST} exact component={ShowcasePlanList} />
                <Route path={SHOWCASE_PLAN_ADD} exact component={ShowcasePlanAdd} />
                <Route path={INVOICE_UPLOAD} exact component={UploadInvoice} />

                <Route path={LIST_SHOWCASE_COMPANIES} exact component={ShowcaseList} />
                <Route path={CREATE_SHOWCASE_COMPANY} exact component={ShowcaseCreate} />
                <Route path={EDIT_SHOWCASE_COMPANY} exact component={ShowcaseCreate} />

                <Route path={REPORT_SHOWCASE_COMPANY} exact component={ShowcaseReport} />
                <Route path={Company_Management_List} exact component={CompanyList} />
                <Route path={Company_Management_NewCompany} exact component={NewCompany} />
                <Route path={Company_Management_EditCompany} exact component={NewCompany} />

                <Route path={Transfer_Job_Management} exact component={TransferJob} />


                <Route path={SEEKER_FOLLOW_UP_MGT} exact component={JobseekerFollowUpMgt} />
                <Route path={JOBSEEKER_PLAN_ACTIVE} exact component={JobseekerPlanActive} />
                <Route path={ADMIN_USERS} exact component={AdminUsers} />
                <Route path={RECRUITER_PROFILE_MGT_EDIT} exact component={RecruiterProfileMgtEdit} />
                <Route path={RECRUITER_INCOMPLETE_EDIT} exact component={RecruiterProfileMgtEdit} />
                <Route path={RECRUITER_MGT} exact component={RecruiterManagement} />
                <Route path={LIST_LEADS} exact component={LeadList} />
                <Route path={CREATE_LEAD} exact component={LeadCreate} />
                <Route path={SEEKER_SUBSCRIPTIONS} exact component={paymentInfo} />
                <Route path={SEEKER_SUBSCRIPTIONS_FORM} exact component={transactionForm} />

                <Route path={RECRUITER_LOG_REPORT} exact component={RecruiterLogReport} />
                <Route path={RECRUITER_INCOMPLETE} exact component={RecruiterIncomplete} />
                <Route path={RECRUITER_DOMAIN_MANAGEMENT} exact component={DomainManagement}/>
                <Route path={RECRUITER_MGR_MGT} exact component={ManagerManagement} />
                <Route path={MANAGER_TEAM_MGT} exact component={ManagerTeamMgt} />
                <Route path={OTHER_FUNCTIONAL_AREA} exact component={OtherFunctionalArea} />            

                <Route path={DOMAIN_MAPPING} exact component={DomainMapping} />

                <Route path={RECRUITER_PROFILE_NOTIFICATION} exact component={RecruiterProfileNotification} />
                <Route path={RESUME_BUILDER} exact component={ResumeBuilder} />
                <Route path={RESUME_BUILDER_EDIT} exact component={ResumeBuilderCreate} />
                <Route path={RESUME_BUILDER_ADD} exact component={ResumeBuilderCreate} />
                <Route path={AVG_PUBLISH_TIME} exact component={AvgTimeToPublishList} />

                <Route path={SEEKER_BLACKLIST} exact component={Blacklist} />
                <Route path={JOBSEEKER_MGT} exact component={JobSeekerManagement} />
                <Route path={APPLICATIONS} exact component={ApplicationList} />
                <Route path={PREMIUM_JOB_LIST} exact component={PremiumJobList} />
                <Route path={PREMIUM_RECRUITER_LIST} exact component={PremiumRecruiterList} />
                <Route path={PREMIUM_POSTING_ADOPTION}  exact component={PremiumPostingAdoption} />
                <Route path={UNICORN_DASHBOARD} exact component={UnicornDashboard} />

                <Route path={ANY_RECRUITER_LOGIN} exact component={Login} />
                <Route path={RECRUITER_CREDIT_MANAGEMENT} exact component={CreditManagement.Listing} />
                <Route path={RECRUITER_CREDIT_MANAGEMENT_ADD} exact component={CreditManagement.Add} />
                <Route path={RECRUITER_CREDIT_MANAGEMENT_EDIT} exact component={CreditManagement.Edit} />
                <Route path={RECRUITER_CREDIT_MANAGEMENT_DISTRIBUTE} exact component={CreditManagement.Distribute} />
                
                <Route path={RECRUITER_PROFILE_MGT} exact component={RecruiterProfileMgt} />

                <Route path={RECRUITER_COMPANY_LIST} exact component={RecruiterCompanyList} />
                <Route path={RECRUITER_NEW_COMPANY} exact component={EditRecruiterCompany} />
                <Route path={RECRUITER_EDIT_COMPANY} exact component={EditRecruiterCompany} />
                <Route path={NOTIFIER_LOGS} exact component={NotifierLogs} />
                <Route path={CLIENT_ENG_REPORTSHEET} exact component={ClientEngagementList} />
                <Route path={ADD_CLIENT_ENGAGEMENT} exact component={AddClientEngagement} />
                <Route path={EDIT_CLIENT_ENGAGEMENT} exact component={AddClientEngagement} />

                <Route path={RECRUITER_VERIFICATION_DASHBOARD} exact component={RecruiterVerificationDashboard} />
                <Route path={SEEKER_NOTIFICATION_EMAIL_SETTINGS} exact component={SeekerEmailNotificationSettings} />
                <Route path={JOB_HISTORY} exact component={JobHistory} />
                <Route path={DIVERSITY_PLAN_ADD} exact component={DiversityPlanAdd} />
                <Route path={DIVERSITY_PLAN_LIST} exact component={DiversityPlanList} />
                <Route path={FOLLOW_UP_VIEW} exact component={FollowUpView} />

                <Route path={USERS_ANALYSIS} exact component={UsersAnalysis} />
                <Route path={JOBS_ANALYSIS} exact component={JobAnalysis} />
                <Route path={FUNNEL_ANALYSIS} exact component={FunnelAnalysis} />

                <Route path={FOLLOWER_REPORT} exact component={FollowerReport} />
                
                <Route path={ SEARCH_MAIL_REPORT } exact component={ SearchMailReport } /> 
                <Route path={SEARCH_QUERY_REPORT} exact component={SearchQueryReport} />
                <Route path={ SEARCH_FLAGGED_USERS } exact component={ SearchFlaggedUsers } /> 
                <Route path={PREMIUM_JOB_LISTING} exact component={PremiumJobListing} />
                <Route path={PREMIUM_JOB_DETAIL} exact component={PremiumJobDetail} />
                <Route path={COUNT_SEARCHED_KEYWORDS} exact component={CountSearchedKeywords} />
                <Route path={REC_FOLLOW_UP_REPORT} exact component={FollowUpReport} />
                <Route path={ LEARNING_SUBSCRIPTION } exact component={ LearnAndGrowManagement } /> 
                <Route path={PRO_LEAD_GEN_REPORT} exact component={ProLeadGenReport} />
                <Route path={ USER_APPLY_JOBS } exact component={ UserAppliedJobs } /> 
                <Route path={ TAGS_ANALYSIS } exact component={ TagsAnalysis } /> 

                <Route path={PAYMENT_SYNC_DASHBOARD} exact component={PaymentSyncDashboard} />
               
                <Route path={ CONFIDENTIAL_JOBS } exact component={ ConfidentialJobsList } /> 

                <Route path={ APPLY_REFERENCE_ANALYSIS} exact component={ ApplyReferenceAnalysis } />

                <Route path={ REPORTS } exact component={ ReportList } />
                <Route path={ ACTIVITY_DETAILS } exact component={ RecruiterActivityList } />

                <Route path={ XML_FEED_EXCLUDED_USERS} exact component={ XMLFeedExcludedUsers } />

                <Route path={ SEEKER_VERIFICATION_LIST} exact component={ UnverifiedUserList } />
                <Route path={CHANGE_SEEKER_PASSWORD} exact component={ChangeSeekerPassword} /> 
                <Route path={ALL_TAG_COUNT} exact component={AllTagsCount} />
                <Route path={ANY_JOBSEEKER_LOGIN} exact component={AnyJobseekerLogin} />

                <Route path={COURSE_LIST} exact component={CourseList} />
                <Route path={ADD_EDIT_COURSE} exact component={AddEdit} />
                <Route path={COURSE_MAPPING_LIST} exact component={CourseMappingList} />
                <Route path={COURSE_ENQUIRY} exact component={CourseEnquiry} />
                <Route path={COURSE_VIEW_LEADS} exact component={CourseViewLeads} />
                <Route path={CHANGE_COURSE_RECRUITER} exact component={ChangeCourseRecruiter} />
                <Route path={MAPPING_ADD_EDIT} exact component={MappingAddEdit} />
                <Route path={ATS_PANEL} exact component={ATSPanel} />
                <Route path={ATS_PANEL_RECS} exact component={ATSPanelRecs} />
                <Route path={ATS_PANEL_ALL_RECS} exact component={ATSPanelRecs} />

                <Route path={TRANSACTION_UPLOAD} exact component={UploadTransactions} />

                <Route path={COMPETITION_DASHBOARD} exact component={CompetitionDashboard} />
                <Route path={POTENTIAL_PAID_JOBS} exact component={PotentialPaidJobs} />
                {/* <Route path={PRO_MEMBERSHIP_INVOICE} exact component={ProMembershipInvoice} /> */}
                <Route path={PRO_MEMBERSHIP_INVOICE_CN} exact component={ProMembershipInvoiceCN} />
                <Route path={LG_BILL} exact component={LGBillingContainer.LGBillingContainer} />
                <Route path={LG_BILL_EDIT} exact component={LGBillingContainer.LGBillingContainer} />
                <Route path={LG_BILLS} exact component={LGBillingContainer.LGBillsContainer} />
                <Route path={LG_COLLECTION} exact component={LGCollectionContainer.LGCollectionContainer} />
                <Route path={LG_COLLECTION_EDIT} exact component={LGCollectionContainer.LGCollectionContainer} />
                <Route path={LG_COLLECTIONS} exact component={LGCollectionContainer.LGCollectionsListContainer} />
                <Route path={LG_BILLING_TARGET} exact component={LGBillTargetContainer.LGBillTargetContainer} />
                <Route path={LG_BILLING_TARGET_EDIT} exact component={LGBillTargetContainer.LGBillTargetContainer} />
                <Route path={LG_BILLING_TARGETS} exact component={LGBillTargetContainer.LGBillTargetsListContainer} />
                <Route path={LG_COLLECTION_TARGET} exact component={LGCollectionTargetContainer.LGCollectionTargetContainer} />
                <Route path={LG_COLLECTION_TARGET_EDIT} exact component={LGCollectionTargetContainer.LGCollectionTargetContainer} />
                <Route path={LG_COLLECTION_TARGETS} exact component={LGCollectionTargetContainer.LGCollectionTargetsListContainer} />
                <Route path={JOB_VISIBILITY} exact component={JobVisibility} />

                <Route path={VALUE_DELIVERY_JOBS} exact component={ValueDeliveryJobs} />

                <Route path={COMPANY_ACQUISITION} component={CompanyAcquisition} />
                <Route path={JOB_ACQUISITION} component={JobAcquisition} />

            </Switch>
        </div>
    </Router>
);