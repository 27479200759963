import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { toast, ToastContainer } from 'react-toastify';
import { Search, Tab, Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import InputBox from '../../components/InputBox';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';

import user from '../../store/user';
import getPaymentSyncRecords from '../../services/getPaymentSyncRecords';
import getPaymentSyncCount from '../../services/getPaymentSyncCount';
import updatePaymentSyncRecord from '../../services/updatePaymentSyncRecord';
import errorCodes from '../../models/naukriSyncErrorCodes';

import { PAYMENT_SYNC_DASHBOARD } from '../../models/routes';
import { getQueryParams } from '../../utils/utilFunctions';

import './styles.scss';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

const FILTER_OPTIONS = [
    {
        id: -1,
        name: 'All'
    },
    {
        id: '1',
        name: 'Sync Successful'
    },
    {
        id: '2,3,4,5,6,7,8,9,10,11,12,13,14,15,16',
        name: 'Sync Failed'
    }
];

const CATEGORIES = [
    {
        id: 'payload_registration',
        label: 'Naukri Customer Insert - Registration'
    },
    {
        id: 'payload_modify',
        label: 'Naukri Customer Modify'
    },
    {
        id: 'payload_transaction',
        label: 'Naukri Transaction'
    },
    {
        id: 'payload_payment',
        label: 'Naukri Payment'
    },
    {
        id: 'payload_mgr_transaction',
        label: 'Manager Dashboard Transaction'
    },
    {
        id: 'payload_mgr_assign',
        label: 'Manager Dashboard Assign'
    }
];

class PaymentSyncDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminId: null,
            pageNumber: 1,
            pageContent: 50,
            records: null,
            isPageLoading: false,
            isLoading: false,
            total: 0,
            type: -1,
            searchValue: '',
            search: '',
            orderId: null,
            actionsAccess: false,
        }
    }

    checkIfNumber = (value) => {
        return !isNaN(value) && !isNaN(parseFloat(value));
    }

    async componentDidMount() {
        const profile = await user.getProfile();

        const params = getQueryParams(this.props.location.search);

        const actionsAccess = user.checkFeatureAccess(adminFeatureMapping.NAUKRI_CRM_SYNC_ACTIONS);

        let pageNumber = 1;

        if (params.pg && !isNaN(params.pg) && !isNaN(parseFloat(params.pg))) {
            pageNumber = params.pg;
        }

        if (params.orderId) {
            this.setState({
                adminId: profile.id,
                orderId: params.orderId,
                search: params.orderId,
                searchValue: params.orderId,
                pageNumber,
                actionsAccess
            }, function () {
                this.getIntegrationRecords();
            })
        } else {
            this.setState({
                adminId: profile.id,
                pageNumber,
                actionsAccess
            }, function () {
                this.getIntegrationRecords();
                this.getIntegrationRecordsCount();
            });
        }
        
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location !== prevProps.location) {

            let orderId = null;
            let pageNumber = 1;

            if (this.props.location.search) {
                const params = getQueryParams(this.props.location.search);

                if (params.orderId && this.checkIfNumber(params.orderId)) {
                    orderId = params.orderId;
                }

                if (params.pg && this.checkIfNumber(params.pg)) {
                    pageNumber = params.pg;
                }
            }

            this.setState({
                orderId,
                search: orderId,
                pageNumber,
            }, function () {
                this.getIntegrationRecords();
            })
        }
    }

    handleDropdownChange = (event) => {
        const newType = event.target.value;

        this.setState({
            type: newType,
            isLoading: true,
            pageNumber: 1,
        }, function () {
            this.getIntegrationRecords()
        });
    }

    handleSearchSubmit = () => {
        this.setState({
            isLoading: true,
            search: this.state.searchValue,
        }, function () {
            this.getIntegrationRecords();
            this.getIntegrationRecordsCount()
        });

    }

    handleSearchReset = async () => {
        this.setState({
            pageNumber: 1,
            type: -1,
            search: '',
            orderId: null,
            searchValue: '',
        }, function () {
            this.handleQueryUpdate();
            this.getIntegrationRecords();
            this.getIntegrationRecordsCount();
        });
    }

    handlePageChange = (page) => {
        this.setState({
            pageNumber: page,
            isPageLoading: true,
        }, function () {
            this.handleQueryUpdate();
            this.getIntegrationRecords();
        });

    }

    handleSearchChange = event => {
        this.setState({
            searchValue: event.target.value,
        });
    }

    handleSearchKeyPress = event => {
        if (event && event.key === 'Enter') {
            this.handleSearchSubmit();
        }
    }

    handleSyncReset = async orderId => {
        try {
            await updatePaymentSyncRecord(this.state.adminId, {
                orderId,
                retries: 0
            });

            let records = this.state.records;
            records = records.map(record => {
                if (record.order_id == orderId) {
                    return {
                        ...record,
                        retries: 0,
                    }
                }
                return record;
            });

            this.setState({
                records,
            });

            toast.success('Order has been reset, a sync will be tried in the next iteration');
        } catch (error) {
            toast.error('Something went wrong, Please try again later!');
        }
    }

    handleQueryUpdate = () => {
        const arr = [];

        arr.push(`pg=${this.state.pageNumber}`);

        if (this.state.orderId) {
            arr.push(`orderId=${this.state.orderId}`);
        }

        this.props.history.push(`${PAYMENT_SYNC_DASHBOARD}?${arr.join('&')}`);
    }

    onOrderClick = orderId => {

        this.setState({
            isLoading: true,
            records: [],
            orderId: orderId,
            searchValue: orderId,
            search: orderId,
        }, function () {
            this.props.history.push(`${PAYMENT_SYNC_DASHBOARD}?orderId=${orderId}`);
        });

    }

    decodeHtml = (html) => {
        try {
            const dummyEle = document.createElement('textarea');
            dummyEle.innerHTML = html;
            return dummyEle.value;
        } catch (error) {
            return '';
        }
    }

    getIntegrationRecords = async () => {
        try {
            this.setState({
                isPageLoading: true
            });

            const response = await getPaymentSyncRecords(this.state.adminId, {
                pageNumber: this.state.pageNumber,
                pageContent: this.state.pageContent,
                type: this.state.type == -1 ? null : this.state.type, 
                orderId: this.state.search ? this.state.search : null,
            });

            this.setState({
                records: response.data.data,   
                isLoading: false,
            });
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later!');
        }


        this.setState({
            isPageLoading: false
        });
    }

    getIntegrationRecordsCount = async (params) => {
        try {
            const response = await getPaymentSyncCount(this.state.adminId, {
                type: this.state.type == -1 ? null : this.state.type,
                orderId: this.state.search ? this.state.search : null,
            });

            this.setState({
                total: response.data.data.count,
            });

        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later!');
        }
    }

    getTableHeading = () => {
        return (
            <Table.Header>
                <Table.HeaderCell width="one">Order ID</Table.HeaderCell>
                <Table.HeaderCell width="one">Recruiter ID</Table.HeaderCell>
                <Table.HeaderCell width="one">Created At</Table.HeaderCell>
                <Table.HeaderCell width="one">Updated At</Table.HeaderCell>
                <Table.HeaderCell width="one">Status</Table.HeaderCell>
                <Table.HeaderCell width="two">Reason</Table.HeaderCell>
                <Table.HeaderCell width="one">Number Of Retries</Table.HeaderCell>
                <Table.HeaderCell width="one">Actions</Table.HeaderCell>
            </Table.Header>
        );
    }

    getSearchContent = () => {
        return (
            <div className='search__container'>
                <InputBox
                    className="search__container--input"
                    placeholder="Search with Order ID"
                    onChange={this.handleSearchChange.bind(this)}
                    onKeyPress={this.handleSearchKeyPress.bind(this)}
                    value={this.state.searchValue}
                />
                <Dropdown
                    className="search__container--dropdown"
                    data={FILTER_OPTIONS}
                    dropdownChange={this.handleDropdownChange}
                />
                <div className="search__container--action" >
                    <Button text="Search" skin="dark" clickButton={this.handleSearchSubmit}/>
                    <Button text="Reset" skin="light" clickButton={this.handleSearchReset}/>
                    {this.state.orderId ? <Button text="Back" skin="dark" clickButton={this.props.history.goBack}/> : null}
                </div>
            </div>
        );
    }

    getMainContent = () => {
        const { isLoading, isPageLoading, records, orderId } = this.state;

        if (isLoading || isPageLoading || records == null) {
            return (
                <>
                    <Table>
                        {this.getTableHeading()}
                    </Table>
                    <Loader />
                </>
            );
        } else if (!isLoading && !isPageLoading && (records && records.length === 0)) {
            return (
                <>
                    <div>No Records Found</div>
                </>
            );
        } 
        else if (orderId) {
            return (
                <>
                    <Table striped>
                        {this.getTableHeading()}
                        <Table.Body>
                            {records.map(item => {
                                return (
                                    <Table.Row className="table-item-row"  verticalAlign="top">
                                        <Table.Cell width="one">{item.order_id}</Table.Cell>
                                        <Table.Cell width="one">{item.recruiterId}</Table.Cell>
                                        <Table.Cell width="one">{item.created_at ? moment(item.created_at).format('YYYY-MM-DD') : ''}</Table.Cell>
                                        <Table.Cell width="one">{item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD') : ''}</Table.Cell>
                                        <Table.Cell width="one">{item.status}</Table.Cell>
                                        <Table.Cell width="two">{errorCodes[item.status]}</Table.Cell>
                                        <Table.Cell width="one">{item.retries}</Table.Cell>
                                        <Table.Cell width="one">
                                            {this.state.actionsAccess ? <Button text="Reset Retries" skin="dark" clickButton={() => {
                                                this.handleSyncReset(item.order_id);
                                            }}/> : null}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>

                    <div className='table-head'>PAYLOADS</div>

                    {CATEGORIES.map(category => {
                        if (records[0][category.id]) {
                            const value = ['payload_registration', 'payload_modify', 'payload_transaction', 'payload_payment'].includes(category.id) ? this.decodeHtml(records[0][category.id]) : records[0][category.id];

                            return (
                                <>
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell>{category.label}</Table.HeaderCell>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {value}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </>
                            )
                        }
                    })}
                </>
            )
        } else {
            return (
                <Table striped>
                    {this.getTableHeading()}
                    <Table.Body>
                        {records.map(item => {
                            return (
                                <Table.Row className="table-item-row"  verticalAlign="top" key={item.order_id}>
                                    <Table.Cell width="one">{item.order_id}</Table.Cell>
                                        <Table.Cell width="one">{item.recruiterId}</Table.Cell>
                                    <Table.Cell width="one">{item.created_at ? moment(item.created_at).format('YYYY-MM-DD') : ''}</Table.Cell>
                                    <Table.Cell width="one">{item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD') : ''}</Table.Cell>
                                    <Table.Cell width="one">{item.status}</Table.Cell>
                                    <Table.Cell width="two">{errorCodes[item.status]}</Table.Cell>
                                    <Table.Cell width="one">{item.retries}</Table.Cell>
                                    <Table.Cell width="one">
                                        <div className="action-container">
                                            <div onClick={() => this.onOrderClick(item.order_id)}>Check Payloads</div>
                                            {this.state.actionsAccess ? <Button text="Reset Retries" skin="dark" clickButton={() => {
                                                this.handleSyncReset(item.order_id);
                                            }}/> : null}
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )
        }
    }

    getPagination = () => {
        let topPageIndicators = null, bottomPageIndicators = null;

        const { orderId, isLoading, records, total, pageNumber, pageContent } = this.state;

        if (orderId) {
            return {};
        }

        if (!isLoading && records && records.length > 0) {
            topPageIndicators = (
                <div className="page-controller pagination-top">
                    <div className="row text-center">
                        <Pagination totalItemsCount={total} content={pageContent} pageNumber={pageNumber} handlePageChange={this.handlePageChange} />
                        <CountDisplay start={(pageNumber - 1) * pageContent + 1} end={pageNumber * pageContent} total={total} />
                    </div>
                </div>
            );
    
            bottomPageIndicators = (
                <div className="page-controller row text-center pagination-bottom">
                    <Pagination totalItemsCount={total} pageNumber={pageNumber} content={pageContent} handlePageChange={this.handlePageChange} />
                </div>
            )
        } else {
            topPageIndicators = <div></div>;
            bottomPageIndicators = <div></div>;
        }

        return { 
            topPageIndicators,
            bottomPageIndicators
        };
    }

    render() {
        const { topPageIndicators, bottomPageIndicators } = this.getPagination();

        return (
            <div className="page-content payment-sync-dashboard">
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover 
                />

                <div className='page-heading'>
                    <Heading text="Recruiter Payment Sync with Naukri" />
                </div>

                {this.getSearchContent()}

                {topPageIndicators}

                <div className='row'>
                    {this.getMainContent()}
                </div>

                {bottomPageIndicators}
            </div>
        );
    }
}

export default PaymentSyncDashboard;