import axios from '../utils/axios';

export default function getJobsMetaData(recId, params) {
    return (
        axios.get('/admin/' + recId + '/recruiters/get-meta',{ params })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
