import React from "react";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import Dropdown from "../../components/Dropdown";
import InputBox from "../../components/InputBoxWithoutCheck";

import getFeedTypes from "../../services/getXMLFeedTypes";
import getFeedExcludedUsers from "../../services/getXMLFeedExcludedUsers";
import addXMLFeedExcludedUsers from "../../services/addXMLFeedExcludedUsers";
import updateXMLFeedExcludedUsers from "../../services/updateXMLFeedExcludedUsers";

class XMLFeedExcludedUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedType: 1,
            feedTypes: [],
            value: "",
            users: [],
            addAPICall: false
        }
    }

    async componentDidMount() {
        const profile = await user.getProfile();
        const adminId = profile.id;

        const resp = await getFeedTypes(adminId);
        this.setState({
            feedTypes: resp.data.data,
            adminId
        }, () => {
            this.getUsers();
        });
    }

    async getUsers() {
        const { feedType, adminId } = this.state;

        const resp = await getFeedExcludedUsers(adminId, feedType);
        this.setState({
            users: resp.data.data
        });
    }

    onChange(key, ev) {
        const data = {
            [key]: ev.target.value
        };
        this.setState(data, () => {
            if (key == "feedType") this.getUsers();
        });
    }

    async submit() {
        try {

            const { adminId, feedType, value = "", users } = this.state;

            if (!value.trim()) return;
            this.setState({
                addAPICall: true
            });

            const data = [];

            value.split("\n").forEach(i => {
                i.split(",").forEach(j => {
                    data.push({
                        feedType,
                        value: j
                    });
                })
            })

            const resp = await addXMLFeedExcludedUsers(adminId, { data });
            this.setState({
                addAPICall: false,
                users: resp.data.data.concat(users),
                value: ""
            });
            console.log(resp);

        } catch (err) {
            console.log(err);
            this.setState({
                addAPICall: false
            });
        }
    }

    async toggle(id) {
        try {

            const { adminId, users } = this.state;
            let status = null;
            const updatedUsers = users.map(i => {
                if (i.id == id) {
                    status = i.status ? 0 : 1;
                    return {
                        ...i,
                        status
                    };
                }
                return i;
            });

            const resp = await updateXMLFeedExcludedUsers(adminId, id, status);
            this.setState({
                // addAPICall: false,
                users: updatedUsers
            });
            console.log(resp);

        } catch (err) {
            console.log(err);
            this.setState({
                addAPICall: false
            });
        }
    }

    render() {
        const { addAPICall } = this.state;

        return (
            <div className="xml-feed-excluded-users">
                <Heading text='XML Feed Excluded Users' />

                <div className="feed-type">
                    <Dropdown
                        dropdownChange={this.onChange.bind(this, "feedType")}
                        defaultValue={this.state.feedType}
                        data={this.state.feedTypes} />
                </div>

                <div className="add-form right">
                    <textarea
                        // type='text'
                        value={this.state.value}
                        placeholder='Enter Recruiter Ids or Domains one in each line or comma separated'
                        onChange={this.onChange.bind(this, "value")} />
                    <Button
                        disabled={addAPICall}
                        loading={addAPICall}
                        skin='dark'
                        text="Add"
                        clickButton={this.submit.bind(this)} />
                </div>
                <div className="users">
                    <Table fixed selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Recruiter Id</Table.HeaderCell>
                                <Table.HeaderCell>Domain</Table.HeaderCell>
                                <Table.HeaderCell >Status</Table.HeaderCell>
                                <Table.HeaderCell >Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                this.state.users.map((item, i) => {
                                    return (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.recruiterId}</Table.Cell>
                                            <Table.Cell>{item.domain}</Table.Cell>
                                            <Table.Cell>{item.status ? 'Active' : 'InActive'}</Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    skin='dark'
                                                    disabled={false}
                                                    loading={false}
                                                    text={item.status ? 'Inactivate' : 'Activate'}
                                                    clickButton={this.toggle.bind(this, item.id)} />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>

                </div>
            </div>
        )
    }
}

export default XMLFeedExcludedUsers;