import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Tab, Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';

import user from '../../store/user';
import getRecruiterFollowUpReport from '../../services/getRecruiterFollowUpReport';

import './styles.scss';

const FollowUpReport = props => {
    const [adminId, setAdminId] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const getTableItem = (aRow) => {
        return (
            <Table.Row>
                <Table.Cell>{aRow.date}</Table.Cell>
                <Table.Cell>{aRow.used ? aRow.used : 0}</Table.Cell>
                <Table.Cell>{aRow.totalActions ? aRow.totalActions : 0}</Table.Cell>
                <Table.Cell>{aRow.shortlist ? aRow.shortlist : 0}</Table.Cell>
                <Table.Cell>{aRow.reject ? aRow.reject : 0}</Table.Cell>
                <Table.Cell>{aRow.saved ? aRow.saved : 0}</Table.Cell>
                <Table.Cell>{aRow.view ? aRow.view : 0}</Table.Cell>
                <Table.Cell>{aRow.download ? aRow.download : 0}</Table.Cell>
            </Table.Row>
        )
    }

    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        getProfile().then(data => {
            setAdminId(data.id);
        });
    }, []);

    useEffect(() => {
        if (adminId) {
            getRecruiterFollowUpReport(adminId)
            .then(response => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch(err => {
                setError(true);
                setIsLoading(false);
            })
        }
    }, [adminId]);

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Follow-up Used</Table.HeaderCell>
                <Table.HeaderCell>Recruiter Actions</Table.HeaderCell>
                <Table.HeaderCell>Shortlist</Table.HeaderCell>
                <Table.HeaderCell>Reject</Table.HeaderCell>
                <Table.HeaderCell>Saved</Table.HeaderCell>
                <Table.HeaderCell>View</Table.HeaderCell>
                <Table.HeaderCell>Download</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    let mainContent = null;
    if (isLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        )
    }
    else if (!isLoading && !data) {
        mainContent = (
            <>
                <div>Could not fetch data</div>
            </>
        )
    } else {
        mainContent = (
            <>
                <Table striped>
                    {tableHeading}
                    <Table.Body>
                        {data.daily && data.daily.map(aRow => {
                            return getTableItem(aRow)
                        })}
                        {data.monthly && data.monthly.map(aRow => {
                            return getTableItem(aRow)
                        })}
                        <Table.Row>
                            <Table.Cell>Total</Table.Cell>
                            <Table.Cell>{data.total.totalUsed}</Table.Cell>
                            <Table.Cell>{data.total.totalActions}</Table.Cell>
                            <Table.Cell>{data.total.totalShortlist}</Table.Cell>
                            <Table.Cell>{data.total.totalReject}</Table.Cell>
                            <Table.Cell>{data.total.totalSaved}</Table.Cell>
                            <Table.Cell>{data.total.totalView}</Table.Cell>
                            <Table.Cell>{data.total.totalDownload}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </>
        )
    }

    return (
        <div className='page-content'>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />

            <div className='page-heading'>
                <Heading text="Follow Up Report - Jobseeker Follow Up" />
            </div>

            <div className='row'>
                {mainContent}
            </div>
        </div>
    );
};

export default FollowUpReport;