import React, { useEffect, useRef, useState } from 'react';

import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import { Icon } from 'semantic-ui-react'

import Heading from '../../components/Heading';
import InputBox from '../../components/InputBox';
import DropDown from '../../components/Dropdown';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import CustomDropdown from '../RecruiterVerificationDashboard/components/CustomDropdown';
import Spinner from '../../components/Spinner';

import { locationListLabel } from '../../models/locationSeeker';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

import user from '../../store/user';
import fetchRecruiterProfile from '../../services/fetchRecruiterProfile.js';
import updateRecruiterProfile from '../../services/updateRecruiterProfile';
import disconnectRecruiterSocial from '../../services/disconnectRecruiterSocial';
import getUnverifiedCompanies from '../../services/getAllCompanies';
import getCompanyManagement from '../../services/getCompanyManagement';
import postProfileImgFromLink from '../../services/postProfileImgFromLink';

import './EditProfile.scss';

const EditProfile = (props) => {
    const params = new URLSearchParams(props.location.search);

    const [recruiterId, setRecruiterId] = useState(params.get('recid') || null);
    const [recruiterEmail, setRecruiterEmail] = useState(params.get('email') || null);

    // indicates if the current page is recruiter/recruiter_incomplete_edit link
    const [isIncomplete, setIsIncomplete] = useState(false);
    const [editCompAccess, setEditCompAccess] = useState(false);

    const [adminId, setAdminId] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imgUploadLoading, setImgUploadLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [companyData, setCompanyData] = useState({
        companyList: [],
        isLoading: false,
        searchText: null
    });
    const [isGmailType, setIsGmailType] = useState(false);
    const [isGmailTypeVerified, setIsGmailTypeVerified] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const nameRef = useRef(null)
    const orgRef = useRef(null)
    const desigRef = useRef(null)
    const urlRef = useRef(null);

    const [data, setData] = useState({
        name: '',
        organisation: '',
        designation: '',
        about: '',
        phone: '',
        url: '',
        location: -1,
        linkedin: false,
        twitter: false,
        isLinkedinDisconnecting: false,
        isTwitterDisconnecting: false,
        companyId: null
    });

    const [errorData, setErrorData] = useState({
        name: false,
        organisation: false,
        designation: false,
        url: false,
    });

    const setFieldError = (key, value) => {
        setErrorData(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;  
            return newObj;
        });
    };

    const setEnteredData = (key, value) => {
        setData(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        });
    };

    useEffect(() => {

        try {
            const pathname = props.location.pathname;
            if (pathname.includes('incomplete')) {
                setIsIncomplete(true);
            } else {
                setIsIncomplete(false);
            }
        } catch (error) {
            setIsIncomplete(false);
        }

        async function getProfile() {
            const profile = await user.getProfile();
            setAdminId(profile.id)
            return profile.id;
        }

        async function getRecruiterData(adminId, id, email) {
            const response = await fetchRecruiterProfile(adminId, { id: id, email: email, getAllData: true })
            return response;
        }

        getProfile().then(async (adminId) => {
            const companyEditAccess = user.checkFeatureAccess(adminFeatureMapping.EDIT_RECRUITER_COMPANY);

            setEditCompAccess(companyEditAccess);

            const response = await getRecruiterData(adminId, recruiterId, recruiterEmail)

            if (response.data) {
                setEnteredData('name', response.data.name);
                setEnteredData('organisation', response.data.organisation);
                setEnteredData('designation', response.data.designation);
                setEnteredData('phone', response.data.phone);
                setEnteredData('about', response.data.about);
                setEnteredData('location', response.data.location);
                setEnteredData('linkedin', (response.data.linkedin || 0) == 1);
                setEnteredData('twitter', (response.data.twitter || 0) == 1);

                if (companyEditAccess) {
                    setEnteredData('companyId', response.data.companyId);
                    if (response.data.companyId) {
                        const companyDataResponse = await getCompanyManagement(adminId, {
                            id: response.data.companyId
                        });
    
                        if (companyDataResponse 
                            && companyDataResponse.data 
                            && companyDataResponse.data.data 
                            && companyDataResponse.data.data.companies
                            && companyDataResponse.data.data.companies.length > 0) {
                            setCompanyName(companyDataResponse.data.data.companies[0].name);
                        }
                    }
                }

                setRecruiterId(response.data.id);
                setRecruiterEmail(response.data.email);
                setProfileData(response.data)

                setIsGmailType(response.data.isGmailType || false);
                setIsGmailTypeVerified(response.data.isVerified || false);

            } else {
                setProfileData(null)
            }
            setIsLoading(false)
        });

    }, [])

    // location
    const locationList = locationListLabel.map((item) => {
        return {
            id: item.value,
            name: item.label
        }
    })
    locationList.unshift({ id: -1, name: 'Select' })

    const imageLink = (profileData && profileData['img_link']) || null;

    // on form submit
    const formSubmitHandler = async (event) => {
        event.preventDefault();

        if (!data.name && data.name.length == 0) {
            setFieldError('name', true);
            nameRef.current.scrollIntoView()
            return
        }

        if (!data.organisation && data.organisation.length == 0) {
            setFieldError('organisation', true);
            orgRef.current.scrollIntoView()
            return
        }

        if (!data.designation && data.designation.length == 0) {
            setFieldError('designation', true);
            desigRef.current.scrollIntoView()
            return
        }

        const formData = new FormData();

        // if file has been uploaded, simply append
        if (uploadedFile) {
            formData.append('image', uploadedFile)
        } else if (data.url && isIncomplete ) {

            // else if url has been entered, pull image from internet and append
            setImgUploadLoading(true);
            try {
                const filename = moment().format('YYYY-MM-DD-HH-MM-SS')+'-'+recruiterId+'.jpg';
                const imgUploadResponse = await postProfileImgFromLink(adminId, {
                    filename,
                    pictureUrl: data.url
                });
                
                const imageName = imgUploadResponse.data.data.imageName;

                formData.append('s3ImageName', imageName);
                formData.append('pictureUrl', data.url);
            } catch (error) {
                setErrorData('url', true);
                urlRef.current.scrollIntoView();
                setImgUploadLoading(false);
                return;
            }
            setImgUploadLoading(false);
        }
        formData.append('fromAdmin', true);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        formData.append('organisation', data.organisation);
        formData.append('designation', data.designation);
        formData.append('location', data.location);
        formData.append('about', data.about);

        if (editCompAccess && data.companyId) {
            formData.append('companyId', data.companyId);
        }

        const response = await updateRecruiterProfile(adminId, recruiterId, formData)
        window.scrollTo(0, 0)

        setIsLoading(false)
        if (response.status == 200) {
            toast.success('Successfully updated!');
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            toast.error('Something went wrong. Please try again!')
        }
    }

    const nameChangeHandler = (e) => {
        const name = e.target.value;
        setEnteredData('name', name);
        if (name && name.length > 0) {
            setFieldError('name', false);
        }
    }

    const orgChangeHandler = (e) => {
        const org = e.target.value;
        setEnteredData('organisation', org);
        if (org && org.length > 0) {
            setFieldError('organisation', false);
        }
    }
    const desigChangeHandler = (e) => {
        const desig = e.target.value;
        setEnteredData('designation', desig);
        if (desig && desig.length > 0) {
            setFieldError('designation', false);
        }
    }
    const phoneChangeHandler = (e) => {
        const phone = e.target.value;
        setEnteredData('phone', phone);

    }
    const aboutChangeHandler = (e) => {
        const about = e.target.value;
        setEnteredData('about', about);
    }

    const locationChangeHanlder = (e) => {
        setEnteredData('location', e.target.value);
    }

    const onFileChangeHandler = (e) => {
        setUploadedFile(e.target.files[0])
    };

    const urlChangeHandler = (e) => {
        setFieldError('url', false);
        setEnteredData('url', e.target.value);
    }

    const disconnectHandler = async (type) => {
        if (type == 'linkedin') {
            setEnteredData('isLinkedinDisconnecting', true);
        } else {
            setEnteredData('isTwitterDisconnecting', true);
        }
        const result = await disconnectRecruiterSocial(adminId, recruiterId, type)
        if (result) {
            if (type == 'linkedin') {
                setEnteredData('linkedin', false);
            } else {
                setEnteredData('twitter', false);
            }
        } else {
            if (type == 'linkedin') {
                setEnteredData('isLinkedinDisconnecting', false);
            } else {
                setEnteredData('isTwitterDisconnecting', false);
            }
        }
    };

    const onSearchChangeHandler = text => {
        setCompanyData(prevObj => {
            const newObj = { ...prevObj, searchText : text };

            if (!text || (text && text.length === 0)) {
                newObj['companyList'] = [];
                newObj['isLoading'] = false;
            }

            return newObj;
        });
    };

    const handleClearList = () => {
        setCompanyData(prevObj => {
            return {
                ...prevObj,
                companyList: [],
                isLoading: false,
            };
        });
    };

    const getCompanies = async () => {
        if (!editCompAccess) return;

        setCompanyData(prevObj => {
            return {
                ...prevObj,
                isLoading: true
            }
        });
        try {
            const response = await getUnverifiedCompanies(adminId, {
                displayNameLike: companyData.searchText,
                nameSort: 1,
                pageNumber: 1,
                pageContent: 500,
                status: 1,
                targetDomain: process.env.REACT_APP_PLATFORM
            });
            
            if (response.status == 200) {
                setCompanyData(prevObj => {                    
                    return {
                        ...prevObj,
                        companyList: response.data.data.data,
                        isLoading: false
                    };
                });
            }
        } catch (error){
            setCompanyData(prevObj => {
                return {
                    ...prevObj,
                    isLoading: false,
                }
            });
            // console.log(error);
        }
    };

    // fetch company after every 300 ms when search text changes
    useEffect(() => {
        let timerID = null;
        
        try {
            timerID = setTimeout(() => {
                if (!companyData.isLoading && companyData.searchText && companyData.searchText.length >= 2) {
                    getCompanies();
                }
            }, 300);
        } catch (error) {
            console.log(error);
        }

        return () => {
            if (timerID) {
                clearTimeout(timerID);
            }
        }
    }, [companyData.searchText]);

    let content;
    if (isLoading) {
        content = (
            <Loader />
        )
    } else if (!isLoading && !profileData) {
        content = (
            <div>
                <p>Error! Could not find profile</p>
            </div>
        )
    } else {
        content = (
            <>
                <div className="container__body">
                    <form className="grid__container" onSubmit={formSubmitHandler} >
                        <div ref={nameRef} className="grid__container--row">
                            <label>Name*</label>
                            <div>
                                <InputBox type="text" name="name" value={data.name} onChange={nameChangeHandler} />
                                {errorData.name && <p className="error-text">Name cannot be empty</p>}
                            </div>

                        </div>
                        <div className="grid__container--row">
                            <label>Email</label>
                            <p>{isIncomplete ? profileData['email'] : '---'}</p>
                        </div>
                        <div ref={orgRef} className="grid__container--row">
                            <label>Organisation*</label>
                            <div>
                                <InputBox type="text" name="organisation" value={data.organisation} onChange={orgChangeHandler} />
                                {errorData.organisation && <p className="error-text">Organisation cannot be empty</p>}
                            </div>
                        </div>
                        {
                            (isGmailType && !isGmailTypeVerified) || (!editCompAccess || isIncomplete) ? null : (
                                <div ref={orgRef} className="grid__container--row">
                                    <label>Company Details</label>
                                    <div>
                                        <CustomDropdown
                                            className="company_dropdown"
                                            dropdownList={companyData.companyList.map(item => {
                                                return {
                                                    label: item.displayName,
                                                    value: item.companyId,
                                                }
                                            })}
                                            isLoading={companyData.isLoading}
                                            onInputChange={onSearchChangeHandler}
                                            onItemSelected={(id, label) => {
                                                setEnteredData('companyId', id)
                                                setCompanyName(label);
                                            }}
                                            clearList={handleClearList}  
                                            isSelected={companyName ? { displayName: companyName } : null}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div ref={desigRef} className="grid__container--row">
                            <label>Designation*</label>
                            <div>
                                <InputBox type="text" name="designation" value={data.designation} onChange={desigChangeHandler} />
                                {errorData.designation && <p className="error-text">Designation cannot be empty</p>}
                            </div>

                        </div>
                        <div className="grid__container--row">
                            <label>Phone</label>
                            <InputBox type="text" name="phone" value={data.phone} onChange={phoneChangeHandler} />
                        </div>
                        <div className="grid__container--row">
                            <label>Location</label>
                            <DropDown data={locationList} name="location" defaultValue={data.location} dropdownChange={locationChangeHanlder} />
                        </div>

                        <div className="grid__container--row">
                            <label>About</label>
                            <textarea className='textbox_about' name="about" value={data.about} onChange={aboutChangeHandler} />
                        </div>

                        <div className="grid__container--row">
                            <label>LinkedIn Status</label>
                            <div className="grid__container--subrow">
                                <p>{(data.linkedin == 1) ? 'Connected' : 'Not Connected'}</p>
                                {(data.linkedin == 1) && (
                                    <div className='btn-disconnect' onClick={() => disconnectHandler('linkedin')}>
                                        {data.isLinkedinDisconnecting && <Icon loading className="spinner" />}
                                        {!data.isLinkedinDisconnecting && 'Disconnect'}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid__container--row">
                            <label>Twitter Status</label>
                            <div className="grid__container--subrow">
                                <p>{(data.twitter == 1) ? 'Connected' : 'Not Connected'}</p>
                                {(data.twitter == 1) && (
                                    <div className='btn-disconnect' onClick={() => disconnectHandler('twitter')} >
                                        {data.isTwitterDisconnecting && <Icon loading className="spinner" />}
                                        {!data.isTwitterDisconnecting && 'Disconnect'}
                                    </div>)}
                            </div>
                        </div>
                        <div className="grid__container--row">
                            <label>Picture</label>
                            {
                                imageLink ? (
                                    <img className="profile_image" src={imageLink} alt="picture" />
                                ) : (
                                    <div>No profile picture available</div>
                                )
                            }
                        </div>
                        
                        <div className="grid__container--row">
                            <label></label>
                            <InputBox type="file" name="picture_upload" onChange={onFileChangeHandler} />
                        </div>

                        {isIncomplete ? (
                            <div ref={urlRef} className="grid__container--row">
                                <label>Image URL</label>
                                <div>
                                    {imgUploadLoading ? (<Spinner />) : (
                                        <>
                                            <InputBox type="text" name="img_url" value={data.url} onChange={urlChangeHandler} />
                                            {profileData.pictureUrl ? (<div className='imgUrlLink'>{profileData.pictureUrl}</div>) : null} 
                                            {errorData.url && <p className="error-text">Provided image url is either corrupted or not supported.</p>}
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="container__body--buttons">
                            <Button skin='dark' disabled={false} text='Submit' />
                        </div>
                    </form>
                </div>
            </>
        )
    }

    return (
        <div className="page-content rec-profile-mgtedit">
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="page-heading">
                <Heading text={`Recruiter ${isIncomplete ? 'Incomplete' : 'Profile'} Edit`} />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/${isIncomplete ? 'recruiter_incomplete_edit' : 'recruiter_profile_mgtedit'}.php?${recruiterId ? `recid=${recruiterId}` : `eheck_email=${recruiterEmail}`}`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version'/>
                </a>
            </div>

            <div className="container">
                {content}
            </div>
        </div>
    )
}

export default EditProfile;
