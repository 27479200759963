import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../../../../components/Spinner';

import './styles.scss';

const InputDropdown = props => {
    const [showDropdown, setShowDropdown] = useState(false);

    const [searchValue, setSearchValue] = useState('');

    const { options = [], selectedValue = null, selectedLabel = '', isLoading = false } = props;

    const handleOnInputFocus = () => {
        setShowDropdown(true);
    };

    const handleOnInputBlur = () => {
        setShowDropdown(false);
    };

    const handleOnItemClick = (item) => {
        props.onItemSelect(item);
    };

    const handleOnChange = () => {
        props.onItemSelect({
            label: null,
            value: null,
        })
    };

    const getContent = () => {
        if (selectedValue) {
            return (
                <div className='dropdown__container--selected'>
                    <div className='selected-label'>{selectedValue} - {selectedLabel}</div>

                    <div 
                        className='btn-change'
                        onClick={handleOnChange}    
                    >Change</div>
                </div>
            )
        } else {
            return (
                <div className='dropdown__container--noselected'>
                    <input 
                        placeholder='Enter company name to start searching...'
                        onFocus={handleOnInputFocus}
                        onBlur={handleOnInputBlur}
                        onChange={(event) => {
                            props.onSearchChange(event.target.value);
                        }}
                    />
                    {showDropdown ? (
                        <div className='dropdown__list'>
                            {isLoading ? (
                                <div className='dropdown__list--loader'>
                                    <Spinner />
                                </div>
                            ) : null}

                            {!isLoading && options && options.length > 0 ? (
                                options.map(item => {
                                    return (
                                        <div 
                                            className='dropdown__list--item'
                                            onMouseDown={() => {
                                                handleOnItemClick(item)
                                            }}
                                        >{item.label}</div>
                                    )
                                })
                            ) : null}

                            {!isLoading && (options && options.length == 0) ? (
                                <div className='dropdown__list--erroritem'>No companies found.</div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            )
        }
    }

    return (
        <div className='dropdown__container'>
            {getContent()}
        </div>
    );
};

InputDropdown.propTypes = {
    options: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
    }),

    selectedLabel: PropTypes.string,
    selectedValue: PropTypes.any,

    isLoading: PropTypes.bool,

    onSearchChange: PropTypes.func,
    onItemSelect: PropTypes.func,
};

export default InputDropdown;