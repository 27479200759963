import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import Heading from '../../../components/Heading';
import FromToDatePicker from '../../../components/FromToDatepicker';
import Button from '../../../components/Button';
import ButtonContainer from '../../../components/ButtonContainer';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import { Table, Icon } from 'semantic-ui-react';
import './candidateList.scss';
import fetchResumeBuilderCandidate from '../../../services/fetchResumeBuilderCandidate';
import fetchIntegration from '../../../services/fetchIntegration';
import Dropdown from '../../../components/Dropdown/Dropdown';
import InputBox from '../../../components/InputBox';
const moment = require('moment');

const ResumeBuilderList = (props) => {
  const [loader, setLoader] = useState(false);
  const pageContent = 10;
  const statusType = [{ name:'Status',id:-1 },{ name:'Active',id:1 },{ name:'Inactive',id:0 }]
  const [parameters,setParameters] = useState({ recordsPerPage:pageContent,pageNum:1 });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(1);
  const [resumeBuilderListData, setResumeBuilderListData] = useState([]);
  const [email,setEmail] = useState('');
  

  useEffect(() => {
    async function fetchData() {
      var params = { ...parameters };
      await getFormattedData({ ...params });
    }
    fetchData();
  }, []);

  const handlePageChange = async (e) => {
    setCurrentPage(e);
    var params = { ...parameters,pageNum: e };
    setParameters(params);
    await getFormattedData({ ...params });
  };

  async function getFormattedData(params)
  {
    setLoader(true);
    var usersData = await fetchResumeBuilderCandidate({...params,activationSource:2 });
      var instituteData = await fetchIntegration({});
      var formattedUserData = usersData.users.map((eachUser) => {
        var userintegration = instituteData.data.filter((each) => {
          return each.id == eachUser.integration_type;
        });
        return {
          id:eachUser.id,
          email: eachUser.email,
          userId: eachUser.userid,
          startDate: moment(eachUser.start_time).format('DD/MM/YYYY'),
          endDate: moment(eachUser.expiration_time).format('DD/MM/YYYY'),
          status: parseInt(eachUser.status) ? 'Active' : 'Inactive',
          integration: userintegration.length ? userintegration[0].name : 'NA',
          institute: userintegration.length
            ? userintegration[0].institute
            : 'NA',
        };
      });
      setResumeBuilderListData([...formattedUserData]);
      setTotalItemsCount(usersData.totalCount);
      setLoader(false);
  }

  const searchData = async (e) => {
    setCurrentPage(1);
    var params = { ...parameters,pageNum: 1,startDateOne:moment(startDate).format('YYYY-MM-DD'),startDateTwo:moment(endDate).format('YYYY-MM-DD') };
    setParameters(params);
    await getFormattedData(params);
  };
  const clearData = async (e) => {
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1);
    var params = { ...parameters,pageNum: 1,startDateOne:null,startDateTwo:null };
    setParameters(params);
    await getFormattedData(params);
  };
  function handleEmailChange(e){
    setEmail(e.target.value);
    var params = { ...parameters,email:e.target.value };
    setParameters(params);
  }
  async function searchCandidates(){
    setCurrentPage(1);
    var params = { ...parameters,pageNum: 1,startDateOne:null,startDateTwo:null };
    setParameters(params);
    await getFormattedData(params);
  }
  async function onChangeStatusTypeButton(e){
    
    var params = { ...parameters };
    var statusDropDown = e.target.value;
    params={ ...params,status:statusDropDown,pageNum:1 };
    setCurrentPage(1);
    if(statusDropDown==-1)
    delete params.status;
    setParameters(params);
    await getFormattedData( params );
    
  }
  return (
      <div className="page-content-resume-builder">
          <div className="page-heading">
              <Heading text="Resume Builder List" />
              <div className="buttons pull-right">
                  <Button
            skin="dark"
            text="New Candidate"
            clickButton={() => {
              props.history.push('/resumeBuilder/add');
            }}
          />
              </div>
          </div>

          <div className="row">
              <ButtonContainer heading="Date:">
                  <li>
                      <div className="date-picker">
                          <div className="date">
                              <div className="col-sm-6 left valueBox">
                                  <FromToDatePicker
                    name="date"
                    startDate={startDate}
                    endDate={endDate}
                    handleChangeStart={(e) => {
                      setStartDate(e);
                    }}
                    handleChangeEnd={(e) => {
                      setEndDate(e);
                    }}
                  />
                              </div>
                          </div>
                      </div>
                  </li>
                  <li>
                      <Button
              skin="light"
              disabled={false}
              text="Search"
              clickButton={(e) => {
                searchData();
              }}
            ></Button>
                  </li>
                  <li>
                      <Button
              skin="light"
              disabled={false}
              text="Clear"
              clickButton={(e) => {
                clearData();
              }}
            ></Button>
                  </li>
              </ButtonContainer>
              <Dropdown data={statusType} dropdownChange={onChangeStatusTypeButton} className="statusResumeBUilderList" />
              <InputBox type="text" value={email} placeholder="Email" onChange={(e)=>{handleEmailChange(e)}} className="emailResumeBuilderList"/><Button skin="dark" text="Search" clickButton={(e)=>{searchCandidates()}}/>
          </div>
          
          <div className="page-row text-center">
              <Pagination
          totalItemsCount={totalItemsCount}
          content={pageContent}
          pageNumber={currentPage}
          handlePageChange={(e) => {
            handlePageChange(e);
          }}
        />
              <CountDisplay
          start={(currentPage - 1) * pageContent + 1}
          end={Math.min(currentPage * pageContent,totalItemsCount)}
          total={totalItemsCount}
        />
          </div>

          <div>
              <Table fixed selectable structured className="resume-builder-table">
                  <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell width={4}>UserId</Table.HeaderCell>
                          <Table.HeaderCell width={8}>Email</Table.HeaderCell>
                          {/* <Table.HeaderCell width={8}>Integration</Table.HeaderCell> */}
                          <Table.HeaderCell width={12}>Institute</Table.HeaderCell>
                          <Table.HeaderCell width={10}>Start Date</Table.HeaderCell>
                          <Table.HeaderCell width={10}>End Date</Table.HeaderCell>
                          <Table.HeaderCell width={4}>Status</Table.HeaderCell>
                          <Table.HeaderCell width={4}>Actions</Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {loader ? (
                          <div className="loader-container">
                              <Loader />
                          </div>
            ) : (
              resumeBuilderListData.map((item) => {
                return (
                    <Table.Row key={item.userId}>
                        <Table.Cell>{item.userId}</Table.Cell>
                        <Table.Cell className="not-capitalized">
                            {item.email}
                        </Table.Cell>
                        {/* <Table.Cell className="not-capitalized">
                            {item.integration}
                        </Table.Cell> */}
                        <Table.Cell className="not-capitalized">
                            {item.institute}
                        </Table.Cell>
                        <Table.Cell className="not-capitalized">
                            {item.startDate}
                        </Table.Cell>
                        <Table.Cell className="not-capitalized">
                            {item.endDate}
                        </Table.Cell>
                        <Table.Cell className="not-capitalized">
                            {item.status}
                        </Table.Cell>
                        <Table.Cell className="not-capitalized">
                            <Icon
                        className="status-btn editResumeCand"
                        name="pencil"
                        onClick={(e) => {
                          props.history.push(
                            `/resumeBuilder/edit/${item.id}`
                          );
                        }}
                      />
                        </Table.Cell>
                    </Table.Row>
                );
              })
            )}
                  </Table.Body>
              </Table>
          </div>
          {loader ? null : (
              <div className="page-row text-center pagination-bottom">
                  <Pagination
            totalItemsCount={totalItemsCount}
            content={pageContent}
            pageNumber={currentPage}
            handlePageChange={(e) => {
              handlePageChange(e);
            }}
          />
              </div>
      )}
      </div>
  );
};

export default ResumeBuilderList;
