import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';

import user from '../../store/user';
import getPremiumLogs from '../../services/getPremiumLogs';
import { getQueryParams } from '../../utils/utilFunctions';

import './styles.scss';

const PremiumJobDetail = props => {

    const [jobData, setJobData] = useState(null);
    const [premiumData, setPremiumData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const queryParams = getQueryParams(props.location.search);

        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile.id;
        }

        getProfile().then(adminId => {
            if (queryParams.id) {
                fetchPremiumLogs(adminId, queryParams.id);
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    const fetchPremiumLogs = async (adminId, jobId) => {
        try {
            const response = await getPremiumLogs(adminId, { jobId });
            
            setJobData(response.data.data);
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    let mainContent = null;

    if (isLoading) {
        mainContent = <Loader />;
    } else if (!isLoading && !jobData){
        mainContent = (
            <div>
                Sorry! No data found. 
            </div>
        );
    } else {
        mainContent = (
            <>
                <Heading className="heading" text={`Premium Detail: ${jobData.title} (${jobData.publishedId})`} />
                <Table celled selectable={true} className="premium_table">
                    <Table.Header>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Recruiter</Table.HeaderCell>
                        <Table.HeaderCell>User Type</Table.HeaderCell>
                    </Table.Header>

                    <Table.Body>
                        {jobData.logs.map(item => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{item.postedDate}</Table.Cell>
                                    <Table.Cell>{item.status}</Table.Cell>
                                    <Table.Cell>{item.recruiterDetail}</Table.Cell>
                                    <Table.Cell>{item.type}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </>
        );
    }

    return (
        <div className='page-content premium-job-detail'>
            <div>
                {mainContent}
            </div>
        </div>
    )
};

export default PremiumJobDetail;