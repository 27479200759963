import React, { useEffect, useState } from 'react';
import { withRouter  } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import InputBox from '../../../components/InputBoxWithoutCheck';
import fetchProductTypes from '../../../services/fetchProductTypeSeekerSubscriptions';
import createSeekerSubscription from '../../../services/createSeekerSubscription';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Button from '../../../components/Button';
import user from '../../../store/user';
import './newTransaction.scss';
import { SEEKER_SUBSCRIPTIONS } from '../../../models/routes';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import Loader from '../../../components/Loader';
import { seekerPlanActivationReasons } from '../../../models/seekerPlanActivationReasons';

const newTransaction =(props)=>{
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [products,setProducts] = useState([]);
    const [loader,setLoader]=useState(false);
    const [adminId,setAdmin]=useState();
    const [errorMsg,setErrorMsg] = useState('');
    const [productDetails,setProductDetails] = useState({});
    const [userData,setUserData] = useState({ productType:'', paymentGatewayId: '' });
    const [reason, setReason] = useState(0);

    useEffect(()=>{
        (async () => {
        const profile = await user.getProfile();
        setAdmin(profile.id);

        const hasPageAccess = user.checkFeatureAccess(adminFeatureMapping.MANAGE_SUBSCRIPTIONS_FORM);

        if (!hasPageAccess) {
            window.location.href='/notAdmin'
        }

        try{
            var prodTypes=await fetchProductTypes(profile.id);
            prodTypes=prodTypes.data ? prodTypes.data.plans : [];
            var productDetailsLoc = {};
            prodTypes = prodTypes.map((eachType)=>{
                productDetailsLoc[eachType.planCode]=eachType;
                return {
                    id:eachType.planCode,
                    name:eachType.planName
                }
               
            })
            
            setProductDetails(productDetailsLoc);
            setProducts([...prodTypes]);
            
            var userDataLoc = {...userData};
            userDataLoc.productType=prodTypes[0].id;
            userDataLoc.amount=productDetailsLoc[prodTypes[0].id].price;
            setUserData(userDataLoc);
        }
        catch(err)
        {
            toast.error(err.response && err.response.data && err.response.data.reason?err.response.data.reason:err.message);
        }
        setIsPageLoading(false);
        })()
    },[]);
    async function setEmail(value){
        const userDataLoc = { ...userData };
        userDataLoc['email'] = value;
        setUserData({ ...userDataLoc });
    }

    async function setPaymentGatewayId(value){
        const userDataLoc = { ...userData };
        userDataLoc['paymentGatewayId'] = value;
        setUserData({ ...userDataLoc });
    }

    async function setProductData(value){
        const userDataLoc = { ...userData };
        userDataLoc['productType'] = value.target.value;
        userDataLoc['amount']=productDetails[value.target.value].price;
        setUserData({ ...userDataLoc });
    }
    async function submitData(){
        setLoader(true);
        try{
        if(validate())
        {
            await createSeekerSubscription(adminId,{
                purchaseOrderData: userData,
                adminId,
                reason,
            });
            const userDataLoc = { ...userData };
            userDataLoc['email'] = '';
            setUserData({ ...userDataLoc });
            toast.success("Created Successfully")
               
            setTimeout(() => {
                setLoader(false);   
                props.history.push(SEEKER_SUBSCRIPTIONS);
              }, 1500);
            
        }
    }
    catch(err)
    {
        toast.error(err.response && err.response.data && err.response.data.reason?err.response.data.reason:err.message);
    }
        setLoader(false);
    }
    function validate(){
        if(!userData.email || !userData.email.length){
            setErrorMsg('Email is required');
            return false;
        }
        
        if (!reason) {
            setErrorMsg('Please select reason for activation');
            return false;
        }

        if([1,2].includes(+reason) && (!userData.paymentGatewayId || !userData.paymentGatewayId.trim())) {
            setErrorMsg('Payment Gateway Id is required');
            return false;
        }

        setErrorMsg('');
        return true;

    }

    return(<div className=''>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />       
        {isPageLoading ? <Loader /> : (
            <>
                <div className='transactionForm'>
                    <label className='labelInput'>Email:</label><InputBox
                        type="text"
                        value={userData.email}
                        placeholder="Enter Email"
                        onChange={(e) => {setEmail(e.target.value)}}
                                />
                </div>
                <div className='transactionForm'>
                    <label className='labelInput'>ProductType:</label><Dropdown data={products} dropdownChange={(e) => setProductData(e)} />
                </div>
                <div className='transactionForm'>
                    <label className='labelInput'>Amount:</label><InputBox
                        type="number"
                        value={userData.amount}
                        disabled            
                                />
                </div>

                <div className='transactionForm'>
                    <label className='labelInput'>Reason:</label>
                    <Dropdown 
                        data={seekerPlanActivationReasons}
                        dropdownChange={(event) => {setReason(event.target.value)}}
                    />
                </div>

                <div className='transactionForm'>
                    <label className='labelInput'>Payment Gateway Id:</label><InputBox
                        type="text"
                        value={userData.paymentGatewayId}
                        placeholder="Enter Payment Gateway Id"
                        onChange={(e) => {setPaymentGatewayId(e.target.value)}}
                                />
                </div>


                
                <div className='transactionButtons'>
                    <Button
                        skin="dark"
                        className='eachButton'
                        disabled={loader}
                        loading={loader}
                        text="Submit"
                        clickButton={()=>submitData()}/>
                    <Button
                        skin="dark"
                        disabled={loader}
                        className='eachButton'
                        text="Cancel"
                        clickButton={()=>{props.history.push(SEEKER_SUBSCRIPTIONS)}}/>
                </div>
                <p className='errorMsg'>{errorMsg}</p>
            </>
        )}
    </div>);
}

export default withRouter(newTransaction);