
var courseType = [
    {
        "val": "2",
        "text": "Part Time"
    },
    {
        "val": "1",
        "text": "Full Time"
    },
    {
        "val": "3",
        "text": "Distance Learning Program"
    },
    {
        "val": "4",
        "text": "Executive Program"
    },
    {
        "val": "5",
        "text": "Certification"
    }
]

export var courseTypeCourses = [
    {
        id: 'Full Time',
        name: 'Full Time'
    },
    {
        id: 'Part-Time',
        name: 'Part Time'
    },
    {
        id: 'Diploma',
        name: 'Diploma'
    },
    {
        id: 'Certificate',
        name: 'Certificate'
    }
]

export function getCourseTypes() {

    return courseType.map(item => {
        return {
            label: item.text,
            value: item.val.toString(),
            key: item.val
        }
    })
}