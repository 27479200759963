import { getDomain } from "../utils/utilFunctions";

const domain = getDomain();
const rejectCourseHirist = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },  
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': 'We generally don\'t allow course postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting course, please send us a test mail from your official mail ID at info@${domain} including your contact number.'
    },
    {
        'name': 'Technical Job',
        'id':2,
        'msg': `Please post this course on ${domain} at http://www.${domain}/recruiter/login.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@${domain}.`
    },
    {
        'name': 'Low Salary',
        'id':3,
        'msg': `Unfortunately, the salary offered for this course is low. We only post premium positions on this portal. Please write to us at info@${domain} for any queries/clarifications.`
    },
    {
        'name': 'Duplicate Posting',
        'id':4,
        'msg': `This course has already been posted on the portal. Please write to us at info@${domain} for any queries/clarifications`
    },
    {
        'name': 'Multiple Locations',
        'id':5,
        'msg': `All locations have been updated within the same course posting which is published. Please write to us at info@${domain} for any queries/clarifications.`
    },
    
    {
        'name': 'Others',
        'id':6,
        'msg': `Please write to us at info@${domain} for any queries/clarifications.`
    }
];

const rejectCourseIIMJOBS = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },  
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': 'We generally don\'t allow course postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting course, please send us a test mail from your official mail ID at info@iimjobs.com including your contact number.'
    },
    {
        'name': 'Technical Job',
        'id':2,
        'msg': 'Please post this course on iimjobs.com at http://www.iimjobs.com/recruiter/login.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@iimjobs.com.'
    },
    {
        'name': 'Low Salary',
        'id':3,
        'msg': 'Unfortunately, the salary offered for this course is low. We only post premium positions on this portal. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'Duplicate Posting',
        'id':4,
        'msg': 'This course has already been posted on the portal. Please write to us at info@iimjobs.com for any queries/clarifications'
    },
    {
        'name': 'Multiple Locations',
        'id':5,
        'msg': 'All locations have been updated within the same course posting which is published. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    
    {
        'name': 'Others',
        'id':6,
        'msg': 'Please write to us at info@iimjobs.com for any queries/clarifications.'
    }
];

const rejectCourseUpdazz = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },  
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': 'We generally don\'t allow course postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting course, please send us a test mail from your official mail ID at info@updazz.com including your contact number.'
    },
    {
        'name': 'Technical Job',
        'id':2,
        'msg': 'Please post this course on iimjobs.com at http://www.updazz.com/recruiter/login.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@updazz.com.'
    },
    {
        'name': 'Low Salary',
        'id':3,
        'msg': 'Unfortunately, the salary offered for this course is low. We only post premium positions on this portal. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'Duplicate Posting',
        'id':4,
        'msg': 'This course has already been posted on the portal. Please write to us at info@updazz.com for any queries/clarifications'
    },
    {
        'name': 'Multiple Locations',
        'id':5,
        'msg': 'All locations have been updated within the same course posting which is published. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    
    {
        'name': 'Others',
        'id':6,
        'msg': 'Please write to us at info@updazz.com for any queries/clarifications.'
    }
];


let rejectCoursePlatfrom;

switch(process.env.REACT_APP_PLATFORM) {
    case 'iimjobs':
        rejectCoursePlatfrom = rejectCourseIIMJOBS;
        break;
    case 'hirist':
        rejectCoursePlatfrom = rejectCourseHirist;
        break;
    case 'updazz':
        rejectCoursePlatfrom = rejectCourseUpdazz;
        break;
    default:
        rejectCoursePlatfrom = [];
        break;
}

export const rejectReasonsCourse = rejectCoursePlatfrom;
