import React from 'react';
import PropTypes from 'prop-types';

import './LinkButtons.scss'

const linkButtons = (props) => {
    return (
        <a className="button" onClick={props.sortClick}>
            { props.name }
            { props.arrow ? (props.arrowType ? ' \u2191' : ' \u2193' ): null }
        </a>
    )
}

linkButtons.propTypes = {
    sortClick: PropTypes.func,
    name: PropTypes.string,
    arrow: PropTypes.bool,
    arrowType: PropTypes.bool,
}

export default linkButtons;