import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Dropdown from '../../../components/Dropdown';
import Button from "../../../components/Button";
import Heading from "../../../components/Heading";
import InputBox from "../../../components/InputBoxWithoutCheck";
import { getProductType, getSource } from "../../../models/filters";
import fetchLeadsMap from "../../../services/fetchLeadsMap";
import fetchRecruiterId from "../../../services/fetchRecruiterId";
import postLead from "../../../services/postLead";
import { locationListLabel as locations } from '../../../models/locationSeeker';
import Loader from '../../../components/Loader';
import validator from "validator";
import user from "../../../store/user";
import './leadCreate.scss'
import Select from "react-select";
import { isEmpty } from "lodash";
import { adminFeatureMapping } from "../../../models/adminFeatureMapping";
import { numberOfEmployeesObj } from '../../../models/numberOfEmployees';

const companyTypes = [
  {
    label: "Direct",
    value: 1
  },
  {
    label: "Consultant",
    value: 2
  }
];

var scrollToElement = require("scroll-to-element");

const validationMsg = {
  recIdReq : 'Recruiter ID is Required',
  nameReq: 'Name is Required',
  companyNameReq: "Company Name is Required",
  productTypeReq: "Product Type is Required",
  emailReq: 'Email is Required',
  phoneReq: 'Phone number is Required',
  activityReq: 'Activity is required',
  locationReq: "Location is Required",
  designationReq: "Designation is Required",
  companySizeReq: "Company Size is Required",
  companyTypeReq: "Company Type is Required"
};

const validationClass = {
  recIdReq: '.recId',
  nameReq: '.name',
  companyNameReq: '.companyName',
  productTypeReq: ".productType",
  emailReq: '.email',
  phoneReq: '.phone',
  activityReq: '.activity',
  locationReq: ".location",
  designationReq: ".designation",
  companyTypeReq: ".companyType",
  companySizeReq: ".companySize",
};

var leadsMap = {}, productTypes = [], sourceTypes = [], productDomains=[];

const LeadCreate = (props) => {
  const [lead, setLead] = useState({
    recId:'',
    name: "",
    companyName: "",
    productType: -1,
    email: "",
    phone: "",
    activity: 2,
    location: -1,
    designation: "",
    companySize: "",
    companyType: ""
  });

  const [adminDetails, setAdminDetails] = useState({
    adminId: null,
    adminEmail:null,
    viewAccess: false
  })
  const [submitApiCall, setSubmitApiCall] = useState(false);
  const [validations, setValidations] = useState({})
  const [fetchedDetails,setFetchedDetails]=useState(true);
  const [leadsAccess,setLeadsAccess]=useState(true);

  useEffect(() => {

    const fetchData = async () => {
      const profile = await user.getProfile();
       
    //   const adminEmails=process.env.REACT_APP_LEADS_BLOCKED_ACCESS;
      const isAccessBlocked = user.checkFeatureAccess(adminFeatureMapping.LEADS_BLOCKED_ACCESS);
        if(isAccessBlocked)
            setLeadsAccess(false);

      
      const adminId = profile.id;
  
      leadsMap = await fetchLeadsMap(adminId)
      productTypes = getProductType(leadsMap)
    
      setAdminDetails({adminId,adminEmail:profile.email})

    }

    fetchData()


  }, [])

  const leadsList = () => {
    props.history.push("/leads");
  };

  const onChange = (key, ev) => {

    let data = { ...lead };
    if(key == 'location')
      data[key] = ev ? ev : '';
    else data[key] = ev.target.value;
    setLead(data)
  }

  const onSubmit = async (e) => {

    e.preventDefault();
    if(validate()) {
    
      setSubmitApiCall(true);

      try{
        let data = {
          productType: lead.productType,
          additionalInfo: {
            phone: lead.phone,
            location: lead.location.value,
            designation: lead.designation,
            enquiry_type: 'New-Inbound'
          },
          source: '17',
          name: lead.name,
          companyName: lead.companyName,
          activity: lead.activity,
          companySize: lead.companySize,
          companyType: lead.companyType
        }

        if(lead.email) data['email'] = lead.email;
        else data['recruiterId'] = lead.recId
        
        const res = await postLead(adminDetails.adminId, data);

        setSubmitApiCall(false)

        if(!res || res.status=='fail')
          toast.error("Error while generating lead");
          
        else {
          toast.success('Lead successfully generated');
		  if(leadsAccess)
          props.history.push('/leads')
		  else
		  {
			  setLead({recId:'',
        name: "",
        companyName: "",
        productType: -1,
        email: "",
        phone: "",
        activity: 2,
        location: -1,
        designation: "",});
		  }
          
        }
          

      }catch(err) {
        console.log(err);
        setSubmitApiCall(false)
        
        toast.error("Error while generating lead");
      }
    }

    

  }

  const validate = () => {

    if(isEmpty(lead.recId) && isEmpty(lead.email)) {
      // toast.error('Either Recruiter Id or Email Required')
      toast.error('Email Required')
      return false;
    }
    if(!isEmpty(lead.recId) && isNaN(lead.recId)) {
      toast.error('Recruiter Id must be number')
      return false;
    }
    if(!isEmpty(lead.email) && !validator.isEmail(lead.email)) {
      toast.error('Invalid Email')
      return false;
    }
    if(!isEmpty(lead.phone) && lead.phone.length != 10) {
      toast.error('Phone Number must be of 10 digits')
      return false;
    }
    let validations= {
      nameReq: checkEmpty(lead.name),
      companyNameReq: checkEmpty(lead.companyName),
      productTypeReq: checkEmpty(lead.productType),
      phoneReq: checkEmpty(lead.phone),
      activityReq: checkEmpty(lead.activity),
      locationReq: checkEmpty(lead.location),
      designationReq: checkEmpty(lead.designation),
    }

    setValidations(validations)
    for (let key in validations) {
      if (validations[key]) {
        
        scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;
  }

  const checkEmpty = (val) =>{
    if (!val) return true;
    if (!isNaN(val) && val == -1) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  async function fetchDetails(){
	  if(!lead.email.length)return;
	  setFetchedDetails(false);
	  const params={
		  email:lead.email
	  }
	var recDetails=await fetchRecruiterId(adminDetails.adminId,params);
	if(recDetails.data)
	{
		var importedLeadData = {...lead,
			name:recDetails.data.name,
			companyName:recDetails.data.companyName,
			phone:recDetails.data.phone,
			designation:recDetails.data.designation
		};

    const location = locations.find(element => element.value == recDetails.data.location)
    importedLeadData["location"] = location;
		setLead(importedLeadData)
	}
  else
  {
    let data = {
      recId:'',
      name: "",
      companyName: "",
      productType: -1,
      phone: "",
      activity: 2,
      location: -1,
      designation: "",
    }
    setLead({...lead, ...data});
  }
setFetchedDetails(true);
  }

  return (
    
      <div className="lead-wrapper">
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />

          <div className="pageHeading">
            <Heading text="Generate a Lead" />
            {leadsAccess?<Button skin="dark" text="Leads List" clickButton={leadsList} />:null}
          </div>
          <hr />
          <div className="generate-lead">
            <form>
              <div className="row details-section">
            
                {/* <div className="form-group recId">
                  <label className="col-sm-2 left"> Recruiter Id </label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.recId}
                      placeholder="Enter Recruiter Id"
                      onChange={(e) => onChange("recId", e)}
                    />
                    {validations.recIdReq ? <p className='validatorError'> {validationMsg.recIdReq} </p> : null}
                  </div>
                </div> */}

                <div className="form-group email">
                  <label className="col-sm-2 left"> Email </label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.email}
                      placeholder="Enter Email"
                      onChange={(e) => onChange("email", e)}
					  onBlur={(e)=>{fetchDetails()}}
                    />
                    {validations.emailReq ? <p className='validatorError'> {validationMsg.emailReq} </p> : null}
                  </div>
                </div>
				{!fetchedDetails?<Loader />:<div>
                <div className="form-group name">
                  <label className="col-sm-2 left"> Name *</label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.name}
                      placeholder="Enter Recruiter Name"
                      onChange={(e) => onChange("name", e)}
                    />
                    {validations.nameReq ? <p className='validatorError'> {validationMsg.nameReq} </p> : null}
                  </div>
                </div>

                <div className="form-group companyName">
                  <label className="col-sm-2 left"> Company Name *</label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.companyName}
                      placeholder="Enter Company Name"
                      onChange={(e) => onChange("companyName", e)}
                    />
                    {validations.companyNameReq ? <p className='validatorError'> {validationMsg.companyNameReq} </p> : null}
                  </div>
                </div>

				<div className="form-group email">
                  <label className="col-sm-2 left"> Phone* </label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.phone}
                      placeholder="Enter Phone"
                      onChange={(e) => onChange("phone", e)}
                    />
                    {validations.phoneReq ? <p className='validatorError'> {validationMsg.phoneReq} </p> : null}
                  </div>
                </div>

				<div className="form-group designation">
                  <label className="col-sm-2 left"> Designation *</label>
                  <div className="col-sm-6 left valueBox">
                    <InputBox
                      type="text"
                      value={lead.designation}
                      placeholder="Enter Designation"
                      onChange={(e) => onChange("designation", e)}
                    />
                    {validations.designationReq ? <p className='validatorError'> {validationMsg.designationReq} </p> : null}
                  </div>
                </div>

				<div className='form-group location'>
                    <label className='col-sm-2 left'> Location *</label>
                    <div className='col-sm-6 left valueBox'>
                        
                          <Select
                            closeMenuOnSelect
                            isOptionDisabled={option => option.disabled}
                            placeholder='Select Location'
                            value={lead.location}
                            isClearable
                            onChange={(e) => onChange('location', e)}
                            options={locations}
                          />

                            {validations.locationReq ? <p className='validatorError'> {validationMsg.locationReq} </p> : null}

                    </div>
                </div>
				</div>
}

            <div className='form-group companySize'>
              <label className='col-sm-2 left'> Company Size *</label>
              <div className='col-sm-6 left valueBox'>

                <Select
                  closeMenuOnSelect
                  placeholder='Select Company Size'
                  value={lead.companySize}
                  isClearable
                  onChange={(e) => onChange('companySize', e)}
                  options={numberOfEmployeesObj}
                />

                {validations.companySizeReq ? <p className='validatorError'> {validationMsg.companySizeReq} </p> : null}

              </div>
            </div>

            <div className='form-group companyType'>
              <label className='col-sm-2 left'> Company Type *</label>
              <div className='col-sm-6 left valueBox'>

                <Select
                  closeMenuOnSelect
                  placeholder='Select Company Type'
                  value={lead.companyType}
                  isClearable
                  onChange={(e) => onChange('companyType', e)}
                  options={companyTypes}
                />
                {validations.companyTypeReq ? <p className='validatorError'> {validationMsg.companyTypeReq} </p> : null}
              </div>
            </div>

                <div className="form-group sourceType">
                    <label className="col-sm-2 left"> Source </label>
                    <div className="col-sm-6 left valueBox">

                      <InputBox disabled value='Inbound Emails'/>
                      
                    </div>
                </div>

                <div className="form-group activity">
                  <label className="col-sm-2 left"> Activity *</label>
                  <div className="col-sm-6 left activityBox">
                    <InputBox type='radio' value='1' checked={lead.activity == '1'} name='activity' onChange={(e) => onChange('activity', e)} /> Click
                    <InputBox type='radio' value='2' checked={lead.activity == '2'} name='activity' onChange={(e) => onChange('activity', e)} /> Enquiry

                    {validations.activityReq ? <p className='validatorError'> {validationMsg.activityReq} </p> : null}
                  </div>
                </div>

				<div className="form-group productType">
                    <label className="col-sm-2 left"> Product Type*</label>
                    <div className="col-sm-6 left valueBox">

                      <Dropdown data={productTypes} dropdownChange={(e) => onChange('productType', e)} defaultValue={lead.productType} />
                        
                      {validations.productTypeReq ? <p className='validatorError'> {validationMsg.productTypeReq} </p> : null}
                    </div>
                </div>
               
               

              </div>


              <div className="actions">
                  <Button
                    skin="dark"
                    disabled={submitApiCall}
                    loading={submitApiCall}
                    text="Submit"
                    clickButton={onSubmit}
                  />
                </div>
            </form>
          </div>
          
        </div>
      
  );
};

export default LeadCreate;
