import React from 'react';
import { Icon } from 'semantic-ui-react';

const spinner = () => (
    <div className="spinner-container">
        <Icon name="spinner" loading/>
    </div>
)

export default spinner;
