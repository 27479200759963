import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';

import user from '../../store/user';
import { getQueryParams } from '../../utils/utilFunctions';

import getRecruiterStatusLogs from '../../services/getRecruiterStatusLogs';
import getRecruiterStatusLogsCount from '../../services/getRecruiterStatusLogsCount';

import { RECRUITER_LOG_REPORT } from '../../models/routes';

import './styles.scss';

const checkIfNumber = (string) => {
    if (!isNaN(string) && !isNaN(parseFloat(string))) {
        return true;
    } 

    return false;
}

const RecruiterLogReport = props => {
    const [adminId, setAdminId] = useState(null);

    const [pageController, setPageController] = useState({
        pageNumber: 0,
        pageContent: 100,
        recruiterList: [],
        total: 0,
        isLoading: true,
        isPageLoading: false,
        callFetchApi: false,
        searchText: '',
    })

    const setData = obj => {
        setPageController(prevObj => {
            return {
                ...prevObj,
                ...obj,
            }
        });
    };

    const handlePageChange = (page) => {
        setData({
            pageNumber: page,
        });
    }

    const getParams = (forCountAPI = false) => {
        let recruiterId = null;
        let recruiterEmail = null;

        if (pageController.searchText) {
            if (checkIfNumber(pageController.searchText)) {
                recruiterId = pageController.searchText;
            } else {
                recruiterEmail = pageController.searchText;
            }

            return {
                recruiterId,
                recruiterEmail
            }
        }

        if (forCountAPI) {
            return {};
        }

        return {
            pageNumber: pageController.pageNumber,
            pageContent: pageController.pageContent,
        };
    };

    const getRecruiterListCount = async () => {
        try {
            const params = getParams(true);

            const response = await getRecruiterStatusLogsCount(adminId, params);

            if (response && response.data && response.data.data) {
                setData({
                    total: response.data.data.count,
                });
            }

        } catch (error) {
            // console.log(error);
        }
    };

    const getRecruiterList = async () => {
        try {
            const params = getParams();

            const response = await getRecruiterStatusLogs(adminId, params);

            if (response && response.data && response.data.data && response.data.data.length > 0) {
                setData({
                    recruiterList: response.data.data,
                    isLoading: false,
                    isPageLoading: false,
                })
            } else {
                setData({
                    recruiterList: null,
                    isLoading: false,
                    isPageLoading: false,
                })
            }
            
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later');
        }
    };

    const updateQueryParams = () => {
        const params = getParams();

        const paramsArr = [];

        if (params.pageNumber) {
            paramsArr.push(`pg=${params.pageNumber}`);
        }
        
        if (params.recruiterId) {
            paramsArr.push(`search=${params.recruiterId}`);
        }

        if (params.recruiterEmail) {
            paramsArr.push(`search=${params.recruiterEmail}`);
        }

        props.history.push(`${RECRUITER_LOG_REPORT}?${paramsArr.join('&')}`);
    }

    useEffect(() => {
        if (pageController.pageNumber) {
            setData({
                isPageLoading: true,
                callFetchApi: true,
            });
        } 

    }, [pageController.pageNumber]);

    useEffect(() => {
        if (!pageController.callFetchApi) {
            return;
        }

        setData({
            callFetchApi: false,
        });

        getRecruiterList();
        getRecruiterListCount();

        updateQueryParams();

    }, [pageController.callFetchApi]);

    useEffect(() => {
        if (adminId) {
            const params = getQueryParams(props.location.search);
                
                let pageNumber = 1, search = null;

                if (params.pg && checkIfNumber(params.pg)) {
                    pageNumber = params.pg;
                }

                if (params.search) {
                    search = params.search;
                }

                setData({
                    pageNumber,
                    searchText: search,
                });
        }
    }, [adminId]);

    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        }

        getProfile().then(profileData => {
            if (profileData.adminType == 2 || profileData.adminType == 3) {

                setAdminId(profileData.id);

            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        }).catch(error => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
        
    }, []);

    const handleSearchSubmit = () => {
        setData({
            callFetchApi: true,
            pageNumber: 1,
        })
    };

    const handleSearchClear = () => {
        setData({
            searchText: '',
            callFetchApi: true,
            pageNumber: 1,
        })
    }

    const handleSearchValueChange = (event) => {
        setData({
            searchText: event.target.value,
        });
    }

    const handleSearchKeyPress = (event) => {
        if (event && event.key && event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width="one">Sr. No.</Table.HeaderCell>
                <Table.HeaderCell width="two">Recruiter Details</Table.HeaderCell>
                <Table.HeaderCell width="one">Status</Table.HeaderCell>
                <Table.HeaderCell width="two">Activated/Deactivated By</Table.HeaderCell>
                <Table.HeaderCell width="one">Created</Table.HeaderCell>
            </Table.Row>    
        </Table.Header>
    );

    let topPageIndicators = null, bottomPageIndicators = null;

    if (!pageController.isLoading && pageController.recruiterList && pageController.recruiterList.length > 0) {
        topPageIndicators = (
            <div className="page-controller pagination-top">
                <div className="row text-center">
                    <Pagination totalItemsCount={pageController.total} content={pageController.pageContent} pageNumber={pageController.pageNumber} handlePageChange={handlePageChange} />
                    <CountDisplay start={(pageController.pageNumber - 1) * pageController.pageContent + 1} end={pageController.pageNumber * pageController.pageContent} total={pageController.total} />
                </div>
            </div>
        );

        bottomPageIndicators = (
            <div className="page-controller row text-center pagination-bottom">
                <Pagination totalItemsCount={pageController.total} pageNumber={pageController.pageNumber} content={pageController.pageContent} handlePageChange={handlePageChange} />
            </div>
        )
    } else {
        topPageIndicators = <div></div>;
        bottomPageIndicators = <div></div>;
    }

    let mainContent = null;

    if (pageController.isLoading) {
        mainContent = <Loader />;
    } else if (!pageController.isLoading && pageController.isPageLoading) {
        mainContent = (
            <>  
                <Table className="main-table">
                    {tableHeading}
                </Table>
                <div>
                    <Loader />
                </div>
            </>
        );
    } else {
        mainContent = (
            <>
                <Table striped className="main-table">
                    {tableHeading}
                    <Table.Body className="main-table-body">
                        {
                            pageController.recruiterList ? pageController.recruiterList.map((item, index) => {
                                return (
                                    <Table.Row className="main-table-body-row">
                                        <Table.Cell width="one">{(pageController.pageNumber - 1) * pageController.pageContent + index + 1}</Table.Cell>
                                        <Table.Cell width="two">{item.rec_email} (Id: {item.rec_id})</Table.Cell>
                                        <Table.Cell width="one">{item.rec_profile_status == 0 ? 'Deactivated' : 'Activated'}</Table.Cell>
                                        <Table.Cell width="two">{item.email} (Type: {item.doneby == 1 ? 'Recruiter' : 'Admin'})</Table.Cell>
                                        <Table.Cell width="one">{moment(item.created).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                    </Table.Row>
                                );
                            }) : null
                        }

                        {!pageController.recruiterList ? (
                            <div className='error-message'>No Results Found.</div>
                        ) : null}
                    </Table.Body>
                </Table>
            </>
        );
    }
    
    return (
        <div className='page-content recruiter-log-report'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggle={false}
                pauseOnHover
            />

            <div className="page-heading">
                <Heading text="Recruiter Activation/Deactivation Log Report" />
            </div>
            
            <div className='search__controller'>
                <InputBox
                    type="text"
                    name="search"
                    value={pageController.searchText}
                    onChange={handleSearchValueChange}
                    placeholder="Recruiter ID/Email"
                    onKeyPress={handleSearchKeyPress}
                />
                <Button 
                    skin="dark" 
                    text="Search" 
                    clickButton={handleSearchSubmit}
                />
                <Button 
                    skin="dark" 
                    text="Clear" 
                    clickButton={handleSearchClear}
                />
            </div>

            {topPageIndicators}

            <div className="main-row">
                {mainContent}
            </div>

            {bottomPageIndicators}
        </div>
    );
};

export default RecruiterLogReport;