import axios from '../../utils/axios';

export default function fetchBusinessLines(adminId, data) {
    return (
        axios.get(`/admin/${adminId}/getLGBusinessLine`,  data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
