import axios from '../utils/axios';

export default function fetchClientTestimonials(adminId, ceId, params) {
    return (
        axios.get('/admin/' + adminId + '/client-engagement/testimonials/' + ceId, { params })
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err;
            })
    )
}
