import axios from '../utils/axios';

export default function fetchRecruiterCompanies(adminId,params) {
    return (
        axios.get('/admin/'+ adminId+'/recruiter-company-list',{
            params:params
        })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
