export const newIndustryList = [
    { name: 'Banking / Financial Services / Broking', id: '2' },
    { name: 'BPO / ITES', id: '3' },
    { name: 'IT Services & Consulting', id: '4' },
    { name: 'Engineering & Construction', id: '5' },
    { name: 'Education / Teaching / Training', id: '6' },
    { name: 'FMCG / Foods / Beverage', id: '7' },
    { name: 'Healthcare / Medical / Hospital', id: '8' },
    { name: 'Travel / Hospitality', id: '9' },
    { name: 'Industrial Equipment / Machinery', id: '10' },
    { name: 'Infrastructure', id: '11' },
    { name: 'Insurance', id: '12' },
    { name: 'Internet / Online', id: '13' },
    { name: 'Automobile', id: '14' },
    { name: 'Analytics / KPO / Research', id: '15' },
    { name: 'Legal', id: '16' },
    { name: 'Manufacturing', id: '17' },
    { name: 'Metals & Mining', id: '19' },
    { name: 'NGO / Social Services / Industry Associations', id: '20' },
    { name: 'Retail', id: '22' },
    { name: 'Telecom / ISP', id: '23' },
    { name: 'Accounting / Auditing', id: '24' },
    { name: 'Advertising', id: '25' },
    { name: 'Petrochemical / Plastics / Rubber', id: '26' },
    { name: 'Facility Management', id: '27' },
    { name: 'Pharmaceutical & Life Sciences', id: '28' },
    { name: 'Real Estate / Property', id: '29' },
    { name: 'Recruitment / Staffing', id: '30' },
    { name: 'Semiconductors / Electronics', id: '31' },
    { name: 'Textile & Apparel', id: '32' },
    { name: 'Other', id: '33' },
    { name: 'Agriculture / Dairy', id: '34' },
    { name: 'Logistics / SCM / Freight / Shipping', id: '35' },
    { name: 'Animation & VFX', id: '36' },
    { name: 'Architecture / Interior Design', id: '37' },
    { name: 'Aviation / Aerospace', id: '38' },
    { name: 'Brewery / Distillery', id: '39' },
    { name: 'Consumer Durables', id: '40' },
    { name: 'Ceramics / Sanitary ware', id: '42' },
    { name: 'Government / Public Administration', id: '43' },
    { name: 'Export / Import', id: '45' },
    { name: 'Fertilizers / Pesticides / Agro chemicals', id: '46' },
    { name: 'Food Processing', id: '47' },
    { name: 'Gems & Jewellery', id: '48' },
    { name: 'Glass', id: '49' },
    { name: 'Heating / Ventilation / Air Conditioning', id: '50' },
    { name: 'Hotels & Restaurants', id: '51' },
    { name: 'Software / Software Services', id: '52' },
    { name: 'Hardware & Networking', id: '53' },
    { name: 'Oil & Gas', id: '56' },
    { name: 'Office Equipment / Automation', id: '57' },
    { name: 'Pulp & Paper', id: '58' },
    { name: 'Printing / Packaging', id: '59' },
    { name: 'Publishing', id: '60' },
    { name: 'Security / Law Enforcement', id: '61' },
    { name: 'Shipping / Marine', id: '62' },
    { name: 'Iron & Steel', id: '63' },
    { name: 'Strategy / Management Consulting Firms', id: '64' },
    { name: 'Tyres', id: '65' },
    { name: 'Water Treatment / Waste Management', id: '66' },
    { name: 'Wellness / Fitness / Sports', id: '67' },
    { name: 'Advertising Tech', id: '68' },
    { name: 'Agri Tech', id: '69' },
    { name: 'Clean Tech', id: '70' },
    { name: 'Delivery/Logistics Tech', id: '71' },
    { name: 'E-Commerce', id: '72' },
    { name: 'E-Learning / EdTech', id: '73' },
    { name: 'FinTech / Payments', id: '74' },
    { name: 'Food Tech', id: '75' },
    { name: 'Gaming', id: '76' },
    { name: 'Health Tech', id: '77' },
    { name: 'HR Tech', id: '78' },
    { name: 'Insurance Tech', id: '79' },
    { name: 'Internet Services', id: '80' },
    { name: 'Legal Tech', id: '81' },
    { name: 'Marketing Tech', id: '82' },
    { name: 'Marketplaces', id: '83' },
    { name: 'Mobile', id: '84' },
    { name: 'OTT', id: '85' },
    { name: 'Platforms/PaaS', id: '86' },
    { name: 'Real Estate Tech', id: '87' },
    { name: 'Retail Tech ', id: '88' },
    { name: 'Storage & Networking', id: '89' },
    { name: 'Travel Tech', id: '90' },
    { name: 'Wearables', id: '91' },
    { name: 'Big Data & Analytics ', id: '92' },
    { name: 'Communication', id: '93' },
    { name: 'Dating', id: '94' },
    { name: 'Ride-Sharing / Transportation', id: '95' },
    { name: 'Co-working', id: '96' },
    { name: 'Community & Lifestyle', id: '97' },
    { name: 'Social Media', id: '98' },
    { name: '3D Printing ', id: '99' },
    { name: 'AI/ML ', id: '100' },
    { name: 'AR/VR ', id: '101' },
    { name: 'Biotechnology', id: '102' },
    { name: 'Blockchain / Cryptocurrency', id: '103' },
    { name: 'Cloud ', id: '104' },
    { name: 'Cloud Computing', id: '105' },
    { name: 'Cybersecurity ', id: '106' },
    { name: 'Drones/Robotics ', id: '107' },
    { name: 'Electric Vehicle (EV)', id: '108' },
    { name: 'IoT ', id: '109' },
    { name: 'Nanotechnology ', id: '110' },
    { name: 'Airlines', id: '111' },
    { name: 'Auto Components', id: '112' },
    { name: 'Beauty & Personal Care', id: '113' },
    { name: 'Cement ', id: '114' },
    { name: 'Clinical Research / Contract Research', id: '115' },
    { name: 'Consumer Electronics & Appliances', id: '116' },
    { name: 'Content Development / Language', id: '117' },
    { name: 'Defence & Aerospace', id: '118' },
    { name: 'Design', id: '119' },
    { name: 'Diagnostics', id: '120' },
    { name: 'Electrical Equipment', id: '121' },
    { name: 'Electronic Manufacturing Services (EMS)', id: '122' },
    { name: 'Electronics Manufacturing', id: '123' },
    { name: 'Events / Live Entertainment', id: '124' },
    { name: 'Film / Music / Entertainment', id: '125' },
    { name: 'Forestry / Fishing', id: '126' },
    { name: 'Furniture & Furnishing', id: '127' },
    { name: 'Green / Renewable Energy / Sustainability', id: '128' },
    { name: 'Hydro Power', id: '129' },
    {
        name: 'Investment Banking / Venture Capital / Private Equity',
        id: '130',
    },
    { name: 'Journalism / News', id: '131' },
    { name: 'Leather', id: '132' },
    { name: 'Marketing / Digital Marketing', id: '133' },
    { name: 'Marketing Research', id: '134' },
    { name: 'Meat / Poultry', id: '135' },
    { name: 'NBFC / Microfinance', id: '136' },
    { name: 'Nuclear Power', id: '137' },
    { name: 'Packaging & Containers', id: '138' },
    { name: 'Paints', id: '139' },
    { name: 'Power', id: '140' },
    { name: 'Public Relations', id: '141' },
    { name: 'Roads / Railways', id: '142' },
    { name: 'Shipbuilding', id: '143' },
    { name: 'Solar Power', id: '144' },
    { name: 'Sports / Leisure & Recreation', id: '145' },
    { name: 'TV / Radio', id: '146' },
    { name: 'Urban Transport', id: '147' },
    { name: 'Wind Power', id: '148' },
];

export function getIndustryListFromId () {
    return newIndustryList.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}