import axios from '../utils/axios';

export default function getSingleJobApplicationsCount(recruiterId,jobId) {
    return (
        axios.get(`/admin/recruiter/${recruiterId}/job/${jobId}/applicationCount`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
