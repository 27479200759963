import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import Button from '../../../components/Button';

import { RECRUITER_INCOMPLETE_EDIT } from '../../../models/routes';

import './TableItem.scss';

const TableItem = props => {
    const data = props.data;
    const [selectedType, setSelectedType] = useState(0);

    let hasLinkedIn = false;
    if (data.lurl && data.lurl.trim().length > 0) {
        hasLinkedIn = true;
    }

    let hasPicture = false;
    if (data.pic && data.pic.length > 0) {
        hasPicture = true;
    }

    const handleButtonClick = () => {
        props.onProfileUpdate(data.id, selectedType);
    }

    const recruiterTypeContent = (
        <div className='recruiter-type'>
            <select onChange={(event) => {
                setSelectedType(event.target.value);
            }}>
                <option value="0" selected={data.rec_type == 0 ? true : false}>Select</option>
                <option value="1" selected={data.rec_type == 1 ? true : false}>Direct Employer</option>
                <option value="2" selected={data.rec_type == 2 ? true : false}>Recruitment Firm</option>
            </select>
            <Button text='Save' skin='dark' clickButton={handleButtonClick} />
        </div>
    )

    const actionLink = `${RECRUITER_INCOMPLETE_EDIT}?recid=${data.id}`;
    return (
        <Table.Row className='table-row' verticalAlign='top'>
            <Table.Cell width='one'>{data.jobid}</Table.Cell>
            <Table.Cell width='one'>{data.name}</Table.Cell>
            <Table.Cell width='one'>{data.email}</Table.Cell>
            <Table.Cell width='one'>{data.designation}</Table.Cell>
            <Table.Cell width='one'>{data.organisation}</Table.Cell>
            <Table.Cell width='one'>{hasLinkedIn ? 'Y' : <p className='text-red'>N</p>}</Table.Cell>
            <Table.Cell width='one'>{hasPicture ? 'Y' : <p className='text-red'>N</p>}</Table.Cell>
            <Table.Cell width='one'>{recruiterTypeContent}</Table.Cell>
            <Table.Cell width='one'><a href={actionLink} target='_blank'>Edit</a></Table.Cell>
        </Table.Row>
    );
}

TableItem.propTypes = {
    data: PropTypes.object,
    onProfileUpdate: PropTypes.func
}

export default TableItem;