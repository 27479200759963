export const gender = {
    1: "Male",
    2: "Female",
    3: "Not Mentioned"
}

export const maritalStatus = {
    1: "Single",
    2: "Married",
    3: "Widow",
    4: "Divorced",
    5: "Separated",
    6: "Others",
    7: "N.A."
}

export const workPermit = {
    1: "No",
    2: 'Have H1 Visa',
    3: 'TN Permit Holder',
    4: 'Green Card Holder',
    5: 'US Citizen',
    6: 'Authorized to work in US'
}

export const binaries = {
    1: "Yes",
    2: "No",
    0: "N.A."
}

export const willingTravel = {
    1: "Occasional",
    2: "Extensive",
    3: "No"
}

export const militaryOptMap={
	1:"Army",
	2:"Navy",
	3:"Air Force",
	4:"Naval Coast Guard",
	5:"others"
}

export const subDisabilityMap={
	1:"Locomotor Disability",
	2:"Visual Impairment",
	3:"Hearing Impairment",
	4:"Speech and Language Disability",
	5:"Specific Learning Disability",
	6:"Autism Spectrum Disorder",
	7:"Mental Illness",
	8:"Chronic Neurological Conditions",
	9:"Blood Disorder",
	10:"Multiple Disabilities"
}
