import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button';
import InputBox from '../../../components/InputBox';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import { Table } from 'semantic-ui-react';
import './DiversityPlanList.scss'
import fetchDiversityPlansList from '../../../services/fetchDivesityPlansList';
import fetchDiversityPlansCount from '../../../services/fetchDiversityPlansCount';
import updateDiversityPlan from '../../../services/updateDiversityPlan';
import user from '../../../store/user';

const DiversityPlanList = () => {

    const [adminId, setAdminId] = useState(null);
    const [recruiterId, setRecruiterId] = useState(null);
    const [planList, setPlanList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const PAGE_CONTENT = 50;

    useEffect(() => {
      (async () => {
            const profile = await user.getProfile();
            setAdminId(profile.id)
            
      })()

    }, [])

    useEffect(() => {
        (async () => {
            if (adminId) {
                setIsLoading(true);
                const { data } = await fetchDiversityPlansCount(adminId, {});
                setTotalCount(data.data[0].cnt);
                await fetchList(1);
                setIsLoading(false);
            }
        })()
    }, [adminId])

    const fetchList = async (page, recId) => {
        const params = { pageNumber: page, pageContent: PAGE_CONTENT };
        if (recId) {
            params.recruiterId = recId
        }
        const { data } = await fetchDiversityPlansList(adminId, params);
        setPlanList(data.data.data);
    };
    
    const changeHandler = (ev) => {
        setRecruiterId(ev.target.value)
    };

    const onSearch = async () => {
        if (recruiterId) {
            setIsLoading(true);
            await fetchList(1, recruiterId);
            setPageNumber(1);
            setTotalCount(1);
            setIsLoading(false);
        }
    };

    const handlePageChange = async (page) => {
        setIsLoading(true);
        setPageNumber(page);
        await fetchList(page);
        setIsLoading(false);
    };

    const changePlanStatus = async (ev) => {
        // eslint-disable-next-line no-undef
        const ans = window.confirm(`Are you sure you want to ${ev.target.name} Plan?`);
        if (ans) {
            const status = ev.target.name === 'deactivate' ? 0 : 1;
            const autoId = ev.target.id;
            await updateDiversityPlan(adminId, { autoId, status, });
            await fetchList(1);
        }
    }

    return (
        <div className='diversity-plan-list-container'>
            <div className='search-container'>
                <InputBox
                    name="recruiterId"
                    type="number"
                    placeholder="Search Recruiter Id"
                    value={recruiterId}
                    onChange={changeHandler}
                />
                <Button
                    text='Search'
                    skin='light'
                    clickButton={onSearch}
                />
            </div>
            <div className='page-heading'>
                <span>Diversity Plan Management</span>
            </div>
            <div className='action-btns'>
                <Button
                    text='Reload'
                    skin='light'
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href='/diversityPlanList'}
                />
                <Button
                    text='Add Plan'
                    skin='light'
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href='/diversityPlanAdd'}
                />
                <Button
                    className='older-version-btn'
                    text='Go to Older Version'
                    skin='light'
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/diversityPlanList.php`}
                />
            </div>
            <div className='pagination-container text-center'>
                <Pagination
                    totalItemsCount={totalCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
                <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={totalCount < (pageNumber * PAGE_CONTENT) ? totalCount : (pageNumber * PAGE_CONTENT)} total={totalCount} />
            </div>
            <div className='list-container'>
                <Table
                    fixed
                    selectable
                    className="plan-list"
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>
                                Recruiter Details
                            </Table.HeaderCell>
                            <Table.HeaderCell width={10}>
                                Company Details
                            </Table.HeaderCell>
                            <Table.HeaderCell width={6}>
                                Domain
                            </Table.HeaderCell>
                            <Table.HeaderCell width={4}>
                                Actions
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {isLoading ?
                        <Loader /> :
                        planList.length ? planList.map((plan) => {
                            return (
                                <Table.Row key={plan.id}>
                                    <Table.Cell>
                                        {plan.recruiter_id} - {plan.recname}
                                        <br/>
                                        {plan.email}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {plan.company_id} - {plan.company_name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {plan.domain}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {plan.status ?
                                            <>
                                                <a href={`/diversityPlanAdd?id=${plan.id}`}>Edit Plan</a>
                                                <br/>
                                                <a id={plan.id} className='change-status-link' name="deactivate" onClick={changePlanStatus}>Deactive</a>
                                            </>
                                        : <a id={plan.id} className='change-status-link' name="activate" onClick={changePlanStatus}>Activate</a>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                        : 'No Record Found'
                    }
                </Table>
            </div>
        </div>
    )
}

export default DiversityPlanList