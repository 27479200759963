import React, { useState, useEffect } from "react";
import user from "../../../../store/user";
import fetchCourseMappingList from "../../../../services/fetchCourseMappingList";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Loader from "../../../../components/Loader";
import CountDisplay from "../../../../components/CountDisplay";
import Pagination from "../../../../components/Pagination";
import Button from "../../../../components/Button";
import Heading from "../../../../components/Heading";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getQueryParams } from "../../../../utils/utilFunctions";
import { Link } from "react-router-dom";
import ButtonContainer from "../../../../components/ButtonContainer";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import fetchCourseIntegrations from "../../../../services/fetchCourseIntegrations";


const PAGE_CONTENT = 50;

const CourseMappingList = (props) => {
    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 1,
    });

    const [admin, setAdmin] = useState({
        adminId: null,
    });

    const [fetchApiCall, setFetchApiCall] = useState(true);
    const [integration, setIntegration] = useState("");

    const [courseIntegrations, setCourseIntegrations] = useState({
        list: [],
        map: {}
    })

    useEffect(() => {
        const fetchProfile = async () => {
            return await user.getProfile();
        };
        fetchProfile().then((userData) => {
            if (
                userData.adminType == 2 ||
                userData.adminType == 3 ||
                userData.adminType == 4
            ) {
                setAdmin({
                    adminId: userData.id,
                });
                const queryObj = getQueryParams(props.location.search);
                setContent((prev) => ({
                    ...prev,
                    pageNumber: queryObj.pageNumber,
                }));
                setIntegration(
                    queryObj.integration ? queryObj.integration : ""
                );
                const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
                const filterObj = {};
                if (queryObj.integration) {
                    filterObj["integration"] = queryObj.integration;
                }
                getCourseInterations(userData.id);
                getCourseMappingList(userData.id, pgNo, filterObj);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, []);

    const getCourseInterations = async(adminId) => {
        try{
          const res = await fetchCourseIntegrations(adminId);
          const data = res.data.data.integrations;
          const formattedList = formatIntegration(data);
    
          setCourseIntegrations({
            list: formattedList,
            map: data
          });
          
        }
        catch(e) {
          console.log(e);
          toast.error('Something went wrong!');
        }
      }
    
    const formatIntegration = (listMap) => {
        const newList = [];
        newList.push({
            id: '',
            name: 'Select Integration Type'
        })
        for(const key in listMap) {
            newList.push({
            id: key,
            name: listMap[key]
            })
        }

        return newList;
    }

    const getCourseMappingList = async (adminId, pageNumber, filters = {}) => {
        setFetchApiCall(true);
        try {
            //handle pagination
            const res = await fetchCourseMappingList(adminId, { ...filters });
            setContent({
                pageNumber: pageNumber,
                list: res.data.data.data,
                count: res.data.data.totalCount,
            });
            setFetchApiCall(false);
        } catch (err) {
            console.log(err);
            setFetchApiCall(false);

            if (err.response.status == 404) {
                setContent({
                    list: [],
                    count: 0,
                });
                return;
            }
            toast.error("Something went wrong!");
        }
    };

    const handlePageChange = async (n) => {
        const filterObj = {};
        if (integration) {
            filterObj["integration"] = integration;
        }
        await getCourseMappingList(admin.adminId, n, filterObj);
        routeRequest(n, integration);
    };

    const routeRequest = (pageNumber, integration) => {
        let array = [];

        array.push(`pageNumber=${pageNumber}`);
        if (integration) {
            array.push(`integration=${integration}`);
        }
        array = array.join("&");
        props.history.push("?" + array);
    };

    const onChangeFilter = async (event) => {
        const integration = event.target.value;
        setIntegration(integration);

        const filterObj = {};
        if (integration) {
            filterObj["integration"] = integration;
        }

        await getCourseMappingList(admin.adminId, 1, filterObj);
        routeRequest(1, integration);
    };

    return (
        <div className="course-mapping-list-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="course-mapping-list-heading">
                <Heading text="Course Mapping Management" />

                <Button
                    skin="dark"
                    className="btn_course_list"
                    text="Course List"
                    clickButton={() => {
                        props.history.push("/courseList");
                    }}
                />
                <Button
                    skin="dark"
                    className="btn_course_mapping"
                    text="Add Course Mapping"
                    clickButton={() => {
                        props.history.push("/courseMapping");
                    }}
                />

                <a
                    href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/course_admin_new.php`}
                    target="_blank"
                >
                    <Button
                        skin="dark"
                        className="btn_use_old"
                        disabled={false}
                        text="Use Old Version"
                    />
                </a>
            </div>

            <div className="row">
                <ButtonContainer heading="Integration type: ">
                    <li key="filter">
                        {" "}
                        <Dropdown
                            data={courseIntegrations.list}
                            dropdownChange={onChangeFilter}
                            defaultValue={integration}
                        />{" "}
                    </li>
                </ButtonContainer>
            </div>

            <div className="row text-center">
                <Pagination
                    totalItemsCount={content.count}
                    content={PAGE_CONTENT}
                    pageNumber={content.pageNumber}
                    handlePageChange={(n) => handlePageChange(n)}
                />
                <CountDisplay
                    start={content.list.length == 0
                      ? content.list.length
                      : (content.pageNumber - 1) * PAGE_CONTENT + 1}
                    end={content.pageNumber * PAGE_CONTENT -
                      (content.list.length < PAGE_CONTENT
                          ? PAGE_CONTENT - content.list.length
                          : 0)}
                    total={content.count}
                />

                {fetchApiCall ? (
                    <Loader />
                ) : content.list.length ? (
                    <div className="content">
                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Course Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Campaign Info
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {content.list.map((item, i) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                <b>Course Id - </b>
                                                {item.courseId} <br />
                                                <b>Published Id - </b>
                                                {item.publishedId}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>Campaign - </b>
                                                {
                                                    courseIntegrations.map[
                                                        item.integration
                                                    ]
                                                }{" "}
                                                <br />
                                                <b>Campaign Code - </b>
                                                {item.campaignCode} <br />
                                                {item.isActivated
                                                    ? <b>Activated</b>
                                                    : <b>Deactivated</b>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Link
                                                    to={`/courseMapping?courseId=${item.courseId}`}
                                                >
                                                    Edit
                                                </Link>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                ) : (
                    <div>No Records Found</div>
                )}
            </div>
        </div>
    );
};

export default CourseMappingList;
