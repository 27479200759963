import React, { useState, useEffect } from "react";
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import "./changePassword.scss";
import user from "../../store/user";
import changePassword from "../../services/changePassword";
import fetchRecruiterList from "../../services/fetchRecruiterList";
import { toast, ToastContainer } from "react-toastify";
import Heading from "../../components/Heading";

// class ChangePassword extends Component

const ChangePassword = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cnfrmPassword, setCnfrmPassword] = useState("");
    const [apiCall, setApiCall] = useState(false);
    const [errors, setErrors] = useState([]);
    const [profile, setProfile] = useState({});
    const [recruiterId, setRecruiterId] = useState('')

    useEffect(() => {

        const id = new URLSearchParams(props.location.search).get('recid');
        setRecruiterId(id);
        (async () => {
            const data = await user.getProfile();
            const adminId = data.id
            setProfile(data)
            if(id) {
                try {
                    let rec = await fetchRecruiterList(adminId, {id: id, pageNumber:1, pageContent:1});
                    if(rec)
                        setEmail(rec.data[0].email)
                }
                catch(e) {
                    toast.error('Account not found')

                }
            }
            
        })();

    }, []);

    function onChange(key, ev) {
        if (key == "password") {
            setPassword(ev.target.value);
        }
        if (key == "cnfrm_password") {
            setCnfrmPassword(ev.target.value);
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        if(!email) {
            toast.error("Account not found");
            return;
        }
        if(password != cnfrmPassword) {
            setErrors([])
            toast.error("Password and Verification Password does not match")
            return;
        }
        const errors = validatePassword(password);
        if(errors.length > 0) {
            setErrors(errors);
            return;
        }
           
        let data = {
            recruiterId,
            password: password
        }

        setApiCall(true);
        try{
            const res = await changePassword(profile.id, data);
            setApiCall(false);
            setErrors([])
            toast.success("Password Updated Successfully");
            setPassword('')
            setCnfrmPassword('')
        }
        catch(err){
            setApiCall(false);
            toast.error("Account not found");
        }

    }

    function validatePassword(password) {
        var errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long")
        }
        if (password.search(/[a-z]/) < 0) {
            errors.push("Password must contain at least one lowercase letter.");
        }
        if (password.search(/[A-Z]/) < 0) {
            errors.push("Password must contain at least one uppercase letter.");
        }
        if (password.search(/[0-9]/) < 0) {
            errors.push("Password must contain at least one digit.");
        }
        if (password.search(/[@#$%^&*()_+|~=\`{}:";'<>\/-]/) < 0) {
            errors.push("Password must contain at least one symbol from @#$%^&*()_+|~-=`{}:\";'<>/");
        }
        return errors
    }

    return (

        <div className='change-password-wrapper'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="pageHeading">
                <Heading text="Change Recruiter Password" />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/changepasswordByAdmin.php?recid=${recruiterId}`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version'/>
                </a>
            </div>
            <hr />
            <div className="change-password">
                <form>
                    {
                        email && (
                            <div className='form-group'>
                                <label className="col-sm-2 left"> Email </label>
                                <div className="col-sm-6 left valueBox">
                                    <p>{email}</p>
                                </div>
                            </div>
                        )
                    }
                    
                    <div className='form-group'>
                        <label className="col-sm-2 left"> New Password* </label>
                        <div className="col-sm-6 left valueBox">
                            <InputBox type='password' value={password} placeholder='New Password' onChange={onChange.bind(null, "password")} />
                        </div>
                    </div>
                    
                    <div className='form-group'>   
                        <label className="col-sm-2 left"> Verify New Password* </label>
                        <div className="col-sm-6 left valueBox">
                            <InputBox type='password' value={cnfrmPassword} placeholder='Verify New Password' onChange={onChange.bind(null, "cnfrm_password")} />
                        </div>
                    </div>
                    
                    <div className="errors">
                        {
                            errors.map(e => (<div>{e}</div>))
                        }
                    </div>
                    <br />
                    <div className='actions'>
                        <Button skin='dark' disabled={apiCall || !email} loading={apiCall} text="Submit" clickButton={(e) =>  onSubmit(e) } />
                    </div>
                </form>
                
               
            </div>
        </div>
       
    )

}

export default ChangePassword;