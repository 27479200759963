import React, { useEffect, useState } from "react";
import user from "../../store/user";

import Pagination from "../../components/Pagination";
import CountDisplay from "../../components/CountDisplay";
import Heading from "../../components/Heading";
import { Table } from "semantic-ui-react";
import Moment from "react-moment";
import Loader from "../../components/Loader";

import fetchOrders from "../../services/fetchOrders";
import fetchOrdersCount from "../../services/fetchOrdersCount";

import "./orders.scss";

const PAGE_CONTENT = 20;
export const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getCount();
    getOrders(page);
  }, []);

  async function getOrders(pageNumber) {
    const profile = await user.getProfile();
    setLoader(true);

    try {
      const res = await fetchOrders(profile.id, {
        pageNumber,
        pageContent: PAGE_CONTENT,
      });
      console.log(res.data);
      setOrders(res.data.data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  async function getCount() {
    const profile = await user.getProfile();

    try {
      const res = await fetchOrdersCount(profile.id);
      setCount(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  function handlePageChange(n) {
    setPage(n);
    getOrders(n);
  }

  function getStatus(key) {
    const map = {
      0: "Initiated",
      3: "Success",
    };

    return map[+key] || "Failed";
  }

  return (
    <div className="wrapper">
      <Heading text="Orders" />
      <div className="row text-center">
        <Pagination
          totalItemsCount={count}
          content={PAGE_CONTENT}
          pageNumber={page}
          handlePageChange={(n) => handlePageChange(n)}
        />
        <CountDisplay
          start={(page - 1) * PAGE_CONTENT + 1}
          end={page * PAGE_CONTENT}
          total={count}
        />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="content">
          <Table fixed selectable className="mailers-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>ID</Table.HeaderCell>
                <Table.HeaderCell width={4}>Date</Table.HeaderCell>
                <Table.HeaderCell width={4}>Recruiter</Table.HeaderCell>
                <Table.HeaderCell width={3}>status</Table.HeaderCell>
                <Table.HeaderCell width={2}>Credits</Table.HeaderCell>
                <Table.HeaderCell width={3}>Amount</Table.HeaderCell>
                <Table.HeaderCell width={3}>Response</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orders.map((item) => (
                <Table.Row>
                  <Table.Cell>{item.id}</Table.Cell>
                  <Table.Cell>
                    <Moment format="DD-MM-YYYY hh:mm A">{item.created}</Moment>
                  </Table.Cell>
                  <Table.Cell>
                    {item.recname} ({item.recruiterId})
                  </Table.Cell>
                  <Table.Cell>{getStatus(item.status)}</Table.Cell>
                  <Table.Cell>
                    {(item.otherInformation || {}).quantity}
                  </Table.Cell>
                  <Table.Cell>{item.amount}</Table.Cell>
                  <Table.Cell>{item.err_msg}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};
