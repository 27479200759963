import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import Loader from '../../../components/Loader';
import TableItem from '../Components/TableItem';

import user from '../../../store/user';
import fetchRecruiterIncomplete from '../../../services/fetchRecruiterIncomplete';
import fetchRecruiterIncompleteCount from '../../../services/fetchRecruiterIncompleteCount';
import updateRecruiterProfile from '../../../services/updateRecruiterProfile';

import { RECRUITER_INCOMPLETE_EDIT } from '../../../models/routes';

import './RecruiterList.scss';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';

class RecruiterListContainer extends Component {
    state = {
        adminId: null,
        pageNumber: 1,
        pageContent: 50,
        lastId: 0,
        enteredSearchId: null,
        searchError: null,
        idMap: {},
        sortby: false,
        order: 'asc',
        lsortby: false,
        lorder: 'asc',
        recruiterList: [],
        total: 0,
        isLoading: false,
        isPageLoading: false,
        searchLink: null,
        searchText: '',
        isSeachDisabled: true,
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location) {
            if(this.props.history && this.props.history.action === 'POP') {
                this.onRouteChange(this.props);
                console.log(this.props);
            }
        }
    }

    onRouteChange(props) {
        let queryString = props.location.search;
        const obj = {};

        queryString = queryString.replace('?', '');
        queryString = queryString.split('&');
        for (let i = 0; i < queryString.length; i++) {
            const temp = queryString[i].split('=');
            obj[temp[0]] = temp[1];
        }

        const state = {
            pageNumber: 1,
            idMap: {}
        };

        let pageNumber = 1;
        if (obj['pg']) {
            pageNumber = obj['pg'];
        }
        state.pageNumber = pageNumber;

        let fetchData = false;
        if (obj['gt']) {
            fetchData = true;
            state.enteredSearchId = obj['gt'];
            state.lastId = obj['gt'];
            state.idMap[pageNumber] = {}
            state.idMap[pageNumber].first = obj['gt'];

            if (obj['lt']) {
                state.idMap[pageNumber].last = obj['lt'];
            }
            if (obj['st']) {
                state.lastId = obj['st'];
            }
        }

        if (obj['sortby']) {
            fetchData = true;
            state.sortby = true;
            if (obj['sortby'].trim().toLowerCase() === 'asc') {
                state.order = 'asc';
            } else if (obj['sortby'].trim().toLowerCase() === 'desc') {
                state.order = 'desc';
            }
        }

        if (obj['lsortby']) {
            fetchData = true;
            state.lsortby = true;
            if (obj['lsortby'].trim().toLowerCase() === 'asc') {
                state.lorder = 'asc';
            } else if (obj['lsortby'].trim().toLowerCase() === 'desc') {
                state.lorder = 'desc';
            }
        }

        if (fetchData) {
            state.isLoading = true;
            this.setState(state);
            const parameters = this.getParameters(state);
            this.getRecruiterList(parameters);
            this.getRecruiterCount();
        } else {
            state.recruiterList = [];
            state.idMap = {};
            state.total = 0;
            state.enteredSearchId = '';
            this.setState(state);
        }
    }

    componentDidMount = async () => {
        const profile = await user.getProfile();
        this.setState({ adminId: profile.id });

        const adminAccess = user.checkFeatureAccess(adminFeatureMapping.RECRUITER_INCOMPLETE);

        if (!adminAccess)
            window.location.href = '/notAdmin';
        
        this.onRouteChange(this.props);
    };

    getParameters = (state) => {
        const params = {
            pageNumber: state.pageNumber,
        };
        if (!state.sortby && !state.lsortby) {
            const mapData = state.idMap[state.pageNumber];
            if (mapData && !(!mapData.first && !mapData.last)) {
                if (mapData.first) {
                    params.gt = mapData.first;
                }
                if (mapData.last) {
                    params.lt = mapData.last;
                }
            } else {
                params.gt = state.lastId;
            }
            params.st = state.lastId;
        } else {
            if (state.sortby) {
                params.sortby = state.order;
            }
            if (state.lsortby) {
                params.lsortby = state.lorder;
            }
        }
        return params;
    };

    getRecruiterList = async (params) => {
        const response = await fetchRecruiterIncomplete(this.state.adminId, params);
        if (response.data) {
            this.setState({
                recruiterList: response.data,
                isLoading: false,
                isPageLoading: false,
                searchError: null,
            });

            if (response.data.length > 0) {
                const firstItemId = response.data[0].published_id;
                const lastItemId = response.data[response.data.length - 1].published_id;
                const state = this.state;
                state.idMap[state.pageNumber] = {
                    first: firstItemId,
                    last: lastItemId
                }
                this.setState(state);
            }
        }
    };

    getRecruiterCount = async () => {
        const params = {}
        if (!this.state.sortby && !this.state.lsortby) {
            params.gt = this.state.lastId;
        }
        const response = await fetchRecruiterIncompleteCount(this.state.adminId, params);
        if (response.data.total) {
            this.setState({ total: response.data.total });
        }
    };

    updateRecruiterType = async (recruiterId, recType) => {
        const response = await updateRecruiterProfile(this.state.adminId, recruiterId, { 'type': recType });
        
        if (response && response.status == 200) {
            const state = this.state;
            const list = state.recruiterList;
            let index = 0;
            for (const recruiter of list) {
                if (recruiter.id == recruiterId) {
                    list[index] = {
                        ...recruiter,
                        company_status: recType
                    }
                }
                index += 1;
            }
            this.setState({ recruiterList: list });
            toast.success('Profile updated successfully');
        } else {
            toast.error('Failed to update profile');
        }
    }

    handlePageChange = (page) => {
        const state = this.state;
        state.isPageLoading = true;
        state.pageNumber = page;
        this.setState(state);

        const params = this.getParameters(this.state);
        this.changeRoute(params);
        this.getRecruiterList(params);
    };

    checkIfNumeric = (text) => {
        return !isNaN(text) && !isNaN(parseInt(text));
    }

    onSearchHandler = () => {
        const state = this.state;
        if (state.enteredSearchId) {
            if (this.checkIfNumeric(state.enteredSearchId)) {
                state.isLoading = true;
                state.lastId = state.enteredSearchId;
                state.pageNumber = 1;
                state.sortby = false;
                state.lsortby = false;
                state.idMap = {}
                state.idMap[1] = {
                    first: state.lastId
                };
                this.setState(state);

                const params = this.getParameters(state)
                this.changeRoute(params);
                this.getRecruiterList(params);
                this.getRecruiterCount();
            } else {
                this.state.searchError = 'Invalid job id';
                this.setState(state);
            }
        } else {
            this.state.searchError = 'This field cannot be empty';
            this.setState(state);
        }
    }

    onKeyPressHandlerSearchJobId = (event) => {
        if(event.key === 'Enter') {
            this.onSearchHandler();
        }
    }

    onKeyPressHandlerSearch = (event) => {
        if(event.key == 'Enter') {
            const searchText = event.target.value;
            
            if(!searchText) {
                return;
            }

            let link = `${RECRUITER_INCOMPLETE_EDIT}?`;
            if (this.checkIfNumeric(searchText)) {
                link += 'recid=' + searchText
            } else {
                link += 'email=' + searchText
            }
            this.setState({ searchLink: link, isSeachDisabled: false });
            window.open(link, '_blank');
        }
    }

    handleSearchChange = (event) => {
        const searchText = event.target.value;
        this.setState({ searchText: searchText })

        if (!searchText) {
            this.setState({ isSeachDisabled: true });
            return;
        }

        let link = `${RECRUITER_INCOMPLETE_EDIT}?`;
        if (this.checkIfNumeric(searchText)) {
            link += 'recid=' + searchText
        } else {
            link += 'email=' + searchText
        }
        this.setState({ searchLink: link, isSeachDisabled: false });

    }

    handleOrderChange = (mode) => {
        const state = this.state;
        this.state.enteredSearchId = '';
        if (mode === 'linkedin') {
            state.lsortby = true;
            state.sortby = false;
            state.order = 'asc';
            if (state.lorder === 'asc') {
                state.lorder = 'desc'
            } else {
                state.lorder = 'asc'
            }
        } else if (mode === 'pic') {
            state.lsortby = false;
            state.sortby = true;
            state.lorder = 'asc';
            if (state.order === 'asc') {
                state.order = 'desc'
            } else {
                state.order = 'asc'
            }
        }

        state.isPageLoading = true;
        state.pageNumber = 1;
        state.idMap = {}
        state.idMap[1] = {
            first: state.lastId
        }
        this.setState(state);

        const parameters = this.getParameters(this.state);
        this.changeRoute(parameters);
        this.getRecruiterList(parameters);
        this.getRecruiterCount();
    };

    changeRoute = (parameters) => {
        let array = [];
        for (const key in parameters) {
            if (key === 'pageNumber') {
                array.push('pg=' + parameters[key]);
            } else if (key === 'gt') {
                array.push('gt=' + parameters[key]);
            } else if (key === 'lt') {
                array.push('lt=' + parameters[key]);
            } else if (key === 'st') {
                array.push('st=' + parameters[key])
            } else if (key === 'sortby' && this.state.sortby) {
                array.push('sortby=' + parameters[key]);
            } else if (key === 'lsortby' && this.state.lsortby) {
                array.push('lsortby=' + parameters[key]);
            }
        }

        array = array.join('&')
        this.props.history.push('/recruiter/recruiter_incomplete?' + array)
    }

    render() {
        const { isLoading, isPageLoading, recruiterList, total, pageNumber, pageContent, sortby, lsortby, order, lorder } = this.state;
        const tableHeading = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="one">Job Id</Table.HeaderCell>
                    <Table.HeaderCell width="two">Name</Table.HeaderCell>
                    <Table.HeaderCell width="two">Email</Table.HeaderCell>
                    <Table.HeaderCell width="two">Designation</Table.HeaderCell>
                    <Table.HeaderCell width="two">Organisation</Table.HeaderCell>
                    <Table.HeaderCell width="one">
                        <div
                            className="sortby-link"
                            onClick={() => {
                                this.handleOrderChange('linkedin');
                            }}
                        >
                            LinkedIn ({lsortby ? (lorder === 'asc' ? 'desc' : 'asc') : 'desc'}
                            )
                        </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width="one">
                        <div
                            className="sortby-link"
                            onClick={() => {
                                this.handleOrderChange('pic');
                            }}
                        >
                            Pic ({sortby ? (order === 'asc' ? 'desc' : 'asc') : 'desc'})
                        </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width="two">Recruiter Type</Table.HeaderCell>
                    <Table.HeaderCell width="one">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );

        const jobIdSearchContent = (
            <div className='job-search search__container'>
                <div className='search__container_item search_bar'>
                    <div>
                        <InputBox type='text' value={this.state.enteredSearchId} placeholder="Show jobs with id greater than: " onChange={(e) => {
                            this.setState({ enteredSearchId: e.target.value, searchError: false });
                        }} 
                        onKeyPress={this.onKeyPressHandlerSearchJobId}/>
                        <Button text='Search' skin='dark' clickButton={this.onSearchHandler} />
                    </div>
                    <p className={this.state.searchError ? 'error-text' : 'hidden'}>{this.state.searchError}</p>
            
                </div>
                <div>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/recruiter_incomplete.php`} target='_blank'>
                        <Button skin='dark' disabled={false} text='Use Old Version'/>
                    </a>
                </div>
            </div>
        )

        const searchButton = <Button text='Search' skin='dark' disabled={this.state.isSeachDisabled} clickButton={() => { this.setState({ searchText: '' }) }} />;

        let mainContent;
        if (isPageLoading) {
            mainContent = (
                <>
                    <Table>{tableHeading}</Table>
                    <Loader />
                </>
            );
        } else if (!recruiterList || recruiterList.length === 0) {
            mainContent = <></>;
        } else {
            mainContent = (
                <>
                    <div className='search__container'>
                        <div className='search__container_item'>
                            <InputBox value={this.state.searchText} type='text' placeholder="Search by email/recid" onChange={this.handleSearchChange} onKeyPress={this.onKeyPressHandlerSearch}/>
                            {this.state.isSeachDisabled ? searchButton : <a href={this.state.searchLink} target="_blank">{searchButton}</a>}
                        </div>    
                    </div>
                    <Table>
                        {tableHeading}
                        <tbody>
                            {recruiterList.map((item) => {
                                const data = {
                                    ...item,
                                    jobid: item.published_id,
                                    name: item.recname,
                                    pic: item.image_name_new,
                                    rec_type: item.company_status,
                                };
                                return <TableItem key={item.published_id} data={data} onProfileUpdate={this.updateRecruiterType} />;
                            })}
                        </tbody>
                    </Table>
                </>
            );
        }

        let topPageIndicators;
        let bottomPageIndicators;

        if (!isLoading && recruiterList.length > 0) {
            topPageIndicators = (
                <div className="row text-center">
                    <Pagination
                        totalItemsCount={total}
                        content={pageContent}
                        pageNumber={pageNumber}
                        handlePageChange={this.handlePageChange}
                    />
                    <CountDisplay
                        start={(pageNumber - 1) * pageContent + 1}
                        end={pageNumber * pageContent}
                        total={total}
                    />
                </div>
            );
            if (!isPageLoading) {
                bottomPageIndicators = (
                    <div className="row text-center pagination-bottom">
                        <Pagination
                            totalItemsCount={total}
                            content={pageContent}
                            pageNumber={pageNumber}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                );
            }
        } else {
            topPageIndicators = <div></div>;
            bottomPageIndicators = <div></div>;
        }

        return (
            <div className="page-content recruiter_incomplete">
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover
                />

                <div className="page-heading">
                    <Heading text="Recruiter Incomplete" />
                </div>

                {jobIdSearchContent}

                {isLoading ? <Loader /> : (
                    <>
                        {topPageIndicators}
                        <div className="row">{mainContent}</div>
                        {bottomPageIndicators}
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(RecruiterListContainer);
