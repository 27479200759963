export const seekerPlanDeactivationReasons = [
    {
        id: 0,
        name: 'Select Reason'
    },
    {
        id: 4,
        name: 'Testing Purpose'
    },
    {
        id: 5,
        name: 'Refund Issued'
    }
];