export const companyTypeObj = [
    {
        id: 0,
        name: 'Select Company Type'
    },
    {
        id: 1,
        name: 'Startup'
    },
    {
        id: 2,
        name: 'Public'
    },
    {
        id: 3,
        name: 'Forbes Global 2000'
    },
    {
        id: 4,
        name: 'Government'
    },
    {
        id: 5,
        name: 'Non-Profit'
    },
    {
        id: 6,
        name: 'Fortune 500'
    },
    {
        id: 7,
        name: 'Conglomerate'
    },
    {
        id: 8,
        name: 'MNC'
    },
    {
        id: 9,
        name: 'Other'
    }
]

export function getCompanyType() {
    return companyTypeObj;
}

export function getCompanyTypeFromId () {
    return companyTypeObj.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}