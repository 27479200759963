import axios from '../utils/axios';

export default function fetchResumeBuilderCandidate(params) {
    return (
        axios.get('/resumeBuilder/resume/resumeBuilderUsers', {
            params
        })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
