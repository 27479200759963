import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import EditPlacement from './Pages/EditPlacement'

export default () => (
	<Switch>
		<Route path="/postcandidate" component={EditPlacement} />
		<Route path="/editcandidate/:id" component={EditPlacement} />
	</Switch>
);
