import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../../components/Heading';
import Loader from '../../../components/Loader';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import TableItem from '../Components/TableItem';

import user from '../../../store/user';
import { getQueryParams } from '../../../utils/utilFunctions';
import getAllRecruiters from '../../../services/getAllRecruiters';
import getAllRecruitersCount from '../../../services/getAllRecruitersCount';
import disconnectSocials from '../../../services/disconnectSocials';

import './RecruiterList.scss';

const RecruiterList = props => {
    const inputRef = useRef(null);
    const [userProfile, setUserProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [recruiterList, setRecruiterList] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const pageContent = 50;

    useEffect(() => {
        async function getProfile () {
            const profile = user.getProfile();
            return profile;
        }

        getProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
                setUserProfile(userData);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        })
    }, []);

    // fetch recruiter list and total count when user profile has been fetched at the very start
    useEffect(() => {
        if (userProfile) {
            const params = getQueryParams(window.location.search);
            if (params.pg && parseInt(params.pg) > 0) {
                setPageNumber(params.pg);
                getRecruiterList(params.pg);
            } else {
                getRecruiterList();
            }
            getRecruiterListCount();
        }
    }, [userProfile]);

    // fetch recruiter list
    const getRecruiterList = async (page) => {
        setIsPageLoading(true);
        const params = {
            pageNumber: page ? page : pageNumber,
            pageContent: pageContent,
        };

        try {
            const response = await getAllRecruiters(userProfile.id, params);
            
            if (response.data && response.data.data && response.data.data.length > 0) {
                setRecruiterList(response.data.data);
            } else {
                setRecruiterList([]);
            }

            setIsPageLoading(false);
            setIsLoading(false);
        } catch (error) {
            // console.log(error);
        }
    };

    // fetch total recruiter count
    const getRecruiterListCount = async () => {
        try {
            const response = await getAllRecruitersCount(userProfile.id);
            setTotalResults(response.data.data.count);
        } catch (error) {
            setTotalResults(0);
        }
    };

    const handleDisconnectSocials = async (dataObj) => {
        try {
            const response = await disconnectSocials(userProfile.id, dataObj);
            
            if (response.status === 200) {
                if (dataObj.disconnectFacebook) {
                    toast.success('Facebook profile deleted successfully');
                } else if (dataObj.disconnectLinkedin) {
                    toast.success('LinkedIn profile deleted successfully');
                } else if (dataObj.disconnectTwitter) {
                    toast.success('Twitter profile deleted successfully');
                }

                // fetch list again
                getRecruiterList();
            } else {
                toast.error('Something went wrong. Please try again later');
            }
        } catch (error) {
            // console.log(error);
        }
    } 

    const redirectOnSearch = () => {
        const searchQuery = inputRef.current.value.trim();
            
        let profileEditLink = '';

        if (parseInt(searchQuery)) {
            // the search query is recruiter id
            profileEditLink = `/recruiter/recruiter_profile_mgtedit?recid=${searchQuery}`
        } else {
            // the search query is recruiter email
            profileEditLink = `/recruiter/recruiter_profile_mgtedit?email=${searchQuery}`
        }

        // opens the link in new tab
        window.open(profileEditLink);
    }

    // redirect to recruiter profile edit page on search
    const handleOnClickSearch = event => {
        if (inputRef && inputRef.current) {
            redirectOnSearch();
        }
    };

    // handles page change through the pagination component
    const handlePageChange = async (page) => {
        setPageNumber(page);
        window.history.replaceState({}, '', `/recruiter/recruiter_profile_mgt?pg=${page}`);
        await getRecruiterList(page);
    };

    // handles pressing Enter in the search input
    const handleOnSearchKeyPress = event => {
        if (event.key === 'Enter') {
            redirectOnSearch();
        }
    }

    let topPageIndicators;
    let bottomPageIndicators;

    if (!isLoading && recruiterList.length > 0) {
        topPageIndicators = (
            <div className='row text-center pagination-top'>
                <Pagination totalItemsCount={totalResults} content={pageContent} pageNumber={pageNumber} handlePageChange={handlePageChange} />
                <CountDisplay start={(pageNumber - 1) * pageContent + 1} end={pageNumber * pageContent} total={totalResults} />
            </div>
        );

        if (!isPageLoading) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination totalItemsCount={totalResults} content={pageContent} pageNumber={pageNumber} handlePageChange={handlePageChange} />
                </div>
            );
        }
    } else {
        topPageIndicators = <div></div>;
        bottomPageIndicators = <div></div>;
    }

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width='one'>ID</Table.HeaderCell>
                <Table.HeaderCell width='one'>Name</Table.HeaderCell>
                <Table.HeaderCell width='one'>Email</Table.HeaderCell>
                <Table.HeaderCell width='one'>Designation</Table.HeaderCell>
                <Table.HeaderCell width='one'>Organisation</Table.HeaderCell>
                <Table.HeaderCell width='one'>Actions</Table.HeaderCell>
                <Table.HeaderCell width='one'>Social Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    let mainContent;
    if (isLoading || isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        );
    } else if (!isLoading && (!recruiterList || recruiterList.length === 0)) {
        mainContent = <></>;
    } else {
        mainContent = (
            <Table>
                {tableHeading}
                <Table.Body>
                    {
                        recruiterList.map(item => {
                            return (
                                <TableItem 
                                    recruiter={item} 
                                    handleDisconnectSocials={handleDisconnectSocials} 
                                />
                            );
                        })
                    }
                </Table.Body>
            </Table>
        )
    }

    return (
        <div className="page-content recruiter_profile_mgt">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className="page-heading">
                <Heading text='Recruiter Profile Management' />
            </div>

            {topPageIndicators}

            <div className="row">
                <div className="search__container">
                    <InputBox placeholder="Search email or id" _ref={inputRef} onKeyPress={handleOnSearchKeyPress} />
                    <Button skin="dark" text="Search" clickButton={handleOnClickSearch}/>
                </div>
            </div>

            {mainContent}

            {bottomPageIndicators}
        </div>
    )
}

export default RecruiterList;