import React, { useEffect, useState } from 'react'

import './competitionAssessmentModal.scss';
import fetchAssessmentResponse from '../../../../services/fetchAssessmentResponse';
import { Header, Modal } from 'semantic-ui-react';

const CompetitionAssessmentModal = (props) => {

    const { adminId, jobId, seekerId } = props;
    const [assessmentResponse, setAssessmentResponse] = useState([]);

    useEffect(() => {

        if (seekerId) {
            getAssessmentResponse();
        }
    }, [seekerId])



    const getAssessmentResponse = async () => {

        const res = await fetchAssessmentResponse(adminId, jobId, seekerId, {
            associationPublished: jobId,
            seeker: seekerId,
            invocation: 2
        });
        setAssessmentResponse(res.data.data);
    }

    const isCorrect = (question, answer) => {
        return question['correctAnswer'].length == question['answer'].length && question['correctAnswer'].includes(answer);
    }

    return (
        <Modal
            className="competition-assessment-modal"
            open={props.open}
            onClose={props.onClose}
            size='medium'
        >

            <Header content="Assessment Details" />

            <Modal.Content scrolling>
                {assessmentResponse[0] && (
                    <div className='comp-assessment-response-container'>
                        {

                            assessmentResponse[0].sections.map(section => (
                                <div className='comp-section-container'>
                                    {
                                        section.questions.map((question, ind) => (
                                            <div className='comp-assessment-section' key={ind}>
                                                <div className='comp-assesssment-que'>
                                                    <p className='comp-assess-head'>Q{ind + 1}: </p>
                                                    <p>{question['question']}</p>
                                                </div>

                                                <div className='comp-assesssment-cr-ans'>
                                                    <p className='comp-assess-head'>Correct Answer: </p>
                                                    <p>
                                                        {
                                                            question['correctAnswer'].map((ans, i) => (
                                                                <p key={i}>
                                                                    {
                                                                        question['answerOptions'][ans]
                                                                    }
                                                                </p>
                                                            ))
                                                        }
                                                    </p>
                                                </div>

                                                <div className='comp-assesssment-cnd-ans'>
                                                    <p className='comp-assess-head'>Candidate Answer: </p>
                                                    <p>
                                                        {
                                                            question['answer'].map((ans, i) => (

                                                                <p key={i} className={`${isCorrect(question, ans) ? 'green' : 'red'}`}>
                                                                    {
                                                                        question['answerOptions'][ans]
                                                                    }
                                                                </p>


                                                            ))
                                                        }
                                                    </p>
                                                </div>

                                                <hr />

                                            </div>


                                        ))
                                    }
                                </div>

                            ))
                        }

                    </div>
                )}
            </Modal.Content>

        </Modal>
    )
}

export default CompetitionAssessmentModal;
