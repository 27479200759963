import React from 'react';
import PropTypes from 'prop-types';

import './ButtonContainer.css';

const buttonContainer = (props) => {
    return (
        <ul className="buttonContainer">
            <li>{props.heading}</li>
            {props.children}
        </ul>
    )
}

buttonContainer.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.any
}

export default buttonContainer;