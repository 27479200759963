import React from "react";
import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import user from '../../../store/user';

import { Table } from "semantic-ui-react";
import Moment from 'react-moment';

import getCreditListing from '../../../services/getCreditListing';
import fetchRecruiterId from '../../../services/fetchRecruiterId';

import { getQueryParams, getQueryString } from '../../../utils/utilFunctions';
import {
    RECRUITER_CREDIT_MANAGEMENT_ADD,
    RECRUITER_CREDIT_MANAGEMENT_EDIT,
    RECRUITER_CREDIT_MANAGEMENT_DISTRIBUTE
} from '../../../models/routes';
import "./styles.scss";

class Listing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            domain: '',
            pageSize: 50,
            totalCount: 0,
            pageNo: 1,
            list: [],
            newAddId: null,
            loading: true,
            profile: {}
        };
    }

    async componentDidMount() {
        const profile = await user.getProfile();
        this.setState({
            profile
        }, () => {
            this.fetchListing();
        })
    }

    async fetchListing() {
        this.setState({
            list: [],
            loading: true
        });
        const params = getQueryParams(window.location.search);

        const state = {
            search: params.search,
            domain: params.domain,
            pageNo: +params.pageNo || 1,
        };
        const resp = await getCreditListing(this.state.profile.id, state);
        const list = resp.data.data.credits;
        const totalCount = resp.data.data.total;

        if (!list.length && params.search) {
            let p = {};
            if (Number(params.search)) {
                p.id = params.search;
            } else {
                p.email = params.search;
            }

            const resp = await fetchRecruiterId(this.state.profile.id, p);
            if (resp.data && resp.data.id) {
                state.newAddId = resp.data.id;
            }
        }


        this.setState({
            ...state,
            list,
            totalCount,
            loading: false
        })
        // console.log(list);
    }

    handleKeyPress(ev) {
        // console.log(ev.which);
        if (ev.which == 13) {
            this.setState({
                pageNo: 1
            }, () => {
                this.onSubmit();
            })
        }
    }

    onChange(ev) {
        const { name, value } = ev.target;
        console.log(name, ev);
        this.setState({
            [name]: value
        });
    }

    onSubmit() {
        const { search, pageNo, domain } = this.state;
        const queryStr = getQueryString({
            search: (search || "").trim(),
            pageNo,
            domain: (domain || "").trim().replace("@", "")
        })
        console.log(queryStr);
        this.props.history.push(queryStr);
        this.fetchListing();
    }

    onClickSearch() {
        this.setState({
            pageNo: 1
        }, () => {
            this.onSubmit();
        });
    }

    onClickClear(key) {
        this.setState({
            [key]: '',
            pageNo: 1
        }, () => {
            this.onSubmit();
        });
    }

    handlePageChange(pageNumber) {
        this.setState({
            pageNo: pageNumber
        }, () => {
            this.onSubmit();
        })
    }

    add(rec_id) {
        this.props.history.push(RECRUITER_CREDIT_MANAGEMENT_ADD + "?rec_id=" + rec_id);
    }

    edit(id) {
        this.props.history.push(RECRUITER_CREDIT_MANAGEMENT_EDIT + "?id=" + id);
    }

    distribute(rec_id) {
        this.props.history.push(RECRUITER_CREDIT_MANAGEMENT_DISTRIBUTE + "?rec_id=" + rec_id);
    }

    render() {
        const { profile } = this.state;
        const isDistributeAllowed = (
            profile &&
            profile.adminPermissions &&
            profile.adminPermissions.credit_management &&
            profile.adminPermissions.credit_management.distribute
        );

        return (
            <div className="credit-management">
                <Heading text='Recruiter Credit Management' />
                <a className="right" href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/recruiter_credit_plan.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
                <br />
                <div className='row'>
                    <div className='left'>
                        <InputBox
                            placeholder='Enter Recruiter ID/Email'
                            value={this.state.search}
                            name="search"
                            onChange={this.onChange.bind(this)}
                            onKeyPress={this.handleKeyPress.bind(this)} />
                        <Button skin='dark' disabled={false} text='Search' clickButton={this.onClickSearch.bind(this)} />
                        <Button skin='light' disabled={false} text='Clear' clickButton={this.onClickClear.bind(this, 'search')} ></Button>
                    </div>
                    <div className='left'>
                        <InputBox
                            placeholder='Enter Domain'
                            name="domain"
                            value={this.state.domain}
                            onChange={this.onChange.bind(this)}
                            onKeyPress={this.handleKeyPress.bind(this)} />
                        <Button skin='dark' disabled={false} text='Search' clickButton={this.onClickSearch.bind(this)} />
                        <Button skin='light' disabled={false} text='Clear' clickButton={this.onClickClear.bind(this, 'domain')} ></Button>
                    </div>
                </div>
                <div className="row  text-center">
                    <Pagination totalItemsCount={this.state.totalCount} content={this.state.pageSize} pageNumber={this.state.pageNo} handlePageChange={this.handlePageChange.bind(this)} />
                    <CountDisplay start={(this.state.pageNo - 1) * this.state.pageSize + 1} end={this.state.pageNo * this.state.pageSize} total={this.state.totalCount} />
                </div>
                {
                    !this.state.loading && !this.state.list.length ?
                        <>
                            <div className="no-records">
                                No Records Found
                            </div>
                            {
                                this.state.newAddId && this.state.search ?
                                    <div className="no-records-add">
                                        No Record found for this recruiter please click here to add more credits
                                        <br />
                                        <Button skin='dark' text="Add" clickButton={this.add.bind(this, this.state.newAddId)} />
                                    </div> : null
                            }
                        </>
                        : null
                }
                {
                    this.state.list.length ?
                        <Table fixed selectable className="credit-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={2} >ID</Table.HeaderCell>
                                    <Table.HeaderCell width={6} >Email</Table.HeaderCell>
                                    <Table.HeaderCell width={4} >Credit Date</Table.HeaderCell>
                                    <Table.HeaderCell width={4} >Start Date</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>End Date</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Quoteline No</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Reason</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Credits</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Credits Used</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Distributed</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    this.state.list.map((item, i) => {
                                        return (

                                            <Table.Row>
                                                <Table.Cell>{item.rec_id} </Table.Cell>
                                                <Table.Cell>{item.email} </Table.Cell>

                                                <Table.Cell><Moment format='DD-MM-YYYY'>{item.credit_date}</Moment></Table.Cell>
                                                <Table.Cell><Moment format='DD-MM-YYYY'>{item.startDate}</Moment></Table.Cell>
                                                <Table.Cell><Moment format='DD-MM-YYYY'>{item.endDate}</Moment></Table.Cell>

                                                <Table.Cell>{item.quoteline_no}</Table.Cell>
                                                <Table.Cell>{item.reason}</Table.Cell>

                                                <Table.Cell>{item.credits_no}</Table.Cell>
                                                <Table.Cell>{item.used_credits}</Table.Cell>
                                                <Table.Cell>{item.distributed_credits}</Table.Cell>
                                                <Table.Cell>{item.status ? "Active" : "Inactive"}</Table.Cell>

                                                <Table.Cell>
                                                    {
                                                        isDistributeAllowed ?
                                                            <>
                                                                {
                                                                    (item.credits_no - item.used_credits - item.distributed_credits) > 0 ?
                                                                        <Button skin='dark' text="Edit" clickButton={this.edit.bind(this, item.id)} /> : null

                                                                }
                                                                <Button skin='dark' text="Add" clickButton={this.add.bind(this, item.rec_id)} />
                                                                <Button skin='dark' text="Distribute" clickButton={this.distribute.bind(this, item.rec_id)} />
                                                            </>
                                                            : null
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table> : null

                }
                <br />
                <br />
            </div>
        )
    }

}

export default Listing;