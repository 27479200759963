export const deliveryMethodObj = [
    {
        id: "Online",
        name: 'Online'
    },
    {
        id: 'Classroom',
        name: 'Classroom'
    },
    {
        id: 'Self Study',
        name: 'Self Study'
    },
    {
        id: 'Blended- Online',
        name: 'Blended- Online'
    },
    {
        id: 'Campus',
        name: 'Campus'
    },
    {
        id: 'Other',
        name: 'Other'
    }
]

export function getDeliveryMethod() {
    return deliveryMethodObj;
}