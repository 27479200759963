import React, { useState, useEffect } from 'react';
import user from '../../store/user';
import './reportList.scss';
import { Header, Modal, Table } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Heading from '../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import fetchReports from '../../services/fetchReports';
import fetchPremiumHistoryList from '../../services/fetchPremiumHistoryList';
import InputBox from '../../components/InputBox';
import { getQueryParams } from "../../utils/utilFunctions";
import moment from 'moment';
import { getCategoryMap } from '../../models/categories';
import { Link } from 'react-router-dom';
import { getPremiumStatusMap } from '../../models/premiumStatus';
import ActionIcons from '../../components/ActionIcons';
import FromToDatePicker from '../../components/FromToDatepicker';
import ButtonContainer from '../../components/ButtonContainer';

const PAGE_CONTENT = 50;

const filtersInitialState = {
  startDate: '',
  endDate: ''
}

const ReportsList = (props) => {

  const [content, setContent] = useState({
    list: []
  })

  const [modalData,setModalData] = useState({
    list: [],
    count: 0
  })

  const [admin, setAdmin] = useState({
    adminId: null,
    adminType: null
  })
  const [fetchApiCall, setFetchApiCall] = useState(true)

  const [searchRecId, setSearchRecId] = useState('')

  const [modalItem, setModalItem] = useState(null);
  const [showModal, setShowModal]= useState(false);

  const [pagination, setPagination] = useState({
    nextCursor: null,
    prevCursor: null,
    showPagination: true
  })
  const [filters, setFilters] = useState(filtersInitialState);

  const [showDateFilter, setShowDateFilter] = useState(false);

  
  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id,
          adminType: userData.adminType
        })
        getResult(userData.id);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])

  useEffect(() => {
    getPremiumHistoryList(admin.adminId);
  }, [modalItem]);


  const getReports = async (adminId, filters = {}, nextCursor, prevCursor) => {
    setFetchApiCall(true);
    const params = {pageContent:PAGE_CONTENT, nextCursor, prevCursor,  ...filters };
    try {
      routeRequest(params)
      const res = await fetchReports(adminId, params)
      setContent({
        list: res.data.data.data
      })
      setPagination(prev => ({
        ...prev,
          nextCursor: res.data.data['nxtCursor'] ,
          prevCursor: res.data.data['prevCursor']
      }))
      setFetchApiCall(false)
    }
    catch (err) {
      toast.error('Something went wrong');
      console.log(err);
      setFetchApiCall(false)
    }
  }
  const getPremiumHistoryList = async (adminId) => {

    if(modalItem && modalItem.id) {
      try{
        const resp = await fetchPremiumHistoryList(adminId, {jobId: modalItem['id']});
        setModalData({
          list: resp.data.data.data,
        })
      }
      catch(e) {
        console.log(e);
        toast('Something went wrong')
      }

    }

  }

  const handleModalClose = () => {
    setShowModal(false);
    setModalItem(null);
    setModalData(null);
};

  const onSearch = async () => {
      const filterObj = {}
      if(searchRecId.trim()) {
        filterObj['recId'] = searchRecId;
        setShowDateFilter(true);
      }

      if(filters.startDate && filters.endDate){
        filterObj['startDate'] = filters.startDate;
        filterObj['endDate'] = filters.endDate;
      }

      await getReports(admin.adminId, filterObj)
      routeRequest(filterObj)
  }

  const onClickClearSearch = async () => {
    setSearchRecId('');
    setShowDateFilter(false);

    await getReports(admin.adminId, {...filters})
  }

  const onClickClearFilter = async () => {
    setFilters(filtersInitialState);
    const filterObj = {};
    
    if(searchRecId.trim()) {
      filterObj['recId'] = searchRecId;
    }
    await getReports(admin.adminId, filterObj)
  }

  const routeRequest = (filters) => {
    let array = [];

    if(filters.recId){
      array.push(`recId=${filters.recId}`)
    }
    if(filters.startDate) {
      array.push(`startDate=${moment(filters.startDate).format()}`)
    }
    if(filters.endDate) {
      array.push(`endDate=${moment(filters.endDate).format()}`)
    }
    if(filters.nextCursor) {
      array.push(`next=${filters.nextCursor}`)
    }
    if(filters.prevCursor) {
      array.push(`prev=${filters.prevCursor}`)
    }
    array = array.join("&");
    props.history.push("?" + array);
};

  const changeHandler = (ev) => {
    setSearchRecId(ev.target.value)
  };

  const getResult = async (adminId) => {
    const queryObj = getQueryParams(props.location.search);
    setContent(prev => ({
      ...prev
    }))

    setPagination(prev => ({
      ...prev,
        nextCursor: queryObj['next'],
        prevCursor: queryObj['prev']
    }))
    setSearchRecId(queryObj.recId ? queryObj.recId : '');
    const startDate = queryObj.startDate && moment(queryObj.startDate).isValid() ? moment(queryObj.startDate).toDate() : '';
    const endDate = queryObj.endDate && moment(queryObj.endDate).isValid() ? moment(queryObj.endDate).toDate() : '';

    setFilters({
      startDate ,
      endDate ,
    })
    const filterObj = {};
    if(queryObj.recId){
      filterObj['recId'] = queryObj.recId;
    }
    if(queryObj.startDate) {
      filterObj['startDate'] = startDate;
    }
    if(queryObj.endDate) {
      filterObj['endDate'] = endDate;
    }

    await getReports(adminId, filterObj, queryObj['next'], queryObj['prev']);
  }

  const onEnterPress = async (e) => {
    if(e.key == 'Enter') {
       await onSearch();
    }
  }

  const getJobStatus = (jobDetails) => {
    let status = '';
    let lastUpdatedText = '';
    let tooltipMessage = '';

    if(jobDetails['publish']){
        status = 'Published';
        if(jobDetails['last_updated']){
            status = 'Published (But Updated)';
        }
    }
    else if(jobDetails['published_id'] != 0) {
        if(jobDetails['last_updated']){
            lastUpdatedText = '(But Updated)';
        }
        if(jobDetails['rejected']) {
            if(jobDetails['message'])
              tooltipMessage = jobDetails['message']
            status = 'Rejected'
        }
        else {
            //update later
            status = "Unpublished"
            if(jobDetails['message']){
                tooltipMessage = jobDetails['message']
            }
        }

        status += lastUpdatedText;
    }
    else{
        //update later
        if(jobDetails['rejected']){
            status = 'Rejected';
            tooltipMessage = jobDetails['message']
        }
        else {
            status = "Pending ";
            //update later
            if(jobDetails['message'] && (admin.adminType == 2 && admin.adminType == 3 || admin.adminType == 4)) {
                status += "But Rejected";
                tooltipMessage = jobDetails['message'];
            }

        }
    }

    return {
      status,
      tooltipMessage
    }

  }


  const onClickPremiumStatus = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setModalItem(item);
    setShowModal(true);
  }

  const loadNextPage = async (e) => {
    const filterObj = {};
      e.preventDefault();
      if(searchRecId){
        filterObj['recId'] = searchRecId ;
      }
      if(filters.startDate) {
        filterObj['startDate'] = filters.startDate ;
      }
      if(filters.endDate) {
        filterObj['endDate'] = filters.endDate ;
      }
      await getReports(admin.adminId, filterObj, pagination.nextCursor)
  }

  const loadPrevPage = async (e) => {
      const filterObj = {};
      e.preventDefault();
      if(searchRecId){
        filterObj['recId'] = searchRecId ;
      }
      if(filters.startDate) {
        filterObj['startDate'] = filters.startDate ;
      }
      if(filters.endDate) {
        filterObj['endDate'] = filters.endDate ;
      }
      await getReports(admin.adminId, filterObj, null, pagination.prevCursor)

  }

  const onClickDwnloadBtn = async () =>{

    const params = {
      download: true,
      nextCursor: pagination.prevCursor,
      pageContent: PAGE_CONTENT,
      recId: searchRecId,
      startDate: filters.startDate,
      endDate: filters.endDate
    }
    try {
      const res = await fetchReports(admin.adminId, params);
      var blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${process.env.REACT_APP_PLATFORM}_report.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch(error) {
      console.log(error)

      if(error.message && error.message.includes(422)) {
        toast.error('Please select both RecruiterId and Date Range');
        return;
      }
        toast.error('Something went wrong');
    }
  }

  const getActivityValue = (job, col, endPoint, status, jobId, title) => {

    let res;
    let excelCount = col == 'totalDownload' && job['excelCount'] > 0 ? '/Yes' : '';
    if(admin.adminType == 4) {
      res =  (job[col] > 0 ? job[col] : 0)+excelCount
      
    }

    if(endPoint == 'applications') {
      res = job[col] > 0 ? <Link to={`/${endPoint}?jobId=${jobId}&status=${status}`}>{job[col]}</Link> : 0
    }
    else {
      res =  job[col] > 0 ? <p><Link to={`/${endPoint}?pageNumber=1&actionId=${status}&jobId=${jobId}&title=${title}`}>{job[col]}</Link>{excelCount}</p> : 0+excelCount

    }

    return res;
  }

  return (
    <div className="report-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="report-list-heading">
        <div>
          <Heading text="Manager Dashboard" />
          <h3 className='download-note'>Note: For downloading report please select both recruiter id and date range</h3>
        </div>
        <div>
          {
            showDateFilter ? (
              <Button skin='dark' className="dwnld_report" disabled={false} text='Download Excel' clickButton={onClickDwnloadBtn}/>
            ) : null
          }

          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/mgr_job_reports_admin2.php`} target='_blank'>
              <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
          </a>
        </div>
        
      </div>

        <hr />
      <div className='search-container'>
            <InputBox
                name="recId"
                type="number"
                placeholder="Search Rec Id"
                value={searchRecId}
                onChange={changeHandler}
                onKeyPress={onEnterPress}
            />
            <Button
                text='Search'
                skin='dark'
                clickButton={onSearch}
            />
            <Button
                skin="light"
                text="Clear"
                clickButton={onClickClearSearch}
            ></Button>
        </div>

        {
          showDateFilter ? (
            <div className='filter-container'>
                    <ButtonContainer heading='Filter By Date: '>
                        <li key="postDate">
                            <FromToDatePicker
                                name="date"
                                startDate={filters.startDate}
                                endDate={filters.endDate}
                                handleChangeStart={(e) => setFilters({ ...filters, startDate: e })}
                                handleChangeEnd={(e) => setFilters({ ...filters, endDate: new Date(new Date(e).setHours(23, 59 ,59)) })}
                            />
                        </li>
                        <li>
                          <Button
                            text='Filter'
                            skin='dark'
                            clickButton={onSearch}
                            disabled={!filters.startDate || !filters.endDate} 
                          />
                          <Button skin="light" text="Clear Filter" clickButton={onClickClearFilter} />
                        </li>
                    </ButtonContainer>
                    
            </div>
          ) : null
        }

        

    
      <div className="row text-center">

      {
          pagination.showPagination ? (
              <div className="manager-report-pagination" >
                  <li>
                      <a href="#" onClick={loadPrevPage}>{'<<Prev'}</a>
                  </li>
                  <li>
                    {
                      pagination.nextCursor ? <a href="#" onClick={loadNextPage}>{'Next>>'}</a> : null
                    }
                      
                  </li>
              </div>
          ): null
      }

        {fetchApiCall ? (
          <Loader />
        ) : (
          content.list.length ? (
            <div className="content">
              <Table
                fixed
                className="mailers-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      Title
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Job Id
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Location
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      Min Exp
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      Max Exp
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      Min CTC
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      Max CTC
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Cat
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Posted By
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Posting Views
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Applications
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Shortlist
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Reject
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Save
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Resume Viewed
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Resume / Excel Downloaded
                    </Table.HeaderCell>
                    <Table.HeaderCell width={5}>
                      Job Current Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Recruiter Activity
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Job Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Premium Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Job Universe
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {content.list.map((item, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          { moment(item['publishedDate']).format('DD-MM-YY')}
                        </Table.Cell>
                        <Table.Cell>
                            <a target="blank" href={`${process.env.REACT_APP_CLIENT_URL}/j/${item['url']}`}>{item['title']}</a>
                        </Table.Cell>
                        <Table.Cell>
                            {item['published_id']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['Location']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['Min']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['Max']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['minsal']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['maxsal']}
                        </Table.Cell>
                        <Table.Cell>
                            {getCategoryMap()[item['catid']]}
                        </Table.Cell>
                        <Table.Cell>
                            <a target="blank" href={`${process.env.REACT_APP_CLIENT_URL}/r/${item['uid']}-${item['recname']}`}>{item['recname']}</a>
                            <br/>
                            {item['email']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['hits']}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['apply_count'] > 0 ? <Link to={`/applications?jobId=${item['published_id']}&status=all`}>{item['apply_count']}</Link> : 0} */}
                            {getActivityValue(item, 'totalApplications','applications','all',item['published_id'])}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['shortlisted'] > 0 ? <Link to={`/applications?status=1&jobId=${item['published_id']}`}>{item['shortlisted']}</Link> : 0} */}
                            {getActivityValue(item, 'shortlisted','applications','1',item['published_id'])}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['rejected'] > 0 ? <Link to={`/applications?jobId=${item['published_id']}&status=2`}>{item['rejected']}</Link> : 0} */}
                            {getActivityValue(item, 'rejectedApp','applications','2',item['published_id'])}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['save'] > 0 ? <Link to={`/applications?jobId=${item['published_id']}&status=3`}>{item['save']}</Link> : 0} */}
                            {getActivityValue(item, 'save','applications','3',item['published_id'])}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['totalView'] > 0 ? <Link to={`/activityDetails?pageNumber=1&actionId=4&jobId=${item['published_id']}&title=${item['title']}`}>{item['totalView']}</Link> : 0} */}
                            {getActivityValue(item, 'totalView','activityDetails','4',item['published_id'],item['title'])}
                        </Table.Cell>
                        <Table.Cell>
                            {/* {item['totalDownload'] > 0 ? <Link to={`/activityDetails?pageNumber=1&actionId=5&jobId=${item['published_id']}&title=${item['title']}`}>{item['totalDownload']}</Link> : 0} */}
                            {getActivityValue(item, 'totalDownload','activityDetails','5',item['published_id'],item['title'])}
                        </Table.Cell>
                        <Table.Cell>
                            { getJobStatus(item)['status'] }                         
                            { getJobStatus(item)['tooltipMessage'] ? <ActionIcons type="onHoldJob" tooltipMessage={getJobStatus(item)['tooltipMessage']}/> : null}
                        </Table.Cell>
                        <Table.Cell>
                            {item['totalActivity']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['premium_post'] ? 'Premium' : 'Normal'}
                        </Table.Cell>
                        <Table.Cell>
                            <a href="" onClick={(e) => onClickPremiumStatus(e,item)}>{item['everPremium'] ? 'Yes': 'No'}</a>
                        </Table.Cell>
                        <Table.Cell>
                          {item['crawledJob'] == 1 ? 'Yes': 'No'}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <Modal
                  className="premium-history-modal"
                  open={showModal}
                  onClose={handleModalClose}
                  size="large"
              >
                  {modalItem && modalData && (
                      <>
                          <Header content={modalItem['title']} />
                          {
                            modalData.list.length == 0 ? (
                              <div className='no-premium-records'>Sorry, no records found</div>
                            ) : (
                              <>
                                <Modal.Content scrolling>
                              {
                                  <>
                                     <div>
                                        <Table
                                          fixed
                                          selectable
                                          className="mailers-table"
                                        >
                                          <Table.Header>
                                            <Table.Row>
                                              <Table.HeaderCell width={3}>
                                                Date
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={6}>
                                                Status
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={6}>
                                                Recruiter
                                              </Table.HeaderCell>
                                              <Table.HeaderCell width={4}>
                                                UserType
                                              </Table.HeaderCell>
                                            </Table.Row>
                                          </Table.Header>

                                          <Table.Body>
                                            {modalData.list.map((item, i) => {
                                              return (
                                                <Table.Row>
                                                  <Table.Cell>
                                                      {moment(item['posted_date']).format('DD-MM-YYYY')}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      {`${getPremiumStatusMap()[item['status']]}`}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      { `${modalItem['recname']} (${item['rec_id']})`}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                      {item['rectype'] == 1 ? 'Recruiter' : item['rectype'] == 2 ? 'Admin': 'Script'}
                                                  </Table.Cell>
                                                </Table.Row>
                                              );
                                            })}
                                          </Table.Body>
                                        </Table>
                                      </div>
                                  </>
                              }
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        clickButton={handleModalClose}
                                        skin="light"
                                        text="Close"
                                    />
                                </Modal.Actions>
                              </>
                            )
                          }
                          
                      </>
                  )}
              </Modal>
            </div>
          ) : (
            <div>
              No Records Found
            </div>
          )
          
        )
        }
      </div>
    </div>
  );
};

export default ReportsList;