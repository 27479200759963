const organisationType = [
    { label: 'Select', value: '' },
    { label: 'Startup', value: 1 },
    { label: 'Public', value: 2 },
    { label: 'Private', value: 3 },
    { label: 'Government', value: 4 },
    { label: 'Non-Profit', value: 5 },
    { label: 'MNC', value: 6 },
    { label: 'SME', value: 7 },
    { label: 'Indian MNC', value: 8 },
    { label: 'Other', value: 9 },
    { label: 'Consultant', value: 10 },
];

export function getOrganisationTypeArr() {
    return organisationType;
}
