import React, { Component } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import { rejectJob } from '../../models/rejectJob';

import ActionIcons from '../ActionIcons';
import Modal from '../Modal';
import Aux from '../Aux';
import Dropdown from '../Dropdown';
import Button from '../Button';
import Input from '../InputBox';
import './TableItem.scss';
import '../../themes/grid.scss';
import ModalWrapper from '../../components/Modalv2';
import getSingleJobApplicationsCount from '../../services/getSingleJobApplicationsCount';
import { Checkbox } from 'semantic-ui-react';

var moment = require('moment');

class TableItem extends Component {

    state = {
        showRejectModal: false,
        rejectReason: '',
        unpublishReason: '',
        modalOpen:false,
        rejectReasonVal:'',
        recruiterId:'',
        applicationStats:'',
        appStatusView:'none',
        rejectReasonId:'',
        showSelectedCheckbox: false,
    }

    rejectDropdownChange = (event) => {
        var rejectJobReasonSelect=rejectJob.filter((eachReason)=>{if(eachReason.id==event.target.value)return true;return false;})
        this.setState({ rejectReason: rejectJobReasonSelect[0].msg , rejectReasonId: event.target.value })
    }

    rejectReasonChange = (event) => {
        this.setState({ rejectReason: event.target.value })
    }

    clickRejectButton = (jobId) => {
        if (this.state.rejectReason == '' || this.state.rejectReasonId == '') alert('Please enter reason!')
        else this.props.rejectJob(jobId, this.state.rejectReasonId, this.state.rejectReason)
    }

    unpublishRadioChange = (event) => {
        this.setState({ unpublishReason: event.target.value })
    }

    clickUnpublishButton = (jobId) => {
        if (this.state.unpublishReason == '') alert('Please choose a reason!')
        else this.props.unpublishJob(jobId, this.state.unpublishReason)
    }

    clickRejectTooltipButton = (message) => {
        this.setState({
            modalOpen:true,
            rejectReasonVal:message
        })
    }

    onCloseModal=() => {
        this.setState({
            modalOpen:false
        })
    }

    async applicationStats(jobId,recruiterId){
       
        if(!this.state.applicationStats)
        {
           await getSingleJobApplicationsCount(recruiterId,jobId)
           .then(res=>{
            const data=res.data;
              this.setState({
                applicationStats:data
              });
            
            });
       }
     console.log(this.state.applicationStats);
       this.state.appStatusView?this.setState({appStatusView:""}):this.setState({appStatusView:"none"});        
    }

    handleOnHover = event => {
        this.setState({
            showSelectedCheckbox: true,
        });
    }

    handleRemoveHover = event => {
        this.setState({
            showSelectedCheckbox: false,
        });
    }

    handleItemSelected = event => {
        this.props.onItemSelected(this.props.item.id);
    }

    render() {
        const item = this.props.item;
        let status = item.status;

        const before2Days = Date.now() - (2 * 24 * 60 * 60 * 1000);
        const showRefBtn = moment(item.publishedDate).valueOf() < before2Days;

        const updatetimestamp = moment.unix(this.props.item.timestamp).format("DD/MM/YYYY")
        // if(status=='published') status= 'Published \n' + item.id; 
        if(status=='updated-published') status = 'Published \n (But Updated)';
        if(status=='updated-unpublished') status = 'Unpublished \n (But Updated)';
        if(status=='pending' && item.message) status = 'Pending \n But Rejected';
        // if(status=='unpublished') status= 'Unpublished \n' + item.id; 

        const domainMapping={
            '1': ['blacklisted'],
            '2': ['paid posting'],
            '3': ['paid', 'premium posting '],
            '10': ['specialcasedomain'],
            '11': ['premium posting '],
        }

        let dotInfoTitle = {};
        dotInfoTitle = this.props.showDotInfo &&
        {
            'blacklisted':  'Blacklisted domain. DO NOT publish this job.',
            'paid posting':  'Paid Account',
            'paid':  'Paid Account',
            'premium posting ':  'Paid Client. Job should be marked premium.',
            'specialcasedomain':  'Special Case Domain',
            'premiumJob':  'Paid Jobs. To be posted on priority.',
            'premiumCompany':  'Jobs of branded/premium companies. To be posted on priority and rejection criteria can be eased out.',
            'verifiedRecruiter': 'This consultant has been verified.',
            'jobUniverseStatus': 'Job Universe Jobs'
        };

        let actionIcons = null;
        if(!this.props.premiumJobs){
        if (item.status == 'published' || item.status == 'updated-published') {
            actionIcons = (
                <Aux>
                    <ActionIcons type='deleteJob' onclick={() => this.props.deleteJob(item.id)} />
                    <Modal content='Unpublish Job' buttonText={<ActionIcons type='unpublishJob' />}>
                        <div className='modal-content'>
                            <div className='modal-subcontent'>
                                <div className='modal-heading'>Please choose a reason for unpublishing this job </div>
                                <label className='modal-unpublish-label' >
                                    <Input type='radio' name='unpublishJob' value='5' onChange={this.unpublishRadioChange}/>
                                    This position has been closed via {process.env.REACT_APP_PLATFORM}.
                                </label>
                                <label className='modal-unpublish-label'>
                                    <Input type='radio' name='unpublishJob' value='6' onChange={this.unpublishRadioChange}/>
                                    This position has been closed via other channel.
                                </label>
                                <label className='modal-unpublish-label'>
                                    <Input type='radio' name='unpublishJob' value='2' onChange={this.unpublishRadioChange}/>
                                    This position has gone on hold.
                                </label>
                                <label className='modal-unpublish-label'>
                                    <Input type='radio' name='unpublishJob' value='3' onChange={this.unpublishRadioChange}/>
                                    I have received enough responses for this position.
                                </label>
                            </div>
                            
                            <Button text='Unpublish' skin='light' clickButton={() => this.clickUnpublishButton(item.id)} />
                        </div>
                    </Modal>
                    {
                        showRefBtn ? 
                        <ActionIcons type='refreshJob' onclick={() => this.props.refreshJob(item.id)} /> : null                    
                    }
                </Aux>
            )
        }

        if (item.status == 'pending') {
            actionIcons = (
                <Aux>
                    <ActionIcons type='deleteJob' onclick={() => this.props.deleteJob(item.id)} />
                    {/* <ActionIcons type='rejectPost' onclick={() => this.props.rejectPost(item.id)} /> */}
                    <Modal content='Why rejected' buttonText={<ActionIcons type='rejectJob' />}>
                        <div className='modal-content'>
                            <div className='modal-subcontent'>
                                <label className='modal-label'>Select Reason</label>
                                <Dropdown dropdownChange={this.rejectDropdownChange} className='modal-dropdown' name='rejectJob' data={rejectJob} />
                            </div>
                            <div className='modal-subcontent'>
                                <label className='modal-label'> Enter Reason</label>
                                <textarea value={this.state.rejectReason} rows='5' maxLength='500' onChange={this.rejectReasonChange}>
                                </textarea>
                            </div>
                            <div className='modal-subcontent'>
                                Max 500 characters
                            </div>
                            <Button text='Reject' skin='light' clickButton={() => this.clickRejectButton(item.id)} />
                        </div>
                    </Modal>
                </Aux>
            )
        }

        if (item.status == 'rejected') {
            actionIcons = (
                <Aux>
                    <ActionIcons type='deleteJob' onclick={() => this.props.deleteJob(item.id)} />
                </Aux>
            )
        }

        if (item.status == 'unpublished') {
            actionIcons = (
                <Aux>
                    <ActionIcons type='deleteJob' onclick={this.props.deleteJob} />
                    <ActionIcons type='rejectJob' onclick={this.props.rejectJob} />
                    {/* <ActionIcons type='rejectPost' onclick={this.props.rejectJob} /> */}
                </Aux>
            )
        }
    }
        return (
            <div className='tableItem' id={item.id} onMouseEnter={this.handleOnHover} onMouseLeave={this.handleRemoveHover}>
                <div className='col-sm-5 px-0'>
                    <div className='head col-date hide768'>
                        {this.props.showSelect ? (
                            item.isSelected || this.state.showSelectedCheckbox ? <Checkbox checked={item.isSelected} className="is-selected" onChange={this.handleItemSelected}/> : null
                        ) : null}
                        <div className='newtextpara'>
                            <p>
                                <span className='glyphicon glyphicon-calendar visible-xs icon'></span>
                                <Moment format='DD-MM-YYYY hh:mm A'>{item.created}</Moment>
                            </p>
                        </div>
                    </div>
                    <div className='head col-title'>
                        <div className='newtextpara'>
                            <p className='smallviewtext title-container'>
                                {
                                    item.premium ?
                                        <span className="status_dot premiumjob" title={dotInfoTitle['premiumJob']}></span> : null
                                }
                                {
                                    item.premiumCompany ?
                                        <span className="status_dot premiumcompany" title={dotInfoTitle['premiumCompany']}></span> : null
                                }
                                {
                                    item.jobUniverseStatus ?
                                        <span className="status_dot jobUniverseJob" title={dotInfoTitle['jobUniverseStatus']}></span> : null
                                }
                                {
                                    domainMapping[item.domainStatus] ?
                                        <>
                                            {
                                                domainMapping[item.domainStatus].map(item => {
                                                    return  <span className={`status_dot ${item}`} title={dotInfoTitle[item]}></span>
                                                })
                                            }
                                        </> : null
                                }
                                <Link to={`/editjob/${item.id}`}>{item.title}
                                </Link>
                                {item.premium && item.status == 'pending' ?
                                    <span className='blinking-text'>Premium</span>
                                    : null
                                }
                            </p>
                            {
                                item.cnfi ? <span className='cnfi-text'>(This job is confidential)</span> : null
                            }
                            {
                                item.assessment ? <div style={{ fontSize: '12px', paddingTop: '2px' }}> * This job contains screening questions </div> : null
                            }
                        </div>
                    </div>
                </div>

                <div className='pull-left flleft  pdright10768 show768'>
                    <div className='head'>
                        <div className='newtextpara'>
                            <p><span className='glyphicon glyphicon-calendar visible-xs icon'></span>
                                <Moment format='DD-MM-YYYY hh:mm A'>{item.created}</Moment>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-sm-3 flleft pull-left  pdright10768'>
                    <div className='col-sm-6 flleft pull-left pdright10768'>
                        <div className='head'>
                            <div className='newtextpara'>
                                <p className="status-container">
                                    {/* <span className='glyphicon glyphicon-retweet visible-xs icon mtop2768'></span> */}
                                    <span className='status'>{status}</span> 
                                    {item.message && item.status!="published" && item.status != 'updated-published' ? 
                                        <ActionIcons type="onHoldJob" tooltipMessage={item.message} onclick={() => this.clickRejectTooltipButton(item.message)} positionTooltip='right center'/> : 
                                        null
                                    }
                                    {item.refreshStatus == 1 ? <span>(Refreshed)</span> : null}
                                    {(item.status=="updated-unpublished" || this.props.pendingRejectSort == 1) ?<span>Updated- {updatetimestamp}</span>:null
                                    }
                                    {item.publishId ? <div><Link to={`/job/${item.publishId}/visibility`}>{item.publishId}</Link></div> : null}
                                    
                                    {/* {item.publishId ?<div><a href={process.env.REACT_APP_CLIENT_URL+'/recruiter/job_visibility.php?id='+item.publishId}>{item.publishId}</a></div> :null} */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 flleft pull-left pdright10768'>
                        <div className='head'>
                            <div className='newtextpara viewedapply'>
                                <p>
                                    {
                                        item.views ?
                                            <span className='pdright10768'>
                                                <span className='glyphicon glyphicon-eye-open visible-xs icon mtop2768'></span>
                                                {item.views} views
                                            </span> : null
                                    }

                                    {
                                        item.totalApplications && item.publishId ?
                                            <span>
                                                <span className='glyphicon glyphicon-ok-circle visible-xs icon mtop2768'></span>
                                                <a title='Total Apply' className='clearblock' target='_blank' href={`/applications?jobId=${item.publishId}`}>
                                                    {item.totalApplications} Applied
                                                </a>
                                                <div onClick={()=>this.applicationStats(item.publishId,item.uid)} style={{"cursor":"pointer","color":"#337ab7","width":"20%"}}>{this.state.appStatusView?"View":"Hide"}</div>
                                                
                                                    <table style={{"display":this.state.appStatusView,"margin-left":"7px"}}>
                                                     {/* <tr>
                                                        <td>Applied</td>
                                                        <td>{this.state.applicationStats.total}</td>
                                                    </tr>  */}
                                                    <tr>
                                                        <td>Unread</td>
                                                        <td>: {this.state.applicationStats.total-(this.state.applicationStats.reviewed+this.state.applicationStats.shortlisted+this.state.applicationStats.rejected+this.state.applicationStats.save)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reviewed</td>
                                                        <td>: {this.state.applicationStats.reviewed}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shortlisted</td>
                                                        <td>: {this.state.applicationStats.shortlisted}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rejected</td>
                                                        <td>: {this.state.applicationStats.rejected}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saved</td>
                                                        <td>: {this.state.applicationStats.save}</td>
                                                    </tr>
                                                    </table>
                                                   
                                                
                                            </span> :
                                            null
                                    }
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='col-sm-4 col-xs-12 pull-left px-0 bordertop768 pdtop10768'>
                    <div className='head'>
                        <div className='newtextpara'>
                            <span className='stateBlock'>
                                <span className='state'>{item.location.join(', ')}</span>
                                <span className=' pdbtm3 pdtop5768' title='256'>
                                    <a target='_blank' title='click to view Profile' href={item.rurl}>{item.recruiterStatus == 1 ? item.email : ''} ({item.uid})</a>
                                </span>

                                {this.props.verified ?
                                    <span className='verified-recruiters' title={dotInfoTitle['verifiedRecruiter']}></span> 
                                    : null
                                }                                
                            </span>
                            {
                                this.props.adminType && this.props.adminType != 4 ? (
                                    <div className='actionIconsContainer'>
                                        {actionIcons}
                                    </div>

                                ) : null
                            }
                            
                        </div>
                    </div>
                </div>                   
                <ModalWrapper content="Why Rejected" modalOpen={this.state.modalOpen} onCloseModal={this.onCloseModal}>
                    <div>
                        {this.state.rejectReasonVal}            
                    </div>                 
                </ModalWrapper>
            </div>                                    
        )
    }
}

TableItem.propTypes = {
    rejectJob: PropTypes.func,
    unpublishJob: PropTypes.func,
    deleteJob: PropTypes.func,
    rejectPost:PropTypes.func,
    refreshJob: PropTypes.func,
    item: PropTypes.object,
    onItemSelected: PropTypes.func
}

export default TableItem;
