const convertMap = (inputMap) => {

    const processedArray = [];

    for(const property in inputMap) {
        processedArray.push({ 'id': property, 'name': inputMap[property] })
    }

    return processedArray;
    
}

export const getSource = (leadsMap) => {
   
    const sources = convertMap(leadsMap['source']);
    sources.unshift({ id: -1, 'name': 'Select Source' })

    return sources;
}

export const getProductType = (leadsMap) => {

    const productTypes = convertMap(leadsMap['productType']);
    productTypes.unshift({ 'id': -1, 'name': 'Select Product Type' })

    return productTypes;
}

export const getActivity = (leadsMap) => {

    const activities = convertMap(leadsMap['activity'])
    activities.unshift({'id' : -1, 'name': 'All (Both Click & Enquiry)'})

    return activities;
}

// export const getTagType = (leadsMap) => {

//     const tagTypes = convertMap(leadsMap['tagType']);
//     tagTypes.unshift({ 'id': -1, 'name': 'Select Tag Type' })

//     return tagTypes;
// }
