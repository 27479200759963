const companyTags = [
    { id: 1, value: 'InsurTech' },
    { id: 2, value: 'FinTech' },
    { id: 3, value: 'EdTech' },
    { id: 4, value: 'AgriTech' },
    { id: 5, value: 'CryptoCurrency' },
    { id: 6, value: 'BlockChain' },
    { id: 7, value: 'Fortune500' },
    { id: 8, value: 'Foreign MNC' },
    { id: 9, value: 'Marketplace' },
    { id: 10, value: 'Innovation Labs' },
    { id: 11, value: 'Soonicorn' },
    { id: 12, value: 'Decacorn' },
];

export const getCompanyTags = () => {
    return companyTags;
}