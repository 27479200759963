import React, { useEffect, useState } from 'react';
import fetchLGCollection from '../../../../services/lGBillingCrud/fetchLGCollection';
import user from '../../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import Heading from '../../../../components/Heading';
import ReactDatePicker from 'react-datepicker';
import InputBox from '../../../../components/InputBox';
import { currencyUnitsGlobal } from '../../../../models/currencyUnits';
import fetchBusinessLines from '../../../../services/lGBillingCrud/getBusinessLines';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Aux from '../../../../components/Aux'
import Button from '../../../../components/Button'
import moment from 'moment'
import "./Container.scss"
import scrollToElement from 'scroll-to-element';
import addLGCollection from '../../../../services/lGBillingCrud/addLGCollection';
import updateLGCollection from '../../../../services/lGBillingCrud/updateLGCollection'
import { adminFeatureMapping } from '../../../../models/adminFeatureMapping';

const LGCollectionContainer = (props) => {

    const addLGCollectionPath = "/addLGCollection" 
    const lGCollectionsListPath = "/lGCollections"

    const [loader, setLoader] = useState(false)
    const [adminType, setAdminType] = useState(null);
    const [adminId, setAdminId] = useState(null)
    const [addCollection, setAddCollection] = useState(true);

    const [currencyUnits, setCurrencyUnits] = useState(null)

    const [formValues, setFormValues] = useState({
        collectionData : null,
        collectionDate : null,
        businessLine : 0,
        availableBusinessLines: [],
        customerName: null,
        collectionAmount: null,
        invoiceNumber: null,
    })

    const [formValidation, setFormValidation] = useState({
        collectionDate : null,
        businessLine : null,
        customerName: null,
        collectionAmount: null,
        invoiceNumber: null,
    })

    const getCurrencyUnits = (amt) => {
        let formattedValue, formattedUnit;
        if(!amt){
            formattedValue = amt
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1)
        }
        else
        if (amt >= 10000000) {
            formattedValue = (amt / 10000000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 10000000);
          } else if (amt >= 100000) {
            formattedValue = (amt / 100000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 100000);
          } else if (amt >= 1000) {
            formattedValue = (amt / 1000);
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1000);
          } else {
            formattedValue = amt;
            formattedUnit = currencyUnitsGlobal.find(dict => dict.value === 1);
        }
        
        return `${formattedValue} ${formattedUnit.unit}`
    }

    const getLGCollection = async (adminId, billId) => {
        fetchLGCollection(adminId, { billId })
        .then(info => {
            if(info.data && info.data.data){
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["collectionDate"]: moment(info.data.data[0]["collectionDate"]).toDate(),
                    ["businessLine"] : info.data.data[0]["businessLine"],
                    ["customerName"]: info.data.data[0]["customerName"],
                    ["collectionAmount"]: info.data.data[0]["amount"],
                    ["invoiceNumber"]: info.data.data[0]["invoiceNumber"],
                    ["collectionData"]: info.data.data[0]
                }))
                const convertedAmount = getCurrencyUnits(info.data.data[0]['amount'])
                setCurrencyUnits(convertedAmount)
            }
        })
        .catch(err => {
            toast.error(err.response.data.message);
        });
    }

    const getBusinessLines = async(adminId) => {
        fetchBusinessLines(adminId, { })
        .then(res => {
            if(res.data){
                let resBuisenessLines = [{"id": 0, "name": "Select Business Line"}]
                Object.keys(res.data["BusinessLine"]).forEach(key => {
                    resBuisenessLines.push({"id": key, "name": res.data["BusinessLine"][key]})
                });
                setFormValues((prevState) =>({
                    ...prevState, 
                    ["availableBusinessLines"]: resBuisenessLines
                }))
            }
        })
        .catch(err => {
            toast.error(err.response.data.message);
        });
    }

    const handleCollectionDate = (date) =>{
        setFormValues((prevState) => ({
            ...prevState,
            ["collectionDate"]: date
        }))
    }

    const handleBusinessLineChange = (event) => {
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["businessLine"]: val
        }))
    }

    const handleCustomerNameChange = (event) =>{
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["customerName"]: val
        }))
    }

    const handleCollectionAmount = (event) => {
        let val = event.target.value
        const convertedAmount = getCurrencyUnits(val)
        setCurrencyUnits(convertedAmount)
        setFormValues((prevState) =>({
            ...prevState, 
            ["collectionAmount"]: val
        }))
    }

    const handleInvoiceNumber = (event) =>{
        let val = event.target.value
        setFormValues((prevState) =>({
            ...prevState, 
            ["invoiceNumber"]: val.trim()
        }))
    }

    const updateJob = () => {
        setLoader(true)
        const data = {
            "businessLine": formValues.businessLine,
            "customerName": formValues.customerName.trim(),
            "amount": formValues.collectionAmount,
            "collectionDate": moment(formValues.collectionDate).format('YYYY-MM-DDTHH:mm:ss'),
            "invoiceNumber": formValues.invoiceNumber
        }

        if(props.match.path == addLGCollectionPath){
            addLGCollection(adminId, data)
            .then(res => {
                if (res.data.status == 'success') {
                    toast.info('Collection addition success');
                    setTimeout(() => {
                        props.history.push(lGCollectionsListPath)
                        }, 1500);
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        else{
            updateLGCollection(adminId, props.match.params.id, data)
            .then(res => {
                
                if (res.data.status == 'success') {
                    toast.info('Collection updation success');
                    setTimeout(() => {
                        props.history.push(lGCollectionsListPath)
                        }, 1500);
                }
                // remove loader
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    toast.error(err.response.data.message);
                }
            })
        }
        setLoader(true)
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const validation = {
            collectionDate: (formValues.collectionDate != null && formValues.collectionDate <= moment()) ? false: true,
            businessLine: (formValues.businessLine != null && formValues.businessLine != 0)? false: true,
            customerName: formValues.customerName != null ? false: true,
            collectionAmount: formValues.collectionAmount != null && formValues.collectionAmount > 0 ? false: true,
            invoiceNumber: formValues.invoiceNumber != null? false: true
        }

        setFormValidation(validation);

        let flag = 1
        for (var prop in validation) {
            if (validation[prop] == true) flag = 0;
        }

        if(flag == 0){
            if (validation.collectionDate)
                scrollToElement('.collectionDate');
            if (validation.businessLine)
                scrollToElement('.businessLine');
            if (validation.customerName)
                scrollToElement('.customerName');
            if (validation.collectionAmount)
                scrollToElement('.collectionAmount');
            if(validation.invoiceNumber)
                scrollToElement('.invoiceNumber')
        }
        else{
            updateJob() 
        }
    }

    useEffect(()=>{
        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(profile => {
            if (profile.adminType == 2 || profile.adminType == 3) {
                const adminId = profile.id
                const type = profile.type;
                const adminType = profile.adminType;
                setAdminType(adminType)
                setAdminId(adminId)
                const billId = props.match.params.id
                getBusinessLines(adminId)
                if(props.match.path != addLGCollectionPath){
                    const adminAccess = user.checkFeatureAccess(adminFeatureMapping.LEARN_AND_GROW_UPDATE_BILLS);
                    if(!adminAccess)
                        window.location.href='/notAdmin';
                    getLGCollection(adminId, billId)
                    setAddCollection(false)
                }                     
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });
    }, [])

    return (
        <>
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />
            <div className='lGCollectionFormContainer'>
                <div className='lGCollectionFormHeadingContainer'>
                {
                    addCollection ? 
                    <>
                        <Heading text = "Add Collection"/>
                    </>
                    :
                    <Heading text = "Edit Collection"/>
                }
                </div>
                <form>
                    <div className='form-group collectionDate'>
                        <label className='col-sm-2 left'>Collection Date*</label>
                        <div className='col-sm-6 left valueBox'>
                            <ReactDatePicker selected={formValues.collectionDate} onChange={(date) => handleCollectionDate(date)}/>
                            {formValidation.collectionDate ? <p className='validatorError'> Please Enter valid collection date </p> : null}
                        </div>
                    </div>
                    <div className='form-group businessLine'>
                        <label className='col-sm-2 left'>Business Line*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container'>
                                <Dropdown className='block-dropdown' data={formValues.availableBusinessLines} name='available-business-lines' defaultValue={formValues.businessLine} dropdownChange={handleBusinessLineChange} />
                                {formValidation.businessLine ? <p className='validatorError'> Please Enter business line </p> : null}
                            </div>
                        </div>
                    </div>
                    <div className='form-group customerName'>
                        <label className='col-sm-2 left'>Customer Name*</label>
                        <div className='col-sm-6 left valueBox'>
                            <InputBox type='text' value={formValues.customerName} name='customer-name' onChange={handleCustomerNameChange}/>
                            {formValidation.customerName ? <p className='validatorError'> Please Enter customer name </p> : null}
                        </div>
                    </div>
                    <div className='form-group collectionAmount'>
                        <label className='col-sm-2 left'>Collection Amount*</label>
                        <div className='col-sm-6 left valueBox'>
                            <div className='block-dropdown-container lGCollectionAmountInput'>
                                <div className='block-dropdown'>
                                    <InputBox type='number' value={formValues.collectionAmount} name='collection-amount' onChange={handleCollectionAmount}/>
                                    {formValidation.collectionAmount ? <p className='validatorError'> Please Enter collection amount </p> : null}
                                </div>
                                <div className='block-dropdown right-dropdown'>
                                    <InputBox className='block-dropdown' value={currencyUnits} name='available-business-lines' placeholder = "units"/>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='form-group invoiceNumber'>
                        <label className='col-sm-2 left'>Invoice Number*</label>
                        <div className='col-sm-6 left valueBox'>
                            <InputBox type='text' value={formValues.invoiceNumber} name='invoice-number' onChange={handleInvoiceNumber}/>
                            {formValidation.invoiceNumber ? <p className='validatorError'> Please Enter invoice number </p> : null}
                        </div> 
                    </div>
                </form>    
                <div className='submitButtonContainer'>
                    <Aux>
                        <Button skin='dark' disabled={false} text={props.match.path == addLGCollectionPath?'Submit': 'Update'} clickButton={(e) => handleSubmit(e)} />
                    </Aux>
                </div>
            </div>
        </>
    );
};

export default LGCollectionContainer;