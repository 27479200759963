import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';

import Heading from '../../components/Heading';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import Loader from '../../components/Loader';

import user from '../../store/user';
import getSeekerAnalysis from '../../services/getSeekerAnalysis';
import getSeekerFollowUpHistory from '../../services/getSeekerFollowUpHistory';
import getJobseekerList from '../../services/getJobseekerList';
import addFollowupCredit from '../../services/addFollowupCredit';

import { getQueryParams } from '../../utils/utilFunctions';
import { SEEKER_FOLLOW_UP_MGT } from '../../models/routes';

import './styles.scss';

const RECRUITER_ACTIONS_MAP = {
    1: 'Shortlist',
    2: 'Reject',
    3: 'Save for Future',
    4: 'View',
    5: 'Download',
    6: 'Appcomment',
    7: 'Contact Info'
};

const JobseekerFollowUpMgt = props => {
    const [adminEmail, setAdminEmail] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [message, setMessage] = useState(null); 
    const [searchtext, setSearchtext] = useState('');
    const [followupData, setFollowupData] = useState(null); 
    const [statsData, setStatsData] = useState(null);
    const [seekerData, setSeekerData] = useState({
        name: null,
        email: null,
        id: null
    });

    const [showAddCredits, setShowAddCredits] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initiateAPI, setInitiateAPI] = useState(false);
    const [initiateAddAPI, setInitiateAddAPI] = useState(false);
    const [addCreditsData, setAddCreditsData] = useState({
        credits: '',
        comment: ''
    });
    
    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };
        
        getProfile().then(response => {
            if (response.adminType == 2 || response.adminType == 3) {
                setAdminId(response.id);
                setAdminEmail(response.email);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        }).catch(error => {
            // console.log(error);
        });
    }, []);

    useEffect(() => {
        if (adminId) {
            const params = getQueryParams(props.location.search);
            
            if (params.search) {
                setSearchtext(params.search);
                setInitiateAPI(true);
            }

            if (params.action && params.action === 'credit') {
                if (params.userid) {
                    setSeekerData({ id: params.userid });
                    setShowAddCredits(true);
                    setInitiateAddAPI(true);
                } else {
                    setShowAddCredits(false);
                    setMessage(['<div>Invalid user id</div>']);
                }
            } else {
                setShowAddCredits(false);
            }
        }
    }, [adminId]);

    useEffect(() => {
        if (initiateAPI) {
            setShowAddCredits(false);
            setIsLoading(true);
            getFollowupHistory();
            updateQueryParams();
            setInitiateAPI(false);
        }
        if (initiateAddAPI) {
            setIsLoading(true);
            getSeekerInfo();
        }
    }, [initiateAPI, initiateAddAPI]);

    const getParams = () => {
        const params = {};
        if (parseInt(searchtext)) {
            params['seekerId'] = searchtext;
        } else {
            params['seekerEmail'] = searchtext;
        }

        return params;
    };

    const getSeekerInfo = async () => {
        if (!seekerData || !seekerData.id) {
            return;
        }

        try {
            const response = await getJobseekerList(adminId, { id: seekerData.id });

            if (
                response.data.data &&
                response.data.data.users && 
                response.data.data.users.length > 0
            ) {
                const seekerEmail = response.data.data.users[0].email;
                const seekerName = response.data.data.users[0].name;

                 // capitalise seeker name 
                const seekerNameParts = seekerName.split(' ');
                let newSeekerName = '';

                seekerNameParts.forEach(word => {
                    if (word) {
                        newSeekerName += `${word[0].toUpperCase()}${word.substring(1, word.length)}` + ' ';
                    }
                });

                setSeekerData(prevObj => {
                    return {
                        ...prevObj,
                        email: seekerEmail,
                        name: newSeekerName,
                    }
                });
                setIsLoading(false);

            } else {
                setMessage(['<div>Invalid User Id</div>']);
            }
        } catch (err) {
            // console.log(err);
            setMessage(['<div>Invalid User Id</div>']);
        }
    };

    const getFollowupHistory = async () => {
        try {
            const response = await getSeekerFollowUpHistory(adminId, getParams());

            const data = response.data.data;

            if (!data) {
                setMessage(['This is not a registered user.']);
                setFollowupData(null);
                setSeekerData(null);
                setStatsData(null);
                setIsLoading(false);
                return;
            }

            const isProMember = response.data.data.isProMember;
            setFollowupData(response.data.data.last10Followups);

            getSeekerStats(response.data.data.seekerId).then(statsObj => {
                const remainingCredit = statsObj.ttl_follow - statsObj.u_follow;

                const messageArr = [];

                if (response.data.data.last10Followups && response.data.data.last10Followups.length > 0) {
                    if (!isProMember) {
                        messageArr.push('Currently user is not a pro-member.');
                    }
                } else if(remainingCredit) {
                    if (!isProMember) {
                        messageArr.push('Currently user is not a pro-member.');
                    }

                    messageArr.push(`Total follow ups: ${remainingCredit}`);
                } else {
                    if (!isProMember) {
                        messageArr.push('Currently user is not a pro-member.');
                    }
                    messageArr.push('No record found in follow-up.');
                }

                messageArr.push(`<div>If you want to credit follow-up for this user <a href='${SEEKER_FOLLOW_UP_MGT}?action=credit&userid=${response.data.data.seekerId}'>CLICK HERE</a></div>`)

                setMessage(messageArr);
            }).finally(() => {
                setIsLoading(false);
            });

            // capitalise seeker name 
            const seekerNameParts = response.data.data.seekerName.split(' ');
            let newSeekerName = '';

            seekerNameParts.forEach(word => {
                if (word) {
                    newSeekerName += `${word[0].toUpperCase()}${word.substring(1, word.length)}` + ' ';
                }
            });
            
            setSeekerData({
                id: response.data.data.seekerId,
                name: newSeekerName,
                email: response.data.data.seekerEmail
            });
        } catch (err) {
            if (err && err.response && err.response.status == 400) {
                toast.error('This is not a registered user.');
            } else {
                toast.error('Something went wrong. Please try again later!');
            }
            setIsLoading(false);
        }
    };

    const getSeekerStats = (seekerId) => {
        return new Promise( async (resolve, reject) => {
            try {
                const response = await getSeekerAnalysis(adminId, seekerId);
    
                setStatsData(response.data.data.data);
                return resolve(response.data.data.data);
            } catch (err) {
                // console.log(err);
                toast.error('Something went wrong. Please try again later!');
                return reject(err);
            }
        });
    };
 
    const validateSearchQuery = () => {

        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        // if search query is seeker id
        if (!isNaN(searchtext)) {
            return true;
        }

        // if search query is seeker email
        if (isNaN(searchtext) && emailRegex.test(searchtext)) {
            return true;
        }

        return false;
    };

    const handleSearchChange = event => {
        const value = event.target.value;
        setSearchtext(value);
    };

    const handleKeyPress = event => {
        if (event && event.key === 'Enter') {
            handleSearchSubmit();
        };
    };

    const handleSearchSubmit = () => {
        if (!adminId || !searchtext) {
            return;
        }
        
        if (validateSearchQuery()) {
            setInitiateAPI(true);
        } else {
            setMessage(['Invalid search query']);
        }
    };

    const handleSearchClear = () => {
        setSearchtext(''); // clear search input
        setIsLoading(false); // clear show loader
        setMessage(null); // clear error messages
        setFollowupData(null); // clear follow up data list
        setSeekerData(null); // clear seeker data
        setStatsData(null); // clear seeker stats
        setShowAddCredits(false); // hide add credits form
        setAddCreditsData({}); // clear add credits data
        setInitiateAPI(false); 
        setInitiateAddAPI(false);
        updateQueryParams(true); // remove all from query params
    };

    const handleAddCreditChange = (key, value) => {
        setAddCreditsData(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        });
    };

    const handleAddCredit = async () => {
        try {
            await addFollowupCredit(adminId, {
                seekerId: seekerData.id,
                recruiterEmail: adminEmail,
                recruiterId: adminId,
                creditAmt: addCreditsData.credits,
                comment: addCreditsData.comment
            });
            toast.success('Credits added successfully.');
            setAddCreditsData({
                credits: '',
                comment: ''
            });
        } catch (e) {
            // console.log(e);
            toast.error('Failed to add credits. Please try again later!');
        }
    };

    const updateQueryParams = (clearParams = null) => {
        const params = [];

        if (!clearParams && searchtext) {
            params.push(`search=${searchtext}`);
        }

        props.history.push(`${SEEKER_FOLLOW_UP_MGT}?${params.join('&')}`);
    }

    const tableHeading = (
        <Table.Header>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Followed-up Date</Table.HeaderCell>
            <Table.HeaderCell>Followed Job ID</Table.HeaderCell>
            <Table.HeaderCell>Recruiter Action</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Header>
    );

    let mainContent = null, seekerStatsContent = null;

    if (showAddCredits) {
        if (isLoading) {
            mainContent = <Loader />;
        } else {
            mainContent = (
                <div className='add_credit_form'>
                    <div className='heading'>Credit Follow-Up Seeker</div>
                    <div className='form-row'>
                        <div className='label'>User Name</div>
                        <div className='value'>{seekerData.email}</div>
                    </div>
    
                    <div className='form-row'>
                        <div className='label'>User Email</div>
                        <div className='value'>{seekerData.name}</div>
                    </div>
    
                    <div className='form-row'>
                        <div className='label'>Number of Credits</div>
                        <div>
                            <InputBox 
                                type="text"
                                value={addCreditsData.credits}
                                placeholder="Enter no. of credits to add" 
                                onChange={event => {
                                    const value = event.target.value;
                                    if (!isNaN(value)) {
                                        handleAddCreditChange('credits', value);
                                    } 
                                }}
                            />
                        </div>
                    </div>
    
                    <div className='form-row'>
                        <div className='label'>Comment</div>
                        <div>
                            <InputBox 
                                type="text"
                                value={addCreditsData.comment}
                                placeholder="Enter comment (if any)"
                                onChange={event => handleAddCreditChange('comment', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <Button skin="dark" text="Submit" clickButton={handleAddCredit}/>
                    </div>
                </div>
            );
        }
    } else {
        if (isLoading) {
            mainContent = <Loader />;
        } else if (!isLoading && followupData && followupData.length > 0) {
            if (statsData) {
                seekerStatsContent = (
                    <div className='seekerstats__container'>
                        <span className='heading'>Follow Up Credits Stats</span>
                        <span>Total Follow Up: {statsData.ttl_follow}</span>
                        <span>Credits Used: {statsData.u_follow}</span>
                        <span>Credits Left: {statsData.ttl_follow - statsData.u_follow}</span>
                    </div>
                );
            }
    
            mainContent = (
                <div className='followup__table'>
                    <div className='followup__table--head'>
                        <div className='heading'>Seeker Last 10 Follow-Up Information</div>
                        <span>{seekerData.name} {seekerData.email} ( {seekerData.id} )</span>
                    </div>
                    
                    <Table>
                        {tableHeading}
                        {
                            followupData ? (
                                <Table.Body>
                                    {followupData.map((item, id) => {
                                        const recruiterAction = item.recruiter_action_id ? RECRUITER_ACTIONS_MAP[item.recruiter_action_id] : '';
    
                                        return (
                                            <Table.Row>
                                                <Table.Cell>{id + 1}</Table.Cell>
                                                <Table.Cell>{item.followup_date}</Table.Cell>
                                                <Table.Cell>{item.jobid}</Table.Cell>
                                                <Table.Cell>{recruiterAction}</Table.Cell>
                                                <Table.Cell>{item.status == 1 ? 'Used' : 'Pending'}</Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            ) : null
                        }
                    </Table>
                </div>
            )
        }
    }

    return (
        <div className='page-content jobseeker-followup'>
            <ToastContainer 
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />

            <div className='page-heading'>
                <Heading text="Jobseeker Follow-Up Management" />

                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/jobseeker_followup_mgt.php`} target='__blank'>
                    <Button skin="dark" text="Use Old Version" />
                </a>
            </div>

            <div className='search__controller'>
                <InputBox
                    type="text"
                    placeholder="Enter seeker email or user ID" 
                    value={searchtext}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                />
                <div className='search__controller--actions'>
                    <Button skin="dark" text="Search" clickButton={handleSearchSubmit} />
                    <Button skin="light" text="Clear" clickButton={handleSearchClear} />
                </div>
            </div>
            
            <div className='page-body'>
                {message ? (
                    <div className='message_link'>
                        {
                            message.map(item => {
                                return <div dangerouslySetInnerHTML={{ __html: item }}></div>;
                            })
                        }
                    </div>
                ) : null}
                {!showAddCredits ? seekerStatsContent : null}
                {mainContent}
            </div>
        </div>
    );
};

export default JobseekerFollowUpMgt;