import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader'
import Button from '../../components/Button';

import user from '../../store/user';
import { getQueryParams } from '../../utils/utilFunctions';
import getSeekerChangePasswordLink from '../../services/getSeekerChangePasswordLink';

import './styles.scss';

const ChangeSeekerPassword = (props) => {
    const linkRef = useRef(null);
    const [adminId, setAdminId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [link, setLink] = useState(null);
    const [seekerData, setSeekerData] = useState({
        id: null,
        email: null
    });

    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        getProfile().then(response => {
            setAdminId(response.id);
        }).catch(err => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    useEffect(() => {
        if (adminId) {
            const params = getQueryParams(props.location.search);

            if (params.userid) {
                setSeekerData({
                    id: params.userid,
                    email: params.email
                });

                getSeekerChangePasswordLink(adminId, {
                    id: params.userid
                }).then(response => {
                    setLink(`${process.env.REACT_APP_CLIENT_URL}${response.data.data.link}`);
                    setIsLoading(false);
                }).catch (err => {
                    setLink(null);
                    setIsLoading(false);
                });
            }else {
                setIsLoading(false);
                setError(true);
            }
        }
    }, [adminId]);

    // to copy text to clipboard, create a virtual element with the text
    // select the text in that element and call the browser's copy command
    // then remove the element from the dom
    const handleCopyToClipboard = async () => {
        const el = document.createElement('textarea');
        el.value = link;
        document.body.append(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.success('Link Copied to Clipboard');
    }

    let mainContent = null;

    if (isLoading) {
        mainContent = <Loader />;
    } else if (!isLoading && link) {
        mainContent = (
            <div className='link__box'>
                <div className='link__box--input' ref={linkRef}>{link}</div>
                <Button skin="dark" text='Copy Link to Clipboard' className="link__box--btn" clickButton={handleCopyToClipboard}/>
            </div>
        );
    } else {
        mainContent = <div>Invalid User id</div>;
    }

    return (
        <div className='page-content change-seeker-password'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />

            <div className='page-heading'>
                <Heading text={`Please find Reset Password Link for Email: ${seekerData.email ? seekerData.email : ''}`} />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/changepasswordSeekerByAdmin.php?userid=${seekerData.id}&email=${seekerData.email}`} target="_blank">
                    <Button skin="dark" text="Use Old Version" />
                </a>
            </div>

            <div className='page-content'>
                {isLoading ? (
                    <Loader />
                ) : mainContent}
            </div>
        </div>
    )
};

export default ChangeSeekerPassword;
