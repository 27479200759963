import axios from '../utils/axios';

export default function checkSeekerExists(adminId, params) {
    return axios.get(`/admin/${adminId}/jobseeker-admin/v2/admin-recruiter/checkexistence`, { params })
        .then(response => {
            return response.data.data.user_exists;
        })
        .catch(err => {
            return false;
        });
}