import React from 'react';

import ChatFeed from '../ChatFeed';

const ChatListPage = () => (
  <ChatFeed />
)

ChatListPage.propTypes = {

}

export default ChatListPage;
