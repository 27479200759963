import axios from '../utils/axios';

export default function fetchRecruiterListCount(adminId, params) {
    return (
        axios.get('/admin/' + adminId + '/recruiters/search-count', { params })
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err;
            })
    )
}
