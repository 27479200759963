import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';

import user from '../../store/user';
import { getQueryParams } from '../../utils/utilFunctions';
import getPremiumJobsCount from '../../services/getPremiumJobsCount';
import getPremiumJobsList from '../../services/getPremiumJobsList';

import { PREMIUM_JOB_DETAIL } from '../../models/routes';;

import './styles.scss';

const USER_ACTIONS = {
    submit: 'SUBMIT',
    clear: 'CLEAR'
};

const PremiumJobListing = props => {
    const PAGE_CONTENT = 100;

    const inputRef = useRef(null);
    const [userAction, setUserAction] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [jobsData, setJobsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        // check query params
        const queryParams = getQueryParams(props.location.search);
        if (queryParams && queryParams.page) {
            setPageNumber(queryParams.page);
        }

        if (queryParams && queryParams.search) {
            setSearchText(queryParams.search);
        }

        getProfile().then(data => {
            setAdminId(data.id);
        }).catch(err => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    // get api call parameters
    const getParameters = () => {
        let recId = null, recEmail = null;

        if (searchText) {
            // check if entered text is a number
            if (parseInt(searchText)) {
                recId = parseInt(searchText);
            } else {
                if (searchText && searchText.length > 0) {
                    recEmail = searchText;
                }
            }
        }

        return {
            recId, 
            recEmail
        };
    };

    // get total count of results
    const getJobsCount = async () => {
        try {
            const params = {
                pageNumber,
                pageContent: PAGE_CONTENT,
                ...getParameters()
            };

            const response = await getPremiumJobsCount(adminId, params);
            setTotalCount(response.data.data.count);
        } catch (error) {
            setTotalCount(0);
        }
    };

    // get jobs list data
    const getJobsList = async (page) => {
        try {
            const params = {
                pageNumber: page || pageNumber,
                pageContent: PAGE_CONTENT,
                ...getParameters()
            };

            const response = await getPremiumJobsList(adminId, params);

            setJobsData(response.data.data);

        } catch (error) {
            setJobsData(null);
        }
        setIsPageLoading(false);
        setIsLoading(false);
    };

    // this fires when admin id is available
    useEffect(() => {
        if (adminId) {
            // get data
            if (!totalCount) {
                getJobsCount();
            }
            getJobsList();
        }
    }, [adminId]);

    // whenever data loads, check params and change route
    useEffect(() => {
        onRouteChange();
    }, [jobsData]);

    // fires when user takes an action
    useEffect(() => {
        if (userAction) {
            getJobsCount();
            getJobsList();

            setUserAction(null);
        }
    }, [userAction]);

    const handleOnChange = event => {
        if (event.target) {
            setSearchText(event.target.value);
        }
    };

    const handleOnKeyPress = event => {
        if (event.key && event.key === 'Enter') {
            handleSearch();
        }
    };

    // on click search button from search bar
    const handleSearch = async () => {
        // default back to page 1
        setIsLoading(true);
        setPageNumber(1);
        setUserAction(USER_ACTIONS.submit);
    };

    const handleClear = async () => {
        // dont set this as null because input wont recognise
        setSearchText('');
        setIsLoading(true);
        setPageNumber(1);
        setUserAction(USER_ACTIONS.clear);
    };

    // on page change from pagination component
    const handlePageChange = async page => {
        setIsPageLoading(true);
        setPageNumber(page);

        await getJobsList(page);
    };

    const onRouteChange = (page = null) => {
        const pageNum = page || pageNumber;
        
        const queryArr = [];
        if (pageNum) {
            queryArr.push(`page=${pageNum}`);
        }

        if (searchText && searchText.length > 0) {
            queryArr.push(`search=${searchText}`);
        }

        props.history.push(`?${queryArr.join('&')}`);
    }

    const tableHeading = (
        <Table.Header>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Header>
    );

    const searchContainer = (
        <div className='top_container'>
            <div className='search__container'>
                <div className='search__container--input'>
                    <InputBox 
                        placeholder="Search from Email or ID" 
                        _ref={inputRef}
                        value={searchText}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />
                </div>
                <div className='search__container--actions'>
                    <Button text="Search" skin="dark" clickButton={handleSearch}/>
                    <Button text="Clear" skin="light" clickButton={handleClear}/>
                </div>
            </div>
            <div className='search__container--old'>
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/premiumjoblist.php`} target="_blank" >
                    <Button text="Use Old Version" skin="dark" />
                </a>
            </div>
        </div>
    );

    let mainContent = null;

    if (isLoading || isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        );
    } else if (!isLoading && !jobsData) {
        mainContent = (
            <div>No Data Found!</div>
        );
    } else {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                    <Table.Body>
                        {jobsData.map(item => {
                            let title = null;
                            if (item.titleUrl) {
                                title = (
                                    <a href={`${process.env.REACT_APP_CLIENT_URL}${item.titleUrl}`} target="_blank">{item.title}</a>
                                );
                            } else {
                                title = <>{item.title}</>
                            }

                            const premiumText = (
                                <a href={`${PREMIUM_JOB_DETAIL}?id=${item.id}`} target="_blank">{item.premiumText}</a>
                            );

                            return (
                                <Table.Row>
                                    <Table.Cell>{item.postedDate || 'NA'}</Table.Cell>
                                    <Table.Cell>{title}</Table.Cell>
                                    <Table.Cell>{premiumText}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </>
        )
    }

    let topPageIndicators = null, bottomPageIndicators = null;

    if (!isLoading && jobsData && jobsData.length > 0) {
        topPageIndicators = (
            <div className='top-page-controller row text-center'>
                <Pagination totalItemsCount={totalCount} content={PAGE_CONTENT} pageNumber={pageNumber} handlePageChange={handlePageChange} />
            </div>
        );

        bottomPageIndicators = (
            <div className='bottom-page-controller row text-center'>
                <Pagination totalItemsCount={totalCount} content={PAGE_CONTENT} pageNumber={pageNumber} handlePageChange={handlePageChange} />
            </div>
        );
    }
    
    return (
        <div className='page-content premium-job-listing'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />
            
            <div className='page-heading'>
                <Heading text='Premium Jobs' />
            </div>

            <div>
                {searchContainer}
                {topPageIndicators}
                {mainContent}
                {bottomPageIndicators}
            </div>

        </div>
    );
};

export default PremiumJobListing;