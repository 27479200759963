import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';
import Button from '../../../../components/Button';
import CustomDropdown from '../CustomDropdown';
import Dropdown from '../../../../components/Dropdown';
import FileInputBox from '../../../../components/FileInputBox';
import Loader from '../../../../components/Loader';

import './styles.scss';

const acceptedFileFormats = ['.jpg', '.png', '.jpeg', '.pdf'];

const filetypes = [
    { id: -1, name: 'Select Document Type' },
    { id: 1, name: 'Company GST Certificate' },
    { id: 2, name: 'Permission to use company name on same Company letter head' },
    { id: 3, name: 'Company’s PAN/TAN Card copy' },
    { id: 4, name: 'PAN Card' },
    { id: 5, name: 'Aadhar Card' },
    { id: 6, name: 'Driving Licence' },
    { id: 7, name: 'Test Email Received' },
]

const UploadDocumentsModal = props => {
    const [fileType, setFileType] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [extraImage, setExtraImage] = useState(null); // use this for additional documents if needed
    const [errorObj, setErrorObj] = useState({});
    const fileInputRef = useRef(null);

    let selectedCompany = null;
    if (props.recruiterList && props.recruiterId) {
        props.recruiterList.map(aRow => {
            if (aRow.id == props.recruiterId) {
                selectedCompany = aRow.selectedCompany;
            }
        })
    }

    const setError = (key, value) => {
        setErrorObj(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        })
    }

    const handleDropdownChange = (e) => {
        const selectedFileType = e.target.value;

        if (selectedFileType == -1) {
            setFileType(null);
            setSelectedImage(null);
            return;
        }

        setFileType(selectedFileType);
        setError('image', null);
    };

    const handleFileSelected = (type, fileData) => {
        if (fileData) {
            setError('image', null);
            setSelectedImage(prevObj => {
                return {
                    type: type,
                    file: fileData
                };
            });
        }
    };

    // clear selected company before closing
    const handleCloseModal = () => {
        props.onItemSelected(null, null, props.recruiterId);
        props.onCloseModal();
    };

    const handleSubmit = () => {
        if (selectedCompany && selectedImage) {
            const formData = new FormData();
            formData.append('document', selectedImage.file);
            formData.append('documentType', selectedImage.type);
            formData.append('recruiterId', props.recruiterId);
            formData.append('displayName', selectedCompany.displayName);
            formData.append('companyId', selectedCompany.companyId);
            
            props.onDocumentUpload(formData);
        } else {
            if (!selectedCompany) {
                setError('company', 'Please select a company from dropdown');
            }
            if (!selectedImage) {
                setError('image', 'Please upload the document for verification')
            }
        }
    };

    const uploadContent = [];

    if (fileType) {
        uploadContent.push((
            <div className='row'>
                <FileInputBox
                    fileType={fileType}
                    placeholder="Select document"
                    allowedExtensions={acceptedFileFormats}
                    fileSizeLimitInMb={5}
                    onFileSelect={handleFileSelected}
                />
            </div>
        ));

        if (fileInputRef == 4 || fileType == 5 || fileType == 6) {
            // push if any other document also required.
        }
    }

    return (
        <Modal 
            className="upload-document-modal" 
            open={props.openModal} 
            onClose={handleCloseModal}>
            <Modal.Header>Upload Documents for Verification</Modal.Header>
            <Modal.Content className="upload-document-modal-content">
                {
                    props.isLoading ? (
                        <Loader />
                    ) : (
                        <div className="content_container">
                            <div className="select_data">
                                <div className="row row-select-company">
                                    <label>Select Company: </label>
                                    <CustomDropdown
                                        placeholder="Select Verified Company"
                                        className="company_dropdown"
                                        dropdownList={props.companyData.companyList.map(item => {
                                            return {
                                                label: item.displayName,
                                                value: item.companyId
                                            }
                                        })}
                                        isLoading={props.companyData.isLoading}
                                        onInputChange={text => {
                                            props.onInputChange(text);
                                        }}
                                        isSelected={selectedCompany}
                                        onItemSelected={(id, label) => props.onItemSelected(id, label, props.recruiterId)}
                                        clearList={props.clearList}
                                        noResultFound={props.companyData.noResultFound}
                                        clearSelected={() => props.onItemSelected(null, null, props.recruiterId)}
                                    />
                                    {errorObj.company ? <div className="error">{errorObj.company}</div> : null}
                                </div>

                                <div className="row row-file-upload">
                                    <label>Select document to upload:</label>
                                    <Dropdown data={filetypes} dropdownChange={handleDropdownChange} name="select-file-type" />
                                    {uploadContent}

                                    {errorObj.image ? <div className="error">{errorObj.image}</div> : null}

                                    <div className='upload-guide'>
                                        <div>Accepted file formats for the document are: {acceptedFileFormats.join(',')}</div>
                                        <div>Please select files smaller than 5Mb</div>
                                    </div>
                                </div>
                            </div>

                            {selectedImage && selectedImage.file && !selectedImage.file.type.includes('pdf') ? (
                                <div className="selected_image">
                                    <img src={URL.createObjectURL(selectedImage.file)} />
                                </div>
                            ) : null}
                        </div>
                    )
                }
            </Modal.Content>
            <Modal.Actions className="upload-document-modal-actions">
                <Button text="Upload & Verify" skin="dark" clickButton={handleSubmit} />
                <Button text="Close" skin="dark" clickButton={handleCloseModal} />
            </Modal.Actions>
        </Modal>
    );
};

UploadDocumentsModal.propTypes = {
    isLoading: PropTypes.bool,
    openModal: PropTypes.bool,
    recruiterId: PropTypes.number,
    onCloseModal: PropTypes.func.isRequired,
    companyData: PropTypes.array,
    onInputChange: PropTypes.func,
    clearList: PropTypes.func,
    onDocumentUpload: PropTypes.func,
    onItemSelected: PropTypes.func,
    clearSelected: PropTypes.func,
    recruiterList: PropTypes.array
};

export default UploadDocumentsModal;
