import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import InputBox from '../../../../../components/InputBox';
import PillButton from '../../../../../components/PillButton';
import Dropdown from '../../../../../components/Dropdown';
import Button from '../../../../../components/Button';
import Spinner from '../../../../../components/Spinner';
import PillSelect from '../../../../../components/PillSelect';
import InputDropdown from '../InputDropdown';

import fetchRecruiterProfile from '../../../../../services/fetchRecruiterProfile';
import getAllCompanies from '../../../../../services/getCompanyManagement';

import { natureOfOfferings } from '../../../../../models/natureOfOfferings';
import { getOrganisationTypeArr } from '../../../../../models/organisationType';
import { getNumberOfEmployees } from '../../../../../models/numberOfEmployees';
import { getEstimatedRevenueArr } from '../../../../../models/estimatedRevenue';
import { getFundingStage } from '../../../../../models/fundingStage';
import { getSortedHeadquarterCityList } from '../../../../../models/headquarterCity';
import { getCompanyTags } from '../../../../../models/companyTags';
import { getCompanyBenefits } from '../../../../../models/companyBenefits';
import { getCompanyAwards } from '../../../../../models/companyAwards';
import { getRecruiterTypeArr } from '../../../../../models/recruiterType';

import './CompanyForm.scss';

const natureOfOfferingsArr = natureOfOfferings.filter(item => {
    if ([1,2,3,6].indexOf(item.id) > -1) {
        return true;
    }
    return false;
}).map(item => ({ label: item.name, value: item.id }));

const possiblePremiumRanks = [
    {
        id: -1,
        name: 'None'
    },
    {
        id: 11,
        name: 11
    },
    {
        id: 12,
        name: 12
    },
    {
        id: 13,
        name: 13
    },
    {
        id: 14,
        name: 14
    },
    {
        id: 15,
        name: 15
    },
    {
        id: 16,
        name: 16
    },
];

const possiblePremiumRanksiimjobs = [
    {
        id: -1,
        name: 'None'
    },
    {
        id: 11,
        name: 11
    },
    {
        id: 12,
        name: 12
    },
    {
        id: 13,
        name: 13
    },
    {
        id: 14,
        name: 14
    },
    {
        id: 15,
        name: 15
    },
    {
        id: 16,
        name: 16
    },
    {
        id: 17,
        name: 17
    },
    {
        id: 18,
        name: 18
    }
];

const tooltipText = {
    name: 'Example - For Meesho, the legal name would be Fashnear Technologies Pvt. Ltd.',
    displayName: 'Example - Meesho will be the display name',
    domains: 'Add all the relevant domains for the company',
    type: 'Direct Company / Recruitment Firm/Individual Consultant',
    industry: 'Please select the core industry the company works in',
    gcc: 'Captive centers, also known as global in-house centers or global capability centers (GCCs) are client-owned, client-operated delivery centers. Global Captive centers typically function in a non-domestic, talent-rich and low-cost location, providing business-critical outcomes directly to the parent company',
    premiumRanks: '11 to 16, 16 being the most premium',
    premiumRanksIimjobs: '11 to 18, 18 being the most premium',
    groupCompany: 'Is this company a parent company or a subsidiary of another company?'
}

const CompanyForm = (props) => {
    const [options, setNewOption] = useState([]);
    const [parentCompanyControl, setParentCompanyControl] = useState({
        options: null,
        search: '',
        timerId: null,
        isLoading: true,
        fetch: false,
    });

    const [isGroupCompany, setIsGroupCompany] = useState(false);
    const [checkDomains, setCheckDomains] = useState(false);
    const [domainError, setDomainError] = useState(false);

    const industryRef = useRef(null);

    const [adminId, setAdminId] = useState('');
    const [isAdminLoading, setIsAdminLoading] = useState(false);
    const [adminDetails, setAdminDetails] = useState({
        name: '',
        email: '',
        platform: process.env.REACT_APP_PLATFORM,
        isLoading: false
    });

    const [showAdminForm, setShowAdminForm] = useState(false);

    const handleSearchAdmin = async () => {
        // return if api call already in progress
        if (isAdminLoading) {
            return;
        }

        setIsAdminLoading(true);

        try {
            const recruiterProfile = await fetchRecruiterProfile(props.adminId, {
                id: adminId
            });

            if (recruiterProfile && recruiterProfile.data) {
                setShowAdminForm(true);
                setAdminId(recruiterProfile.data.id);
                setAdminDetails({
                    email: recruiterProfile.data.email,
                    name: recruiterProfile.data.name,
                    platform: process.env.REACT_APP_PLATFORM
                });

                props.setData({
                    key: 'adminDetails',
                    value: {
                        id: adminId,
                        email: recruiterProfile.data.email,
                        name: recruiterProfile.data.name,
                        platform: process.env.REACT_APP_PLATFORM
                    }
                })
            } else {
                toast.error('Could not find admin profile!');
            }
        } catch (error) {
            toast.error('Could not find admin profile!');
        }

        setIsAdminLoading(false);
    };

    const handleClearAdmin = () => {
        setShowAdminForm(false);
        setAdminId('');

        props.setData({
            key: 'adminDetails',
            value: {}
        });
    }

    useEffect(() => {
        if (checkDomains) {
            if (!props.data.domains || (props.data.domains && props.data.domains.length == 0)) {
                setDomainError([]);
                return;
            }

            getAllCompanies(props.adminId, {
                domainsIn: props.data.domains.join(','),
                status: 1,
                pageNumber: 1,
                pageContent: 50
            }).then(response => {
                const companies = response.data.data.companies;
                if (companies) {
                    const errorDomains = [];

                    companies.forEach(company => {
                        if (company && company.domains && company.domains.length > 0) {
                            props.data.domains.forEach(domain => {
                                if (props.editMode && company.id == props.data.id) {
                                    return;
                                }
                                if (company.domains.indexOf(domain) > -1 && errorDomains.indexOf(domain) == -1) {
                                    errorDomains.push(domain);
                                }
                            })
                        }
                    })
                    if (errorDomains.length > 0) {
                        setDomainError(errorDomains);
                    } else {
                        setDomainError([]);
                    }

                    props.setData({
                        key: 'errorDomains',
                        value: errorDomains,
                    })
                }
            }).catch(error => {
                setDomainError([]);
            });
        }

        return () => {
            setCheckDomains(false);
        }
    }, [checkDomains]);

    useEffect(() => {
        var arr = [];
        (props.data.domains || []).forEach(element => {
            arr.push({ text: element, value: element });
        });

        setNewOption(arr);

        if (props.data.groupId) {
            setIsGroupCompany(true);
        }

        // if admin details are already present, then set in the form
        if (props.data.adminDetails && props.data.adminDetails.id) {
            setAdminDetails({
                email: props.data.adminDetails.email,
                name: props.data.adminDetails.name,
                platform: props.data.adminDetails.platform,
            });
            setAdminId(props.data.adminDetails.id);
            setShowAdminForm(true);
        }
    }, []);

    // get company suggestions
    const getCompanySuggestions = () => {
        getAllCompanies(props.adminId, {
            nameLike: parentCompanyControl.search,
            groupCompaniesOnly: true,
            pageNumber: 1,
            pageContent: 50
        }).then(response => {
            if (response && response.data && response.data.data && response.data.data.companies) {
                const companies = response.data.data.companies;

                if (!companies || (companies && companies.length == 0)) {
                    setParentCompanyControl(prevObj => {
                        return {
                            ...prevObj,
                            options: [],
                            isLoading: false,
                            timerId: null,
                            fetch: false
                        }
                    })
                    return;
                }
                const opts = companies.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                });

                setParentCompanyControl(prevObj => {
                    return {
                        ...prevObj,
                        options: opts,
                        isLoading: false,
                        timerId: null,
                        fetch: false
                    }
                })
            }
        }).catch(error => {
            setParentCompanyData(prevObj => {
                return {
                    ...prevObj,
                    options: null,
                    timerId: null,
                    fetch: false,
                };
            });
        })
    };

    // fire when timeout finishes
    useEffect(() => {
        if (parentCompanyControl.fetch) {
            getCompanySuggestions();
        }
    }, [parentCompanyControl.fetch]);

    // will fire everytime when search value changes, if timer id is not set, then it will create a timeout
    // if timer id already exists, return
    // if search is cleared, reset control
    useEffect(() => {
        if (!parentCompanyControl.search) {
            setParentCompanyControl({
                options: null,
                search: '',
                timerId: null,
            });

            return;
        }

        if (parentCompanyControl.timerId) {
            console.log('timer already active');

            return;
        }

        const timerId = setTimeout(() => {
            setParentCompanyData('fetch', true);
        }, 1000);

        setParentCompanyControl(prevObj => {
            return {
                ...prevObj,
                timerId: timerId,
                options: [],
                isLoading: true,
            }
        });
    }, [parentCompanyControl.search]);

    // helper function to set parent company data
    const setParentCompanyData = (key, value) => {
        setParentCompanyControl(prevObj => {
            const newObj = { ...prevObj };

            newObj[key] = value;
            return newObj;
        });
    };

    return (
        <div>
            <div className="inputGroup">
                <label className="managementLabels">Company Name (Legal Entity Name)*: </label>

                <div className="managementInputFields">
                    {' '}
                    <InputBox
                        placeholder="Enter company name"
                        type="text"
                        value={props.data.name}
                        name="name"
                        onChange={(e) => {
                            props.setData({
                                key: 'name',
                                value: e.target.value,
                            });
                        }}
                    />

                    <div className='desc-text'>{tooltipText['name']}</div>
                </div>
            </div>
            <div className="inputGroup">
                <label className="managementLabels">
                    Display Name/Brand Name* : 
                </label>

                <div className="managementInputFields">
                    {' '}
                    <InputBox
                        type="text"
                        placeholder="Enter display/brand name"
                        value={props.data.displayName}
                        name="displayName"
                        onChange={(e) => {
                            props.setData({
                                key: 'displayName',
                                value: e.target.value,
                            });
                        }}
                    />

                    <div className='desc-text'>{tooltipText['displayName']}</div>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Domains* :
                </label>

                <div className="managementInputFields">
                    <PillButton
                        name="domains"
                        onChange={(e, data) => {
                            props.setData({
                                key: 'domains',
                                value: data.value,
                            });
                            setCheckDomains(true);
                        }}
                        placeholder="Enter domain(s)"
                        allowAdditions={true}
                        data={options}
                        defaultValue={props.data.domains}
                        onAddOtherLocation={(e, { value }) => {
                            setCheckDomains(true);

                            var newop = options;
                            newop.push({ text: value, value })
                            setNewOption(newop)
                        }}
                        error={domainError && domainError.length > 0 ? true : false}
                    />

                    {domainError && domainError.length > 0 ? (
                        <div className='error-text'>The domain {domainError[0]} already exists, please map with a group company if required</div>
                    ) : (
                        <div className='desc-text'>{tooltipText['domains']}</div>
                    )}
                </div>
            </div>

            {(props.editMode && props.data.status == 1) || !props.editMode ? (
                <div className="inputGroup">
                    <label className="managementLabels">Group Company: </label>
                    <div className="managementInputFields">
                        <Checkbox
                            name='parentCompany'
                            checked={isGroupCompany}
                            onChange={(event, data) => {
                                setIsGroupCompany(data.checked);
                                if (!data.checked) {
                                    props.setData({
                                        key: 'groupId',
                                        value: null,
                                    });
                                } else if (props.data.groupId) {
                                    props.setData({
                                        key: 'groupId',
                                        value: props.data.groupId,
                                    })
                                }
                            }}
                        />

                        <div className='desc-text'>{tooltipText['groupCompany']}</div>
                    </div>
                </div>
            ) : null}

            {isGroupCompany ? (
                <>
                    <div className='inputGroup'>
                        <label className='managementLabels'>
                            This is a parent company:
                        </label>
                        <div className="managementInputFields">
                            <Checkbox
                                defaultChecked={props.data.isParentCompany || (props.data.id && props.data.groupId && props.data.groupId == props.data.id) ? true : false}
                                onChange={(event, data) => {
                                    props.setData({
                                        key: 'isParentCompany',
                                        value: data.checked ? 1 : null,
                                    });
                                }}
                            />
                        </div>
                    </div>
                
                    {!props.data.isParentCompany ? (
                        <div className="inputGroup">
                            <label className="managementLabels">
                                Parent Company* :
                            </label>

                            <div className="managementInputFields">
                                {' '}
                                <InputDropdown
                                    name="parentCompany"
                                    options={parentCompanyControl.options}
                                    isLoading={parentCompanyControl.isLoading}
                                    onSearchChange={(searchValue) => {
                                        setParentCompanyData('search', searchValue);
                                    }}
                                    onItemSelect={(companyItem) => {
                                        if (!companyItem.value) {
                                            setParentCompanyData('options', null);
                                        }
                                        props.setData({
                                            key: 'parentCompany',
                                            value: companyItem,
                                        })
                                    }}
                                    selectedLabel={props.data.groupName}
                                    selectedValue={props.data.groupId}
                                />
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}

            <div className="inputGroup">
                <label className="managementLabels">Company Type* :</label>

                <div className="managementInputFields">
                    {' '}
                    <select onChange={(e) => {
                        props.setData({
                            key: 'type',
                            value: e.target.value,
                        });
                    }} >
                        <option name="recType" value="">
                            Select Employer Type
                        </option>
                        {
                            getRecruiterTypeArr().map((op) => {
                                return props.data.type == op.value ? <option name="recType" value={op.value} selected>
                                    {op.text}
                                </option> : <option name="recType" value={op.value}>
                                    {op.text}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Industry* :</label>

                <div className="managementInputFields">
                    {' '}
                    <select name='industry' ref={industryRef} onChange={(e) => {
                        props.setData({
                            key: 'industry',
                            value: parseInt(e.target.value),
                        });
                    }} >
                        <option name="recType" value="">Select Industry</option>
                        {props.industry.map((ind) => {
                            return props.data.industry == ind.id ? <option name="recType" value={ind.id} selected>
                                {ind.name}
                            </option> : <option name="recType" value={ind.id}>
                                {ind.name}
                            </option>
                        })}
                    </select>

                    <div className='desc-text'>{tooltipText['industry']}</div>
                </div>
            </div>

            {/* if classification is IT then show */}
            {props.data.classification == 1 ? (
                <div className="inputGroup">
                    <label className="managementLabels">Nature of Business* :</label>

                    <div className="managementInputFields">
                        {' '}
                        <select name="type" onChange={(e) => {
                            props.setData({
                                key: 'natureOfOffering',
                                value: e.target.value,
                            });
                        }} >
                            <option name="natureOfOffering" value="">
                                Select Nature of Business
                            </option>
                            {
                                natureOfOfferingsArr.map((op) => {
                                    return props.data.natureOfOffering == op.value ? <option name="natureOfOffering" value={op.value} selected>
                                        {op.label}
                                    </option> : <option name="natureOfOffering" value={op.value}>
                                        {op.label}
                                    </option>
                                })
                            }
                        </select>
                    </div>
                </div>
            ) : null}

            <div className="inputGroup">
                <label className="managementLabels">Organisation Type* :</label>

                <div className="managementInputFields">
                    {' '}
                    <select name="companyType" onChange={(e) => {
                        props.setData({
                            key: 'companyType',
                            value: e.target.value,
                        });
                    }} >
                        <option name="companyType" value="">
                            Select Organisation Type
                        </option>
                        {
                            getOrganisationTypeArr().slice(1).map((op) => {

                                return props.data.companyType == op.value ? <option name="companyType" value={op.value} selected>
                                    {op.label}
                                </option> : <option name="companyType" value={op.value}>
                                    {op.label}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>

            {props.data.companyType == 1 ? (
                <>
                    <div className="inputGroup">
                        <label className="managementLabels">Funding Stage :</label>

                        <div className="managementInputFields">
                            {' '}
                            <select onChange={(e) => {
                                props.setData({
                                    key: 'fundingStage',
                                    value: e.target.value,
                                });
                            }} >
                                {
                                    getFundingStage().map((op) => {
                                        return props.data.fundingStage == op.id ? <option name="fundingStage" value={op.id} selected>
                                            {op.name}
                                        </option> : <option name="fundingStage" value={op.id}>
                                            {op.name}
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="inputGroup">
                        <label className="managementLabels">Valuation :</label>

                        <div className="managementInputFields">
                            {' '}
                            <InputBox
                                type="text"
                                placeholder="Enter valuation"
                                value={props.data.valuation}
                                name="valuation"
                                onChange={(e) => {
                                    props.setData({
                                        key: 'valuation',
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="inputGroup">
                    <label className="managementLabels">Estimated Revenue:</label>

                    <div className="managementInputFields">
                        {' '}
                        <select onChange={(e) => {
                            props.setData({
                                key: 'estimatedRevenue',
                                value: e.target.value,
                            });
                        }} >
                            <option name="estimatedRevenue" value="">
                                Select Estimated Revenue
                            </option>
                            {
                                getEstimatedRevenueArr().map((op) => {
                                    return props.data.estimatedRevenue == op.value ? <option name="estimatedRevenue" value={op.value} selected>
                                        {op.label}
                                    </option> : <option name="estimatedRevenue" value={op.value}>
                                        {op.label}
                                    </option>
                                })
                            }
                        </select>
                    </div>
                </div>
            )}

            <div className="inputGroup">
                <label className="managementLabels">Unicorn Status: </label>
                <div className="managementInputFields">
                    <Checkbox
                        defaultChecked={props.data.unicornStatus == 1 ? true : false}
                        onChange={(event, data) => {
                            props.setData({
                                key: 'unicornStatus',
                                value: data.checked ? 1 : null,
                            });
                        }}
                    />
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Captive/GCC: </label>
                <div className="managementInputFields">
                    <div className='checkbox-group'>
                        <Checkbox
                            label="Captive"
                            defaultChecked={props.data.captive == 1 ? true : false}
                            checked={props.data.captive == 1 ? true : false}
                            onChange={(event, data) => {
                                props.setData({
                                    key: 'captive',
                                    value: data.checked ? 1 : null,
                                });
                            }}
                        />

                        <Checkbox
                            label="GCC"
                            defaultChecked={props.data.gcc == 1 ? true : false}
                            checked={props.data.gcc == 1 ? true : false}
                            onChange={(event, data) => {
                                props.setData({
                                    key: 'gcc',
                                    value: data.checked ? 1 : null,
                                });
                            }}
                        />
                    </div>
                    <div className='desc-text'>{tooltipText['gcc']}</div>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Premium Rank - Hirist :</label>
                <div className="managementInputFields">
                    <Dropdown
                        data={possiblePremiumRanks}
                        defaultValue={props.data.tempPremiumRanks ? props.data.tempPremiumRanks.hirist : null}
                        name="premiumRanksHiristDropdown"
                        dropdownChange={(event) => {
                            const value = event.target.value == -1 ? null : parseInt(event.target.value);
                            props.setData({
                                key: 'premiumRanks.hirist',
                                value: value,
                            });
                        }}
                    />
                    <div className='desc-text'>{tooltipText['premiumRanks']}</div>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Premium Rank - Iimjobs: </label>
                <div className="managementInputFields">
                    <Dropdown
                        data={possiblePremiumRanksiimjobs}
                        defaultValue={props.data.tempPremiumRanks ? props.data.tempPremiumRanks.iimjobs : null}
                        name="premiumRanksIimjobsDropdown"
                        dropdownChange={(event) => {
                            const value = event.target.value == -1 ? null : parseInt(event.target.value);
                            props.setData({
                                key: 'premiumRanks.iimjobs',
                                value: value,
                            });
                        }}
                    />
                    <div className='desc-text'>{tooltipText['premiumRanksIimjobs']}</div>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Premium Rank - Updazz: </label>
                <div className="managementInputFields">
                    <Dropdown
                        data={possiblePremiumRanks}
                        defaultValue={props.data.tempPremiumRanks ? props.data.tempPremiumRanks.updazz : null}
                        name="premiumRanksUpdazzDropdown"
                        dropdownChange={(event) => {
                            const value = event.target.value == -1 ? null : parseInt(event.target.value);
                            props.setData({
                                key: 'premiumRanks.updazz',
                                value: value,
                            });
                        }}
                    />
                    <div className='desc-text'>{tooltipText['premiumRanks']}</div>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Company Size :</label>

                <div className="managementInputFields">
                    {' '}
                    <select onChange={(e) => {
                        props.setData({
                            key: 'noOfEmployees',
                            value: e.target.value,
                        });
                    }} >
                        {
                            getNumberOfEmployees().map((op) => {
                                return props.data.noOfEmployees == op.value ? <option name="noOfEmployees" value={op.value} selected>
                                    {op.label}
                                </option> : <option name="noOfEmployees" value={op.value}>
                                    {op.label}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Headquater Location :</label>

                <div className="managementInputFields">
                    {' '}
                    <select onChange={(e) => {
                        props.setData({
                            key: 'headquaterCity',
                            value: e.target.value,
                        });
                    }} >
                        <option name="headquaterCity" value="">
                            Select Headquater Location
                        </option>
                        {
                            getSortedHeadquarterCityList().slice(1).map((op) => {
                                return props.data.headquaterCity == op.id ? <option name="headquaterCity" value={op.id} selected>
                                    {op.name}
                                </option> : <option name="headquaterCity" value={op.id}>
                                    {op.name}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>
        
            <div className="inputGroup">
                <label className="managementLabels">
                    Other office locations :
                </label>

                <div className="managementInputFields">
                    <PillButton
                        onChange={(e, data) => {
                            props.setData({
                                key: 'otherOfficeLocations',
                                value: data.value,
                            });
                        }}
                        placeholder="Select other office location"
                        allowAdditions={false}
                        data={getSortedHeadquarterCityList().slice(1).map(item => ({ text: item.name, value: parseInt(item.id) }))}
                        defaultValue={props.data.otherOfficeLocations}
                    />
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Important Tags :
                </label>

                <div className="managementInputFields">
                    <PillSelect 
                        options={getCompanyTags()}
                        selectedOptions={props.data.tags}
                        onSelectOption={(optsId) => {
                            let newOpts = props.data.tags;

                            if (!newOpts) {
                                newOpts = [];
                            }

                            if (newOpts.indexOf(optsId) == -1) {
                                newOpts.push(optsId);
                            } else {
                                newOpts = newOpts.filter(item => item != optsId);
                            }

                            props.setData({
                                key: 'tags',
                                value: newOpts,
                            });
                        }}
                    />
                    {/* <PillButton
                        placeholder="Select tags"
                        onChange={(e, data) => {
                            props.setData({
                                key: 'tags',
                                value: data.value,
                            });
                        }}
                        allowAdditions={false}
                        data={getCompanyTags().map(item => ({ text: item.value, value: item.id }))}
                        defaultValue={props.data.tags}
                    /> */}
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Benefits :
                </label>

                <div className="managementInputFields">
                    <PillSelect 
                        options={getCompanyBenefits()}
                        selectedOptions={props.data.benefits}
                        onSelectOption={(optsId) => {
                            let newOpts = props.data.benefits;

                            if (!newOpts) {
                                newOpts = [];
                            }

                            if (newOpts.indexOf(optsId) == -1) {
                                newOpts.push(optsId);
                            } else {
                                newOpts = newOpts.filter(item => item != optsId);
                            }

                            props.setData({
                                key: 'benefits',
                                value: newOpts,
                            });
                        }}
                    />
                    {/* <PillButton
                        placeholder="Select benefits"
                        onChange={(e, data) => {
                            props.setData({
                                key: 'benefits',
                                value: data.value,
                            });
                        }}
                        allowAdditions={false}
                        data={getCompanyBenefits().map(item => ({ text: item.value, value: item.id }))}
                        defaultValue={props.data.benefits}
                    /> */}
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Awards :
                </label>

                <div className="managementInputFields">
                    <PillSelect 
                        options={getCompanyAwards()}
                        selectedOptions={props.data.awards}
                        onSelectOption={(optsId) => {
                            let newOpts = props.data.awards;

                            if (!newOpts) {
                                newOpts = [];
                            }

                            if (newOpts.indexOf(optsId) == -1) {
                                newOpts.push(optsId);
                            } else {
                                newOpts = newOpts.filter(item => item != optsId);
                            }

                            props.setData({
                                key: 'awards',
                                value: newOpts,
                            });
                        }}
                    />
                    {/* <PillButton
                        placeholder="Select awards"
                        onChange={(e, data) => {
                            props.setData({
                                key: 'awards',
                                value: data.value,
                            });
                        }}
                        allowAdditions={false}
                        data={getCompanyAwards().map(item => ({ text: item.value, value: item.id }))}
                        defaultValue={props.data.awards}
                    /> */}
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">Comments :</label>

                <div className="managementInputFields">
                    {' '}
                    <InputBox
                        type="text"
                        placeholder="Add comments"
                        value={props.data.comment}
                        name="comment"
                        onChange={(e) => {
                            props.setData({
                                key: 'comment',
                                value: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Description :
                </label>

                <div className="managementInputFields">
                    <textarea
                        placeholder='Add description'
                        rows={5}
                        className='description'
                        value={props.data.description}
                        name='description'
                        onChange={(event) => {
                            props.setData({
                                key: 'description',
                                value: event.target.value,
                            })
                        }}
                    />
                </div>
            </div>

            <div className="inputGroup">
                <label className="managementLabels">
                    Founding Year:
                </label>

                <div className="managementInputFields">
                    <InputBox
                        placeholder="Enter founding year"
                        type="number"
                        value={props.data.companyFounded}
                        name="companyFounded"
                        onKeyPress={(e) => {
                            if (['e', 'E', '+', '-'].includes(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value && Number.isNaN(parseInt(e.target.value))) {
                                return;
                            }
                            if (e.target.value && e.target.value.length > 4) {
                                return;
                            }
                            props.setData({
                                key: 'companyFounded',
                                value: e.target.value,
                            });
                        }}
                    />
                    {/* <select onChange={(e) => {
                        props.setData({
                            key: 'companyFounded',
                            value: e.target.value,
                        });
                    }} >
                        {
                            getCompanyFounded().map((op) => {
                                return props.data.companyFounded == op.id ? <option name="companyFounded" value={op.id} selected>
                                    {op.name}
                                </option> : <option name="companyFounded" value={op.id}>
                                    {op.name}
                                </option>
                            })
                        }
                    </select> */}
                </div>
            </div>
            
            {/* keep in last */}
            <div className='restricted-fields'>
                <div className='inputGroup'>
                    <label className="managementLabels">InfoEdge Id :</label>

                    <div className="managementInputFields">
                        {' '}
                        <InputBox
                            disabled={props.fieldsEditAccess ? null : true}
                            placeholder="Enter infoedge id"
                            type="text"
                            value={props.data.infoedgeId}
                            name="infoedgeId"
                            onChange={(e) => {
                                props.setData({
                                    key: 'infoedgeId',
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="inputGroup">
                    <label className="managementLabels">Hirist Id :</label>

                    <div className="managementInputFields">
                        {' '}
                        <InputBox
                            disabled={props.fieldsEditAccess ? null : true}
                            placeholder="Enter hirist id"
                            type="text"
                            value={props.data.hiristId}
                            name="hiristId"
                            onChange={(e) => {
                                props.setData({
                                    key: 'hiristId',
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="inputGroup">
                    <label className="managementLabels">iimjobs Id :</label>

                    <div className="managementInputFields">
                        {' '}
                        <InputBox
                            disabled={props.fieldsEditAccess ? null : true}
                            placeholder="Enter iimjobs id"
                            type="text"
                            value={props.data.iimjobsId}
                            name="iimjobsId"
                            onChange={(e) => {
                                props.setData({
                                    key: 'iimjobsId',
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="inputGroup">
                    <label className="managementLabels">Updazz Id :</label>

                    <div className="managementInputFields">
                        {' '}
                        <InputBox
                            disabled={props.fieldsEditAccess ? null : true}
                            placeholder="Enter updazz id"
                            type="text"
                            value={props.data.updazzId}
                            name="updazzId"
                            onChange={(e) => {
                                props.setData({
                                    key: 'updazzId',
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="inputGroup">
                    <label className="managementLabels">First Party Email :</label>

                    <div className="managementInputFields">
                        {' '}
                        <InputBox
                            disabled={props.fieldsEditAccess ? null : true}
                            placeholder="Enter first party email"
                            type="text"
                            value={props.data.firstPartyEmail}
                            name="firstPartyEmail"
                            onChange={(e) => {
                                props.setData({
                                    key: 'firstPartyEmail',
                                    value: e.target.value.trim(),
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="inputGroup">
                    <label className="managementLabels">Platforms to hide from: </label>
                    <div className="managementInputFields">
                        <div className="toHideCheckboxContainer">
                            <Checkbox
                                disabled={props.fieldsEditAccess ? false : true}
                                label="iimjobs"
                                defaultChecked={props.data.toHide ? props.data.toHide.iimjobs : false}
                                onChange={(event, data) => {
                                    props.setData({
                                        key: 'toHide.iimjobs',
                                        value: data.checked ? true : false,
                                    });
                                }}
                            />
                            <Checkbox
                                disabled={props.fieldsEditAccess ? false : true}
                                label="hirist"
                                defaultChecked={props.data.toHide ? props.data.toHide.hirist : false}
                                onChange={(event, data) => {
                                    props.setData({
                                        key: 'toHide.hirist',
                                        value: data.checked ? true : false,
                                    });
                                }}
                            />
                            <Checkbox
                                disabled={props.fieldsEditAccess ? false : true}
                                label="updazz"
                                defaultChecked={props.data.toHide ? props.data.toHide.updazz : false}
                                onChange={(event, data) => {
                                    props.setData({
                                        key: 'toHide.updazz',
                                        value: data.checked ? true : false,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="inputCategory admin-details">
                    <label className="categoryLabel">Admin Details:</label>

                    <div className="inputGroup">
                        <label className="managementLabels">ID:</label>
                        <div className="managementInputFields adminId">
                            <InputBox
                                disabled={props.fieldsEditAccess ? null : true}
                                placeholder="Enter recruiter id"
                                type="text"
                                value={adminId}
                                onChange={event => {
                                    setAdminId(event.target.value);
                                }}
                            />
                            <Button
                                skin='dark'
                                text="Search"
                                disabled={!props.fieldsEditAccess || adminId.length == 0 ? true : null}
                                clickButton={handleSearchAdmin}
                            />
                            {isAdminLoading ? (
                                <Spinner />
                            ) : (
                                showAdminForm ? (
                                    <Button
                                        text="Clear"
                                        clickButton={handleClearAdmin}
                                        skin="light"
                                    />
                                ) : null
                            )}
                        </div>
                    </div>

                    {props.fieldsEditAccess && showAdminForm ? (
                        <>
                            <div className="inputGroup">
                                <label className="managementLabels">Name:</label>
                                <div className="managementInputFields">
                                    <InputBox
                                        type="text"
                                        value={adminDetails.name}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="inputGroup">
                                <label className="managementLabels">Email:</label>
                                <div className="managementInputFields">
                                    <InputBox
                                        type="text"
                                        disabled={true}
                                        value={adminDetails.email}
                                    />
                                </div>
                            </div>

                            <div className="inputGroup">
                                <label className="managementLabels">Platform:</label>
                                <div className="managementInputFields">
                                    <InputBox
                                        type="text"
                                        disabled={true}
                                        value={adminDetails.platform}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>

        </div>
    );
};

CompanyForm.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    industry: PropTypes.array,
    adminId: PropTypes.string,
    fieldsEditAccess: PropTypes.bool,
    editMode: PropTypes.bool,
};

export default CompanyForm;
