import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Heading from '../../components/Heading';
import Button from '../../components/Button';
import DragAndDrop from '../../components/DragAndDrop';
import InputBox from '../../components/InputBox';
import ToolTip from '../../components/ToolTip';

import user from '../../store/user';
import postDomainMapping from '../../services/postDomainMapping';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

import './Container.scss';

const DomainMapping = props => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [viewAccess, setViewAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [override, setOverride] = useState(0);

    useEffect(() => {
        async function getProfile() {
            const profile = await user.getProfile()
            return profile
        }
        getProfile().then(userData => {
            setViewAccess(user.checkFeatureAccess(adminFeatureMapping.DOMAIN_MAPPING));
            setAdminId(userData.id);
        });
    }, [])

    const onUpload = (file) => {
        setSelectedFile(file);
    }

    const onError = (msg) => {
        toast.error(msg);
    }

    const handleFileUpload = async () => {
        if (selectedFile) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('csv', selectedFile);
            formData.append('override', override);

            try {
                const response = await postDomainMapping(adminId, formData);

                if (response && response.status === 200) {
                    toast.success('File Uploaded Successfully');
                    setSelectedFile(null);
                } else {
                    toast.error('Something went wrong. Please try again later.')
                }
            }catch(error) {
                toast.error('Something went wrong. Please try again later.')
            }
            
            setIsLoading(false);
        }
    }

    const handleSelectedFile = (file) => {
        setSelectedFile(file);
    }

    const handleCheckboxChange = (event) => {
        setOverride(event.target.checked ? 1 : 0)
    }

    return (
        <div>
            {
                viewAccess ? (
                    <div className='page-content'>
                        <ToastContainer 
                                position='bottom-left'
                                autoClose={2000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover 
                            />

                        <div className='page-heading'>
                            <Heading text='Domaing to Organisation ID Mapping' />
                        </div>

                        <div className='domain__mapping'>
                            <DragAndDrop
                                    formats={['csv']}
                                    onError={onError}
                                    onUpload={onUpload}
                                    selectedFile={selectedFile}
                                    setSelectedFile={handleSelectedFile}
                                    />

                            { selectedFile && (
                                <div className='domain__mapping--actions'>
                                    <Button className='btn-upload' skin='dark' text={isLoading ? 'Uploading...' : 'Upload'} clickButton={handleFileUpload} loading={isLoading} />
                                    <div className='checkbox'>
                                        <label className={override ? 'override' : ''}>
                                            <InputBox type='checkbox' name='override' value={override} checked={override ? true : false} onChange={handleCheckboxChange} /> Override Existing Values
                                            <ToolTip className='tooltip'>Warning: Checking this option will override existing IDs in the system.</ToolTip>
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div className='instructions'>
                                <b>Instructions:</b>
                                <ul>
                                    <li>Only <code>.csv</code> can be uploaded.</li>
                                    <li>First row must always be the heading for the respective columns.</li>
                                    <li>First column in the csv should be Domain, the third Column should be Company ID, and the fourth column should be Logo Status. </li>
                                    <li>For multiple domain entries in a single row, please use <code>|</code> (pipe) symbol as the seperator.</li>
                                </ul>

                                To Edit already mapped values,
                                <ul>
                                    <li>Check the 'Override Existing Values' checkbox</li>
                                    <li>Note that this will override the IDs for all the entries that you provide in the CSV file</li>
                                    <li>To reset the ID of a domain, just set the ID to 0 in the CSV file</li>
                                </ul>
                            </div>
                        </div>
                    </div>
               ): (
                   <div>You don't have access to this page</div>
               )
           }
        </div>
    );
};

export default DomainMapping;