const iimjobsJobReferenceMap = {
    1:  "cl", 
    2:  "rl", 
    3:  "ep", 
    4:  "jf", 
    5:  "kp", 
    6:  "en", 
    7:  "sp", 
    8:  "hp", 
    9:  "enf", 
    10:  "pp", 
    11:  "ln", 
    12:  "tm", 
    13:  "lngrp", 
    14:  "tw", 
    15:  "premium", 
    16:  "apj", 
    17:  "sj", 
    18:  "jd", 
    19:  "lkgroup", 
    20:  "tjapp", 
    21:  "LiJobs", 
    22:  "glassdoor", 
    23:  "recruit", 
    24:  "jobrapido", 
    25:  "trovit", 
    26:  "careerjet", 
    27:  "simplyhired", 
    28:  "mitula", 
    29:  "jobisjob", 
    30:  "recom", 
    31:  "recom_push_ios", 
    32:  "recom_push_android", 
    33:  "linkedin", 
    34:  "indeed", 
    35:  "reljf", 
    36:  "adsafari", 
    37:  "adzuna", 
    38:  "workcircle", 
    39:  "jooble", 
    40:  "clickindia", 
    41:  "jobsmart", 
    42:  "jobtome", 
    43:  "sercanto", 
    44:  "jora", 
    45:  "neuvoo", 
    46:  "techslash", 
    47:  "hpjob", 
    48:  "default", 
    49:  "rl_aj", 
    50:  "multiapp", 
    51:  "bj", 
    52:  "cj", 
    53:  "db", 
    54:  "psj", 
    55:  "scp", 
    56:  "rzwebnotif", 
    57:  "recom_email", 
    58:  "recom_apply", 
    59:  "recom_ev", 
    60:  "ep_more", 
    61:  "ep_sec2", 
    62:  "ep_sec2_more", 
    63:  "ejf_tags", 
    64:  "ejf_mytags", 
    65:  "ejf_meta", 
    66:  "ep_ev", 
    67:  "ep_sec2_ev", 
    68:  "ep_more_ev", 
    69:  "ep_sec2_more_ev", 
    70:  "recom_ev_com", 
    71:  "recom_push_android_com", 
    72:  "recom_push_ios_com", 
    73:  "em", 
    74:  "ep_weekly", 
    75:  "ep_more_weekly", 
    76:  "recom_unpub", 
    77:  "caljpm", 
    78:  "calcai", 
    79:  "calcad", 
    80:  "jobfeed", 
    81:  "confirmpage", 
    82:  "active_push_android", 
    83:  "active_push_ios", 
    84:  "enf_top", 
    85:  "push", 
    86:  "deepLink", 
    87:  "appMenu", 
    88:  "inAppNotification", 
    89:  "custom404", 
    90:  "jsChatbot", 
    91:  "wfh", 
    92:  "ep_old", 
    93:  "ep_old_more", 
    94:  "ep_old_sec2_more", 
    95:  "ep_old_sec2", 
    96:  "ambitionbox", 
    97:  "ep_ev_ml", 
    98:  "recom_ev_ml", 
    99:  "recom_bn", 
    100:  "whatjob", 
    101:  "Others", 
    102:  "blank", 
    103:  "kp_car_br_prm", 
    104:  "googlejob", 
    105:  "careesma", 
    106:  "jf_ml", 
    107:  "clp_p", 
    108:  "clp_e", 
    109:  "clp_f", 
    110:  "kp_car_m_br_prm", 
    111:  "jf_prm", 
    112:  "jf_list_prm", 
    113:  "cl_prm", 
    114:  "cl_list_prm", 
    115:  "kp_prm", 
    116:  "kp_list_prm", 
    117:  "rl_aj_prm", 
    118:  "rl_aj_list_prm", 
    119:  "ev_recom_seg4", 
    120:  "ev_recom_seg7", 
    121:  "jf_pml", 
    122:  "ev_earlyapp", 
    123:  "cl_p_prm", 
    124:  "cl_p", 
    125:  "cl_m_prm", 
    126:  "cl_m", 
    127:  "cl_br_prm", 
    128:  "cl_br", 
    129:  "cl_p_br_prm", 
    130:  "cl_p_br", 
    131:  "cl_m_br_prm", 
    132:  "cl_m_br", 
    133:  "kp_br_prm", 
    134:  "kp_br", 
    135:  "kp_p_prm", 
    136:  "kp_p_br_prm", 
    137:  "kp_p_br", 
    138:  "kp_p", 
    139:  "kp_m_prm", 
    140:  "kp_m_br_prm", 
    141:  "kp_m_br", 
    142:  "kp_m", 
    143:  "cl_list_p_prm", 
    144:  "cl_list_m_prm", 
    145:  "cl_list_p_br_prm", 
    146:  "cl_list_br_prm", 
    147:  "cl_list_m_br_prm", 
    148:  "cl_car_p_prm", 
    149:  "cl_car_prm", 
    150:  "cl_car_m_prm", 
    151:  "cl_car_p_br_prm", 
    152:  "cl_car_br_prm", 
    153:  "cl_car_m_br_prm", 
    154:  "kp_list_p_prm", 
    155:  "kp_list_m_prm", 
    156:  "kp_list_p_br_prm", 
    157:  "kp_list_br_prm", 
    158:  "kp_list_m_br_prm", 
    159:  "kp_car_p_prm", 
    160:  "kp_car_prm", 
    161:  "kp_car_m_prm", 
    162:  "kp_car_p_br_prm"
} 

const hiristJobReferenceMap = {
    1: "cl",
    2: "rl",
    3: "ep",
    4: "jf",
    5: "kp",
    6: "en",
    7: "sp",
    8: "hp",
    9: "enf",
    10: "pp",
    11: "ln",
    12: "tm",
    13: "lngrp",
    14: "tw",
    15: "premium",
    16: "apj",
    17: "sj",
    18: "jd",
    19: "lkgroup",
    20: "tjapp",
    21: "LiJobs",
    22: "glassdoor",
    23: "recruit",
    24: "jobrapido",
    25: "trovit",
    26: "careerjet",
    27: "simplyhired",
    28: "mitula",
    29: "jobisjob",
    30: "recom",
    31: "recom_push_ios",
    32: "recom_push_android",
    101: "Others",
    102: "blank",
    33: "linkedin",
    34: "indeed",
    35: "reljf",
    36: "adsafari",
    37: "adzuna",
    38: "workcircle",
    39: "jooble",
    40: "clickindia",
    41: "jobsmart",
    42: "jobtome",
    43: "sercanto",
    44: "jora",
    45: "neuvoo",
    46: "techslash",
    104: "googlejob",
    105: "careesma",
    47: "hpjob",
    48: "default",
    50: "multiapp",
    51: "bj",
    52: "cj",
    53: "db",
    54: "psj",
    55: "scp",
    49: "rl_aj",
    56: "rzwebnotif",
    57: "recom_email",
    58: "recom_apply",
    59: "recom_ev",
    60: "ep_more",
    61: "ep_sec2",
    62: "ep_sec2_more",
    63: "ejf_tags",
    64: "ejf_mytags",
    65: "ejf_meta",
    66: "ep_ev",
    67: "ep_sec2_ev",
    68: "ep_more_ev",
    69: "ep_sec2_more_ev",
    70: "recom_ev_com",
    71: "recom_push_android_com",
    72: "recom_push_ios_com",
    73: "em",
    74: "ep_weekly",
    75: "ep_more_weekly",
    76: "recom_unpub",
    77: "caljpm",
    78: "calcai",
    79: "calcad",
    80: "jobfeed",
    81: "confirmpage",
    82: "active_push_android",
    83: "active_push_ios",
    84: "enf_top",
    85: "push",
    86: "deepLink",
    87: "appMenu",
    88: "inAppNotification",
    89: "custom404",
    90: "jsChatbot",
    91: "wfh",
    92: "ep_old",
    93: "ep_old_more",
    94: "ep_old_sec2_more",
    95: "ep_old_sec2",
    96: "ambitionbox",
    97: "ep_ev_ml",
    98: "recom_ev_ml",
    111: "jf_prm",
    112: "jf_list_prm",
    113: "cl_prm",
    114: "cl_list_prm",
    115: "kp_prm",
    116: "kp_list_prm",
    117: "rl_aj_prm",
    118: "rl_aj_list_prm",
    99: "recom_bn",
    119: "ev_recom_seg4",
    120: "ev_recom_seg7",
    121: "jf_pml",
    122: "ev_earlyapp",
    100: "whatjob",
};


export default function getJobReferenceMap(){
    const platform = process.env.REACT_APP_PLATFORM;
    if(platform == 'hirist') {
        return hiristJobReferenceMap;
    }
    else if(platform == 'iimjobs'){
        return iimjobsJobReferenceMap;
    }
}


