import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../components/Heading';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';

import { JOBSEEKER_MGT, SEEKER_BLACKLIST } from '../../models/routes';

import user from '../../store/user';
import getBlacklistedSeeker from '../../services/getBlacklistedSeeker';
import { getQueryParams } from '../../utils/utilFunctions';

import './styles.scss';

const Blacklist = ({ history, location }) => {

    const [adminId, setAdminId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [controller, setController] = useState({
        seekerList: [],
        isPageLoading: false,
        pageNumber: 1,
        pageContent: 100,
        totalResults: 0
    });
    
    const CONTROLLER_KEYS = {
        seekerList: 'seekerList',
        isPageLoading: 'isPageLoading',
        pageNumber: 'pageNumber',
        pageContent: 'pageContent',
        totalResults: 'totalResults'
    };

    useEffect(() => {
        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        const params = getQueryParams(location.search);

        if (params.pg) {
            setControllerData(CONTROLLER_KEYS.pageNumber, params.pg);
        }

        getProfile().then(response => {
            setAdminId(response.id);
        }).catch(error => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    useEffect(() => {
        if (adminId) {
            getSeekerList(null, 1);
        }
    }, [adminId]);

    const setControllerData = (key, value) => {
        setController(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        });
    };

    const getSeekerList = async (page = null, withCount = null) => {
        try {
            const response = await getBlacklistedSeeker(adminId, {
                pg: page || controller.pageNumber,
                withCount
            });

            if (withCount) {
                setControllerData(CONTROLLER_KEYS.totalResults, response.data.data.totalCount);
            }
            setControllerData(CONTROLLER_KEYS.seekerList, response.data.data.blacklistedUsers);
            setControllerData(CONTROLLER_KEYS.isPageLoading, false);
            setIsLoading(false);
            
        } catch (error) {
            toast.error('Something went wrong. Please try again later!');
        }
    };

    const handlePageChange = (page) => {
        setControllerData(CONTROLLER_KEYS.pageNumber, page);
        setControllerData(CONTROLLER_KEYS.isPageLoading, true);

        getSeekerList(page);
        updateQueryParams(page);
    };

    const updateQueryParams = (page = null) => {
        history.push(`${SEEKER_BLACKLIST}?pg=${page}`);
    };
    
    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Current Organisation</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    let mainContent = null;

    if (isLoading) {
        mainContent = <Loader />;
    } else if (!isLoading && controller.isPageLoading) {
        mainContent = (
            <div>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </div>
        );
    } else {
        mainContent = (
            <div>
                <Table>
                    {tableHeading}
                    <Table.Body>
                        {controller.seekerList.map(item => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{item.id}</Table.Cell>
                                    <Table.Cell>{item.name || '-'}</Table.Cell>
                                    <Table.Cell>{item.email}</Table.Cell>
                                    <Table.Cell>{item.phone || '-'}</Table.Cell>
                                    <Table.Cell>{item.current_organization || '-'}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    let topPageIndicators = <div></div>;
    let bottomPageIndicators = <div></div>;

    if (!isLoading && controller.seekerList.length > 0) {
        topPageIndicators = (
            <div className='top-page-controller'>
                <div className='row text-center'>
                    <Pagination totalItemsCount={controller.totalResults} content={controller.pageContent} pageNumber={controller.pageNumber} handlePageChange={handlePageChange} />
                    <CountDisplay start={(controller.pageNumber - 1) * controller.pageContent + 1} end={controller.pageNumber * controller.pageContent} total={controller.totalResults} />
                </div>
            </div>
        )

        if (!controller.isPageLoading) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination totalItemsCount={controller.totalResults} content={controller.pageContent} pageNumber={controller.pageNumber} handlePageChange={handlePageChange} />
                </div>
            )
        }
    }

    return (
        <div className='page-content seeker-blacklist'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />

            <div className='heading__container'>
                <Heading text="Blacklist Jobseeker" />
                <div className='heading__container--actions'>
                    <a href={JOBSEEKER_MGT} target="_blank">
                        <Button text="Jobseeker Management" skin="dark" />
                    </a>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/blacklist.php`} target="_blank">
                        <Button text="Use Old Version" skin="dark" />
                    </a>
                </div>
            </div>

            {topPageIndicators}

            <div className='content'>
                {mainContent}
            </div>

            {bottomPageIndicators}

        </div>
    );
};

export default Blacklist;