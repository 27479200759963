function replacer(text) {
    text = text.replace(/&amp;amp;/g, "&amp;");
    text = text.replace(/&amp;amp;/g, "&amp;");
    text = text.replace(/&amp;/g, "&");
    text = text.replace(/&nbsp;/g, " ");
    text = text.replace(/<br>/g, "\n");
    text = text.replace(/<br\/>/g, "\n");
    text = text.replace(/<br \/>/g, "\n");
    text = text.replace(/\r/g, '\n');
    text = text.replace(/\n{3,}/g, '\n\n');
    text = text.replace(/\n/g, '<br/>');
    text = text.replace(/<div>|<\/div>/g, "");
    text = text.replace(/\t/g, ' ');
    text = text.replace(/  +/g, ' ');
    text = text.replace(/\*/g, '-');
    text = text.replace(/\?s/g, "'s ")

    var rxp = /|||&#61656;|&#61623;|&#8234;|&#61660;|&#61548;|&#8203;|&#61692;|&#61472;|&#9702;|■|&#61607;/g
    text = text.replace(rxp, '- ');
    text = text.replace(/[^[\p{L}\p{N}\s]\-\/\\\\!\~\`\@\#\%\<\>\?\^\&\*\(\)\-\_\+\=\{\}\[\]\$\^\;\"\'\,\.\|\:]+/g, '- ');
    var regExp = /- - - -|- - -|- -|- ‚-|----|---|--|##|Ø|-->|->|- •|-•|•|●|- ●|-●|ç|æ|œ|á|í|ó|ú|à|è|ì|ò|ù|ä|ë|ï|ö|ü|ÿ|â|ê|î|ô|û|å|ø|Ø|Å|Á|À|Â|Ä|Ê|Ë|Í|Î|Ï|Ì|Ò|Ó|Ô|Ö|Ú|Ù|Û|Ü|Ÿ|Ç|Æ|Œ/gi
    text = text.replace(regExp, "- ");
    text = text.replace(/- &euro;|- &sbquo;/gi, "\n")
    text = text.replace(/\n- \n/g, '');
    text = text.replace(/[^(\x20-\x7F)\x0A\x0D\xC9]*/gi, "");
    text = text.normalize('NFC').replace(/[\u0300-\u036f]/g, "")

    return text
}

export function replacerCourse(text) {
    text = text.replace(/&amp;amp;/g, "&amp;");
    text = text.replace(/&amp;amp;/g, "&amp;");
    text = text.replace(/&amp;/g, "&");
    text = text.replace(/&nbsp;/g, " ");
    text = text.replace(/<br>/g, "\n");
    text = text.replace(/<br\/>/g, "\n");
    text = text.replace(/<br \/>/g, "\n");
    text = text.replace(/\r/g, '\n');
    text = text.replace(/\n{3,}/g, '\n\n');
    text = text.replace(/\n/g, '<br/>');
    text = text.replace(/<div>|<\/div>/g, "");
    text = text.replace(/\t/g, ' ');
    text = text.replace(/  +/g, ' ');
    text = text.replace(/\*/g, '-');
    text = text.replace(/\?s/g, "'s ")

    var rxp = /|||&#61656;|&#61623;|&#8234;|&#61660;|&#61548;|&#8203;|&#61692;|&#61472;|&#9702;|■|&#61607;/g
    text = text.replace(rxp, '- ');
    text = text.replace(/[^[\p{L}\p{N}\s]\-\/\\\\!\~\`\@\#\%\<\>\?\^\&\*\(\)\-\_\+\=\{\}\[\]\$\^\;\"\'\,\.\|\:]+/g, '- ');
    var regExp = /- - - -|- - -|- -|- ‚-|----|---|--|##|Ø|-->|->|- •|-•|•|●|- ●|-●|ç|æ|œ|á|é|í|ó|ú|à|è|ì|ò|ù|ä|ë|ï|ö|ü|ÿ|â|ê|î|ô|û|å|ø|Ø|Å|Á|À|Â|Ä|È|É|Ê|Ë|Í|Î|Ï|Ì|Ò|Ó|Ô|Ö|Ú|Ù|Û|Ü|Ÿ|Ç|Æ|Œ/gi
    // text = text.replace(regExp, "- ");
    text = text.replace(/- &euro;|- &sbquo;/gi, "\n")
    text = text.replace(/\n- \n/g, '');
    // text = text.replace(/[^(\x20-\x7F)\x0A\x0D]*/gi, "");
    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

    return text
}

export function cleanIntroText(text) {
    let updatedText = text;
    updatedText = updatedText.replace(/–/g, '-');
    updatedText = updatedText.replace(/—/g, '-');
    updatedText = updatedText.replace(/’/g, "'");
    updatedText = updatedText.replace(/“/g, '"');
    updatedText = updatedText.replace(/”/g, '"');
    updatedText = updatedText.replace(/‘/g, "'");
    updatedText = updatedText.replace(/·/g, '-');
    updatedText = updatedText.replace(/…/g, '.');
    updatedText = unescape(encodeURIComponent(updatedText));
    return updatedText;
}

// module.exports = {
//     replacer,
//     cleanIntroText
// }
export default replacer;
