const errorCodes = {
    0: 'Not initiated',
    1: 'Successful',
    2: 'Company ID not present in recruiter profile',
    3: 'Company not found', 
    4: 'Could not find recruiter',
    5: 'Plan ID is invalid',
    6: 'Error updating company data',
    7: 'No active subscription found.',
    8: 'Register company api (naukri) failed.',
    9: 'Transaction api (naukri) failed.',
    10: 'Payment api (naukri) failed',
    11: 'Pricing Plans API failed',
    12: 'Company Management API call failed',
    13: 'Get Customer Details API (naukri) failed',
    14: 'Transaction API (manager dashboard) failed',
    15: 'Assign API (manager dashboard) failed',
    16: 'Modify company API (naukri) failed',
};

export default errorCodes;