import React from "react";
import recActivate from "../../../services/integrationRecActivate";
import recDeactivate from "../../../services/integrationRecDeactivate";
import recNewToken from "../../../services/integrationRecNewToken";
import fetchIntegrationRecs from "../../../services/fetchIntegrationRecs";
import addIntegrationRecs from "../../../services/addIntegrationRec";
import fetchRecruiterId from '../../../services/fetchRecruiterId';
import getAllIntegrationRecs from "../../../services/getAllIntegrationRecs";
import { Table } from "semantic-ui-react";

import user from '../../../store/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Moment from 'react-moment';

import Heading from '../../../components/Heading';
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import "./styles.scss";


class ATSPanelRecs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            recs: [],
            recSearch: "",
            loading: true
        }
    }

    async componentDidMount() {
        const profile = await user.getProfile();

        console.log(this.props.match);
        let recs = [];
        if (this.props.match.params.id) {
            recs = await fetchIntegrationRecs(profile.id, this.props.match.params.id);
        } else {
            recs = await getAllIntegrationRecs(profile.id, {
                expiryLT: moment().add(30, "days").format("X"),
                expiryGT: moment().format("X"),
            })
        }
        this.setState({
            recs: recs.data.data,
            profile,
            integrationId: this.props.match.params.id,
            loading: false
        })
    }

    activate = async (id) => {
        try {
            const { profile, integrationId, recs } = this.state;
            await recActivate(profile.id, integrationId, id);

            let nRecs = recs.map(i => {
                if (i.id == id) {
                    return {
                        ...i,
                        status: 1
                    };
                }
                return i;
            })
            this.setState({ recs: nRecs });
            toast.success("Activated Successfully");

        } catch (err) {
            toast.error(err.response.data.message);
        }

    }

    deactivate = async (id) => {
        try {
            const { profile, integrationId, recs } = this.state;
            await recDeactivate(profile.id, integrationId, id);
            let nRecs = recs.map(i => {
                if (i.id == id) {
                    return {
                        ...i,
                        status: 0
                    };
                }
                return i;
            })
            this.setState({ recs: nRecs });
            toast.success("DeActivated Successfully");
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    recnewtoken = async (id) => {
        const confirm = window.confirm("This action will generate new token and remove the current one, Confirm if you want to continue");
        if (!confirm) return;
        try {
            const { profile, integrationId, recs } = this.state;
            const resp = await recNewToken(profile.id, integrationId, id);
            let nRecs = recs.map(i => {
                if (i.id == id) {
                    return {
                        ...i,
                        token: resp.data.data.token
                    };
                }
                return i;
            })
            this.setState({ recs: nRecs });

            toast.success("New Token Generated Successfully");
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    onChangeRecId = (event) => {
        this.setState({
            recSearch: event.target.value
        })
    }

    addRecruiter = async () => {
        const { recSearch = "", profile } = this.state;

        if (!recSearch.trim()) return;
        let recId = null;
        if (isNaN(recSearch)) {

            try {
                const recResp = await fetchRecruiterId(profile.id, { email: recSearch });
                recId = recResp.data.id;
            } catch (err) {
                toast.error('Recruiter Not Found');
            }

        } else {
            recId = recSearch;
        }

        try {
            await addIntegrationRecs(profile.id, this.state.integrationId, {
                recruiterId: recId
            });
            window.location.reload();
        } catch (err) {
            toast.error(err.response.data.message);
        }

    }

    render() {
        const { recs, loading, integrationId } = this.state;

        return (

            <div>
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />
                <div className="pageHeading">
                    <Heading text={`ATS Integration Management > Recruiters ${(integrationId || loading) ? "" : "with token expiry of less than 30 days"}`} />
                    <Button skin='dark' clickButton={() => this.props.history.push("/ats-panel-integrations")} text="Go To Integations" />
                </div>
                {
                    integrationId ?
                        <div className='right'>
                            <InputBox placeholder='Enter Recruiter ID/Email' value={this.state.recSearch} onChange={this.onChangeRecId} />
                            <Button skin='dark' disabled={false} text='Add Recruiter' clickButton={this.addRecruiter} />
                        </div> : null
                }

                {
                    recs.length ?
                        <Table fixed selectable className="rec-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Recruiter Details</Table.HeaderCell>
                                    <Table.HeaderCell>Integration</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Token</Table.HeaderCell>
                                    <Table.HeaderCell>Expiry</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    recs.map((item, i) => {
                                        return (
                                            <>
                                                <Table.Row>
                                                    <Table.Cell>{item.id} </Table.Cell>
                                                    <Table.Cell>{item.rec.name} ({item.recruiter_id}) <br /> {item.rec.email} <br/><br/> {item.rec.organisation} </Table.Cell>
                                                    <Table.Cell>{item.integrationName} </Table.Cell>
                                                    <Table.Cell>{item.status == 1 ? "Active" : "InActive"} </Table.Cell>
                                                    <Table.Cell>{item.token}</Table.Cell>
                                                    <Table.Cell>
                                                        <Moment format='DD-MM-YYYY'>{item.expiry * 1000}</Moment>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {
                                                            item.status == 1 ?
                                                                <Button skin='dark' text="DeActivate" clickButton={() => this.deactivate(item.id)} /> :
                                                                <Button skin='dark' text="Activate" clickButton={() => this.activate(item.id)} />
                                                        }
                                                        <Button skin='dark' text="Generate New Token" clickButton={() => this.recnewtoken(item.id)} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            </>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table> : null
                }

                {
                    !loading && !recs.length ?
                        <div className="no-data">No Data Found</div> : null
                }

            </div>
        )
    }

}

export default ATSPanelRecs;