import axios from 'axios';
import Cookies from 'js-cookie';
import { isTechDomain} from '../utils/utilFunctions'

const cookieName = process.env.REACT_APP_COOKIE_NAME
const oldCookieName= process.env.REACT_APP_OLD_COOKIE_NAME

const authToken = Cookies.get(cookieName, { domain: process.env.REACT_APP_DOMAIN })

const oldCookie = Cookies.get(oldCookieName, { domain: process.env.REACT_APP_DOMAIN })

const config = axios.create({
    baseURL: !isTechDomain() ?  process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_TECH,
    withCredentials: true
})

const currentUrl=window.location.href;

// if(!authToken && oldCookie) {
//     window.location.href = process.env.REACT_APP_LOGIN_URL+'/transition?callbackadmin='+currentUrl;
// }

// if(!authToken){
//     window.location.href = process.env.REACT_APP_LOGIN_URL+'/transition?callbackadmin='+currentUrl; 
// }

config.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;

config.interceptors.response.use(function(resp) {
    return resp
}, function(error) {
    if(error.response.status == 401) {
        window.location.href = (isTechDomain() ?  process.env.REACT_APP_LOGIN_URL_TECH : process.env.REACT_APP_LOGIN_URL) + '/transition'
    } 
    if(error.response.status == 403){
        window.location.href = '/notAdmin'
        return Promise.reject(error)
    }
    return Promise.reject(error);
})

export default config;