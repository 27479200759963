import React from 'react';
import Routes from '../../routes';
import './App.scss';
import Notifications from '../Notifications';
import user from '../../store/user';
import { adminFeatureMapping } from '../../models/adminFeatureMapping';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        if (window.notificationHandler) {
            
            const profile = await user.getProfile();

            if (user.checkFeatureAccess(adminFeatureMapping.NOTIFY_USERS)) {
                notificationHandler.setup({
                    baseURL: process.env.REACT_APP_WEBSOCKET_BASE_URL,
                    id: profile.id,
                    email: profile.email,
                    product: process.env.REACT_APP_PLATFORM
                });
            }

            // if (process.env.REACT_APP_NOTIFY_USERS.includes(profile.email)) {
                
            // }

        } else {
            console.log("notificationHandler is not supported!")
        }
    }

    render() {
        return (
            <div>
                <Notifications />
                {Routes}
            </div>
        );
    }
}

App.propTypes = {
};

export default App;
