import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import './FromToDatepicker.css'

class FromToDatePicker extends Component {

	render() {
		return (
			<div className='fromToDatePicker'>
				{
					this.props.showLabels ?
						<span className='date-pick-label'>From : </span> : null
				}
				<DatePicker className='datePicker'
					selected={this.props.startDate}
					placeholderText='From'
					selectsStart
					onChange={this.props.handleChangeStart}
					dateFormat='dd/MM/yyyy'
					minDate={this.props.minDate}
				/>
				{
					this.props.showLabels ?
						<span className='date-pick-label'>To : </span> : null
				}
				<DatePicker className='datePicker'
					selected={this.props.endDate}
					placeholderText='To'
					selectsEnd
					minDate={this.props.startDate}
					// endDate={this.props.endDate}
					dateFormat='dd/MM/yyyy'
					onChange={this.props.handleChangeEnd}
					maxDate={this.props.maxDate}
					disabled={this.props.disabled}
				/>
			</div>

		);
	}
}

FromToDatePicker.propTypes = {
	startDate: PropTypes.any,
	endDate: PropTypes.any,
	handleChangeStart: PropTypes.func,
	handleChangeEnd: PropTypes.func,

}

export default FromToDatePicker;