import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Dropdown.scss'

class Dropdown extends Component {
    render() {
        return (
            <select className={this.props.className} onChange={this.props.dropdownChange} name={this.props.name} value={this.props.defaultValue}>
                {this.props.data.map(aData => {
                    if(aData.isCategory) {
                        return (
                            <optgroup key={aData.id} label={aData.label}>
                                {aData.items.map(item => {
                                    return (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    )
                                })}
                            </optgroup>
                        )
                    }else {
                        return( 
                            <option 
                                value={aData.id} 
                                key={aData.id} 
                                disabled={aData.disabled ? aData.disabled : null}
                                selected={aData.selected ? aData.selected : null}
                                >
                                {aData.name}
                            </option>
                        )
                    }
                })}
            </select>
        )
    }
}

Dropdown.propTypes = {
    className: PropTypes.any,
    dropdownChange: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    data: PropTypes.array
}

export default Dropdown;