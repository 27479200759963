import React, { useState, useEffect } from 'react'
import Button from '../../../components/Button'
import InputBox from '../../../components/InputBox'
import Dropdown from '../../../components/Dropdown/Dropdown'

import fetchDiversityPlansList from '../../../services/fetchDivesityPlansList'
import fetchCompanyList from '../../../services/fetchCompanyList'
import addDiversityPlan from '../../../services/addDiversityPlan'
import updateDiversityPlan from '../../../services/updateDiversityPlan'

import { getQueryParams } from '../../../utils/utilFunctions'

import { default as adminProfile } from '../../../store/user';

import { Table } from 'semantic-ui-react'
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment'

import './DiversityPlanAdd.scss'

const DiversityPlanAdd = (props) => {

    const [adminId, setAdminId] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [user, setUser] = useState('');
    const [userInputError, setUserInputError] = useState('');
    const [formError, setFormError] = useState('');
    const [planData, setPlanData] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [formData, setFormData] = useState({
        recruiterId: '',
        domain: '',
        company: '',
    });

    // eslint-disable-next-line react/prop-types
    const urlParams = getQueryParams(props.location.search)

    useEffect(() => {
        (async () => {
            const profile = await adminProfile.getProfile();
            setAdminId(profile.id)
            const { data } = await fetchCompanyList(profile.id);
            const list = [{ id: '', name: 'Select Company' }, ...data.data ];
            setCompanyList(list);

            // params
            if (urlParams.id) {
                setIsEdit(true);
                const response = await fetchDiversityPlansList(profile.id, { autoId: urlParams.id });
                setShowForm(true);
                setFormData({
                    recruiterId: response.data.data.data[0].recruiter_id,
                    domain: response.data.data.data[0].domain,
                    company: response.data.data.data[0].company_id,
                });
            }
        })()
    }, []);

    const changeHandler = (ev) => {
        const { name, value } = ev.target
        setFormData((form) => ({ ...form, [name]: value }))
    };

    const onChange = (ev) =>  {
        setUser(ev.target.value)
        setUserInputError('');
    };
    const onSearch = async () => {
        if (user) {
            const params = { user, };
            try {
                const { data } = await fetchDiversityPlansList(adminId, params);
                setIsEdit(false);
                if (data.data.data.length) {
                    setShowTable(true);
                    setShowForm(false);
                    setFormData({
                        recruiterId: '',
                        domain: '',
                        company: '',
                    });
                } else {
                    setShowForm(true);
                    setShowTable(false);
                    setFormData((form) => ({
                        ...form,
                        recruiterId: data.data.recruiterId,
                        domain: '',
                        company: '',
                    }));
                }
                setUserInputError('');
                setPlanData(data.data.data);
            } catch (error) {
                if (error.message == 'Request failed with status code 400') {
                    setUserInputError('No Recruiter Found');
                }
                setShowForm(false);
                setShowTable(false);
            }
        } else {
            setUserInputError('Please Enter email or recruiter id');
        }
    };

    const onSubmit = async () => {
        if (validate()) {
            setFormError('');
            let companyName = '';
            for (const company of companyList) {
                if (company.id == formData.company) {
                    companyName = company.name;
                    break;
                }
            }
            const data = {
                recruiterId: formData.recruiterId,
                companyId: formData.company,
                domain: formData.domain,
                companyName,
            }
            let res;
            if (isEdit && urlParams.id) {
                data.autoId = urlParams.id;
                res = await updateDiversityPlan(adminId, data);
            } else {
                res = await addDiversityPlan(adminId, data);
            }
            setShowForm(false);
            setFormData({
                recruiterId: '',
                domain: '',
                company: '',
            });
            toast.info(res.data.message);
            // eslint-disable-next-line no-undef
            setTimeout(()=> {
                // eslint-disable-next-line no-undef
                window.location.href = '/diversityPlanAdd';
            }, 2000);
        } else {
            setFormError('Please Enter Valid Details');
        }
    };

    const validate = () => {
        for (const field in formData) {
            if (!formData[field]) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className='diversity-plan-add-container'>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
            < div className='topbar'>
                <div className='page-heading'>
                    <h4>Search for recruiters</h4>
                </div>
                <div className='redirect-btns'>
                    <Button
                        text='Plan Management'
                        skin='light'
                        // eslint-disable-next-line no-return-assign, no-undef
                        clickButton={() => window.location.href = '/diversityPlanList'}
                    />
                    <Button
                        className='older-version-btn'
                        text='Go to Older Version'
                        skin='light'
                        // eslint-disable-next-line no-return-assign, no-undef
                        clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/diversityPlanAdd.php`}
                    />
                </div>
            </div>
            <div className='search-container'>
                <div className='user-input-field'>
                    <InputBox
                        name="recruiter"
                        type="text"
                        placeholder="Enter email or ID"
                        value={user}
                        onChange={onChange}
                    />
                    <span className='error-text error-field'>{userInputError}</span>
                </div>
                <Button
                    text="Search"
                    skin="light"
                    clickButton={onSearch}
                />
                <Button
                    text="Clear Filters"
                    skin="dark"
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href = '/diversityPlanAdd'}
                />
            </div>
            {showTable ?
                <div className='plan-list-container'>
                    <Table
                        fixed
                        selectable
                        className="plan-list"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Diversity Id
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Recruiter Id
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Company
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Domain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Created By
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Created On
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {planData.map((plan) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>
                                            {plan.id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {plan.recruiter_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {plan.company_id} - {plan.company_name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {plan.domain}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {plan.created_by}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment(plan.created_on).format('YYYY-MM-DD hh:mm:ss')}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
                :
                null
            }
            {showForm ?
                <div className='edit-plan-form'>
                    <h4>Edit Plan</h4>
                    <div className='form-group'>
                        <label>Recruiter ID<span className='mandatory-mark'>*</span></label>
                        {formData.recruiterId}
                    </div>
                    <div className='form-group'>
                        <label>Domain<span className='mandatory-mark'>*</span></label>
                        <InputBox
                            type="text"
                            name="domain"
                            value={formData.domain}
                            onChange={changeHandler}
                            className="formInput"
                        />
                    </div>
                    <div className='form-group'>
                        <label>Company<span className='mandatory-mark'>*</span></label>
                        <Dropdown
                            name="company"
                            className="formInput"
                            data={companyList}
                            dropdownChange={changeHandler}
                            defaultValue={formData.company}
                        />
                    </div>
                    <div className='error-text form-group'>{formError}</div>
                    <Button
                        text="Submit"
                        skin="light"
                        clickButton={onSubmit}
                    />
                </div>
                :
                null
            }
        </div>
    )
}

export default DiversityPlanAdd