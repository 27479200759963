/* eslint-disable import/first */
import React,{Component} from 'react';
import Editor from 'react-medium-editor';
import PropTypes from 'prop-types';
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');
import './MediumEditor.scss';


class mediumEditor extends Component {
    shouldComponentUpdate = (nextProps, nextState) => {
        return nextProps.value !== this.props.value 
    }
    render(){
        return (
            <Editor
                onChange={this.props.onChange}
                options={{
                    placeholder: { text: `${this.props.placeholder || ''}` },
                    toolbar: { buttons: ['bold', 'italic', 'underline', 'anchor', 'unorderedlist'] },
                    paste: {
                        cleanTags: ['meta', 'span', 'style', 'scripts'],
                        unwrapTags: ['span']
                    },
                    disableExtraSpaces: true,
                    hideOnClick: false,
                    ...this.props.editorOptions,
                }}
                text={this.props.value}
            />
        );
    }
}

mediumEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    editorOptions: PropTypes.object,
}

export default mediumEditor;
