import axios from '../utils/axios';

export default function getLastActiveSubscription(adminId, params) {
    return axios
        .get(`/admin/${adminId}/jobseeker-admin/v2/admin-recruiter/lastactivesubscription`, { params })
        .then(response => {
            return response.data.data;
        })
        .catch(err => {
            return {};
        });
}