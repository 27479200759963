import React from 'react';
import './TableHeading.scss';

const tableHeading = (props) => {
    return (
        <div className="tableHeading hidden-xs">
            <div className="col-sm-5 px-0">
                <div className="newhead col-date">
                    <div className="headtext">
                        <h3 className="hidden-xs">Date</h3>
                    </div>
                </div>
            
                <div className="newhead col-title">
                    <div className="headtext">
                        <h3 className="hidden-xs">Title</h3>
                    </div>
                </div>
            </div>

            <div className="col-sm-3 pull-left px-0 pdright10768">
                <div className="col-sm-6 pull-left px-0 pdright10768">
                    <div className="newhead">        
                        <div className="headtext">
                            <h3 className="hidden-xs">Status</h3>
                        </div>
                    </div>  
                </div>
                <div className="col-sm-6 pull-left px-0 pdright10768">
                    <div className="newhead">
                        <div className="headtext">
                            <h3 className="hidden-xs">Views/Applied</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-4 pull-left px-0">
                <div className="newhead">
                    <div className="headtext">
                        <h3 className="hidden-xs">Action</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default tableHeading;