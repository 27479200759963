import moment from "moment";
import React, { useEffect, useState } from "react";
import fetchProLeadReport from "../../services/fetchProLeadReport";
import "./proLeadGenReport.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import { 
    categories
} from "./constants";
import { dates } from "./constants";
import { todayValues } from "./constants";
import { todaySuccessValues } from "./constants";
import { planWiseMap } from "./constants";
import { getPlanwiseValues } from "./constants";

const ProLeadGenReport = (props) => {
    const [data, setData] = useState({});

    const current = moment().format("YYYY-MM-DD");
    const days = {
        today: moment(current).format("DD-MM-YYYY"),
        yesterday: moment(current).subtract(1, "day").format("DD-MM-YYYY"),
        dayBeforeYesterday: moment(current).subtract(2, "day").format("DD-MM-YYYY"),
        currMonth: moment(current).format("MMM YYYY"),
        lastMonth: moment(current).subtract(1, "month").format("MMM YYYY"),
        lastToLastMonth: moment(current).subtract(2, "month").format("MMM YYYY")
    }

    useEffect(() => {
        user.getProfile().then(profile => {
            fetchProLeadReport(profile.id)
                .then(resp => {
                    setData(resp.data.data);
                }).catch(err => {
                    console.log(err);
                })
        })
    }, []);

    return (
        <div className="pro-lead-gen-wrapper">
            <div className="pro-lead-gen-heading">
                <Heading text='Pro Lead Analysis' />
                <div className='right-aligned'>
                      <div className="use-old-btn">
                          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/proleadgenreport.php`} target='_blank'>
                              <Button skin='dark' disabled={false} text='Use Old Version'/>
                          </a>
                      </div>

                </div>
            </div>
            <div className="row text-center">
                {
                    Object.keys(data).length ? (
                        <div>
                            <h2>Buy Now Click Analysis</h2>
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            categories.map(category => {
                                                return (
                                                    <Table.HeaderCell>{category}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>
        
                                <Table.Body>
                                
                                    {
                                        dates.map((date, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{days[date]}</Table.Cell>
                                                    {
                                                        todayValues.map(value => {
                                                            return (
                                                                <Table.Cell>{data[date][value]}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
        
                                </Table.Body>
                            </Table>
                        </div>
                    ):null
                }
               
            </div>

            <div className="row text-center">
            {
                Object.keys(data).length ? (
                    <div>
                        <hr className="table-separator"/>
                        <h2>Success Transaction</h2>
                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    {
                                        categories.map(category => {
                                            return (
                                                <Table.HeaderCell>{category}</Table.HeaderCell>
                                            )
                                        })
                                    }
                                </Table.Row>
                            </Table.Header>
    
                            <Table.Body>
                            
                                {
                                    dates.map((date, i) => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>{days[date]}</Table.Cell>
                                                {
                                                    todaySuccessValues.map(value => {
                                                        return (
                                                            <Table.Cell>{data[date][value]}</Table.Cell>
                                                        )
                                                    })
                                                }
                                            </Table.Row>
                                        )
                                    })
                                }
    
                            </Table.Body>
                        </Table>
                    </div>
                ):null
            }
            </div>

            <div className="row text-center">
            {
                Object.keys(data).length ? (
                    <div>
                        <hr className="table-separator"/>
                        <h2>Success Transaction Plan Wise</h2>
                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                {
                                    categories.map(category => {
                                        return (
                                            <Table.HeaderCell>{category}</Table.HeaderCell>
                                        )
                                    })
                                }
                                </Table.Row>
                            </Table.Header>
    
                            <Table.Body>
                            
                            {
                                [...Array(4).keys()].map(item => {
                                    return(
                                        <Table.Row>
                                        <Table.Cell>{planWiseMap[item+1]}</Table.Cell>
                                            {
                                                getPlanwiseValues(item+1).map(item => {
                                                    return <Table.Cell>{data['planwise'][item]}</Table.Cell>
                                                })
                                            }
                                        </Table.Row>
                                    )
                                })
                            }
    
                            </Table.Body>
                        </Table>
                    </div>
                ):null
            }
            </div>
        </div>
    )

}

export default ProLeadGenReport;