import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Table } from 'semantic-ui-react';

import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import TableItem from '../Components/TableItem';
import Loader from '../../../components/Loader';
import Dropdown from '../../../components/Dropdown';
import AdminAccessModal from '../Components/AdminAccessModal';
import BulkActionsModal from '../Components/BulkActionsModal';

import user from '../../../store/user';
import fetchRecruiterList from '../../../services/fetchRecruiterList';
import fetchRecruiterListCount from '../../../services/fetchRecruiterListCount';
import updateRecruiterProfile from '../../../services/updateRecruiterProfile';
import activateRecruiter from '../../../services/activateRecruiter';
import resendActivationLink from '../../../services/resendActivationLink';
import getJobsMetaData from '../../../services/getJobsMetaData';
import fetchVerifiedRecruiters from '../../../services/fetchVerifiedRecruiters';
import updateRecruiterVerificationStatus from '../../../services/updateRecruiterVerificationStatus';
import updateFeatureAccess from '../../../services/updateFeatureAccess';
import getVerificationDocument from '../../../services/getVerificationDocument';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import { getQueryParams } from '../../../utils/utilFunctions';
import bulkActivateRecruiters from '../../../services/bulkActivateRecruiters';
import bulkDeactivateRecruiters from '../../../services/bulkDeactivateRecruiters';

import { RECRUITER_MGT, SEARCH_FLAGGED_USERS } from '../../../models/routes';

import './RecruiterList.scss';

const modalKeys = {
    FEATURE_ACCESS: 'change_admin_access',
    BULK_ACTION_DEACTIVATE: 'bulk_action_deactivate',
    BULK_ACTION_ACTIVATE: 'bulk_action_activate'
};

const bulkActionsDropdown = [
    {
        id: 0,
        name: 'Select Action'
    },
    {
        id: 1,
        name: 'Bulk Activate Accounts'
    },
    {
        id: 2,
        name: 'Bulk Deactivate Accounts'
    }
];

const activeRecruitersFilterData = [
    { id: -1, name: 'Select Recruiters Status' },
    { id: 0, name: 'Inactive'},
    { id: 1, name: 'Active' }
];

const RecruiterListContainer = props => {
    const pageContent = 50;

    const [userProfile, setUserProfile] = useState(null);

    const [recruiterList, setRecruiterList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);

    const [enteredQuery, setEnteredQuery] = useState('')

    const [bulkDropdownValue, setBulkDropdownValue] = useState(null);
    const [activeRecruitersFilterValue, setActiveRecruitersFilterValue] = useState(-1);
    const [modalController, setModalController] = useState({
        showModal: null,
        modalData: null,
        isLoading: false
    });

    const [bulkSelected, setBulkSelected] = useState([]);
    const [verifyAccess, setVerifyAccess] = useState(false);

    // handles bulk actions
    const handleBulkActionsSubmit = (event) => {
        
        if (!bulkDropdownValue || bulkActionsDropdown == 0) {
            return;
        }

        const option = window.confirm('Are you sure you want to proceed with this bulk action?');

        if (!option) {
            return;
        }

        if (bulkDropdownValue == 1) {
            handleBulkActivate();
        } else {
            handleBulkDeactivate();
        }

    };

    const handleOpenAccessModal = (recId, recEmail, featuresAccess, overrideInitials) => {
        setModalController({
            showModal: modalKeys.FEATURE_ACCESS,
            isLoading: false,
            modalData: {
                recruiterId: recId,
                recruiterEmail: recEmail,
                featuresAccess,
                adminFeatureMap: userProfile.adminFeatureMap,
                checkedFeatures: featuresAccess,
                overrideInitials,
                isBulkAction: false,
            }
        });
    };

    const handleBulkActivate = async () => {
        try {
            const dataArr = [];

            bulkSelected.map(item => {
                if (item.status == 0) {
                    dataArr.push({
                        recruiterId: item.recruiterId,
                        recruiterEmail: item.recruiterEmail,
                        recruiterName: item.recruiterName
                    });
                }
            });

            if (dataArr.length === 0) {
                if (bulkSelected.length == 0) {
                    toast.error('No recruiters selected for the action');
                } else {
                    toast.error(`Selected ${bulkSelected.length > 1 ? 'accounts are' : 'account is'} already activated`);
                }
                return;
            }

            setModalController({
                showModal: modalKeys.BULK_ACTION_ACTIVATE,
                isLoading: true,
                modalData: {
                    actiontype: modalKeys.BULK_ACTION_ACTIVATE
                }
            });

            const response = await bulkActivateRecruiters(userProfile.id, { recruiterArr: dataArr });

            setModalController(prevObj => {
                const newModalData = { ...prevObj.modalData };
                newModalData['response'] = response.data.data;
                return {
                    ...prevObj,
                    isLoading: false,
                    modalData: newModalData
                }
            });

            onSearchSubmitHandler();

        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later!');
            handleModalClose();
        }
    };

    const handleBulkDeactivate = async () => {
        try {
            const recruiterIdArr = [];

            bulkSelected.map(item => {
                if (item.status != 0) {
                    recruiterIdArr.push(item.recruiterId);
                }
            });

            if (recruiterIdArr.length === 0) {
                if (bulkSelected.length == 0) {
                    toast.error('No recruiters selected for the action');
                } else {
                    toast.error(`Selected ${bulkSelected.length > 1 ? 'accounts are' : 'account is'} already deactivated`);
                }
                return;
            }

            setModalController({
                showModal: modalKeys.BULK_ACTION_DEACTIVATE,
                isLoading: true,
                modalData: {
                    actiontype: modalKeys.BULK_ACTION_DEACTIVATE
                }
            });
            
            const response = await bulkDeactivateRecruiters(userProfile.id, { recIdArr: recruiterIdArr });

            setModalController(prevObj => {
                const newModalData = { ...prevObj.modalData };
                newModalData['response'] = response.data.data;
                return {
                    ...prevObj,
                    isLoading: false,
                    modalData: newModalData,
                }
            });

            onSearchSubmitHandler();

        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later!');
            handleModalClose();
        }
    };

    const handleModalSave = async () => {
        setModalController(prevValue => {
            return {
                ...prevValue,
                isLoading: true
            };
        });
        
        try {
            const dataArray = [];

            if (modalController.modalData.isBulkAction) {
                bulkSelected.forEach(item => {
                    dataArray.push({
                        ...item,
                        features: modalController.modalData.checkedFeatures
                    });
                });
            } else {
                dataArray.push({
                    recruiterId: modalController.modalData.recruiterId,
                    recruiterEmail: modalController.modalData.recruiterEmail,
                    features: modalController.modalData.checkedFeatures,
                    overrideInitials: modalController.modalData.overrideInitials ? modalController.modalData.overrideInitials : null
                });
            }
            const response = await updateFeatureAccess(userProfile.id, {
                recruiters: dataArray
            });

            if (response && response.status && response.status == 200) {
                toast.success('Recruiter Feature Access updated successfully');

                onSearchSubmitHandler();
            } else {
                toast.error('Something went wrong. Please try again later')
            }

        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong. Please try again later')
        }

        handleModalClose();
    };

    const handleModalClose = () => {
        setModalController({
            modalData: null,
            isLoading: false
        });
    };

    useEffect(() => {
        async function getProfile() {
            const profile = await user.getProfile()
            return profile
        }

        // check for query params
        const params = getQueryParams(props.location.search);
            
        if (params && params.search) {
            setEnteredQuery(params.search);
        }

        getProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
                setUserProfile(userData);
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }

            if (user.checkFeatureAccess(adminFeatureMapping.VERIFY_REC)) {
                setVerifyAccess(true);
            }
        });
    }, []);

    useEffect(() => {
        if (userProfile) {
            onSearchSubmitHandler();
        }
    }, [userProfile]);

    const getRecruiterListCount = async (query = null, recruiterStatus = null) => {
        const dataObj = {};

        let searchQuery = enteredQuery;

        if (query !== null) {
            searchQuery = query;
        }

        if (parseInt(searchQuery)) {
            dataObj['id'] = searchQuery.trim()
        } else {
            dataObj['email'] = searchQuery.trim()
        }

        /* 
            null : no active recruiter filter
            1 : only active recruiters
            0 : only inactive recruiters 
        */

        let recStatus = recruiterStatus || activeRecruitersFilterValue
        recStatus = (recStatus == -1) ? null : recStatus

        dataObj['status'] = recStatus
        
        const response = await fetchRecruiterListCount(userProfile.id, dataObj)
        if (response.data) {
            const count = response.data.count;
            setTotalResults(count);
        } else {
            setTotalResults(0)
        }
    }

    // get recruiter list from api 
    const getRecruiterList = async (page, query = null, recruiterStatus = null) => {
        const dataObj = {
            pageNumber: page,
            pageContent: 50,
            page: 'recruiter_mgt'
        };

        let recStatus = recruiterStatus || activeRecruitersFilterValue
        recStatus = (recStatus == -1) ? null : recStatus
        
        /* 
            null : no active recruiter filter
            1 : only active recruiters
            0 : only inactive recruiters 
        */
        
        dataObj["status"] = recStatus

        let searchQuery = enteredQuery;

        if (query !== null) {
            searchQuery = query;
        }

        if (+searchQuery) {
            dataObj['id'] = searchQuery.trim()
        } else {
            dataObj['email'] = searchQuery.trim()
        }

        const response = await fetchRecruiterList(userProfile.id, dataObj)
        
        if (response.data && response.data.length > 0) {
            let data = response.data;

            const recIdArr = []
            data.forEach(aRow => {
                recIdArr.push(aRow.id);
            });
            
            const metaData = await getJobsMetaData(userProfile.id, {
                recIdArray: recIdArr.join(',')
            });

            const verificationStatus = await fetchVerifiedRecruiters(userProfile.id, {
                recruiterIds: recIdArr.join(',')
            });
            
            data = data.map(aRow => {
                return {
                    ...aRow,
                    ...metaData.data[aRow.id],
                    verificationStatus: verificationStatus.data[aRow.id]['verificationStatus']
                }
            });

            setRecruiterList(data);
            onRouteChange(searchQuery, page);

        } else {
            setRecruiterList([])
        }
    }

    //update recruiter profile
    const onProfileUpdate = async (data, recruiterId, message) => {
        let response
        if(data.verificationStatus != undefined) {
            response = await updateRecruiterVerificationStatus(userProfile.id, recruiterId, data)
        } else {
            response = await updateRecruiterProfile(userProfile.id, recruiterId, data)
        }
        if (response && response.status && response.status === 200) {
            toast.success(message ? message : 'Recruiter profile updated successfully')
            // setRecruiterList([])
            // setEnteredQuery('')

            onSearchSubmitHandler();
        } else {
            toast.error('Update failed. Please try again later')
        }
    }

    // on activate recruiter
    const onActivate = async (recruiterId, email, name) => {
        const response = await activateRecruiter(userProfile.id, recruiterId, { email: email, name: name })
        if (response && response.status && response.status === 200) {
            toast.success('Recruiter activated successfully')

            onSearchSubmitHandler();
        } else {
            toast.error('Update failed. Please try again later')
        }
    }

    // resend activation email
    const onResendActLink = async (recruiterId) => {
        const response = await resendActivationLink(userProfile.id, recruiterId);
        if (response && response.status && response.status === 200) {
            toast.success('Activation Email sent successfully')
        } else {
            toast.error('Could not send email. Please try again later')
        }
    }

    // on search value change
    const onSearchChangeHandler = (e) => {
        let value = e.target.value;
        setEnteredQuery(e.target.value);
    }

    const onKeyPressHandler = (event) => {
        if(event.key === 'Enter') {
            onSearchSubmitHandler(null, 1);
        }
    }

    const onRouteChange = (search, page) => {
        props.history.push(`${RECRUITER_MGT}?search=${search}&page=${page}`);
    }

    const onClearHandler = async () => {
        props.history.push(RECRUITER_MGT);

        setEnteredQuery('');
        setBulkSelected([]);
        setRecruiterList([]);
        setTotalResults(0);
        setPageNumber(1)
    };

    // when search submitted
    const onSearchSubmitHandler = async (event, page = null, recruiterStatus = null) => {
        if (enteredQuery.trim().length == 0) {
            return;
        }

        let pg = page || pageNumber;

        setIsLoading(true)
        setBulkSelected([]);
        await getRecruiterList(pg, null, recruiterStatus);
        await getRecruiterListCount(null, recruiterStatus);
        setPageNumber(pg);
        setIsLoading(false)
    }

    const handlePageChange = async (page) => {
        setPageNumber(page)
        setIsPageLoading(true)
        await getRecruiterList(page)
        setIsPageLoading(false)
    }

    const handleSelectAll = () => {
        if (bulkSelected.length === recruiterList.length) {
            setBulkDropdownValue(null);
            setBulkSelected([]);
            return;
        }
        const tempArr = [];
        recruiterList.map(item => {
            tempArr.push({
                recruiterId: item.id,
                recruiterEmail: item.email,
                recruiterName: item.recname,
                status: item.status
            });
        });

        setBulkSelected(tempArr);
    }

    const handleRecruiterSelected = (recId, recEmail, recName, checked, recStatus) => {
        if (checked) {
            setBulkSelected(prevValue => {
                return [...prevValue, { recruiterId: recId, recruiterEmail: recEmail, recruiterName: recName, status: recStatus }];
            });
        } else {
            setBulkSelected(prevValue => {
                const tempArr = prevValue.filter(item => item.recruiterId!=recId);
                return tempArr;
            });
        }
    }

    const bulkSelectedModal = () => {
        setModalController({
            showModal: modalKeys.FEATURE_ACCESS,
            isLoading: false,
            modalData: {
                adminFeatureMap: userProfile.adminFeatureMap,
                checkedFeatures: [],
                isBulkAction: true
            }
        });
    };

    const handleLoadVerificationDocument = async (docName) => {
        try {
            const response = await getVerificationDocument(userProfile.id, docName, {});

            if (response.data && response.data.data && response.data.data.url) {
                window.open(response.data.data.url, '_blank');
            } else {
                toast.error('Error retrieving document. Please try again later.');
            }

        } catch (error) {
            // console.log(error);
            toast.error('Error retrieving document. Please try again later.');
        }
    };

    const handleActiveRecruitersFilterDropdown = (event) => {
        setActiveRecruitersFilterValue(event.target.value);
        onSearchSubmitHandler(event, 1, event.target.value)
    }

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width='one'>Id</Table.HeaderCell>
                <Table.HeaderCell width='one'>Name</Table.HeaderCell>
                <Table.HeaderCell width='one'>Email</Table.HeaderCell>
                <Table.HeaderCell width='one'>Phone</Table.HeaderCell>
                <Table.HeaderCell width='one'>Organisation</Table.HeaderCell>
                <Table.HeaderCell width='two'>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )

    let mainContent;

    if (isLoading || isPageLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        )
    } else if (!isLoading && (!recruiterList || recruiterList.length === 0)) {
        mainContent = <></>
    } else {
        mainContent = (
            <Table>
                {tableHeading}
                <tbody>
                    {
                        recruiterList.map(item => {
                            let isSelected = false;
                            const tempItem = bulkSelected.filter(obj => {
                                return obj.recruiterId==item.id
                            });
                            if (tempItem.length > 0) {
                                isSelected = true;
                            }
                            return (
                                <TableItem 
                                    key={item.id} 
                                    email={userProfile.email} 
                                    accessMap={userProfile.adminPermissions} 
                                    item={item} 
                                    onProfileUpdate={onProfileUpdate} // to update profile
                                    onActivate={onActivate}  // to activate profile
                                    onResendActLink={onResendActLink}  // to send activation link
                                    onAdminAccessModal={handleOpenAccessModal}  // to change features access
                                    isSelected={isSelected} 
                                    verifyAccess={verifyAccess} 
                                    onRecruiterSelected={handleRecruiterSelected} 
                                    showAdminAccess={process.env.REACT_APP_ADMIN_ACCESS && process.env.REACT_APP_ADMIN_ACCESS.includes(userProfile.email) ? true : false} 
                                    loadDocument={handleLoadVerificationDocument}
                                />
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    let topPageIndicators;
    let bottomPageIndicators;

    if (!isLoading && recruiterList && recruiterList.length > 0) {
        topPageIndicators = (
            <div className="top-page-controller">
                <div className='row text-center'>
                    <Pagination totalItemsCount={totalResults} content={pageContent} pageNumber={pageNumber} handlePageChange={handlePageChange} />
                    <CountDisplay start={(pageNumber - 1) * pageContent + 1} end={pageNumber * pageContent} total={totalResults} />
                </div>
            </div>
        )
        if (!isPageLoading) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination totalItemsCount={totalResults} content={pageContent} pageNumber={pageNumber} handlePageChange={handlePageChange} />
                </div>
            )
        }
    } else {
        topPageIndicators = <div></div>
        bottomPageIndicators = <div></div>
    }

    return (
        <div className="page-content">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className='page-heading'>
                <Heading text="Recruiter Management" />
            </div>

            <div className="row">
                <div className="search__row">
                    <div className='search__row--form'>
                        <InputBox placeholder='Search' onChange={onSearchChangeHandler} onKeyPress={onKeyPressHandler} value={enteredQuery} />
                        <Button skin='dark' disabled={false} text='Search' clickButton={event => onSearchSubmitHandler(event, 1)} />
                        <Button skin='light' disabled={false} text='Clear' clickButton={onClearHandler} />
                    </div>
                    <div className="search__row--action">
                        <div>
                            <Dropdown 
                                data={activeRecruitersFilterData}
                                dropdownChange={event => {
                                    handleActiveRecruitersFilterDropdown(event)
                                }}
                            /> 
                        </div>

                        <div>
                            {
                                userProfile && (userProfile.adminType == 2 || userProfile.adminType == 3) ? (
                                    <a href="/recruiter/adminusers" target='_blank'>
                                        <Button skin='dark' disabled={false} text='Admin Users'/>
                                    </a>
                                ) : null
                            }
                           
                        </div>
                        <div>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/recruiter_mgt.php`} target='_blank'>
                                <Button skin='dark' disabled={false} text='Use Old Version'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {!isLoading && recruiterList && recruiterList.length > 0 ? (
                <div className="bulk-btn-container">
                    <Button text="Select All" skin="dark" clickButton={handleSelectAll}/>
                    {bulkSelected.length > 0 ? (
                        <>
                            <Button text="Change Admin Access" skin="dark" clickButton={bulkSelectedModal} />
                            <div className='actions_container'>
                                <Dropdown 
                                    data={bulkActionsDropdown}
                                    dropdownChange={event => {
                                        setBulkDropdownValue(event.target.value);
                                    }}
                                />
                                <Button text="Submit Action" skin="light" clickButton={handleBulkActionsSubmit}/>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}

            {topPageIndicators}

            <div className="row">
                {mainContent}
            </div>

            {bottomPageIndicators}

            {modalController.showModal === modalKeys.BULK_ACTION_ACTIVATE || modalController.showModal === modalKeys.BULK_ACTION_DEACTIVATE ? (
                <BulkActionsModal
                    openModal={true}
                    onModalClose={handleModalClose}
                    modalData={modalController.modalData}
                    isLoading={modalController.isLoading}
                />
            ) : null}

            {/* admin feature access modal */}
            {modalController.showModal === modalKeys.FEATURE_ACCESS ? (
                <AdminAccessModal
                    openModal={true}
                    modalData={modalController.modalData}
                    isLoading={modalController.isLoading}
                    onModalClose={handleModalClose}
                    onModalSave={handleModalSave}
                    onAccessChange={(data, id) => {
                        setModalController(prevValue => {
                            const prevCheckedFeatures = prevValue.modalData.checkedFeatures;
                            const newModalData = { ...prevValue.modalData };

                            if (data.checked) {
                                newModalData.checkedFeatures = [...prevCheckedFeatures, id.toString()];
                            } else {
                                newModalData.checkedFeatures = prevCheckedFeatures.filter(itemId => itemId != id);
                            }
                            
                            return {
                                ...prevValue,
                                modalData: newModalData
                            }
                        });
                    }}
                    onDisplayTagChange={event => {
                        if (event && event.target && event.target.value) {
                            const value = event.target.value
                            setModalController(prevValue=> {
                                const newModalData = { ...prevValue.modalData };
                                newModalData['overrideInitials'] = value;

                                return {
                                    ...prevValue,
                                    modalData: newModalData
                                };
                            });
                        }
                        
                    }}
                />
            ) : null}

        </div>
    )
}

RecruiterListContainer.propTypes = {
    location: PropTypes.any,
    history: PropTypes.any
}

export default withRouter(RecruiterListContainer);