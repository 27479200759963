import onHoldIcon from '../assets/question.png'

export const refreshJob = {
    src: 'https://static.' + process.env.REACT_APP_PLATFORM + '.com/recruiter/resources/images/refrsh-grey.png',
    title: 'Refresh Job'
};

export const deleteJob = {
    src: 'https://static.' + process.env.REACT_APP_PLATFORM + '.com/recruiter/resources/images/delete-grey.png',
    title: 'Click to delete job'
};

export const deleteBill = {
    src: 'https://static.' + process.env.REACT_APP_PLATFORM + '.com/recruiter/resources/images/delete-grey.png',
    title: 'Click to delete entry'
};

export const unpublishJob = {
    src: 'https://static.' + process.env.REACT_APP_PLATFORM + '.com/recruiter/resources/images/unpublish-grey.png',
    title: 'Unpublish Job'
};

export const rejectJob = {
    src: 'https://static.' + process.env.REACT_APP_PLATFORM + '.com/recruiter/resources/images/reject-grey.png',
    title: 'Reject Job'
};

export const onHoldJob = {
    src: onHoldIcon,
    title: 'This position has gone on hold.'
}

export const rejectPost = {
    src: onHoldIcon,
    title: 'Reject Job And Post On Hirist.com'
}