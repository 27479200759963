import moment from "moment";
import React, { useEffect, useState } from "react";
import fetchRefAnalysis from "../../services/fetchRefAnalysis";
import "./applyReferenceAnalysis.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';
import getJobReferenceMap from "./constants";
import Loader from "../../components/Loader";

const ApplyReferenceAnalysis = (props) => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const referenceMap = getJobReferenceMap();

    useEffect(() => {
        user.getProfile().then(profile => {
          fetchRefAnalysis(profile.id)
                .then(resp => {
                    setData(resp.data.data);
                    setIsLoading(false);

                }).catch(err => {
                    console.log(err);
                    setIsLoading(false);

                })
        })
    }, []);

    return (
        <div className="ref-apply-analysis-wrapper">
            <div className="ref-apply-analysis-heading">
                <Heading text='Apply Reference Analysis' />
                <div className='right-aligned'>
                      <div className="use-old-btn">
                          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/refapplyanalysis.php`} target='_blank'>
                              <Button skin='dark' disabled={false} text='Use Old Version'/>
                          </a>
                      </div>

                </div>
            </div>
            {
              isLoading ? (
                <div className="loader-container">
                    <Loader />
                </div>
              ): (
                <div className="row text-center">
                    {
                        Object.keys(data).length ? (
                            <div className="table-container">
                              <div className="ref-table">
                                <h2>This Month Rating</h2>
                                <Table className="ref-analysis-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Ref</Table.HeaderCell>
                                            <Table.HeaderCell>Count</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
            
                                    <Table.Body>
                                      {
                                        data['currMonth'].map(item => (
                                          <Table.Row>
                                            <Table.Cell>{referenceMap[item['ref']]}</Table.Cell>
                                            <Table.Cell>{item['count']}</Table.Cell>
                                          </Table.Row>
                                        ))
                                      }
                                    </Table.Body>
                                </Table>
                              </div>
                                
                              <div className="ref-table">
                                <h2>Last Month Rating</h2>
                                <Table className="ref-analysis-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Ref</Table.HeaderCell>
                                            <Table.HeaderCell>Count</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
            
                                    <Table.Body>
                                    {
                                        data['lastMonth'].map(item => (
                                          <Table.Row>
                                            <Table.Cell collapsing>{referenceMap[item['ref']]}</Table.Cell>
                                            <Table.Cell collapsing>{item['count']}</Table.Cell>
                                          </Table.Row>
                                        ))
                                      }
                                    </Table.Body>
                                </Table>
                              </div>
    
                              <div className="ref-table">
                                <h2>Total Known Rating</h2>
                                <Table className="ref-analysis-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Ref</Table.HeaderCell>
                                            <Table.HeaderCell>Count</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
            
                                    <Table.Body>
                                    {
                                        data['known'].map(item => (
                                          <Table.Row>
                                            <Table.Cell>{referenceMap[item['ref']]}</Table.Cell>
                                            <Table.Cell>{item['count']}</Table.Cell>
                                          </Table.Row>
                                        ))
                                      }
                                    </Table.Body>
                                </Table>
                              </div>

                              <div className="ref-table">
                                <h2>Total Unknown Rating</h2>
                                <Table className="ref-analysis-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Ref</Table.HeaderCell>
                                            <Table.HeaderCell>Count</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
            
                                    <Table.Body>
                                    {
                                        data['unknown'].map(item => (
                                          <Table.Row>
                                            <Table.Cell>{item['ref']}</Table.Cell>
                                            <Table.Cell>{item['count']}</Table.Cell>
                                          </Table.Row>
                                        ))
                                      }
                                    </Table.Body>
                                </Table>
                              </div>

                                
                            </div>
                        ):null
                    }
                  
                </div>
              )
            }

        </div>
    )

}

export default ApplyReferenceAnalysis;