import axios from '../utils/axios';

export default function postJobComment(adminId, jobId, data) {
    return (
        axios.post(`/admin/${adminId}/job/${jobId}/comment/add-comment`,  data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}
