export const noticePeriodList = [
    {name: 'Select Notice Period', id: ''}, 
    {name: 'Immediately Available', id: 7},
    {name: '1 month or less', id: 1}, 
    {name: '2 months or less', id: 2}, 
    {name: '3 months or less', id: 3}, 
    {name: '4 months or less', id: 4}, 
    {name: '5 months or less', id: 5}, 
    {name: '6 months or less', id: 6}
    
]
