import axios from '../utils/axios';

export default function fetchAvgPublishTime(adminId,params) {
    return (
        axios.get('/admin/'+ adminId+'/average-publish-time',{
            params:params
        })
        .then(res => {
            return res.data.data
        })
        .catch(err => {
            return err;
        })
    )
}
