/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import fetchCompanyList from '../../services/fetchCompanyList';
import fetchFollowUp from '../../services/fetchFollowUp';

import user from '../../store/user';

import { Table } from 'semantic-ui-react';

import './FollowUpView.scss'

const FollowUpView = (props) => {

    const [selectedCompany, setSelectedCompany] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [searchedCompanyResult, setSearchedCompanyResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const profile = await user.getProfile();
                const { data } = await fetchCompanyList(profile.id);
                const list = [{ id: '', name: 'Select Company' }, ...data.data];
                setCompanyList(list);

                // get company id from url query string

                // eslint-disable-next-line react/prop-types
                let queryString = props.location.search;
                queryString = queryString.replace('?', '');
                queryString = queryString.split('&');
                if (queryString.length && queryString[0].includes('companyId')) {
                    const id = queryString[0].split('=')[1];
                    if (id) {
                        setSelectedCompany(id);
                        await searchCompany(id);
                    }
                }
            } catch (error) {
                console.log(error);
            }

        })()
    }, [])

    const onCompanyChange = (ev) => {
        setSelectedCompany(ev.target.value);
    };

    const onSearch = async () => {
        // eslint-disable-next-line react/prop-types
        props.history.push('/companyListInFollowUp?companyId=' + selectedCompany);
        await searchCompany(selectedCompany);
    };

    const searchCompany = async (id) => {
        try {
            setIsLoading(true);
            const profile = await user.getProfile();
            const { data } = await fetchFollowUp(profile.id, { companyId: id, status: 1 });
            setSearchedCompanyResult(data.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='company-list-follow-up-container'>
            <div className='topbar'>
                <h2 className='page-heading'>Showcase Follower Report</h2>
                <div className='older-version-btn'>
                    <Button
                        skin="light"
                        text="Go to Older Version"
                        // eslint-disable-next-line no-return-assign
                        clickButton={() => window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recruiter/company-list-in-followup/`}
                    />
                </div>
            </div>

            <div className='company-search-container'>
                <Dropdown name="companyList" data={companyList} dropdownChange={onCompanyChange} defaultValue={selectedCompany} />
                <Button skin="dark" text="Search" clickButton={onSearch} />
            </div>
            <div className='searched-company-result-container'>
                <Table
                    fixed
                    selectable
                    className="followup-company-list"
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Company Id
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Company Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                No Of Followers
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Action
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {isLoading ?
                        <Loader /> :
                        searchedCompanyResult.length ?
                            <Table.Body>
                                {searchedCompanyResult.map((company) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                {company.c_id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {company.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {company.fcnt}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <a href={process.env.REACT_APP_CLIENT_URL + `/recruiter/followup-user-details/?company_id=${selectedCompany}`}>View Followers</a>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                            : 'No Record'
                    }
                </Table>
            </div>
        </div>
    )
}

export default FollowUpView