import moment from "moment";
import React, { useEffect, useState } from "react";
import getUserAnalysis from "../../services/getUsersAnalysis";
import "./styles.scss";
import { Table } from "semantic-ui-react";
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import user from '../../store/user';

import {
    dates,
    apply_jobs,
    saved_jobs,
    rec_action,
    seeker_login,
    seeker_reg
} from "./constants";

const UsersAnalysis = (props) => {
    const [data, setData] = useState({});

    const current = moment().format("YYYY-MM-DD");
    const days = {
        today: moment(current).format("DD-MM-YYYY"),
        yesterday: moment(current).subtract(1, "day").format("DD-MM-YYYY"),
        dayBeforeYesterday: moment(current).subtract(2, "day").format("DD-MM-YYYY"),
        currMonth: moment(current).format("MMM YYYY"),
        lastMonth: moment(current).subtract(1, "month").format("MMM YYYY"),
        lastToLastMonth: moment(current).subtract(2, "month").format("MMM YYYY"),
        overall: "Overall"
    }

    useEffect(() => {
        user.getProfile().then(profile => {
            getUserAnalysis(profile.id)
                .then(resp => {
                    setData(resp.data.data);
                }).catch(err => {
                    console.log("Err");
                    console.log(err);
                })
        })
    }, []);

    return (
        <div className="users-analysis">
            <div className="pageHeading">
                <Heading text='Users Analysis' />
                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/usersanalysis.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>
            <div className="row">
                {
                    data.rec ?
                        <div className="col">
                            {/* <Heading text='Overall' /> */}
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Overall</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>

                                    <Table.Row>
                                        <Table.Cell>Total Recruiters</Table.Cell>
                                        <Table.Cell>{data.rec.trs.overall || 'N/A'}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>LinkedIn Connected</Table.Cell>
                                        <Table.Cell>{data.rec.trlc.overall || 'N/A'}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Twitter Connected</Table.Cell>
                                        <Table.Cell>{data.rec.trtc.overall || 'N/A'}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Profile Image</Table.Cell>
                                        <Table.Cell>{data.rec.trppc.overall || 'N/A'}</Table.Cell>
                                    </Table.Row>

                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
            <div className="row">
                {
                    data.seeker_reg ?
                        <div>
                            <Heading text='Jobseeker Registration' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        seeker_reg.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.seeker_reg[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.seeker_reg[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Tags</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{(data.seeker_reg.jtc || {})[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
            <div className="row">
                {
                    data.apply_jobs ?
                        <div className="col">
                            <Heading text='Apply Job Analysis' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        apply_jobs.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.apply_jobs[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.apply_jobs[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
                {
                    data.saved_jobs ?
                        <div className="col">
                            <Heading text='Save Job Analysis' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        saved_jobs.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.saved_jobs[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.saved_jobs[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
            <div className="row">
                {
                    data.seeker_login ?
                        <div className="col">
                            <Heading text='Jobseeker Login' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        seeker_login.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.seeker_login[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.seeker_login[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
                {
                    data.rec_login ?
                        <div className="col">
                            <Heading text='Recruiter Login' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.rec_login[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
            <div className="row">
                {
                    data.rec_action ?
                        <div>
                            <Heading text='Recruiter Actions' />
                            <Table fixed selectable className="mailers-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.HeaderCell>{days[date]}</Table.HeaderCell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        rec_action.map((item, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{item.title}</Table.Cell>
                                                    {
                                                        dates.map(date => {
                                                            return (
                                                                <Table.Cell>{(data.rec_action[item.key] || {})[date] || 'N/A'}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                    }
                                    <Table.Row>
                                        <Table.Cell>Total</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{data.rec_action[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Unique</Table.Cell>
                                        {
                                            dates.map(date => {
                                                return (
                                                    <Table.Cell>{(data.rec_action.unique || {})[date] || 'N/A'}</Table.Cell>
                                                )
                                            })
                                        }
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        : null
                }
            </div>
        </div>
    )

}

export default UsersAnalysis;