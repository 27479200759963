import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';

import Heading from '../../components/Heading';
import Pagination from '../../components/Pagination';
import CountDisplay from '../../components/CountDisplay';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import user from '../../store/user';
import { RECRUITER_MGT, ADMIN_USERS } from '../../models/routes';
import { getQueryParams } from '../../utils/utilFunctions';

import getAllRecruiters from '../../services/getAllRecruiters';
import getAllRecruitersCount from '../../services/getAllRecruitersCount';

import './styles.scss';

const AdminUsers = ({ location, history }) => {
    const [adminId, setAdminId] = useState(null);
    const [dataController, setDataController] = useState({
        recruiterList: [],
        pageNumber: 1,
        pageContent: 50,
        totalResults: 0,
        isPageLoading: false
    });

    const CONTROLLER_KEYS = {
        recruiterList: 'recruiterList',
        pageNumber: 'pageNumber',
        totalResults: 'totalResults',
        isPageLoading: 'isPageLoading'
    };

    const [isLoading, setIsLoading] = useState(true);

    const setController = (key, value) => {
        setDataController(prevObj => {
            const newObj = { ...prevObj };
            newObj[key] = value;
            return newObj;
        });
    };

    useEffect(() => {
        const params = getQueryParams(location.search);

        if (params.pg) {
            setController(CONTROLLER_KEYS.pageNumber, params.pg);
        }

        const getProfile = async () => {
            const profile = await user.getProfile();
            return profile;
        };

        getProfile().then(response => {
            setAdminId(response.id);
        }).catch(error => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })

    }, []);

    const getParameters = (page = null) => {
        return {
            pageNumber: page || dataController.pageNumber,
            pageContent: dataController.pageContent,
            adminType: 2,
            order: 'desc'
        };
    };

    const getRecruiterList = async (page = null) => {
        const params = getParameters(page);

        try {
            const response = await getAllRecruiters(adminId, params);

            setController(CONTROLLER_KEYS.recruiterList, response.data.data);
            setController(CONTROLLER_KEYS.isPageLoading, false);
            setIsLoading(false);
        } catch (error) {
            // console.log(error);
            toast.error('Failed to fetch Admin Users');
        }
    };

    const getRecruiterCount = async () => {
        const params = getParameters();

        try {
            const response = await getAllRecruitersCount(adminId, params);
            setController(CONTROLLER_KEYS.totalResults, response.data.data.count);
        } catch (error) {
            // console.log(error);
            toast.error('Failed to fetch Admin Users Count');
        }
    };

    const handlePageChange = page => {
        setController(CONTROLLER_KEYS.pageNumber, page);
        setController(CONTROLLER_KEYS.isPageLoading, true);

        getRecruiterList(page);
        getRecruiterCount();
        updateQueryParams(page);
    };

    useEffect(() => {
        if (adminId) {
            // fetch users
            getRecruiterList()
            getRecruiterCount();
        }
    }, [adminId]);

    const updateQueryParams = (page) => {
        history.push(`${ADMIN_USERS}?pg=${page}`)
    };

    const tableHeading = (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Organisation</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
    
    let mainContent = null;

    if (isLoading) {
        mainContent = <Loader />
    } 
    else if (!isLoading && dataController.isPageLoading) {
        mainContent = (
            <div>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </div>
        );
    } else if (!isLoading && dataController.recruiterList.length === 0) {
        mainContent = (
            <div>
                No Record Found!
            </div>
        );
    } else {
        mainContent = (
            <div>
                <Table selectable>
                    {tableHeading}
                    <Table.Body>
                        {dataController.recruiterList.map(item => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{item.id}</Table.Cell>
                                    <Table.Cell>{item.recname}</Table.Cell>
                                    <Table.Cell>{item.email}</Table.Cell>
                                    <Table.Cell>{item.phone}</Table.Cell>
                                    <Table.Cell>{item.organisation}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        )
    };

    let topPageIndicators = <div></div>;
    let bottomPageIndicators = <div></div>;

    if (!isLoading && dataController.recruiterList.length > 0) {
        topPageIndicators = (
            <div className='top-page-controller'>
                <div className='row text-center'>
                    <Pagination totalItemsCount={dataController.totalResults} content={dataController.pageContent} pageNumber={dataController.pageNumber} handlePageChange={handlePageChange} />
                    <CountDisplay start={(dataController.pageNumber - 1) * dataController.pageContent + 1} end={dataController.pageNumber * dataController.pageContent} total={dataController.totalResults} />
                </div>
            </div>
        )

        if (!dataController.isPageLoading) {
            bottomPageIndicators = (
                <div className='row text-center pagination-bottom'>
                    <Pagination totalItemsCount={dataController.totalResults} content={dataController.pageContent} pageNumber={dataController.pageNumber} handlePageChange={handlePageChange} />
                </div>
            )
        }
    }

    return (
        <div className='page-content admin-users'>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover 
            />
            <div className='heading__container'>
                <Heading text="Admin Recruiters" />
                <div className='heading__container--actions'>
                    <a href={`${RECRUITER_MGT}`} target="_blank">
                        <Button text="Recruiter Management" skin="dark" />
                    </a>
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/adminusers.php`} target="_blank">
                        <Button text="Use Old Version" skin="dark" />
                    </a>
                </div>
            </div>

            {topPageIndicators}

            <div className='content'>
                {mainContent}
            </div>

            {bottomPageIndicators}
        </div>
    )
};

export default AdminUsers;