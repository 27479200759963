import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';

function Loading() {
  return <h3>Loading...</h3>;
}

const JobsList = Loadable({
  loader: () => import('../Container'),
  loading: Loading,
});

const JobListing = () => (
    <JobsList />
)

JobListing.propTypes = {
};

export default JobListing;
