import React from "react";
import Heading from '../../components/Heading';
import Moment from 'react-moment';

import { Table } from "semantic-ui-react";
import getNotifierLogs from "../../services/notifierLogs";
import getActiveNotifyUsers from "../../services/getActiveNotifyUsers";
import "./styles.scss"

class NotifierLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            users: []
        }
    }

    async componentDidMount() {

        try {

            const resp = await getNotifierLogs();
            const list = resp.data.data.map(i => {
                // console.log(i, typeof i);
                try {
                    const d = JSON.parse(i);
                    let p = {
                        timestamp: d.timestamp,
                        type: d.type
                    };
                    // console.log(d.type);
                    switch (d.type) {
                        case "user-details":
                            p.users = [`${d.payload.email} (${d.payload.id})`];
                            p.data = ["User Connected"];
                            p.product = d.payload.product;
                            break;
                        case "disconnection":
                            p.data = ["User Disconnected"];
                            p.users = [`${d.user.email} (${d.user.id})`];
                            p.product = d.user.product;
                            break;
                        case "send":
                            p.users = d.user.map(i => `${i.info.email} (${i.info.id})`);

                            // d.user.reduce((p, c) => {
                            //     p += `${c.info.email} (${c.info.id})`;
                            //     return p;
                            // }, "");

                            console.log(p, d.payload);
                            if (d.payload.payload.state == 1) {
                                p.data = [
                                    d.payload.payload.jobTitle,
                                    d.payload.payload.jobLink,
                                    d.payload.payload.createdAt
                                ]
                                p.product = d.payload.payload.product;
                            } else {
                                p = "";
                            }
                            break;
                        default:
                            p = "";
                            break;
                    }

                    console.log(p);
                    return p;

                } catch (err) {
                    console.log(err);
                    return ""
                }

            }).filter(i => !!i).sort((a, b) => b.timestamp - a.timestamp);
            console.log(list);

            const aResp = await getActiveNotifyUsers();

            this.setState({
                list,
                users: aResp.data.data
            });

        } catch (err) {
            console.log(err);
        }

    }

    render() {
        const { list } = this.state;
        return (
            <div>
                <Heading text='Notifier Logs' />
                <br/>
                {
                    this.state.users.length ?
                        <div className="row">
                            {/* Active Users */}
                            <div className="col-sm-6 left">
                                <span className="sub-head">Hirist</span>
                                {
                                    this.state.users
                                        .filter(i => i.product == "hirist")
                                        .map(i => (<div> {i.email} ({i.id}) </div>))
                                }
                            </div>
                            <div className="col-sm-6 left">
                                <span className="sub-head">IIMjobs</span>
                                {
                                    this.state.users
                                        .filter(i => i.product == "iimjobs")
                                        .map(i => (<div> {i.email} ({i.id}) </div>))
                                }
                            </div>
                        </div> : null
                }
                <Table fixed selectable className="mailers-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2} >Time</Table.HeaderCell>
                            <Table.HeaderCell width={2} >Type</Table.HeaderCell>
                            <Table.HeaderCell width={6} >Data</Table.HeaderCell>
                            <Table.HeaderCell width={6} >Users</Table.HeaderCell>
                            <Table.HeaderCell width={2} >Product</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            list.map((item, i) => {
                                let users = item.users.map(i => <div>{i}</div>)
                                let data = item.data.map(i => <div>{i}</div>)

                                return (
                                    <Table.Row>
                                        <Table.Cell><Moment format='DD-MM-YYYY hh:mm A'>{item.timestamp}</Moment></Table.Cell>
                                        <Table.Cell>{item.type} </Table.Cell>
                                        <Table.Cell>{data} </Table.Cell>
                                        <Table.Cell>{users} </Table.Cell>
                                        <Table.Cell>{item.product} </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
                <br />
            </div>
        )
    }
}

export default NotifierLogs;