import moment from "moment";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Header, Modal, Table } from "semantic-ui-react";
import Button from "../../../components/Button";
import ButtonContainer from "../../../components/ButtonContainer";
import FromToDatePicker from "../../../components/FromToDatepicker";
import Heading from "../../../components/Heading";
import Loader from "../../../components/Loader";
import fetchAvgPublishTime from "../../../services/fetchAvgPublishTime";
import user from "../../../store/user";
import { getJobUrl } from "../../../utils/utilFunctions";
import "./avgTimeToPublish.scss";

const AvgTimeToPublishList = (props) => {
    const [dateRange, setDateRange] = useState({
        startDate: moment()
            .subtract(1, "day")
            .subtract(1, "week")
            .startOf("day")
            .toDate(),
        endDate: moment()
            .subtract(1, "day")
            .startOf("day")
            .toDate(),
    });
  
    const [adminId, setAdminId] = useState(null);
    const [loader, setLoader] = useState(true);
    const [avgTimeList, setAvgTimeList] = useState([]);
    const [avgTimeListCrawled, setAvgTimeListCrawled] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [jobDetails, setJobDetails] = useState({ paid_jobs: [] });
    const [showFreeDetails, setShowFreeDetails] = useState(false);
    const [freeJobDetails, setFreeJobDetails] = useState({ free_jobs: [] });
    const [showCrawledDetails, setShowCrawledDetails] = useState(false);
    const [crawledJobDetails, setCrawledJobDetails] = useState({ paid_jobs: [] });
    const [showFreeCrawledDetails, setShowFreeCrawledDetails] = useState(false);
    const [freeCrawledJobDetails, setFreeCrawledJobDetails] = useState({ free_jobs: [] });

    const [avgTats, setAvgTats] = useState({
        freeJobsAvg : 0,
        paidJobAvg: 0,
        freeCrawledJobsAvg : 0,
        paidCrawledJobsAvg: 0
    })

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        calculateAvgs()
    }, [avgTimeList, avgTimeListCrawled])

    const getProfile = async () => {
        const profile = await user.getProfile();
        setAdminId(profile.id);

        getAvgTime(profile.id);

    };

    const getAvgTime = async (admin_id, noCache) => {
        setLoader(true);
        let params = {
            startDate: moment(dateRange.startDate).unix(),
            endDate: moment(dateRange.endDate).unix(),
        }
        if(noCache) {
            params['noCache']= noCache
        }
        const list = await fetchAvgPublishTime(admin_id, params);
        const data = list;

        setLoader(false);

        setAvgTimeList(data["uncrawled"]);
        setAvgTimeListCrawled(data["crawled"]);
    };
    
    const convertSecondsToMinutes = (seconds) => {
        const minutes = Math.floor(seconds / 60);

        return `${minutes} mins`

    }

    const calculateAvgs = () => {
        if(avgTimeList.length) {
            let freeJobsAvg = 0, paidJobAvg = 0;
            avgTimeList.forEach(item => {
                freeJobsAvg += item.actual_avg_publish_time_free;
                paidJobAvg += item.actual_avg_publish_time_paid;
            })
    
            freeJobsAvg = convertSecondsToMinutes(freeJobsAvg/avgTimeList.length);
            paidJobAvg = convertSecondsToMinutes(paidJobAvg/avgTimeList.length);
    
            setAvgTats((prevState) => ({
                ...prevState,
                ["freeJobsAvg"]: freeJobsAvg,
                ["paidJobAvg"]: paidJobAvg
            }))
        }

        if(avgTimeListCrawled.length) {
            let freeCrawledJobsAvg = 0, paidCrawledJobsAvg = 0;
            avgTimeListCrawled.forEach(item => {
                freeCrawledJobsAvg += item.actual_avg_publish_time_free;
                paidCrawledJobsAvg += item.actual_avg_publish_time_paid;
            })
    
            freeCrawledJobsAvg = convertSecondsToMinutes(freeCrawledJobsAvg/avgTimeListCrawled.length);
            paidCrawledJobsAvg = convertSecondsToMinutes(paidCrawledJobsAvg/avgTimeListCrawled.length);
            
            setAvgTats((prevState) => ({
                ...prevState,
                ["freeCrawledJobsAvg"]: freeCrawledJobsAvg,
                ["paidCrawledJobsAvg"]: paidCrawledJobsAvg
            }))
        }
       
    }
    return (
        <div className="page-content-publish-time">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="page-heading">
                <Heading text="Average Time to Publish" />
            </div>

            <div className="row">
                <ButtonContainer heading="Date:">
                    <li>
                        <div className="date-picker">
                            <div className="form-group date">
                                <div className="col-sm-6 left valueBox">
                                    <FromToDatePicker
                                        name="date"
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                        minDate={moment().subtract(6, 'months').startOf("day").toDate()}
                                        maxDate={moment()
                                            .subtract(1, "day")
                                            .startOf("day")
                                            .toDate()}
                                        handleChangeStart={(e) => {
                                            setDateRange({
                                                ...dateRange,
                                                startDate: e,
                                            });
                                        }}
                                        handleChangeEnd={(e) => {
                                            setDateRange({
                                                ...dateRange,
                                                endDate: e,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <Button
                            skin="dark"
                            disabled={false}
                            text="Search"
                            clickButton={() => getAvgTime(adminId, false)}
                        ></Button>
                        <Button
                            className={["right-btn"]}
                            skin="dark"
                            disabled={false}
                            text="Search from db"
                            clickButton={() => getAvgTime(adminId, true)}
                        ></Button>
                    </li>
                </ButtonContainer>
            </div>

            <div>
                <Table fixed celled structured className="avg-time-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2} rowSpan={2}>S.No</Table.HeaderCell>
                            <Table.HeaderCell width={3} rowSpan={2}>Date </Table.HeaderCell>
                            <Table.HeaderCell width={16} colspan={7}>Paid Jobs</Table.HeaderCell>
                            <Table.HeaderCell width={16} colspan={8}>Free Jobs</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Total</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'<1min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'1-3min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'3-5min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'>5min'}</Table.HeaderCell>
                            <Table.HeaderCell width={6}>TAT <br />{`(Avg: ${avgTats.paidJobAvg})`}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>CTA</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Total</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'<30min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'30-60min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'60-90min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'90-120min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'>120min'}</Table.HeaderCell>
                            <Table.HeaderCell width={6}>TAT <br /> {`(Avg: ${avgTats.freeJobsAvg})`}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>CTA</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {loader ? (
                            <div className="loader-container">
                                <Loader />
                            </div>
                        ) : (
                            avgTimeList.map((item, ind) => {
                                return (
                                    <Table.Row key={item.date}>
                                        <Table.Cell>{ind + 1}</Table.Cell>
                                        <Table.Cell className="not-capitalized">
                                            {item.date}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.total_paid_jobs}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['lt1']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['1to3']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['3to5']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['gt5']}
                                        </Table.Cell>
                                        <Table.Cell
                                        >
                                            {convertSecondsToMinutes(item.actual_avg_publish_time_paid)}
                                        </Table.Cell>
                                        <Table.Cell className="paid_avg_time" onClick={() => {
                                                setJobDetails(item);
                                                setShowDetails(true);
                                            }}>
                                                <span className="tat_link">View Jobs</span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.total_free_jobs}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['lt30']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['30to60']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['60to90']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['90to120']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['gt120']}
                                        </Table.Cell>
                                        
                                        <Table.Cell>
                                            {convertSecondsToMinutes(item.actual_avg_publish_time_free)}
                                        </Table.Cell>
                                        <Table.Cell className="paid_avg_time" onClick={() => {
                                                setFreeJobDetails(item);
                                                setShowFreeDetails(true);
                                            }}>
                                                <span className="tat_link">View Jobs</span>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                        )}
                    </Table.Body>
                </Table>
            </div>
                    
            <br />

            <div>
                <Table fixed celled structured className="avg-time-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2} rowSpan={2}>S.No</Table.HeaderCell>
                            <Table.HeaderCell width={3} rowSpan={2}>Date </Table.HeaderCell>
                            <Table.HeaderCell width={16} colspan={7}>Crawled Paid Jobs</Table.HeaderCell>
                            <Table.HeaderCell width={16} colspan={8}>Crawled Free Jobs</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Total</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'<1min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'1-3min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'3-5min'}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{'>5min'}</Table.HeaderCell>
                            <Table.HeaderCell width={6}>TAT <br />{`(Avg: ${avgTats.paidCrawledJobsAvg})`}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>CTA</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Total</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'<30min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'30-60min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'60-90min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'90-120min'}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{'>120min'}</Table.HeaderCell>
                            <Table.HeaderCell width={6}>TAT <br /> {`(Avg: ${avgTats.freeCrawledJobsAvg})`}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>CTA</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {loader ? (
                            <div className="loader-container">
                                <Loader />
                            </div>
                        ) : (
                            avgTimeListCrawled.map((item, ind) => {
                                return (
                                    <Table.Row key={item.date}>
                                        <Table.Cell>{ind + 1}</Table.Cell>
                                        <Table.Cell className="not-capitalized">
                                            {item.date}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.total_paid_jobs}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['lt1']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['1to3']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['3to5']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.paid_jobs_stats['gt5']}
                                        </Table.Cell>
                                        <Table.Cell
                                        >
                                            {convertSecondsToMinutes(item.actual_avg_publish_time_paid)}
                                        </Table.Cell>
                                        <Table.Cell className="paid_avg_time" onClick={() => {
                                                setCrawledJobDetails(item);
                                                setShowCrawledDetails(true);
                                            }}>
                                                <span className="tat_link">View Jobs</span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.total_free_jobs}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['lt30']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['30to60']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['60to90']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['90to120']}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.free_jobs_stats['gt120']}
                                        </Table.Cell>
                                        
                                        <Table.Cell>
                                            {convertSecondsToMinutes(item.actual_avg_publish_time_free)}
                                        </Table.Cell>
                                        <Table.Cell className="paid_avg_time" onClick={() => {
                                                setFreeCrawledJobDetails(item);
                                                setShowFreeCrawledDetails(true);
                                            }}>
                                                <span className="tat_link">View Jobs</span>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                        )}
                    </Table.Body>
                </Table>
            </div>

            <Modal open={showDetails} size="large" onClose={() => setShowDetails(false)}>
                <Header content={`Time to Publish for ${jobDetails.date}`} />

                <Modal.Content scrolling>
                    {jobDetails && jobDetails["paid_jobs"].length ? (
                        <Table fixed selectable className="paid-avg-time-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Job Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Posted Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Publish Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Notification Click Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Notification Created Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        TAT
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Published By
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {jobDetails.paid_jobs.map((item, ind) => {
                                let startWorkingTime = new moment(item.notification_created_time);
                                startWorkingTime.set({ h: 10, m: 0, s: 0 });
                                let lastWorkingTime = new moment(item.notification_created_time);
                                lastWorkingTime.set({ h: 18, m: 30, s: 0 });
                                return (
                                    <Table.Row key={item.job_id}>
                                        <Table.Cell>{item.job_id}</Table.Cell>
                                        <Table.Cell><a href={ getJobUrl(item.title, item.publishedId) } target='_blank'>{item.title}</a></Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.posted_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.posted_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.published_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.published_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell>{moment(item.notification_click_time).isValid() && moment(item.notification_created_time).isValid() && moment(item.notification_created_time).isBefore(lastWorkingTime) && moment(item.notification_created_time).isAfter(startWorkingTime) ? <><span>{moment(item.notification_click_time).format("DD-MM-YYYY")}</span><br/>{moment(item.notification_click_time).format("hh:mm:ss A")}</> : 'N/A'}</Table.Cell>
                                        <Table.Cell>{moment(item.notification_created_time).isValid() && moment(item.notification_created_time).isBefore(lastWorkingTime) && moment(item.notification_created_time).isAfter(startWorkingTime) ? <><span>{moment(item.notification_created_time).format("DD-MM-YYYY")}</span><br/>{moment(item.notification_created_time).format("hh:mm:ss A")}</> : 'N/A'}</Table.Cell>
                                        <Table.Cell>{convertSecondsToMinutes(item.actual_avg_time)}</Table.Cell>
                                        <Table.Cell>{item.publisher_email}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table>
                    ) : (
                        <p style={{ textAlign: "center" }}>
                            No Paid Jobs Found
                        </p>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        clickButton={() => setShowDetails(false)}
                        skin="light"
                        text="Close"
                    ></Button>
                </Modal.Actions>
            </Modal>

            <Modal open={showFreeDetails} size="large" onClose={() => setShowFreeDetails(false)}>
                <Header content={`Time to Publish for ${freeJobDetails.date}`} />

                <Modal.Content scrolling>
                    {freeJobDetails && freeJobDetails["free_jobs"].length ? (
                        <Table fixed selectable className="paid-avg-time-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Job Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Posted Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Publish Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        TAT
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Published By
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {freeJobDetails.free_jobs.map((item, ind) => {
                                return (
                                    <Table.Row key={item.job_id}>
                                        <Table.Cell>{item.job_id}</Table.Cell>
                                        <Table.Cell><a href={ getJobUrl(item.title, item.publishedId) } target='_blank'>{item.title}</a></Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.posted_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.posted_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.published_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.published_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell>{convertSecondsToMinutes(item.actual_avg_time)}</Table.Cell>
                                        <Table.Cell>{item.publisher_email}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table>
                    ) : (
                        <p style={{ textAlign: "center" }}>
                            No Free Jobs Found
                        </p>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        clickButton={() => setShowFreeDetails(false)}
                        skin="light"
                        text="Close"
                    ></Button>
                </Modal.Actions>
            </Modal>

            <Modal open={showCrawledDetails} size="large" onClose={() => setShowCrawledDetails(false)}>
                    <Header content={`Time to Publish for ${crawledJobDetails.date}`} />

                <Modal.Content scrolling>
                    {crawledJobDetails && crawledJobDetails["paid_jobs"].length ? (
                        <Table fixed selectable className="paid-avg-time-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Job Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Posted Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Publish Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Notification Click Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Notification Created Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        TAT
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Published By
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {crawledJobDetails.paid_jobs.map((item, ind) => {
                                let startWorkingTime = new moment(item.notification_created_time);
                                startWorkingTime.set({ h: 10, m: 0, s: 0 });
                                let lastWorkingTime = new moment(item.notification_created_time);
                                lastWorkingTime.set({ h: 18, m: 30, s: 0 });
                                return (
                                    <Table.Row key={item.job_id}>
                                        <Table.Cell>{item.job_id}</Table.Cell>
                                        <Table.Cell><a href={ getJobUrl(item.title, item.publishedId) } target='_blank'>{item.title}</a></Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.posted_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.posted_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.published_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.published_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell>{moment(item.notification_click_time).isValid() && moment(item.notification_created_time).isValid() && moment(item.notification_created_time).isBefore(lastWorkingTime) && moment(item.notification_created_time).isAfter(startWorkingTime) ? <><span>{moment(item.notification_click_time).format("DD-MM-YYYY")}</span><br/>{moment(item.notification_click_time).format("hh:mm:ss A")}</> : 'N/A'}</Table.Cell>
                                        <Table.Cell>{moment(item.notification_created_time).isValid() && moment(item.notification_created_time).isBefore(lastWorkingTime) && moment(item.notification_created_time).isAfter(startWorkingTime) ? <><span>{moment(item.notification_created_time).format("DD-MM-YYYY")}</span><br/>{moment(item.notification_created_time).format("hh:mm:ss A")}</> : 'N/A'}</Table.Cell>
                                        <Table.Cell>{convertSecondsToMinutes(item.actual_avg_time)}</Table.Cell>
                                        <Table.Cell>{item.publisher_email}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table>
                    ) : (
                        <p style={{ textAlign: "center" }}>
                            No Paid Crawled Jobs Found
                        </p>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        clickButton={() => setShowCrawledDetails(false)}
                        skin="light"
                        text="Close"
                    ></Button>
                </Modal.Actions>
            </Modal>

            <Modal open={showFreeCrawledDetails} size="large" onClose={() => setShowFreeCrawledDetails(false)}>
                <Header content={`Time to Publish for ${freeCrawledJobDetails.date}`} />

                <Modal.Content scrolling>
                    {freeCrawledJobDetails && freeCrawledJobDetails["free_jobs"].length ? (
                        <Table fixed selectable className="paid-avg-time-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}>
                                        Job Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Posted Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        Publish Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        TAT
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={8}>
                                        Published By
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {freeCrawledJobDetails.free_jobs.map((item, ind) => {
                                return (
                                    <Table.Row key={item.job_id}>
                                        <Table.Cell>{item.job_id}</Table.Cell>
                                        <Table.Cell><a href={ getJobUrl(item.title, item.publishedId) } target='_blank'>{item.title}</a></Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.posted_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.posted_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell><span>{moment.unix(item.published_time).format("DD-MM-YYYY")}</span><br/>{moment.unix(item.published_time).format("hh:mm:ss A")}</Table.Cell>
                                        <Table.Cell>{convertSecondsToMinutes(item.actual_avg_time)}</Table.Cell>
                                        <Table.Cell>{item.publisher_email}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table>
                    ) : (
                        <p style={{ textAlign: "center" }}>
                            No Free Crawled Jobs Found
                        </p>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        clickButton={() => setShowFreeCrawledDetails(false)}
                        skin="light"
                        text="Close"
                    ></Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default AvgTimeToPublishList;
