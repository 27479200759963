import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import DisplayTagsPage from './pages/DisplayTagsPage';

export default () => (
  <BrowserRouter>
    <Route path="/" component={DisplayTagsPage} />
  </BrowserRouter>
);
