/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import InputBox from '../../components/InputBox'
import Pagination from '../../components/Pagination'
import CountDisplay from '../../components/CountDisplay'
import Loader from '../../components/Loader'

import fetchLearningSubscription from '../../services/fetchLearningSubscription'
import fetchLearningSubscriptionCount from '../../services/fetchLearningSubscriptionCount'

import user from '../../store/user'

import { Table } from 'semantic-ui-react'
import moment, { unix } from 'moment'

import './Learn&GrowManagement.scss'

const LearnAndGrowManagement = () => {

    const [adminId, setAdminId] = useState(null);
    const [email, setEmail] = useState('');
    const [searchedEmail, setSearchedEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [subscriberList, setSubscriberList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [subscriberCount, setSubscriberCount] = useState(0)

    const PAGE_CONTENT = 50;

    useEffect(() => {
        (async () => {
            const profile = await user.getProfile();
            setAdminId(profile.id);
            setIsLoading(true);
            const countData = await fetchLearningSubscriptionCount(profile.id, { status: 1, });
            setSubscriberCount(countData.data);
            const subscriptionData = await fetchLearningSubscription(profile.id, { status: 1, pageNumber, pageContent: PAGE_CONTENT });
            setSubscriberList(subscriptionData.data)
            setIsLoading(false);
        })()
    }, [])

    const onSearch = async () => {
        if (email.trim()) {
            setIsLoading(true);
            const countData = await fetchLearningSubscriptionCount(adminId, { email, status: 1, });
            setSubscriberCount(countData.data);
            setPageNumber(1);
            const subscriptionData = await fetchLearningSubscription(adminId, { email, status: 1, pageNumber: 1, pageContent: PAGE_CONTENT });
            setSubscriberList(subscriptionData.data);
            setSearchedEmail(email);
            setIsLoading(false);
        }
    };

    const onDownload = async () => {
        const parameters = { status: 1, pageNumber, pageContent: PAGE_CONTENT, downloadSheet: 1 };
        if (searchedEmail) {
            parameters.email = searchedEmail;
        }
        const data = await fetchLearningSubscription(adminId, parameters );

        // down load logic
        const outputFilename = `subscribeduser_${moment().format('YYYY-MM-DD hh:mm:ss')}.csv`;
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        return ;
    };

    const handlePageChange = async (page) => {
        setIsLoading(true);
        const subscriptionData = await fetchLearningSubscription(adminId, { status: 1, pageNumber: page, pageContent: PAGE_CONTENT });
        setSubscriberList(subscriptionData.data)
        setPageNumber(page);
        setIsLoading(false);
    }

    const onEnter = async (e) => {
        if(e.key === 'Enter') {
            await onSearch()
        }
    }

    return (
        <div className='learn-and-grow-management-container'>
            <h2>Learn & Grow Subscription Management</h2>
            <div className='search-panel'>
                <InputBox
                    type='text'
                    name='email'
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    onKeyPress={onEnter}                   
                />
                <Button
                    text='Search'
                    skin='dark'
                    clickButton={onSearch}
                />
                <Button
                    text='Clear'
                    skin='light'
                    // eslint-disable-next-line no-return-assign, no-undef
                    clickButton={() => window.location.href = '/learningSubscription'}
                />
                {subscriberList.length ? 
                    <Button
                        text='Download'
                        skin='light'
                        // eslint-disable-next-line no-return-assign, no-undef
                        clickButton={onDownload}
                    />
                    : null
                }
            </div>
            <div className='text-center'>
                <Pagination
                    totalItemsCount={subscriberCount}
                    content={PAGE_CONTENT}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                />
                <CountDisplay start={(pageNumber - 1) * PAGE_CONTENT + 1} end={subscriberCount < (pageNumber * PAGE_CONTENT) ? subscriberCount : (pageNumber * PAGE_CONTENT)} total={subscriberCount} />
            </div>
            <div className='subscriber-list'>
                <Table
                    fixed
                    selectable
                    className='subscriber-list'
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Subscribed On
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoading ?
                            <Loader />
                            : subscriberList.length ? subscriberList.map((subscriber) => {
                                return (
                                    <Table.Row key={subscriber.email}>
                                        <Table.Cell>
                                            {subscriber.email}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment.unix(subscriber.created_on).format('DD-MMM-YY')}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                                : 'No Records'
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default LearnAndGrowManagement