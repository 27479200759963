import React from 'react';
import { Route } from 'react-router-dom';

import AddIntegration from './Pages/AddIntegration';

export default () => (
    <switch>
        <Route path="/addintegration" component={AddIntegration} />
        <Route path="/editintegration/:id" component={AddIntegration} />
    </switch>
);
