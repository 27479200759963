export const dates = [
    "today",
    "yesterday",
    "dayBeforeYesterday",
    "currMonth",
    "lastMonth",
    "lastToLastMonth"
];

const categoriesIIMJOBS = [
    {
        title: "Finance",
        key: "cat13"
    },
    {
        title: "Marketing",
        key: "cat14"
    },
    {
        title: "Consulting",
        key: "cat16"
    },   
    {
        title: "HR",
        key: "cat17"
    },    
    {
        title: "IT",
        key: "cat15"
    },
    {
        title: "Operations",
        key: "cat19"
    },
    {
        title: "BPO",
        key: "cat22"
    },
    {
        title: "Legal",
        key: "cat21"
    },
];

const categoriesHIRIST = [
    {
        title: "Online/ Mobile/ Web",
        key: "cat1"
    },
    {
        title: "UI/ UX/ Product",
        key: "cat2"
    },
    {
        title: "Systems/ Product Software",
        key: "cat5"
    },   
    {
        title: "Semiconductor / VLSI/ EDA",
        key: "cat4"
    },    
    {
        title: "Enterprise - SAP/Oracle",
        key: "cat6"
    },
    {
        title: "Others",
        key: "cat3"
    },
    // {
    //     title: "Others",
    //     key: "cat22 BPO"
    // },
    // {
    //     title: "Others",
    //     key: "cat21 LEGAL"
    // },
];

const categoriesUPDAZZ = [
    {
        key: "cat14",
        title: "BFSI Sales"
    },
    {
        key: "cat2",
        title: "B2B/Corporate Sales"
    },
    {
        key: "cat1",
        title: "B2C/Retail/Channel Sales"
    },
    {
        key: "cat11",
        title: "Online/Digital marketing"
    },
    {
        key: "cat5",
        title: "Telesales/Inside Sales/BPO"
    },
    {
        key: "cat15",
        title: "IT Sales"
    },
    {
        key: "cat9",
        title: "Marketing/PR/Advertising"
    },
    {
        key: "cat8",
        title: "After-Sales / Customer Service"
    },
    {
        key: "cat10",
        title: "Content/Design"
    },
    {
        key: "cat7",
        title: "Pre-Sales"
    },
    {
        key: "cat6",
        title: "Sales Management"
    }
]

let selectedCategories;

if(process.env.REACT_APP_PLATFORM == 'hirist') {
    selectedCategories = categoriesHIRIST;
}
else if(process.env.REACT_APP_PLATFORM == 'updazz') {
    selectedCategories = categoriesUPDAZZ
}

else {
    selectedCategories = categoriesIIMJOBS;
}
export const categories = selectedCategories;

export const actions = [
    {
        title: "Unpublished",
        key: "unpub"
    },
    {
        title: "Refreshed",
        key: "ref"
    }
];

export const socials = [
    {
        title: "Shared on LinkedIn (Dashboard)",
        key: "linkedIn"
    },
    {
        title: "Shared on twitter",
        key: "twitter"
    }
]