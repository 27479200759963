import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './ChatFeed.scss';
import {
  fetchChatMessages
} from '../../../services/chatMessages';
import {
  convertUnixToTime,
  createMarkup,
  getJAndR,
  convertUnixToDate
} from '../../../utils/utilFunctions';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import user from '../../../store/user';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import { getDomain } from "../../../utils/utilFunctions";


const domain = getDomain();

class ChatFeed extends Component {


  constructor() {
    super();
    this.state = {
      messages: [],
      date: new Date(),
      pageNo: 0,
      pageSize: 100,
      isLoading: true,
      msg: ''
    }
  }

  componentDidMount = async () => {
    const profile = await user.getProfile();
    console.log(profile, '--profile--');

    const adminAccess = user.checkFeatureAccess(adminFeatureMapping.CHAT_FEED_ACCESS);
    if (!adminAccess)
      window.location.href = '/notAdmin';

    const { date, pageNo } = this.state;
    this.fetchMessages('', date, pageNo);
  }

  fetchMessages = (type, date, pageNo) => {
    let { pageSize } = this.state;
    let day = 24 * 60 * 60 * 1000;
    if (type == 'prev') {
      pageNo -= 1;
    }
    if (type == 'next') {
      pageNo += 1;
    }
    this.setState({ isLoading: true, msg: '' });
    fetchChatMessages(pageSize, pageNo * pageSize, convertUnixToDate(date.getTime()), convertUnixToDate(date.getTime() + day))
      .then(res => {
        if (res.status) {
          this.setState({ messages: res.data, pageNo, isLoading: false });
        } else {
          this.setState({ msg: 'Something went wrong', isLoading: false })
        }
      })
  }

  handleChange = (date) => {
    this.setState({ date, pageNo: 0 });
    this.fetchMessages('', date, 0);
  };


  render() {
    const {
      messages,
      date,
      pageNo,
      pageSize,
      isLoading,
      msg
    } = this.state;
    return (
      <div className='chat-feed-wrapper'>
        <p className='title'>Chat Messages</p>
        <div className='date-picker'>
          <DatePicker
            selected={date}
            onChange={this.handleChange}
            filterDate={(date) => {
              return new Date() > date;
            }}
          />
        </div>
        <div className='btn-wrapper'>
          <Button
            skin={pageNo === 0 ? 'light' : 'dark'}
            disabled={pageNo === 0}
            text='Prev'
            clickButton={() => this.fetchMessages('prev', date, pageNo)}
          />
          <Button
            skin={messages.length < pageSize ? 'light' : 'dark'}
            disabled={messages.length < pageSize}
            text='Next'
            clickButton={() => this.fetchMessages('next', date, pageNo)}
          />
        </div>
        {
          !isLoading && !msg ?
            messages.length ?
              messages.map((val, index) => (
                <div key={index} className='message-wrapper'>
                  <p className='time'>{convertUnixToTime(val.t)}</p>
                  <a className='r' href={`https://www.${domain}/r/${getJAndR(val.c).r}-shilpi-vohra`}>{getJAndR(val.c).r}</a>
                  <a className='j' href={`https://www.${domain}/recruiter/view_resume.php?id=${getJAndR(val.c).j}`}>{getJAndR(val.c).j}</a>
                  <p className='type'>{val.u.includes('r') ? 'Recruiter' : 'Jobseeker'}</p>
                  <p
                    className='msg'
                    dangerouslySetInnerHTML={createMarkup(val.p.text)}
                  />
                </div>
              )) : <div className='message-wrapper'>No messages</div>
            : ''
        }
        {msg ?
          <div className='message-wrapper'>{msg}</div>
          : isLoading && <Loader />
        }
      </div>
    )
  }
}

export default ChatFeed;
