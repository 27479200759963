import React, { useState, useEffect } from 'react';
import user from '../../store/user';
import './unverifiedUserList.scss';
import { Table } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import CountDisplay from '../../components/CountDisplay';
import InputBox from '../../components/InputBox'
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Heading from '../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import { getQueryParams } from "../../utils/utilFunctions";
import fetchSeekerVerificationList from '../../services/fetchSeekerVerificationList';
import updateSeekerEmailStatus from '../../services/updateSeekerEmailStatus';
import updateSeekerPhoneStatus from '../../services/updateSeekerPhoneStatus';

const PAGE_CONTENT = 100;
const UnverifiedUserList = (props) => {

  const [content, setContent] = useState({
    list: [],
    count: 0,
    currentCount: 0,
    pageNumber: 1
  })

  const [searchSeekerId, setSearchSeekerId] = useState('');

  const [admin, setAdmin] = useState({
    adminId: null,
  })
  const [fetchApiCall, setFetchApiCall] = useState(true);

  useEffect(() => {

    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3) {
        setAdmin({
          adminId: userData.id,
        })
        getResult(userData.id);

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    });

  }, [])

  const getList = async (adminId, pageNumber, filters = {}) => {
    setFetchApiCall(true);
    try {
      const res = await fetchSeekerVerificationList(adminId, { pg: pageNumber, ...filters })
      setContent(prev => ({
        ...prev,
        pageNumber: pageNumber,
        list: res.data.data.data,
        count: res.data.data.totalCount,
        currentCount: res.data.data.pageCount
      }))
      setFetchApiCall(false)
    }
    catch (err) {
      toast.error('Something went wrong');
      console.log(err);
      setFetchApiCall(false)
    }
  }

  const handlePageChange = async(n) => {
  
      const filterObj = {}
      if(searchSeekerId.trim()) {
        filterObj['user'] = searchSeekerId;
      }

      await getList(admin.adminId,n, filterObj)
      routeRequest(n,filterObj)
  }

  const routeRequest = (pageNumber, filters) => {
    
    let array = [];

    array.push(`pageNumber=${pageNumber}`);
    if(filters.seekerId){
      array.push(`user=${filters.seekerId}`)
    }
    array = array.join("&");
    props.history.push("?" + array);
};

 
  const getResult = async (adminId) => {
    const queryObj = getQueryParams(props.location.search);
    setContent(prev => ({
      ...prev,
      pageNumber: queryObj.pageNumber
    }))
    setSearchSeekerId(queryObj.user || '');
    const filterObj = {};
    if(queryObj.user) {
      filterObj['user'] = queryObj.user;
    }
   
    const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
    await getList(adminId, pgNo, filterObj);
  }

  const changeHandler = (ev) => {
    setSearchSeekerId(ev.target.value)
  };

  const onEnterPress = async (e) => {
    if(e.key == 'Enter') {
       await onSearch();
    }
  }

  const onSearch = async () => {
    const filterObj = {}
    if(searchSeekerId.trim()) {
      filterObj['user'] = searchSeekerId;
      await getList(admin.adminId,1, filterObj)
      routeRequest(1,{seekerId: searchSeekerId})

    }
}

const onClickClearSearch = async () => {
    setSearchSeekerId('');
    await getList(admin.adminId, 1)
    routeRequest(1, {})
  }

const changeVerificationStatus = async (e, seekerDetails, status, property) => {

  e. preventDefault();

    const data = {
        userId: seekerDetails['id'],
        flag: status ? 'verify' : 'unverify'
    }

    try {
        if(property === 'email'){
          await updateSeekerEmailStatus(admin.adminId, data);
        }
        else {
          await updateSeekerPhoneStatus(admin.adminId, data);
        }
        await getResult(admin.adminId);
        toast.success('Status updated successfully');
    }
    catch(e) {
        console.log(e);
        toast.error('Something went wrong')
    }
    
}

  return (
    <div className="unv-usr-list-wrapper">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

      <div className="unv-usr-list-heading">
        <Heading text='User Verification Info Management' />
        <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/unverifieduser.php`} target='_blank'>
            <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
        </a>
      </div>

        <hr />

        <div className='search-container'>
            <InputBox
                name="seekerId"
                placeholder="Seeker Id or email"
                value={searchSeekerId}
                onChange={changeHandler}
                onKeyPress={onEnterPress}
            />
            <Button
                text='Search'
                skin='dark'
                clickButton={onSearch}
            />
            <Button
                skin="light"
                text="Clear"
                clickButton={onClickClearSearch}
            ></Button>
        </div>


      <div className="row text-center">

      <Pagination
          totalItemsCount={content.count}
          content={PAGE_CONTENT}
          pageNumber={content.pageNumber}
          handlePageChange={(n) => handlePageChange(n)} />
      <CountDisplay start={(content.pageNumber - 1) * content.currentCount + 1} end={content.pageNumber * content.currentCount} total={content.count} />

        {fetchApiCall ? (
          <Loader />
        ) : (
          content.list.length ? (
            <div className="content">
              <Table
                fixed
                selectable
                className="mailers-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>
                      Id
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Number
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Email
                    </Table.HeaderCell>  
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {content.list.map((item, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                            {item['id']}
                        </Table.Cell>
                        <Table.Cell>
                            {item['name']}
                        </Table.Cell>
                        <Table.Cell>
                            {
                              item['phone'] ? (
                                <>
                                  <p>{item['phone']}</p>
                                  <p>{item['phone_status'] ? <a href="" onClick={(e) => changeVerificationStatus(e, item, false, 'phone')}>Unverify Phone</a> : <a href="" onClick={(e) => changeVerificationStatus(e, item, true, 'phone')}>Verify Phone</a> }</p>
                                </>
                              ) : null
                            }
                            
                        </Table.Cell>
                        <Table.Cell>
                            {
                              item['email'] ? (
                                <>
                                  <p>{item['email']}</p>
                                  <p>{item['email_status'] ? <a href="" onClick={(e) => changeVerificationStatus(e, item, false, 'email')}>Unverify Email</a> : <a href="" onClick={(e) => changeVerificationStatus(e, item, true, 'email')}>Verify Email</a> }</p>
                                </>
                              ) : null
                            }
                            
                        </Table.Cell>
                        
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <div>
              No Records Found
            </div>
          )
          
        )
        }
      </div>
    </div>
  );
};

export default UnverifiedUserList;