/**
*
* SearchMailReport
*
*/
import React, { useEffect, useState } from "react";
import user from '../../store/user';
import Heading from '../../components/Heading';
import Loader from '../../components/Loader';

import InputBox from "../../components/InputBox";
import Button from "../../components/Button";

import { Table } from "semantic-ui-react";

import getSearchMailReport from "../../services/getSearchMailReport";
import { getQueryParams } from "../../utils/utilFunctions";

import "./styles.scss";
import Moment from "react-moment";

const PAGE_CONTENT = 50;
function SearchMailReport(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);
    const [recs, setRecs] = useState({});

    useEffect(() => {
        const { search = '' } = getQueryParams(window.location.search);
        setSearch(search);
        getData(search);
    }, []);

    async function getData(search) {
        try {

            if (!search) return;

            setIsLoading(true);
            const profile = await user.getProfile();
            const resp = await getSearchMailReport(profile.id, {
                search
            });
            setIsLoading(false);
            setList(resp.data.data.data);
            setRecs(resp.data.data.recruiters);

            console.log(resp.data);

        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }

    function handleKeyPress(event) {
        if (event.key == 'Enter') {
            onClickSearch();
        }
    };

    function onClickClear() {
        setSearch("");
        setList([]);
        props.history.push(`?`);
    }

    function onClickSearch() {
        getData(search);
        props.history.push(`?search=${search}`);
    }

    function onChange(ev) {
        setSearch(ev.target.value);
    }

    return (
        <div className="search-mail-report">

            <div className="pageHeading">
                <Heading text='Search Mail Report' />
                <a className="right" href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/search_mail_report.php`} target='_blank'>
                    <Button skin='dark' disabled={false} text='Use Old Version' />
                </a>
            </div>

            <div className='left'>
                <InputBox placeholder='Enter RecID/Email' value={search} onChange={onChange} onKeyPress={handleKeyPress} />
                <Button skin='dark' disabled={false} text='Search' clickButton={onClickSearch} />
                <Button skin='light' disabled={false} text='Clear' clickButton={onClickClear} ></Button>
            </div>

            {
                list.length ?
                    <div className="content">

                        <Table fixed selectable className="mailers-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Action Date</Table.HeaderCell>
                                    <Table.HeaderCell>Recruiter</Table.HeaderCell>
                                    <Table.HeaderCell>Search Query</Table.HeaderCell>
                                    <Table.HeaderCell>No of candidates</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    list.map(item => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell> <Moment format="DD-MM-YYYY">{item.actiondate}</Moment> </Table.Cell>
                                            <Table.Cell>
                                                <div>{(recs[item.recruiterid] || {}).name}</div>
                                                <div>{(recs[item.recruiterid] || {}).email}</div>
                                            </Table.Cell>
                                            <Table.Cell>{item.searchkw}</Table.Cell>
                                            <Table.Cell>{item.candidate_count}</Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>

                    </div> : null
            }

            <div>
                {
                    isLoading ?
                        <Loader /> : null
                }
            </div>
            {
                !list.length ?
                    <div className="no-recs">No Records Found</div> : null
            }
            <br />
            <br />
        </div>
    )
}

export default SearchMailReport;
