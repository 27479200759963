const mailersStatus = {
    1: "New",
    2: "Updated",
    3: "Testing",
    4: "Testing Fail",
    5: "Testing Complete",
    6: "Running",
    7: "Sent",
    8: "Sent Failed",
    9: "Stoped",
    10: "Delete",
    11: "Running"
};

export default mailersStatus;