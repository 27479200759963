import React, { useState, useEffect } from 'react';
import user from '../../../store/user';
import fetchCourseList from '../../../services/fetchCourseList';
import './styles.scss';
import { Header, Modal, Table } from 'semantic-ui-react';
import Loader from '../../../components/Loader';
import CountDisplay from '../../../components/CountDisplay';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { getQueryParams } from "../../../utils/utilFunctions";
import { Link } from 'react-router-dom';
import deleteCourse from '../../../services/deleteCourse';
import unpublishCourse from '../../../services/unpublishCourse';
import rejectCourse from '../../../services/rejectCourse';
import deleteCourseBrochure from '../../../services/deleteCourseBrochure';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { rejectReasonsCourse } from '../../../models/rejectReasonsCourse';


const PAGE_CONTENT = 50;


const CourseList = (props) => {

    const [content, setContent] = useState({
        list: [],
        count: 0,
        pageNumber: 1
    })

    const [admin, setAdmin] = useState({
        adminId: null,
    })

    const [fetchApiCall, setFetchApiCall] = useState(true)

    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [modalCourseId, setModalCourseId] = useState(null);
    const [rejectData, setRejectData] = useState({
        reasonId: '',
        rejectMsg: ''
    })


    useEffect(() => {

        const fetchProfile = async () => {
            return await user.getProfile()
        }
        fetchProfile().then(userData => {
            if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
                setAdmin({
                    adminId: userData.id,
                })
                const queryObj = getQueryParams(props.location.search);
                setContent(prev => ({
                    ...prev,
                    pageNumber: queryObj.pageNumber
                }))
                
                const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;
                getCourseList(userData.id, pgNo);

            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        });

    }, [])

    const getCourseList = async (adminId, pageNumber) => {
        setFetchApiCall(true);
        try {
            routeRequest(pageNumber)
            const res = await fetchCourseList(adminId, { page: pageNumber, pageSize: PAGE_CONTENT })
            setContent({
                pageNumber: pageNumber,
                list: res.data.data.data,
                count: res.data.data.totalCount
            })
            setFetchApiCall(false)
        }
        catch (err) {
            console.log(err);
            setFetchApiCall(false)
        }
    }

    const handlePageChange = async(n) => {
            await getCourseList(admin.adminId, n)
    }

    const routeRequest = (pageNumber) => {
        let array = [];

        array.push(`pageNumber=${pageNumber}`);
        array = array.join("&");
        props.history.push("?" + array);
    };

    const delCourse = async (e, courseId) => {
        e.preventDefault();
        if (window.confirm('Are you sure to delete this Course ? ')) {

            try{
                setFetchApiCall(true);
                await deleteCourse(admin.adminId, courseId);
                toast.info('Course deleted successfully');

                const queryObj = getQueryParams(props.location.search);
                const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;

                await getCourseList(admin.adminId, pgNo)
                setFetchApiCall(false);


            }catch(e) {
                setFetchApiCall(false);
                toast.error('Course could not be deleted');
            }
        }
    }

    const unpubCourse = async (e, courseId) => {
        e.preventDefault();
        if (window.confirm('Are you sure to unpublish this Course ? ')) {

            try{
                setFetchApiCall(true);
                await unpublishCourse(admin.adminId, courseId);
                toast.info('Course unpublished successfully');

                const queryObj = getQueryParams(props.location.search);
                const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;

                await getCourseList(admin.adminId, pgNo)
                setFetchApiCall(false);


            }catch(e) {
                setFetchApiCall(false);
                toast.error('Course could not be unpublished');
            }
        }
    }

    const onClickRejectButton = async () => {
        try{
            // if(!rejectData.reasonId || !rejectData.rejectMsg) {
            //     alert('Please enter a reason! ')
            //     return;
            // }
            if(!rejectData.reasonId) {
                alert('Please enter a reason! ')
                return;
            }
            setFetchApiCall(true);
            const data = {
                courseId: modalCourseId,
                reason: rejectData.rejectMsg
            }
            await rejectCourse(admin.adminId, data);
            toast.info('Course rejected successfully');

            const queryObj = getQueryParams(props.location.search);
            const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;

            setIsRejectModalOpen(false);
            setRejectData({
                reasonId: '',
                rejectMsg: ''
            })

            await getCourseList(admin.adminId, pgNo);
            setFetchApiCall(false);

        }catch(e) {
            setFetchApiCall(false);
            toast.error('Course could not be rejected');
        }
    }

    const rejCourse = (e, courseId) => {
        e.preventDefault();
        setModalCourseId(courseId);
        setIsRejectModalOpen(true);
    }

    const delBrochure = async (e, courseId, recId) => {
        e.preventDefault();
        if (window.confirm('Are you sure to delete this Course Brochure? ')) {

            try{
                setFetchApiCall(true);
                await deleteCourseBrochure(admin.adminId, {
                    courseId,
                    recId
                });
                toast.info('Course Brochure deleted successfully');

                const queryObj = getQueryParams(props.location.search);
                const pgNo = queryObj.pageNumber ? queryObj.pageNumber : 1;

                await getCourseList(admin.adminId, pgNo);
                setFetchApiCall(false);


            }catch(e) {
                setFetchApiCall(false);
                toast.error('Course Brochure could not be deleted');
            }
        }
    }

    const rejectDropdownChange = (event) => {
        var rejectJobReasonSelect=rejectReasonsCourse.filter((eachReason)=>{if(eachReason.id==event.target.value)return true;return false;})
        setRejectData({ rejectMsg: rejectJobReasonSelect[0].msg , reasonId: event.target.value })
        // setRejectData({ rejectMsg: '' , reasonId: event.target.value })
    }

    const onModalClose = () => {
        setIsRejectModalOpen(false);
        setModalCourseId(null);
        setRejectData({
            reasonId: '',
            rejectMsg: ''
        })
    }

    const rejectReasonChange = (e) => {
        const value = e.target.value;
        setRejectData(prev => ({
            ...prev,
            rejectMsg: value
        }))
    }

    return (
        <div className="course-list-wrapper">
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />

            <div className="course-list-heading">
                <Heading text="Course" />
                <Button  skin='dark' className="btn_post_course" text="Post Course" clickButton={() => { props.history.push('/addCourse') }} />
                {/* <a href='/courseMappingList'>
                    <Button  skin='dark' className="btn_course_mapping" text="Course Mapping" clickButton={() => { props.history.push('/courseMappingList?pageNumber=1') }}  />
                </a> */}
                <Button  skin='dark' className="btn_course_mapping" text="Course Mapping" clickButton={() => { props.history.push('/courseMappingList?pageNumber=1') }}  />

                <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/course_admin_new.php`} target='_blank'>
                        <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
                </a>

            </div>


            <div className="row text-center">

            <Pagination
                    totalItemsCount={content.count}
                    content={PAGE_CONTENT}
                    pageNumber={content.pageNumber}
                    handlePageChange={(n) => handlePageChange(n)} />
            <CountDisplay start={(content.pageNumber - 1) * PAGE_CONTENT + 1} end={content.pageNumber * PAGE_CONTENT} total={content.count} />

                {fetchApiCall ? (
                    <Loader />
                ) : (
                    content.list.length ? (
                        <div className="content">
                            <Table
                                fixed
                                selectable
                                className="mailers-table"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}>
                                            Date
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={4}>
                                            Title
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Hits/ Applied
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Actions
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Recruiter
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            Display Location <br />
                                            Main Website/ Mobile App/ Similar Jobs Web/ Similar Jobs Mobile
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {content.list.map((item, i) => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>
                                                    {moment(item.date).format('DD-MM-YYYY hh:mm a')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Link to={`/addCourse?courseId=${item.id}&publishedId=${item.publishedId}`}>{`${item.title} `}</Link>
                                                    {item.isPremium ? '[Premium]' : null} <br />
                                                    {`Published Id: ${item.publishedId}`}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {`${item.hits} Hits`} <br />
                                                    {
                                                        item.publishedId ? (
                                                                <Link to={`/courseEnquiry?courseId=${item.publishedId}`}>View Leads </Link>
                                                        ) : 'View Leads'
                                                    }
                                                    ({item.leadsCount})
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.status}
                                                    {item.lastUpdated == 1 ? ' (But Updated)' : null} <br />
                                                    {
                                                        item.isPublished ? (
                                                            <>
                                                                <a href="" onClick={(e) => delCourse(e, item.id)} >Delete</a> <br />
                                                                <a href="" onClick={(e) => unpubCourse(e, item.id)}>Unpublish</a>
                                                            </>
                                                        ): (
                                                            <>
                                                                <a href="" onClick={(e) => delCourse(e, item.id)}>Delete</a> <br />
                                                                {
                                                                    !item.isRejected ? (
                                                                        <a href="" onClick={(e) => rejCourse(e, item.id)}>Reject</a>
                                                                    ) : null
                                                                }
                                                            </>

                                                        )
                                                        
                                                    }
                                                    
                                                    
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.email} <br />
                                                    <a target='_blank' href={`${process.env.REACT_APP_CLIENT_URL}/r/${item.recId}-${item.recName}`}>View Profile</a> <br />
                                                    <Link to={`/changeCourseRecruiter?courseId=${item.id}`}>Change Recruiter</Link> <br />
                                                    {
                                                        item.brochureFile ? (
                                                            <a href="" onClick={(e) => delBrochure(e, item.id, item.recId)}>Delete Brochure File</a>
                                                        ): null
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.web}/ {item.mobApp}/ {item.similarWeb}/ {item.simMob} <br />
                                                    Redirected: {item.redirected}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>

                            <Modal open={isRejectModalOpen} onClose={onModalClose} >
                                <Header content = 'Why Rejected' />
                                <Modal.Content>
                                    <div className='modal-content'>
                                        <div className='modal-subcontent'>
                                            <label className='modal-label'>Select Reason</label>
                                            <Dropdown dropdownChange={rejectDropdownChange} className='modal-dropdown' name='rejectJob' data={rejectReasonsCourse} />
                                        </div>
                                        <div className='modal-subcontent'>
                                            <label className='modal-label'> Enter Reason</label>
                                            <textarea value={rejectData.rejectMsg} rows='5' maxLength='300' onChange={rejectReasonChange}>
                                            </textarea>
                                        </div>
                                        <div className='modal-subcontent'>
                                            Max 300 characters
                                        </div>
                                    </div>
                                </Modal.Content>

                                <Modal.Actions>
                                    <Button text='Reject' skin='light' clickButton={onClickRejectButton} />
                                    <Button text='Close' skin='light' clickButton={onModalClose} />
                                </Modal.Actions>
                            </Modal>
                        </div>
                    ) : (
                        <div>
                            No Records Found
                        </div>
                    )
                    
                )
                }
            </div>
        </div>
    );
};

export default CourseList;