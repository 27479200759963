import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import InputBox from '../../../components/InputBoxWithoutCheck';
import Loader from '../../../components/Loader';
import Dropdown from '../../../components/Dropdown/Dropdown';
import FromToDatePicker from '../../../components/FromToDatepicker';
import validator from 'validator';
import user from '../../../store/user';
import postResumeBuilderCandidate from '../../../services/postResumeBuilderCandidate';
import fetchIntegration from '../../../services/fetchIntegration';
import './editCandidate.scss';
import moment from 'moment';
import fetchResumeBuilderCandidate from '../../../services/fetchResumeBuilderCandidate';
import updateResumeBuilderCandidate from '../../../services/updateResumeBuilderCandidate'
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';

var scrollToElement = require('scroll-to-element');

const validationMsg = {
  integration: 'Integrtaion is Required',
  email: 'Enter a valid email address',
  status: 'Status is Required',
  institute: 'Institue is Required',
  datePicker:
    'Start and endDate are required and start date must be less than end date',
};

const ResumeBuilderEdit = (props) => {
  const [candidate, setCandidate] = useState({
    email: '',
    status: 1,
  });

  const [submitApiCall, setSubmitApiCall] = useState(false);
  const [validations, setValidations] = useState({});
  const [fetchedDetails, setFetchedDetails] = useState(true);
  const [integrationData, setIntegrationData] = useState([]);
  const [showSubmit,setShowSubmit] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profile = await user.getProfile();

        const adminAccess = user.checkFeatureAccess(adminFeatureMapping.EDIT_CANDIDATE_RESUME);
        if (!adminAccess)
          window.location.href = '/notAdmin';

        await fetchAllIntegrations();
        if (props.match.params.id) {
          var userData = await fetchResumeBuilderCandidate({
            id: props.match.params.id,
          });
          userData = userData.users[0];
          var userInstData = await fetchIntegration({
            id: userData.integration_type,
          });
          setCandidate({
            email: userData.email,
            integration: userData.integration_type,
            startDate: new Date(userData.start_time),
            endDate: new Date(userData.expiration_time),
            status: userData.status,
            institute: userInstData.data[0].institute,
            uniqueId: userData.id,
          });
          if(new Date(userData.expiration_time).getTime() < new Date().getTime())
          {
            setShowSubmit(false);
          }
        }
      } catch (err) {
        toast.error('Could Load Page');
      }
    };

    fetchData();
  }, []);

  const onChange = (key, ev) => {
    const data = { ...candidate };
    if (key != 'startDate' && key != 'endDate')
      data[key] = ev.target.value || ev.target.checked;
    else if (key == 'startDate' && !data['endDate']) {
      data[key] = ev;
      data['endDate'] = new Date(
        moment(ev)
          .add(6, 'months')
          .format()
      );
    } else {
      data[key] = ev;
    }

    setCandidate(data);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setSubmitApiCall(true);

      try {
        const data = {
          email: candidate.email,
          integrationType: candidate.integration,
          status: candidate.status,
          startTime: moment(candidate.startDate).format(),
          expirationTime: moment(candidate.endDate).format(),
        };

        //post data
        var res;
        if (props.match.params.id) {
          data.id = candidate.uniqueId;
          res = await updateResumeBuilderCandidate(data);
          //update api
        } else {
          res = await postResumeBuilderCandidate(data);
        }

        setSubmitApiCall(false);

        if (!res || res.status != 200)
          {if(res.response && res.response.data && res.response.data.reason)
            {
              toast.error(res.response.data.reason);
              if(res.response.status==400)setValidations({ email: 1 });
            }
            else
            toast.error('Unfortunately something went wrong...');
          }
        else {
          toast.success('Data successfully updated');
          props.history.push('/resumeBuilder');
        }
      } catch (err) {
        console.log(err);
        setSubmitApiCall(false);

        toast.error('Unfortunately something went wrong...');
      }
    }
  };

  const validate = () => {
    if (checkEmpty(candidate.email)) {
      setValidations({ email: 1 });
      scrollToElement('email');
      return false;
    }
    if (checkEmpty(candidate.integration)) {
      setValidations({ integration: 1 });
      scrollToElement('integration');
      return false;
    }
    if (checkEmpty(candidate.institute)) {
      setValidations({ institute: 1 });
      scrollToElement('institute');
      return false;
    }

    if (
      moment(candidate.startDate).format() >= moment(candidate.endDate).format()
    ) {
      setValidations({ datePicker: 1 });
      scrollToElement('datePicker');
      return false;
    }

    return true;
  };

  const checkEmpty = (val) => {
    if (!val) return true;
    if (!isNaN(val) && val == -1) return true;
    if (typeof val == 'string' && validator.isEmpty(val)) return true;
    return false;
  };

  async function fetchDetails() {
    if (!candidate.email.length) return;
    setFetchedDetails(false);
    const params = {
      email: candidate.email,
    };
    var usersData = await fetchResumeBuilderCandidate(params);

    if (!usersData.totalCount) {
      setFetchedDetails(true);
      return;
    }

    usersData = usersData.users.filter((eachUser) => {
      return new Date(eachUser.expiration_time).getTime() >= new Date().getTime();
    });

    if (!usersData.length) {
      setFetchedDetails(true);
      return;
    }
    usersData = usersData[0];
    var userInstData = await fetchIntegration({
      id: usersData.integration_type,
    });
    setCandidate({
      email: usersData.email,
      integration: usersData.integration_type,
      startDate: new Date(usersData.start_time),
      endDate: new Date(usersData.expiration_time),
      status: usersData.status,
      institute: userInstData.data[0].institute,
      uniqueId: usersData.id,
    });
    var id = usersData.id;
    props.history.push(`/resumeBuilder/edit/${id}`);

    setFetchedDetails(true);
  }

  const fetchAllIntegrations = async () => {
    try {
      var integrationsData = await fetchIntegration({ name: 1 });

      const list = integrationsData.data;
      list.unshift({ id: 0, name: 'Choose Integration' });
      setIntegrationData(list);
    } catch (err) {
      toast.error('Could load integration Data');
    }
  };
  async function integrationDropdownChange(e) {
    try {
      var id = e.target.value;
      var instituteData = await fetchIntegration({ id });

      setCandidate({
        ...candidate,
        integration: id,
        institute: instituteData.data[0].institute,
      });
    } catch (err) {
      console.log(err);
      toast.error('Couldnt load institutes');
    }
  }

  return (
      <div>
          <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

          <div className="pageHeading">
              <Heading text="Add a candidate" />
          </div>
          <hr />
          <div className="">
              <form>
                  <div className="row details-section">
                      <div className="form-group email">
                          <label className="col-sm-2 left"> Email </label>
                          <div className="col-sm-6 left valueBox">
                              <InputBox
                  type="text"
                  value={candidate.email || ''}
                  disabled={props.match.params.id ? true : false}
                  placeholder="Enter Email"
                  onChange={(e) => onChange('email', e)}
                  onBlur={(e) => {
                    fetchDetails();
                  }}
                />
                              {validations.email ? (
                                  <p className="validatorError"> {validationMsg.email} </p>
                ) : null}
                          </div>
                      </div>
                      {!fetchedDetails ? (
                          <Loader />
            ) : (
                <div className="actions">
                    <div className={'form-group integration'}>
                        <label className="col-sm-2 left"> Integration Type </label>
                        <div className="col-sm-6 left valueBox">
                            <Dropdown
                      type="text"
                      value={candidate.integration || 0}
                      data={integrationData}
                      name="integrationName"
                      dropdownChange={integrationDropdownChange}
                      defaultValue={candidate.integration || 0}
                    />
                            {validations.integration ? (
                                <p className="validatorError">
                                    {validationMsg['integration']}
                                </p>
                    ) : null}
                        </div>
                    </div>

                    <div className={'form-group status'}>
                        <label className="col-sm-2 left"> Status </label>
                        <div className="col-sm-6 left valueBox">
                            <InputBox
                      type="radio"
                      value={1}
                      checked={parseInt(candidate['status']) ? true : false}
                      onChange={(e) => onChange('status', e)}
                    />
                            <label className="statusMarginRight">Active</label>
                            <InputBox
                      type="radio"
                      value={0}
                      checked={parseInt(candidate['status']) ? false : true}
                      onChange={(e) => onChange('status', e)}
                    />
                            <label>Inactive</label>
                        </div>
                    </div>

                    <div className={'form-group institute'}>
                        <label className="col-sm-2 left"> Institute </label>
                        <div className="col-sm-6 left valueBox">
                            {
                                <InputBox
                        type="text"
                        disabled
                        value={candidate.institute || ''}
                        placeholder={'Institute of the Candidate'}
                      />
                    }
                            {validations.institute ? (
                                <p className="validatorError">
                                    {validationMsg['institute']}
                                </p>
                    ) : null}
                        </div>
                    </div>
                    <div className={'form-group '}>
                        <label className="col-sm-2 left"> Date </label>
                        <div className="col-sm-6 left valueBox">
                            <FromToDatePicker
                      startDate={candidate['startDate']}
                      handleChangeStart={(e) => onChange('startDate', e)}
                      endDate={candidate['endDate']}
                      handleChangeEnd={(e) => onChange('endDate', e)}
                    />
                            {validations['datePicker'] ? (
                                <p className="validatorError">
                                    {validationMsg['datePicker']}
                                </p>
                    ) : null}
                        </div>
                    </div>
                    <div className="submitButtons">
                        { showSubmit?<Button
                    skin="dark submitButton"
                    disabled={submitApiCall}
                    loading={submitApiCall}
                    text="Submit"
                    clickButton={onSubmit}
                  />:null}
                        <Button
                    skin="dark"
                    disabled={submitApiCall}
                    text="Cancel"
                    clickButton={(e) => props.history.push('/resumeBuilder')}
                  />
                    </div>
                </div>
            )}
                  </div>
              </form>
          </div>
      </div>
  );
};

export default ResumeBuilderEdit;
