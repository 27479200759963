var languages = [
    {
       "text":"English"
      ,"val":"English",
  
    },
    {
       "text":"Hindi"
      ,"val":"Hindi"
    },
    {
       "text":"Arabic"
      ,"val":"Arabic"
    },
    {
       "text":"Portuguese"
      ,"val":"Portuguese"
    },
    {
       "text":"Bengali"
      ,"val":"Bengali"
    },
    {
       "text":"Bihari"
      ,"val":"Bihari"
    },
    {
       "text":"Marathi"
      ,"val":"Marathi"
    },
    {
       "text":"German"
      ,"val":"German"
    },
    {
       "text":"French"
      ,"val":"French"
    },
    {
       "text":"Gujarati"
      ,"val":"Gujarati"
    },
    {
       "text":"Kannada"
      ,"val":"Kannada"
    },
    {
       "text":"Malayalam"
      ,"val":"Malayalam"
    },
    {
       "text":"Punjabi"
      ,"val":"Punjabi"
    },
    {
       "text":"Telugu"
      ,"val":"Telugu"
    },
    {
       "text":"Tamil"
      ,"val":"Tamil"
    },
    {
       "text":"Urdu"
      ,"val":"Urdu"
    },
    {
       "text":"Oriya"
      ,"val":"Oriya"
    },
    {
       "text":"Nepali"
      ,"val":"Nepali"
    },
    {
       "text":"Kashmiri"
      ,"val":"Kashmiri"
    },
    {
       "text":"Irish"
      ,"val":"Irish"
    },
    {
       "text":"Persian"
      ,"val":"Persian"
    },
    {
       "text":"Russian"
      ,"val":"Russian"
    },
    {
       "text":"Italian"
      ,"val":"Italian"
    },
    {
       "text":"Sindhi"
      ,"val":"Sindhi"
    }].sort(function(a, b){
        return a["text"].localeCompare(b.text)
    });

export function getLanguages() {
    return languages.map(item => {
        return {
            label: item.text,
            value: item.val.toString(),
            key: item.val
        }
    })
}