export const natureOfOfferings = [
    { name: 'Select', id: '' },
    { name: 'Product', id: 1 },
    { name: 'Service', id: 2 },
    { name: 'Product & Services', id: 3 },
    { name: 'Captive', id: 4 },
    { name: 'Innovation Labs', id: 5 },
    { name: 'R&D/Development Center', id: 6 },
    { name: 'Marketplace', id: 7 },
    { name: 'Other', id: 8 },
];

export function getNatureOfOfferingsFromId () {
    return natureOfOfferings.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}