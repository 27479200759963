import axios from '../utils/axios';

export default function getCurrentSubscription(adminId, params) {
    return axios.
        get(`/admin/${adminId}/jobseeker-admin/v2/admin-recruiter/currentsubscription`, { params })
        .then(response => {
            return response.data.data;
        })
        .catch(err => {
            return {};
        });
}