import axios from '../utils/axios';

export default function postCompanyManagement(recId,data) {
    return (
        axios.post('/admin/' + recId +'/companyManagement/v1/companies',  data )
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err;
        })
    )
}