import { getDomain } from "../utils/utilFunctions";

const domain = getDomain();

const rejectJobHirist = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },
    {
        'name': 'Not Relevant',
        'id':6,
        'msg': `We cannot post this as it is not relevant to our target audience. We post IT/Tech jobs only. For more information, please drop in a mail to info@${domain}.`
    },
    {
        'name': 'More Details',
        'id':7,
        'msg': `Please provide more details about the role & responsibilities/profile. The content provided in the JD is insufficient for us to post this job. Once you update it, please drop us a note at info@${domain} or inform us on the chat. We will review and publish it.`
    },
    {
        'name': 'Salary Offered',
        'id':8,
        'msg': `How much is the average salary offered for this position ? Once you update it, please drop us a note at info@${domain} or inform us on the chat.`
    },
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': `We generally don/'t allow job postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@${domain} including your contact number.`
    },
    {
        'name': 'iimjobs.com',
        'id':2,
        'msg': `Please post this job on iimjobs.com at http://www.iimjobs.com/recruiter/login.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@${domain}.`
    },
    {
        'name': 'engineeristic.com',
        'id':9,
        'msg': `Please post this job on engineeristic.com at http://www.engineeristic.com/recruiter/recruiter.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@${domain}. `
    },
    {
        'name': 'updazz.com',
        'id':10,
        'msg': `Please post this job on updazz.com at http://www.updazz.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to info@${domain}.`
    },
    {
        'name': 'biojoby.com',
        'id':13,
        'msg': `Please post this job on biojoby.com at https://www.biojoby.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to info@${domain}. `
    },
    {
        'name': 'Duplicate Posting',
        'id':3,
        'msg': `This job has already been posted on the portal. Please write to us at info@${domain} for any queries/clarifications`
    },
    {
        'name': 'Multiple Locations',
        'id':4,
        'msg': `All locations have been updated within the same job posting which is published. Please write to us at info@${domain} for any queries/clarifications.`
    },
    {
        'name': 'No content',
        'id':11,
        'msg': `There is no content/JD available. Please post it again with more details and update at info@${domain}`
    },
    {
        'name': 'Others',
        'id':5,
        'msg': `Please write to us at info@${domain} for any queries/clarifications.`
    },
    {
        'name': 'Low Salary',
        'id':14,
        'msg': `Unfortunately, the salary offered for this job is low. We only post premium positions on this portal. Please write to us at info@${domain} for any queries/clarifications.`
    },
    {
        'name': 'Low Salary v-a-v experience',
        'id':15,
        'msg': `Unfortunately, the salary offered for this job is low vis-a-vis the years of experience required. We only post premium positions on this portal. Please write to us at info@${domain} for any queries/clarifications.`
    },
    {
        'name': 'More Details+Official id issue',
        'id': 16,
        'msg': `We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids. This is to ensure credibility of posting on the portal.In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@${domain} including your contact number. Also, please provide more details about the role & responsibilities as the content currently provided in the JD is insufficient.`
    },
    {
        'name': 'Multiple JDs',
        'id': 17,
        'msg': 'Kindly post all the roles separately with proper job descriptions.'
    },
    {
        'name': 'JD & Title Mismatch',
        'id': 18,
        'msg': 'The job description and title do not match. Please update the job description.'
    },

];
const rejectJobIIMJOBS = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },
    {
        'name': 'Not Relevant',
        'id':6,
        'msg': 'Regret we cannot post this job as it is not relevant to our target audience i.e. candidates from tier 1/premier B schools of India. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'More Details',
        'id':7,
        'msg': 'Please provide more details about the role & responsibilities/profile. The content provided in the JD is insufficient for us to post this job. Once you update it, please drop us a note at info@iimjobs.com or inform us on the chat. We will review and publish it.'
    },
    {
        'name': 'Salary Offered',
        'id':8,
        'msg': 'How much is the average salary offered for this position ? Once you update it, please drop us a note at info@iimjobs.com or inform us on the chat.'
    },
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': 'We generally don\'t allow job postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@iimjobs.com including your contact number.'
    },
    {
        'name': 'hirist.tech',
        'id':12,
        'msg': 'Please post all technical jobs on hirist.tech at http://www.hirist.tech/recruiter/login.php . It\'s absolutely free and easy. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'Low Salary',
        'id':14,
        'msg': 'Unfortunately, the salary offered for this job is low. We only post premium positions on this portal. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'Low Salary v-a-v experience',
        'id':15,
        'msg': 'Unfortunately, the salary offered for this job is low vis-a-vis the years of experience required. We only post premium positions on this portal. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'engineeristic.com',
        'id':9,
        'msg': 'Please post this job on engineeristic.com at http://www.engineeristic.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to  info@iimjobs.com .'
    },
    {
        'name': 'updazz.com',
        'id':10,
        'msg': 'Please post this job on updazz.com at http://www.updazz.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to info@iimjobs.com.'
    },
    {
        'name': 'biojoby.com',
        'id':13,
        'msg': 'Please post this job on biojoby.com at https://www.biojoby.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to info@iimjobs.com. '
    },
    {
        'name': 'Duplicate Posting',
        'id':3,
        'msg': 'This job has already been posted on the portal. Please write to us at info@iimjobs.com for any queries/clarifications'
    },
    {
        'name': 'Multiple Locations',
        'id':4,
        'msg': 'All locations have been updated within the same job posting which is published. Please write to us at info@iimjobs.com for any queries/clarifications.'
    },
    {
        'name': 'No content',
        'id':11,
        'msg': 'There is no content/JD available. Please post it again with more details and update at info@iimjobs.com'
    },
    {
        'name': 'More Details+Official id issue',
        'id': 16,
        'msg': 'We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids. This is to ensure credibility of posting on the portal.In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@iimjobs.com including your contact number. Also, please provide more details about the role & responsibilities as the content currently provided in the JD is insufficient.'
    },
    {
        'name': 'Multiple JDs',
        'id': 17,
        'msg': 'Kindly post all the roles separately with proper job descriptions.'
    },
    {
        'name': 'JD & Title Mismatch',
        'id': 18,
        'msg': 'The job description and title do not match. Please update the job description.'
    },
    {
        'name': 'Others',
        'id':5,
        'msg': 'Please write to us at info@iimjobs.com for any queries/clarifications.'
    }
];

const rejectJobUpdazz = [   
    {   
        'name': 'Please select reason',
        'id': '',
        'msg':''
    },
    {
        'name': 'Not Relevant',
        'id':6,
        'msg': 'We cannot post this as it is not relevant to our target audience. We post Sales/Marketing jobs only. For more information, please drop in a mail to info@updazz.com.'
    },
    {
        'name': 'More Details',
        'id':7,
        'msg': 'Please provide more details about the role & responsibilities/profile. The content provided in the JD is insufficient for us to post this job. Once you update it, please drop us a note at info@updazz.com or inform us on the chat. We will review and publish it.'
    },
    {
        'name': 'Salary Offered',
        'id':8,
        'msg': 'How much is the average salary offered for this position ? Once you update it, please drop us a note at info@updazz.com or inform us on the chat.'
    },
    {
        'name': 'Official ID issue',
        'id':1,
        'msg': 'We generally don\'t allow job postings from Gmail/Yahoo/Outlook etc ids. This is done to prevent any fake postings on the site. In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@updazz.com including your contact number.'
    },
    {
        'name': 'iimjobs.com',
        'id':2,
        'msg': 'Please post this job on iimjobs.com at http://www.iimjobs.com/recruiter/login.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@updazz.com.'
    },
    {
        'name': 'engineeristic.com',
        'id':9,
        'msg': 'Please post this job on engineeristic.com at http://www.engineeristic.com/recruiter/recruiter.php . It\'s absolutely free and easy. For more information, please drop in a mail to info@updazz.com. '
    },
    {
        'name': 'hirist.tech',
        'id':12,
        'msg': 'Please post all technical jobs on hirist.tech at http://www.hirist.tech/recruiter/login.php . It\'s absolutely free and easy. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'biojoby.com',
        'id':13,
        'msg': 'Please post this job on biojoby.com at https://www.biojoby.com/recruiter/recruiter.php. It\'s absolutely free and easy. For more information, please drop in a mail to info@updazz.com. '
    },
    {
        'name': 'Duplicate Posting',
        'id':3,
        'msg': 'This job has already been posted on the portal. Please write to us at info@updazz.com for any queries/clarifications'
    },
    {
        'name': 'Multiple Locations',
        'id':4,
        'msg': 'All locations have been updated within the same job posting which is published. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'No content',
        'id':11,
        'msg': 'There is no content/JD available. Please post it again with more details and update at info@updazz.com'
    },
    {
        'name': 'Others',
        'id':5,
        'msg': 'Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'Low Salary',
        'id':14,
        'msg': 'Unfortunately, the salary offered for this job is low. We only post premium positions on this portal. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'Low Salary v-a-v experience',
        'id':15,
        'msg': 'Unfortunately, the salary offered for this job is low vis-a-vis the years of experience required. We only post premium positions on this portal. Please write to us at info@updazz.com for any queries/clarifications.'
    },
    {
        'name': 'More Details+Official id issue',
        'id': 16,
        'msg': 'We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids. This is to ensure credibility of posting on the portal.In case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@updazz.com including your contact number. Also, please provide more details about the role & responsibilities as the content currently provided in the JD is insufficient.'
    },
    {
        'name': 'Multiple JDs',
        'id': 17,
        'msg': 'Kindly post all the roles separately with proper job descriptions.'
    },
    {
        'name': 'JD & Title Mismatch',
        'id': 18,
        'msg': 'The job description and title do not match. Please update the job description.'
    },

];


let rejectJobPlatfrom;

switch(process.env.REACT_APP_PLATFORM) {
    case 'iimjobs':
        rejectJobPlatfrom = rejectJobIIMJOBS;
        break;
    case 'hirist':
        rejectJobPlatfrom = rejectJobHirist;
        break;
    case 'updazz':
        rejectJobPlatfrom = rejectJobUpdazz;
        break;
    default:
        rejectJobPlatfrom = [];
        break;
}

export const rejectJob = rejectJobPlatfrom;
