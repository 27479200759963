import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableRow } from 'semantic-ui-react';

import Button from '../../../../../components/Button';

import { getValueFromRecruiterType } from '../../../../../models/recruiterType';

import './CompanyStyles.scss';

const notAvailable = 'N/A';
const actionIcons = { 0: 'Activate', 1: 'Inactive', 2: 'Verify' };

const CompanySingleList = (props) => {
    console.log(props.data);
  return (
      <TableRow>
          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.id ? props.data.id : notAvailable}
          </Table.Cell>
          <Table.Cell className="managementData" verticalAlign="top">
              {(props.data.domains || []).join(',')}
          </Table.Cell>
          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.name ? props.data.name : notAvailable}
          </Table.Cell>

          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.displayName ? props.data.displayName : notAvailable}
          </Table.Cell>

          <Table.Cell className="managementData" verticalAlign="top">
              {Number(props.data.type) ? getValueFromRecruiterType(props.data.type) : notAvailable}
          </Table.Cell>
          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.industry
          ? props.industryData[props.data.industry]
          : notAvailable}
          </Table.Cell>
          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.infoedgeId ? props.data.infoedgeId : notAvailable}
          </Table.Cell>
          <Table.Cell className="managementData" verticalAlign="top">
              {props.data.moderated ? 'true' : 'false'}
          </Table.Cell>
          {props.executeAccess ? (
              <Table.Cell className="managementData actions" verticalAlign="top">
                  {props.data.status != 3 && props.data.status != 0 ? (
                      <Button
                        skin="dark"
                        text={actionIcons[props.data.status]}
                        clickButton={props.changeStatus}
                        className="buttonMargin"
                      />
                  ) : null}
                  {/* this condition because if status == 0, company may be inactive or rejected */}
                  {props.data.status == 0 && props.data.companyId ? (
                        <Button
                            skin="dark"
                            text={actionIcons[props.data.status]}
                            clickButton={props.changeStatus}
                            className="buttonMargin"
                        />
                  ) : null}
                  {props.data.status == 2 ? (
                      <Button
                        skin="dark"
                        text='Reject'
                        clickButton={props.rejectCompany}
                        className="buttonMargin"
                    />
                    ) : null}
                  <Button
                        skin="dark"
                        text="Edit"
                        clickButton={props.onClick}
                        className="buttonMargin"
                    />
              </Table.Cell>
      ) : null}
      </TableRow>
  );
};

CompanySingleList.propTypes = {
    data: PropTypes.object,
    industryData: PropTypes.array,
    executeAccess: PropTypes.bool,
    rejectCompany: PropTypes.func,
    openRecruitersModal: PropTypes.func,
    changeStatus: PropTypes.func,
    onClick: PropTypes.func,
    isRejectedStatus: PropTypes.bool,
};

export default CompanySingleList;
