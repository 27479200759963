import axios from '../utils/axios';

export default function saveRecruiterNotification(adminId, data) {
    return (
        axios.post('/admin/' + adminId + '/recruiter-notification', data)
        .then(res => {
            return res.data;
        }).catch(err => {
            return err;
        })
    )
}