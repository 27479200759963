export const applicationCategories = [
    { name: 'All', id: 'all', text:'total', arrow: false, asc: true },
    { name: 'Unread', id: '0', text:'unread', arrow: false, asc: true },
    { name: 'Reviewed', id: '4,5,9', text:'reviewed', arrow: false, asc: true },
    { name: 'Shortlisted', id: '1', text: 'shortlisted', arrow: false, asc: true },
    { name: 'Rejected', id: '2', text: 'rejected', arrow: false, asc: true },
    { name: 'Saved', id: '3', text: 'save',  arrow: false, asc: true },
]

export const applicationCategoryMap = {
    'all': 'total',
    '0': 'unread',
    '4,5,9':'reviewed',
    '1': 'shortlisted',
    '2': 'rejected',
    '3': 'save', 
}

export const applicationStatusMap = {
    '0': 'Unread',
    '1': 'Shortlisted',
    '2': 'Rejected',
    '3': 'Saved', 
    '4':'Reviewed',
    '5':'Reviewed',
    '9':'Reviewed'
}