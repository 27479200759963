import React, { Component } from 'react';
import { withRouter ,Redirect } from 'react-router-dom';
import PropTypes, { object } from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Heading from '../../../components/Heading';
import ButtonContainer from '../../../components/ButtonContainer';
import LinkButtons from '../../../components/LinkButtons';
import Dropdown from '../../../components/Dropdown';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import CountDisplay from '../../../components/CountDisplay';
import TableHeading from '../../../components/TableHeading';
import TableItem from '../../../components/TableItem';
import Loader from '../../../components/Loader';
import FromToDatePicker from '../../../components/FromToDatepicker/FromToDatepicker';
import { getProductType, getSource, getActivity } from '../../../models/filters';
import fetchAllLeads from '../../../services/fetchLeads';
import fetchLeadsMap from '../../../services/fetchLeadsMap';
import getLeadsCSV from '../../../services/getLeadsCSV';
import syncLeadWithCRM from '../../../services/syncLeadsWithCRM';
import user from '../../../store/user';
import { Table } from 'semantic-ui-react';
import './leadList.scss';
import moment from 'moment';
import { adminFeatureMapping } from '../../../models/adminFeatureMapping';

var leadsMap = {}, productTypes = [], sources = [], activities = [];

class LeadList extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            recruiterId: '',
            nameLike: '',
            tagId: '',
            productType: -1,
            source: -1,
            tagType:-1,
            activity: 2,
            pageNumber: 1,
            pageContent: 50,
            totalItemsCount: 0,
            leadList: [],
            loader: true,
            adminId: null,
            csvButtonDisabled: false,
            startDate: null,
            endDate: null
        }
    }
    

    componentDidMount = async () => {

        const profile = await user.getProfile();
       
        // const adminEmails=process.env.REACT_APP_LEADS_BLOCKED_ACCESS;
       
        const isAccessBlocked = user.checkFeatureAccess(adminFeatureMapping.LEADS_BLOCKED_ACCESS);
        if(isAccessBlocked)
            window.location.href='/notAdmin'

        const adminId = profile.id;
        let queryString = this.props.location.search
        const obj = {};
      
        queryString = queryString.replace('?', '');
        queryString = queryString.split('&');
        for (let i = 0; i < queryString.length; i++) {
            const temp = queryString[i].split('=');
            obj[temp[0]] = temp[1]
        }

        let state = {
            recruiterId: obj.recruiterId,
            adminId: adminId,
        };

        state = { ...this.state, ...state }

        this.setState(state)
        const parameters = this.getParameters(state);
        
        leadsMap = await fetchLeadsMap(this.state.adminId)
        productTypes = getProductType(leadsMap)
        sources = getSource(leadsMap)
        activities = getActivity(leadsMap)

        this.fetchLeads(adminId, parameters)

    }

    onChangeProductTypeButton = (event) => {
        const state = this.state;
        state.loader = true;
        state.leadList = [];
        state.productType = parseInt(event.target.value)
        state.pageNumber = 1;
        this.setState(state, () => {
            const parameters = this.getParameters(state);
            parameters.pageNumber = 1;
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(state)
        })
        
    }

    onChangeSourceButton = (event) => {
        const state = this.state;
        state.loader = true;
        state.leadList = [];
        state.source = parseInt(event.target.value)
        state.pageNumber = 1;
        this.setState(state, () => {
            const parameters = this.getParameters(state);
            parameters.pageNumber = 1;
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(state)
        })
       
    }

    onChangeActivityButton = (event) => {
        const state = this.state;
        state.loader = true;
        state.leadList = [];
        state.activity = parseInt(event.target.value)
        state.pageNumber = 1;
        this.setState(state, () => {
            const parameters = this.getParameters(state);
            parameters.pageNumber = 1;
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(state)
        })
       
    }

    onChangeRecInfo = (event) => {

        var value = event.target.value;
        if(value == '') {
            this.setState({
                nameLike: value,
                recruiterId: value,
                pageNumber: 1
            })
        }
        if(isNaN(value))
        this.setState({
            nameLike: value,
            recruiterId: '',
            pageNumber: 1
        })

        else {
            this.setState({
                recruiterId: value,
                nameLike:'',
                pageNumber: 1
            })
        }
    }

    handleRecInfoKeyPress = (event) => {
        if (event.key == 'Enter') {
          this.onClickSearchRecInfo();
        }
    };

    onClickSearchRecInfo = () => {

        const params=this.getParameters(this.state)
        if(!params.recruiterId && !params.nameLike)
            return
     
        this.setState({
             loader: true ,
             pageNumber:1
        }, () => {
            const parameters = this.getParameters(this.state);
            parameters.pageNumber=1;
            this.fetchLeads(this.state.adminId ,parameters)
            this.routeRequest(this.state)
        })
        
    }

    onClickClearRecInfo = () => {
        this.setState({ 
            loader: true ,
            recruiterId:'',
            nameLike:'',
            pageNumber:1
        },() => {
            const parameters = this.getParameters(this.state);  
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(this.state)
        });
    }

    onClickClearDate = () => {
        this.setState({ 
            loader: true ,
            startDate: null,
            endDate: null,
            pageNumber:1
        },() => {
            const parameters = this.getParameters(this.state);  
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(this.state)
        });
    }

    onClickExportCsv = () => {
        this.setState({
            csvButtonDisabled: true
        }, () => {
            const parameters = this.getParameters(this.state);
            this.getLeadsCsv(this.state.adminId, parameters);
            this.routeRequest(this.state)
        })
    }

    filterByDate = () => {
        this.setState({ 
            loader: true ,
            recruiterId:'',
            pageNumber:1
        },() => {
            const parameters = this.getParameters(this.state);  
            this.fetchLeads(this.state.adminId, parameters)
            this.routeRequest(this.state)
        });
    }

    getParameters = (state) => {
        const parameters = {
            pageNumber: state.pageNumber,
            pageContent: state.pageContent,
        }  

        if(isNaN(state.nameLike) && state.nameLike != ''){
            parameters['nameLike'] = state.nameLike;
        }    

        if(!isNaN(state.recruiterId) && state.recruiterId > 0){
            parameters['recruiterId'] = state.recruiterId
        }

        if (state.productType > 0)
            parameters['productType'] = state.productType

        if (state.source > 0)
            parameters['source'] = state.source

        if (state.tagType > 0)
            parameters['tagType'] = state.tagType

        if(state.tagId != '')
            parameters['tagId'] = state.tagId

        if(state.startDate != null && state.endDate != null){
            parameters['startDate'] = moment(state.startDate).format('YYYY-MM-DD')
            parameters['endDate'] = moment(state.endDate).format('YYYY-MM-DD')

        }
        if([1, 2].includes(state.activity)) {
            parameters['activity'] = state.activity
        }

        return parameters;

    }

    handlePageChange = (pageNumber) => {
        const state = this.state;
        state.loader = true
        state.pageNumber = pageNumber;
        this.setState(state)
        this.routeRequest(state)
        const parameters = this.getParameters(this.state);
        this.fetchLeads(this.state.adminId, parameters);

    }

    fetchLeads = (adminId, parameters) => {
        fetchAllLeads(adminId, parameters)
            .then(res => {
                const data = res.data.data;
                
                this.setState({
                    leadList: data.data,
                    totalItemsCount: data.totalCount,
                    loader: false
                });
            })
            .catch(err => {
                this.setState({ loader: false })
        })
    }

    getLeadsCsv = (adminId, parameters) => {
        
        getLeadsCSV(adminId, parameters)
        .then(res => {
            var blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
            var url = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'leads_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                csvButtonDisabled: false
            })
        }).catch(e => {
            this.setState({
                csvButtonDisabled: false
            })
            console.log(e)
        })
    }

    // syncWithCRM = (leadId) => {

    //     syncLeadWithCRM(adminId, {leadsId: leadId})
    //     .then(res => {
            
    //     }).catch(e => {
    //         console.log(e)
    //         toast.error("Unable to sync")
    //     })

    //     toast.success("Synced successfully")

    // }

    newLead = () => {
        this.props.history.push("/newLead");
    }

    routeRequest = (parameters) => {
        let array = [];
        for (const key in parameters) {
            if (parameters[key] != null && key !== 'loader' && key !== 'totalItemsCount' && key !== 'leadList' && key != 'adminId') {
                
                if (typeof (parameters[key]) == 'number' && parameters[key] > 0) 
                    array.push(key + '=' + parameters[key])
                else if (typeof (parameters[key]) == 'string' && parameters[key] != ''){
                     array.push(key + '=' + parameters[key])  
                }
                else if (typeof (parameters[key]) == 'object'){
                    if(this.state.startDate != null && this.state.endDate != null) {
                        var date = moment(this.state[key]).format('YYYY-MM-DD')
                        array.push(key + '=' + date)
                    }
                }

            }
        }

        array = array.join('&')
        this.props.history.push('?' + array)
    }

    parseInfo = (rawInput, recInfo, otherInfo) => {

        for(const key in rawInput) {

            if(key == 'ipAddress' || key == 'companyName' || key =='email' || key == 'location') continue;
            
            if(key == 'name' || key == 'designation' || key == 'phone') {
                recInfo.push({ key:`${key}`, value: `${rawInput[key]}` })
            }
            else otherInfo.push({ key:`${key}`, value: `${rawInput[key]}` })
        }
        return
    }

    render = () => {

        return (
            <div className='page-content-leads'>
                <ToastContainer position='bottom-left'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover />

                <div className='page-heading'>
                    <Heading text='Leads' />
                    <div className='buttons'>
                        <Button skin="dark" text="New Lead" clickButton={this.newLead} />

                        {this.state.leadList.length > 0 ?
                            <Button className="" skin='dark' loading={this.state.csvButtonDisabled} disabled={this.state.csvButtonDisabled} text='Export CSV' clickButton={this.onClickExportCsv} /> :
                            null
                        }
                     </div>
                    
                </div>
                <div className='row category-filter'>
                    <ButtonContainer heading='Categorize By: '>
                        <li key='productType'> <Dropdown data={productTypes} dropdownChange={this.onChangeProductTypeButton} defaultValue={this.state.productType} /> </li>
                        <li key='source'> <Dropdown data={sources} dropdownChange={this.onChangeSourceButton} defaultValue={this.state.source} /> </li>
                        <li key='activity'> <Dropdown data={activities} dropdownChange={this.onChangeActivityButton} defaultValue={this.state.activity} /> </li>
                    </ButtonContainer>
                    <div className="rec-input right">
                        <InputBox className="inputBox" placeholder='Enter Search Keyword' value={this.state.nameLike ? this.state.nameLike : this.state.recruiterId} onChange={this.onChangeRecInfo} onKeyPress={this.handleRecInfoKeyPress} />
                        <Button skin='dark' disabled={false} text='Search' clickButton={this.onClickSearchRecInfo} />
                        <Button skin='light' disabled={false} text='Clear' clickButton={this.onClickClearRecInfo} ></Button>
                    </div>
                    
                </div>

                <div className="row">
                    <ButtonContainer heading= 'Date:'>
                        <li>
                            <div className='date-picker'>
                                <div className="form-group date">
                                    <div className="col-sm-6 left valueBox">
                                        <FromToDatePicker
                                            name="date"
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            handleChangeStart={(e) => {
                                                this.setState({ startDate: e })
                                                if(this.state.endDate != null) {
                                                    this.filterByDate()
                                                    }
                                                } 
                                            }
                                            handleChangeEnd={(e) => {
                                                this.setState({ endDate: e })
                                                if(this.state.startDate != null) {
                                                    this.filterByDate()
                                                    }
                                                }  
                                            }
                                        />
                                    </div>
                                </div>       
                            </div>
                                
                        </li>
                        <li>
                            <Button skin='light' disabled={false} text='Clear' clickButton={this.onClickClearDate} ></Button>
                        </li>
                    </ButtonContainer>
                </div>
                
                <div className='page-row text-center'>
                    <Pagination totalItemsCount={this.state.totalItemsCount} content={this.state.pageContent} pageNumber={this.state.pageNumber} handlePageChange={this.handlePageChange} />
                    <CountDisplay start={(this.state.pageNumber - 1) * this.state.pageContent + 1} end={this.state.pageNumber * this.state.pageContent} total={this.state.totalItemsCount} />
                </div>

                <div >
                
                    <Table fixed selectable structured className="leads-table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3} >S.No</Table.HeaderCell>
                                <Table.HeaderCell width={4} >Date of Enquiry</Table.HeaderCell>
                                <Table.HeaderCell width={4} >Product</Table.HeaderCell>
                                <Table.HeaderCell width={8} >Source</Table.HeaderCell>
                                <Table.HeaderCell width={8}>Recruiter email</Table.HeaderCell>
                                <Table.HeaderCell width={8}>Company Name</Table.HeaderCell>
                                <Table.HeaderCell width={12}>Recruiter Information</Table.HeaderCell>
                                <Table.HeaderCell width={12}>Other Information</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.loader ? (
                                <div className="loader-container">
                                    <Loader />
                                </div>
                                ) : (
                                this.state.leadList.map((item) => {
                            
                                    const parsed = JSON.parse(item.additional_info)
                                    const recInfo = [], otherInfo = [];
                                    this.parseInfo(parsed, recInfo, otherInfo)
        
                                    return (
                                        
                                        <Table.Row key= {item.id}>
                                            <Table.Cell>{item.id}</Table.Cell>
                                            <Table.Cell className="not-capitalized">{moment(item.created_date).format('DD-MM-YYYY')}</Table.Cell>
                                            <Table.Cell>{leadsMap['productType'][item.product_type]}</Table.Cell>
                                            <Table.Cell>{leadsMap['source'][item.source]}</Table.Cell>
                                            <Table.Cell className="not-capitalized">{item.email}</Table.Cell>
                                            <Table.Cell className="not-capitalized">{item.companyName}</Table.Cell>
                                            <Table.Cell>
                                                {<tr><b><td>name:</td></b> <td>{`${item.name}`}</td></tr>}

                                                {
                                                    recInfo.map(info => 
                                                        info.value != 'null' ? <tr key={info.key}><b><td>{`${info.key}: `}</td></b> <td>{`${info.value}`}</td></tr> : null
        
                                                    )
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {
                                                    otherInfo.map(info => 
                                                        info.value != 'null' ? <tr key={info.key}><b><td>{`${info.key}: `}</td></b> <td>{`${info.value}`}</td></tr> : null
                                                    )

                                                }
                                                {(item.location) ? <tr><b><td>location:</td></b> <td>{`${item.location}`}</td></tr> : null}
                                                {<tr><b><td>activity:</td></b> <td>{`${leadsMap['activity'][item.activity]}`}</td></tr>}
                                                {(item.tag_id) ? <tr><b><td>tag id:</td></b> <td>{`${item.tag_id}`}</td></tr> : null}
                                                {(item.tag_type) ? <tr><b><td>tag type:</td></b> <td>{`${leadsMap['tagType'][item.tag_type]}`}</td></tr> : null}
                                                {(item.ref) ? <tr><b><td>Ref:</td></b> <td>{item.ref}</td></tr> : null}

                                            </Table.Cell>
                                            
                                        </Table.Row>
                                        
                                    )
                                })
                            )
                        
                    }
                        </Table.Body>
                    </Table>
                
                </div>
                {
                        this.state.loader ? null : (
                            <div className='page-row text-center pagination-bottom'>
                                <Pagination totalItemsCount={this.state.totalItemsCount} content={this.state.pageContent} pageNumber={this.state.pageNumber} handlePageChange={this.handlePageChange} />
                            </div>
                        )
                }
               
            </div>
        )
    }
}

export default withRouter(LeadList);