import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';

import Heading from '../../components/Heading';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import user from '../../store/user';
import getSearchedKeywords from '../../services/getSearchedKeywords';
import { getQueryParams } from '../../utils/utilFunctions';
import { COUNT_SEARCHED_KEYWORDS } from '../../models/routes';

import './styles.scss';

const timeDurationOptions = [
    {
        id: '1week',
        name: '1 Week Back',
    },
    {
        id: '1month',
        name: '1 Month Back',
    },
    {
        id: '3month',
        name: '3 Months Back',
    },
    {
        id: '6month',
        name: '6 Months Back',
    },
]
const CountSearchedKeywords = props => {
    const [adminId, setAdminId] = useState(null);
    const [durationSlab, setDurationSlab] = useState('1week');
    const [isLoading, setIsLoading] = useState(true);
    const [keywordData, setKeywordData] = useState(null);

    const getKeywordData = async () => {
        try {
            const response = await getSearchedKeywords(adminId, { durationSlab: durationSlab });
           
            if (response && response.data && response.data.data) {
                setKeywordData(response.data.data);
            } else {
                setKeywordData([]);
            }
        } catch (error) {
            setKeywordData([]);
            // console.log(error);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        async function getProfile () {
            const profile = await user.getProfile();
            return profile;
        }

        // check if duration slab is passed in the query params
        if (props.location.search) {
            const queryParams = getQueryParams(props.location.search);
            
            if (queryParams.duration) {
                // check if the duration matches the options
                const findSlab = timeDurationOptions.filter(item => item.id == queryParams.duration);

                if (findSlab.length > 0) {
                    setDurationSlab(queryParams.duration);
                }
            }
        }

        getProfile().then(profile => {
            setAdminId(profile.id);
        }).catch(err => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        })
    }, []);

    useEffect(() => {
        if (adminId) {
            getKeywordData();
        }
    }, [adminId]);

    const handleDropdownChange = (event) => {
        const value = event.target.value;
        if (value != durationSlab) {
            setDurationSlab(value);
        }
    };

    const changeQueryParams = () => {
        props.history.push(`${COUNT_SEARCHED_KEYWORDS}?duration=${durationSlab}`);
    }

    const handleFormSubmit = () => {
        setIsLoading(true);
        getKeywordData();
        changeQueryParams();
    };

    const searchController = (
        <div className="search__container">
            <Dropdown 
                className="duration_dropdown" 
                data={timeDurationOptions} 
                dropdownChange={handleDropdownChange} 
                defaultValue={durationSlab} 
            />
            <Button skin="dark" text="Submit" clickButton={handleFormSubmit}/>
        </div>
    );

    const tableHeading = (
        <Table.Header fullWidth={true}>
            <Table.HeaderCell width="one"></Table.HeaderCell>
            <Table.HeaderCell>Searched Keyword</Table.HeaderCell>
            <Table.HeaderCell width="two">Count</Table.HeaderCell>
        </Table.Header>
    );

    let mainContent = null;
    if (isLoading) {
        mainContent = (
            <>
                <Table>
                    {tableHeading}
                </Table>
                <Loader />
            </>
        );
    } else if (!isLoading && keywordData && keywordData.length === 0){
        mainContent = (
            <>
                No data found!
            </>
        );
    } else {
        mainContent = (
            <>
                <Table striped>
                    {tableHeading}
                    <Table.Body>
                        {keywordData.map((aRow, index) => {
                            return (
                                <Table.Row>
                                    <Table.Cell width="one">{index + 1}</Table.Cell>
                                    <Table.Cell>{aRow.keyword}</Table.Cell>
                                    <Table.Cell width="two">{aRow.count}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </>
        )
    }
    
    return (
        <div className="page-content count-top-searched-keywords">
            <ToastContainer position='bottom-left'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover />

            <div className="page-heading">
                <Heading text={`Count Top 50 Searched Keywords - ${process.env.REACT_APP_PLATFORM}`} />
            </div>

            {searchController}

            <div className="main-content">
                {mainContent}
            </div>
        </div>
    )
};

export default CountSearchedKeywords;