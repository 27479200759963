export const dates = [
    "today",
    "yesterday",
    "dayBeforeYesterday",
    "currMonth",
    "lastMonth",
    "lastToLastMonth",
    "overall"
];

export const rec_action = [
    {
        key: "short",
        title: "Shortlist"
    },
    {
        key: "rej",
        title: "Reject"
    },
    {
        key: "save",
        title: "Save"
    },
    {
        key: "view",
        title: "View"
    },
    {
        key: "down",
        title: "Download"
    },
    {
        key: "comment",
        title: "Comment"
    },
    {
        key: "contact",
        title: "Contact"
    }
];

export const seeker_reg = [
    {
        key: "jrw",
        title: "Web"
    }, {
        key: "jrm",
        title: "Mobile"
    }, {
        key: "jra",
        title: "App"
    }, {
        key: "jrr",
        title: "RespUI"
    }
];

export const apply_jobs = [
    {
        key: "web",
        title: "Web"
    }, {
        key: "mob",
        title: "Mobile"
    }, {
        key: "app",
        title: "App"
    }, {
        key: "res",
        title: "RespUI"
    }
];

export const saved_jobs = apply_jobs;
export const seeker_login = apply_jobs;