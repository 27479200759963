const categoryObjIimjobs = [
    { name: 'Select Category', id: -1 },
    { name: 'Finance', id: 13 },
    { name: 'Sales & Marketing', id: 14 },
    { name: 'Consulting', id: 16 },
    { name: 'HR', id: 17 },
    { name: 'IT & Systems', id: 15 },
    { name: 'Operations', id: 19 },
    { name: 'Legal', id: 21 },
    { name: 'BPO', id: 22 },
]

const categoryObjHirist = [
    { name: 'Select Category', id: -1 },
    { id: '7', name: 'Analytics & Data Science' },
    { id: '5', name: 'Mobile Applications' },
    { id: '8', name: 'DevOps' },
    { id: '2', name: 'Frontend Developer' },
    { id: '1', name: 'Backend Developer' },
    { id: '9', name: 'Emerging Technologies & Roles' },
    { id: '10', name: 'UI & Design' },
    { id: '11', name: 'Quality Assurance' },
    { id: '12', name: 'Product Management Jobs' },
    { id: '6', name: 'Enterprise - SAP/Oracle Jobs' },
    { id: '4', name: 'Semiconductor/VLSI/EDA' },
    { id: '13', name: 'Business Analysis and Project Management' },
    { id: '3', name: 'Other Jobs' }
]

const categoryObjUpdazz = [
    { id: -1,
      name: 'Select Category'
    },
    {
        id: "14",
        name: "BFSI Sales"
    },
    {
        id: "2",
        name: "B2B/Corporate Sales"
    },
    {
        id: "1",
        name: "B2C/Retail/Channel Sales"
    },
    {
        id: "11",
        name: "Online/Digital marketing"
    },
    {
        id: "5",
        name: "Telesales/Inside Sales/BPO"
    },
    {
        id: "15",
        name: "IT Sales"
    },
    {
        id: "9",
        name: "Marketing/PR/Advertising"
    },
    {
        id: "8",
        name: "After-Sales / Customer Service"
    },
    {
        id: "10",
        name: "Content/Design"
    },
    {
        id: "7",
        name: "Pre-Sales"
    },
    {
        id: "6",
        name: "Sales Management"
    }
]

export function getCategory() {
    switch(process.env.REACT_APP_PLATFORM) {
        case 'iimjobs':
            return categoryObjIimjobs
        case 'updazz':
            return categoryObjUpdazz
        default:
            return categoryObjHirist
    }
}

export function getCategoryPill() {
    const category = getCategory();
    const pillCategory = category.map(cat => ({
        text: cat['name'],
        key: cat['id'],
        value: `${cat['id']}`

    }))


    return pillCategory;
}

export function getCategoryMap() {
    let arr = [];
    if(process.env.REACT_APP_PLATFORM == 'iimjobs')
        arr = categoryObjIimjobs;
    else if(process.env.REACT_APP_PLATFORM == 'hirist')
        arr = categoryObjHirist
    else 
        arr = categoryObjUpdazz

    return arr.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}