import axios from '../utils/axios';

const disconnectRecruiterSocial = (adminId, recruiterId, type) => {
    return (
        axios.get('/admin/' + adminId + '/recruiter/' + recruiterId + '/disconnect/' + type)
            .then(res => {
                return res
            })
            .catch(err => {
                return null;
            })
    )
}

export default disconnectRecruiterSocial;