const fundingStageObj = [
    {
        id: 0,
        name: 'Select Funding Stage'
    },
    {
        id: 1,
        name: 'Bootstrapped'
    },
    {
        id: 2,
        name: 'Seed Stage'
    },
    {
        id: 3,
        name: 'Series A'
    },
    {
        id: 4,
        name: 'Series B'
    },
    {
        id: 5,
        name: 'Series C'
    },
    {
        id: 6,
        name: 'Series D and beyond'
    },
    {
        id: 7,
        name: 'Unicorn'
    },
    {
        id: 8,
        name: 'IPO'
    },
    {
        id: 9,
        name: 'Acquired'
    },
    {
        id: 10,
        name: 'Other'
    }
]

export function getFundingStage() {
    return fundingStageObj;
}

export function getFundingStageFromId () {
    return fundingStageObj.reduce((p,c) => {
        p[c.id] = c.name;
        return p;
    }, {})
}