import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Table } from 'semantic-ui-react';

import './TableItem.scss';

const TableItem = props => {
    const recruiterData = props.data;
    const rowType = props.type ? props.type : 'active';

    const postingLink = `${process.env.REACT_APP_CLIENT_URL}/recruiter/mgr_job_reports.php?recruiterid=${rowType === 'manager' ? recruiterData.managerId : recruiterData.id}&search=search`; 
    const dbSearchLink = `${process.env.REACT_APP_CLIENT_URL}/recruiter/dbsearchlog.php?e_search=${rowType === 'manager' ? recruiterData.managerEmail : recruiterData.email}&search=search`

    const handleRecruiterStatusUpdate = () => {
        if (rowType === 'inactive') {
            return props.onUpdateRecruiterStatus([recruiterData.id], 1);
        }
        return props.onUpdateRecruiterStatus([recruiterData.id], 0);
    };

    return (
        <Table.Row className={`recruiter-row-item ${rowType === 'manager' ? 'manager' : ''}`} key={recruiterData}>
            <Table.Cell width="one" verticalAlign="top">{recruiterData.id}</Table.Cell>
            <Table.Cell width="two" verticalAlign="top">{recruiterData.name}</Table.Cell>
            <Table.Cell width="two" verticalAlign="top">{recruiterData.email}</Table.Cell>
            <Table.Cell width="two" verticalAlign="top">{recruiterData.phone}</Table.Cell>
            <Table.Cell width="two" verticalAlign="top">{recruiterData.organisation}</Table.Cell>
            <Table.Cell width="one" verticalAlign="top" className="actions">
                <div onClick={handleRecruiterStatusUpdate}>
                    {rowType !== 'manager' ? rowType === 'active' ? 'Remove Recruiter' : 'Add Recruiter' : null}
                </div>
                {rowType === 'manager' ? <a href={postingLink} target='_blank'>Posting</a> : null}
            </Table.Cell>
            {rowType !== 'inactive' ? (
                <Table.Cell width="one" verticalAlign="top">
                    <div className='more-actions'>
                        {rowType === 'manager' ? null : <a href={postingLink} target='_blank'>Posting</a>}
                        <a href={dbSearchLink} target='_blank'>DB Search</a>
                    </div>
                </Table.Cell>
            ) : null}
            <Table.Cell width="one" verticalAlign="top">
                {rowType === 'manager' ? null : (
                    <Checkbox 
                        checked={recruiterData.isChecked}
                        onChange={() => props.onSelectRecruiter(recruiterData.id)}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    )
};

TableItem.propTypes = {
    key: PropTypes.string,
    data: PropTypes.object.isRequired,
    onUpdateRecruiterStatus: PropTypes.func,
    onSelectRecruiter: PropTypes.func,
    type: PropTypes.string,
    managerId: PropTypes.string,
    managerEmail: PropTypes.string,
}

export default TableItem;