import React from "react";
import { useState } from "react";
import InputBox from "../../../../components/InputBoxWithoutCheck";
import Button from "../../../../components/Button";

import Heading from "../../../../components/Heading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import user from "../../../../store/user";
import { useEffect } from "react";
import validator from "validator";

import './styles.scss'
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { getQueryParams } from '../../../../utils/utilFunctions';
import fetchCourseMapping from "../../../../services/fetchCourseMapping";
import upsertCourseMapping from "../../../../services/upsertCourseMapping";
import fetchCourseIntegrations from "../../../../services/fetchCourseIntegrations";


const validationMsg = {
  integrationReq: 'Please select integration',
  idReq: 'Please enter course id',
  publishedIdReq: 'Please enter course publish id',
  titleReq: 'Please enter course title',
  campaignCodeReq: 'Please enter campaign code'
}

const MappingAddEdit = (props) => {

const {courseId} = getQueryParams(props.location.search);

  const [details, setDetails] = useState({
    integration: '',
    courseId: '',
    publishedId: '',
    title: '',
    campaignCode: '',
    status: 'active'
  })

  const [courseIntegrations, setCourseIntegrations] = useState([]);
  const [validations, setValidations] = useState({})
  const [submitApiCall, setSubmitApiCall] = useState(false)
  const [admin, setAdmin] = useState({
    adminId: null
  })

  useEffect(() => {
    const fetchProfile = async () => {
      return await user.getProfile()
    }
    fetchProfile().then(userData => {
      if (userData.adminType == 2 || userData.adminType == 3 || userData.adminType == 4) {
        setAdmin({
          adminId: userData.id
        })

        getCourseInterations(userData.id);

        if(courseId) {
          getCourseMappingDetail(userData.id);
        }

      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    }).catch(e => {
      console.log(e);
      toast.error('Something went wrong!')
    });
  }, [])


  const getCourseInterations = async(adminId) => {
    try{
      const res = await fetchCourseIntegrations(adminId);
      const data = res.data.data.integrations;
      const formattedList = formatIntegration(data);

      setCourseIntegrations(formattedList);
    }
    catch(e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  }

  const formatIntegration = (listMap) => {
    const newList = [];
    newList.push({
        id: '',
        name: 'Select Integration Type'
    })


    for(const key in listMap) {
      newList.push({
        id: key,
        name: listMap[key]
      })
    }


    return newList;
  }

  const getCourseMappingDetail = async(adminId) => {

    try {

      const res = await fetchCourseMapping(adminId, courseId)
      const data = res.data.data;
      setDetails({...data});
    }
    catch(e) {
      console.log(e);
      if(e.response.status == 404) {
        toast.error('Course Not found');
        return;
      }

      toast.error('Something went wrong')
    }
    
  }

  const onInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setDetails(prev => ({
        ...prev,
        [name]: value
      }))
  }

  const validate = () => {
     const validations = {
      integrationReq: checkEmpty(details.integration),
      idReq: checkEmpty(details.courseId),
      publishedIdReq: checkEmpty(details.publishedId),
      titleReq: checkEmpty(details.title),
      campaignCodeReq: checkEmpty(details.campaignCode)
     }
     setValidations({...validations})

     for (let key in validations) {
      if (validations[key]) {
        // scrollToElement(validationClass[key]);
        return false;
      }
    }
    return true;

  }

  const checkEmpty = (val) => {
    if (!val) return true;
    if (typeof val == "string" && validator.isEmpty(val)) return true;
    return false;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if(validate()) {
      setSubmitApiCall(true);
      const data = {
        ...details,
        status: details['status'] == 'active' ? 1 : 0
      }

      try {
        await upsertCourseMapping(admin.adminId, data);

        if(courseId) {
          toast.success('Course Mapping updated successfully');
          setTimeout(() =>  props.history.push('/courseMappingList'), 2000)
        }

        else {
          toast.success('Course Mapping added successfully');

          setDetails({});
          
          setTimeout(() =>  props.history.push('/courseMappingList'), 2000)
        }

        setSubmitApiCall(false);
      }
      catch(e){
        setSubmitApiCall(false);
        console.log(e);

        if(e.message.includes('400')) {
          return toast.error('Details incorrect. Please check!');
        }
        toast.error('Something went wrong');
      }
      
    }
  }

  return (
    <div>
      <div className="add-course-mapping-container">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />

        <div className="pageHeading">
          <Heading text={courseId ? 'Update Course Mapping': 'Add Course Mapping'}/>
          <Button
            className='btn_ce_view_report'
            skin="dark"
            text="Course Mapping"
            clickButton={() => {
              props.history.push('/courseMappingList')
            }}
          />
          <a href={`${process.env.REACT_APP_CLIENT_URL}/recruiter/add_client_engagement.php`} target='_blank'>
              <Button skin='dark' className="btn_use_old" disabled={false} text='Use Old Version'/>
          </a>
        </div>
        <hr />
        <div className="create-tag-mailer">
          <form>
            <div className="row section details-section">
              <p className="label-heading"> {courseId ? "" : "New "}Course Mapping Details </p>

              <div className="form-group">
                <label className="col-sm-2 left"> Integration* </label>
                <div className="col-sm-6 left valueBox">
                <Dropdown
                    name='integration'
                    data={courseIntegrations}
                    dropdownChange={onInputChange}
                    defaultValue={details.integration}
                />
                  {validations.integrationReq ? <p className='validatorError'> {validationMsg.integrationReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left">Course Id*</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="courseId"
                    value={details.courseId}
                    placeholder="Course Id"
                    onChange={onInputChange}
                  />
                  {validations.idReq ? <p className='validatorError'> {validationMsg.idReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Course Published Id*</label>
                <div className="col-sm-6 left valueBox">
                <InputBox
                    type="text"
                    name="publishedId"
                    value={details.publishedId}
                    placeholder="Course Published Id"
                    onChange={onInputChange}
                  />
                  {validations.publishedIdReq ? <p className='validatorError'> {validationMsg.publishedIdReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Course Title*</label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="title"
                    value={details.title}
                    placeholder="Course Title"
                    onChange={onInputChange}
                  />
                  {validations.titleReq ? <p className='validatorError'> {validationMsg.titleReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Campaign Code* </label>
                <div className="col-sm-6 left valueBox">
                  <InputBox
                    type="text"
                    name="campaignCode"
                    value={details.campaignCode}
                    placeholder="Campaign Code"
                    onChange={onInputChange}
                  />
                  {validations.campaignCodeReq ? <p className='validatorError'> {validationMsg.campaignCodeReq} </p> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 left"> Status</label>
                <div className="col-sm-6 left valueBox">
                    <div className="radio-container">
                        <InputBox
                            type="radio"
                            value="active"
                            checked={
                                details.status ==
                                "active"
                            }
                            name="status"
                            onChange={onInputChange}
                        />{" "}
                        Active
                    </div>
                    <div className="radio-container">
                        <InputBox
                            type="radio"
                            value="deactive"
                            checked={
                                details.status ==
                                "deactive"
                            }
                            name="status"
                            onChange={onInputChange}
                        />{" "}
                        Deactive
                    </div>
                    {validations.statusReq ? <p className='validatorError'> {validationMsg.statusReq} </p> : null}
                </div>
              </div>
            </div>

            <div className="actions">
              <Button
                skin="dark"
                disabled={submitApiCall}
                loading={submitApiCall}
                text={courseId ? "Update" : "Submit"}
                clickButton={onSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MappingAddEdit