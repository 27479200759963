import React from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment';
import { Checkbox } from 'semantic-ui-react';

import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import CompanyForm from './components/CompanyForm';
import Loader from '../../../components/Loader';

import user from '../../../store/user';
import fetchIndustries from '../../../services/fetchIndustries';
import getAllCompanies from '../../../services/getCompanyManagement';
import getUnverifiedCompanies from '../../../services/getUnverifiedCompanies';
import postCompanyManagement from '../../../services/postCompanyManagement';
import updateCompanyManagement from '../../../services/updateCompanyManagement';
import updateUnverifiedCompany from '../../../services/updateUnverifiedCompany';

import { adminFeatureMapping } from '../../../models/adminFeatureMapping';
import { getQueryParams } from '../../../utils/utilFunctions';
import { getValueFromRecruiterType } from '../../../models/recruiterType';
import { getCompanyClassificationValue } from '../../../models/companyClassification';
import { getOrganisationTypeArr } from '../../../models/organisationType';

import 'react-toastify/dist/ReactToastify.css';
import './newCompany.scss';

const INPUT_FIELDS_CLASSNAME_MAP = {
    name: 'name',
    displayName: 'displayName',
    domains: 'domains',
    industry: 'industry',
    type: 'recType',
    firstPartyEmail: 'firstPartyEmail',
    companyType: 'organisationType',
    companyFounded: 'companyFounded',
    parentCompany: 'parentCompany'
};
class NewCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataObj: {},
            buttonClicked: false,
            buttonAction: 'Add',
            adminId: '',
            adminEmail: '',
            isLoading: true,
            industryApiData: [],
            executeAccess: false,
            fieldsEditAccess: false,
            status: null,
            editMode: false,
        };
        this.setbuttonCLicked = this.setbuttonCLicked.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    async componentDidMount() {
        const userDetails = await user.getProfile();
        const adminId = userDetails.id;
        const adminEmail = userDetails.email;
        var executeAccess = false;
        var fieldsEditAccess = false;

        if (user.checkFeatureAccess(adminFeatureMapping.COMPANY_MGT)) {
            executeAccess = true;
        }

        if (user.checkFeatureAccess(adminFeatureMapping.COMPANY_MGT_FIELDS_EDIT)) {
            fieldsEditAccess = true;
        }

        this.setState({ adminId, adminEmail, executeAccess, fieldsEditAccess });
        var industryApiData = await fetchIndustries();
        this.setState({ industryApiData: industryApiData.industries });

        if (this.props.match.path != '/NewCompany') {
            const params = { id: this.props.match.params.id };
            const queryParams = getQueryParams(this.props.location.search);
            const status = queryParams.status;

            this.setState({ status: status, editMode: true });

            if (status == 1) {
                await getAllCompanies(this.state.adminId, params)
                    .then(async (res) => {
                        var data = res.data.data.companies[0] || {};
                        // data.domains=data.domains.join(',');

                        let groupName = '';

                        // if company is associated with a group, then fetch group name of the company
                        // because data.groupId is company id
                        if (data && data.groupId) {
                            const groupCompanyResponse = await getAllCompanies(this.state.adminId, {
                                id: data.groupId
                            });

                            if (groupCompanyResponse && groupCompanyResponse.data && groupCompanyResponse.data.data && groupCompanyResponse.data.data.companies && groupCompanyResponse.data.data.companies.length > 0) {
                                groupName = groupCompanyResponse.data.data.companies[0].name;
                            }
                        }
                        await this.setState({
                            dataObj: {
                                ...data,
                                groupName,
                                isParentCompany: data.groupId && data.groupId == data.id ? 1 : 0, 
                                tempPremiumRanks: { ...data.premiumRanks },
                            }, buttonAction: 'Update', isLoading: false
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                await getUnverifiedCompanies(this.state.adminId, params)
                    .then(async (res) => {
                        var data = res.data.data.companies[0] || {};
                        // data.domains=data.domains.join(',');

                        let groupName = '';

                        // if company is associated with a group, then fetch group name of the company
                        // because data.groupId is company id
                        if (data && data.groupId) {
                            const groupCompanyResponse = await getAllCompanies(this.state.adminId, {
                                id: data.groupId
                            });

                            if (groupCompanyResponse && groupCompanyResponse.data && groupCompanyResponse.data.data && groupCompanyResponse.data.data.companies && groupCompanyResponse.data.data.companies.length > 0) {
                                groupName = groupCompanyResponse.data.data.companies[0].name;
                            }
                        }

                        await this.setState({
                            dataObj: {
                                ...data,
                                groupName,
                                tempPremiumRanks: { ...data.premiumRanks },
                            }, buttonAction: 'Update', isLoading: false
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }


            return
        }
        this.setState({ isLoading: false });
    }

    setbuttonCLicked(data) {
        this.setState({ buttonClicked: data });
    }

    scrollToInput = (key) => {
        const element = document.getElementsByName(key);
        if (element && element.length > 0) {
            element[0].scrollIntoView({ behavior: 'smooth' });
        }
    }

    validateData(data) {
        // name is required
        if (!data.name || !data.name.trim().length) {
            this.setbuttonCLicked(false);
            toast.error('Name cant be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.name);
            return 0;
        }

        // display name is required
        if (!data.displayName || !data.displayName.trim().length) {
            this.setbuttonCLicked(false);
            toast.error('Display  cant be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.displayName);
            return 0;
        }

        // domains is required
        if (!data.domains || data.domains == null || !data.domains.length) {
            this.setbuttonCLicked(false);
            toast.error('Domain cant be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.domains);
            return 0;
        }

        if (data.errorDomains && data.errorDomains.length > 0) {
            let hasError = false;

            data.errorDomains.forEach(domain => {
                if (data.domains.indexOf(domain) > -1) {
                    hasError = true;
                }
            });

            if (hasError) {
                this.setbuttonCLicked(false);
                toast.error('Some/All Domains are already registered');
                this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.domains);
                return 0;
            }
        }

        // if domains provided, check if valid
        if (data.domains.length) {
            var validDomains = data.domains.filter((eachDomain) => { return eachDomain.split('.').length > 1 ? true : false })
            if (validDomains.length != data.domains.length) {
                this.setbuttonCLicked(false);
                toast.error('Please provide valid domain name');
                this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.domains);
                return 0;
            }
        }

        // industry is required
        if (!data.industry || data.industry == null) {
            this.setbuttonCLicked(false);
            toast.error('Industry cant be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.industry);
            return 0;
        }

        // company type is required
        if (!data.type || data.type == null) {
            this.setbuttonCLicked(false);
            toast.error('Recruiter Type cant be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.type);
            return 0;
        }

        if (!data.companyType || data.companyType == null) {
            this.setbuttonCLicked(false);
            toast.error('Organisation Type cannot be empty');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.companyType);
            return 0;
        }

        if (data.firstPartyEmail && data.firstPartyEmail.length && !(/(.+)@(.+){2,}\.(.+){2,}/.test(data.firstPartyEmail))) {
            this.setbuttonCLicked(false);
            toast.error('First Primary Email Field must contain an email or empty field');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.firstPartyEmail);
            return 0;
        }

        // if company founded year is set then check validity
        if (data.companyFounded && data.companyFounded.length > 0) {
            if (data.companyFounded.length < 4 || data.companyFounded.length > 4) {
                this.setbuttonCLicked(false);
                toast.error('Please enter valid year');
                this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.companyFounded);
                return 0;
            } else {
                const currentYear = new Date().getFullYear();

                if (data.companyFounded > currentYear) {
                    this.setbuttonCLicked(false);
                    toast.error('Please enter valid year');
                    this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.companyFounded);
                    return 0;
                }
            }
        }

        if (data.type == '2' || data.type == '3') {
            // selected recruiter type is consultant
            // industry cannot be anything other than Recruitment/Staffing

            let staffingId = null;
            this.state.industryApiData.map(item => {
                if (item.name && item.name.toLowerCase().indexOf('staffing') > -1) {
                    staffingId = item.id;
                }
            });

            if (data.industry != staffingId) {
                this.setbuttonCLicked(false);
                toast.error(`For ${getValueFromRecruiterType(data.type)}, industry is not Recruitment/Staffing`);
                this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.industry);
                return 0;
            }
        }

        if (data.groupCompany && !data.isParentCompany && !data.parentCompanyId) {
            this.setbuttonCLicked(false);
            toast.error('Please select parent company');
            this.scrollToInput(INPUT_FIELDS_CLASSNAME_MAP.parentCompany);
            return 0;
        } 

        // if (!data.infoedgeId||data.infoedgeId==null||!data.infoedgeId.trim().length) {
        //   this.setbuttonCLicked(false);
        //   toast.error('InfoedgeId cant be empty');
        //   return 0;
        // }
        return 1;
    }
    async sendData() {
        this.setbuttonCLicked(true);
        var wholeData;

        // edit company
        if (this.props.match.path != '/NewCompany') {
            const stateObj = {
                ...this.state.dataObj,
                premiumRanks: this.state.dataObj.tempPremiumRanks,
            }
            wholeData = JSON.parse(JSON.stringify(stateObj));

            delete wholeData.tempPremiumRanks;
            delete wholeData.groupName;

            // if parent company, then remove group id 
            if (wholeData.isParentCompany) {
                // this flag will only be set for active companies
                // so we can use id for company id here
                wholeData.groupId = this.state.dataObj.id;
                delete wholeData.isParentCompany;
            }

            if (!this.validateData(wholeData)) {
                return;
            }

            // if toHide object not set, set default values
            if (!wholeData.toHide) {
                wholeData.toHide = {
                    iimjobs: false,
                    hirist: false,
                    updazz: false
                }
            }

            // if company not active, then update via unverified route
            if (this.state.status == 2 || this.state.status == 3 || this.state.status == 0) {
                wholeData = {
                    ...wholeData,
                    updatedAt: moment().unix()
                };
                await updateUnverifiedCompany(
                    this.state.adminId,
                    this.props.match.params.id,
                    wholeData
                )
                    .then((res) => {
                        if (res.status != 'success') {
                            this.setbuttonCLicked(false);
                            toast.error('Update failed');
                            return;
                        }
                        this.setbuttonCLicked(false);
                        toast.info('Updated Successfully');
                        setTimeout(() => {
                            this.props.history.push('/companyManagement');
                        }, 1500);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                await updateCompanyManagement(
                    this.state.adminId,
                    this.props.match.params.id,
                    wholeData
                )
                    .then((res) => {
                        if (res.status != 'success') {
                            this.setbuttonCLicked(false);
                            toast.error('Update failed');
                            return;
                        }
                        this.setbuttonCLicked(false);
                        toast.info('Updated Successfully');
                        setTimeout(() => {
                            this.props.history.push('/companyManagement');
                        }, 1500);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {

            // new company

            const stateObj = {
                ...this.state.dataObj,
                premiumRanks: this.state.dataObj.tempPremiumRanks,
            }
            wholeData = JSON.parse(JSON.stringify(stateObj));

            delete wholeData.tempPremiumRanks;
            delete wholeData.groupName;

            wholeData.status = 1;

            if (!this.validateData(wholeData)) {
                return;
            }

            await postCompanyManagement(this.state.adminId, wholeData)
                .then((res) => {
                    if (res.status != 'success') {
                        this.setbuttonCLicked(false);
                        let errorMsg = 'Post failed';
                        if (res.response && res.response.data && res.response.data.message) {
                            errorMsg = res.response.data.message;
                        }
                        toast.error(errorMsg);

                        return;
                    }
                    this.setbuttonCLicked(false);
                    toast.info('Added Successfully');
                    setTimeout(() => {
                        this.props.history.push('/companyManagement');
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    setData(data) {
        var stateData = { ...this.state.dataObj };
    
        // set nested admin details object
        if (data.key.includes('adminDetails')) {
            let newAdminDetails = { ...stateData.adminDetails };

            if (data.key.split('.').length > 1) {
                // this means there is a key appended
                const objKey = data.key.split('.')[1];
                newAdminDetails[objKey] = data.value;
            } else {
                // this means the whole object to be set at once
                newAdminDetails = data.value;
            }

            stateData['adminDetails'] = newAdminDetails;
        } else if (data.key.includes('premiumRanks')) {
            // since premium ranks inside data obj is a nested object
            const premiumRanksArr = data.key.split('.');
            const product = premiumRanksArr[1];

            if (!stateData.tempPremiumRanks) {
                stateData.tempPremiumRanks = {};
            }

            const tempPremiumRanksObj = stateData['tempPremiumRanks'];

            if (!data.value) {
                // if company already had a premium rank for this product, then set it to null to update
                if (stateData.premiumRanks[product]) {
                    tempPremiumRanksObj[product] = null;
                } else {
                    // if company did not have premium rank then delete key from state object
                    delete tempPremiumRanksObj[product];
                }
            } else {
                tempPremiumRanksObj[product] = data.value;
            }
            stateData['tempPremiumRanks'] = tempPremiumRanksObj;
        } else if (data.key.includes('toHide')) {
            const toHideArr = data.key.split('.');
            const product = toHideArr[1];
            const toHideObj = stateData['toHide'] || {};

            toHideObj[product] = data.value;
            stateData['toHide'] = toHideObj;
        } else if (data.key.includes('industry')) {
            this.state.industryApiData.map((ind) => {
                if (ind.id == data.value) {
                    // set company classification
                    stateData['classification'] = getCompanyClassificationValue(ind.classification);
                }
            });

            if(!data.value) {
                stateData['classification'] = null;
            }
            stateData[data.key] = data.value;
        } else if (data.key == 'gcc') {
            stateData['gcc'] = data.value == 1 ? 1 : 0;
            stateData['captive'] = 0; 
        } else if (data.key == 'captive') {
            stateData['captive'] = data.value == 1 ? 1 : 0;
            stateData['gcc'] = 0;
        } else if (data.key == 'type') {
            stateData['type'] = data.value; 
            if (data.value == 3 || data.value == 2) {
                let staffingId = null;
                let orgTypeId = null;
                let classification = 0;

                this.state.industryApiData.map(item => {
                    if (item.name && item.name.toLowerCase().indexOf('staffing') > -1) {
                        staffingId = item.id;
                        classification = getCompanyClassificationValue(item.classification);
                    }
                });

                getOrganisationTypeArr().map(item => {
                    if (item.label.toLowerCase() == 'consultant') {
                        orgTypeId = item.value;
                    }
                });

                if (!this.state.editMode) {
                    stateData['industry'] = staffingId;
                    stateData['companyType'] = orgTypeId;
                    stateData['classification'] = classification;
                } else {
                    const userChoice = confirm('Recommended industry for individual consultant is Staffing. Change industry now?');

                    if (userChoice) {
                        stateData['industry'] = staffingId;
                        stateData['companyType'] = orgTypeId;
                        stateData['classification'] = classification;
                    }
                }
            } 
        } else if (data.key == 'moderated') { 
            stateData[data.key] = data.value;
            stateData['moderatedBy'] = this.state.adminId;
        } else if (data.key == 'parentCompany') {
            stateData['groupId'] = data.value.value;
            stateData['groupName'] = data.value.label;
        } else if (data.key == 'groupId') {
            stateData[data.key] = data.value;

            if (!data.value) {
                stateData['isParentCompany'] = 0;
            }
        } else {
            stateData[data.key] = data.value;
        }
        
        this.setState({ dataObj: stateData });
    }

    cancel() {
        this.props.history.push('/companyManagement');
    }

    render() {
        return (
            <div className='new-company-form'>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover
                />
                <div className='main-content'>
                    {this.state.isLoading ? <Loader /> : (
                        <>
                            <CompanyForm
                                adminId={this.state.adminId}
                                match={{ path: this.props.match.path }}
                                data={this.state.dataObj}
                                setData={this.setData.bind(this)}
                                industry={this.state.industryApiData}
                                classNameMap={INPUT_FIELDS_CLASSNAME_MAP}
                                editMode={this.state.editMode}
                                fieldsEditAccess={this.state.fieldsEditAccess}
                            />

                            <div className="inputGroup moderation">
                                <label className="managementLabels">Moderated By MOD Team :</label>

                                <div className="managementInputFields">
                                    {' '}
                                    <Checkbox
                                        defaultChecked={this.state.dataObj.moderated ? true : false}
                                        checked={this.state.dataObj.moderated ? true : false}
                                        onChange={(event, data) => {
                                            this.setData({
                                                key: 'moderated',
                                                value: data.checked ? true : false,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="managementButtons">
                        <div className="managementAddButton">
                            {this.state.buttonClicked ? (
                                <Spinner />
                            ) : (this.state.executeAccess ?
                                <Button
                                    skin="dark"
                                    text={this.state.buttonAction}
                                    clickButton={this.sendData.bind(this)}
                                /> : null
                            )}
                        </div>
                        <div className="managementAddButton">
                            {' '}
                            <Button
                                skin="dark"
                                text="Cancel"
                                clickButton={this.cancel.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NewCompany);
