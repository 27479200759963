import axios from '../utils/axios';

export default function assignJob(adminId, data) {
    return (
        axios.post('/admin/' + adminId + '/assign-jobs', data)
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err;
            })
    )
}
